  <template>
  <div>
    <router-view></router-view>
    <div class="channelManagement" v-if="$route.path == '/websiteMange'">
      <div class="channelManagement-head">
        <h1>基本设置</h1>
      </div>
      <el-card class="box-card">
        <div class="cloum">
          <p class="cloum_tit">轮播图设置：</p>
          <el-upload
            style="width: 600px"
            class="upload-demo"
            drag
            :action="uploadUrl"
            :headers="myHeaders"
            accept="image/jpeg,image/jpg,image/png"
            :on-change="uploadChange"
            :on-success="uploadReturn"
            :before-upload="beforeAvatarUpload"
            :on-exceed="handleExceed"
            :limit="10"
            multiple
            :file-list="fileList"
            :show-file-list="false"
          >
            <!-- :on-remove="companyUploadRemove" -->
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过2M (轮播图建议尺寸 宽:850px;
              高:380px;)
            </div>
          </el-upload>
        </div>
        <!-- {{ carouselList }} -->
        <div style="margin-left: 144px; margin-bottom: 30px">
          <div class="carousel_warps">
            <div class="carousel_item" v-for="d in carouselList" :key="d.id">
              <i
                class="el-icon-circle-close close_icon"
                @click="companyUploadRemove(d)"
              ></i>
              <img :src="d.url || d.data" alt="" />
              <p>{{ d.docRealName }}</p>
            </div>
          </div>
          <el-button
            style="margin-top: -10px"
            type="primary"
            @click="submitCarouse"
            v-if="userbtn.indexOf('WEBSIT_CAROUSEL_SET') >= 0"
            >保存</el-button
          >
        </div>
        <div class="cloum">
          <p class="cloum_tit">动态企业设置：</p>
          <div class="cloum_con">
            <span
              >请输入企业名称，不同企业名称之间用
              <b style="color: red">中文；符号</b>
              分割，结尾不需要加；字符最多不超过300字</span
            >
            <el-input
              type="textarea"
              placeholder="请输入企业名称，不同企业名称之间用中文；分割，字符最多不超过300字"
              v-model="categoryText"
              maxlength="300"
              show-word-limit
            >
            </el-input>
            <el-button
              type="primary"
              @click="submitCategory"
              v-if="userbtn.indexOf('WEBSIT_CATEGORY_SET') >= 0"
              >保存</el-button
            >
          </div>
        </div>
        <div class="cloum">
          <p class="cloum_tit">动态热词设置：</p>
          <div class="cloum_con">
            <span
              >请输入热词，不同热词之间用
              <b style="color: red">中文；符号</b>
              分割，结尾不需要加；字符最多不超过300字</span
            >
            <el-input
              type="textarea"
              placeholder="请输入热词，不同热词之间用；分割，字符最多不超过300字"
              v-model="hotWord"
              maxlength="300"
              show-word-limit
            >
            </el-input>
            <el-button
              type="primary"
              @click="submitHotWord"
              v-if="userbtn.indexOf('WEBSIT_HOTWORD_SET') >= 0"
              >保存</el-button
            >
          </div>
        </div>
        <div class="cloum">
          <p class="cloum_tit">企业发布数据设置：</p>
          <div class="cloum_wrap">
            <div class="cloum_left">
              <div class="cloum_item">
                <p>开关：</p>
                <el-switch v-model="sendDetail.isOpen"> </el-switch>
              </div>
              <div
                class="cloum_item"
                v-for="(d, i) in sendDetail.data"
                :key="i"
              >
                <template>
                  <p v-if="d.isFixed">{{ d.name }}</p>
                  <el-input v-else v-model="d.name"></el-input>
                </template>
                <el-input v-model="d.val" @blur="frontChartBar" />
                <i
                  class="el-icon-circle-close"
                  v-if="!d.isFixed"
                  style="margin-left: 10px; font-size: 20px"
                  @click="delSendDetailItem(i)"
                ></i>
              </div>
              <el-button type="primary" @click="addIndexItem"
                >+ 新增指标</el-button
              >
              <el-button
                type="primary"
                @click="submitSend"
                v-if="userbtn.indexOf('WEBSIT_DATA_SET') >= 0"
                >保存</el-button
              >
            </div>
          </div>
          <div class="ring_wrap">
            <chart-box :ref="'bottom1'" />
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import constant from 'constant'
import {
  carouselChartApi,
  getCarouselChart,
  enterpriseDynamics,
  enterpriseDynamicsEditApi,
  dynamicHotWordsEditApi,
  dynamicHotWordsApi,
  enterpriseReleaseDataEditApi,
  enterpriseReleaseData
} from 'api/websit'
import { sendChart, frontChartBarData } from '@/util/js/homeCharts'

export default {
  name: 'websiteMange',
  data() {
    return {
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      carouselList: [],
      carouselIdList: [],
      fileList: [],
      categoryText: '',
      hotWord: '',
      sendDetail: {
        isOpen: false,
        data: [
          {
            isFixed: true,
            name: '发布量：',
            value: ''
          },
          {
            isFixed: true,
            name: '渠道量',
            value: ''
          },
          {
            isFixed: true,
            name: '企业量 ',
            value: ''
          }
        ]
      }
    }
  },
  // created() {
  //   this.initData()
  // },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      // 企业动态
      enterpriseDynamics().then(res => {
        this.categoryText = res.result.join('；')
      })
      // 动态热词
      dynamicHotWordsApi().then(res => {
        this.hotWord = res.result.join('；')
      })
      // 获取企业发布数量设置
      enterpriseReleaseData().then(res => {
        this.sendDetail = { ...res.result }
        if (!this.sendDetail.isOpen) {
          this.sendDetail.isOpen = false
        }
        // this.frontChartBottom()
        this.frontChartBar()
      })
      //轮播图
      getCarouselChart().then(res => {
        res.result.map(item => {
          item.name = item.docRealName
          this.carouselIdList.push(item.id)
        })
        this.carouselList = res.result
        // this.carouselIdList = []
        // this.fileList = res.result
      })
    },
    submitSend() {
      enterpriseReleaseDataEditApi({ ...this.sendDetail }).then(res => {
        if (res.code == 200) {
          this.$message.success('企业发布数量设置成功！')
        }
      })
    },
    submitCategory() {
      let arr = this.categoryText.split('；')
      enterpriseDynamicsEditApi(arr).then(res => {
        if (res.code == 200) {
          this.$message.success('企业动态设置成功！')
        }
      })
    },
    submitHotWord() {
      let arr = this.hotWord.split('；')
      dynamicHotWordsEditApi(arr).then(res => {
        if (res.code == 200) {
          this.$message.success('动态热词设置成功！')
        }
      })
    },
    submitCarouse() {
      carouselChartApi({ list: this.carouselIdList }).then(res => {
        if (res.code == 200) {
          this.$message.success('轮播图设置成功！')
        }
      })
    },
    uploadChange(file, fileList) {
      // this.fileList = fileList
    },
    uploadReturn(res) {
      if (res.code === 200) {
        this.carouselList.push(res.result)
        this.carouselIdList.push(res.result.id)
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    },
    handleExceed() {
      this.$message.warning(`当前限制上传 10 张图片`)
    },
    companyUploadRemove(file) {
      function splitIndex(arr) {
        arr.map((res, ind) => {
          if (res.id == file.id) {
            arr.splice(ind, 1)
          } else if (res == file.id) {
            arr.splice(ind, 1)
          }
        })
        return arr
      }
      this.carouselList = splitIndex(this.carouselList)
      // console.log(this.carouselList)
      this.carouselIdList = splitIndex(this.carouselIdList)
      // console.log(this.carouselIdList)
      // this.fileList = this.fileList.filter(item => {
      //   return item.url != file.url
      // })
      // let arr = []
      // let arrList = []
      // fileList.forEach(item => {
      //   if (item.response) {
      //     arr.push(item.response.result.id)
      //     arrList.push(item.response.result)
      //   } else {
      //     arr.push(item.id)
      //     arrList.push(item)
      //   }
      // })
      // this.carouselIdList = arr
      // this.carouselList = arrList
      // console.log(this.carouselIdList, '|||||', this.carouselList)
    },
    frontChartBottom() {
      this.sendDetail.channelNum = Number(this.sendDetail.channelNum)
      this.sendDetail.articleNum = Number(this.sendDetail.articleNum)
      this.sendDetail.companyNum = Number(this.sendDetail.companyNum)
      let allNum =
        this.sendDetail.channelNum +
        this.sendDetail.articleNum +
        this.sendDetail.companyNum
      sendChart.series[0].data[0].value = Math.ceil(
        (this.sendDetail.articleNum / allNum) * 100
      )
      sendChart.series[0].data[1].value = Math.ceil(
        (this.sendDetail.channelNum / allNum) * 100
      )
      sendChart.series[0].data[2].value = Math.ceil(
        (this.sendDetail.companyNum / allNum) * 100
      )
      this.$refs.bottom1.draw(sendChart)
    },
    frontChartBar() {
      const chartData = []
      this.sendDetail.data.map(res => {
        chartData.push([Number(res.val), res.name])
      })
      frontChartBarData.dataset.source = chartData
      this.$refs.bottom1.draw(frontChartBarData)
    },
    //新增指标
    addIndexItem() {
      if (this.sendDetail.data.length >= 8) {
        this.$message.error('总指标不得超过八个')
      } else {
        this.sendDetail.data.push({
          name: '',
          val: null,
          isFixed: false
        })
      }
    },
    delSendDetailItem(index) {
      this.sendDetail.data.splice(index, 1)
    }
  },
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
    .cloum {
      width: 1300px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      .cloum_tit {
        width: 144px;
      }
      .cloum_con {
        width: 600px;
        span {
          display: block;
          margin-bottom: 10px;
        }
      }
      .cloum_wrap {
        width: 400px;
        .cloum_item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          p {
            width: 100px;
            text-align: left;
          }
        }
      }
    }
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
  .carousel_warps {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    .carousel_item {
      margin-right: 15px;
      margin-bottom: 10px;
      position: relative;
      text-align: center;
      .close_icon {
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 20px;
        cursor: pointer;
      }
      img {
        height: 100px;
      }
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
  .el-button {
    margin-top: 10px;
  }
  .ring_wrap {
    width: 500px;
    height: 400px;
    display: flex;
    overflow: hidden;
    margin-top: 20px;

    .ring_item {
      text-align: center;
      width: 33%;
    }
  }
}
</style>
