<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
      <h1>列表编辑</h1>
      <el-tabs v-model="typeNumber" @tab-click="handleClick">
        <el-tab-pane label="合作媒体" name="10"></el-tab-pane>
        <el-tab-pane label="合作客户" name="20"></el-tab-pane>
        <el-tab-pane label="战略伙伴" name="30"></el-tab-pane>
      </el-tabs>
    </div>
    <el-card class="box-card">
      <el-row class="text-left">
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
        >
          <el-col :span="8">
            <el-form-item label="名称" class="limitinputWidth">
              <el-input
                placeholder="请选择"
                clearable
                v-model="pageMsg.channelName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1"> <p style="opacity: 0"></p> </el-col>
          <el-col :span="8">
            <el-form-item label="格式" class="limitinputWidth">
              <el-select
                v-model="pageMsg.companyId"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="7" class="text-right">
            <el-button type="primary" size="small" @click="searchData(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData(false)">
              重置
            </el-button>
          </el-col>
        </el-form>
      </el-row>
      <el-row style="margin-bottom: 20px">
        <el-button
          type="primary"
          @click="passItemFalse"
          v-if="userbtn.indexOf('WEBSIT_PARTNER_PIC') >= 0"
          >上传图片</el-button
        >
      </el-row>
      <div class="select_warn" v-if="selectionArr.length > 0">
        已经选择
        <span>{{ selectionArr.length }}</span>
        项
        <el-button
          type="text"
          @click="passItemTrue(selectionArr)"
          v-if="userbtn.indexOf('WEBSIT_PARTNER_MOREDEL') >= 0"
          >删除</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        @selection-change="handleSelectionChange"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>

        <el-table-column
          prop="articleTitle"
          label="缩略图"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <img :src="row.docUrl" alt="" width="100px" height="100px" />
          </template>
        </el-table-column>
        <el-table-column
          prop="classifyName"
          label="类型"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="docName"
          label="图片名称"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="docType"
          label="格式"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="上传时间"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="上传时间"
          label="提交时间"
          sortable
          header-align="center"
          align="center"
          width="250"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.updatedTime) }}
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="passItemTrue(row)"
              v-if="userbtn.indexOf('WEBSIT_PARTNER_DEL') >= 0"
            >
              删除
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.native.prevent="editOpen(row)"
              v-if="userbtn.indexOf('WEBSIT_PARTNER_EDIT') >= 0"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="编辑图片"
      @close="editClose"
      :visible.sync="editDialog"
    >
      <el-form
        :model="ruleForm"
        ref="formName"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="产品名称">
          <el-input v-model="editMessage.docName"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <img :src="editMessage.docUrl" alt="" width="100" height="100" />
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="editMessage.classifyName"></el-input>
        </el-form-item>
        <!-- accept="image/jpeg,image/jpg" -->
        <el-upload
          class="upload-demo"
          drag
          :action="uploadUrl"
          :headers="myHeaders"
          :on-success="editReturn"
          :before-upload="beforeAvatarUpload"
          multiple
          :show-file-list="false"
        >
          <!-- :on-change="editChange" -->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">点击或将文件拖到这里上传</div>
          <div class="el-upload__tip" slot="tip">
            支持规格：jpg、png格式，文件最大5M，支持宽150px,高136px
          </div>
        </el-upload>
        <div class="dialog-footer text-right">
          <el-button size="small" @click="editClose">取 消</el-button>
          <el-button size="small" type="primary" @click="editSubmit('formName')"
            >确 定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- 本地添加图片 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="本地上传"
      @close="handleClose"
      :visible.sync="passDialog"
    >
      <el-form :model="ruleForm" ref="formName">
        <el-form-item>
          <!-- accept="image/jpeg,image/jpg" -->
          <el-upload
            class="upload-demo"
            drag
            :action="uploadUrl"
            :headers="myHeaders"
            :on-change="uploadChange"
            :on-success="uploadReturn"
            :before-upload="beforeAvatarUpload"
            :on-exceed="handleExceed"
            :limit="20"
            :on-remove="companyUploadRemove"
            multiple
            :file-list="ruleForm.picList"
            :show-file-list="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击或将文件拖到这里上传</div>
            <div class="el-upload__tip" slot="tip">
              支持规格：jpg、png格式，文件最大5M，支持宽150px,高136px,限制最多不超过20张
            </div>
          </el-upload>
        </el-form-item>
        <div class="pic_wrap">
          <draggable
            v-model="ruleForm.picList"
            filter=".id"
            animation="300"
            :move="onMove"
            style="display: flex"
          >
            <div v-for="d in ruleForm.picList" :key="d.result.id">
              <img :src="d.result.url" alt="" width="100px" height="100px;" />
            </div>
          </draggable>
        </div>
        <div style="margin-bottom: 10px; text-align: center">
          已成功上传 {{ ruleForm.picList.length }} 张图片，拖到可调整顺序
        </div>
        <div style="display: flex; align-items: center; height: 100px">
          <span>类型：</span>
          <el-input
            style="width: 80%"
            v-model="ruleForm.classifyName"
            placeholder="请输入类型"
          ></el-input>
        </div>
        <!-- <div style="margin-bottom: 30px"></div> -->
        <div class="dialog-footer text-right">
          <el-button size="small" @click="handleClose">取 消</el-button>
          <el-button size="small" type="primary" @click="addPic('formName')"
            >确 定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import constant from 'constant'

import draggable from 'vuedraggable'
import {
  cooperativePartnerPageApi,
  cooperativePartnerAddApi,
  cooperativePartnerDelApi,
  cooperativePartnerUpdApi
} from 'api/websit'
export default {
  name: 'partner',
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    }
  },

  data() {
    return {
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {
              Token: JSON.parse(localStorage.getItem('adminToken')) || ''
      },
      fileList: [],
      ruleForm: {
        picList: [],
        classifyName: ''
      },
      rules: {
        picList: [{ required: true, message: '请添加图片' }]
      },
      passDialog: false,
      companyOptions: [],
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10,
        descs: ['created_time'],
        docName: '',
        docType: '',
        type: 10
      },
      typeNumber: '10',
      tableLoading: false,
      editDialog: false,
      editMessage: {}
    }
  },
  components: { draggable },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      cooperativePartnerPageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    //关闭添加弹窗
    handleClose() {
      this.ruleForm.picList = []
      this.ruleForm.classifyName = ''
      this.$refs.formName.resetFields()
      this.passDialog = false
    },
    //打开添加弹窗
    passItemFalse() {
      this.passDialog = true
    },
    //查询
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    //添加图片 确定
    async addPic() {
      // if (this.ruleForm.picList.length <= 0) {
      //   this.$message.error('请添加图片')
      // } else if (this.ruleForm.classifyName == '') {
      //   this.$message.error('请填写所属类型')
      // } else {
      //   let docList = []
      //   this.ruleForm.picList.map(res => {
      //     docList.push({
      //       docId: res.result.id,
      //       docName: res.result.docRealName,
      //       docType: res.result.type
      //     })
      //   })
      //   cooperativePartnerAddApi({
      //     type: this.typeNumber,
      //     docList,
      //     classifyName: this.ruleForm.classifyName
      //   }).then(response => {
      //     if (response.code == 200) {
      //       this.initData()
      //       this.$message.success('添加成功')
      //       this.handleClose()
      //     }
      //   })
      // }
      const { picList, classifyName } = this.ruleForm

      if (picList.length <= 0) {
        this.$message.error('请添加图片')
        return
      }

      if (classifyName.trim() === '') {
        this.$message.error('请填写所属类型')
        return
      }

      const docList = picList.map(({ result }) => ({
        docId: result.id,
        docName: result.docRealName,
        docType: result.type
      }))

      try {
        const response = await cooperativePartnerAddApi({
          type: this.typeNumber,
          docList,
          classifyName
        })

        if (response.code === 200) {

          this.initData()
          this.$message.success('添加成功')
          this.handleClose()
        }
      } catch (error) {
        // 错误处理
      }
    },
    onMove(e) {
      console.log(e)
    },
    //列表多选
    handleSelectionChange(e) {
      console.log(e)
      this.selectionArr = e
    },
    //批量添加图片
    uploadChange(file, fileList) {
      this.fileList = fileList
      console.log(this.fileList)
    },
    uploadReturn(res) {
      if (res.code === 200) {
        console.log(res)
        this.ruleForm.picList.push(res)
        // this.ruleForm.logoDocId = res.result.id
        // this.$refs.ruleForm.validateField('logoDocId')
      }
    },
    beforeAvatarUpload(file) {
      // const isJPG =
      //   file.type === 'image/jpg' ||
      //   file.type === 'image/jpeg' ||
      //   file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5

      // if (!isJPG) {
      //   this.$message({
      //     message: '图片只支持上传jpg,jpeg格式!',
      //     type: 'error'
      //   })
      // }
      if (!isLt5M) {
        this.$message({
          message: '图片大小不能超过 5M!',
          type: 'error'
        })
      }
      return isLt5M
    },
    handleExceed() {
      this.$message.warning(`当前限制上传 20 张图片`)
    },
    companyUploadRemove(file) {
      // this.ruleForm.logoDocId = ''
      // this.$refs.ruleForm.validateField('logoDocId')
      this.fileList = this.fileList.filter(item => {
        return item.url != file.url
      })
    },
    // 删除 列表的
    passItemTrue(row) {
      let arr = []
      if (row.id) {
        arr = [row.id]
      } else {
        row.map(res => {
          arr.push(res.id)
        })
      }
      cooperativePartnerDelApi({
        list: arr
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.initData()
        }
      })
    },
    handleClick() {
      this.pageMsg.type = this.typeNumber
      this.initData()
    },
    //编辑
    editOpen(row) {
      console.log(row)
      this.editMessage = { ...row }
      this.editDialog = true
    },
    //关闭编辑弹窗
    editClose() {
      this.editMessage = {}
      this.editDialog = false
    },
    editSubmit() {
      cooperativePartnerUpdApi({ ...this.editMessage }).then(res => {
        if (res.code == 200) {
          this.initData()
          this.$message.success('更新成功')
          this.editClose()
        }
      })
    },
    //
    editReturn(res) {
      if (res.code === 200) {
        this.editMessage.docName = res.result.docRealName
        this.editMessage.docType = res.result.type
        this.editMessage.docId = res.result.id
        this.editMessage.docUrl = res.result.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  /deep/.el-upload {
    display: block;
    margin: 0 auto;
    .el-upload-dragger {
      margin: 0 auto;
    }
  }
  /deep/.el-upload__tip {
    text-align: center;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
    padding: 0;
  }
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
  .pic_wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    img {
      margin-right: 10px;
    }
  }
}
</style>
