<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <div>
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px"
        >
          <el-row>

            <el-col :span="7">
              <el-form-item label="状态:">
                <el-select v-model="pageMsg.stockState"   placeholder="请选择账户名称"    style="width: auto">
                  <el-option
                    v-for="item in basicStateOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

<!--            <el-col :span="7">-->
<!--              <el-form-item label="关联人员:">-->
<!--                <el-select v-model="pageMsg.marketId"   placeholder="请选择账户名称"    style="width: auto">-->
<!--                  <el-option-->
<!--                    v-for="item in optionUserId"-->
<!--                    :key="item.id"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

            <el-col :span='7'>
              <el-form-item label='请选择:'>
                <el-radio-group v-model="pageMsg.radio">
                  <el-radio :label="0">辖区</el-radio>
                  <el-radio :label="1">行业</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span='6'>
              <el-button type='primary' size='small' @click='select'>查询</el-button>
              <el-button size='small' @click='reset'>重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div>
        <div id='dataChat' style="width: 1000px; height: 700px;"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getRelevanceUserApi,queryRelevanceSecBureauApi,queryRelevanceIndustryApi } from 'api/article'

import * as echarts from 'echarts'

export default {
  data(){
    return{
      pageMsg:{
        radio:0,
        stockState:"2",
        marketId:null,
        industryArr:null,
        secBureau:null,
      },
      secBureauShow:false,
      industryShow:false,
      optionUserId:[],
      industryOptions:[],
      secBureauOptions:[],
      basicStateOptions:[
        {
          value: '2',
          label: '签约'
        },
        // {
        //   value: '3',
        //   label: '签约中'
        // },
        {
          value: '1',
          label: '试用'
        },
      ]
    }
  },
  created() {
  },
  mounted() {
    this.select();
    // this.initRoles();
  },
  methods:{
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
    },
    select(){
      if (this.pageMsg.stockState==null){
        this.$message.error("请选择状态");
        return;
      }

      // if (this.pageMsg.marketId==null){
      //   this.$message.error("请选择关联人员");
      //   return;
      // }
      if (this.pageMsg.radio == 0){
        this.renderingCharts(false)
        //证监局
        // this.renderingSecBureauCharts();
      }else {
        this.renderingCharts(true)
        // this.renderingIndustryCharts();
      }
    },
    initRoles() {
      getRelevanceUserApi().then(res => {
        const data = res.result;
        data.forEach(item=>{
          this.optionUserId.push({
            label:item.marketName,
            value:item.id
          })
        })
      })
    },
    renderingCharts(bool){
      if (bool){
        queryRelevanceIndustryApi(this.pageMsg).then(res => {
          const data = res.result;
          var legendData = data.map(item => item.industry || "未知");
          var seriesData = data.map(item => ({
            name: item.industry || "未知",
            value: item.count || 0
          }));
          var dataOptions = {
            title: {
              text: '销售关联行业',
              // subtext: '纯属虚构',
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              right: 10,
              top: 20,
              bottom: 20,
              data: legendData
              // data: data.map(item => item.industry || "未知")  // 直接使用 data.map 获取 legendData
            },
            series: [
              {
                name: "",
                type: 'pie',
                radius: '55%',
                center: ['40%', '50%'],
                // data: data.map(item => ({
                //   name: item.industry || "未知",
                //   value: item.count || 0
                // })),
                data: seriesData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          var chartDom = document.getElementById('dataChat');
          var myChart = echarts.init(chartDom);
          myChart.setOption(dataOptions);
        })
      }else {
        queryRelevanceSecBureauApi(this.pageMsg).then(res => {
          const data = res.result;
          var legendData = data.map(item => item.secBureau || "未知");
          var seriesData = data.map(item => ({
            name: item.secBureau || "未知",
            value: item.count || 0
          }));
          var dataOptions = {
            title: {
              text: '销售关联辖区',
              // subtext: '纯属虚构',
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              right: 10,
              top: 20,
              bottom: 20,
              data: legendData
            },
            series: [
              {
                name: "",
                type: 'pie',
                radius: '55%',
                center: ['40%', '50%'],
                data: seriesData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          var chartDom = document.getElementById('dataChat');
          var myChart = echarts.init(chartDom);
          myChart.setOption(dataOptions);
        })
      }
    },
    renderingIndustryCharts(){
      queryRelevanceIndustryApi(this.pageMsg).then(res => {
        const data = res.result;
        var legendData = data.map(item => item.industry || "未知");
        var seriesData = data.map(item => ({
          name: item.industry || "未知",
          value: item.count || 0
        }));

        var industryOptions = {
          title: {
            text: '销售关联行业',
            // subtext: '纯属虚构',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: legendData
          },
          series: [
            {
              type: 'pie',
              radius: '55%',
              center: ['40%', '50%'],
              data: seriesData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        this.industryShow = true;
        var chartDom = document.getElementById('industry');
        var myChart = echarts.init(chartDom);
        myChart.setOption(industryOptions);
      })
    },
    renderingSecBureauCharts(){
      queryRelevanceSecBureauApi(this.pageMsg).then(res => {
        const data = res.result;
        var legendData = data.map(item => item.secBureau || "未知");
        var seriesData = data.map(item => ({
          name: item.secBureau || "未知",
          value: item.count || 0
        }));
        var secBureauOptions = {
          title: {
            text: '销售关联辖区',
            // subtext: '纯属虚构',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: legendData
          },
          series: [
            {
              type: 'pie',
              radius: '55%',
              center: ['40%', '50%'],
              data: seriesData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        this.secBureauShow = true;
        var chartDom = document.getElementById('secBureau');
        var myChart = echarts.init(chartDom);
        myChart.setOption(secBureauOptions);
      })

    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
