<template>
  <div>
    <el-card class="box-card">
      <el-row class="text-left">
        <el-col class="text-right" style="padding: 10px 0">
          <el-button type="primary" size="small" @click.native.prevent="add()">
            新增
          </el-button>
        </el-col>
      </el-row>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="名称" header-> </el-table-column>
        <el-table-column prop="typeName" label="模块类型" header->
        </el-table-column>
        <el-table-column prop="icon" label="模块icon" header->
        </el-table-column>
        <el-table-column prop="code" label="模块组件" header->
        </el-table-column>
        <el-table-column label="操作" header- width="250" fixed="right">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="add(row)"
            >
              新增
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.native.prevent="edit(row)"
            >
              编辑
            </el-button>
            <!-- <el-button
              type="danger"
              size="small"
              @click.native.prevent=""
              >删除</el-button
            > -->
            <el-popconfirm
              title="确定删除该模块儿吗？"
              @confirm="del(row)"
              style="margin-left: 10px"
            >
              <el-button slot="reference" type="text" size="small" style="color: red">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      width="30%"
      :visible.sync="sourceDetailVisible"
      @close="sourceDialogClose"
      :close-on-click-modal="false"
    >
      <div slot="title" class="text-left">{{ sourceDetailOperate }}</div>
      <el-form
        ref="sourceDetail"
        :model="sourceFormDetail"
        :rules="sourceFormRules"
        label-width="100px"
      >
        <el-form-item label="所属模块：" prop="parentId">
          <el-cascader
            v-if="sourceDetailVisible"
            :disabled="isunderModule || tableData.length <= 0"
            v-model="sourceFormDetail.parentId"
            :options="tableData"
            :props="tableDataMap"
            clearable
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="模块名称：" prop="name">
          <el-input clearable v-model="sourceFormDetail.name"></el-input>
        </el-form-item>
        <!-- 菜单试图：模块组件   操作：模块code -->
        <el-form-item
          :label="sourceFormDetail.type != 3 ? '模块组件：' : '模块code：'"
          prop="code"
        >
          <el-input clearable v-model="sourceFormDetail.code"></el-input>
        </el-form-item>
        <el-form-item label="模块类型：" prop="type">
          <el-select v-model="sourceFormDetail.type" style="width: 100%">
            <el-option
              v-for="item in moduleType"
              :key="parseInt(item.k)"
              :label="item.v"
              :value="parseInt(item.k)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模块路径：" prop="path">
          <el-input clearable v-model="sourceFormDetail.path"></el-input>
        </el-form-item>
        <el-form-item label="模块icon：" prop="icon">
          <el-input clearable v-model="sourceFormDetail.icon"></el-input>
        </el-form-item>
        <el-form-item label="模块备注：" prop="remarks">
          <el-input
            clearable
            type="textarea"
            v-model="sourceFormDetail.remarks"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button size="small" @click.native.prevent="sourceDetailVisible = false">
          取 消
        </el-button>
        <el-button size="small" type="primary" @click.native.prevent="saveMsg">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import { lookDic } from "api/common";
import store from '@/store'

import {
  getLoadModuleTree,
  saveModuleMsg,
  updateModuleMsg,
  delModule
} from 'api/rightsetting'
export default {
  name: 'sourcelist',
  data() {
    return {
      tableLoading: false,
      sourceDetailVisible: false,
      isunderModule: false,
      sourceDetailOperate: '新增模块',
      sourceFormDetail: {
        parentId: '',
        name: '',
        code: '',
        path: '',
        icon: '',
        type: '',
        remarks: ''
      },
      sourceFormRules: {
        name: [{ required: true, message: '模块名称不能为空', trigger: blur }],
        // code: [{ required: true, message: "模块code不能为空", trigger: blur }],
        type: [{ required: true, message: '模块类型不能为空', trigger: blur }]
        // path: [{ required: true, message: "模块路径不能为空", trigger: blur }],
      },
      tableData: [],
      tableDataMap: {
        value: 'id',
        label: 'name',
        checkStrictly: true
      },
      moduleType: []
    }
  },
  computed: {
    ...mapState('menu', ['menuAllOriginal']) //左侧菜单
  },
  mounted() {
    console.log('xxxxxxxxx');
    this.initData(1)
  },
  methods: {
    getAllData(type) {
      if (!type) {
        store.dispatch('menu/setMenuList', 'getAllData')
        sessionStorage.removeItem('treeExpanded')
        sessionStorage.removeItem('treeChecked')
      }
    },
    initData(type) {
      this.tableLoading = true
      this.getAllData(type)
      getLoadModuleTree().then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.tableData = res.result
          this.$nextTick(() => {
            // 默认展开管理配置一级
            document.getElementsByClassName('el-table__expand-icon')[0]?.click()
          })
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
      // 查询模块类型字典
      if (localStorage.getItem('DICTIONARY')) {
        this.moduleType = JSON.parse(localStorage.getItem('DICTIONARY'))[
          'module_type_enum'
        ]
      }
    },
    add(data = '') {
      this.sourceDetailVisible = true
      this.sourceDetailOperate = '新增模块'
      // 判断是否是模块下新增
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!data) {
        this.isunderModule = true
        this.sourceFormDetail.parentId = data.id
      } else {
        this.isunderModule = false
      }
    },
    saveMsg() {
      this.$refs.sourceDetail.validate(valid => {
        if (valid) {
          let sendData = { ...this.sourceFormDetail }
          if (Array.isArray(sendData.parentId)) {
            sendData.parentId = sendData.parentId[sendData.parentId.length - 1]
          }
          if (sendData.id) {
            updateModuleMsg(sendData).then(res => {
              this.sourceDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  message: '更新成功',
                  type: 'success'
                })
                this.initData()
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          } else {
            saveModuleMsg(sendData).then(res => {
              this.sourceDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.initData()
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    edit(data = {}) {
      this.isunderModule = true
      this.sourceFormDetail = { ...data }
      this.sourceDetailOperate = '编辑模块'
      this.sourceDetailVisible = true
    },
    del(data = {}) {
      delModule(data.id).then(res => {
        if (res.code === 200) {
          this.initData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },
    sourceDialogClose() {
      this.sourceFormDetail = { parentId: '', name: '', code: '', type: '' }
      this.$refs.sourceDetail.resetFields()
    },
    logoUploadReturn(res) {
      if (res.code === 200) {
        this.formData.logo = res.result.url
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    },
    logoUploadChange(file, fileList) {
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__header {
  border-bottom: solid 1px #eee !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
