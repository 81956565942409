var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            { ref: "formData", attrs: { model: _vm.pageMsg, size: "small" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "日期:" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "picker-options": _vm.pickerOptions,
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              align: "right",
                            },
                            model: {
                              value: _vm.pageMsg.createdTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "createdTime", $$v)
                              },
                              expression: "pageMsg.createdTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入公司简称",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.getPinYin(_vm.pageMsg.stockName)
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchData(true)
                              },
                            },
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.pageMsg.sendType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "sendType", $$v)
                                },
                                expression: "pageMsg.sendType",
                              },
                            },
                            _vm._l(_vm.sendTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "margin-top": "10px",
              },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "taskType",
                  label: "任务类型",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockName",
                  label: "客户名称",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "articleTitle",
                  label: "文章",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "failReason",
                  label: "失败原因",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createdTime",
                  label: "发送时间",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                  formatter: _vm.formatData,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sendType",
                  label: "发送类型",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.sendType === 0
                          ? _c(
                              "p",
                              { staticStyle: { color: "green" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      disabled: "",
                                      plain: "",
                                    },
                                  },
                                  [_vm._v("成功")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        row.sendType === 1
                          ? _c(
                              "p",
                              { staticStyle: { color: "red" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkFailReason(
                                          row.failLogId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("失败")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "失败原因",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.failReasonLoading,
                  expression: "failReasonLoading",
                },
              ],
            },
            [
              _c(
                "p",
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(" 客户联系方式: "),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(" " + _vm._s(_vm.failReason.stockContact) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(" 错误类型: "),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(" " + _vm._s(_vm.failReason.errorType) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(" 失败原因: "),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(" " + _vm._s(_vm.failReason.errorReason) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(" 错误信息: "),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(" " + _vm._s(_vm.failReason.errorMsg) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(" 文章标题: "),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(" " + _vm._s(_vm.failReason.articleTitle) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(" 文件路径: "),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm._v(" " + _vm._s(_vm.failReason.filePath) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "span",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }