var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.pageMsg,
                size: "small",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "媒介名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "160px" },
                            attrs: { placeholder: "请填写媒介名称" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchData(true)
                              },
                            },
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "签约人:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "160px" },
                            attrs: { placeholder: "请填写签约人姓名" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchData(true)
                              },
                            },
                            model: {
                              value: _vm.pageMsg.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "userName", $$v)
                              },
                              expression: "pageMsg.userName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { "margin-left": "-10px" } },
                        [
                          _vm.userbtn.indexOf("MANAGE_CREATE") >= 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reject()
                                    },
                                  },
                                },
                                [_vm._v("新建")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchData(false)
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchData(true)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.downloadPattern },
                            },
                            [_vm._v(" 导出 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "-75px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "dateType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.pageMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "dateType", $$v)
                                },
                                expression: "pageMsg.dateType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 2 },
                                },
                                [_vm._v("日")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 4 },
                                },
                                [_vm._v("区间")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 3 },
                                },
                                [_vm._v("季度")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 1 },
                                },
                                [_vm._v("月度")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.pageMsg.dateType === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "number" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "month",
                                  "value-format": "yyyy-MM",
                                  placeholder: "选择月",
                                },
                                model: {
                                  value: _vm.pageMsg.monthYD,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "monthYD", $$v)
                                  },
                                  expression: "pageMsg.monthYD",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pageMsg.dateType === 3
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "number" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择第几季度" },
                                  model: {
                                    value: _vm.pageMsg.number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pageMsg, "number", $$v)
                                    },
                                    expression: "pageMsg.number",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: { label: "第一季度", value: "1" },
                                  }),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: { label: "第二季度", value: "2" },
                                  }),
                                  _c("el-option", {
                                    key: "3",
                                    attrs: { label: "第三季度", value: "3" },
                                  }),
                                  _c("el-option", {
                                    key: "4",
                                    attrs: { label: "第四季度", value: "4" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pageMsg.dateType === 2
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "number" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: _vm.pageMsg.day,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "day", $$v)
                                  },
                                  expression: "pageMsg.day",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pageMsg.dateType === 4
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "number" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "date",
                                  clearable: "",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "开始日期",
                                },
                                model: {
                                  value: _vm.pageMsg.startMonths,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "startMonths", $$v)
                                  },
                                  expression: "pageMsg.startMonths",
                                },
                              }),
                              _c("el-date-picker", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  type: "date",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "结束日期",
                                },
                                model: {
                                  value: _vm.pageMsg.endMonths,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "endMonths", $$v)
                                  },
                                  expression: "pageMsg.endMonths",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "mediaName",
                  label: "媒介名称",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.Detail(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.mediaName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mediaType",
                  label: "媒介类型",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mediaWay",
                  label: "洽谈方式",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mediaManager",
                  label: "媒介签约人",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "mediaMoney",
                  label: "奖金",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mediaMoneyType",
                  label: "图片类型",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "auditDate",
                  label: "审核日期",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "startEndDate",
                  label: "签约日期",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.userbtn.indexOf("MANAGE_DELETE") >= 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.basicJobDeleteOne(row.id)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "新增媒介签约信息",
            width: "800px",
            visible: _vm.passLoading1,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading1 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel",
                  attrs: {
                    rules: _vm.userFormRules,
                    model: _vm.channelForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒介名称:", prop: "mediaName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaName", $$v)
                          },
                          expression: "channelForm.mediaName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒介类型:", prop: "mediaType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: { placeholder: "请选择媒介类型" },
                          model: {
                            value: _vm.channelForm.mediaType,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm, "mediaType", $$v)
                            },
                            expression: "channelForm.mediaType",
                          },
                        },
                        _vm._l(_vm.optionType, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒介签约人:", prop: "mediaManager" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请填写媒介签约人" },
                        model: {
                          value: _vm.channelForm.mediaManager,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaManager", $$v)
                          },
                          expression: "channelForm.mediaManager",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核时间:", prop: "auditDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "审核时间",
                        },
                        model: {
                          value: _vm.channelForm.auditDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "auditDate", $$v)
                          },
                          expression: "channelForm.auditDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约开始日期:", prop: "startDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "签约开始日期",
                        },
                        model: {
                          value: _vm.channelForm.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "startDate", $$v)
                          },
                          expression: "channelForm.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约结束日期:", prop: "endDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "签约结束日期",
                        },
                        model: {
                          value: _vm.channelForm.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "endDate", $$v)
                          },
                          expression: "channelForm.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "考核类型:", prop: "checkedCities" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.channelForm.checkedCities,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm, "checkedCities", $$v)
                            },
                            expression: "channelForm.checkedCities",
                          },
                        },
                        _vm._l(_vm.cities, function (city) {
                          return _c(
                            "el-checkbox",
                            { key: city, attrs: { label: city } },
                            [_vm._v(_vm._s(city))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约描述:", prop: "mediaContent" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入内容",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.channelForm.mediaContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaContent", $$v)
                          },
                          expression: "channelForm.mediaContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "comImage",
                      attrs: { label: "上传图片：", prop: "faceDocId" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadUrl,
                            headers: _vm.myHeaders,
                            "list-type": "picture-card",
                            accept: "image/jpeg,image/jpg,image/png",
                            "on-change": _vm.uploadChange,
                            "on-success": _vm.uploadReturn,
                            "on-preview": _vm.handlePictureCardPreview,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-exceed": _vm.handleExceed,
                            "on-remove": _vm.companyUploadRemove,
                            "file-list": _vm.fileList,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.channelAdd },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "查看媒介签约信息",
            width: "800px",
            visible: _vm.passLoading2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading2 = $event
            },
            close: _vm.dialogCreate1,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel",
                  attrs: {
                    rules: _vm.userFormRules,
                    model: _vm.channelForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒介名称:", prop: "mediaName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaName", $$v)
                          },
                          expression: "channelForm.mediaName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒介类型:", prop: "mediaType" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaType,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaType", $$v)
                          },
                          expression: "channelForm.mediaType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "媒介签约人:", prop: "mediaManager" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaManager,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaManager", $$v)
                          },
                          expression: "channelForm.mediaManager",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "洽谈方式:", prop: "mediaWay" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaWay,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaWay", $$v)
                          },
                          expression: "channelForm.mediaWay",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖金:", prop: "mediaMoney" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaMoney", $$v)
                          },
                          expression: "channelForm.mediaMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "考核类型:", prop: "mediaMoneyType" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.mediaMoneyType,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaMoneyType", $$v)
                          },
                          expression: "channelForm.mediaMoneyType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约开始日期:", prop: "startDate" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "startDate", $$v)
                          },
                          expression: "channelForm.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约结束日期:", prop: "endDate" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写媒介名称" },
                        model: {
                          value: _vm.channelForm.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "endDate", $$v)
                          },
                          expression: "channelForm.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约描述:", prop: "mediaContent" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          type: "textarea",
                          placeholder: "请输入内容",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.channelForm.mediaContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "mediaContent", $$v)
                          },
                          expression: "channelForm.mediaContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "comImage",
                      attrs: { label: "上传合同图片：", prop: "faceDocId" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadUrl,
                            headers: _vm.myHeaders,
                            "list-type": "picture-card",
                            accept: "image/jpeg,image/jpg,image/png",
                            "on-change": _vm.uploadChange,
                            "on-success": _vm.uploadReturn,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-exceed": _vm.handleExceed,
                            "on-remove": _vm.companyUploadRemove,
                            "file-list": _vm.fileList,
                            disabled: "",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }