<template>
  <el-card class="box-card"  v-if="!isPublish" >
    <div class="channel-add">
      <el-form
        :rules="userFormRules"
        ref="addChannel"
        :model="channelForm"
        :label-position="labelPosition"
        :label-width="formLabelWidth"
      >
        <el-form-item label="媒介名称" prop="channelName">
          <el-input v-model="channelForm.channelName"></el-input>
        </el-form-item>
        <el-form-item label="终端类别" prop="terminalIdList">
          <el-select v-model="channelForm.terminalIdList" multiple>
            <el-option
              v-for="d in terminalIdList"
              :key="d.id"
              :label="d.data"
              :value="d.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="媒介类型" prop="channelTypeName">
          <el-input v-model="channelForm.channelTypeName"></el-input>
        </el-form-item>

        <el-form-item label="媒介描述" prop="apiDescribe">
          <el-input
            type="textarea"
            autosize
            v-model="channelForm.apiDescribe"
          ></el-input>
        </el-form-item>
        <el-form-item label="月活跃量（MAU）" prop="monthlyActivity">
          <el-input v-model="channelForm.monthlyActivity"></el-input>
        </el-form-item>
        <el-form-item label="日活跃量（MAU）" prop="dayActivity">
          <el-input v-model="channelForm.dayActivity"></el-input>
        </el-form-item>
        <el-form-item label="下载量（MAU）" prop="downloadNum">
          <el-input v-model="channelForm.downloadNum"></el-input>
        </el-form-item>
        <el-form-item label="接口类别" prop="type">
          <el-select v-model="channelForm.type" :disabled="isEdit">
            <el-option
              v-for="d in apiCategoryList"
              :key="d.key"
              :label="d.key"
              :value="d.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="channelForm.type == 10">
          <el-form-item label="接口地址" prop="apiUrl">
            <el-input v-model="channelForm.apiUrl"></el-input>
          </el-form-item>
          <el-form-item label="权益次数" prop="infiniteFlag">
            <el-radio-group v-model="channelForm.infiniteFlag">
              <el-radio :label="0">无限制</el-radio>
              <el-radio :label="1">有限制</el-radio>
            </el-radio-group>
            <br />
            <el-input-number
              v-if="channelForm.infiniteFlag"
              v-model="channelForm.equityNumber"
              controls-position="right"
              @change="handleChange"
              :min="1"
              :max="9999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              autosize
              v-model="channelForm.remarks"
            ></el-input>
          </el-form-item>
        </template>

        <!--  -->
        <template v-if="channelForm.type == 20">
          <el-form-item label="限制频次" prop="withinTimeSecond">
            <div style="display: flex; justify-content: space-between">
              <el-input
                type="input"
                v-model="channelForm.withinTimeSecond"
                style="width: 40%"
              ></el-input>
              秒请求：
              <el-input
                type="input"
                style="width: 40%"
                v-model="channelForm.requestsNum"
              ></el-input>
              次
            </div>
          </el-form-item>
          <el-form-item label="API key" prop="apiKey">
            <el-input
              v-model="channelForm.apiKey"
              :disabled="isEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="API Secret" prop="apiSecret">
            <div style="display: flex; justify-content: space-between">
              <el-input
                style="width: calc(100% - 100px)"
                v-model="channelForm.apiSecret"
                :disabled="isEdit"
              ></el-input
              ><el-button type="primary" v-if="!isEdit" @click="getSecret"
                >点击获取</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="key和Secret有效期：" prop="dateValue">
            <el-date-picker
              class="change-date-form"
              v-model="channelForm.dateValue"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="dateChange"
              :picker-options="pickerOptions"
              :default-value="timeDefaultShow"
              style="width: 80%"
            >
            </el-date-picker>
          </el-form-item>
        </template>
        <el-form-item label='显示顺序'>
          <el-input v-model="channelForm.sequenceNum" placeholder="请输入显示顺序(默认优先)"></el-input>
        </el-form-item>
        <!--  -->

        <!-- <el-form-item label="接口权限" prop="apiRole">
          <el-input
            type="textarea"
            autosize
            v-model="channelForm.apiRole"
          ></el-input>
        </el-form-item> -->

        <!-- <el-form-item label="API key" prop="apiKey">
          <el-input v-model="channelForm.apiKey"></el-input>
        </el-form-item>
 
        <el-form-item label="目标预计覆盖人群" prop="coverage">
          <el-input-number
            v-model="channelForm.coverage"
            controls-position="right"
            @change="handleChange"
            :min="1"
            :max="9999"
          ></el-input-number>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button @click="channelClose">取 消</el-button>
        <el-button type="primary" @click="channelAdd">提 交</el-button>
      </div>
    </div>
  </el-card>
</template>
<script>
import {
  channelPageApi,
  channelAddApi,
  channelUpdApi,
  channelDetailApi,
  validTerminalApi
} from 'api/channelManagement'
import { md5Fun } from '@/util/js/tools.js'
export default {
  props: {
    ifUpdate: {
      type: Boolean,
      default: false
    }
    // apiDetailMsg: {
    //   type: Number,
    //   default: null
    // }
  },
  watch: {
    ifUpdate: {
      handler(bool) {
        if (bool) {
          // this.channelForm = { ...this.apiDetailMsg }
          console.log(this.apiDetailMsg)
          // channelDetailApi(this.apiDetailMsg).then(res => {
          //   console.log(res)
          //   res.result.terminalIdList = []
          //   res.result.terminalList.map(item1 => {
          //     res.result.terminalIdList.push(item1.id)
          //   })
          //   this.channelForm = { ...res.result }
          //   this.channelForm.infiniteFlag
          //     ? (this.channelForm.infiniteFlag = 1)
          //     : (this.channelForm.infiniteFlag = 0)

          //   console.log(this.channelForm)
          // })
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    const validateTime = (rule, value, callback) => {
      if (Number(value) > 86400) {
        callback(new Error('时间不能超过一天(86400秒)'))
      } else {
        if (this.channelForm.requestsNum == '') {
          callback(new Error('请求次数不能为空'))
        }
        callback()
      }
    }
    return {
      apiDetailMsg: null, //修改的id
      isEdit: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
        isPublish: false,
      channelForm: {
        sequenceNum:0,
          dayActivity: null,
          downloadNum: null,
        channelName: '', //媒介名称
        terminalIdList: [], //终端类型数组
        channelTypeName: '', //渠道类型
        codeExample: '', //代码实例
        remarks: '', //备注
        // coverage: '', //覆盖人群
        equityNumber: '', //权益次数
        infiniteFlag: 0, //限制状态 0:不限制，1:限制
        monthlyActivity: null, //月活量
        requestsNum: '', //请求次数(请求频次控制) [type = 20]
        termValidityEnd: '', //Key与Secret有效期结束 [type = 20]
        termValidityStart: '', //Key与Secret有效期开始 [type = 20]
        type: null, //渠道类型(10:推送，20:拉取)
        withinTimeSecond: '', //时间范围 单位:秒(请求频次控制) [type = 20]
        apiUrl: '', //接口地址
        apiDescribe: '', //接口描述
        // apiRole: '', //接口权限
        apiKey: '', //api Key
        apiSecret: '', //apiSecret
        dateValue: [] //时间组件
      },
      terminalIdList: [],
      userFormRules: {
        channelName: [{ required: true, message: '媒介名称不能为空' }],
        terminalIdList: [{ required: true, message: '终端类别不能为空' }],
        channelTypeName: [{ required: true, message: '媒介类别不能为空' }],
        apiUrl: [{ required: true, message: '接口地址不能为空' }],
        // apiRole: [{ required: true, message: '接口权限不能为空' }],
        apiKey: [{ required: true, message: 'API key不能为空' }],
        coverage: [{ required: true, message: '目标预计覆盖人群不能为空' }],
        infiniteFlag: [{ required: true, message: '权益次数不能为空' }],
        // codeExample: [{ required: true, message: '调用代码实例不能为空' }],
        apiDescribe: [{ required: true, message: '媒介描述不能为空' }],
        monthlyActivity: [
            { required: true,
                pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "请输入正确的数字格式数据",
                trigger: "blur"
            }],
          dayActivity: [  { required: true,
              pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
              message: "请输入正确的数字格式数据",
              trigger: "blur"
          }],
          downloadNum: [  { required: true,
              pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
              message: "请输入正确的数字格式数据",
              trigger: "blur"
          }],
        type: [{ required: true, message: '接口类别不能为空' }],
        withinTimeSecond: [
          { required: true, message: '限制频次不能为空', trigger: 'blur' },
          { validator: validateTime, trigger: 'blur' }
        ],
        apiSecret: [
          { required: true, message: 'API Secret不能为空', trigger: 'blur' }
        ],
        dateValue: [
          {
            required: true,
            message: 'key和Secret有效期不能为空',
            trigger: 'blur'
          }
        ]
      },
      formLabelWidth: '160px',
      labelPosition: 'right',
      paramsValue: {
        startTime: '',
        endTime: ''
      },
      timeDefaultShow: '',
      pickerOptions: {
        // disabledDate(time) {
        //   console.log(time,'time');
        //   return time.getTime() > Date.now()
        // }
      },
      apiCategoryList: [
        {
          key: '推送',
          value: 10
        },
        {
          key: '拉取',
          value: 20
        }
      ]
    }
  },
  created() {
    this.initData()
    this.timeDefaultShow = new Date()
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
    this.initEditData()
  },
  methods: {
    initEditData() {
      if (this.$route.query.id) {
        this.isEdit = true
        this.apiDetailMsg = this.$route.query.id
        channelDetailApi(this.apiDetailMsg).then(res => {
          console.log(res)
          res.result.terminalIdList = []
          res.result.terminalList.map(item1 => {
            res.result.terminalIdList.push(item1.id)
          })
          this.channelForm = { ...res.result }
          this.channelForm.infiniteFlag
            ? (this.channelForm.infiniteFlag = 1)
            : (this.channelForm.infiniteFlag = 0)
          function converTimeOfHMS(date) {
            var json_date = new Date(date).toJSON()
            return new Date(new Date(json_date) + 8 * 3600 * 1000)
              .toISOString()
              .replace(/T/g, ' ')
              .replace(/\.[\d]{3}Z/, '')
          }
          this.channelForm.termValidityStart = converTimeOfHMS(
            new Date(res.result.termValidityStart)
          )
          this.channelForm.termValidityEnd = converTimeOfHMS(
            new Date(res.result.termValidityEnd)
          )
          this.channelForm.dateValue = [
            res.result.termValidityStart,
            res.result.termValidityEnd
          ]
          delete this.channelForm.createdTime
          delete this.channelForm.state
          delete this.channelForm.terminalIdJoin
          delete this.channelForm.terminalList
          delete this.channelForm.updatedByUser
          delete this.channelForm.updatedTime
        })
      }
    },
    initData() {
      setTimeout(() => {
        this.$refs.addChannel.clearValidate()
      }, 100)
      validTerminalApi().then(({ result }) => {
        this.terminalIdList = result
      })
    },
    handleChange(e) {
      console.log(e)
    },
    channelClose() {

      this.channelForm = this.$options.data().channelForm

      this.$refs.addChannel.resetFields()
      this.$router.go(-1)
        this.$emit('closeAdd')
        console.log('66666')
    },
      goBack() {
          this.channelForm = this.$options.data().channelForm
          this.$router.go(-1)
      },

      channelAdd() {
      this.$refs.addChannel.validate(valid => {
        if (valid) {
          delete this.channelForm.dateValue
          delete this.channelForm.createdByUser
          if (this.isEdit) {
            channelUpdApi({
              ...this.channelForm,
              id: Number(this.apiDetailMsg)
            }).then(res => {
              if (res.code == 200) {
                 this.$emit('closeAdd')
                this.$message.success('接口修改成功')
                this.channelForm = this.$options.data().channelForm
                 this.isPublish = true
                this.goBack()
              }
            })
          } else {
            channelAddApi({ ...this.channelForm }).then(res => {
              if (res.code == 200) {

                this.$message.success('接口设置成功')
                  this.channelForm = this.$options.data().channelForm
                  //this.$router.push('/channelManagement')
                  this.isPublish = true
                  this.goBack()
              }
            })
          }
        }
      })
    },
    dateChange(e) {
      this.channelForm.termValidityStart = e[0] + ' 00:00:00'
      this.channelForm.termValidityEnd = e[1] + ' 23:59:59'
    },
    getSecret() {
      // function setSecret(num) {
      //   var count = 3000
      //   var originalArray = new Array() //原数组
      //   //给原数组originalArray赋值
      //   for (var i = 0; i < count; i++) {
      //     if (originalArray.length <= num) {
      //       originalArray[i] = i + 1
      //     }
      //   }
      //   originalArray.sort(function () {
      //     return 0.5 - Math.random()
      //   })
      //   return originalArray.join('').slice(0, num)
      // }
      let a = String(new Date().getTime())
      let b = String(new Date().getTime())
      this.channelForm.apiKey = md5Fun(a, 16)
      this.channelForm.apiSecret = md5Fun(b, 32)
    }
  }
}
</script>
<style lang="scss" scoped>
.box-card {
  width: calc(100% - 60px);
  padding: 10px;
  margin: 20px auto;
  .channel-add {
    width: 70%;
  }
}
</style>
