var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "channelManagement" },
      [
        _c("div", { staticClass: "channelManagement-head" }),
        _c("el-card", { staticClass: "box-card" }, [
          _c(
            "div",
            [
              _c("h2", [_vm._v("同业今日分发文章: ")]),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4, align: "center" } }, [
                        _c("span", { staticStyle: { "font-size": "18px" } }, [
                          _vm._v(" 公司名称: "),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 14, align: "center" } }, [
                        _c("span", { staticStyle: { "font-size": "18px" } }, [
                          _vm._v(" 文章标题: "),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 6, align: "center" } }, [
                        _c("span", { staticStyle: { "font-size": "18px" } }, [
                          _vm._v(" 发布时间: "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.articleList, function (item) {
                return _c(
                  "div",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4, align: "center" } },
                          [_c("el-tag", [_vm._v(_vm._s(item.stockName))])],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 14, align: "center" } },
                          [
                            _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(item.articleTitle)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6, align: "center" } },
                          [
                            _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v(_vm._s(item.createdTime)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c("div", { staticStyle: { "margin-top": "120px" } }, [
            _c("h2", [_vm._v(" 同业分发排名: ")]),
            _c("div", { staticStyle: { width: "700px" } }, [
              _c("div", {
                staticStyle: {
                  width: "500px",
                  height: "450px",
                  margin: "0px auto",
                },
                attrs: { id: "industry" },
              }),
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v(" 您公司当前在同行业中发布数量排名为第"),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.rankingNumber)),
                ]),
                _vm._v("名! "),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }