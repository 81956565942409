<template>
  <div class="channelManagement-wrap">
    <router-view></router-view>
    <div class="channelManagement" v-if="$route.path == '/channelManagement'">
      <div class="channelManagement-head">
        <h1>API设置</h1>
      </div>
      <el-card class="box-card">
        <el-row class="text-left" :gutter="20">
          <el-form
            :model="pageMsg"
            ref="formData"
            size="small"
            label-position="left"
          >
            <el-col :span="5">
              <el-form-item
                label="媒介："
                class="limitinputWidth"
                label-width="54px"
              >
                <el-input clearable v-model="pageMsg.channelName" placeholder="请输入" style="width: 150px"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="1"> <p style="opacity: 0"></p> </el-col> -->
            <el-col :span="5">
              <el-form-item
                label="状态："
                class="limitinputWidth"
                label-width="54px"
              >
                <el-select v-model="pageMsg.state" placeholder="请选择">
                  <el-option
                    v-for="item in stateOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item
                label="媒介类别："
                class="limitinputWidth"
                label-width="84px"
              >
                <el-select v-model="pageMsg.channelTypeId" placeholder="请选择">
                  <el-option
                    v-for="d in terminalIdList"
                    :key="d.id"
                    :label="d.name"
                    :value="d.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item
                label="类型："
                class="limitinputWidth"
                label-width="54px"
              > 
                <el-select v-model="pageMsg.type" placeholder="请选择">
                  <el-option
                    v-for="d in apiCategoryList"
                    :key="d.key"
                    :label="d.key"
                    :value="d.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--&lt;!&ndash;&ndash;&gt;-->
            <el-col :span="4">
              <el-form-item label="分配:"   label-width="54px" >
                <el-select v-model="pageMsg.userId" placeholder="请选择" style="width: 150px">
                  <el-option
                          v-for="item in optionUserId1"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>



          </el-form>
        </el-row>
        <el-row class="text-left" style="margin-bottom: 10px">
          <el-button
            type="primary"
            size="small"
            @click="$router.push('/channelManagement/add')"
            v-if="userbtn.indexOf('API_ADD') >= 0"
          >
            <!-- @click="dialogAdd = true" -->
            新建API
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="platformExport"
            v-if="userbtn.indexOf('API_EXPORT') >= 0"
          >
            导出列表
          </el-button>
          <el-button
                  type="primary"
                  size="small"
                  @click="plfp(pageMsg)"
                  v-if="userbtn.indexOf('MANAGE_MENT_PLFP') >= 0"
          >
         <!--   &lt;!&ndash;&ndash;&gt;-->
            批量分配
          </el-button>
          <el-button
                  type="primary"
                  size="small"
                  @click="pljc(pageMsg)"
                  v-if="userbtn.indexOf('MANAGE_MENT_PLJC') >= 0"
          >

            批量解除
          </el-button>
          <el-button type="primary" size="small" @click="searchData(true)">
            查询
          </el-button>
          <el-button size="small" @click="searchData(false)">
            重置
          </el-button>
          <el-button size='small' @click='sequenceClick' type='primary'>
            分配顺序
          </el-button>
          <el-button size='small' @click='sequenceChildrenClick' type='primary'>
            媒介小类排序
          </el-button>
          <el-button  size='small' @click='apiRelevanceClick' type='primary'>
            关联媒介渠道
          </el-button>
        </el-row>
        <div class="select_warn" v-if="selectionArr.length > 0">
          已经选择
          <span>{{ selectionArr.length }}</span>
          项
          <el-button
            type="text"
            @click="endList"
            v-if="userbtn.indexOf('API_MORE_HIDE') >= 0"
            >冻结</el-button
          >
        </div>
        <el-table
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="channelName"
            label="媒介名称"
            header-align="center"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              <div style="display: flex; align-items: center">
                <el-switch
                  v-model="row.statusVal"
                  @change="switchEvent($event, row)"
                  v-if="userbtn.indexOf('API_SWITCH_BTN') >= 0"
                >
                </el-switch>
                <p style="margin-left: 20px">{{ row.channelName }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="apiUrl"
            label="API地址"
            header-align="center"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="mediaManager"
            label="媒介经理"
            header-align="center"
            align="center">
          </el-table-column>

          <el-table-column
            prop="apiUrl"
            label="类型"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.type == 20 ? '拉取' : '推送' }}
            </template>
          </el-table-column>
          <el-table-column
            width="70"
            label="权益"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                @click="setDialog(row)"
                v-if="userbtn.indexOf('API_GOOD_SET') >= 0 && row.type == 10"
                >设置</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="key和Secret"
            width="110"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                @click="setKeyDialog(row)"
                v-if="userbtn.indexOf('API_GOOD_SET') >= 0 && row.type == 20"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="updatedTime"
            label="更新时间"
            sortable
            header-align="center"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              {{ formatDate(row.updatedTime) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="state"
            label="状态"
            header-align="center"
            align="center"
            width="60"
          >
            <template slot-scope="{ row }">
              {{ row.state == 10 ? '正常' : '关闭' }}
            </template>
          </el-table-column> -->
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="180"
            fixed="right"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click.native.prevent="lookdetail(row)"
                v-if="userbtn.indexOf('API_EDIT') >= 0"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                v-if="row.state == 10 && userbtn.indexOf('API_HIDE') >= 0"
                size="small"
                @click.native.prevent="addAndEditDialog(row)"
              >
                冻结
              </el-button>
              <el-button
                type="text"
                v-if="row.state == 20 && userbtn.indexOf('API_UP') >= 0"
                size="small"
                @click.native.prevent="noAndEditDialog(row)"
              >
                解冻
              </el-button>
              <el-button size="small"  v-if="row.show === 1
"   type="text"  >
                已关联
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </el-card>
      <!-- <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        title="媒介设置"
        :visible.sync="dialogAdd"
      >
        <add
          @closeAdd="closeAdd"
          :ifUpdate="ifUpdate"
          :apiDetailMsg="apiDetailMsg"
        />
      </el-dialog> -->
      <el-dialog
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="权益设置"
        :visible.sync="dialogSet"
      >
        <sets @closeAdd="closeAdd" :apiDetailMsg="apiDetailMsg" />
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="key和Secret"
        :visible.sync="dialogSetKeys"
      >
        <setkey @closeAdd="closeAdd" :apiDetailMsg="apiDetailMsg" />
      </el-dialog>


      <el-dialog
              width="800px"  :visible.sync="passDialog"  @close="dialogCreate2"
              title="选择分配人员"
      >

        <!--//<el-form :rules="rules" :model="ruleModel" ref="formName">-->
        <el-form :model="ruleModel" ref="formName">
          <el-form-item
                  label="分配用户:"

          >
            <el-select v-model="ruleModel.assignUserId" placeholder="请选择分配用户"    style="width: auto">
              <el-option
                      v-for="item in optionUserId"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <div style="margin-bottom: 30px"></div>
          <div class="dialog-footer">
            <el-button size="small" @click="handleClose2">取 消</el-button>
            <el-button
                    size="small"
                    type="primary"
                    v-if="canSubmit"
                    @click="addAndEditBanner2('formName')"
            >确 定</el-button
            >
          </div>
        </el-form>
      </el-dialog>
    </div>
<!--分配顺序-->
<!--    <el-dialog-->
<!--      title="媒介类型分配顺序"-->
<!--      :visible.sync="sequenceVisible"-->
<!--      width="30%"-->
<!--      :before-close="sequenceVisibleClose">-->
<!--      <el-form-->
<!--        v-for='item in pushChannelList'-->
<!--        :model="item"-->
<!--        ref="sequenceForm"-->
<!--        :rules="sequenceFormRules"-->
<!--        :label-position="labelPosition"-->
<!--        :label-width="formLabelWidth"-->
<!--      >-->
<!--        <el-form-item :label=item.channelTypeName prop="sequenceNum" >-->
<!--          <el-input v-model='item.sequenceNum'  style="width: 200px"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop='sequenceNum'>-->
<!--          <el-input></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="sequenceVisible = false">取 消</el-button>-->
<!--    <el-button type="primary" @click="submitClick">确 定</el-button>-->
<!--  </span>-->
<!--    </el-dialog>-->

    <el-dialog
      title="媒介类型分配顺序"
      :visible.sync="sequenceVisible"
      width="35%"
      :before-close="sequenceVisibleClose">
      <draggable v-model="pushChannelList"  chosenClass="chosen" forceFallback="true" group="people" animation="1000" @start="onStart" @end="onEnd">
        <transition-group>
          <div class="item" v-for="element in pushChannelList" :key="element.channelTypeId">
            <p style='width:70%;margin:0px auto;background-color: #bbe3ea;padding: 15px;border-radius: 25px;text-align: center;color: black;font-size: 20px'>
              {{element.channelTypeName}}
            </p>
          </div>
        </transition-group>
      </draggable>
      <div style='margin-top: 50px;text-align: center' >
        <el-button @click="sequenceVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitNewClick">确 定</el-button>
      </div>

    </el-dialog>



    <el-dialog
      title='请选择媒介类型'
      :visible.sync='selectChildrenVisible'
      width='20%'
      :before-close='selectChildrenVisibleClose'>

      <el-select v-model="selectChildValue" placeholder="请选择媒介类型">
        <el-option
          v-for="item in selectChildOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <div style='margin-top: 50px'>
      </div>
      <p style='text-align: center'>
        <el-button  size='small' @click='selectChildrenVisibleClose'>
          取消
        </el-button>
        <el-button type='primary' size='small' @click='openMediumSequence'>
          确定
        </el-button>
      </p>
    </el-dialog>


    <el-dialog
      title="媒介子类型分配顺序"
      :visible.sync="sequenceChildrenVisible"
      width="25%"
      :before-close="sequenceChildrenVisibleClose">
      <draggable v-model="pushChannelChildrenList"  chosenClass="chosen" forceFallback="true" group="people" animation="1000" @start="onStart" @end="onEnd">
        <transition-group>
          <div class="item" v-for="element in pushChannelChildrenList" :key="element.channelTypeId">
            <p style='width:70%;margin:0px auto;background-color: #bbe3ea;padding: 15px;border-radius: 25px;text-align: center;color: black;font-size: 20px'>
              {{element.channelTypeName}}
            </p>
          </div>
        </transition-group>
      </draggable>
      <div style='margin-top: 50px;text-align: center' >
        <el-button @click="sequenceChildrenVisible = false">取 消</el-button>
        <!--    <el-button type="primary" @click="submitClick">确 定</el-button>-->
        <el-button type="primary" @click="submitChildrenClick">确 定</el-button>
      </div>

    </el-dialog>

    <el-dialog
      title='关联媒介渠道'
      :visible.sync='apiRelevanceVisible'
      width='30%'
      :before-close='apiRelevanceVisibleClose'>
      <el-row>
        <el-col :span='12' align='center'>
          <el-button type="primary" @click="addRelevance">新建关系</el-button>
        </el-col>
        <el-col :span='12' align='center'>
          <el-button type="primary" @click="manageRelevance">管理关系</el-button>
        </el-col>
      </el-row>
    </el-dialog>


<!--    新建关系-->
    <el-dialog
      title='新建媒介关联'
      :visible.sync='addRelevanceVisible'
      width='30%'
      :before-close='addRelevanceVisibleClose'>

      <div>
        <el-form
          ref="addRelevanceRef"
          :rules="addRelevanceFormRules"
          :model="addRelevanceForm"
          label-position="left"
          label-width="150" >
          <el-form-item label="渠道名称:" prop="apiParentName">
            <el-input v-model="addRelevanceForm.apiParentName" placeholder="请输入渠道名称" style='width: 200px'></el-input>
          </el-form-item>
          <span>
            <el-button type='primary' @click="addRelevanceFormSubmit">确定</el-button>
            <el-button @click="addRelevanceVisibleClose">取消</el-button>
          </span>


        </el-form>
      </div>

    </el-dialog>


<!--    管理媒介关系-->
    <el-dialog
      title='管理媒介关系'
      :visible.sync='manageRelevanceVisible'
      width='80%'
      :before-close='manageRelevanceVisibleClose'>

      <el-form
        ref="manageRelevanceRef"
        :rules="manageRelevanceFormRules"
        :model="manageRelevanceForm"
        label-position="left"
        label-width="150" >

        <el-form-item label='请选择父级媒介名称:'>
          <el-select v-model="manageRelevanceForm.apiParentId" placeholder="请选择" @input='selectManageInput'>
            <el-option
              v-for="item in manageRelevanceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label='请选择子级媒介:' v-if='pushApiListShow'>
          <el-checkbox-group v-model="manageRelevanceForm.pushApiList">
            <div style="display: flex; flex-wrap: wrap;">
              <div v-for="(item, index) in pushApiAllList" :key="item.id" style="flex-basis: 20%; box-sizing: border-box; margin-bottom: 10px;">
                <el-checkbox :label="item.channelName"></el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </el-form-item>


        <span>
          <el-button type='primary' @click="manageRelevanceSubmit">确定</el-button>
          <el-button @click="manageRelevanceClose">取消</el-button>
        </span>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  channelUpdPLFPApi,
  channelUpdPLJCApi,
  channelPageApi,
  frozenApi,
  normalApi,
  platformExportApi,
  getValidTypeApi,
  getChannelInfoApi,
  upSequenceApi,
  upSequenceNewApi,
  getChannelInfoNewApi,
  getChannelChildrenByIdApi, upChildrenSequenceApi,
  addRelevanceApi,
  queryManageRelevanceApi,
  queryPushListApi,
  queryRelevancePushListApi,
  upPushApiRelevanceApi
} from 'api/channelManagement'
// import add from './add.vue'
import sets from './set.vue'
import setkey from './setkey.vue'
import {
    getUserList1
} from 'api/rightsetting'


import draggable from 'vuedraggable'

export default {
  name: 'channelManagement',
  // components: { add, sets, setkey },
  components: { sets, setkey,draggable },
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    }
  },
  watch: {
      $route: {
          handler(to) {
              // 初始化操作
              if (to.path === '/channelManagement') {
                  // this.pageMsg = this.$options.data().pageMsg
                  this.initData()
              }
          }
      },
    dialogAdd(bool) {
      if (!bool) {
        this.ifUpdate = false
        this.apiDetailMsg = null
      }
    },
    dialogSet(bool) {
      if (!bool) {
        this.apiDetailMsg = null
      }
    },
    dialogSetKeys(bool) {
      if (!bool) {
        this.apiDetailMsg = null
      }
    }
  },
  data() {
    return {
      manageRelevanceOptions:[],
      addRelevanceForm:{

      },
      manageRelevanceForm:{
        apiParentId:null,
        pushApiList:["复选框A"]
      },
      apiRelevanceVisible:false,
      addRelevanceVisible:false,
      manageRelevanceVisible:false,
      selectChildValue:null,
      selectChildOptions:[],
      pushChannelChildrenList:[
      ],
      linkData: [
        {name:'百度'},
        {name:'腾讯网'},
        {name:'新浪微博'},
        {name:'今日头条'},
        {name:'哔哩哔哩'}
      ],
      isNoull:false,
      pushChannelList:[],
      sequenceForm:[],
      addRelevanceFormRules: {
        apiParentName: [{required: true, message: '请输入渠道名称',trigger: 'blur'}],
      },
      manageRelevanceFormRules: {

      },
      sequenceFormRules: {
        sequenceNum: [{required: true, message: '请输入顺序',trigger: 'blur'}],
      },
      sequenceVisible:false,
      sequenceChildrenVisible:false,
      selectChildrenVisible:false,
        passDialog: false,
      dialogSet: false,
      dialogAdd: false, //媒介弹框
      dialogSetKeys: false, //key和secret弹框
      ifUpdate: false,
      apiDetailMsg: null,
      formLabelWidth: '30%',
      labelPosition: 'right',
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10,
        descs: ['created_time'],
        state: null,
        channelName: '',
          userId: '',
        channelTypeId: null,
        type:null,
          selectionArr: [],
          assignUserId:undefined,
      },
      terminalIdList: [],
      stateOption: [
        {
          label: '正常',
          value: 10
        },
        {
          label: '冻结',
          value: 20
        }
      ],
      apiCategoryList: [
        {
          key: '推送',
          value: 10
        },
        {
          key: '拉取',
          value: 20
        }
      ],
        ruleModel: {
            assignUserId: null,
            selectionArr: [],
            selection: false,
            selection1: true,
        },
      tableLoading: false,
        optionUserId: [],
        optionUserId1: [],
        oneDialog2: false,
        canSubmit: false,
      pushApiAllList:[],
      pushApiListShow:false
    }
  },
  mounted() {
    this.initData()
      this.initRoles()
  },
    created() {
        this.initData()
        this.initRoles()
    },
  methods: {
    manageRelevanceSubmit(){
      const data = {
        id:this.manageRelevanceForm.apiParentId,
        pushApiList:this.manageRelevanceForm.pushApiList
      }
      upPushApiRelevanceApi(data).then(res=>{
        if(res.code === 200){
          this.$message.success("关联成功");
          this.manageRelevanceVisibleClose();
        }
      })
    },
    manageRelevanceClose(){
      this.manageRelevanceVisibleClose()
    },
    selectManageInput(){
      this.queryPushListFun();
    },
    queryPushListFun(){
      if (this.pushApiList==null||this.pushApiList.length==0){
        queryPushListApi(this.manageRelevanceForm.apiParentId).then(res => {
          if(res.code === 200){
            this.pushApiAllList = res.result;
            this.pushApiListShow = true;
          }
        })
        queryRelevancePushListApi(this.manageRelevanceForm.apiParentId).then(res=>{
          if (res.code === 200){
            const data = res.result;
            const pushApiList = [];
            data.forEach(item =>{
              pushApiList.push(item.channelName);
            })
            this.manageRelevanceForm.pushApiList = pushApiList;
          }
        })
      }
    },
    addRelevanceFormSubmit(){
      this.$refs.addRelevanceRef.validate(valid => {
        if (valid){
          addRelevanceApi(this.addRelevanceForm).then(res=>{
            if(res.code === 200){
              this.$message.success("添加成功!")
            }
          })
          this.addRelevanceForm = this.$options.data().addRelevanceForm;
          this.addRelevanceVisible = false;
        }
      })
    },
    addRelevance(){
      this.addRelevanceVisible = true;
    },
    manageRelevance(){
      // this.manageRelevanceForm =  this.$options.data().manageRelevanceForm;
      queryManageRelevanceApi().then(res => {
        if (res.code ===200) {
          const data = res.result;
          this.manageRelevanceOptions = [];
          data.forEach(item => {
            this.manageRelevanceOptions.push({
              value: item.id,
              label: item.apiParentName
            })
          })
          this.manageRelevanceVisible = true;
        }
      })
    },
    apiRelevanceVisibleClose(){
      this.apiRelevanceVisible = false;
    },
    addRelevanceVisibleClose(){
      this.addRelevanceForm = this.$options.data().addRelevanceForm;
      this.addRelevanceVisible = false;
    },
    manageRelevanceVisibleClose(){
      this.manageRelevanceForm =  this.$options.data().manageRelevanceForm;
      this.pushApiListShow = false;
      this.manageRelevanceVisible = false;
    },
    apiRelevanceClick(){
      this.apiRelevanceVisible = true;
    },
    openMediumSequence(){
      getChannelChildrenByIdApi(this.selectChildValue).then(res => {
        if (res.code === 200){
          //打开小类型排序
          this.pushChannelChildrenList = res.result;
          this.selectChildrenVisible = false;
          this.sequenceChildrenVisible = true;
        }

        //清空
        this.selectChildValue = this.$options.data().selectChildValue;

      })
    },
    selectChildrenVisibleClose(){
      this.selectChildValue = this.$options.data().selectChildValue;
      this.selectChildrenVisible = false;
    },
    // 拖拽
    //开始拖拽事件
    submitChildrenClick(){
      upChildrenSequenceApi(this.pushChannelChildrenList).then(res => {
        this.$message.success("更新成功!")
        this.sequenceChildrenVisible = false;
        this.pushChannelChildrenList = this.$options.data().pushChannelChildrenList;
      })


    },
    onStart(){
      this.drag=true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag=false;
    },

    drag(event, index) {
      event.dataTransfer.setData('index', index);
    },
    drop(event, index) {
      event.preventDefault();
      let startIndex = parseInt(event.dataTransfer.getData('index'));
      let currentIndex = parseInt(index);
      if (startIndex - currentIndex > 0) {
        this.pushChannelList.splice(currentIndex, 0, this.pushChannelList[startIndex]);
        this.pushChannelList.splice(startIndex + 1, 1)

      } else if (startIndex - currentIndex < 0) {
        this.pushChannelList.splice(currentIndex + 1, 0, this.pushChannelList[startIndex]);
        this.pushChannelList.splice(startIndex, 1)
      } else {
      }
    },
    allowDrop(event) {
      event.preventDefault();
    },

    // 拖拽
    submitFun(){
      upSequenceApi(this.pushChannelList).then( res=> {
        this.$message.success("更新成功!")
        this.sequenceVisible = false;
        this.pushChannelList = this.$options.data().pushChannelList;
      })
    },
    isNullFun(){
      this.pushChannelList.forEach((item)=>{
        if (item.sequenceNum===""||item.sequenceNum===null){
          return false;
        }
      })
      return true;
    },
    // submitClick(){
    //   if(this.isNullFun()){
    //     this.submitFun();
    //   }else {
    //     this.$message.error("媒介顺序不能为空!")
    //   }
    // },
    submitNewClick(){
      upSequenceNewApi(this.pushChannelList).then(res=>{
        this.$message.success("更新成功!")
        this.sequenceVisible = false;
        this.pushChannelList = this.$options.data().pushChannelList;
      })
      this.initData();
    },

    async submitClick(){
      this.pushChannelList.forEach((item)=>{
        if (item.sequenceNum===""||item.sequenceNum===null){
          this.isNoull = true;
        }
      })
      if (this.isNoull){
        this.$message.error("媒介顺序不能为空!")
      }else {
        this.submitFun();
      }
      this.isNoull = false;
    },
    sequenceVisibleClose(){
      this.sequenceVisible = false;
    },
    sequenceChildrenVisibleClose(){
      this.sequenceChildrenVisible = false;
    },
    dialogCreate2(){
      this.passDialog = false
    },
    //分配顺序
    sequenceClick(){
      // this.pushChannelList = this.terminalIdList;
      //   this.sequenceVisible = true;
      getChannelInfoNewApi().then(res => {
        this.pushChannelList = res.result;
        this.sequenceVisible = true;
      })
    },
    sequenceChildrenClick(){
      //获取所有媒介类型
      getValidTypeApi().then(({ result }) => {
        this.selectChildOptions = result
        this.selectChildrenVisible = true;
      })
    },
      addAndEditBanner2(formName) {

          this.$refs[formName].validate(valid => {

              if (valid) {
                  this.canSubmit = false
                  if (this.oneDialog2) {
                      channelUpdPLFPApi({
                          ...this.ruleModel
                      }).then(res => {
                          if (res.code == 200) {
                              this.$message.success('分配成功')
                              this.initData()
                              this.passDialog = false
                              this.oneDialog2 = false
                              this.ruleModel.assignUserId = null
                          }
                      })
                  }
              } else {
                  return false
              }
          })
      },
      handleClose2() {
          this.ruleModel.assignUserId = null
          this.ruleModel.selectionArr = []
          this.$refs.formName.resetFields()
          this.passDialog = false
          this.oneDialog2 = false
      },
      initRoles() {
          getUserList1().then(res => {
              this.optionUserId = res.result.records
              this.optionUserId1 = res.result.records
          })
      },
      plfp(pageMsg) {
          if (this.ruleModel.selectionArr.length<= 0) {
              this.$message({
                  type: 'error',
                  message: '无任何选中项!'
              })
              return
          }
          if (this.ruleModel.selection === true) {
              this.$message({
                  type: 'error',
                  message: '该客户已分配'
              })
              return
          }
          this.passDialog = true
          this.oneDialog2 = true
          this.canSubmit  = true
      },
      pljc(pageMsg) {
          if (this.ruleModel.selectionArr.length<= 0) {
              this.$message({
                  type: 'error',
                  message: '无任何选中项!'
              })
              return
          }

          if (this.ruleModel.selection1 === false) {
              this.$message({
                  type: 'error',
                  message: '该客户未被分配!'
              });
              return;
          } else {
              pageMsg.selectionArr =this.ruleModel.selectionArr;
              this.$confirm('是否确认批量解绑', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
              }).then(function () {
                  return  channelUpdPLJCApi(pageMsg)
              }).then(data => {
                  this.$message.success('解绑成功')
                  this.initData()
              });
          }




      },
    platformExport() {
      platformExportApi({
        ...this.pageMsg
      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = new Date().toLocaleDateString()
        link.download = `API导出列表${dateXls}.csv`
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      channelPageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.tableData.map(tableres => {
            if (tableres.state == 20) {
              tableres.statusVal = false
            } else {
              tableres.statusVal = true
            }
          })
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
      getValidTypeApi().then(({ result }) => {
        this.terminalIdList = result
      })
    },
    switchEvent(bool, row) {
      if (bool) {
        //解冻
        this.noAndEditDialog(row)
      } else {
        // 冻结
        this.addAndEditDialog(row)
      }
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    setDialog(data) {
      this.apiDetailMsg = data.id
      this.dialogSet = true
    },
    setKeyDialog(data) {
      this.apiDetailMsg = data.id
      this.dialogSetKeys = true
    },
    lookdetail(data) {
      this.ifUpdate = true
      this.apiDetailMsg = data.id
      this.dialogAdd = true
      this.$router.push({
        path: '/channelManagement/add',
        query: {
          id: data.id
        }
      })
    },
    endList() {
      let num = 0
      this.selectionArr.map(data => {
        frozenApi(data.id).then(res => {
          if (res.code == 200) {
            num++
          } else {
            return this.$message.error(res.desc)
          }
          if (num == this.selectionArr.length) {
            this.initData()
            this.$message.success('冻结成功')
          }
        })
      })
    },
    addAndEditDialog(data = '') {
      frozenApi(data.id).then(res => {
        if (res.code == 200) {
          this.initData()
          this.$message.success('冻结成功')
        }
      })
    },
    noAndEditDialog(data = '') {
      normalApi(data.id).then(res => {
        if (res.code == 200) {
          this.initData()
          this.$message.success('解冻成功')
        }
      })
    },

    handleSelectionChange(e) {
        this.ruleModel.selectionArr = [];
        this.ruleModel.selection = false
        this.ruleModel.selection1 = true
        for (var i = e.length - 1; i >= 0; i--) {
            this.ruleModel.selectionArr.push(e[i].id)
        }

        for (var i = e.length - 1; i >= 0; i--) {
            if (e[i].show === 1) {
                this.ruleModel.selection = true
            }
        }
        for (var i = e.length - 1; i >= 0; i--) {
            if (e[i].show !== 1) {
                this.ruleModel.selection1 = false
            }
        }
    },
    closeAdd() {
      this.dialogAdd = false
      this.dialogSet = false
      this.initData()
    }
  }
}
</script>

<style lang="scss" scoped>
/*被拖拽对象的样式*/
.item {
  padding: 6px;
  //background-color: #fdfdfd;
  //border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
}
/*选中样式*/
.chosen {
  border: solid 2px #3089dc !important;
}
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
