var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "channelManagement" },
      [
        _c("div", { staticClass: "channelManagement-head" }),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v("公司端")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.companyMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "公司名称:", prop: "stockName" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "150px" },
                              attrs: { placeholder: "请输入公司名称" },
                              model: {
                                value: _vm.companyMsg.stockName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyMsg, "stockName", $$v)
                                },
                                expression: "companyMsg.stockName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.companyMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyMsg, "dateTypeYear", $$v)
                                },
                                expression: "companyMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.companyMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyMsg, "dateType", $$v)
                                },
                                expression: "companyMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "股票类型:", prop: "stockType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择股票类型" },
                                model: {
                                  value: _vm.companyMsg.stockType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.companyMsg, "stockType", $$v)
                                  },
                                  expression: "companyMsg.stockType",
                                },
                              },
                              _vm._l(_vm.stockTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排名列数:" } },
                          [
                            _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.companyMsg.rankingNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyMsg, "rankingNum", $$v)
                                },
                                expression: "companyMsg.rankingNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6, align: "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdCompany },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetCompany },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-row"),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v("公司端-自动生成")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.companysMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.companysMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companysMsg, "dateTypeYear", $$v)
                                },
                                expression: "companysMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.companysHandleChange },
                              model: {
                                value: _vm.companysMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companysMsg, "dateType", $$v)
                                },
                                expression: "companysMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "股票类型:", prop: "stockType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择股票类型" },
                                model: {
                                  value: _vm.companysMsg.stockType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.companysMsg, "stockType", $$v)
                                  },
                                  expression: "companysMsg.stockType",
                                },
                              },
                              _vm._l(_vm.stockTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排名列数:" } },
                          [
                            _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.companysMsg.rankingNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companysMsg, "rankingNum", $$v)
                                },
                                expression: "companysMsg.rankingNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6, align: "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdCompanys },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetCompanys },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-row"),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v("市场端")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.pressMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.pressMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pressMsg, "dateTypeYear", $$v)
                                },
                                expression: "pressMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.pressHandleChange },
                              model: {
                                value: _vm.pressMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pressMsg, "dateType", $$v)
                                },
                                expression: "pressMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "股票类型:", prop: "stockType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择股票类型" },
                                model: {
                                  value: _vm.pressMsg.stockType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pressMsg, "stockType", $$v)
                                  },
                                  expression: "pressMsg.stockType",
                                },
                              },
                              _vm._l(_vm.stockTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdPress },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetPress },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v(" 互动易/易互动 ")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.interactMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "类型:", prop: "interactType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择类型" },
                                model: {
                                  value: _vm.interactMsg.interactType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.interactMsg,
                                      "interactType",
                                      $$v
                                    )
                                  },
                                  expression: "interactMsg.interactType",
                                },
                              },
                              _vm._l(_vm.interactOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.interactMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.interactMsg, "dateTypeYear", $$v)
                                },
                                expression: "interactMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.interactHandleChange },
                              model: {
                                value: _vm.interactMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.interactMsg, "dateType", $$v)
                                },
                                expression: "interactMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排名列数:" } },
                          [
                            _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.interactMsg.rankingNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.interactMsg, "rankingNum", $$v)
                                },
                                expression: "interactMsg.rankingNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12, align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdInteract },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetInteract },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v(" 互动信息-自动生成 ")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.interactsMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.interactsMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.interactsMsg,
                                    "dateTypeYear",
                                    $$v
                                  )
                                },
                                expression: "interactsMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.interactsHandleChange },
                              model: {
                                value: _vm.interactsMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.interactsMsg, "dateType", $$v)
                                },
                                expression: "interactsMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排名列数:" } },
                          [
                            _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.interactsMsg.rankingNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.interactsMsg, "rankingNum", $$v)
                                },
                                expression: "interactsMsg.rankingNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12, align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdInteracts },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetInteracts },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v(" ESG指标 ")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.esgMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.esgMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.esgMsg, "dateTypeYear", $$v)
                                },
                                expression: "esgMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.esgHandleChange },
                              model: {
                                value: _vm.esgMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.esgMsg, "dateType", $$v)
                                },
                                expression: "esgMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "行业:" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择行业" },
                                model: {
                                  value: _vm.esgMsg.industry,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.esgMsg, "industry", $$v)
                                  },
                                  expression: "esgMsg.industry",
                                },
                              },
                              _vm._l(_vm.industryOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排名列数:" } },
                          [
                            _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.esgMsg.rankingNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.esgMsg, "rankingNum", $$v)
                                },
                                expression: "esgMsg.rankingNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12, align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdEsg },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetEsg },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c("h2", [_vm._v(" ESG指标-自动生成 ")]),
            _c(
              "el-form",
              {
                ref: "formData",
                attrs: {
                  model: _vm.esgsMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年份:", prop: "dateTypeYear" } },
                          [
                            _c("el-date-picker", {
                              attrs: { type: "year", placeholder: "选择年" },
                              model: {
                                value: _vm.esgsMsg.dateTypeYear,
                                callback: function ($$v) {
                                  _vm.$set(_vm.esgsMsg, "dateTypeYear", $$v)
                                },
                                expression: "esgsMsg.dateTypeYear",
                              },
                            }),
                            _c("span", {
                              staticStyle: { "margin-right": "20px" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间:", prop: "timeTypeDate" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.timeTypeDateOptions,
                                "show-all-levels": false,
                              },
                              on: { change: _vm.esgsHandleChange },
                              model: {
                                value: _vm.esgsMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.esgsMsg, "dateType", $$v)
                                },
                                expression: "esgsMsg.dateType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排名列数:" } },
                          [
                            _c("el-slider", {
                              attrs: { "show-input": "" },
                              model: {
                                value: _vm.esgsMsg.rankingNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.esgsMsg, "rankingNum", $$v)
                                },
                                expression: "esgsMsg.rankingNum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12, align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.createdEsgs },
                          },
                          [_vm._v("生成稿件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.resetEsgs },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { "margin-bottom": "100px" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }