<template>
  <div>
    <router-view></router-view>
    <div class="businessManagement" v-if="$route.path == '/businessManagement'">
      <div class="channelManagement-head">
        <h1>账户列表</h1>
      </div>
      <el-card class="box-card">
        <el-row class="text-left">
          <el-form
            :model="searchFormData"
            ref="formData"
            size="small"
            label-position="left"
            label-width="80px"
          >
            <el-col :span="6">
              <el-form-item label="客户名称" class="limitinputWidth">
                <el-input clearable v-model="pageMsg.companyName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> <p style="opacity: 0">1</p> </el-col>
            <el-col :span="6">
              <el-form-item label="账户：" class="limitinputWidth">
                <el-input clearable v-model="pageMsg.username"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> <p style="opacity: 0">1</p> </el-col>

            <el-col :span="6">
              <el-form-item label="类型：" class="limitinputWidth">
                <el-cascader
                  :options="options"
                  @change="handleChange"
                  v-model="pageMsg.industryCategoryArr"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="text-right">
              <el-button type="primary" size="small" @click="searchData(true)">
                查询
              </el-button>
              <el-button size="small" @click="searchData(false)">
                重置
              </el-button>
              <!-- <el-col class="text-right" style="padding: 10px 0"> -->
            </el-col>
          </el-form>
        </el-row>
        <el-row style="padding-bottom: 10px">
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              @click="addAndEditDialog()"
              v-if="userbtn.indexOf('CATEGORY_ADD') >= 0"
            >
              新建客户
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="platformExport"
              v-if="userbtn.indexOf('CATEGORY_EXPORT') >= 0"
            >
              导出列表
            </el-button>
          </el-col>
        </el-row>
        <div class="select_warn" v-if="selectionArr.length > 0">
          已经选择{{ selectionArr.length }}项
          <el-button
            type="text"
            @click="enableDisablingList"
            v-if="userbtn.indexOf('CATEGORY_MORE_HIDE') >= 0"
            >禁用</el-button
          >
          <el-button
            type="text"
            @click="upDisablingList"
            v-if="userbtn.indexOf('CATEGORY_MORE_SHOW') >= 0"
            >启用</el-button
          >
        </div>
        <el-table
          v-loading="tableLoading"
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="companyName"
            label="客户名称"
            header-align="center"
            width="200"
          >
            <template slot-scope="{ row }">
              <div style="display: flex; align-items: center">
                <el-switch
                  class="text-left"
                  v-model="row.statusVal"
                  v-if="userbtn.indexOf('CATEGORY_SWITCH') >= 0"
                  @change="switchEvent($event, row)"
                >
                </el-switch>
                <p style="margin-left: 20px">{{ row.companyName }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            label="账号"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="equityNumber"
            label="权益次数"
            header-align="center"
            align="center"
            width="180"
            sortable
          >
          </el-table-column>
          <el-table-column
            label="客户地址"
            header-align="center"
            align="center"
            width="180"
          >
            <template slot-scope="{ row }">
              <!-- row.cityName + -->
              {{
                row.provinceName +
                '  ' +
                row.cityName +
                '  ' +
                row.streetAddress
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="180"
            fixed="right"
          >
            <template slot-scope="{ row, $index }">
              <div>
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="addAndEditDialog(row)"
                  v-if="userbtn.indexOf('CATEGORY_EDIT') >= 0"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  v-if="
                    row.frozenState !== 20 &&
                    userbtn.indexOf('CATEGORY_HIDE') >= 0
                  "
                  size="small"
                  @click.native.prevent="enableDisablingRole(row)"
                >
                  禁用
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  v-if="
                    row.frozenState == 20 && userbtn.indexOf('CATEGORY_UP') >= 0
                  "
                  @click.native.prevent="enableDisablingRole(row)"
                >
                  启用
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
import {
  companyPageApi,
  companyupdFreezeStateApi,
  companyfrozenApi,
  platformExportApi
} from 'api/company'
import { industryCategoryTreeApi } from 'api/common'
import constant from 'constant'
export default {
  name: 'userlist',
  data() {
    return {
      searchFormData: {
        searchRole: '',
        searchUserName: ''
      },
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        descs: ['a.created_time'],
        pageSize: 10,
        companyName: '',
        username: '',
        industryCategoryCode: ''
      },
      userDetailOperate: '新增用户',
      roleList: [],
      newRoleList: [],
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      imageUrl: '',
      userFormDetail: {
        username: '',
        name: '',
        password: '',
        roleId: null
      },
      userFormRules: {
        username: [
          { required: true, message: '用户账号不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        addPassword: [
          { required: true, message: '密码不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '昵称不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        roleId: [{ required: true, message: '角色不能为空' }]
      },
      userDetailText: {},
      chosedRoleList: [],
      tableLoading: false,
      options: []
    }
  },
  watch: {
    $route: {
      handler(to) {
        // 初始化操作

        if (to.path === '/businessManagement') {
          this.initData()
          this.pageMsg = this.$options.data().pageMsg
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.initData()
    this.initCategoryCode()
  },
  methods: {
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      companyPageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.tableData.map(tableres => {
            if (tableres.frozenState == 20) {
              tableres.statusVal = false
            } else {
              tableres.statusVal = true
            }
          })
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.options = result
      })
    },
    handleChange(val) {
      console.log(val)
      if (val) {
        this.pageMsg.industryCategoryCode = val[val.length - 1]
      }
    },
    platformExport() {
      platformExportApi({
        ...this.pageMsg
      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = new Date().toLocaleDateString()
        link.download = `公司导出列表${dateXls}.csv`
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    switchEvent(bool, row) {
      this.enableDisablingRole(row)
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    enableDisablingList() {
      let data = []
      this.selectionArr.map(res => {
        data.push(res.id)
      })
      companyfrozenApi({
        list: data
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('禁用成功')
          this.initData()
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },
    upDisablingList() {
      let data = []
      this.selectionArr.map(res => {
        data.push(res.id)
      })
      companyupdFreezeStateApi({
        list: data
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('启用成功')
          this.initData()
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },

    // 禁用
    enableDisablingRole(data = '') {
      if (data.frozenState == 10) {
        companyfrozenApi({
          list: [data.id]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('禁用成功')
            this.initData()
          } else {
            this.$message({
              message: res.desc,
              type: 'error'
            })
          }
        })
      } else {
        // 启用
        companyupdFreezeStateApi({
          list: [data.id]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('启用成功')
            this.initData()
          } else {
            this.$message({
              message: res.desc,
              type: 'error'
            })
          }
        })
      }
    },
    userDialogClose() {
      this.$refs.userDetail.resetFields()
      for (let item in this.userFormDetail) {
        delete this.userFormDetail[item]
      }
    },
    lookdetail(data) {
      this.userDetailText = { ...data }
    },
    addAndEditDialog(data = '') {
      if (data) {
        this.$router.push({
          path: `/businessManagement/add`,
          query: {
            id: data.id
          }
        })
      } else {
        this.$router.push('/businessManagement/add')
      }
    },

    handleSelectionChange(e) {
      this.selectionArr = e
    }
  }
}
</script>
<style lang="scss" scoped>
.businessManagement {
  /deep/ .el-dialog__header {
    border-bottom: solid 1px #eee !important;
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>
