import { render, staticRenderFns } from "./joinDetail.vue?vue&type=template&id=93d5503a&scoped=true&"
import script from "./joinDetail.vue?vue&type=script&lang=js&"
export * from "./joinDetail.vue?vue&type=script&lang=js&"
import style0 from "./joinDetail.vue?vue&type=style&index=0&id=93d5503a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93d5503a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\DtbWork\\action\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('93d5503a')) {
      api.createRecord('93d5503a', component.options)
    } else {
      api.reload('93d5503a', component.options)
    }
    module.hot.accept("./joinDetail.vue?vue&type=template&id=93d5503a&scoped=true&", function () {
      api.rerender('93d5503a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/websiteMange/joinDetail.vue"
export default component.exports