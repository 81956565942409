<template>
  <div>
    <div class="channelManagement" >
      <div class="channelManagement-head">
      </div>
      <el-card class="box-card">

        <div>
          <h2>同业今日分发文章:
          </h2>
          <div style='margin-bottom: 20px'>
            <el-row>
              <el-col :span='4' align='center'>
                <span style='font-size: 18px'>
                公司名称:
                </span>
              </el-col>
              <el-col :span='14' align='center'>
                <span style='font-size: 18px'>
                文章标题:
                </span>
              </el-col>
              <el-col :span='6' align='center'>
                <span style='font-size: 18px'>
                发布时间:
                </span>

              </el-col>
            </el-row>
          </div>

          <div v-for='item in articleList' style='margin-bottom: 20px'>
            <el-row>
              <el-col :span='4' align='center'>
                <el-tag>{{item.stockName}}</el-tag>
              </el-col>
              <el-col :span='14' align='center'>
                <el-tag type="success">{{item.articleTitle}}</el-tag>
              </el-col>
              <el-col :span='6' align='center'>
                <el-tag type="warning">{{item.createdTime}}</el-tag>
              </el-col>
            </el-row>
          </div>
        </div>

        <div style='margin-top: 120px'>
          <h2>
            同业分发排名:
          </h2>
          <div style='width: 700px;'>
            <div id="industry" style="width: 500px; height: 450px;margin: 0px auto"></div>
            <div style='text-align: center'>
              您公司当前在同行业中发布数量排名为第<span style='color:red'>{{rankingNumber}}</span>名!
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {
  getIndustryInfoDayApi,
  getIndustryInfoByStockNameApi,
  getIndustryRankingByStockNameApi
} from 'api/statistics'
export default {
  data() {
    return {
      articleList:[],
      rankingNumber:0
    }
  },
  created() {
    const clientInfo = localStorage.getItem('USER_ALL_INFO');
    const client = JSON.parse(clientInfo);
    this.getIndustryInfoDay(client.companyName);
    this.industryEcharts(client.companyName);
    this.getIndustryRanking(client.companyName);
  },
  mounted() {
  },
  methods:{
    getIndustryRanking(companyName){
      getIndustryRankingByStockNameApi(companyName).then(res => {
        if (res.code === 200){
          this.rankingNumber = res.result;
        }
      })
    },
    getIndustryInfoDay(companyName){
      getIndustryInfoDayApi(companyName).then(res => {
        if (res.code === 200){
          this.articleList = res.result;
        }
      })
    },
   industryEcharts(companyName){
      console.log("公司名称:"+companyName)
     getIndustryInfoByStockNameApi(companyName).then(res => {
        if (res.code === 200){
          this.industryOption = res.result;
          const stockNameArr = this.industryOption.map(item => item.stockName);
          const validSumArr = this.industryOption.map(item => item.validSum)
          var industryOption = {
            xAxis: {
              type: 'category',
              data: stockNameArr
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: validSumArr,
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('industry');
          var myChart = echarts.init(chartDom);
          myChart.setOption(industryOption);
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>