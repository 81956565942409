<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
      <h1>动态列表</h1>
    </div>
    <el-card class="box-card">
      <el-row class="text-left">
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-width="80px"
        >
          <el-col :span="8">
            <el-form-item label="账户:" class="limitinputWidth">
              <el-input clearable  style="width: auto" v-model="pageMsg.channelName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="1"> <p style="opacity: 0"></p> </el-col>
          <el-col :span="8">
            <el-form-item label="企业:" class="limitinputWidth">
              <el-select
                      style="width: auto"
                      v-model="pageMsg.companyId"
                filterable
                placeholder="请填写公司名称或者股票代码"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="7" class="text-right">
            <el-button type="primary" size="small" @click="searchData(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData(false)">
              重置
            </el-button>
          </el-col>
        </el-form>
      </el-row>
      <div class="select_warn" v-if="selectionArr.length > 0">
        已经选择
        <span>{{ selectionArr.length }}</span>
        项
        <el-button type="text" @click="passItemAll(true)"
        v-if="userbtn.indexOf('RELEASE_REVIEW_MOREPASS') >= 0">通过</el-button>
        <el-button type="text" @click="passItemAll(false)"
        v-if="userbtn.indexOf('RELEASE_REVIEW_MOREFAIL') >= 0">驳回</el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        @selection-change="handleSelectionChange"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="articleTitle"
          label="动态内容"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="turnDetail(row)">
              {{ row.articleTitle }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="distributeUsername"
          label="分发账户"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="关联企业"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="author"
          label="创建人"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="提交时间"
          sortable
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="state"
          label="状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              v-if="row.authState == 10"
              style="color: #409eff"
            >
              <i style="background: #409eff"></i>
              审核中
            </p>
            <p
              class="state-table"
              v-if="row.authState == 20"
              style="color: #30b08f"
            >
              <i style="background: #30b08f"></i>成功
            </p>
            <p
              class="state-table"
              v-if="row.authState == 30"
              style="color: red"
            >
              <i style="background: red"></i>失败({{ row.failReason }})
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              v-if="row.authState == 10 && userbtn.indexOf('RELEASE_REVIEW_PASS') >= 0"
              size="small"
              @click.native.prevent="passItemTrue(row)"
            >
              通过
            </el-button>
            <el-button
                  type="text"
                  v-if="row.authState == 10  && userbtn.indexOf('RELEASE_REVIEW_FAIL') >= 0"
                  size="small"

                  @click.native.prevent="passItemFalse(row)"
          >
            驳回
          </el-button>


          </template>
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :visible.sync="detailBool"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1200px"
    >
      <releaseDetail :rowID="rowID"/>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="驳回原因"
      :visible.sync="passDialog"
    >
      <el-form :rules="rules" :model="ruleModel" ref="formName">
        <el-form-item prop="failReason">

            <el-select v-model="ruleModel.failReason" placeholder="请选择驳回原因"    style="width: auto">
                <el-option
                        v-for="item in basicWordCode"
                        :key="item.wordName"
                        :label="item.wordName"
                        :value="item.wordName">
                </el-option>
            </el-select>
        </el-form-item>
        <div style="margin-bottom: 30px"></div>
        <div class="dialog-footer">
          <el-button size="small" @click="handleClose">取 消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="addAndEditBanner('formName')"
            >确 定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { companyPageApi } from 'api/company'
import releaseDetail from '@/views/release/releaseDetail.vue'
import {
    articlePageApiSH,
  authStateSuccessApi,
  authStateFailApi,
    basicWordSelectApi
} from 'api/article'
export default {
  name: 'contentReview',
  components: {
    releaseDetail
  },
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    }
  },

  data() {
    return {
      rules: {
        failReason: [{ required: true, message: '请填写驳回原因' }]
      },
      passDialog: false,
      oneDialog: false,
      ruleModel: {
        articleId: null,
        failReason: ''
      },
        basicWordCode: [],
      companyOptions: [],
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 100,
        descs: ['created_time'],
        state: null,
        channelName: ''
      },
      tableLoading: false,
      rowID: null,
      detailBool: false
    }
  },
  mounted() {
    this.initCompany()
    this.initData()
  },
  methods: {
      tree() {
          basicWordSelectApi().then(data => {
              console.log('ddddd',data)
              this.basicWordCode = data.result
          });
      },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      console.log('22',data)
        articlePageApiSH(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    initCompany() {
      //初始化公司列表
      companyPageApi({
        pageNum: 1,
        pageSize: 100
      }).then(res => {
        if (res.code === 200) {
          this.companyOptions = res.result.records
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    passItemTrue(row) {
        this.$confirm('是否确认通过', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(function () {
            return authStateSuccessApi(row.id)
        }).then(res => {
            if (res.code == 200) {
                this.$message.success('审核成功')
                this.initData()
            }
        })

    },
     /* deleteHandle(id) {
          this.$confirm('是否确认删除', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(function () {
              return delObj(id)
          }).then(data => {
              this.$message.success('删除成功')
              this.getDataList()
          })
      },*/
    passItemAll(bool) {
      if (bool) {
        let len = 0
        this.selectionArr.map(res => {
          authStateSuccessApi(res.id).then(res => {
            len++
            if (res.code == 200) {
              if (len == this.selectionArr.length) {
                this.$message.success('审核成功')
                this.initData()
              }
            }
          })
        })
      } else {
        this.passItemFalse()
      }
    },
    alldialogTit() {
      this.$refs.formName.validate(valid => {
        if (valid) {
          let len = 0
          this.selectionArr.map(res => {
            authStateFailApi({
              articleId: res.id,
              failReason: this.ruleModel.failReason
            }).then(res => {
              len++
              if (res.code == 200) {
                if (len == this.selectionArr.length) {
                  this.$message.success('驳回成功')
                  this.handleClose()
                  this.initData()
                }
              }
            })
          })
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.ruleModel.articleId = null
      this.ruleModel.failReason = ''
        this.basicWordCode = [];
      this.$refs.formName.resetFields()
      this.passDialog = false
      this.oneDialog = false
    },
    addAndEditBanner(formName) {
      console.log('44',this.oneDialog)
      this.$refs[formName].validate(valid => {
        console.log(valid)
        if (valid) {
          if (this.oneDialog) {
            authStateFailApi({
              ...this.ruleModel
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('驳回成功')
                this.handleClose()
                this.initData()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    passItemFalse(row) {
      this.passDialog = true
      // this.$nextTick(() => {
      //   this.$refs.formName.validate(valid => {
      //     console.log(valid)
      //   })
      // })
        this.tree();
      if (row) {
        this.ruleModel.articleId = row.id
        this.oneDialog = true
      }
      // authStateFailApi({
      //   articleId: row.id
      // }).then(res => {
      //   if (res.code == 200) {
      //     this.$message.success('驳回成功')
      //     this.initData()
      //   }
      // })
    },
    turnDetail(row) {
      // this.$router.push({
      //   path: '/releaseDetail',
      //   query: {
      //     id: row.id
      //   }
      // })
      this.rowID = row.id
        console.log('ddddd',row)
      this.detailBool = true
    },

      DateToStr(dd) {

      },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    handleSelectionChange(e) {
      console.log('3',e)
      this.selectionArr = e
    },
    closeAdd() {
      this.dialogAdd = false
      this.dialogSet = false
      this.initData()
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
