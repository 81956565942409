<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">



      <!--  <el-form
                :model="pageMsg"
                ref="formData"
                size="small"
                label-position="left"
                label-width="44px"
        >
            <el-row class="text-left" :gutter="24">
                <el-col :span="5">
                    <el-form-item label="简称:" >
                        <el-input clearable v-model="pageMsg.stockName" style="width: 150px"  placeholder="请输入公司简称"></el-input>
                    </el-form-item>

                </el-col>
                &lt;!&ndash; <el-col :span="1">
                  <p style="opacity: 0"></p>
                </el-col> &ndash;&gt;
                <el-col :span="4">
                    <el-form-item label="类型:">
                        <el-select v-model="pageMsg.contentType"    style="width: 100px">
                            <el-option
                                    v-for="item in optionContent"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="5">
                    <el-form-item label="行业:"    class="limitinputWidth"  style="width: 200px">
                        <el-cascader

                                :options="options"
                                @change="handleChange"
                                v-model="pageMsg.industryCategoryArr"
                                :props="{ checkStrictly: 'true' }"
                        ></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item  label="城市:"  >
                        <el-select v-model="pageMsg.city"  filterable   style="width: 150px">
                            <el-option

                                    v-for="item in optionsCity"
                                    :key="item.value"
                                    :label="item.value"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6"  align="left">
                    <el-form-item  >
                        <el-radio-group v-model="pageMsg.dateType" >
                            <el-radio :label="1" style="margin-right: 10px">启用</el-radio>
                            <el-radio :label="0" style="margin-right: 10px">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
                <el-row >
                <el-col :span="12" align="left">
                    <el-button
                            type="primary"
                            size="small"
                            @click="reject()"
                    >
                        添加信息
                    </el-button>
                    <el-button
                            type="primary"
                            size="small"
                            @click="plkq(pageMsg)"
                    >
                        批量启用
                    </el-button>
                    <el-button
                            type="primary"
                            size="small"
                            @click="plgb(pageMsg)"
                    >
                        批量禁用
                    </el-button>
                    <el-button
                                type="primary"
                                size="small"
                                @click="basicStockDeleteAll(pageMsg)"
                    >
                        邮件推送
                    </el-button>
                </el-col>
                <el-col :span="12"  align="right" >
                    <el-button size="small" @click="searchData(false)">
                        重置
                    </el-button>
                    <el-button type="primary" size="small" @click="searchData(true)">
                        查询
                    </el-button>
                </el-col>
                </el-row>

        </el-form>-->

        <el-form
                :model="pageMsg"
                ref="formData"
                size="small"
        >
            <el-row class="text-left" :gutter="24">
                <el-col :span="8">
                    <el-button
                            type="primary"
                            size="small"
                            @click="reject()"
                    >
                        添加任务
                    </el-button>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="任务类型:" >
                        <el-select v-model="pageMsg.jobType" placeholder="请选择任务类型"   >
                            <el-option
                                    v-for="item in optionContent"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>      </el-form-item>

                </el-col>
                <el-col :span="8"  align="right" >
                    <el-button size="small" @click="searchData(false)">
                        重置
                    </el-button>
                    <el-button type="primary" size="small" @click="searchData(true)">
                        查询
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
      <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px;margin-top: 10px"
              row-key="id"
              @selection-change="handleSelectionChange"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              v-loading="tableLoading"
      >
         <!-- <el-table-column  type="selection" width="55" >
          </el-table-column>-->




        <el-table-column
                prop="jobType"
                label="任务类型"
                header-align="center"
                align="center"
                width="auto"
        >
        </el-table-column>
          <el-table-column
                  prop="jobTime"
                  label="执行时间"
                  header-align="center"
                  align="center"
                  width="auto"
          >
          </el-table-column>
          <el-table-column
                  prop="isShop"
                  label="交易时间"
                  header-align="center"
                  align="center"
                  width="auto"
          >
              <template slot-scope="{ row }">
                  <p

                          v-if="row.isShop === 0"

                  >
                      是
                  </p>
                  <p

                          v-if="row.isShop === 1"

                  >
                      否
                  </p>
              </template>
          </el-table-column>
          <el-table-column
                  prop="isDay"
                  label="工作日"
                  header-align="center"
                  align="center"
                  width="auto"
          >
              <template slot-scope="{ row }">
                  <p

                          v-if="row.isDay === 0"

                  >
                      每天
                  </p>

              </template>
          </el-table-column>
          <el-table-column
                  prop="stockName"
                  label="公司简称"
                  header-align="center"
                  align="center"
                  width="auto"
          >
          </el-table-column>
          <el-table-column
                  prop="categoryName"
                  label="行业名称"
                  header-align="center"
                  align="center"
                  width="auto"
          >
          </el-table-column>

        <el-table-column
                label="操作"
                header-align="center"
                align="center"
                fixed="right"
        >
          <template slot-scope="{ row }">
           <!-- <el-button size="small" @click="edit(row)"   type="text" >
              编辑
            </el-button>

-->        <el-button size="small"  v-if="row.isDel === 0" @click="basicJobDeleteOne(row.id)"   type="text"  >
              禁用
          </el-button>
              <el-button size="small"  v-if="row.isDel === 1" @click="basicJobDeleteZero(row.id)"   type="text"  >
                  启用
              </el-button>

          </template>
        </el-table-column>

      </el-table>
      <div class="pb20"></div>
      <el-pagination
              class="text-center"
              v-if="pageTotal > 0"
              @size-change="initData"
              @current-change="initData"
              :current-page.sync="pageMsg.pageNum"
              :page-size.sync="pageMsg.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <el-dialog   title="新增任务信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading1"  @close="dialogCreate" >

        <div class="channel-add">
      <el-form
              :rules="userFormRules"
              :model="channelForm"
              ref="addChannel"
              :label-position="labelPosition"
              :label-width="formLabelWidth"
      >
          <el-form-item label="任务类型:" prop="jobType">
              <el-select v-model="channelForm.jobType" placeholder="请选择任务类型"    style="width: auto">
                  <el-option
                          v-for="item in optionContent"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="所属行业:" prop="categoryCode" v-if="channelForm.jobType === '竞品信息推送'">
              <el-cascader
                      :options="options"
                      @change="handleChange"
                      :props="{ checkStrictly: 'true' }"
                      v-model="channelForm.industryCategoryArr"

              ></el-cascader>

          </el-form-item>
          <el-form-item label="推送时间:" prop="jobTime" v-if="channelForm.jobType === '竞品信息推送'">
              <el-time-select
                      v-model="channelForm.jobTime"
                      :picker-options="{
  start: '00:00',
    step: '00:03',
    end: '23:57'
  }"
                      placeholder="选择时间">
              </el-time-select>
          </el-form-item>

        
          <el-form-item label="推送公司:" prop="stockName"   v-if="channelForm.jobType=== '分发报告推送'&&isAdmin===true">
              <el-input   v-model="channelForm.stockName" style="width: 220px"
                          placeholder="请填写公司简称"></el-input>
          </el-form-item>

        <el-form-item label="推送公司:" prop="stockName"   v-if="channelForm.jobType=== '分发报告推送'&&isAdmin===false">
          <el-select v-model="channelForm.stockName" placeholder="请选择">
            <el-option
              v-for="item in stockClientOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>



        <!--  <el-form-item label="交易时间:" prop="isShop" v-if="channelForm.jobType === '竞品信息推送'">
              <el-radio-group v-model="channelForm.isShop" >
                  <el-radio :label="0" style="margin-right: 10px">是</el-radio>
                  <el-radio :label="1" style="margin-right: 10px">否</el-radio>
              </el-radio-group>
          </el-form-item>-->
          <el-form-item label="推送日期:" prop="isDay"   v-if="channelForm.jobType === '分发报告推送'">
              <el-radio-group v-model="channelForm.isDay" >
                  <el-radio :label="0" style="margin-right: 10px">每天</el-radio>
              </el-radio-group>
          </el-form-item>
          <el-form-item label="推送时间:" prop="jobTime" v-if="channelForm.jobType === '分发报告推送'" >
              <el-time-select
                      v-model="channelForm.jobTime"
                      :picker-options="{
  start: '00:00',
    step: '00:03',
    end: '23:57'
  }"
                      placeholder="选择时间">
              </el-time-select>
          </el-form-item>
      </el-form>
          <div align="center">   <span  >
      <el-button @click="dialogCreate" >取消</el-button>
      <el-button type="primary"  :loading="loading" @click="channelAdd">确定</el-button>
    </span></div>

        </div>

    </el-dialog>

      <el-dialog   title="修改客户信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading2"  @close="dialogCreate" >
          <div class="channel-add">
              <el-form
                      :model="channelForm1"
                      ref="addChannel1"
                      :rules="userFormRules1"
                      :label-position="labelPosition"
                      :label-width="formLabelWidth"
              >
                  <el-form-item label="任务类型:" prop="jobType">
                      <el-select v-model="channelForm1.jobType" placeholder="请选择任务类型"    style="width: auto">
                          <el-option
                                  v-for="item in optionContent"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="所属行业:" prop="categoryCode" v-if="channelForm1.jobType === '竞品信息推送'">
                      <el-cascader
                              :options="options"
                              @change="handleChange"
                              :props="{ checkStrictly: 'true' }"
                              v-model="channelForm1.industryCategoryArr"

                      ></el-cascader>

                  </el-form-item>
                  <el-form-item label="推送时间:" prop="jobTime" v-if="channelForm1.jobType === '竞品信息推送'">
                      <el-time-select
                              v-model="channelForm.jobTime"
                              :picker-options="{
    start: '08:30',
    step: '00:15',
    end: '18:30'
  }"
                              placeholder="选择时间">
                      </el-time-select>
                  </el-form-item>
                  <el-form-item label="推送公司:" prop="stockName"   v-if="channelForm1.jobType=== '分发报告推送'">
                      <el-input   v-model="channelForm.stockName" style="width: 220px"
                                  placeholder="请填写公司简称"></el-input>
                  </el-form-item>
                  <el-form-item label="交易时间:" prop="isShop" v-if="channelForm1.jobType === '竞品信息推送'">
                      <el-radio-group v-model="channelForm.isShop" >
                          <el-radio :label="0" style="margin-right: 10px">是</el-radio>
                          <el-radio :label="1" style="margin-right: 10px">否</el-radio>
                      </el-radio-group>
                  </el-form-item>
                  <el-form-item label="推送日期:" prop="isDay"   v-if="channelForm1.jobType === '分发报告推送'">
                      <el-radio-group v-model="channelForm.isDay" >
                          <el-radio :label="0" style="margin-right: 10px">每天</el-radio>
                      </el-radio-group>
                  </el-form-item>
                  <el-form-item label="推送时间:" prop="jobTime" v-if="channelForm1.jobType === '分发报告推送'" >
                      <el-time-select
                              v-model="channelForm.jobTime"
                              :picker-options="{
  start: '08:30',
    step: '00:15',
    end: '18:30'
  }"
                              placeholder="选择时间">
                      </el-time-select>
                  </el-form-item>
              </el-form>
              <div align="center">   <span  >
      <el-button @click="dialogCreate1" >取消</el-button>
      <el-button type="primary" :loading="loading1"  @click="channelAdd1">确定</el-button>
    </span></div>

          </div>

      </el-dialog>




      <el-dialog   title="编辑邮件"    width="800px"  :visible.sync="passLoading4"  @close="dialogCreate" >
          <div class="channel-add" >
              <el-form
                      :model="contentData"
                      ref="addChannel2"

              >
                  <el-form-item label="标题：" prop="subject" >
                      <el-input
                              clearable
                              v-model="contentData.subject"
                              style="width: 300px"
                      ></el-input>
                  </el-form-item>
                          <el-form-item label="内容：" prop="articleContent" >
                              <vue-tinymce
                                      v-model="contentData.content"
                                      :setting="setting"
                              />
                          </el-form-item>
              </el-form>
              <div align="center">   <span  >
      <el-button @click="dialogCreate2" >取消</el-button>
      <el-button type="primary" :loading="loading1"  @click="channelAdd2">确定</el-button>
    </span></div>

          </div>

      </el-dialog>

  </div>
</template>

<script>
import {basicJobDeleteZero,basicJobDeleteOne,basicJobEdit,basicJobPage,basicJobAdd,basicStockSelect,basicStockStartAll,basicStockCloseAll,basicStocksendEmail, basicStockPageApi,treeContentypeApi,basicStockAddApi,basicStockEditApi,basicStockDeleteZero,basicStockDeleteOne,basicStockDeleteAll,basicJobClientApi } from 'api/article'
import { industryCategoryTreeApi } from 'api/common'
export default {

    name: 'contentReview',

    computed: {
        formatDate() {
            return function (oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()

                const dateString =
                    Y +
                    '-' +
                    add0(M) +
                    '-' +
                    add0(D) +
                    '  ' +
                    add0(h) +
                    ':' +
                    add0(m) +
                    ':' +
                    add0(s)
                return dateString
            }
        }
    },

    data() {
        return {
          isAdmin:false,
          stockClientOptions:[],
            setting: {
                menubar: false,
                toolbar:
                    'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image  table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins: 'link image  table lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                images_upload_url: true,
                images_upload_handler: function (blobInfo, succFun, failFun) {
                    var formData = new FormData()
                    let url = '/common/upload'
                    formData.append('file', blobInfo.blob()) //此处与源文档不一样

                    axios
                        .post(url, formData, {
                            headers: signActionTime('post', formData)
                        })
                        .then(response => {
                            succFun(response.data.result.url)
                        })
                        .catch(error => {
                            failFun(error)
                        })
                }
            },

            selectionArr: [],
            loading: false,
            loading1: false,
            optionContent: [{
                value: '分发报告推送',
                label: '分发报告推送'
            }, {
                value: '竞品信息推送',
                label: '竞品信息推送'
            }],
            contentData: {
                content: '',
                subject: '',
                email: '',
            },
            channelForm: {
                isDay:undefined,
                isShop:undefined,
                jobName: undefined,
                stockName: undefined,
                jobType:undefined,
                categoryCode: undefined,
                jobTime: undefined,
                industryCategoryCode: '',
                industryCategoryArr: [],
            },
            options: [],
            optionsCity: [],
            channelForm1: {
                id: '',
                isDay:undefined,
                isShop:undefined,
                stockName: undefined,
                jobName: undefined,
                jobType:undefined,
                categoryCode: undefined,
                categoryName: undefined,
                jobTime: undefined,
                industryCategoryCode: '',
                industryCategoryArr: [],
            },
            userFormRules: {
                jobType: [{required: true, message: '股票类型不能为空',trigger: 'blur'}],
                industryCategoryCode: [{required: true, message: '行业分类不能为空',trigger: 'blur'}],
                jobTime: [{required: true, message: '任务时间不能为空',trigger: 'blur'}],
                isShop: [{required: true, message: '是否交易时间不能为空',trigger: 'blur'}],
                isDay: [{required: true, message: '是否工作日不能为空',trigger: 'blur'}],
                stockName: [{required: true, message: '公司简称为空',trigger: 'blur'}],
            },
            userFormRules1: {
                jobType: [{required: true, message: '股票类型不能为空',trigger: 'blur'}],
                industryCategoryCode: [{required: true, message: '行业分类不能为空',trigger: 'blur'}],
                jobTime: [{required: true, message: '任务时间不能为空',trigger: 'blur'}],
                isShop: [{required: true, message: '是否交易时间不能为空',trigger: 'blur'}],
                isDay: [{required: true, message: '是否工作日不能为空',trigger: 'blur'}],
                stockName: [{required: true, message: '公司简称为空',trigger: 'blur'}],
            },
            passLoading1: false,
            passLoading2: false,
            passLoading3: false,
            passLoading4: false,
            pageForm:{
                pageNum: 1,
                pageSize: 10,
                wordType:null,
            },
            formLabelWidth: '160px',
            labelPosition: 'right',
            tableData: [],
            pageTotal: 0,
            pageMsg: {
                dateType: 2,
                pageNum: 1,
                pageSize: 10,
                jobType:undefined,
                city: undefined,
                stockName: undefined,
                contentType: undefined,
                industryCategoryArr: [],
                industry: undefined,

            },
            tableLoading: false,
        }
    },
    mounted() {
        this.select()
        this.initData()
        this.initCategoryCode()
    },
    created() {
        this.select()
    },
    methods: {


        select() {
            basicStockSelect()
                .then(data => {


                   let arr =  data.result.map((item) => {
                        return { value: item, label: item }
                    })
                    this.optionsCity = arr

                })
        },
        plkq(pageMsg) {
            console.log('6666',pageMsg)
            if (pageMsg.industryCategoryArr.length > 0 && this.passLoading3== true) {
                this.$confirm('是否确认批量启用', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    console.log('555',pageMsg)

                    return  basicStockStartAll(pageMsg)
                }).then(data => {
                    this.$message.success('启用成功')
                    this.initData()
                });
            } else {
                return this.$message.error("请先进行行业筛选");
            }

        },
        plgb(pageMsg) {
            if (pageMsg.industryCategoryArr.length > 0 && this.passLoading3== true) {
                this.$confirm('是否确认批量禁用', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    return  basicStockCloseAll(pageMsg)
                }).then(data => {
                    this.$message.success('禁用成功')
                    this.initData()
                });
            } else {
                return this.$message.error("请先进行行业筛选");
            }
        },
        switchEvent(bool, row) {
            console.log("54645",row)
            if (bool) {
                //解冻
                this.basicStockDeleteThree(row.id)
            } else {
                // 冻结
                this.basicStockDeleteFour(row.id)
            }
        },
        basicStockDeleteThree(id) {

               basicStockDeleteOne(id)
         .then(data => {
                this.$message.success('启用成功')
                this.initData()
            })
        },

        basicStockDeleteFour(id) {

             basicStockDeleteZero(id)
           .then(data => {
                this.$message.success('禁用成功')
                this.initData()
            })
        },

        basicStockDeleteAll(pageMsg) {

            if (this.pageMsg.industryCategoryArr.length>0 ) {


                basicStockDeleteAll(pageMsg).then(data => {
                    console.log('dddd', data);
                    if (data.content) {
                        this.passLoading4 = true;
                        this.contentData = data
                    } else {
                        this.$message.error("没有可推送的信息")
                    }

                  /*  if (data === true) {
                        this.$message.success('发送成功');
                        this.passLoading3 = false;

                        this.initData();
                    } else {
                        this.$message.error("没有可推送的信息")
                    }*/
                });
            } else {
                return this.$message.error("请先进行行业筛选");
            }

        },
        handleSelectionChange(e) {
            this.selectionArr = e

        },
        searchData(bool) {
            if (bool) {
                this.pageMsg.pageNum = 1
                this.pageMsg.pageSize = 10

            } else {
                this.pageMsg = this.$options.data().pageMsg
                this.pageMsg.industryCategoryArr = [];
                this.pageMsg.jobType = undefined;
            }
            this.initData()
        },
        basicJobDeleteOne(id) {

            this.$confirm('是否确认禁用', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                return  basicJobDeleteOne(id)
            }).then(data => {
                this.$message.success('禁用成功')
                this.initData()
            })
        },

        basicJobDeleteZero(id) {

            this.$confirm('是否确认启用', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                return  basicJobDeleteZero(id)
            }).then(data => {
                this.$message.success('启用成功')
                this.initData()
            })
        },
        handleChange(val) {
            this.channelForm.industryCategoryCode = val[val.length - 1]
        },
        initCategoryCode() {
            industryCategoryTreeApi().then(({ result }) => {
                const classification = result
                function classifi(arr) {
                    arr.forEach(item => {
                        item.label = item.name
                        item.value = item.code
                        if (item.children && item.children.length > 0) {
                            classifi(item.children)
                        } else {
                            delete item['children']
                        }
                    })
                }
                classifi(classification)
                this.options = result
            })
        },
        edit(row){
            console.log('d this.channelForm1',row)
            this.passLoading2 = true;
            this.channelForm1.jobType = row.jobType
            this.channelForm1.isDay = row.isDay
            this.channelForm1.isShop = row.isShop
            this.channelForm1.jobTime = row.jobTime
            this.channelForm1.categoryCode = row.categoryCode
            this.channelForm1.categoryName = row.categoryName
            this.channelForm1.stockName = row.stockName
            this.channelForm1.id = row.id
        },
        dialogCreate() {
            this.passLoading1 = false
        },
        dialogCreate1() {
            this.passLoading2 = false
            this.channelForm1 = this.$options.data().channelForm1

        },
        dialogCreate2() {
            this.passLoading4 = false
        },
        reject() {
          basicJobClientApi().then(res => {
            if (res.code === 200){
              this.isAdmin = res.result.isAdmin;
              if (!res.result.isAdmin){
                const classification = res.result.basicStockList;
                function classifi(arr) {
                  arr.forEach(item => {
                    item.label = item.stockName
                    item.value = item.stockName
                  })
                }
                classifi(classification)
                this.stockClientOptions = res.result.basicStockList;
                //更改推送公司信息
              }
            }
          })
            this.passLoading1 = true;
            this.channelForm = this.$options.data().channelForm
        },
		//设置定时任务-Dream
        channelAdd() {
            this.$refs.addChannel.validate(valid => {
                if (valid) {
                    this.loading = true
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicJobAdd({ ...this.channelForm }).then(res => {
                            if (res.code == 200) {

                                this.$message.success('保存成功')
                                this.channelForm = this.$options.data().channelForm
                                this.passLoading1 = false
                                this.loading = false
                                this.initData()
                            }
                        this.loading = false

                        })
                }
            })
        },


        channelAdd1() {
            this.$refs.addChannel1.validate(valid => {
                this.passLoading2 = true
                if (valid) {
                    this.loading1 = true
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicJobEdit({ ...this.channelForm1 }).then(res => {
                        if (res.code == 200) {

                            this.$message.success('修改成功')

                            this.channelForm1 = this.$options.data().channelForm1
                            this.passLoading2 = false

                            this.loading1 = false

                            this.initData()
                        }
                        this.loading1 = false
                    })
                }
            })
        },
        channelAdd2() {
            this.$refs.addChannel2.validate(valid => {
                this.passLoading4 = true
                if (valid) {
                    this.loading1 = true
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicStocksendEmail({ ...this.contentData }).then(res => {
                        if (res.code == 200) {

                            this.$message.success('发送成功')

                            this.contentData = this.$options.data().contentData
                            this.passLoading4 = false
                            this.loading1 = false
                            this.initData()
                        }
                        this.loading1 = false
                    })
                }
            })
        },
        initData() {
            this.tableLoading = true
            let a =   this.pageMsg.industryCategoryArr.join(",");
            this.pageMsg.industry = a;
            const data = {
                ...this.pageMsg
            }
            basicJobPage(this.pageMsg).then(res => {
                console.log("88888",res)
                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData = records
                    this.pageTotal = total

                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading = false
            })
        },

    }
}
</script>

<style lang="scss" scoped>
  .channelManagement {
    width: 100%;
    .channelManagement-head {
      padding: 10px 20px;
      background: #fff;
    }
    .box-card {
      width: calc(100% - 60px);
      padding: 10px;
      margin: 20px auto;
    }
    /deep/.state-table {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .select_warn {
      background: rgb(230, 247, 255);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      span {
        color: $primary;
      }
    }
  }
</style>
