var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.pageMsg,
                size: "small",
                "label-position": "center",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { placeholder: "请输入公司简称" },
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "span",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.initData },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.reset },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.openClientAccount },
                          },
                          [_vm._v("开通")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "媒介盒子余额:" } },
                        [
                          _c("span", { staticStyle: { "font-size": "18px" } }, [
                            _c("strong", [
                              _vm._v(" " + _vm._s(_vm.mediaBoxAmount) + " "),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "margin-top": "10px",
              },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "stockName",
                  label: "公司名称",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openAmountDetail(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.stockName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "amount",
                  label: "余额",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isFreeze",
                  label: "是否开启",
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          on: {
                            change: function ($event) {
                              return _vm.isFreezeChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.isFreeze,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isFreeze", $$v)
                            },
                            expression: "scope.row.isFreeze",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-dialog",
                {
                  staticStyle: { "margin-left": "-80px" },
                  attrs: {
                    title: "编辑客户信息",
                    width: "800px",
                    visible: _vm.editClientAccountDialog,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.editClientAccountDialog = $event
                    },
                    close: _vm.editClientAccountDialogClose,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "editRef",
                      attrs: {
                        model: _vm.editMsg,
                        rules: _vm.editFormRules,
                        size: "small",
                        "label-position": "center",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称:", prop: "stockName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              placeholder: "请输入客户名称",
                              disabled: true,
                            },
                            model: {
                              value: _vm.editMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editMsg, "stockName", $$v)
                              },
                              expression: "editMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "当前余额:", prop: "amount" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.editMsg.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editMsg, "amount", $$v)
                              },
                              expression: "editMsg.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作:", prop: "editType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.editMsg.editType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editMsg, "editType", $$v)
                                },
                                expression: "editMsg.editType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "0", border: "" } },
                                [_vm._v("增加")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "1", border: "" } },
                                [_vm._v("减少")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作金额", prop: "editAmount" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, max: 100000 },
                            model: {
                              value: _vm.editMsg.editAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editMsg, "editAmount", $$v)
                              },
                              expression: "editMsg.editAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.editClientAccount },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.editClientAccountDialogClose },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-dialog",
                {
                  staticStyle: { "margin-left": "-80px" },
                  attrs: {
                    title: "开通储值发客户账号",
                    width: "800px",
                    visible: _vm.openClientAccountDialog,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.openClientAccountDialog = $event
                    },
                    close: _vm.openClientAccountDialogClose,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addRef",
                      attrs: {
                        model: _vm.openMsg,
                        size: "small",
                        "label-position": "center",
                        "label-width": "100px",
                        rules: _vm.addRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "请选择公司:", prop: "stockId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { placeholder: "请选择公司" },
                              model: {
                                value: _vm.openMsg.stockId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.openMsg, "stockId", $$v)
                                },
                                expression: "openMsg.stockId",
                              },
                            },
                            _vm._l(_vm.stockOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "请填写余额:", prop: "amount" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "auto" },
                            attrs: { placeholder: "请填写余额" },
                            model: {
                              value: _vm.openMsg.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.openMsg, "amount", $$v)
                              },
                              expression: "openMsg.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.openClient },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        { on: { click: _vm.openClientAccountDialogClose } },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "查看客户余额记录",
                visible: _vm.editStockAmountDialogVisible,
                width: "85%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editStockAmountDialogVisible = $event
                },
                close: _vm.editStockAmountDialogClose,
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.amountTableLoading,
                      expression: "amountTableLoading",
                    },
                  ],
                  staticStyle: {
                    width: "100%",
                    "margin-bottom": "20px",
                    "margin-top": "10px",
                  },
                  attrs: {
                    data: _vm.stockAmountData,
                    "header-cell-style": { background: "rgb(250,250,250)" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "changeType", label: "类型" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "changeAmount", label: "变更金额" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "changeBeforeAmount", label: "变更前余额" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "changeAfterAmount", label: "变更后余额" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "changeRemark", label: "备注" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "updatedTime", label: "变更时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(row.updatedTime)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "pb20" }),
              _vm.amountLogTotal > 0
                ? _c("el-pagination", {
                    staticClass: "text-center",
                    attrs: {
                      "current-page": _vm.amountLogMsg.pageNum,
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.amountLogMsg.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.amountLogTotal,
                    },
                    on: {
                      "size-change": _vm.getStockAmountLogFun,
                      "current-change": _vm.getStockAmountLogFun,
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.amountLogMsg, "pageNum", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.amountLogMsg, "pageNum", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.amountLogMsg, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.amountLogMsg, "pageSize", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }