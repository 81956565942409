<template>
  <div>
    <el-card class="box-card">
      <el-row class="text-left">
        <el-form
          :model="searchFormData"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
        >
          <el-col :span="6">
            <el-form-item label="账户：" class="limitinputWidth">
              <el-input
                clearable
                v-model="searchFormData.searchUserName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1"> <p style="opacity: 0">1</p> </el-col>
          <el-col :span="6">
            <el-form-item label="角色：" class="limitinputWidth">
              <el-input
                clearable
                v-model="searchFormData.searchRole"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" class="text-right">
            <el-button type="primary" size="small"> 重置 </el-button>
            <el-button type="primary" size="small"> 查询 </el-button>
            <!-- <el-col class="text-right" style="padding: 10px 0"> -->
            <el-button type="primary" size="small" @click="addAndEditDialog()">
              新增用户
            </el-button>
          </el-col>
        </el-form>
      </el-row>
      <div class="select_warn">
        已经选择{{ selectionArr.length }}项
        <el-button type="text">清空</el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="engName"
          label="英文名"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          header-align="center"
          align="center"
          width="250"
        >
        </el-table-column>
        <el-table-column
          prop="rolesInfoName"
          label="用户角色"
          header-align="center"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="用户状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            {{
              row.status == -1 ? '未激活' : row.status == 1 ? '在职' : '离职'
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="lookdetail(row)"
            >
              <!-- v-if="userbtn.indexOf('LOOKUSER_BTN') >= 0" -->
              查看
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.native.prevent="addAndEditDialog(row)"
              v-if="row.status !== -1"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.native.prevent="enableDisablingRole(row)"
              v-if="row.status !== -1"
            >
              {{ row.status === 1 ? '禁用' : '启用' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <finial style="display: none" />
  </div>
</template>
<script>
import {
  getUserList,
  addUser,
  editUser,
  getRoleList,
  enableDisableUserApi
} from 'api/rightsetting'
import finial from './finial.vue'
export default {
  name: 'statistics',
  components: { finial },
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3456789]\d{9}$/
      if (!value) {
        return callback(new Error('请填写手机号'))
      }
      setTimeout(() => {
        if (phoneReg.test(value)) {
          callback()
        } else {
          callback(new Error('手机号格式错误'))
        }
      }, 100)
    }
    let checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('请填写邮箱'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式错误'))
        }
      }, 100)
    }
    return {
      searchFormData: {
        searchRole: '',
        searchUserName: ''
      },
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: { pageNum: 1, pageSize: 10 },
      userDetailVisible: false,
      userDetailTextVisible: false,
      userDetailOperate: '新增用户',
      roleList: [],
      userFormDetail: {
        username: '',
        name: '',
        engName: '',
        email: '',
        mobile: '',
        roles: []
      },
      userFormRules: {
        username: [
          { required: true, message: '用户账号不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '用户姓名不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        engName: [
          { required: true, message: '英文名称不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        mobile: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        email: [{ required: true, validator: checkEmail, trigger: 'blur' }]
      },
      userDetailText: {},
      chosedRoleList: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const data = {
        ...this.pageMsg
      }
      getUserList(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    // 启用禁用
    enableDisablingRole(data = '') {
      let status = data.status === 1 ? 2 : 1
      // data.status 为1 是启用 去禁用 disable为0
      enableDisableUserApi({ id: data.id, status }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: status === 1 ? '启用成功' : '禁用成功',
            type: 'success'
          })
          this.initData()
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },
    userDialogClose() {
      this.$refs.userDetail.resetFields()
      for (let item in this.userFormDetail) {
        delete this.userFormDetail[item]
      }
    },
    lookdetail(data) {
      this.userDetailText = { ...data }
      this.userDetailTextVisible = true
    },
    addAndEditDialog(data = '') {
      if (data) {
        this.userFormDetail = { ...data }
        this.roleList = data.rolesInfo
        if (data.rolesInfoStr && data.rolesInfoStr.length > 0) {
          this.userFormDetail.roles = data.rolesInfoStr.split(',')
        }
        this.userDetailOperate = '编辑用户'
      } else {
        this.userDetailOperate = '新增用户'
      }
      this.userDetailVisible = true
    },
    saveMsg() {
      this.$refs.userDetail.validate(valid => {
        if (valid) {
          if (this.userFormDetail.id) {
            let sendData = { ...this.userFormDetail }
            sendData.pageSize = this.pageMsg.pageSize
            sendData.pageNum = this.pageMsg.pageNum
            sendData.roles = this.chosedRoleList
            console.log(sendData, 'sendData')
            editUser(sendData).then(res => {
              this.userDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '更新成功'
                })
                if (
                  sendData.id ==
                  JSON.parse(localStorage.getItem('USER_ALL_INFO')).id
                ) {
                  this.$store.dispatch('user/getInfo').then(() => {
                    location.reload()
                  })
                }
                let { pageSize, pageNum } = this.pageMsg
                this.initData({ size: pageSize, num: pageNum })
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          } else {
            let sendData = { ...this.userFormDetail }
            sendData.size = this.pageMsg.pageSize
            sendData.page = this.pageMsg.pageNum
            addUser(sendData).then(res => {
              this.userDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '添加成功'
                })
                let { pageSize, pageNum } = this.pageMsg
                this.initData({ size: pageSize, num: pageNum })
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // handleSizeChange(val) {
    //   this.pageMsg.pageSize = val;
    //   this.pageMsg.pageNum = 1;
    //   let { pageSize, pageNum } = this.pageMsg;
    //   let sendData = { page: pageNum, size: pageSize };
    //   this.initData(sendData);
    // },
    // handleCurrentChange(val) {
    //   this.pageMsg.pageNum = val;
    //   let { pageSize, pageNum } = this.pageMsg;
    //   let sendData = { page: pageNum, size: pageSize };
    //   this.initData(sendData);
    // },
    remoteMethod(val) {
      if (val) {
        getRoleList({ keywords: val, disable: 0 }).then(res => {
          if (res.code === 200) {
            this.roleList = res.result.records
          } else {
            this.$message({
              type: 'error',
              message: res.desc
            })
          }
        })
      } else {
        this.roleList = []
      }
    },
    changeRoleList(data) {
      console.log(data, 'data')
      console.log(this.roleList, 'this.roleList')
      data.map(item => {
        return { id: item, name: this.roleList }
      })
      let sendRoleList = []
      for (let i = 0; i < this.roleList.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (this.roleList[i].id == data[j]) {
            sendRoleList.push({ id: data[j], name: this.roleList[i].name })
          }
        }
      }
      this.chosedRoleList = sendRoleList
      console.log(this.chosedRoleList, 'this.chosedRoleList')
      this.$forceUpdate()
    },
    handleSelectionChange(e) {
      console.log(e)
      this.selectionArr = e
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__header {
  border-bottom: solid 1px #eee !important;
}
</style>
