<template>
  <div>
<!--    <router-view></router-view>-->
    <div class="channelManagement" >
      <div class="channelManagement-head">
      </div>
      <el-card class="box-card">
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="60px">
          <el-row justify='center'>

            <el-col :span="6">
              <el-form-item label="时间:"  >
                <el-date-picker
                  v-model="pageMsg.day"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span='12' align='right'>
              <div>
                <el-button type='primary' @click='search()'>
                  查询
                </el-button>
                <el-button @click='resetClick()'>
                  重置
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>


        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px;margin-top: 10px"
          row-key="id"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          v-loading="tableLoading"
        >

          <el-table-column
            prop="articleTitle"
            label="分发标题"
            header-align="center"
            align="center"
            width='350'
          >
          </el-table-column>

          <el-table-column
            prop="createdTime"
            label="分发时间"
            sortable
            header-align="center"
            align="center"

          >
            <template slot-scope="{ row }">
              {{ formatDate(row.createdTime) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="channelNum"
            label="分发媒介数"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tag>{{ row.channelNum }}</el-tag>
            </template>

          </el-table-column>

          <el-table-column
            label="分发详情"
            header-align="center"
            align="center"
            fixed="right"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click="lookdetail(row)"
              >
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>


        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
        <div id="main" v-show="false" style="width:1000px;height:400px;"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  platformPagesApi,
} from 'api/statistics'
export default {
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    },
  },
  // watch: {
  //   $route: {
  //     handler(to) {
  //       // 初始化操作
  //       if (to.path === '/clientData') {
  //         this.expands = []
  //         this.scopeDetail = []
  //         this.initCompany()
  //         this.initData()
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  data() {
    return {
      tableData:[],
      tableLoading:false,
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10,
        descs: ['created_time'],
        state: null,
        keyWord: '',
        stockName: null,
        day: ''
      },
    }
  },
  created() {
    const clientInfo = localStorage.getItem('USER_ALL_INFO');
    const client = JSON.parse(clientInfo);
    this.pageMsg.stockName = client.companyName;
    this.initData()
  },
  mounted() {
  },
  methods: {
    resetClick(){
      this.pageMsg = this.$options.data().pageMsg
      this.initData();
    },
    search(){
      //转化时间
      this.pageMsg.day = this.transferTimeFun(new Date(this.pageMsg.day));
      this.initData();
    },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      platformPagesApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    lookdetail(row) {
      this.$router.push({
        path: '/clientDataDetail/index',
        query: {
          id: row.id
        }
      })
    },
    // 转换时间
    transferTimeFun(date){
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>
