import apiServer from 'api/apiServer.js'


export function timerTaskLogPageApi(params = {}) {
  let url = `/timerTaskLog/page` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getTimerTaskErrorLogById(params = {}) {
  let url = `/timerTaskLog/getTimerTaskErrorLogById/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}