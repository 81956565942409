var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mine" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "div",
            { staticClass: "mine-form" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账户" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入内容",
                              disabled: true,
                            },
                            model: {
                              value: _vm.username,
                              callback: function ($$v) {
                                _vm.username = $$v
                              },
                              expression: "username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "密码" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.uploadBtn },
                            },
                            [_vm._v("修改密码 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "昵称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入昵称",
                              disabled: true,
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "头像" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadUrl,
                        headers: _vm.myHeaders,
                        "list-type": "picture-card",
                        accept: "image/jpeg,image/jpg,image/png",
                        "on-change": _vm.uploadChange,
                        "on-success": _vm.uploadReturn,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-exceed": _vm.handleExceed,
                        limit: 1,
                        "file-list": _vm.fileList,
                        "on-remove": _vm.companyUploadRemove,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }