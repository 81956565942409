<template>
  <div>
    <router-view></router-view>
    <div class="channelManagement" v-if="$route.path == '/dataStatistics'">
      <div class="channelManagement-head">
      </div>
      <el-card class="box-card">
        <el-row class="text-left">
          <el-form
            :model="pageMsg"
            ref="formData"
            size="small"

            label-width="80px"
          >
            <el-col :span="6">
              <el-form-item label="公司简称:">
                <el-input clearable
                          v-model="pageMsg.stockName"
                          @input="getPinYin(pageMsg.stockName)"
                          style="width: auto"  placeholder="请输入公司简称"
                          @keyup.enter.native="searchData(true)"></el-input>
              </el-form-item>
            </el-col>

<!--            <el-col :span='8'>-->
<!--              <el-form-item label='股票代码:'>-->
<!--                <el-input clearable v-model="pageMsg.stockCode"-->
<!--                          placeholder="请输入股票代码"  style="width: auto"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

          <!--<el-col :span="1"> <p style="opacity: 0"></p> </el-col>-->
            <el-col :span="6">
              <el-form-item label="企业:">
                <el-select
                  v-model="pageMsg.companyId"
                  style="width: auto"
                  filterable
                  placeholder="请填写公司名称或者股票代码"
                  @keyup.enter.native="searchData(true)"
                >
                  <el-option
                    v-for="item in companyOptions"
                    :key="item.id"
                    :label="item.companyName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="时间:"  >
                <el-date-picker

                        v-model="pageMsg.day"
                        type="date"
                        style="width: auto"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择日期">
                </el-date-picker>     </el-form-item>
            </el-col>
            <el-col :span="6" class="text-right">
<!--              <el-button type="primary" size="small" @click="searchData(true)">-->
<!--                查询-->
<!--              </el-button>-->
<!--              <el-button type="primary" size="small" @click="searchData(false)">-->
<!--                重置-->
<!--              </el-button>-->

<!--              <el-button-->
<!--                type="primary"-->
<!--                size="small"-->
<!--                @click="exportNew(multipleSelection)"-->
<!--                v-if="userbtn.indexOf('DATASTATISITICE_LIST_EXPORT') >= 0"-->
<!--              >-->
<!--                导出报告-->
<!--              </el-button>-->
            </el-col>
          </el-form>
        </el-row>

          <el-row>

            <el-col :span='24' align='center'>
              <el-button type="primary" size="small" @click="searchData(true)">
                查询
              </el-button>

              <el-button type="primary" size="small" @click="searchData(false)">
                重置
              </el-button>

              <el-button
                type="primary"
                size="small"
                @click="exportNew(multipleSelection)"
                v-if="userbtn.indexOf('DATASTATISITICE_LIST_EXPORT') >= 0"
              >
                导出报告
              </el-button>

              <el-button
                type="primary"
                size="small"
                @click="pass()"
              >
                无效列表
              </el-button>

              <el-button
                type="primary"
                size="small"
                @click="reject()"
              >
                有效列表
              </el-button>
            </el-col>
          </el-row>


        <!-- <div class="chartDataWarp">
          <chart-box :ref="'expectChart'" id="rowsa" />
        </div> -->
        <!-- <div class="select_warn" v-if="selectionArr.length > 0">
        已经选择
        <span>{{ selectionArr.length }}</span>
        项
        <el-button type="text">通过</el-button>
      </div> -->
        <el-table
          ref="tableList"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          :expand-row-keys="expands"
          v-loading="tableLoading"
          @row-click="clickRowHandle"
          @expand-change="expandColumn"
          @selection-change="handleSelectionChange"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="scope_row_wrap">
                <div class="chartDataWarp">
                  <div
                    class="echarts"
                    :id="scope.row.id"
                    style="width: 300px; height: 300px"
                  ></div>
                  <!-- <chart-box :ref="'expectChart'" id="rowsa" /> -->
                </div>
                <div class="chart-list">
                  <div
                    class="chart-list-item"
                    :class="'list-item' + index"
                    v-for="(d, index) in scopeDetail"
                    :key="d.id"
                  >
                    <h5><i class="circle-item"></i> {{ d.channelName }}</h5>
                    <p v-for="(item, ind) in d.list" :key="item.id">
                      <span v-if="ind < 3">
                        <span @click="openUrl(item.urlLink)">{{
                          item.channelName
                        }}</span>
                      </span>
                    </p>
                    <p v-if="d.list.length > 3">......</p>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="60"  content="center"></el-table-column>

          <el-table-column
            prop="articleTitle"
            label="分发标题"
            header-align="center"
            align="center"
          >
          </el-table-column>



          <el-table-column
            prop="channelTypeList"
            label="媒介类型"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <div v-if="row.channelTypeList.length > 0">
                <span v-for="d in row.channelTypeList" :key="d.id">
                  {{ d.name }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
                  prop="stockName"
                  label="公司简称"
                  header-align="center"
                  align="center"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click.native.prevent="Detail(row)">

                {{ row.stockName }}
              </el-button>
            </template>
          </el-table-column>

          <el-table-column
            prop="stockCode"
            label="股票代码"
            header-align="center"
            align="center">
          </el-table-column>

          <el-table-column
            prop="companyName"
            label="所属企业"
            header-align="center"
            align="center"

          >
          </el-table-column>
          <el-table-column
            prop="createdTime"
            label="分发时间"
            sortable
            header-align="center"
            align="center"

          >
            <template slot-scope="{ row }">
              {{ formatDate(row.createdTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="channelNum"
            label="分发媒介数"
            header-align="center"
            align="center"

          >
          </el-table-column>
          <el-table-column
            label="分发详情"
            header-align="center"
            align="center"
            width="180"
            fixed="right"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click="lookdetail(row)"
                v-if="userbtn.indexOf('DATASTATISITICE_LOOK_DETAIL') >= 0"
              >
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
        <vue-html2pdf
          :show-layout="showLayout"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="数据统计"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a3"
          pdf-orientation="landscape"
          pdf-content-width="1000px"
          :html-to-pdf-options="htmlToPdfOptions"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              @selection-change="handleSelectionChange1"
              v-loading="tableLoading"
            >
              <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
              <el-table-column
                prop="articleTitle"
                label="分发标题"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop=""
                label="媒介类型"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="所属企业"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="createdTime"
                label="分发时间"
                sortable
                header-align="center"
                align="center"
                width="250"
              >
                <template slot-scope="{ row }">
                  {{ formatDate(row.createdTime) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="state"
                label="分发媒介数"
                header-align="center"
                align="center"
                width="180"
              >
              </el-table-column>
            </el-table>
          </section>
        </vue-html2pdf>
        <div id="main" v-show="false" style="width:1000px;height:400px;"></div>
      </el-card>
      <el-dialog  style="width: 1500px"  title="文章列表" :visible.sync="detailBool1" @close="dialogCreate" >
        <el-form
                :model="pageForm"
                ref="formData"
                size="small"
                label-position="left"
                label-width="80px"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item prop="stringTime" label="日期:"  >
                <el-date-picker
                        v-model="pageForm.stringTime"
                        type="date"
                       style="margin-left: -40px"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">

              <el-form-item  >
                <!--          <el-button type="primary" icon="el-icon-download" @click="exportExcelSelect">导出到EXCEL</el-button>-->
                <el-button size="small"   type="primary"  @click="Detail(pageForm)">
                  查询
                </el-button>
                <el-button size="small"  @click="searchDetail">
                  重置
                </el-button>
                <el-button
                        type="primary"
                        size="small"       @click="exportStockName(multipleSelection)" >
                  导出
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-table
                ref="multipleTable"
                :data="tableData1"
                style="width: 100%; margin-bottom: 20px"
                row-key="id"
                @selection-change="handleSelectionChange1"
                header-row-class-name="tableHead"
                :header-cell-style="{ background: 'rgb(250,250,250)' }"
                v-loading="tableLoading1"
        >
          <el-table-column type="selection" width="60"  content="center"></el-table-column>

          <el-table-column
                  prop="articleTitle"
                  label="文章名称"
                  header-align="center"
                  align="center"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click.native.prevent="turnDetail(row)">
                {{ row.articleTitle }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
                  prop="passOrReject"
                  label="审核结果"
                  header-align="center"
                  align="center"
          >

          </el-table-column>
          <el-table-column
                  prop="stringTime"
                  label="发布时间"
                  sortable
                  header-align="center"
                  align="center"
          >
          </el-table-column>
        </el-table>
        <el-pagination
                class="text-center"
                v-if="pageTotal1 > 0"
                @size-change="Detail(pageForm)"
                @current-change="Detail(pageForm)"
                :current-page.sync="pageForm.pageNum"
                :page-size.sync="pageForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageTotal1"
        >
        </el-pagination>
      </el-dialog>
      <el-dialog
              :visible.sync="detailBool"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              width="1200px"
      >
        <releaseDetail :rowID="rowID"/>
      </el-dialog>
    </div>



    <el-dialog   title="无效列表" style="margin-left: 80px"  width="1100px"  :visible.sync="passLoading"  @close="dialogCreate" >
      <el-form
        :model="pageMsg1"
        ref="formData"
        size="small"
        label-position="left"
        label-width="44px"
      >
        <el-row class="text-left" >
          <el-col :span="8">
            <el-form-item label="公司:" >
              <el-input clearable v-model="pageMsg1.stockName"
                        @keyup.enter.native="searchData1(true)"
                        @input="getPinYin2(pageMsg1.stockName)"  style="width: 200px"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item
              label-position="right"
              label-width="0"
              prop="publisherDate1"
            >
              <el-date-picker
                class="change-date-form"
                v-model="publisherDate1"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="dateChange1"
                style="width: 260px"
                :picker-options="pickerOptions"
                :default-value="timeDefaultShow"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="text-right">
            <el-button type="primary" size="small" @click="searchData1(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData1(false)">
              重置
            </el-button>
            <el-button type='primary' size='small' @click='invalidExportClick'>
              导出
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableData1"
        row-key="id"
        @selection-change="handleSelectionChange"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading1"
      >

        <el-table-column
          prop="stockName"
          label="企业简称"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="turnDetail(row)">
              {{ row.stockName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="articleTitle"
          label="动态"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="categoryCodeFirstName"
          label="行业类型"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contentType"
          label="内容类型"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          label="推送类别"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.type == 10">ESG</span>
            <span v-if="row.type == 20">经营管理</span>
            <span v-if="row.type == 30">产品服务</span>
            <span v-if="row.type == 40">发展战略</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="createdTime"
          label="提交时间"
          header-align="center"
          align="center"
          :formatter="formatData"
        >
        </el-table-column>
        <el-table-column
          prop="checkTime"
          label="审核时间"
          header-align="center"

          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="articleUrl"
          label="原文链接"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="bbb(row)">
              {{ row.articleUrl }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="isTimer"
          label="时效"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              v-if="row.isTimer == '准时'"
              style="color: #30b08f"
            >
              <i     style="color: #30b08f"></i>
              {{ row.isTimer }}
            </p>
            <p
              class="state-table"
              v-if="row.isTimer == '超时'"
              style="color: red"
            >
              <i  style="color: red"></i>
              {{ row.isTimer }}
            </p>
            <p
              class="state-table"
              v-if="row.isTimer == '非工作时间信息'"
              style="color: #409eff"
            >
              <i     style="color: #409eff"></i>
              {{ row.isTimer }}
            </p>

          </template>
        </el-table-column>
        <el-table-column
          prop="isTrue"
          label="复核"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              style="color: red"
              v-if="row.isTrue == '不合格'"
            >
              <i style="background: red"></i>
              不合格({{row.passReason}})
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column
           prop="updatedTime"
           label="修改时间"
           header-align="center"
           align="center"
           width="170"
           :formatter="formatData"
         >
         </el-table-column>-->

        <el-table-column
          label="状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              v-if="row.authState == 10"
              style="color: #409eff"
            >
              <i style="background: #409eff"></i>
              审核中
            </p>
            <p
              class="state-table"
              v-if="row.authState == 20"
              style="color: #30b08f"
            >
              <i style="background: #30b08f"></i>有效
            </p>
            <p
              class="state-table"
              v-if="row.authState == 30"
              style="color: red"
            >
              <i style="background: red"></i>无效({{ row.failReason }})
            </p>


          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="passItemTrue3(row)"
              v-if="userbtn.indexOf('ARTICLE_SEND_BHG') >= 0"
            >
              不合格
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
        class="text-center"
        v-if="pageTotal1 > 0"
        @size-change="pass(pageMsg1)"
        @current-change="pass(pageMsg1)"
        :current-page.sync="pageMsg1.pageNum"
        :page-size.sync="pageMsg1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal1"
      >
      </el-pagination>
    </el-dialog>


    <el-dialog    title="有效列表" style="margin-left: 80px"  width="1100px"   :visible.sync="passLoading1"  @close="dialogCreate1" >

      <el-form
        :model="pageMsg2"
        ref="formData"
        size="small"
        label-position="left"
        label-width="44px"
      >
        <el-row class="text-left" >
          <el-col :span="8">
            <el-form-item label="公司:" >
              <el-input clearable v-model="pageMsg2.stockName"
                        @keyup.enter.native="searchData2(true)"
                        @input="getPinYin1(pageMsg2.stockName)"  style="width: 200px"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item
              label-position="right"
              label-width="0"
              prop="publisherDate2"
            >
              <el-date-picker
                class="change-date-form"
                v-model="publisherDate2"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="dateChange2"
                style="width: 260px"
                :picker-options="pickerOptions"
                :default-value="timeDefaultShow"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="text-right">
            <el-button type="primary" size="small" @click="searchData2(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData2(false)">
              重置
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableData2"
        row-key="id"
        @selection-change="handleSelectionChange"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading2"
      >

        <el-table-column
          prop="stockName"
          label="企业简称"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="turnDetail(row)">
              {{ row.stockName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="articleTitle"
          label="动态"
          header-align="center"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="categoryCodeFirstName"
          label="行业类型"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contentType"
          label="内容类型"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          label="推送类别"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.type == 10">ESG</span>
            <span v-if="row.type == 20">经营管理</span>
            <span v-if="row.type == 30">产品服务</span>
            <span v-if="row.type == 40">发展战略</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="createdTime"
          label="提交时间"
          header-align="center"

          align="center"
          :formatter="formatData"
        >
        </el-table-column>
        <el-table-column
          prop="checkTime"
          label="审核时间"
          header-align="center"

          align="center"

        >
        </el-table-column>
        <el-table-column
          prop="articleUrl"
          label="原文链接"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="bbb(row)">
              {{ row.articleUrl }}
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column
           prop="updatedTime"
           label="修改时间"
           header-align="center"
           align="center"
           width="170"
           :formatter="formatData"
         >
         </el-table-column>-->
        <el-table-column
          prop="isTimer"
          label="时效"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              v-if="row.isTimer == '准时'"
              style="color: #30b08f"
            >
              <i     style="color: #30b08f"></i>
              {{ row.isTimer }}
            </p>
            <p
              class="state-table"
              v-if="row.isTimer == '超时'"
              style="color: red"
            >
              <i  style="color: red"></i>
              {{ row.isTimer }}
            </p>
            <p
              class="state-table"
              v-if="row.isTimer == '非工作时间信息'"
              style="color: #409eff"
            >
              <i     style="color: #409eff"></i>
              {{ row.isTimer }}
            </p>

          </template>
        </el-table-column>
        <el-table-column
          prop="isTrue"
          label="复核"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              style="color: red"
              v-if="row.isTrue == '不合格'"
            >
              <i style="background: red"></i>
              不合格({{row.passReason}})
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              v-if="row.authState == 10"
              style="color: #409eff"
            >
              <i style="background: #409eff"></i>
              审核中
            </p>
            <p
              class="state-table"
              v-if="row.authState == 20"
              style="color: #30b08f"
            >
              <i style="background: #30b08f"></i>有效({{ row.failReason }})
            </p>
            <p
              class="state-table"
              v-if="row.authState == 30"
              style="color: red"
            >
              <i style="background: red"></i>无效({{ row.failReason }})
            </p>


          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="passItemTrue4(row)"
              v-if="userbtn.indexOf('ARTICLE_SEND_BHG') >= 0"
            >
              不合格
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
        class="text-center"
        v-if="pageTotal2 > 0"
        @size-change="reject(pageMsg2)"
        @current-change="reject(pageMsg2)"
        :current-page.sync="pageMsg2.pageNum"
        :page-size.sync="pageMsg2.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal2"
      >
      </el-pagination>
    </el-dialog>


  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import { articlecontentApi, articleExportSelectListApi, articlePageApi, invalidExportApi, selectApi } from 'api/article'

import {
  articlePushDataStatisticsApi,
  detailsPlatformApi,
  exportWordDownApi,
  getTitleByIdApi,
  platformExportApi,
  platformPageApi,
  statisticsPieCharApi
} from 'api/statistics'
import PizZip from 'pizzip'
import docxtemplater from 'docxtemplater'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import { companyPageApi } from 'api/company'
import { statisticsOption } from '@/util/js/statisticsEcharts'
import * as echarts from 'echarts'
import releaseDetail from '../release/releaseDetail.vue'

export default {
  name: 'channelManagement',
  components: {
    // PdfPrint,
    VueHtml2pdf, releaseDetail,

  },
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    },
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: '数据统计.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a3',
          orientation: 'portrait'
        }
      }
    }
  },
  watch: {
    $route: {
      handler(to) {
        // 初始化操作
        if (to.path === '/dataStatistics') {
          this.expands = []
          this.scopeDetail = []
          this.initCompany()
          this.initData()
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      pageTotal2: 0,
      publisherDate1: [], //时间选择器筛选
      publisherDate2: [], //时间选择器筛选
      tableData2: [],
      rowID: null,
      rowID1: null,
      timeDefaultShow: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      pageMsg1: {
        pageNum: 1,
        pageSize: 10,
        pass: 1,
        descs: ['created_time'],
        companyId: '',
        authState: '',
        startTime: '',
        startT: null,
        endTime: '',
        endT: null,
        stockName: null,
        contentTypeId: null
      },
      pageMsg2: {
        pageNum: 1,
        pageSize: 10,
        pass: 2,
        descs: ['created_time'],
        companyId: '',
        authState: '',
        startTime: '',
        startT: null,
        endTime: '',
        endT: null,
        stockName: null,
        contentTypeId: null
      },
      tableLoading2:false,
      passLoading: false,
      passLoading1: false,
        multipleSelection: [],
      showLayout: false,
      companyOptions: [],
      selectionArr: [],
      tableData: [],
      tableData1: [],
      pageTotal: 0,
        pageForm:{
            pageNum: 1,
            pageSize: 100,
            stockName: '',
            num: 1,
            stringTime: ''
        },
        pageTotal1: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 100,
        descs: ['created_time'],
        state: null,
        keyWord: '',
          stockName: '',
          day: ''
      },
      tableLoading: false,
      tableLoading1: false,
        detailBool1: false,
        detailBool: false,
        dl: false,
        img2: '',
        img1: '',
      expands: [],
      scopeDetail: [],
        topParams: {
            pushApiNum: 0, //api媒介数量
            channelTypeNum: 0, //媒介类型名称
            readNum: 0 //阅读量
        },
        detailParams: [], // 推送列表
        articleParams: {}, //文章详情
    }
  },
  created() {
    this.timeDefaultShow = new Date()
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
  },
  mounted() {
    this.initCompany()
    this.initData()
    this.expands = []
    this.scopeDetail = []
    // this.$nextTick(() => {
    //   this.initChart()
    //   // this.$refs.expectChart.draw(statisticsOption)
    // })
  },
  methods: {
    turnDetail1(row) {
      this.rowID1 = row.id
      this.detailBool = true
    },
    searchData2(bool) {
      if (bool) {
        this.pageMsg2.pageNum = 1
        this.pageMsg2.pageSize = 10
      } else {
        this.publisherDate2=[]
        this.pageMsg2 = this.$options.data().pageMsg2
      }
      this.reject()
    },
    getPinYin1(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */
      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg2.stockName =name.toUpperCase();
      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg2.stockName)
    },
    searchData1(bool) {
      if (bool) {
        this.pageMsg1.pageNum = 1
        this.pageMsg1.pageSize = 10
      } else {
        this.pageMsg1 = this.$options.data().pageMsg1
        this.publisherDate1=[]
      }
      this.pass()
    },
    getPinYin2(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */
      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg1.stockName =name.toUpperCase();

      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg1.stockName)
    },
    dialogCreate1() {
      this.passLoading1 = false
      this.pageMsg2= this.$options.data().pageMsg2
      this.publisherDate2 = [];
    },
    formatData(row, column, cellValue, index) {
      //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
      //最终返回 s 就可以了
      var s = new Date(cellValue).toLocaleString()
      return s

      // 测试参数含义：不知道参数是什么含义的就打印出来
    },
    //无效列表导出
    invalidExportClick(){
      const data = {
        ...this.pageMsg1
      }
      invalidExportApi(data).then(data => {
        // debugger;
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        // let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
        link.download = `无效列表.csv`
        link.href = blobUrl;
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    dateChange1() {
      if (this.publisherDate1 && this.publisherDate1.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg1.startTime = this.publisherDate1[0]
        this.pageMsg1.startT = this.publisherDate1[0]+ " 00:00:00"
        this.pageMsg1.endTime = this.publisherDate1[1]
        this.pageMsg1.endT = this.publisherDate1[1]+ ' 23:59:59'
      }

    },
    dateChange2() {
      if (this.publisherDate2 && this.publisherDate2.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg2.startTime = this.publisherDate2[0]
        this.pageMsg2.startT = this.publisherDate2[0]+ " 00:00:00"
        this.pageMsg2.endTime = this.publisherDate2[1]
        this.pageMsg2.endT = this.publisherDate2[1]+ ' 23:59:59'
      }

    },
    pass(){
      this.passLoading = true;
      this.tableLoading1 = true
      const data = {
        ...this.pageMsg1
      }
      data.authState = 30;
      articlePageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData1 = records
          this.pageTotal1 = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading1 = false
      })
    },
    reject(){
      this.passLoading1 = true;
      this.tableLoading2 = true
      const data = {
        ...this.pageMsg2
      }
      data.authState = 20;
      articlePageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData2 = records

          this.pageTotal2 = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading2 = false
      })
    },
      getPinYin(name){
          /**
           * 声明变量存储首拼
           */
          var char = ''
          /**
           * 调用设置属性
           */

          /**
           * 跟据传入文字调用方法进行转换
           */
          this.pageMsg.stockName =name.toUpperCase();

          /**
           * 赋值检索码
           */
          console.log('char', this.pageMsg.stockName)
      },
      handleSelectionChange1 (val) {
          this.multipleSelection = []
          for (var i = val.length - 1; i >= 0; i--) {
              this.multipleSelection.push(val[i].id)
          }
      },
      exportStockName() {
          let arr =   this.multipleSelection.join()
          let arrString =   arr.split(',')
          for (var  b= arrString.length - 1; b >= 0; b--) {
              let c =  parseInt(arrString[b])
              let  articleParams = {}
              let  detailParams = []
              let  topParams = {}
              articlePushDataStatisticsApi(c).then(res => {
                  topParams = { ...res.result }
              })
              articlecontentApi(c).then(res => {
                  articleParams= { ...res.result }
                  articleParams.nowTime = new Date().toLocaleDateString()
                  articleParams.createdTime = new Date(
                      articleParams.createdTime
                  ).toLocaleDateString()
                  detailsPlatformApi(c).then(res => {
                      detailParams = res.result
                      detailParams.map(res => {
                          for (let i = 0; i <res.list.length; i++) {
                              res.list[i].isEdit = false
                              res.list[i].createdTime = new Date(res.createdTime).toLocaleString()

                              if ( res.list[i].monthlyActivity) {
                                  let str  =  res.list[i].monthlyActivity.toString()
                                  if (str.length > 4) {
                                      res.list[i].numberCount = str.substring(0, str.length - 4) + "(万)";
                                  } else {
                                      res.list[i].numberCount = '1(万)'
                                  }


                              } else {
                                  res.list[i].numberCount = "/"
                              }

                              if ( res.list[i].remarks) {
                                  res.list[i].redBook =  res.list[i].remarks;
                              } else {
                                  res.list[i].redBook = "/"
                              }


                              if ( res.list[i].urlLinkType === 10) {
                                  res.list[i].hasText = true;
                                  res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看链接' + '</a>';
                                  res.list[i].has = false;

                              } else if ( res.list[i].urlLinkType === 20) {
                                  res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看截图' + '</a>';
                              } else {
                                  res.list[i].hasHtml ="/"
                              }
                          }
                     });
                      this.dl = true
                      this.aaaa(articleParams,detailParams,topParams,this.dl);

                  })


              })


              /**word列表文字转为图片 二进制 {%hasBaseImg} */
          }

      },
      dialogCreate () {
          this.detailBool1 = false
          this.pageForm.num = 0;
          this.pageForm.stringTime = '';

      },
    // 本页面文章
      turnDetail(row) {
          this.rowID = row.id
          this.detailBool = true
      },
    addAndEditDialog(row) {
      this.rowID = row.id
    },
    downPdf() {
      this.$refs.html2Pdf.generatePdf()
      this.rowID = null
    },
      downloadPatternTitle() {
          articleExportSelectListApi({
              ...this.pageForm
          }).then(data => {
              let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
              // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
              let blobUrl = window.URL.createObjectURL(blob)
              let link = document.createElement('a')
              link.style.display = 'none'
              // let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
              link.download = `发布文章.csv`


              link.href = blobUrl;
              link.click()
              link.remove()
              // 5.释放这个临时的对象url
              window.URL.revokeObjectURL(blobUrl)
          })
      },
      searchDetail() {
          this.pageForm.num = 0;
          this.pageForm.stringTime=''
          this.Detail(this.pageForm)
      },
      Detail(row) {
          this.detailBool1 = true
          this.tableLoading1 = true
          this.pageForm.stockName = row.stockName

          selectApi(this.pageForm).then(res => {
              if (res.code === 200) {
                  let { total, records } = res.result
                  this.tableData1 = records
                  if (records.size > 0) {
                      this.pageForm.stockName = records[0].stockName;
                  }

                  this.pageTotal1 = total
              } else {
                  this.$message({
                      type: 'error',
                      message: res.desc
                  })
              }
              this.tableLoading1 = false
          })
      },
    // 导出
    platformExport() {
      platformExportApi({
        ...this.pageMsg
      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = new Date().toLocaleDateString()
        link.download = `数据导出列表${dateXls}.csv`
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    initChart(id) {
      let mychart = echarts.init(document.getElementById(id))
      mychart.setOption(statisticsOption)
        this.img2 =  mychart.getDataURL({
            type: 'jpg',
            pixelRatio: 1,
            backgroundColor:'white'
        })

    },
    clickRowHandle(row, expandedRows) {

      if (expandedRows.id) {
        //说明此时是展开的
        //找到这个row的id
        let id = row.id
        let chartsBool = true
        //让所有项收起来，这是做成手风琴accordion的形式
        //让刚刚展开的项放到expands数组中

        if (row) {
          if (this.expands.length) {
            if (this.expands[0] == row.id) {
              this.expands = []
              chartsBool = false
            } else {
              this.expands = []
              this.expands.push(row.id)
            }
          } else {
            this.expands = []
            this.expands.push(row.id)
          }
        }
        //在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM
        this.$nextTick(() => {
          // 数据处理

          statisticsPieCharApi(id).then(res => {
            this.scopeDetail = res.result
            let num = 0;
            this.scopeDetail.forEach(item => {
              num +=item.length;
            })
            let arr = []
            this.scopeDetail.map((item, index) => {
              arr.push({
                value: item.length,
                name: item.channelName
              })
            })
            statisticsOption.series[0].data = arr
            statisticsOption.title.text = num
            if (chartsBool) {
              this.initChart(id)
            }
          })

          // statisticsPieCharApi(id).then(res => {
          //   this.scopeDetail = res.result
          //   let arr = []
          //   res.result.list.map((item, index) => {
          //     if (index > 5) {
          //     } else {
          //       arr.push({
          //         value: item.pushApiNum,
          //         name: item.channelTypeName
          //       })
          //     }
          //   })
          //   statisticsOption.series[0].data = arr
          //   statisticsOption.title.text = res.result.num
          //   if (chartsBool) {
          //     this.initChart(id)
          //   }
          // })
          //这个id对应上边的row.id
        })
      } else {
        //此时是收起
        //收起所有
        this.expands = []
      }
    },
      expandColumn (row, expandedRows) {

      // 每次只展开一行
      let that = this

      if (expandedRows.length) {
        //说明此时是展开的
        //找到这个row的id
        let id = row.id

        //让所有项收起来，这是做成手风琴accordion的形式
        this.expands = []
        //让刚刚展开的项放到expands数组中
        if (row) {
          this.expands.push(row.id)
        }
        //在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM
        this.$nextTick(() => {
          // 数据处理

          statisticsPieCharApi(id).then(res => {
            this.scopeDetail = res.result
            let num = 0;
            this.scopeDetail.forEach(item => {
              num +=item.length;
            })
            let arr = []
            this.scopeDetail.map((item, index) => {
              arr.push({
                value: item.length,
                name: item.channelName
              })
            })
            statisticsOption.series[0].data = arr
            statisticsOption.title.text = num
              this.initChart(id)
          })

          // statisticsPieCharApi(id).then(res => {
          //
          //   this.scopeDetail = res.result
          //   let arr = []
          //   res.result.list.map((item, index) => {
          //     if (index > 5) {
          //     } else {
          //       arr.push({
          //         value: item.pushApiNum,
          //         name: item.channelTypeName
          //       })
          //     }
          //   })
          //   statisticsOption.series[0].data = arr
          //
          //
          //   statisticsOption.title.text = res.result.num
          //   this.initChart(id)
          // })
          //这个id对应上边的row.id
        })
      } else {
        //此时是收起
        //收起所有
        this.expands = []
      }
    },
    show: function () {
      this.showLayout = !this.showLayout
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      platformPageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    lookdetail(row) {
      this.$router.push({
        path: '/dataStatistics/detail',
        query: {
          id: row.id
        }
      })
    },
    openUrl(url) {

      console.log("url"+url)

      if (url) {
        if (url.includes('http') || url.includes('http')) {
          window.open(url, '_blank')
        } else {
          this.$message.warning(`请配置合法跳转路由！！`)
        }
      }
    },
    initCompany() {
      //初始化公司列表
      companyPageApi({
        pageNum: 1,
        pageSize: 100
      }).then(res => {
        if (res.code === 200) {
          this.companyOptions = res.result.records
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 100
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    handleSelectionChange(e) {
      this.selectionArr = e
        this.multipleSelection = []
        for (var i = e.length - 1; i >= 0; i--) {
            this.multipleSelection.push(e[i].id)
        }

        let arr =   this.multipleSelection.join()
        let arrString =   arr.split(',')
        for (var  b= arrString.length - 1; b >= 0; b--) {
           let c =  parseInt(arrString[b])

        }
    },

    //更改导出报告
    exportNew(){
        if(this.multipleSelection.length > 0){
          let arr =   this.multipleSelection.join()
          let arrString =   arr.split(',')
          for (var  b= arrString.length - 1; b >= 0; b--) {
            let dateXls = "";
            let c =  parseInt(arrString[b])
            exportWordDownApi(c).then( data => {
              getTitleByIdApi(c).then(res => {
                const stock = res.result;
                console.log("公司名称"+stock.stockName)
                console.log("标题:"+stock.articleTitle)
                dateXls = res.result.articleTitle;
                let blob = new Blob([data.data])
// 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                // let dateXls = this.articleParams.articleTitle;
                link.download = stock.stockName+"-"+stock.articleTitle+"分发报告.pdf"
                link.href = blobUrl
                link.click()
                link.remove()
// 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
              })
//               let blob = new Blob([data.data])
// // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
//               let blobUrl = window.URL.createObjectURL(blob)
//               let link = document.createElement('a')
//               link.style.display = 'none'
//               // let dateXls = this.articleParams.articleTitle;
//               link.download = `${dateXls}"分发报告.pdf`
//               link.href = blobUrl
//               link.click()
//               link.remove()
// // 5.释放这个临时的对象url
//               window.URL.revokeObjectURL(blobUrl)
            })
          }
        }else {
          return   this.$message.error('请先勾选')

        }

    },


      exportA() {
          if (this.multipleSelection.length >0) {
              let arr =   this.multipleSelection.join()
              let arrString =   arr.split(',')

              for (var  b= arrString.length - 1; b >= 0; b--) {
                  let c =  parseInt(arrString[b])

                  let  articleParams = {}
                  let  detailParams = []
                  let  topParams = {}
                  articlePushDataStatisticsApi(c).then(res => {
                      topParams = { ...res.result }
                  })
                  articlecontentApi(c).then(res => {
                      articleParams= { ...res.result }
                      articleParams.nowTime = new Date().toLocaleDateString()
                      articleParams.createdTime = new Date(
                          articleParams.createdTime
                      ).toLocaleDateString()
                      detailsPlatformApi(c).then(res => {
                          detailParams = res.result
                          detailParams.map(res => {
                              for (let i = 0; i <res.list.length; i++) {
                                  res.list[i].isEdit = false
                                  res.list[i].createdTime = new Date(res.createdTime).toLocaleString()

                                  if ( res.list[i].monthlyActivity) {
                                      let str  =  res.list[i].monthlyActivity.toString()
                                      if (str.length > 4) {
                                          res.list[i].numberCount = str.substring(0, str.length - 4) + "(万)";
                                      } else {
                                          res.list[i].numberCount = '1(万)'
                                      }


                                  } else {
                                      res.list[i].numberCount = "/"
                                  }

                                  if ( res.list[i].remarks) {
                                      res.list[i].redBook =  res.list[i].remarks;
                                  } else {
                                      res.list[i].redBook = "/"
                                  }


                                  if ( res.list[i].urlLinkType === 10) {
                                      res.list[i].hasText = true;
                                      res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看链接' + '</a>';
                                      res.list[i].has = false;

                                  } else if ( res.list[i].urlLinkType === 20) {
                                      res.list[i].hasHtml = '<a href="' +  res.list[i].urlLink + '">' + '查看截图' + '</a>';
                                  } else {
                                      res.list[i].hasHtml ="/"
                                  }
                              }
                           });
                          let detailId = c

                          this.dl = true
                          this.aaaa(articleParams,detailParams,topParams,this.dl,detailId);
                      })

                  })
            }

          }else {
            return   this.$message.error('请先勾选')
          }

      },
      base64DataURLToArrayBuffer(dataURL) {
          const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/
          if (!base64Regex.test(dataURL)) {
              return false
          }
          const stringBase64 = dataURL.replace(base64Regex, '')
          let binaryString
          if (typeof window !== 'undefined') {
              binaryString = window.atob(stringBase64)
          } else {
              binaryString = new Buffer(stringBase64, 'base64').toString('binary')
          }
          const len = binaryString.length
          const bytes = new Uint8Array(len)
          for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i)
              bytes[i] = ascii
          }
          return bytes.buffer
      },
    bbb(row) {
      let routeData = row.articleUrl;
      window.open(routeData);
    },
    async  aaaa(articleParams,detailParams,topParams,d,detailId) {

        if (d === true) {
            //这里要引入处理图片的插件，下载docxtemplater后，引入的就在其中了
            var ImageModule = require('docxtemplater-image-module-free');
            var HtmlModule = require('docxtemplater-html-module')
            var fs = require('fs')
            // const expressions = require('angular-expressions')
            let _this = this
            // 读取并获得模板文件的二进制内容，放在项目中即可
            JSZipUtils.getBinaryContent(
                'text08.docx',
                function (error, content) {
                    if (error) {
                        throw error
                    }

                    statisticsPieCharApi(detailId).then(res => {
                            let arr = []
                            let arr1 = []
                            /*  res.result.list.map((item, index) => {
                                  if (index > 5) {
                                  } else {
                                      arr.push({

                                          name: item.chdocannelTypeName
                                      })
                                      arr1.push({
                                          value: item.pushApiNum,

                                      })
                                  }

                              })*/
                            for (let i = 0; i <res.result.list.length; i++) {
                                arr.push(res.result.list[i].channelTypeName)
                                arr1.push(res.result.list[i].pushApiNum)

                            }
                             var myChart = echarts.init(document.getElementById('main'));

                            // 指定图表的配置项和数据
                            var
                                option = {
                                    animation:false,

                                    xAxis: {
                                        type: 'category',
                                        data: arr
                                    },
                                    yAxis: {
                                        type: 'value',
                                        min:0,
                                        max:10
                                    },
                                    series: [
                                        {
                                            data: arr1,
                                            type: 'bar',
                                            label: {
                                                show: true,
                                                position: 'top'
                                            },

                                            itemStyle: {
                                                normal: {
                                                    //这里是颜色
                                                    color: function(params) {
                                                        //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                                        var colorList = ['#00A3E0','#9d2933', '#fa8c35', '#fff143', '#789262','#424c50', '#fcefe8'];
                                                        return colorList[params.dataIndex]
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                };

                            option && myChart.setOption(option);

                            _this.img2 =  myChart.getDataURL({
                                type: 'jpg',
                                pixelRatio: 1,
                                backgroundColor:'white'
                            })

                            // 图片处理
                            let opts = {}
                            opts = { centered: false }
                            opts.getImage = chartId => {
                                return _this.base64DataURLToArrayBuffer(chartId)
                            }
                            opts.getSize = function (img, tagValue, tagName) {
                                //自定义指定图像大小，此处可动态调试各别图片的大小
                                if (tagName === 'img2') return [600, 400]
                                return [50, 50]
                            }

                            // 创建一个PizZip实例，内容为模板的内容
                            let zip = new PizZip(content)
                            // 创建并加载docxtemplater实例对象
                            let doc = new docxtemplater()
                            let htmlModule = new HtmlModule({ tags: { p: { paragraph: true } } })
                            doc.attachModule(htmlModule)
                            doc.attachModule(new ImageModule(opts))
                            doc.loadZip(zip)

                            doc.setData({
                                ...articleParams,
                                ...topParams,
                                table: detailParams,
                                //img1: url,
                                img2: _this.img2,
                                img1: _this.img1
                            })

                            try {
                                // 用模板变量的值替换所有模板变量
                                doc.render()
                            } catch (error) {
                                // 抛出异常
                                let e = {
                                    message: error.message,
                                    name: error.name,
                                    stack: error.stack,
                                    properties: error.properties
                                }

                                throw error
                            }

                            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                            let out = doc.getZip().generate({
                                type: 'blob',
                                mimeType:
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            })

                            // 将目标文件对象保存为目标类型的文件，并命名

                                saveAs(
                                    out,
                                    `${articleParams.stockName}-${articleParams.articleTitle}-分发报告-${articleParams.nowTime}.docx`)

                        }
                    )
                })

        }

      },
    closeAdd() {
      this.dialogAdd = false
      this.dialogSet = false
      this.initData()
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  .scope_row_wrap {
    display: flex;
    justify-content: space-around;
    .chartDataWarp {
      width: 300px;
      height: 300px;
    }
    .chart-list {
      display: flex;
      flex-wrap: wrap;
      width: 300px;
      .chart-list-item {
        margin-right: 20px;
        span {
          cursor: pointer;
          &:hover {
            color: #0096eb !important;
          }
        }
        a {
          text-decoration: none;
          color: #606266;
        }
      }
      .circle-item {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
      }
      .list-item0 {
        h5 {
          color: #ff8452;
        }
        .circle-item {
          background: #ff8452;
        }
      }
      .list-item0 {
        h5 {
          color: #ff8452;
        }
        .circle-item {
          background: #ff8452;
        }
      }
      .list-item1 {
        h5 {
          color: #5470c6;
        }
        .circle-item {
          background: #5470c6;
        }
      }
      .list-item2 {
        h5 {
          color: #91cc75;
        }
        .circle-item {
          background: #91cc75;
        }
      }
      .list-item3 {
        h5 {
          color: #fac858;
        }
        .circle-item {
          background: #fac858;
        }
      }
      .list-item4 {
        h5 {
          color: #ee6666;
        }
        .circle-item {
          background: #ee6666;
        }
      }
      .list-item5 {
        h5 {
          color: #73c0de;
        }
        .circle-item {
          background: #73c0de;
        }
      }
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
