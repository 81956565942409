<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">

        <el-form
                :model="pageMsg"
                ref="formData"
                size="small"
                label-position="center"
                label-width="60px"
        >
            <el-row>
                <el-col :span="6">
                    <el-form-item label="简称:" >
                        <el-input clearable   @keyup.enter.native="searchData(true)"
                                  v-model="pageMsg.stockName"  @input="getPinYin(pageMsg.stockName)" style="width: 150px"  placeholder="请输入公司简称"></el-input>
                    </el-form-item>

                </el-col>
                <!-- <el-col :span="1">
                  <p style="opacity: 0"></p>
                </el-col> -->
                <el-col :span="6">
                    <el-form-item label="市场:">
                        <el-select v-model="pageMsg.contentType"
                                 style="width: 100px">
                            <el-option
                                    v-for="item in optionContent"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="6">
                    <el-form-item label="行业:"    class="limitinputWidth"  style="width: 200px">
                        <el-cascader

                                :options="options"
                                @change="handleChange"
                                v-model="pageMsg.industryCategoryArr"
                                :props="{ checkStrictly: 'true' }"
                        ></el-cascader>
                    </el-form-item>
                </el-col>

                <el-col :span="6"  align="left" >
                    <el-form-item  >
                        <el-radio-group v-model="pageMsg.isDel">
                            <el-radio :label="1" style="margin-right: 10px">启用</el-radio>
                            <el-radio :label="0" style="margin-right: 10px">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>

                <el-row >
                    <el-col :span="6"    v-if="userbtn.indexOf('BASIC_STOCK_FPSS') >= 0" >
                        <el-form-item label="分配:" >
                            <el-select v-model="pageMsg.userId"   placeholder="请选择账户名称"    style="width: 150px">
                                <el-option
                                        v-for="item in optionUserId1"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                  <el-col :span='6'>
                    <el-form-item label="辖区:">
                    <el-select v-model="pageMsg.secBureau" placeholder="请选择">
                      <el-option
                        v-for="item in secBureauOptions"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span='6'>
                    <el-form-item label='类型'>
                      <el-radio-group v-model="pageMsg.validOrInvalidType">
                        <el-radio :label="0">有效</el-radio>
                        <el-radio :label="1">无效</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

          <el-row>
            <el-col :span="24" align='center'>
              <el-button
                type="primary"
                size="small"
                @click="basicStockDeleteAll(pageMsg)"
              >
                邮件推送
              </el-button>

              <el-button
                type="primary"
                size="small"
                @click="plfp(pageMsg)"
              >
                批量分配
              </el-button>

              <el-button
                type="primary"
                size="small"
                @click="pljc(pageMsg)"
                v-if="userbtn.indexOf('BASIC_STOCK_PLJC') >= 0"
              >
                批量解除
              </el-button>
              <!--                  城市-->
              <!--                    <el-form-item  label="城市:"  >-->
              <!--                        <el-select v-model="pageMsg.city"  filterable   style="width: 150px">-->
              <!--                            <el-option-->

              <!--                                    v-for="item in optionsCity"-->
              <!--                                    :key="item.value"-->
              <!--                                    :label="item.value"-->
              <!--                                    :value="item.value">-->
              <!--                            </el-option>-->
              <!--                        </el-select>-->
              <!--                    </el-form-item>-->

              <el-button
                type="primary"
                size="small"
                v-if="userbtn.indexOf('BASIC_STOCK_ADD') >= 0"
                @click="reject()"
              >
                添加信息
              </el-button>

              <!--                    <el-button-->
              <!--                            type="primary"-->
              <!--                            size="small"-->
              <!--                            @click="plkq(pageMsg)"-->
              <!--                            v-if="userbtn.indexOf('BASIC_STOCK_PLQY') >= 0"-->
              <!--                    >-->
              <!--                        批量启用-->
              <!--                    </el-button>-->
              <!--                    <el-button-->
              <!--                            type="primary"-->
              <!--                            size="small"-->
              <!--                            @click="plgb(pageMsg)"-->
              <!--                            v-if="userbtn.indexOf('BASIC_STOCK_PLJY') >= 0"-->
              <!--                    >-->
              <!--                        批量禁用-->
              <!--                    </el-button>-->

              <!--                    <el-button-->
              <!--                            type="primary"-->
              <!--                            size="small"-->
              <!--                            @click="plfp(pageMsg)"-->
              <!--                            v-if="userbtn.indexOf('BASIC_STOCK_PLFP') >= 0"-->
              <!--                    >-->
              <!--                        批量分配-->
              <!--                    </el-button>-->
              <el-button
                type="primary"
                size="small"
                @click="secBureauAllocationShowClick"
              >
                辖区分配
              </el-button>
              <el-button size="small" @click="searchData(false)">
                重置
              </el-button>
              <el-button type="primary" size="small" @click="searchData(true)">
                查询
              </el-button>
              <el-button type='primary' size="small" @click='basicStockAllExport(0)'>
                导出
              </el-button>
              <el-button type='primary' size="small" @click='basicStockAllExport(1)'>
                导出(不带联系方式)
              </el-button>
              <el-button type='primary' size="small" @click='relevant'>批量操作</el-button>
            </el-col>

          </el-row>

        </el-form>
      <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px;margin-top: 10px"
              row-key="id"
              @selection-change="handleSelectionChange"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              v-loading="tableLoading"
      >
         <!-- <el-table-column  type="selection" width="55" >
          </el-table-column>-->

          <el-table-column type="selection" width="60"  content="center"></el-table-column>
        <el-table-column
                prop="stockName"
                label="公司简称"
                header-align="center"
                align="center"
                width="200px"
        >
            <template slot-scope="{ row }">
                <div style="display: flex; align-items: center">
                    <el-switch
                            v-model="row.bool"
                            @change="switchEvent($event, row)"
                    >
                    </el-switch>
                    <p style="margin-left: 20px">{{ row.stockName }}</p>
                </div>
            </template>
        </el-table-column>
        <el-table-column
                prop="stockCode"
                label="股票代码"
                header-align="center"
                align="center"
                width="auto"
        >
        </el-table-column>
          <el-table-column
                  prop="industryCatePathName"
                  label="行业类型"
                  header-align="center"
                  align="center"
                  width="auto"
          >
          </el-table-column>
          <el-table-column
                  prop="stockType"
                  label="股票类型"
                  header-align="center"
                  align="center"
                  width="auto"
          >
          </el-table-column>
        <el-table-column
                prop="email"
                label="邮箱"
                header-align="center"
                align="center"
                width="auto"
        >
        </el-table-column>
          <el-table-column
                  prop="website"
                  label="公司网址"
                  header-align="center"
                  align="center"
                  width="auto"
          >
          </el-table-column>


<!--      所属地  -->
<!--          <el-table-column-->
<!--                  prop="city"-->
<!--                  label="所属地"-->
<!--                  header-align="center"-->
<!--                  align="center"-->
<!--                  width="auto"-->
<!--          >-->
<!--              <template slot-scope="{ row }">-->
<!--                  {{ row.province }}-{{row.city}}-->
<!--              </template>-->
<!--          </el-table-column>-->


        <el-table-column
        prop='secBureau'
        label="辖区"
        header-align="center"
        align="center"
        width="auto"
        >
        </el-table-column>

        <el-table-column
          prop='validSum'
          label="有效条数"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop='invalidSum'
          label="无效条数"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="relatedSalesName"
          label="销售人员"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
                label="操作"
                header-align="center"
                align="center"
                fixed="right"
        >

            <template slot-scope="{ row }" >
                <el-button size="small" @click="edit(row)"   type="text" >
                    编辑
                </el-button>
                <el-button size="small"  v-if="row.show === 1
"   type="text"  @click='getRelevanceInfoClick(row.id)'>
                   已关联
                </el-button>
            </template>
        </el-table-column>

      </el-table>
      <div class="pb20"></div>
      <el-pagination
              class="text-center"
              v-if="pageTotal > 0"
              @size-change="initData"
              @current-change="initData"
              :current-page.sync="pageMsg.pageNum"
              :page-sizes="[100, 200, 300, 400]"
              :page-size.sync="pageMsg.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal"
      >
      </el-pagination>
    </el-card>
      <el-dialog   title="新增客户信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading1"  @close="dialogCreate" >

        <div class="channel-add">
      <el-form
              :rules="userFormRules"
              :model="channelForm"
              ref="addChannel"
              :label-position="labelPosition"
              :label-width="formLabelWidth"
      >

        <el-form-item label="公司简称:" prop="stockName">
          <el-input v-model="channelForm.stockName" placeholder="请输入公司简称"  style="width: 215px"></el-input>
        </el-form-item>
        <el-form-item label="股票代码:" prop="stockCode">
            <el-input v-model="channelForm.stockCode"  placeholder="请输入股票代码"  style="width: 215px"></el-input>
        </el-form-item>
          <el-form-item label="股票类型:" prop="stockType">
              <el-select v-model="channelForm.stockType" placeholder="请选择股票类型"    style="width: auto">
                  <el-option
                          v-for="item in optionContent"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="所属行业:" prop="categoryCode">
              <el-cascader
                      :options="options"
                      @change="handleChange"
                      v-model="channelForm.industryCategoryArr"
              ></el-cascader>

          </el-form-item>
          <el-form-item label="公司网址:" prop="website">
              <el-input v-model="channelForm.website" placeholder="请输入公司网址" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="公司邮箱:" prop="email">
              <el-input v-model="channelForm.email"  placeholder="请输入公司邮箱" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item label="公司所在地(省):" prop="province">
              <el-input v-model="channelForm.province"  placeholder="请输入公司所在地" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item label="公司所在地(市):" prop="city">
              <el-input v-model="channelForm.city"  placeholder="请输入公司所在地" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item label="公司联系方式:" prop="phone">
          <el-input v-model="channelForm.phone"  placeholder="请输入公司联系方式" style="width: 500px"></el-input>
      </el-form-item>
          <el-form-item label="公司办公地址:" prop="address">
              <el-input v-model="channelForm.address" placeholder="请输入公司办公地址"  style="width: 500px"></el-input>
          </el-form-item>
      </el-form>

          <div align="center">   <span>
      <el-button @click="dialogCreate" >取消</el-button>
      <el-button type="primary"  :loading="loading" @click="channelAdd">确定</el-button>
    </span></div>

        </div>

    </el-dialog>

      <el-dialog   title="修改客户信息" style="margin-left: -20px"   width="1200px"  :visible.sync="passLoading2"  @close="dialogCreate" >
          <div class="channel-add">
              <el-form
                      :model="channelForm1"
                      ref="addChannel1"
                      :rules="userFormRules1"
                      :label-position="labelPosition"
                      :label-width="formLabelWidth"
              >    <el-form-item label="公司简称:" prop="stockName"  placeholder="请输入公司简称"  align='left' >
                  <el-input v-model="channelForm1.stockName" style="width: 215px"></el-input>
              </el-form-item>
                  <el-form-item label="股票代码:" prop="stockCode" placeholder="请输入股票代码" >
                      <el-input v-model="channelForm1.stockCode" disabled style="width: 215px"></el-input>
                  </el-form-item>
                  <el-form-item label="股票类型:" prop="stockType">
                      <el-select v-model="channelForm1.stockType" placeholder="请选择股票类型"    style="width: auto">
                          <el-option
                                  v-for="item in optionContent"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                          </el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item  label="公司客户:" prop="isCum" >
                      <el-radio-group v-model="channelForm1.isCum" >
                        <el-radio :label="0" style="margin-right: 10px">潜在</el-radio>
                        <el-radio :label="1" style="margin-right: 10px">试用</el-radio>
                        <el-radio :label="2" style="margin-right: 10px">签约</el-radio>
<!--                        <el-radio :label="3" style="margin-right: 10px">签约中</el-radio>-->
                      </el-radio-group>
                  </el-form-item>

                  <el-form-item label="公司网址:" prop="website" placeholder="请输入公司网址">
                      <el-input v-model="channelForm1.website" style="width: 300px"></el-input>
                  </el-form-item>


                <el-form-item label='ETF标签' prop='etfTags'>
                  <el-tag>
                    {{ etfTags }}
                  </el-tag>
                </el-form-item>

                <div v-for="(item,i) in channelForm1.contactList">

                  <el-form-item label='新增联系人' prop='contactList'>
                        <el-input v-model="channelForm1.contactList[i].clientName" clearable placeholder="姓名" style='width: 120px;margin-right: 20px'></el-input>
                        <el-input v-model="channelForm1.contactList[i].job" clearable placeholder="职务" style='width: 120px;margin-right: 20px'></el-input>
                        <el-input v-model="channelForm1.contactList[i].email" clearable placeholder="邮箱号" style='width: 200px;margin-right: 20px'></el-input>
                        <el-input v-model="channelForm1.contactList[i].phoneNumber" clearable placeholder="手机号" style='width: 150px;margin-right: 20px'></el-input>
                        <el-input v-model='channelForm1.contactList[i].remark' clearable placeholder='请输入备注' style='width: 120px;margin-right: 20px'></el-input>
                    <el-switch style='margin-right: 20px'
                               v-model="channelForm1.contactList[i].isDel" @input='upIsDel(i)'>
                    </el-switch>
                      <el-button circle icon="el-icon-plus" @click="addContactList()"></el-button>
                      <el-button circle icon="el-icon-minus" @click="subContactList(i)" ></el-button>
                  </el-form-item>
                </div>

                  <el-form-item label="公司邮箱:" placeholder="请输入公司邮箱">
                      <el-input v-model="channelForm1.email" style="width: 500px"></el-input>
                    <p style='margin: 0px'>
                      <span style='color: red;margin-right: 10px'>多个邮箱请用英文逗号隔开,注释信息请在小括号中填写</span>
                      <br>
                      <span style='color:red;'>例如:zs@163.com(张三),ls@163.com(李四)</span>
                    </p>
                  </el-form-item>

                <el-form-item label="公司联系方式:"  >
                  <el-input v-model="channelForm1.phone" style="width: 500px"></el-input>
                  <p style='margin: 0px'>
                    <span style='color: red;margin-right: 10px'>多个手机号请用英文逗号隔开,注释信息请在小括号中填写</span>
                    <br>
                    <span style='color:red;'>例如:13661398887(张三),13961399965(李四)</span>
                  </p>
                </el-form-item>



                  <el-form-item label="公司所在地(省):" prop="province" placeholder="请输入公司所在地">
                      <el-input v-model="channelForm1.province" style="width: 300px"></el-input>
                  </el-form-item>
                  <el-form-item label="公司所在地(市):" prop="city" >
                      <el-input v-model="channelForm1.city" style="width: 300px"></el-input>
                  </el-form-item>

                  <el-form-item label="公司办公地址:" prop="address"  >
                      <el-input v-model="channelForm1.address" style="width: 500px"></el-input>
                  </el-form-item>

                <el-form-item label='公司简介:' prop="stockIntroduce">
                  <el-input v-model="channelForm1.stockIntroduce" style="width: 500px"></el-input>
                </el-form-item>

              </el-form>
              <div align="center">   <span  >
      <el-button @click="dialogCreate1" >取消</el-button>
      <el-button type="primary" :loading="loading1"  @click="channelAdd1">确定</el-button>
    </span></div>

          </div>

      </el-dialog>

      <el-dialog   title="编辑邮件"    width="800px"  :visible.sync="passLoading4"  @close="dialogCreate" >
          <div class="channel-add" >
              <el-form
                      :model="contentData"
                      ref="addChannel2"

              >
                  <el-form-item label="标题：" prop="subject" >
                      <el-input
                              clearable
                              v-model="contentData.subject"
                              style="width: 300px"
                      ></el-input>
                  </el-form-item>
                          <el-form-item label="内容：" prop="articleContent" >
                              <vue-tinymce
                                      v-model="contentData.content"
                                      :setting="setting"
                              />
                          </el-form-item>
              </el-form>
              <div align="center">   <span  >
      <el-button @click="dialogCreate2" >取消</el-button>
      <el-button type="primary" :loading="loading1"  @click="channelAdd2">确定</el-button>
    </span></div>

          </div>

      </el-dialog>

      <el-dialog
              width="800px"  :visible.sync="passDialog"  @close="dialogCreate2"
              title="选择分配人员"
      >

          <!--//<el-form :rules="rules" :model="ruleModel" ref="formName">-->
          <el-form :model="ruleModel" ref="formName">
              <el-form-item
                      label="分配用户:"

              >
                  <el-select v-model="ruleModel.assignUserId" placeholder="请选择分配用户"    style="width: auto">
                      <el-option
                              v-for="item in optionUserId"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <div style="margin-bottom: 30px"></div>
              <div class="dialog-footer">
                  <el-button size="small" @click="handleClose2">取 消</el-button>
                  <el-button
                          size="small"
                          type="primary"
                          v-if="canSubmit"
                          @click="addAndEditBanner2('formName')"
                  >确 定</el-button
                  >
              </div>
          </el-form>
      </el-dialog>

    <div>
      <el-dialog
        title="关联销售信息"
        :visible.sync="relevanceVisible"
        width="30%"
        :before-close="handleClose">
<!--        <p>关联人Id: {{ userInfo.id }}</p>-->
        <p>关联人名称: {{ userInfo.name }}</p>
        <p>关联人账号: {{ userInfo.username }}</p>
        <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleClose">确 定</el-button>
  </span>
      </el-dialog>
    </div>

    <div>
      <el-dialog
        title="辖区分配"
        :visible.sync="secBureauAllocationVisible"
        width="30%"
        :before-close="secBureauAllocationClose">
        <el-form
          :model="pageMsgs"
          ref="secBureauAllocationData"
          :rules="secBureauAllocationFormRules"
          size="small"
          label-position="center"
          label-width="100px">
          <el-form-item label="分配:" prop='userId'>
            <el-select v-model="pageMsgs.userId"  placeholder="请选择账户名称"    style="width: 150px">
              <el-option
                v-for="item in optionUserId1"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="辖区:" prop='secBureau'>
            <el-select v-model="pageMsgs.secBureau" placeholder="请选择">
              <el-option
                v-for="item in secBureauOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="secBureauAllocationVisible = false">取 消</el-button>
    <el-button type="primary" @click="secBureauAllocationClick">确 定</el-button>
  </span>
      </el-dialog>
    </div>


    <div>
      <el-dialog
        title="批量添加/删除销售人"
        :visible.sync="relevantVisible"
        width="50%"
        :before-close="relevantClose">

        <el-form
          :label-position="labelPosition"
          :label-width="formLabelWidth"
          :model='relevantForm'
          ref='relevantRef'
          :rules='relevantRules'>
          <el-form-item label="请选择辖区:">
            <el-select v-model="relevantForm.secBureau" placeholder="请选择">
              <el-option
                v-for="item in secBureauOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择行业:">
            <el-cascader
              :options="options"
              @change="handleChange"
              v-model="relevantForm.industryCategoryArr"
              :props="{ checkStrictly: 'true' }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="请选择客户状态:" prop='stockType'>
            <el-checkbox-group v-model="relevantForm.stockType">
              <el-checkbox label="0">试用</el-checkbox>
              <el-checkbox label="1">签约</el-checkbox>
<!--              <el-checkbox label="2">签约中</el-checkbox>-->
              <el-checkbox label="3">潜在</el-checkbox>
              <el-checkbox label="4">全部</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label='销售:' prop='relevantInfo' >
            <el-radio-group v-model="relevantForm.radio" @input='relevanInfoUp'>
              <el-radio :label="0">选择</el-radio>
              <el-radio :label="1">填写信息</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="请选择:" v-if='isAllocation'>
            <el-select v-model="relevantForm.marketId"   placeholder="请选择账户名称">
              <el-option
                v-for="item in marketOptions"
                :key="item.id"
                :label="item.marketName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="分配:" v-if='isAllocation'>-->
          <!--            <el-select v-model="relevantForm.userId"   placeholder="请选择账户名称">-->
          <!--              <el-option-->
          <!--                v-for="item in optionUserId1"-->
          <!--                :key="item.id"-->
          <!--                :label="item.name"-->
          <!--                :value="item.id">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <div v-if='!isAllocation'>
            <el-form-item label='姓名' >
              <el-input v-model="relevantForm.relevantName" placeholder="请输入姓名" style='width: 300px'></el-input>
            </el-form-item>
            <el-form-item label='职务' >
              <el-input v-model="relevantForm.relevantJob" placeholder="请输入职务" style='width: 300px'></el-input>
            </el-form-item>
            <el-form-item label='邮箱' >
              <el-input v-model="relevantForm.relevantEmail" placeholder="请输入邮箱" style='width: 300px' ></el-input>
            </el-form-item>
            <el-form-item label='手机号' >
              <el-input v-model="relevantForm.relevantPhone" placeholder="请输入手机号" style='width: 300px'></el-input>
            </el-form-item>
            <el-form-item label='备注'>
              <el-input v-model='relevantForm.relevantRemark' placeholder="请输入备注" style='width: 300px'></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div align="center">
          <span>
            <el-button type="primary" @click="relevantSubmitForm()">添加</el-button>
            <el-button type="primary" @click="relevantDelForm()">删除</el-button>
            <el-button type="primary" @click="relevantReset()">重置</el-button>
            <el-button @click="relevantCloseForm" >取消</el-button>
          </span>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  basicStockAddApi,
  basicStockAllExportApi,
  basicStockCloseAll,
  basicStockDeleteAll,
  basicStockDeleteOne,
  basicStockDeleteZero,
  basicStockEditApi,
  basicStockPageAllApi,
  basicStockSelect,
  basicStockSelectPlfp,
  basicStockSelectPljc,
  basicStocksendEmail,
  basicStockStartAll,
  getRelevanceInfoApi,
  getSecBureauAllApi,
  secBureauAllocationApi,
  getContactListApi,
  deleteContactApi,
  basicStockContactExportApi,
  loadingEmailPhoneApi,
  getAssociatedEmailAllApi,
  delBatchesApi,
  relevantAllApi,
  upIsDelContactApi
} from 'api/article'
import { industryCategoryTreeApi } from 'api/common'
import { getMarketRelevanceListApi, getUserList1 } from 'api/rightsetting'
import moment from "moment"
import { getEtfTagsApi } from 'api/stock'
export default {
    name: 'contentReview',
    computed: {
        formatDate() {
            return function (oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()

                const dateString =
                    Y +
                    '-' +
                    add0(M) +
                    '-' +
                    add0(D) +
                    '  ' +
                    add0(h) +
                    ':' +
                    add0(m) +
                    ':' +
                    add0(s)
                return dateString
            }
        }
    },

    data() {
        return {
          etfTags:null,
          marketOptions:[],
          isAllocation:true,
          relevantRules:{
            userId: [{required: true, message: '关联人不能为空',trigger: 'blur'}],
            stockType:[{required: true, message: '客户状态不能为空',trigger: 'blur'}],
          },
          relevantForm:{
            relevantRemark:null,
            marketId:null,
            relevantName:null,
            relevantJob:null,
            relevantEmail:null,
            relevantPhone:null,
            radio:0,
            stockType:['0'],
            userId:null,
            secBureau:null,
            industryCategoryArr:null,
            industryCode:null
          },
          relevantVisible:false,
          emailUserNameOptions:[],
          checkAll: false,
          isIndeterminate: true,
          secBureauAllocationVisible:false,
          secBureauOptions:[],
          relevanceVisible:false,
          userInfo:{
            id:"",
            name:"",
            username:""
          },
            setting: {
                menubar: false,
                toolbar:
                    'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image  table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
                plugins: 'link image  table lists fullscreen quickbars',
                language: 'zh_CN',
                height: 350,
                images_upload_url: true,
                images_upload_handler: function (blobInfo, succFun, failFun) {
                    var formData = new FormData()
                    let url = '/common/upload'
                    formData.append('file', blobInfo.blob()) //此处与源文档不一样

                    axios
                        .post(url, formData, {
                            headers: signActionTime('post', formData)
                        })
                        .then(response => {
                            succFun(response.data.result.url)
                        })
                        .catch(error => {
                            failFun(error)
                        })
                }
            },
            selectionArr: [],
            loading: false,
            loading1: false,
            optionContent: [{
                value: 'A股',
                label: 'A股'
            }, {
                value: '港股',
                label: '港股'
            },{
              value: '新三板',
              label: '新三板'
            }],
            contentData: {
                content: '',
                subject: '',
                email: '',
            },
            channelForm: {
                stockName: '',
                stockCode: '',
                stockType: '',
                categoryCode: '',
                email: '',
                province: '',
                city: '',
                website: '',
                phone: '',
                address: '',
                industryCategoryCode: '',
                industryCategoryArr: [],
            },
            options: [],
            optionsCity: [],
            channelForm1: {
              contactList:[
              {id:'',clientName: '', job: '',email:null, phone:null, address:''}
            ],
                id: '',
              associatedEmailUserName:[],
                stockName: '',
                stockCode: '',
                stockType: '',
                categoryCode: '',
                email: '',
                province: '',
                city: '',
                website: '',
                phone: '',
                address: '',
                isCum: 3,
                industryCategoryCode: '',
                industryCategoryArr: [],
              stockIntroduce:null
            },
          secBureauAllocationFormRules:{
            userId:[{required: true, message: '分配人员不能为空',trigger: 'blur'}],
            secBureau:[{required: true, message: '分配辖区不能为空',trigger: 'blur'}],
          },
            userFormRules: {
                stockName: [{required: true, message: '公司简称不能为空',trigger: 'blur'}],
                stockCode: [{required: true, message: '股票代码不能为空',trigger: 'blur'}],
                stockType: [{required: true, message: '股票类型不能为空',trigger: 'blur'}],
                industryCategoryCode: [{required: true, message: '行业分类不能为空',trigger: 'blur'}],
                email: [{required: true, message: '请输入正确邮箱',trigger: 'blur'}],
                province: [{required: true, message: '公司所在地不能为空',trigger: 'blur'}],
                city: [{required: true, message: '公司所在地不能为空',trigger: 'blur'}],
                website: [{required: true,

                    message: '公司网址不能为空',trigger: 'blur'}],
                phone: [{
                    required: true,
                    pattern: /^((\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14})|([1][3,4,5,6,7,8,9][0-9]{9})$/,
                    message: '请输入正确的公司电话',trigger: 'blur'}],
                address: [{required: true, message: '公司办公地不能为空',trigger: 'blur'}],
            },
            userFormRules1: {
                stockName: [{required: true, message: '公司简称不能为空',trigger: 'blur'}],
                stockCode: [{required: true, message: '股票代码不能为空',trigger: 'blur'}],
                stockType: [{required: true, message: '股票类型不能为空',trigger: 'blur'}],
                categoryCode: [{required: true, message: '行业分类不能为空',trigger: 'blur'}],
                isCum: [{required: true, message: '请选择是否为公司客户',trigger: 'blur'}],
                email: [{required: true, message: '请输入正确邮箱',trigger: 'blur'}],
                province: [{required: true, message: '公司所在地不能为空',trigger: 'blur'}],
                city: [{required: true, message: '公司所在地不能为空',trigger: 'blur'}],
                website: [{required: true,

                    message: '公司网址不能为空',trigger: 'blur'}],
                phone: [{
                    required: true,
                    pattern: /^((\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14})|([1][3,4,5,6,7,8,9][0-9]{9})$/,
                    message: '请输入正确的公司电话',trigger: 'blur'}],
                address: [{required: true, message: '公司办公地不能为空',trigger: 'blur'}],
            },
            passLoading1: false,
            passLoading2: false,
            passDialog: false,
            passLoading3: false,
            passLoading4: false,
            pageForm:{
                pageNum: 1,
                pageSize: 100,
                wordType:null,
            },
            formLabelWidth: '130px',
            labelPosition: 'left',
            tableData: [],
            pageTotal: 0,
            pageMsg: {
              isIncludeContacts:0,
              validOrInvalidType:0,
              secBureau:null,
                isCum:null,
              isDel:3,
              dateType: null,
                pageNum: 1,
                pageSize: 100,
                city: undefined,
                userId: undefined,
                stockName: undefined,
                contentType: undefined,
                industryCategoryArr: [],
                industry: undefined,
                assignUserId:undefined,
                selectionArr: []
            },
            ruleModel: {
                assignUserId: null,
                selectionArr: [],
                selection: false,
                selection1: true,
            },
            optionUserId: [],
            optionUserId1: [],
            tableLoading: false,
            oneDialog2: false,
            canSubmit: false,
          pageMsgs:{
            userId:null,
            secBureau:null
          }
        }
    },
    mounted() {
        this.select()
        this.initData()
        this.initRoles()
        this.initCategoryCode()
      //加载证监局
      this.secBureauData();
    },
    created() {
        this.select()
    },
    methods: {
      relevanInfoUp(){
        if (this.relevantForm.radio == 1){
          this.isAllocation = false;
        }else {
          this.isAllocation = true;
        }
      },
      relevantCloseForm(){
        this.relevantVisible = false
        this.isAllocation = true;
        this.reset();
      },
      reset(){
        this.relevantForm = this.$options.data().relevantForm;
        //设置初始化值
        this.relevantForm.stockType = ['0'];
        this.relevantForm.radio = 0;
        this.isAllocation = true;
      },
      relevantReset(){
        this.reset();
      },
      relevantDelForm(){
        this.$refs.relevantRef.validate(valid => {
          if (valid){
            // if (this.relevantForm.secBureau==null&&this.relevantForm.industryCategoryArr==null){
            //   this.$message.error("证监局和行业不能同时为空!")
            //   return
            // }
            if (this.relevantForm.industryCategoryArr!=null){
              this.relevantForm.industryCode = this.relevantForm.industryCategoryArr.slice(-1);
            }
            delBatchesApi(this.relevantForm).then(res => {
              if(res.code==200){
                this.$message.success("清除成功!");
              }
              this.relevantVisible = false
              this.reset()
              this.initData();
              this.channelForm1.contactList = this.$options.data().channelForm1.contactList;
            })
          }else {
            return;
          }
        })
      },
      relevantSubmitForm(){
        this.$refs.relevantRef.validate(valid => {
          if (valid){
            // if (this.relevantForm.secBureau==null&&this.relevantForm.industryCategoryArr==null){
            //   this.$message.error("证监局和行业不能同时为空!")
            //   return
            // }
            if (this.relevantForm.industryCategoryArr!=null){
              this.relevantForm.industryCode = this.relevantForm.industryCategoryArr.slice(-1);
            }
            relevantAllApi(this.relevantForm).then(res => {
              if(res.code==200){
                this.$message.success("关联成功!");
              }
              this.relevantVisible = false
              this.reset()
              this.initData();
            })
          }else {
            return;
          }
        })
      },
      getMarketRelevance(){
        getMarketRelevanceListApi().then(res => {
          if(res.code === 200){
            // this.marketOptions = res.result;
            let options = res.result.map(item => {
              return {
                id: item.id, // 假设id作为value
                marketName: item.marketName +"---职务:"+item.marketJob// 假设marketName作为label
                // 可以根据需要添加其他字段
              };
            });
            this.marketOptions = options;
          }
        })
      },
      getUserList(){
        getUserList1().then(res => {
          this.optionUserId1 = res.result.records
        })
      },
      relevant(){
        this.getMarketRelevance();
        this.relevantVisible = true;
      },
      relevantClose(){
        this.relevantVisible = false;
        this.isAllocation = true;
        this.reset();
      },
      initDataEmailOptions(secBureau){
        getAssociatedEmailAllApi(secBureau).then(res => {
          const userArr = [];
          let index = 0;
          res.result.forEach((item) =>{
            userArr[index] = item;
            index++;
          })
          this.emailUserNameOptions = userArr;
        })
      },
      handleCheckAllChange(val) {
        this.channelForm1.associatedEmailUserName = val ? this.emailUserNameOptions : [];
        this.isIndeterminate = false;
        loadingEmailPhoneApi(this.channelForm1).then(res => {
          if (res.code === 200){
            this.channelForm1.email = res.result.email;
            this.channelForm1.phone = res.result.phone;
          }
        })
      },
      //关联邮箱
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.emailUserNameOptions.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.emailUserNameOptions.length;
        this.channelForm1.associatedEmailUserName = value;
        //动态加载联系方式
        loadingEmailPhoneApi(this.channelForm1).then(res => {
          if (res.code === 200){
            this.channelForm1.email = res.result.email;
            this.channelForm1.phone = res.result.phone;
          }
        })
      },
      addContactList(){
        this.channelForm1.contactList.push({clientName: '', job: '',email:'',phoneNumber:'',remark:'',isDel:true})
      },
      upIsDel(index){
        const data = {
          id:this.channelForm1.contactList[index].id,
          isDel:this.channelForm1.contactList[index].isDel
        }
        upIsDelContactApi(data).then(res =>{
          if (res.code === 200){
            if (res.result){
              this.$message.success("修改成功!")
            }
          }
        })
      },
      subContactList(index){
        const clientName = this.channelForm1.contactList[index].clientName;
        if (clientName!=null&&clientName!=""&&clientName!=undefined){
          //禁用该字段
          deleteContactApi(this.channelForm1.contactList[index].id).then(res => {
            if (res.code === 200){
              this.channelForm1.contactList.splice(index, 1).splice(index, 1)
            }
          })
        }else {
          this.channelForm1.contactList.splice(index, 1).splice(index, 1)
        }
      },
      basicStockAllExport(isIncludeContacts){
        this.pageMsg.isIncludeContacts = isIncludeContacts;
        basicStockAllExportApi(this.pageMsg).then(data => {
          let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let blobUrl = window.URL.createObjectURL(blob)
          let link = document.createElement('a')
          link.style.display = 'none'

          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
          let dayDate = `${year}年${month}月${day}日${hours}时${minutes}分${seconds}秒`;

          if(this.pageMsg.secBureau!=null){
            link.download = this.pageMsg.secBureau+`公司信息${dayDate}.xlsx`;
          }else {
            link.download = `公司信息${dayDate}.xlsx`
          }

          // link.download = '全部公司信息.xlsx';
          link.href = blobUrl;
          link.click()
          link.remove()
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(blobUrl)
        })
      },
      secBureauAllocationClose(){
        this.secBureauAllocationVisible = false;
      },
      //辖区分配显示
      secBureauAllocationShowClick(){
        this.secBureauAllocationVisible = true;
      },
      //辖区分配
      secBureauAllocationClick(){
        this.$refs.secBureauAllocationData.validate(valid => {
          if (valid){
            secBureauAllocationApi(this.pageMsgs).then(res => {
              if (res.code === 200){
                this.$message.success("分配成功!")
              }else {
                this.$message.error("分配失败!");
              }
              this.initData();
              this.secBureauAllocationVisible = false;
            })
          }
        })
        this.pageMsgs = this.$options.data().pageMsgs;
      },
      secBureauData(){
        getSecBureauAllApi().then(res=>{
          this.secBureauOptions = res.result;
        })
      },
      handleClose(){
        this.relevanceVisible = false;
      },
      //查询关联信息
      getRelevanceInfoClick(id){
        getRelevanceInfoApi(id).then( res => {
          if (res.code === 200){
            this.userInfo = res.result;
            this.relevanceVisible = true;
          }
        })
      },

        addAndEditBanner2(formName) {

            this.$refs[formName].validate(valid => {

                if (valid) {
                    this.canSubmit = false
                    if (this.oneDialog2) {
                        basicStockSelectPlfp({
                            ...this.ruleModel
                        }).then(res => {
                            if (res.code == 200) {
                                this.$message.success('分配成功')



                                this.initData()
                                this.passDialog = false
                                this.oneDialog2 = false
                                this.ruleModel.assignUserId = null
                            }
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleClose2() {
            this.ruleModel.assignUserId = null
            this.ruleModel.selectionArr = []
            this.$refs.formName.resetFields()
            this.passDialog = false
            this.oneDialog2 = false
        },
        initRoles() {
            getUserList1().then(res => {
                this.optionUserId = res.result.records
                this.optionUserId1 = res.result.records

            })
        },
        plfp(pageMsg) {
            if (this.ruleModel.selectionArr.length<= 0) {
                this.$message({
                    type: 'error',
                    message: '无任何选中项!'
                })
                return
            }
            if (this.ruleModel.selection === true) {
                this.$message({
                    type: 'error',
                    message: '该客户已分配'
                })
                return
            }
            this.passDialog = true
            this.oneDialog2 = true
            this.canSubmit  = true
        },
        pljc(pageMsg) {
            if (this.ruleModel.selectionArr.length<= 0) {
                this.$message({
                    type: 'error',
                    message: '无任何选中项!'
                })
                return
            }

            if (this.ruleModel.selection1 === false) {


                this.$message({
                    type: 'error',
                    message: '该客户未被分配!'
                })
                return
            } else {
                pageMsg.selectionArr = this.ruleModel.selectionArr;
                this.$confirm('是否确认批量解绑', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    return basicStockSelectPljc(pageMsg)
                }).then(data => {
                    this.$message.success('解绑成功')
                    this.initData()
                });
            }

           /* if (!pageMsg.userId   ) {

                this.$message({
                    type: 'error',
                    message: '请先进行分配人筛选!'
                })
                return
            }*/


        },
        getPinYin(name){
            /**
             * 声明变量存储首拼
             */
            var char = ''
            /**
             * 调用设置属性
             */

            /**
             * 跟据传入文字调用方法进行转换
             */
            this.pageMsg.stockName =name.toUpperCase();

            /**
             * 赋值检索码
             */
        },
        select() {
            basicStockSelect()
                .then(data => {


                   let arr =  data.result.map((item) => {
                        return { value: item, label: item }
                    })
                    this.optionsCity = arr

                })
        },
        plkq(pageMsg) {
                this.$confirm('是否确认批量启用', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    return  basicStockStartAll(pageMsg)
                }).then(data => {
                    this.$message.success('启用成功')
                    this.initData()
                });


        },
        plgb(pageMsg) {
                this.$confirm('是否确认批量禁用', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    return  basicStockCloseAll(pageMsg)
                }).then(data => {
                    this.$message.success('禁用成功')
                    this.initData()
                });

        },
        switchEvent(bool, row) {
            if (bool) {
                //解冻
                this.basicStockDeleteThree(row.id)
            } else {
                // 冻结
                this.basicStockDeleteFour(row.id)
            }
        },
        basicStockDeleteThree(id) {
               basicStockDeleteOne(id)
         .then(data => {
                this.$message.success('启用成功')
                this.initData()
            })
        },

        basicStockDeleteFour(id) {

             basicStockDeleteZero(id)
           .then(data => {
                this.$message.success('禁用成功')
                this.initData()
            })
        },

        basicStockDeleteAll(pageMsg) {

            if (this.pageMsg.industryCategoryArr.length>0 ) {


                basicStockDeleteAll(pageMsg).then(data => {
                    if (data.content) {
                        this.passLoading4 = true;
                        this.contentData = data
                    } else {
                        this.$message.error("没有可推送的信息")
                    }

                  /*  if (data === true) {
                        this.$message.success('发送成功');
                        this.passLoading3 = false;

                        this.initData();
                    } else {
                        this.$message.error("没有可推送的信息")
                    }*/
                });
            } else {
                return this.$message.error("请先进行行业筛选");
            }

        },
        handleSelectionChange(e) {
            this.ruleModel.selectionArr = [];
            this.ruleModel.selection = false
            this.ruleModel.selection1 = true
            for (var i = e.length - 1; i >= 0; i--) {
                this.ruleModel.selectionArr.push(e[i].id)
            }

            for (var i = e.length - 1; i >= 0; i--) {
                if (e[i].show === 1) {
                    this.ruleModel.selection = true
                }
            }

            for (var i = e.length - 1; i >= 0; i--) {
                if (e[i].show !== 1) {
                    this.ruleModel.selection1 = false
                }
            }
        },
        searchData(bool) {
            if (bool) {
                this.pageMsg.pageNum = 1
                this.pageMsg.pageSize = 100

            } else {
                this.pageMsg = this.$options.data().pageMsg
                this.pageMsg.industryCategoryArr = [];
                this.pageMsg.city = undefined;
                this.passLoading3= false;
            }
            if (this.pageMsg.industryCategoryArr.length > 0) {
                this.passLoading3= true;
            }



            this.initData()
        },
        basicStockDeleteOne(id) {
            this.$confirm('是否确认启用', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                return  basicStockDeleteOne(id)
            }).then(data => {
                this.$message.success('启用成功')
                this.initData()
            })
        },
        basicStockDeleteZero(id) {

            this.$confirm('是否确认禁用', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                return  basicStockDeleteZero(id)
            }).then(data => {
                this.$message.success('禁用成功')
                this.initData()
            })
        },
        handleChange(val) {
            this.channelForm.industryCategoryCode = val[val.length - 1]
        },
        initCategoryCode() {
            industryCategoryTreeApi().then(({ result }) => {
                const classification = result
                function classifi(arr) {
                    arr.forEach(item => {
                        item.label = item.name
                        item.value = item.code
                        if (item.children && item.children.length > 0) {
                            classifi(item.children)
                        } else {
                            delete item['children']
                        }
                    })
                }
                classifi(classification)
                this.options = result
            })
        },
        edit(row){
          // 查询练习方式list
          getContactListApi(row.id).then(res => {
            if (res.result!=null&&res.result!=""&&res.result!=undefined) { // 使用truthy值进行判断，可同时检查null和空字符串
              this.channelForm1.contactList = res.result;
              const data = res.result;
              data.forEach(item => {
                if(item.isDel===1) {
                  item.isDel = false
                }else {
                  item.isDel = true
                }
              })
              this.channelForm1.contactList = data;
            }
          });

          console.log("公司名称:"+row.stockName)
          console.log("股票代码:"+row.stockCode)
          const stockDto = {
            stockName: row.stockName,
            stockCode: row.stockCode
          }
          getEtfTagsApi(stockDto).then(data =>{
            console.log("返回的数据111" + JSON.stringify(data))
            if (data.code === 200){
              console.log("ETF标签", JSON.stringify(data))

              let etfTag = data.result.slice(1, -1);
              if (etfTag !== ""){
                this.etfTags = data.result.slice(1, -1);
              }else {
                this.etfTags = "无";
              }
            }
          })

          // getEtfTagsApi(stockDto).then(data =>{
          //   console.log("返回的数据111"+JSON.stringify(data))
          //   if (data.code === 200){
          //     console.log("ETF标签",JSON.stringify(data))
          //     if (data.result!=""){
          //       this.etfTags = data.result.subject(0,data.result.length-1);
          //     }
          //   }
          // })

            this.passLoading2 = true;
          this.channelForm1.secBureau = row.secBureau;
            this.channelForm1.stockName = row.stockName
            this.channelForm1.stockCode = row.stockCode
            this.channelForm1.stockType = row.stockType
            this.channelForm1.email = row.email
            this.channelForm1.province = row.province
            this.channelForm1.city = row.city
            this.channelForm1.website = row.website
            this.channelForm1.phone = row.phone
            this.channelForm1.isCum = row.isCum
            //this.channelForm1.categoryCode = row.codePath
            this.channelForm1.address = row.address
            this.channelForm1.id = row.id
          this.channelForm1.stockIntroduce = row.stockIntroduce;
          // var namesString = row.associatedEmailUserName;
          // var emailUserNameArr = namesString.split(',').map(name => name.trim());
          // this.channelForm1.associatedEmailUserName = emailUserNameArr;
        },
        dialogCreate() {
            this.passLoading1 = false
        },
        dialogCreate1() {
            this.passLoading2 = false
            this.channelForm1 = this.$options.data().channelForm1
            this.channelForm1.isCum = null
        },
        dialogCreate2() {
            this.passLoading4 = false
        },
        reject() {
            this.passLoading1 = true;
            this.channelForm = this.$options.data().channelForm
        },
        channelAdd() {
            this.$refs.addChannel.validate(valid => {
                this.loading = true
                if (valid) {
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicStockAddApi({ ...this.channelForm }).then(res => {
                            if (res.code == 200) {

                                this.$message.success('保存成功')
                                this.channelForm = this.$options.data().channelForm
                                this.passLoading1 = false
                                this.loading = false
                                this.initData()
                            }
                        this.loading = false

                        })
                }
            })
        },


        channelAdd1() {
            this.$refs.addChannel1.validate(valid => {
              this.passLoading2 = true
                if (valid) {
                    this.loading1 = true
                  const data = this.channelForm1.contactList;
                  data.forEach(item => {
                    if (item.isDel){
                      item.isDel = 1
                    }else{
                      item.isDel = 0
                    }
                  })
                    basicStockEditApi({ ...this.channelForm1 }).then(res => {
                        if (res.code == 200) {

                            this.$message.success('修改成功')

                            this.channelForm1 = this.$options.data().channelForm1
                            this.passLoading2 = false

                            this.loading1 = false

                            this.initData()
                        }
                        this.loading1 = false
                    })
                }
            })
        },
        channelAdd2() {
            this.$refs.addChannel2.validate(valid => {
                this.passLoading4 = true
                if (valid) {
                    this.loading1 = true
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicStocksendEmail({ ...this.contentData }).then(res => {
                        if (res.code == 200) {

                            this.$message.success('发送成功')

                            this.contentData = this.$options.data().contentData
                            this.passLoading4 = false
                            this.loading1 = false
                            this.initData()
                        }
                        this.loading1 = false
                    })
                }
            })
        },
        initData() {
            this.tableLoading = true
            let a =   this.pageMsg.industryCategoryArr.join(",");
            this.pageMsg.industry = a;
            const data = {
                ...this.pageMsg
            }


          if (this.pageMsg.dateType === null){
            this.pageMsg.dateType === null;

          }

          // basicStockPageApi(this.pageMsg).then(res => {
          basicStockPageAllApi(this.pageMsg).then(res => {

            localStorage.setItem("测试111",JSON.stringify(this.pageMsg))

                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData = records
                    this.pageTotal = total;
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading = false
            })
        },

    }
}
</script>

<style lang="scss" scoped>
  .channelManagement {
    width: 100%;
    .channelManagement-head {
      padding: 10px 20px;
      background: #fff;
    }
    .box-card {
      width: calc(100% - 60px);
      padding: 10px;
      margin: 20px auto;
    }
    /deep/.state-table {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .select_warn {
      background: rgb(230, 247, 255);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      span {
        color: $primary;
      }
    }
  }
</style>
