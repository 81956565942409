<template>
  <div>
    <div class="channelManagement" >
      <div class="channelManagement-head">
      </div>
      <el-card class="box-card">

        <div>
          <el-form
            :model="pageMsg"
            ref="formData"
            size="small"
            label-position="center"
            label-width="60px">
            <el-row justify='center'>
              <el-col :span='8'>
                <el-form-item label='日期:'>
                  <el-radio-group v-model="pageMsg.dateType" @input='dateTypeInput'>
                    <el-radio :label="0">近7天</el-radio>
                    <el-radio :label="1">近30天</el-radio>
                    <el-radio :label="2">区间</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="10" v-show='dateTimeShow'>
                <el-form-item label="时间:">
                  <el-date-picker
                    v-model="pageMsg.dateTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span='4' align='right'>
                <div>
                  <el-button type='primary' @click='search()'>
                    查询
                  </el-button>
                  <el-button @click='resetClick()'>
                    重置
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div style='margin-top: 50px'>
          <h2>
            {{title}}
          </h2>

          <div style='margin-top: 20px'>
            <el-row>
              <el-col :span='12' align='center'>
                <p style='margin-bottom: 20px'><el-tag type="success">有效率</el-tag></p>
                <el-progress type="circle" :percentage="releaseData.validRate" color="green"></el-progress>
              </el-col>

              <el-col :span='12' align='center'>
                <p style='margin-bottom: 20px'><el-tag type="danger">无效率</el-tag></p>
                <el-progress type="circle" :percentage="releaseData.inValidRate" color="red"></el-progress>
              </el-col>
            </el-row>
          </div>

          <div style='margin-bottom: 20px;margin-top: 50px'>
            <el-row>
              <el-col :span='12' align='center'>
                文章标题:
              </el-col>
              <el-col :span='12' align='center'>
                发布时间:
              </el-col>
            </el-row>
          </div>

          <div v-for='item in releaseArticleList' style='margin-bottom: 20px'>
            <el-row>
              <el-col :span='12' align='center'>
                <el-tag type="success" v-if='item.isValid'>{{item.articleTitle}}</el-tag>
                <el-tag type="danger" v-if='!item.isValid'>{{item.articleTitle}}</el-tag>
              </el-col>
              <el-col :span='12' align='center'>
                <el-tag type="warning">{{item.createdTime}}</el-tag>
              </el-col>
            </el-row>
          </div>
        </div>


        <div style='margin-top: 50px'>
          <el-row>
            <el-col :span='12' align='center'>
              <span style='font-size: 16px'>
              有效数:
              </span>
              <span style='margin-left: 10px'>
              <el-tag v-loading="readLoading">{{releaseData.validSum}}</el-tag>
              </span>
            </el-col>
            <el-col :span='12' align='center'>
              <span style='font-size: 16px'>
              无效数:
              </span>
              <span style='margin-left: 10px'>
              <el-tag v-loading='readLoading'>{{releaseData.inValidSum}}</el-tag>
              </span>
            </el-col>
          </el-row>
        </div>


        <div style='margin-top: 50px'>
          <el-row>
            <el-col :span='12' align='center'>
              <span style='font-size: 16px'>
              分发终端数总和:
              </span>
              <span style='margin-left: 10px'>
              <el-tag v-loading="pushApiLoading">{{channelData.pushApiSum}}</el-tag>
              </span>
            </el-col>
            <el-col :span='12' align='center'>
              <span style='font-size: 16px'>
              阅读量总和:
              </span>
              <span style='margin-left: 10px'>
              <el-tag v-loading='readLoading'>{{channelData.readSum}}</el-tag>
              </span>
            </el-col>
          </el-row>
        </div>

        <div style='margin-top: 50px'>
          <el-row>
            <el-col :span='12' align='center'>
                <h3 style='text-align: left'>
                  同业分发排名:
                </h3>
              <div id="industry" style="width: 500px; height: 450px;"></div>
              <p style='text-align: center'>
                <span style='font-size: 18px'>
                  您公司当前在同行业中发布数量排名为第
                </span>
                <span style='color:red;font-size: 18px'>{{rankingNumber}}</span>
                <span style='font-size: 18px'>
                  名!
                </span>
              </p>
            </el-col>
            <el-col :span='12' align='center'>
              <h3 style='text-align: left'>
                同辖区发排名:
              </h3>
              <div id="bureau" style="width: 500px; height: 450px;"></div>
              <p style='text-align: center'>
                <span style='font-size: 18px'>
                  您公司当前在同辖区中发布数量排名为第
                </span>
                <span style='color:red;font-size: 18px'>{{bureauRankingNumber}}</span>
                <span style='font-size: 18px'>
                  名!
                </span>
              </p>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  getReleaseArticleApi,
  getReleaseDataApi,
  getChannelDataApi,
  getIndustryInfoByStockNameApi,
  getBureauInfoByStockNameApi,
  getIndustryRankingByStockNameApi,
  getBureauRankingByStockNameApi
} from 'api/statistics'
import * as echarts from 'echarts';
export default {
  data() {
    return {
      stockName:null,
      dateTimeShow:false,
      pushApiLoading:false,
      readLoading:false,
      title:"近7天发布的文章:",
      rankingNumber:0,
      bureauRankingNumber:0,
      pageMsg:{
        stockName:null,
        dateType:0,
        dateTime:[],
        startTime:null,
        endTime:null
      },
      releaseArticleList:[],
      releaseData:{
        validSum:0,
        inValidSum:0,
        validRate:0,
        inValidRate:0
      },
      channelData:{
        pushApiSum:0,
        readSum:0
      }
    }
  },
  created() {
    const clientInfo = localStorage.getItem('USER_ALL_INFO');
    const client = JSON.parse(clientInfo);
    this.stockName = client.companyName;
    this.pageMsg.stockName = client.companyName;
    this.initData();
    // this.releaseArticle();
    // this.releaseDataFun();
    // this.channelDataFun();
    this.industryEcharts(client.companyName);
    this.BureauEcharts();
    this.getIndustryRanking(client.companyName);
    this.getBureauRanking(client.companyName);
  },
  mounted() {
  },
  methods:{
    dateTypeInput(){
      if (this.pageMsg.dateType===2){
        this.dateTimeShow = true;
      }else {
        this.dateTimeShow = false;
      }
    },
    getIndustryRanking(companyName){
      getIndustryRankingByStockNameApi(companyName).then(res => {
        if (res.code === 200){
          this.rankingNumber = res.result;
          console.log("行业排名:"+res.result)
        }
      })
    },
    getBureauRanking(companyName){
      getBureauRankingByStockNameApi(companyName).then(res =>{
        if (res.code === 200){
          this.bureauRankingNumber = res.result;
          console.log("辖区排名:"+res.result)
        }
      })
    },
    channelDataFun(){
      this.pushApiLoading = true;
      this.readLoading = true;
      getChannelDataApi(this.pageMsg).then(res =>{
        if(res.code === 200){
          this.channelData = res.result;
        }
      })
      this.pushApiLoading = false;
      this.readLoading = false;

    },
    //区间内发布过的文章
    releaseArticle(){
      getReleaseArticleApi(this.pageMsg).then(res => {
        if(res.code === 200){
          this.releaseArticleList = res.result;
        }
      })
    },
    //发布文章数据
    releaseDataFun(){
      getReleaseDataApi(this.pageMsg).then(res => {
        if(res.code === 200){
          this.releaseData = res.result
        }

      })
    },
    search(){
      this.pageMsg.startTime = this.transferTimeFun(new Date(this.pageMsg.dateTime[0]))
      this.pageMsg.endTime = this.transferTimeFun(new Date(this.pageMsg.dateTime[1]))
      this.initData();
    },
    initData(){
      if (this.pageMsg.dateType===0){
        this.title = "近7天发布的文章:";
      }else if (this.pageMsg.dateType===1){
        this.title = "近30天发布的文章:";
      }else if (this.pageMsg.dateType===2){
        this.title = this.pageMsg.startTime.substring(0,10)+"-"+this.pageMsg.endTime.substring(0,10)+"发布的文章:";
      }
      this.releaseArticle();
      this.releaseDataFun();
      this.channelDataFun();
    },
    resetClick(){
      this.pageMsg = this.$options.data().pageMsg;
      this.dateTimeShow = false;
      this.pageMsg.stockName = this.stockName;
      this.initData();
    },
    industryEcharts(companyName){
      getIndustryInfoByStockNameApi(companyName).then(res => {
        if (res.code === 200){
          this.industryOption = res.result;
          const stockNameArr = this.industryOption.map(item => item.stockName);
          const validSumArr = this.industryOption.map(item => item.validSum)
          var industryOption = {
            xAxis: {
              type: 'category',
              data: stockNameArr
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: validSumArr,
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('industry');
          var myChart = echarts.init(chartDom);
          myChart.setOption(industryOption);
        }
      })
    },
    BureauEcharts(){
      getBureauInfoByStockNameApi(this.pageMsg.stockName).then(res => {
        if (res.code === 200){
          this.bureauOption = res.result;
          const stockNameArr = this.bureauOption.map(item => item.stockName);
          const validSumArr = this.bureauOption.map(item => item.validSum)
          var bureauOption = {
            xAxis: {
              type: 'category',
              data: stockNameArr
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: validSumArr,
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('bureau');
          var myChart = echarts.init(chartDom);
          myChart.setOption(bureauOption);
        }
      })
    },
    // 转换时间
    transferTimeFun(date){
      console.log("data:"+date)

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>