<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">


        <el-form
                :model="pageMsg"
                ref="formData"
                size="small"
                label-position="left"
                label-width="80px"
        >
          <el-row>
            <el-col :span="8">
            <el-form-item label="公司名称:"     >
              <el-input clearable v-model="pageMsg.stockName"
                        @keyup.enter.native="searchData(true)"
                        placeholder="请输入公司名称"  @input="getPinYin(pageMsg.stockName)"   style="width: auto"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                      label="股票类型:"
              >

                <el-select v-model="pageMsg.contentType"   placeholder="请选择股票类型"    style="width: auto">
                  <el-option
                          v-for="item in optionContent"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>


            </el-col >
            <el-col :span="8">
              <el-form-item
                      label="行业类型:"
              >
                  <el-cascader
                          style="width: 185px"
                          :options="options"
                          @change="handleChange"
                          v-model="pageMsg.industryCategoryArr"
                          :props="{ checkStrictly: 'true' }"
                  ></el-cascader>
              </el-form-item>
            </el-col>
      </el-row>
          <el-row>
            <el-col :span="8">
            <el-form-item prop="dateType" >
              <el-radio-group v-model="pageMsg.dateType"  @keyup.enter.native="searchData(true)" style="margin-left: -80px">
                <el-radio :label="2" style="margin-right: 10px">日</el-radio>
                <el-radio :label="0" style="margin-right: 10px">本周</el-radio>
                <el-radio :label="1" style="margin-right: 10px">当月</el-radio>
                <el-radio :label="3" style="margin-right: 10px">年度</el-radio>
                <el-radio :label="4" style="margin-right: 10px">区间</el-radio>

              </el-radio-group>
            </el-form-item>


            <el-form-item prop="year" v-if="pageMsg.dateType === 3 ">
              <el-date-picker style="margin-left: -80px"
                      v-model="pageMsg.year"
                      type="year"
                      value-format="yyyy"
                      placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="number" v-if="pageMsg.dateType === 2">
              <el-date-picker style="margin-left: -80px"
                      v-model="pageMsg.day"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="number" v-if="pageMsg.dateType === 4">
              <el-date-picker style="margin-left: -80px"
                      v-model="pageMsg.startMonths"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="开始日期">
              </el-date-picker>
              <el-date-picker
                      v-model="pageMsg.endMonths"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                      label="账户名称:"

              >

                <el-select v-model="pageMsg.userId"   placeholder="请选择账户名称"    style="width: auto">
                  <el-option
                          v-for="item in optionUserId"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>


            </el-col >
            <el-col :span="8"  v-if="pageMsg.failReasonWx" >
                <el-form-item
                        label="有效原因:"

                >
<!--                  <el-select v-model="pageMsg.failReason" placeholder="请选择有效原因"    style="width:185px">
                    <el-option
                            disabled
                            v-for="item in basicWordCode1"
                            :key="item.wordName"
                            :label="item.wordName"
                            :value="item.wordName">
                    </el-option>
                  </el-select> -->
				  
				  
				  
<!-- 				  <el-cascader
				   style="width:185px"
				   v-model="pageMsg.failReason"
				  placeholder="请选择有效原因"
				      :options="validOptions"
				      :props="{ checkStrictly: true }"
				      clearable></el-cascader> -->
					  
					  <el-cascader
					  disabled
					  v-model="pageMsg.failReason"
					   placeholder="请选择有效原因"
            :props="{ checkStrictly: true }"
					   :options="validOptions" 
					   :show-all-levels="false"></el-cascader>
					  
                </el-form-item>

            </el-col>
            <el-col :span="8"  v-if="!pageMsg.failReasonWx" >
                <el-form-item
                  label="有效原因:" >
<!--                  <el-select v-model="pageMsg.failReason" placeholder="请选择有效原因"    style="width:185px">
                    <el-option
                            v-for="item in basicWordCode1"
                            :key="item.wordName"
                            :label="item.wordName"
                            :value="item.wordName">
                    </el-option>
                  </el-select> -->
				  
				  
				  <!-- Dream修改 
				  
				  v-model="validValue"
				  -->
				  
				 <!-- <el-cascader
				   style="width:185px"
				   v-model="pageMsg.failReason"
				  placeholder="请选择有效原因111"
				      :options="validOptions"
				      :props="{ checkStrictly: true }"
				      clearable></el-cascader> -->
					  
					   <el-cascader
					  					  v-model="pageMsg.failReason"
					  					   placeholder="请选择有效原因"
					  					   :options="validOptions"
                        :props="{ checkStrictly: true }"
					  					   :show-all-levels="false"></el-cascader>
                </el-form-item>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" v-if="pageMsg.failReason" >
              <el-form-item
                      label="无效原因:"

              >
              <!--  <el-select v-model="pageMsg.failReasonWx" placeholder="请选择无效原因"    style="width:210px">
                  <el-option
                          disabled
                          v-for="item in basicWordCode"
                          :key="item.wordName"
                          :label="item.wordName"
                          :value="item.wordName">
                  </el-option>
                </el-select> -->
				
				
			<!-- 	<el-cascader
				 style="width:210px"
				 v-model="pageMsg.failReasonWx"
				placeholder="请选择无效原因"
				    :options="invalidOptions"
				    :props="{ checkStrictly: true }"
				    clearable></el-cascader> -->
					
					 <el-cascader
					 disabled
						v-model="pageMsg.failReasonWx"
						placeholder="请选择无效原因"
           :props="{ checkStrictly: true }"
						:options="invalidOptions" 
						:show-all-levels="false"></el-cascader>
					
              </el-form-item>

            </el-col>
              <el-col :span="8" v-if="!pageMsg.failReason " >
                  <el-form-item
                          label="无效原因:"

                  >
                      <!-- <el-select v-model="pageMsg.failReasonWx" placeholder="请选择无效原因"    style="width:210px">
                          <el-option

                                  v-for="item in basicWordCode"
                                  :key="item.wordName"
                                  :label="item.wordName"
                                  :value="item.wordName">
                          </el-option>
                      </el-select> -->
					   <!-- Dream修改
					   v-model="invalidValue"
						-->
					<!--  <el-cascader 
					   style="width:210px"
					   v-model="pageMsg.failReasonWx"
					  placeholder="请选择无效原因"
					      :options="invalidOptions"
					      :props="{ checkStrictly: true }"
					      clearable></el-cascader> -->
						  
						  <el-cascader
						  						v-model="pageMsg.failReasonWx"
						  						placeholder="请选择无效原因"
						  						:options="invalidOptions"
                          :props="{ checkStrictly: true }"
						  						:show-all-levels="false"></el-cascader>
					  
                  </el-form-item>

              </el-col>

            <el-col :span='8'>
              <el-form-item label='排序方式:'>
                  <el-radio-group v-model="pageMsg.validOrInvalidType">
                  <el-radio :label="0">有效</el-radio>
                  <el-radio :label="1">无效</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

<!--            <el-col :span='8'>-->
<!--              <el-form-item label='股票代码:'>-->
<!--                <el-input clearable v-model="pageMsg.stockCode"-->
<!--                          placeholder="请输入股票代码"  style="width: auto"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->



            <el-col :span="16" >
            <el-form-item  style="margin-left: 0px">
              <el-button type="primary" size="small"  @click="searchData(true)">统计</el-button>
              <!--          <el-button type="primary" icon="el-icon-download" @click="exportExcelSelect">导出到EXCEL</el-button>-->
              <el-button size="small"  @click="searchData(false)">
                重置
              </el-button>
              <el-button
                      type="primary"
                      size="small"   @click="downloadPattern" >
                导出
              </el-button>
<!--              <el-button type='primary' size='small' @click='openDownLoadPatter'>-->
                <el-button type='primary' size='small' @click='downloadPatterns'>
<!--                <el-button type='primary' size='small' @click='downloadPatterns'>-->
              导出全部列表
            </el-button>

              <el-button type='primary' size='small' @click='downSecondaryClick'>
                导出二级列表
              </el-button>

            </el-form-item>
              </el-col>

          </el-row>
        </el-form>


      <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              @selection-change="handleSelectionChange"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              v-loading="tableLoading"
      >

        <el-table-column
                prop="stockName"
                label="公司名称"
                header-align="center"
                align="center"
                width="150"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="Detail(row)">

              {{ row.stockName }}
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="stockCode"
          label="股票代码"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
                prop="contentType"
                label="股票类型"
                header-align="center"
                align="center"
                width="150"
        >
        </el-table-column>



        <el-table-column
                prop="num"
                label="发布数量"
                header-align="center"
                align="center"
                width="160"
        >
        </el-table-column>
        <el-table-column
                prop="passNumber"
                label="有效数量"
                header-align="center"
                align="center"
                width="160"
        >
        </el-table-column>
        <el-table-column
                prop="rejectNumber"
                label="无效数量"
                header-align="center"
                align="center"
                width="160"
        >
        </el-table-column>
        <el-table-column
                prop="passingRate"
                label="有效率"

                header-align="center"
                align="center"
                width="160"
        >
        </el-table-column>
        <el-table-column
                prop="rejectRate"
                label="无效率"
                header-align="center"
                align="center"
                width="150"
        >
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
              class="text-center"
              v-if="pageTotal > 0"
              @size-change="initData"
              @current-change="initData"
              :current-page.sync="pageMsg.pageNum"
              :page-size.sync="pageMsg.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <el-dialog
            :visible.sync="detailBool"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="1200px"
    >
      <releaseDetail :rowID="rowID"/>
    </el-dialog>
<el-dialog   width="800px"  title="文章列表" :visible.sync="detailBool1" @close="dialogCreate" >
  <el-form
          :model="pageForm"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
  >
    <el-row>
      <el-col :span="12">
    <el-form-item
    >
      <el-radio-group v-model="pageForm.num" style="margin-left: -80px">
        <el-radio :label="0" style="margin-right: 10px">全部</el-radio>
        <el-radio :label="1" style="margin-right: 10px">有效</el-radio>
        <el-radio :label="2" style="margin-right: 10px">无效</el-radio>
        <el-radio :label="4" style="margin-right: 10px">审核中</el-radio>
        <el-radio :label="3" style="margin-right: 10px">待发布</el-radio>

      </el-radio-group>
    </el-form-item>
      </el-col>
      <el-col :span="12" >
    <el-form-item  >
      <!--          <el-button type="primary" icon="el-icon-download" @click="exportExcelSelect">导出到EXCEL</el-button>-->
      <el-button size="small"   type="primary"  @click="Detail(pageForm)">
        查询
      </el-button>
      <el-button size="small"  @click="searchDetail">
        重置
      </el-button>
      <el-button
              type="primary"
              size="small"   @click="downloadPatternTitle" >
        导出
      </el-button>
    </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <el-table
          ref="multipleTable"
          :data="tableData1"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          v-loading="tableLoading1"
  >

    <el-table-column
            prop="articleTitle"
            label="文章名称"
            header-align="center"
            align="center"
    >
      <template slot-scope="{ row }">
        <el-button type="text" @click.native.prevent="turnDetail(row)">
          {{ row.articleTitle }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
            prop="passOrReject"
            label="审核结果"
            header-align="center"
            align="center"
    >

    </el-table-column>
    <el-table-column
            prop="stringTime"
            label="发布时间"
            :formatter="formatData"
            header-align="center"
            align="center"
    >
    </el-table-column>
  </el-table>
  <el-pagination
          class="text-center"
          v-if="pageTotal1 > 0"
          @size-change="Detail(pageForm)"
          @current-change="Detail(pageForm)"
          :current-page.sync="pageForm.pageNum"
          :page-size.sync="pageForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal1"
  >
  </el-pagination>
</el-dialog>

    <el-dialog
      title="导出全部列表"
      :visible.sync="exportAllVisible"
      width="70%"
      :before-close="exportAllVisibleClose">

      <el-form :model='pageMsgDownAll'
               label-position="left"
               size="small"
               label-width="44px">
        <el-row class="text-left" :gutter="20">
          <el-col :span="4">
            <el-form-item label="公司:">
              <!--          <el-input clearable @keyup.enter.native="searchData(true)"-->
              <el-input v-model="pageMsgDownAll.stockName"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='4'>
            <el-form-item label='状态'>
              <el-select v-model="pageMsgDownAll.authState" placeholder="请选择">
                <el-option
                  v-for="item in autoStateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span='4'>
            <el-form-item label='类型'>
              <el-select v-model="pageMsgDownAll.contentTypeId" placeholder="请选择">
                <el-option
                  v-for="item in contentTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row class="text-left" :gutter="20">

          <el-col :span="8">
            <el-form-item
              label-position="right"
              label-width="0"
              prop="publisherDate"
            >
              <el-date-picker
                class="change-date-form"
                v-model="publisherDate"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="dateChange"
                style="width: 260px"
                :picker-options="pickerOptions"
                :default-value="timeDefaultShow"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span='10'>
            <el-button type='primary' size='small' @click='selectExportData'>查询</el-button>
            <el-button type='primary' size='small' @click='downloadPatterns'>导出</el-button>
            <el-button type='primary' size='small' @click='resetDownloadPatterns'>重置</el-button>
          </el-col>
        </el-row>
      </el-form>

      <el-card>
      <el-table
        ref="multipleTable"
        :data="tableDataShow"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        @selection-change="handleSelectionChange"
        v-loading="tableLoadingShow"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="stockName"
          label="企业简称"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="turnDetail1(row)">
              <p style="display: inline-block;">

                <!--						  <span style="color: red;font-size: 30px;" v-if="row.isClient">*-->
                <span style="color: red;font-size: 30px;">
                {{row.isClient}}
              </span>
                {{ row.stockName }}
              </p>

            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="articleTitle"
          label="动态"
          header-align="center"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="categoryCodeFirstName"
          label="行业类型"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contentType"
          label="内容类型"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="createdTime"
          label="提交时间"
          header-align="center"
          width="170"
          align="center"
          :formatter="formatData"
        >
        </el-table-column>
        <el-table-column
          prop="articleUrl"
          label="原文链接"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="aaaa(row)">
              {{ row.articleUrl }}
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column
           prop="updatedTime"
           label="修改时间"
           header-align="center"
           align="center"
           width="170"
           :formatter="formatData"
         >
         </el-table-column>-->

        <el-table-column
          label="状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <p
              class="state-table"
              v-if="row.authState == 10"
              style="color: #409eff"
            >
              <i style="background: #409eff"></i>
              审核中
            </p>
            <p
              class="state-table"
              v-if="row.authState == 20"
              style="color: #30b08f"
            >

              <i style="background: #30b08f"></i>成功
            </p>
            <p
              class="state-table"
              v-if="row.authState == 30"
              style="color: red"
            >
              <i style="background: red"></i>失败({{ row.failReason }})
            </p>
            <p
              class="state-table"
              v-if="row.authState == 40"
              style="color:  #409eff"
            >
              <i style="background: #409eff"></i>待审阅
            </p>


          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          fixed="right"
        >
          <!-- 有效按钮Dream定位 -->
          <template slot-scope="{ row }">
            <template >
              <el-button
                type="text"
                size="small"
                @click.native.prevent="passItemTrue(row)"
                style="color: cornflowerblue"
                v-if="userbtn.indexOf('RELEASE_ESETSEND_BTN') >= 0"
              >
                有效
              </el-button>
              <el-button    size="small"   type="text" style="color: cornflowerblue"
                            @click="passItemFalse(row)"
              >无效</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotalExport > 0"
        @size-change="exportAllShowData"
        @current-change="exportAllShowData"
        :current-page.sync="pageMsgDownAll.pageNum"
        :page-size.sync="pageMsgDownAll.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotalExport"
      >
      </el-pagination>
      </el-card>

    </el-dialog>

    <!--      导出二级列表对话框-->
    <el-dialog
      title="导出二级列表"
      :visible.sync="exportSecondaryVisible"
      width="50%"
      :before-close="exportSecondaryVisibleClose">

      <el-form
      :model='pageMsgSecondary'>
        <el-row>
          <el-col :span='8'>
            <el-form-item>
              <el-radio-group v-model="isValidRadio" @input='radioInpufChange'>
                <el-radio :label="0">有效</el-radio>
                <el-radio :label="1">无效</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span='10' v-if='isValid'>
<!--            有效-->
            <el-form-item>
              <el-cascader
                v-model="validValues"
                placeholder="请选择有效原因"
                :props="{ checkStrictly: true }"
                :options="validOptionss"
                clearable></el-cascader>
            </el-form-item>
          </el-col>

          <el-col :span='10' v-if='!isValid'>
<!--            无效-->
            <el-form-item>
              <el-cascader
                v-model="invalidValues"
                placeholder="请选择无效原因"
                :props="{ checkStrictly: true }"
                :options="invalidOptionss"
                clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span='6'>
            <el-button type='primary' size='small' @click='downSecondaryClicks'>
              导出
            </el-button>
            <el-button type='primary' size='small' @click='resetSecondaryClicks'>
              重置
            </el-button>
          </el-col>

        </el-row>

      </el-form>


    </el-dialog>1





  </div>
</template>

<script>
import pinyin from 'js-pinyin'
import {
  articleByFailReasonExportApi,
  articleExportApi,
  articleExportCountApi,
  articleExportSelectListApi,
  articlePageApi,
  basicWordSelectApi1,
  getIndustryNameByCodeApi,
  getStockIdByStockNameApi,
  numberConutApi,
  queryBasicWordApi,
  selectApi,
  treeContentypeApi
} from 'api/article'
import { industryCategoryTreeApi } from 'api/common'
import { getUserList1 } from 'api/rightsetting'

import moment from 'moment'

import releaseDetail from '@/views/release/releaseDetail.vue'

export default {

    name: 'contentReview',
    components: {
        releaseDetail,

    },
    computed: {
        formatDate() {
            return function (oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()

                const dateString =
                    Y +
                    '-' +
                    add0(M) +
                    '-' +
                    add0(D) +
                    '  ' +
                    add0(h) +
                    ':' +
                    add0(m) +
                    ':' +
                    add0(s)
                return dateString
            }
        }
    },

    data() {
        return {
          validValues:null,
          invalidValues:null,
          invalidOptionss:[],
          validOptionss:[],
          isValidRadio:0,
          isValid:true,
          pageMsgSecondary:{

          },
          tableDataShow:[],
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() > Date.now()
            }
          },
          publisherDate: [], //时间选择器筛选
          timeDefaultShow: '',
          contentTypeOptions:[
            {
              value: '0',
              label: 'A股'
            },
            {
              value: '1',
              label: "港通股"
            },{
              value: '2',
              label: '中概股'
            },{
              value: '3',
              label: '新三板'
            }
          ],
          autoStateOptions:[
            {
              value: '10',
              label: '审核中'
            },
            {
              value: '20',
              label: "成功"
            },{
            value: '30',
              label: '失败'
            }
          ],
          exportAllVisible:false,
          pageMsgDownAll:{
            companyId:null,
            stockName:null,
            authState:null,
            contentTypeId:null,
            startTime:'',
            endTime:''
          },
          exportSecondaryVisible: false,
			//有效绑定值
			validValue:{},
			//无效绑定值
			invalidValue:{},
			//有效原因
			validOptions: [],
			//无效原因
			invalidOptions:[],
            rules: {
                failReason: [{ required: true, message: '请填写驳回原因' }]
            },
            passDialog: false,
            oneDialog: false,
            ruleModel: {
                articleId: null,
                failReason: null
            },
            months: '',
            options: [],
            pageForm:{
                pageNum: 1,
                pageSize: 100,
                stockName: '',
                num: 0,
                stringTime: ''
            },
            companyOptions: [],
            selectionArr: [],
            tableData: [],
            tableData1: [],
          pageTotalExport:0,
            pageTotal: 0,
            pageTotal1: 0,
            optionAuth: [{
                value: '全部',
                label: '全部'
            },{
                value: '有效',
                label: '有效'
            }, {
                value: '无效',
                label: '无效'
            }],
            optionContent: [{
                value: 'A股',
                label: 'A股'
            }, {
                value: '港股',
                label: '港股'
            }, {
                value: '中概股',
                label: '中概股'
            },{
              value: '新三板',
              label: '新三板'
            }],
            optionUserId: [],
            optionIndustryCategoryCode: [],
            basicWordCode: [],
            basicWordCode1: [],
            pageMsg: {
              stockCode:null,
            current:1,

                // failReason: "",
                // failReasonWx: "",
				failReason: undefined,
				failReasonWx: undefined,
                industryCategoryArr: [],
                industryCategoryCode: null,
                dateType: 0,
                contentType: null,
                year: undefined,
                number: undefined,
                pageNum: 1,
                pageSize: 100,
                descs: ['created_time'],
                state: null,
                stockName: null,
                userId: null,
                day: '',
                month: '',
                startMonths: '',
                endMonths: '',
              validOrInvalidType:0
            },

            tableLoading: false,
            tableLoading1: false,
            rowID: null,
            rowStockName: null,
            detailBool: false,
            detailBool1: false,
          tableLoadingShow: false,
        }
    },
    mounted() {
        this.tree()
        this.initData()
        this.initRoles()
        this.tree1();
        this.tree2();

        this.initCategoryCode()
    },
    created() {
      this.timeDefaultShow = new Date()
      this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
        this.initCategoryCode()
      this.tree111();
      this.tree222()
    },
    methods: {
      resetSecondaryClicks(){
        this.isValidRadio = 0;
        this.validValues = this.$options.data().validValues;
        this.invalidValues = this.$options.data().invalidValues;
      },
      downSecondaryClicks(){
        console.log("valid:"+this.validValues)
        console.log("invalid:"+this.invalidValues)

        let failReason = "";
        if (this.isValid){
          //有效
          failReason = this.validValues
        }else {
          //无效
          failReason = this.invalidValues
        }

        console.log("failReason"+JSON.toString(failReason))

        articleByFailReasonExportApi(failReason).then(data=>{
          let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let blobUrl = window.URL.createObjectURL(blob)
          let link = document.createElement('a')
          link.style.display = 'none'
          let dateXls = new Date().toLocaleDateString()
          link.download = `二级列表${dateXls}.xlsx`
          link.href = blobUrl
          link.click()
          link.remove()
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(blobUrl)
        })
        //二级列表导出按钮

      },
      //无效原因 一级菜单信息
      tree222() {
        console.log("测试111")
        // basicWordSelectApi().then(({ result }) => {
        queryBasicWordApi().then(({ result }) => {
          console.log("测试222:"+JSON.stringify(result))

          const classification = result
          function classifi(arr) {
            arr.forEach(item => {
              console.log("wordName"+item.wordName)
              item.label = item.wordName
              item.value = item.id
              if (item.children && item.children.length > 0) {
                classifi(item.children)
              } else {
                delete item['children']
              }
            })
          }
          classifi(classification)
          this.invalidOptionss = result
          // 原代码
          // this.basicWordCode = data.result
        });
      },
      //有效原因 一级菜单信息
      tree111() {
        basicWordSelectApi1().then(({ result }) => {
          const classification = result
          function classifi(arr) {
            arr.forEach(item => {
              console.log("wordName"+item.wordName)
              item.label = item.wordName
              item.value = item.id
              if (item.children && item.children.length > 0) {
                classifi(item.children)
              } else {
                delete item['children']
              }
            })
          }
          classifi(classification)
          this.validOptionss = result

          //原代码
          // this.basicWordCode1 = data.result
        });
      },
      radioInpufChange(){
        if (this.isValidRadio===0){
          this.isValid = true;
        }else if (this.isValidRadio===1){
          this.isValid = false;
        }
      },
      exportSecondaryVisibleClose(){
        this.exportSecondaryVisible = false;
        //重置
        this.resetSecondaryClicks();
      },
      downSecondaryClick(){
        //导出二级列表
        this.exportSecondaryVisible = true;
      },
      resetDownloadPatterns(){
        this.pageMsgDownAll = this.$options.data().pageMsgDownAll;
        this.downloadPatterns();
      },
      selectExportData(){
        const stockName = this.pageMsgDownAll.stockName;
        if (stockName!=null&&stockName!=""&&stockName!=undefined){
          //根据公司名查询id
          getStockIdByStockNameApi(stockName).then(res =>{
            if (res.code === 200){
              console.log("id"+res.result)
              this.pageMsgDownAll.companyId = res.result
            }
          })
        }
        this.exportAllShowData()
      },
      exportAllShowData(){
        this.tableLoadingShow = true
        const data = {
          ...this.pageMsgDownAll
        }
        articlePageApi(data).then(res => {
          if (res.code === 200) {
            let { total, records } = res.result
            this.tableDataShow = records
            this.pageTotalExport = total
          } else {
            this.$message({
              type: 'error',
              message: res.desc
            })
          }
          this.tableLoadingShow = false
        })
      },
      dateChange() {
        if (this.publisherDate && this.publisherDate.length == 2) {
          // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
          // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
          this.pageMsgDownAll.startTime = this.publisherDate[0]
          this.pageMsgDownAll.startT = this.publisherDate[0] + " 00:00:00"
          this.pageMsgDownAll.endTime = this.publisherDate[1]
          this.pageMsgDownAll.endT = this.publisherDate[1]+ ' 23:59:59'

        }
      },
      exportAllVisibleClose(){
        this.exportAllVisible = false;
        this.publisherDate=[];
      },
      openDownLoadPatter(){
        this.exportAllShowData();
        this.exportAllVisible = true;
      },
      downloadPatterns() {
        const stockName = this.pageMsgDownAll.stockName;
        if (stockName!=null&&stockName!=""&&stockName!=undefined){
          //根据公司名查询id
          getStockIdByStockNameApi(stockName).then(res =>{
            console.log("companyId:"+res.result)
            if (res.code === 200){
              this.pageMsgDownAll.companyId = res.result
              articleExportApi({
                ...this.pageMsgDownAll
              }).then(data => {
                let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
                // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                let dateXls = new Date().toLocaleDateString()
                link.download = `分发中心列表${dateXls}.xlsx`
                link.href = blobUrl
                link.click()
                link.remove()
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
              })
            }
          })
        }else {
          articleExportApi({
            ...this.pageMsgDownAll
          }).then(data => {
            let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
            // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
            let blobUrl = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.style.display = 'none'
            let dateXls = new Date().toLocaleDateString()
            link.download = `分发中心列表${dateXls}.xlsx`
            link.href = blobUrl
            link.click()
            link.remove()
            // 5.释放这个临时的对象url
            window.URL.revokeObjectURL(blobUrl)
          })

        }

      },
        getPinYin(name){
            /**
             * 声明变量存储首拼
             */
            var char = ''
            /**
             * 调用设置属性
             */
            pinyin.setOptions({checkPolyphone:false,charCase:0});
            /**
             * 跟据传入文字调用方法进行转换
             */
            this.pageMsg.stockName =name.toUpperCase();

            /**
             * 赋值检索码
             */
          // console.log('char', this.pageMsg.stockName)
        },
		
		//有效
        tree1() {
			//原代码
            // basicWordSelectApi1().then(data => {
            //     this.basicWordCode1 = data.result
            // });
			basicWordSelectApi1().then(({ result }) => {
			      const classification = result
			      function classifi(arr) {
			          arr.forEach(item => {
			              item.label = item.wordName
			              item.value = item.id
			              if (item.children && item.children.length > 0) {
			                  classifi(item.children)
			              } else {
			                  delete item['children']
			              }
			          })
			      }
			      classifi(classification)
						  this.validOptions = result
						  
						  //原代码
			    // this.basicWordCode1 = data.result
			});
        },
		//无效
        tree2() {
			//无效原因
            // basicWordSelectApi().then(data => {
            //     this.basicWordCode = data.result
            // });
          // basicWordSelectApi().then(({ result }) => {
          queryBasicWordApi().then(({ result }) => {
							const classification = result
			      function classifi(arr) {
			          arr.forEach(item => {
			              item.label = item.wordName
			              item.value = item.id
			              if (item.children && item.children.length > 0) {
			                  classifi(item.children)
			              } else {
			                  delete item['children']
			              }
			          })
			      }
			      classifi(classification)
						  this.invalidOptions = result
							// 原代码
			    // this.basicWordCode = data.result
			});
        },
        initRoles() {
            getUserList1().then(res => {
                this.optionUserId = res.result.records
            })
        },
        initCategoryCode() {
            industryCategoryTreeApi().then(({ result }) => {
                const classification = result
                function classifi(arr) {
                    arr.forEach(item => {
                        item.label = item.name
                        item.value = item.code
                        if (item.children && item.children.length > 0) {
                            classifi(item.children)
                        } else {
                            delete item['children']
                        }
                    })
                }
                classifi(classification)
                this.options = result
            })
        },
        handleChange(val) {
            this.pageMsg.industryCategoryCode = val[val.length - 1]
        },
        dialogCreate () {
            this.detailBool1 = false
            this.pageForm.num = 0;

        },
        tree() {
            treeContentypeApi().then(data => {
                this.optionIndustryCategoryCode = data.result.industryCategoryList
            });
        },
        searchDetail() {
            this.pageForm.num = 0
            this.pageForm.userId = ''
           this.Detail( this.pageForm)
        },
        downloadPatternTitle() {
            articleExportSelectListApi({
                ...this.pageForm
            }).then(data => {
                let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
                // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                   // let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
                    link.download = `发布文章.xlsx`


                link.href = blobUrl;
                link.click()
                link.remove()
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
            })
        },
		//导出方法-Dream
       async downloadPattern() {
          this.pageMsg.failReason = JSON.stringify(this.pageMsg.failReason);
          this.pageMsg.failReasonWx = JSON.stringify(this.pageMsg.failReasonWx);
          let industryName = null;
          let contentType = this.pageMsg.contentType;
          let stockName = this.pageMsg.stockName;
          if (this.pageMsg.industryCategoryCode!=null){
            await getIndustryNameByCodeApi(this.pageMsg.industryCategoryCode)
              .then(res=>{
                industryName = res.result;
              })
          }

          if (industryName==null){
            industryName="";
          }
          if (contentType==null){
            contentType="";
          }
         if (stockName==null){
           stockName="";
         }


          //获取行业名称
         await articleExportCountApi({
                ...this.pageMsg
            }).then(data => {
                let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
                // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                if (this.pageMsg.dateType === 0) {
                    let currDate = moment(new Date(),"YYYY-MM-DD");
                    let weekOfday = parseInt(moment().format('d')) // 计算今天是这周第几天 周日为一周中的第一天
                    let monday = moment().weekday(1).format('YYYY-MM-DD') //本周一
                    let sunday = moment().weekday(7).format('YYYY-MM-DD') //本周日
                    let dateXls = monday+'至'+sunday;
                    link.download = `${stockName}${contentType}${industryName}${dateXls}数据分析.xlsx`
                    //当月
                }else if (this.pageMsg.dateType === 1) {
                    let currDate = moment(new Date(),"YYYY-MM-DD");
                    var firstDay= moment(currDate.startOf("month").valueOf()).format('YYYY-MM-DD');//获取该月份第一天的时间戳
                    var endDay = moment(currDate.endOf("month").valueOf()).format('YYYY-MM-DD');//获取该月份最后一天的时间戳
                    let dateXls = firstDay+'至'+endDay;
                    link.download = `${stockName}${contentType}${industryName}${dateXls}数据分析.xlsx`

                    //当日
                }else if (this.pageMsg.dateType === 2) {
                    let dateXls =  this.pageMsg.day;
                  link.download = `${stockName}${contentType}${industryName}${dateXls}数据分析.xlsx`

                }else if (this.pageMsg.dateType === 3) {
                    let dateXls =  this.pageMsg.year+'年';
                  link.download = `${stockName}${contentType}${industryName}${dateXls}数据分析.xlsx`

                }else if (this.pageMsg.dateType === 4) {
                    let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
                  link.download = `${stockName}${contentType}${industryName}${dateXls}数据分析.xlsx`
                }


                link.href = blobUrl;
                link.click()
                link.remove()
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
            })
        },
		
		//初始化列表
        initData() {
          this.pageMsg.current = this.pageMsg.pageNum;
            this.tableLoading = true
            const data = {
                ...this.pageMsg
            }
			// this.$message({
			//     type: 'success',
			//     message: this.pageMsg.failReason + "+" +this.pageMsg.failReasonWx
			// })
			
			// console.log("failReason"+this.pageMsg.failReason)
			// console.log("failReasonWx"+this.pageMsg.failReasonWx)

			
			//根据,划分

          if (this.pageMsg.stockName==""||this.pageMsg.stockName==null||this.pageMsg.stockName==undefined){
            this.pageMsg.stockName = null;
          }

			if(this.pageMsg.failReason!=null && this.pageMsg.failReason!=undefined && this.pageMsg.failReason!=''
			&& typeof this.pageMsg.failReason!="string"){
				this.pageMsg.failReason = this.pageMsg.failReason.join(',');
			}
			
			if(this.pageMsg.failReasonWx!=null && this.pageMsg.failReasonWx!=undefined && this.pageMsg.failReasonWx!=''
			&& typeof this.pageMsg.failReasonWx!="string"){
				this.pageMsg.failReasonWx = this.pageMsg.failReasonWx.join(',');
			}
            numberConutApi(this.pageMsg).then(res => {
                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData = records
                    this.pageTotal = total
                    if (this.pageMsg.dateType === 0) {
                        this.pageMsg.day = '';
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 1) {
                        this.pageMsg.day = '';
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 2) {
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 3) {
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.day = '';
                    }else if (this.pageMsg.dateType === 4) {
                        this.pageMsg.year = '';
                        this.pageMsg.day = '';
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading = false
            })
			// this.pageMsg.failReason = '';
			// this.pageMsg.failReasonWx = '';
            this.initRoles()
        },

        turnDetail(row) {
            // this.$router.push({
            //   path: '/releaseDetail',
            //   query: {
            //     id: row.id
            //   }
            // })
            this.rowID = row.id
            this.detailBool = true
        },
        Detail(row) {
            // this.$router.push({
            //   path: '/releaseDetail',
            //   query: {
            //     id: row.id
            //   }
            // })

            this.detailBool1 = true
            this.tableLoading1 = true
            this.pageForm.stockName = row.stockName

            selectApi(this.pageForm).then(res => {

                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData1 = records

                    if (records.size > 0) {
                        this.pageForm.stockName = records[0].stockName;
                    }

                    this.pageTotal1 = total
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading1 = false
            })
        },
        DateToStr(dd) {

        },
        formatData(row, column, cellValue, index) {
            //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
            //最终返回 s 就可以了
            var s = new Date(cellValue).toLocaleString()
            return s

            // 测试参数含义：不知道参数是什么含义的就打印出来
        },
        searchData(bool) {
            if (bool) {
                this.pageMsg.pageNum = 1
                this.pageMsg.pageSize = 100
                 if (this.pageMsg.dateType === 3 && !this.pageMsg.year) {
                    this.$message({
                        type: 'error',
                        message: '请选择年份'
                    });
                    return;
                } else if (this.pageMsg.dateType === 2 && !this.pageMsg.day) {
                    this.$message({
                        type: 'error',
                        message: '请选择日期'
                    });
                    return;
                }
                if (this.pageMsg.dateType === 4 && this.pageMsg.startMonths && this.pageMsg.endMonths) {
                    this.pageMsg.month = this.pageMsg.startMonths + ',' + this.pageMsg.endMonths;
                } else if(this.pageMsg.dateType === 4 && !this.pageMsg.startMonths && !this.pageMsg.endMonths){
                    this.$message({
                        type: 'error',
                        message: '请选择日期'
                    });
                    return;
                }
            } else {
				//重置事件
				// this.invalidOptions = [];
				// this.validOptions = [];
				// this.invalidValue = undefined;
				// this.validValue = undefined;
                this.pageMsg = this.$options.data().pageMsg
                this.pageMsg.failReasonWx = undefined;
                this.pageMsg.failReason = undefined;
            }
            
            this.initData()
        },
        handleSelectionChange(e) {
            this.selectionArr = e
        },
        closeAdd() {
            this.dialogAdd = false
            this.dialogSet = false
            this.initData()
        }
    }
}
</script>

<style lang="scss" scoped>
  .channelManagement {
    width: 100%;
    .channelManagement-head {
      padding: 10px 20px;
      background: #fff;
    }
    .box-card {
      width: calc(100% - 60px);
      padding: 10px;
      margin: 20px auto;
    }
    /deep/.state-table {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .select_warn {
      background: rgb(230, 247, 255);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      span {
        color: $primary;
      }
    }
  }
</style>
