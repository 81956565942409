import apiServer from 'api/apiServer.js'


export function getEtfPageApi(params = {}) {
  let url = `/etf/getEtfPage` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getChildrenEtfPageApi(params = {}) {
  let url = `/etf/getChildrenEtfPage` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getEtfTagsApi(params = {}) {
  let url = `/etf/getEtfTags` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getIprRanking(params = {}) {
  let url = `/stockData/getIprRanking` //post methods
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getInteractRankingApi(params = {}) {
  let url = `/stockData/getInteractRanking` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getInteractByQualityRankingApi(params = {}) {
  let url = `/stockData/getInteractByQualityRanking` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getIndustryDataByIndustryCodeApi(params = {}) {
  let url = `/stockData/getIndustryDataByIndustryCode` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getIndustryETFDataApi(params = {}) {
  let url = `/stockData/getIndustryETFData` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getIndustryEtfCodeApi(params = {}) {
  let url = `/stockData/getIndustryEtfCode` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getIndustryDataMarketApi(params = {}) {
  let url = `/stockData/getIndustryDataMarket` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getInteractByTimelyRankingApi(params = {}) {
  let url = `/stockData/getInteractByTimelyRanking` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function transitionIndustry(params = {}) {
  let url = `/stockData/transitionIndustry` //post methods
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getUnsubscribePageApi(params = {}) {
  let url = `/basicStock/getUnsubscribePage` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

