<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
  <div>
    <el-card class="box-card">
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="clientName"
          label="公司简称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="clientUrl"
          label="公司网址"
          width="180">
        </el-table-column>
        <el-table-column
          prop="clientPhone"
          label="联系方式">
        </el-table-column>
        <el-table-column
          prop="clientEmail"
          label="公司邮箱">
        </el-table-column>
<!--        <el-table-column-->
<!--          prop="clientWorkAddress"-->
<!--          label="办公地点">-->
<!--        </el-table-column>-->
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="examineClick(scope.row)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small" @click='auditClick(scope.row)'>审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页查询 -->
      <div style="margin: 0px auto;">
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal">
        </el-pagination>
      </div>
    </el-card>

<!--查看-->
    <div>
      <el-dialog
        title="客户信息"
        :visible.sync="examineVisible"
        width="800px"
        :before-close="handleClose"
        @close='handleClose'
      >
        <el-form :model='clientForm'
                 ref="clientForm"
                 :label-position="labelPosition"
                 :rules="clientFormRules"
                 :label-width="formLabelWidth">
          <el-form-item label="公司简称"  placeholder="请输入公司简称">
            <el-input v-model="clientForm.clientName"></el-input>
          </el-form-item>
          <el-form-item label="公司股票代码" prop="clientStockCode"   placeholder="请输入公司股票代码">
            <el-input v-model="clientForm.clientStockCode"></el-input>
            <span style='color: red'>例如:SZ000949/SH603738</span>
          </el-form-item>
          <el-form-item label="公司网址"  placeholder="请输入公司网址">
            <el-input v-model="clientForm.clientUrl"></el-input>
          </el-form-item>
          <el-form-item label="公司微信公众号名称"  placeholder="请输入公司微信公众号名称">
            <el-input v-model="clientForm.clientGzhName"></el-input>
          </el-form-item>
          <el-form-item label="公司邮箱" placeholder="请输入公司邮箱">
            <el-input v-model="clientForm.clientEmail"></el-input>
          </el-form-item>
          <el-form-item label="公司联系方式">
            <el-input v-model="clientForm.clientPhone"></el-input>
          </el-form-item>
<!--          <el-form-item label="公司所在省">-->
<!--            <el-input v-model="clientForm.clientProvince"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="公司所在市">-->
<!--            <el-input v-model="clientForm.clientCity"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="公司办公地址">-->
<!--            <el-input v-model="clientForm.clientWorkAddress"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button type='primary' @click='save()'>保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>


    <div>
      <el-dialog
        title="提示"
        :visible.sync="auditVisible"
        width="20%"
        :before-close="auditleClose">
        <span>确定审核成功吗?</span>
        <span slot="footer" class="dialog-footer">
    <el-button @click="auditVisible = false">取 消</el-button>
    <el-button type="primary" @click="auditOKClick">确 定</el-button>
  </span>
      </el-dialog>
    </div>



    <div>
      <el-dialog
        title="提示"
        :visible.sync="auditsVisible"
        width="35%"
        :before-close="auditlesClose">

        <div v-if='isCheckState' style='color: red;margin-bottom: 30px'>
          <p>{{stockNameResult}}</p>
          <p>{{emailResult}}</p>
          <p>{{phoneResult}}</p>
          <p>{{gzhNameResult}}</p>
          <p>{{urlResult}}</p>
        </div>

        <p>确定审核成功吗?</p>
        <span slot="footer" class="dialog-footer">
    <el-button @click="auditsVisible = false">取 消</el-button>
    <el-button type="primary" @click="auditOKsClick">确 定</el-button>
  </span>
      </el-dialog>
    </div>



  </div>
  </div>
</template>

<script>
import { getClientInfoAllApi,auditOKApi,saveInfoApi,checkStockCodeApi,comparisonApi } from 'api/company'
import fa from 'element-ui/src/locale/lang/fa'

export default {
  name: 'index',
  data(){
    return{
      isCheckState:false,
      stockNameResult:"",
      emailResult:"",
      phoneResult:"",
      gzhNameResult:"",
      urlResult:"",
      clientId:"",
      stockCode:"",
      formLabelWidth: '160px',
      labelPosition: 'right',
      examineVisible:false,
      auditVisible:false,
      auditsVisible:false,
      clientForm:{
        clientId:"",
        clientName:"",
        clientStockCode:"",
        clientUrl:"",
        clientEmail:"",
        clientGzhName:"",
        clientPhone:"",
        clientProvince:"",
        clientCity:"",
        clientWorkAddress:""
      },
      tableData:[],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10
      },
      clientFormRules: {
        clientStockCode: [{required: true, message: '公司股票代码不能为空 ',trigger: 'blur'}],
      },
    }
  },
  mounted() {
    this.initData();
  },
  methods:{
    //保存的方法
    save(){
      this.$refs.clientForm.validate(valid => {
        if (valid){
          //验证股票代码重复问题
          checkStockCodeApi(this.clientForm.clientStockCode).then(res => {
            if (res.code === 200){
              saveInfoApi({...this.clientForm}).then(res => {
                if(res.code === 200){
                  this.$message({
                    type: 'success',
                    message: '保存成功'
                  })
                }else {
                  this.$message({
                    type: 'error',
                    message: res.desc
                  })
                }
                this.clientForm = this.$options.data().clientForm;
                this.examineVisible = false;
                this.initData();
              })
            }
          })
        }
      })
    },
    auditOKsClick(){
      auditOKApi(this.clientId).then(res =>{
        if(res.code === 200){
          this.$message({
            type: 'success',
            message: "审核成功"
          })
          this.auditsVisible=false;
          this.initData();
        }else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }

      })
    },
    //审核确定的方法
    auditOKClick(){
      if(this.stockCode==""||this.stockCode==null||this.stockCode=="undefined"){
        this.auditVisible=false;
        this.initData();
        return this.$message.error("公司股票代码不能为空!")
      }
      checkStockCodeApi(this.stockCode).then(res => {
        if (res.result){
          //说明存在该股票代码或该公司
          comparisonApi(this.clientId).then(res =>{
            if(res.code === 200){
              if (res.result.isCheck){
                //说明都一致
                auditOKApi(this.clientId).then(res =>{
                  if(res.code === 200){
                    this.$message({
                      type: 'success',
                      message: "审核成功"
                    })
                    this.auditVisible=false;
                    this.initData();
                  }else {
                    this.$message({
                      type: 'error',
                      message: res.desc
                    })
                  }
                })
              }else {
                this.isCheckState=true;
                if (res.result.stockNameResult!=null){
                  this.stockNameResult = res.result.stockNameResult;
                }
                if (res.result.emailResult!=null){
                  this.emailResult = res.result.emailResult;
                }
                if (res.result.gzhNameResult!=null){
                  this.gzhNameResult = res.result.gzhNameResult;
                }
                if (res.result.phoneResult!=null){
                  this.phoneResult = res.result.phoneResult;
                }
                if (res.result.urlResult!=null){
                  this.urlResult = res.result.urlResult;
                }
                //提示不一致
                this.auditsVisible=true;
              }
            }
          })

        }else {
          //说明不存在 直接插入
          auditOKApi(this.clientId).then(res =>{
            if(res.code === 200){
              this.$message({
                type: 'success',
                message: "审核成功"
              })
              this.auditVisible=false;
              this.initData();
            }else {
              this.$message({
                type: 'error',
                message: res.desc
              })
            }
          })
        }
        //
        // if (res.code === 200) {
        //   auditOKApi(this.clientId).then(res =>{
        //     if(res.code === 200){
        //       this.$message({
        //         type: 'success',
        //         message: "审核成功"
        //       })
        //       this.auditVisible=false;
        //       this.initData();
        //     }else {
        //       this.$message({
        //         type: 'error',
        //         message: res.desc
        //       })
        //     }
        //
        //   })
        // }
        this.auditVisible=false;
        this.initData();
      })
    },
    //审核显示的方法
    auditClick(row){
      this.auditVisible = true;
      this.clientId = row.id;
      this.stockCode = row.clientStockCode;
    },
    //查看
    examineClick(row){
      this.examineVisible = true;
      this.clientForm.id = row.id;
      this.clientForm.clientName = row.clientName;
      this.clientForm.clientStockCode = row.clientStockCode;
      this.clientForm.clientUrl = row.clientUrl;
      this.clientForm.clientGzhName = row.clientGzhName;
      this.clientForm.clientCity = row.clientCity;
      this.clientForm.clientProvince = row.clientProvince;
      this.clientForm.clientEmail = row.clientEmail;
      this.clientForm.clientPhone = row.clientPhone;
      this.clientForm.clientWorkAddress = row.clientWorkAddress;
    },
    handleClose(){
      this.examineVisible=false;
    },
    auditleClose(){
      this.auditVisible = false;
    },
    auditlesClose(){
      this.auditsVisible = false;
    },
    //初始化
    initData(){
      getClientInfoAllApi({...this.pageMsg}).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    }
  }
}
</script>



<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>