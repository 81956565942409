var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userlist" },
    [
      _vm._m(0),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "text-left" },
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.pageMsg,
                    size: "small",
                    "label-position": "left",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "limitinputWidth",
                          attrs: { label: "账户：" },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.pageMsg.searchWord,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "searchWord", $$v)
                              },
                              expression: "pageMsg.searchWord",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c("p", { staticStyle: { opacity: "0" } }, [_vm._v("1")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "limitinputWidth",
                          attrs: { label: "角色：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.pageMsg.roleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "roleId", $$v)
                                },
                                expression: "pageMsg.roleId",
                              },
                            },
                            _vm._l(_vm.stateOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 10 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _vm.userbtn.indexOf("USERLIST_ADD") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addAndEditDialog()
                                },
                              },
                            },
                            [_vm._v(" 新增用户 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("USERLIST_EXPORT") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.platformExport },
                            },
                            [_vm._v(" 导出列表 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectionArr.length > 0
            ? _c(
                "div",
                { staticClass: "select_warn" },
                [
                  _vm._v(" 已经选择" + _vm._s(_vm.selectionArr.length) + "项 "),
                  _vm.userbtn.indexOf("USERLIST_MOREHIDE") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.endList(2)
                            },
                          },
                        },
                        [_vm._v("禁用")]
                      )
                    : _vm._e(),
                  _vm.userbtn.indexOf("USERLIST_MOREUP") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.endList(1)
                            },
                          },
                        },
                        [_vm._v("启用")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
                data: _vm.tableData,
                "row-key": "id",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "username",
                  label: "账户(手机号)",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "名称",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "rolesInfoName",
                  label: "用户角色",
                  "header-align": "center",
                  align: "center",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "rolesInfoName",
                  label: "角色备注",
                  "header-align": "center",
                  align: "center",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(" " + _vm._s(row.rolesInfo[0].remarks) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updatedTime",
                  label: "更新时间",
                  "header-align": "center",
                  align: "center",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(new Date(row.updatedTime).toLocaleString()) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "180",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "div",
                          [
                            _vm.userbtn.indexOf("USERLIST_EDIT") >= 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addAndEditDialog(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            row.status === 1 &&
                            _vm.userbtn.indexOf("USERLIST_HIDE") >= 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.enableDisablingRole(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 禁用 ")]
                                )
                              : _vm._e(),
                            row.status !== 1 &&
                            _vm.userbtn.indexOf("USERLIST_UP") >= 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.enableDisablingRole(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 启用 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            visible: _vm.userDetailVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDetailVisible = $event
            },
            close: _vm.userDialogClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" " + _vm._s(_vm.userDetailOperate) + " ")]
          ),
          _c(
            "el-form",
            {
              ref: "userDetail",
              attrs: {
                model: _vm.userFormDetail,
                rules: _vm.userFormRules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户账号(手机号)：", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11", clearable: "" },
                    model: {
                      value: _vm.userFormDetail.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.userFormDetail, "username", $$v)
                      },
                      expression: "userFormDetail.username",
                    },
                  }),
                ],
                1
              ),
              _vm.userDetailOperate == "编辑用户"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "(不填写即原密码)",
                        },
                        model: {
                          value: _vm.userFormDetail.updPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.userFormDetail, "updPassword", $$v)
                          },
                          expression: "userFormDetail.updPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userDetailOperate == "新增用户"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "addPassword" } },
                    [
                      _c("el-input", {
                        attrs: { "show-password": "", clearable: "" },
                        model: {
                          value: _vm.userFormDetail.addPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.userFormDetail, "addPassword", $$v)
                          },
                          expression: "userFormDetail.addPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "昵称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "输入昵称" },
                    model: {
                      value: _vm.userFormDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.userFormDetail, "name", $$v)
                      },
                      expression: "userFormDetail.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "输入邮箱" },
                    model: {
                      value: _vm.userFormDetail.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.userFormDetail, "email", $$v)
                      },
                      expression: "userFormDetail.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户角色：", prop: "roleId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.userFormDetail.roleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.userFormDetail, "roleId", $$v)
                        },
                        expression: "userFormDetail.roleId",
                      },
                    },
                    _vm._l(_vm.newRoleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "comImage",
                  attrs: { label: "上传图片：", prop: "faceDocId" },
                },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadUrl,
                        headers: _vm.myHeaders,
                        "list-type": "picture-card",
                        accept: "image/jpeg,image/jpg,image/png",
                        "on-change": _vm.uploadChange,
                        "on-success": _vm.uploadReturn,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-exceed": _vm.handleExceed,
                        "on-remove": _vm.companyUploadRemove,
                        limit: 1,
                        "file-list": _vm.fileList,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.userDetailVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveMsg.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            visible: _vm.userDetailTextVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDetailTextVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("查看用户")]
          ),
          _c(
            "el-form",
            { attrs: { "label-width": "150px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户账号(手机号)：", prop: "username" } },
                [_vm._v(" " + _vm._s(_vm.userDetailText.username) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名称：", prop: "name" } },
                [_vm._v(" " + _vm._s(_vm.userDetailText.name) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户角色：", prop: "rolesInfoName" } },
                [_vm._v(" " + _vm._s(_vm.userDetailText.rolesInfoName) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channelManagement-head" }, [
      _c("h1", [_vm._v("用户列表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }