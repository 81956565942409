<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">

      <div>
        <el-form
          :model='pageMsg'
          size='small'
          label-position="center"
          label-width="100px">
          <el-row>
            <el-col :span='6'>
              <el-form-item label="名称:" label-width='120px'>
                <el-input
                  v-model='pageMsg.etfName'
                  placeholder='请输入内容'>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span='6' align='right'>
              <el-button type='primary' @click='initData'>查询</el-button>
              <el-button @click='reset'>重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>


      <div>
        <el-table
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          :data="tableData"
          v-loading="tableLoading"
        >

          <el-table-column
            label="序号"
            width='50'
            header-align="center"
            align="center">

            <template slot-scope="scope">
              {{ getPage(scope.$index, pageMsg.pageNum,pageMsg.pageSize) }}
            </template>

<!--            <template slot-scope="scope">-->

<!--              {{ this.getPage(scope.$index,pageMsg.pageNum) }}-->


<!--&lt;!&ndash;              {{ scope.$index + 1 }}&ndash;&gt;-->
<!--            </template>-->
          </el-table-column>

          <el-table-column
            prop="etfName"
            label="ETF名称"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click.native.prevent="open(row.etfCode)">
                {{row.etfName}}
              </el-button>
            </template>
          </el-table-column>

          <el-table-column
            prop="etfCode"
            label="股票代码"
            header-align="center"
            align="center">

          </el-table-column>



        </el-table>


        <div class="pb20"></div>

        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-sizes="[10, 50, 100, 200]"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>

      </div>
    </el-card>



    <div>
<!--      点击详情后的分页处理
-->
      <el-dialog title="查看成分股信息"
                 style="margin-left: -80px"
                 width="800px"
                 :visible.sync="dialogVisible"
                 @close="closeDia" >

        <el-table
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          :data="childrenTableData"
          v-loading="childrenTableLoading"
        >

          <el-table-column
            label="序号"
            width='50'
            header-align="center"
            align="center">
            <template slot-scope="scope">
              {{ getPage(scope.$index, childrenPageMsg.pageNum,childrenPageMsg.pageSize) }}
            </template>
<!--            <template slot-scope="scope">-->

<!--              {{ getPage(scope.$index,childrenPageMsg.pageNum,childrenPageMsg.pageSize) }}-->

<!--            </template>-->

<!--            prop="num"-->

<!--            <template slot-scope="scope">-->
<!--              {{ getPage(scope.$index, childrenPageMsg.pageNum) }}-->
<!--            </template>-->

<!--            <template slot-scope="scope">-->
<!--              {{ scope.$index + 1 }}-->
<!--            </template>-->
          </el-table-column>

          <el-table-column
            prop="stockName"
            label="公司名称"
            header-align="center"
            align="center"
          >
          </el-table-column>

          <el-table-column
            label="股票代码"
            prop="stockCode"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>

        <div class="pb20"></div>

        <el-pagination
          class="text-center"
          v-if="childrenPageTotal > 0"
          @size-change="childrenInitData"
          @current-change="childrenInitData"
          :current-page.sync="childrenPageMsg.pageNum"
          :page-sizes="[10, 50, 100, 200]"
          :page-size.sync="childrenPageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="childrenPageTotal"
        >
        </el-pagination>

      </el-dialog>



    </div>


    </div>

</template>

<script>
import { getChildrenEtfPageApi, getEtfPageApi } from 'api/stock'

export default {
  data(){
    return{
      pageMsg:{
        etfName:null,
        pageNum: 1,
        pageSize: 10,
      },
      childrenPageMsg:{
        pageNum: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      childrenPageTotal: 0,
      tableData:[],
      childrenTableData:[],
      childrenTableLoading:false,
      tableLoading:false,
      dialogVisible:false,
      etfCode:null,
    }
  },
  mounted() {
    this.initData();
  },
  methods:{
    getNum(){

    },
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    },
    initData(){
      this.tableLoading = true
      getEtfPageApi(this.pageMsg).then(res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false

      })
    },
    open(etfCode){


      //获取分页
      this.etfCode = etfCode;
      this.dialogVisible = true;
      this.childrenInitData();
    },
    closeDia(){
      this.dialogVisible = false;
      this.childrenPageMsg = this.$options.data().childrenPageMsg;
      // this.childrenTableData = this.$options.data().childrenTableData;
      // this.childrenPageTotal = this.$options.data().childrenPageTotal;
    },
    childrenInitData(){
      this.tableLoading = true
      this.childrenPageMsg.etfCode = this.etfCode;
      getChildrenEtfPageApi(this.childrenPageMsg).then(res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.childrenTableData = records
          this.childrenPageTotal = total;
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false

      })
    },
    getPage(index, pageNum, pageSize) {
      if (pageNum === 1) {
        return index + 1;
      }
      return (pageNum - 1) * pageSize + index + 1;
    }
  },
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
