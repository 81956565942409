<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
      <p>网站管理/加入我们</p>
      <h1>招聘发布</h1>
    </div>
    <el-card class="box-card">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="公司" prop="companyName">
          <el-input v-model="ruleForm.companyName"></el-input>
        </el-form-item>
        <el-form-item label="职务名称" prop="positionName">
          <el-input v-model="ruleForm.positionName"></el-input>
        </el-form-item>
        <el-form-item label="经验要求" prop="experienceRequire">
          <el-select v-model="ruleForm.experienceRequire" placeholder="请选择">
            <el-option
              v-for="item in WorkExperienceEnum"
              :key="item.id"
              :label="item.v"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历要求" prop="educationRequire">
          <el-select v-model="ruleForm.educationRequire" placeholder="请选择">
            <el-option
              v-for="item in EducationEnum"
              :key="item.id"
              :label="item.v"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="薪酬范围" prop="salaryRange">
          <el-select v-model="ruleForm.salaryRange" placeholder="请选择">
            <el-option
              v-for="item in SalaryEnum"
              :key="item.id"
              :label="item.v"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职务描述" prop="positionDescribe">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.positionDescribe"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="工作地址" prop="cityId">
          <el-select
            v-model="ruleForm.countryId"
            placeholder="请选择国家"
            @change="guoChange"
          >
            <el-option
              v-for="item in guo"
              :key="item.Name"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="ruleForm.provinceId"
            placeholder="请选择省份"
            @change="shengChange"
          >
            <el-option
              v-for="item in sheng"
              :key="item.Name"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="ruleForm.cityId"
            placeholder="请选择城市"
            @change="shiChange"
          >
            <el-option
              v-for="item in shi"
              :key="item.Name"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布人" prop="publisher">
          <el-input placeholder="请填写" v-model="ruleForm.publisher">
          </el-input>
        </el-form-item>
        <el-form-item label="发布人岗位" prop="publisherPosition">
          <el-input placeholder="请填写" v-model="ruleForm.publisherPosition">
          </el-input>
        </el-form-item>
        <el-form-item label="职位链接" prop="positionUrl">
          <el-input v-model="ruleForm.positionUrl"></el-input>
        </el-form-item>
        <el-row>
          <el-button @click="$router.go(-1)">取消</el-button>
          <el-button type="primary" @click="submit('ruleForm')">提交</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { area } from '@/assets/area'
import constant from 'constant'
import {
  positionRecruitAddApi,
  positionRecruitDetailApi,
  positionRecruitEditApi
} from 'api/websit'
export default {
  name: 'websiteMange',
  data() {
    return {
      rules: {
        countryId: [
          { required: true, message: '请选择国家', trigger: 'change' }
        ],
        provinceId: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        cityId: [{ required: true, message: '请选择市', trigger: 'change' }],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        educationRequire: [
          { required: true, message: '请选择学历要求', trigger: 'change' }
        ],
        experienceRequire: [
          { required: true, message: '请选择经验要求', trigger: 'change' }
        ],
        positionDescribe: [
          { required: true, message: '请输入职位描述', trigger: 'blur' }
        ],
        positionName: [
          { required: true, message: '请输入职位名称', trigger: 'blur' }
        ],
        positionUrl: [
          { required: true, message: '请输入职位链接', trigger: 'blur' }
        ],
        publisher: [
          { required: true, message: '请输入职发布者', trigger: 'blur' }
        ],
        publisherPosition: [
          { required: true, message: '请输入职发布者职位', trigger: 'blur' }
        ],
        salaryRange: [
          { required: true, message: '请选择薪资范围', trigger: 'change' }
        ]
      },
      ruleForm: {
        countryId: '', //国家id
        countryName: '', //国家名称
        provinceId: '', //省id
        provinceName: '', //省名称
        cityId: '', //市id
        cityName: '', //市名称
        companyName: '', //公司名称
        educationRequire: '', //学历要求 字典:EducationEnum
        experienceRequire: '', //经验要求 字典:WorkExperienceEnum
        positionDescribe: '', //职位描述
        positionName: '', //职位名称
        positionUrl: '', //职位链接
        publisher: '', //发布者
        publisherPosition: '', //发布者职位
        salaryRange: '' //薪资范围 字典:SalaryEnum
      },
      guo: [], //国家list
      sheng: [], //省份list
      shi: [], //市list
      areaList: [], //城市list
      EducationEnum: [], // 学历要求
      WorkExperienceEnum: [], // 经验要求
      SalaryEnum: [] // 薪资范围
    }
  },
  created() {
    this.areaList = area.Location.CountryRegion
    this.guo = this.areaList
    this.initData()
  },
  methods: {
    initData() {
      this.DICTIONARYMth()
      if (this.$route.query.id) {
        positionRecruitDetailApi(this.$route.query.id).then(res => {
          this.guoChange(res.result.countryId)
          this.ruleForm = { ...res.result }
        })
      }
    },
    DICTIONARYMth() {
      let DICTIONARYS = JSON.parse(localStorage.getItem('DICTIONARY'))
      this.EducationEnum = DICTIONARYS.EducationEnum
      this.WorkExperienceEnum = DICTIONARYS.WorkExperienceEnum
      this.SalaryEnum = DICTIONARYS.SalaryEnum
    },
    guoChange(e) {
      this.areaList.map(res => {
        if (e == res.Code) {
          this.ruleForm.countryName = res.Name
          if (res.State.City) {
            this.sheng = res.State.City
          } else {
            this.sheng = res.State
          }
          this.ruleForm.provinceId = this.sheng[0].Code
          this.shengChange(this.ruleForm.provinceId)
        }
      })
    },
    shengChange(e) {
      this.sheng.map(res => {
        if (e == res.Code) {
          this.ruleForm.provinceName = res.Name
          if (res.City) {
            this.shi = res.City
          } else {
            this.shi = [{ ...res }]
          }
          this.ruleForm.cityId = this.shi[0].Code
          this.ruleForm.cityName = this.shi[0].Name
        }
      })
    },
    shiChange(e) {
      this.shi.map(res => {
        if (res.Code == e) {
          this.ruleForm.cityName = res.Name
        }
      })
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.$route.query.id) {
            positionRecruitEditApi({
              ...this.ruleForm
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('新闻编辑成功！')
                this.$router.push('/websiteMange/join')
              }
            })
          } else {
            positionRecruitAddApi({
              ...this.ruleForm
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('新闻新增成功！')
                this.$router.push('/websiteMange/join')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
