<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
      :model='pageMsg'>

        <el-row :gutter5='10'>
          <el-col :span='12' align='left'>
            <el-form-item label="" prop="dateTimeRadio">
              <el-radio-group v-model="pageMsg.dateTimeRadio" @input='radioInputFun'>
                <el-radio :label='0'>当天</el-radio>
                <el-radio :label="1">本周</el-radio>
                <el-radio :label="2">当月</el-radio>
                <el-radio :label="3">本年</el-radio>
                <el-radio :label='4'>区间</el-radio>
<!--                <el-radio :label='5'>不限</el-radio>-->
              </el-radio-group>
            </el-form-item>
          </el-col>



          <!--          <el-col :span='7'>-->
<!--            <el-form-item label="类型:" prop="validType">-->
<!--              <el-radio-group v-model="pageMsg.validType">-->
<!--                <el-radio :label=0>有效</el-radio>-->
<!--                <el-radio :label=1>无效</el-radio>-->
<!--              </el-radio-group>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span='12' :push='1'>
            <el-form-item label="股票类型:" prop="stockType">
              <el-radio-group v-model="pageMsg.stockType">
                <el-radio label="A股">A股</el-radio>
                <el-radio label="港股">港股</el-radio>
                <el-radio label="新三板">新三板</el-radio>
<!--                <el-radio label="中概股">中概股</el-radio>-->
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span='10' v-if='sectionShow'  :push='1'>
            <el-form-item label='日期' prop='dateTimeSection' >
              <el-date-picker
                v-model="pageMsg.dateTimeSection"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
<!--          <el-col :span='10' v-if='sectionShow'>-->
<!--            <el-form-item label='日期' prop='dateTimeSection' >-->
<!--              <el-date-picker-->
<!--                v-model="pageMsg.dateTimeSection"-->
<!--                type="daterange"-->
<!--                range-separator="至"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->


        </el-row>


        <el-row>
          <el-col :span='8' >
            <el-form-item label="收录来源:" prop="sourceType">
              <el-radio-group v-model="pageMsg.sourceType">
                <el-radio :label="0">网页端</el-radio>
                <el-radio :label="1">微信端</el-radio>
                <el-radio :label='2'>全部</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>


<!--          <el-col :span='6'>-->
<!--            <el-form-item label='行业:'>-->
<!--              <el-cascader-->
<!--                style="width: 185px"-->
<!--                :options="industryOptions"-->
<!--                @change="industryHandleChange"-->
<!--                v-model="pageMsg.industryCategoryArr"-->
<!--                :props="{ checkStrictly: 'true' }"-->
<!--              ></el-cascader>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span='6'>-->
<!--            <el-form-item label="证监局:">-->
<!--              <el-select v-model="pageMsg.secBureau" placeholder="请选择">-->
<!--                <el-option-->
<!--                  v-for="item in secBureauOptionsSelect"-->
<!--                  :key="item"-->
<!--                  :label="item"-->
<!--                  :value="item">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span='8' :push='2'>
            <el-form-item>
              <el-button type='primary' size="small" @click='searchClick'>统计</el-button>
              <el-button type='primary' size="small" @click='resetClick'>重置</el-button>
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>
<!--      <div style='margin-top: 10px;margin-bottom: 10px' >-->
        <el-divider></el-divider>
<!--      </div>-->
<!--      图表-->
      <el-row>
        <el-col :span='11'>
          <div v-show='mainShow'>
            <div id="main" style="margin:0px auto;width: 500px; height: 500px;"></div>
            <p style='text-align: left'>
              <span>{{ dateTime }}</span>,
              <span>{{ mainStr }}</span>
            </p>
          </div>
        </el-col>

        <el-col :span='11' :push='2'>
          <div v-show='industryShow'>
            <div id="industry" style="width: 500px; height: 500px;"></div>
            <p style='text-align: left'>
              <span>{{ dateTime }}</span>,分发排名前十行业分别为:
              <span>{{ industryArrStr }}</span>
            </p>
          </div>
        </el-col>
      </el-row>

      <div style='margin-top: 30px'>

      </div>

      <el-row>
        <el-col :span='11'>
          <div v-show='secBureauShow'>
            <div id='secBureau'style="width: 500px; height: 500px;"></div>
            <p style='text-align: left'>
              <span>{{ dateTime }}</span>,分发排名前十辖区分别为:
              <span>{{ secBureauArrStr }}</span>
            </p>
          </div>
        </el-col>
        <el-col :span='11' :push='2'>
          <div v-show='stockNameShow'>
            <div id='basicStock' style="width: 500px; height: 500px;"></div>
            <p style='text-align: left'>
              <span>{{ dateTime }}</span>,分发排名前十公司分别为:
              <span>{{ stockNameArrStr }}</span>
            </p>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import dayjs from 'dayjs';

import {
  getIndustryRankingAllInfoApi,
  getSecBureauAllApi,
  getSecBureauRankingAllInfoApi,
  RenderingChartsAPi,
  getStockRankingAllInfoApi
} from 'api/article'
import { industryCategoryTreeApi } from 'api/common'

export default {
  data() {
    return{
      dateTime:null,
      mainShow: false,
      industryShow:false,
      secBureauShow:false,
      stockNameShow:false,
      mainStr:"",
      industryArrStr:"",
      secBureauArrStr:"",
      stockNameArrStr:"",
      dateStr:"当天",
      secBureauOptionsSelect:[],
      secBureauOptions:[],
      industryOptions:[],
      stockOptions:[],
      sectionShow:false,
      pageMsg:{
        industryCode:null,
        industryCategoryArr:[],
        secBureau:null,
        dateTimeRadio:0,
        dateTimeSection:[],
        validType:0,
        stockType:"A股",
        sourceType:2
      },
      chartData:[],
      echartsName:""
    }
  },

  created() {
  },
  mounted() {
    this.initData();
    this.upDateStr();
    this.initCategoryCode();
    this.secBureauData();
    this.industryRankingInfoEcharts();
    this.secBureauRankingInfoAsEcharts();
    this.stockRankingInfoEcharts();
    this.getDateTime();
  },
  methods:{
    upDateStr(){
      switch (this.pageMsg.dateTimeRadio){
        case 0:
          this.dateStr = "当天";
          break;
        case 1:
          this.dateStr = "本周";
          break;
        case 2:
          this.dateStr = "本月";
          break;
        case 3:
          this.dateStr = "本年";
          break;
        case 4:
          this.dateStr = "区间";
          break;
      }
    },
    stockRankingInfoEcharts(){
      getStockRankingAllInfoApi(this.pageMsg).then(res => {
        if (res.code === 200){
          this.stockOptions = res.result;
          const stockNameArr = this.stockOptions.map(item => item.stockName);
          stockNameArr.forEach(item => {
            this.stockNameArrStr+=item+",";
          })

          let index = this.stockNameArrStr.lastIndexOf(',')

          if(index !== -1){
            this.stockNameArrStr = this.stockNameArrStr.substring(0,index)
          }

          const validSumArr = this.stockOptions.map(item => item.validSum)
          var stockOptions = {
            title:{
              text:this.dateStr+'分发排名前十公司'
            },
            tooltip: {
            },
            xAxis: {
              type: 'category',
              data: stockNameArr,
              axisLabel: {
                fontSize: 12,
                interval: 0,
                rotate: 45
              }
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [
                  {
                    value: validSumArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: validSumArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: validSumArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: validSumArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: validSumArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: validSumArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: validSumArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: validSumArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: validSumArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: validSumArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  },
                ],
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('basicStock');
          var myChart = echarts.init(chartDom);
          myChart.setOption(stockOptions);
          this.stockNameShow = true;
        }
      })
    },
    industryRankingInfoEcharts: function() {
      // if (this.pageMsg.industryCategoryArr!=null&&this.pageMsg.industryCategoryArr.length>0){
      // this.pageMsg.industryCode =this.pageMsg.industryCategoryArr[this.pageMsg.industryCategoryArr.length-1]
      getIndustryRankingAllInfoApi(this.pageMsg).then(res => {
        // getIndustryRaningInfoApi(this.pageMsg).then(res=>{
        if (res.code === 200) {
          this.industryOption = res.result
          const industryArr = this.industryOption.map(item => item.industry)
          console.log("industryArr"+JSON.stringify(industryArr))
          industryArr.forEach(item => {
            this.industryArrStr += item + ','
          })

          let index = this.industryArrStr.lastIndexOf(',')

          if(index !== -1){
            this.industryArrStr = this.industryArrStr.substring(0,index)
          }

          const validSumArr = this.industryOption.map(item => item.validSum)
          var industryOption = {
            title: {
              text: this.dateStr + '分发排名前十行业'
            },
            tooltip: {},
            xAxis: {
              type: 'category',
              data: industryArr,
              axisLabel: {
                fontSize: 12,
                interval: 0,
                rotate: 45
              }
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [
                  {
                    value: validSumArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: validSumArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: validSumArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: validSumArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: validSumArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: validSumArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: validSumArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: validSumArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: validSumArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: validSumArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  }
                ],
                type: 'bar'
              }
            ]
          }
          var chartDom = document.getElementById('industry')
          var myChart = echarts.init(chartDom)
          myChart.setOption(industryOption)
          this.industryShow = true
        }
      })
      // }
    },
    secBureauRankingInfoAsEcharts(){
      // if (this.pageMsg.secBureau!=null){
      getSecBureauRankingAllInfoApi(this.pageMsg).then(res => {
        // getSecBureauRaningInfoApi(this.pageMsg).then(res => {
        if (res.code === 200){
          this.secBureauOptions = res.result;
          const secBureauArr = this.secBureauOptions.map(item => item.secBureau);
          console.log("secBureauArr"+JSON.stringify(secBureauArr))
          secBureauArr.forEach(item=>{
            this.secBureauArrStr+=item+",";
          })

          let index = this.secBureauArrStr.lastIndexOf(',')

          if(index !== -1){
            this.secBureauArrStr = this.secBureauArrStr.substring(0,index)
          }

          const validSumArr = this.secBureauOptions.map(item => item.validSum)
          var secBureauOptions = {
            title:{
              text:this.dateStr+'分发排名前十辖区'
            },
            tooltip: {
            },
            xAxis: {
              type: 'category',
              data: secBureauArr,
              axisLabel: {
                fontSize: 12,
                interval: 0,
                rotate: 45
              }
            },
            axisLabel: {
              fontSize: 12,
              interval: 0,
              rotate: 45
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [
                  {
                    value: validSumArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: validSumArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: validSumArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: validSumArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: validSumArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: validSumArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: validSumArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: validSumArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: validSumArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: validSumArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  },
                ],
                type: 'bar'
              }
            ]
          };
          var chartDom = document.getElementById('secBureau');
          var myChart = echarts.init(chartDom);
          myChart.setOption(secBureauOptions);
          this.secBureauShow = true;
        }
        })
      // }
    },
    secBureauData(){
      getSecBureauAllApi().then(res=>{
        this.secBureauOptionsSelect = res.result;
      })
    },
    init(){
      this.pageMsg.dateTimeRadio=0;
      this.pageMsg.validType = 0
      this.pageMsg.stockType = "A股"
      this.pageMsg.sourceType = 0
    },
    industryHandleChange(val){
      this.pageMsg.industryCategoryCode = val[val.length - 1]
    },
    refreshChart(){
      this.chart.setOption({
        title: {
          text: this.chartData.seriesName,
          // subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            center: ['50%', '40%'],    //显示位置
            name:'',
            type:'pie',
            radius:['0%','60%'],/*空心圆的占比*/
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                borderWidth: 5,/*隔开的白色边界*/
                borderColor: '#fff',/*border*/
                // labelLine :{show:true}
              },
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show:true,
                position:'inner',
                textStyle: {
                  fontWeight: 200,
                  fontSize: 20   //文字的字体大小
                },
              //   formatter: '{d}%',/*饼状图内显示百分比*/
              //   // data:['40%','60%'],
              // },
                formatter: (params) => {
                  const {name,value,percent=0} = params;
                  // const text = name + ":" + value + "(" + percent + ")" + "%";
                  const text = percent+"%";
                  return text;
                }
              },

              emphasis: {
                show: false,/*空心文字出现*/
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
                data: [
                  { value: this.chartData.invalidTotal, name: '无效条数' },
                  { value: this.chartData.validTotal, name: '有效条数' }
                ],
          }
        ]
      })

      this.mainShow = true;
      const totalAll = this.chartData.invalidTotal + this.chartData.validTotal;

      const validRate = totalAll !== 0 ? (this.chartData.validTotal / totalAll * 100).toFixed(2) : '0.00';
      const invalidRate = totalAll !== 0 ? (this.chartData.invalidTotal / totalAll * 100).toFixed(2) : '0.00';
      if (this.chartData.seriesName.includes("区间")){
        this.mainStr = this.chartData.seriesName.substring(3)+"共计"+totalAll+"条,有效率:"+validRate+"%,有效条数:"+this.chartData.validTotal+"条,无效率:"+invalidRate+"%,无效条数:"+this.chartData.invalidTotal+"条";
      }else {
        this.mainStr = this.chartData.seriesName.substring(2)+"共计"+totalAll+"条,有效率:"+validRate+"%,有效条数:"+this.chartData.validTotal+"条,无效率:"+invalidRate+"%,无效条数:"+this.chartData.invalidTotal+"条";
      }

    },
    searchClick(){
      //清空
      this.secBureauArrStr = "";
      this.stockNameArrStr = "";
      this.industryArrStr = "";


      // this.secBureauShow = false;
      // this.industryShow = false;
      // this.stockNameShow = false;
      this.upDateStr();
      this.initData();
      this.industryRankingInfoEcharts();
      this.secBureauRankingInfoAsEcharts();
      this.stockRankingInfoEcharts();
      this.getDateTime();
    },
    getDateTime(){
      switch (this.pageMsg.dateTimeRadio){
        case 0:
          //当天
          const today = dayjs().format('YYYY年MM月DD日');
          this.dateTime = today;
          break;
        case 1:
          //本周
          const startOfWeek = dayjs().startOf('week').format('YYYY年MM月DD日');
          const endOfWeek = dayjs().endOf('week').format('YYYY年MM月DD日');
          this.dateTime = `${startOfWeek}-${endOfWeek}`;
          break;
        case 2:
          const startOfMonth = dayjs().startOf('month').format('YYYY年MM月DD日');
          const endOfMonth = dayjs().endOf('month').format('YYYY年MM月DD日');
          this.dateTime = `${startOfMonth}-${endOfMonth}`;
          break;
        case 3:
          const startOfYear = dayjs().startOf('year').format('YYYY年MM月DD日');
          const endOfYear = dayjs().endOf('year').format('YYYY年MM月DD日');
          this.dateTime = `${startOfYear}-${endOfYear}`;
          break;
        case 4:
          const startDate = dayjs(this.pageMsg.dateTimeSection[0]).format('YYYY年MM月DD日');
          const endDate = dayjs(this.pageMsg.dateTimeSection[1]).format('YYYY年MM月DD日');
          this.dateTime = `${startDate}-${endDate}`;
          break;
      }
    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.industryOptions = result
      })
    },
    //初始化
    initData(){
      if (this.pageMsg.dateTimeRadio===4){
        if (this.pageMsg.dateTimeSection==null||this.pageMsg.dateTimeSection==""){
          this.$message.error("请选择区间!")
          return;
        }else {
          RenderingChartsAPi(this.pageMsg).then( res => {

            this.chartData = res.result;
            this.chart = echarts.init(document.getElementById('main'));
            if (this.chartData.invalidTotal==0||this.chartData.invalidTotal=='undefined'){
              this.chartData.invalidTotal = 0;
            }
            if (this.chartData.validTotal==0||this.chartData.validTotal=='undefined'){
              this.chartData.validTotal = 0;
            }
            this.refreshChart();
          })
        }
      }else {
        RenderingChartsAPi(this.pageMsg).then( res => {
          this.chartData = res.result;
          this.chart = echarts.init(document.getElementById('main'));
          if(typeof this.chartData.invalidTotal != 'number'){
            this.chartData.invalidTotal = 0;
          }
          if(typeof this.chartData.validTotal != 'number'){
            this.chartData.validTotal = 0;
          }

          if (this.chartData.invalidTotal==0||this.chartData.invalidTotal=='undefined'||this.chartData.invalidTotal==null){
            this.chartData.invalidTotal = 0;
          }
          if (this.chartData.validTotal==0||this.chartData.validTotal=='undefined'||this.chartData.validTotal==null){
            this.chartData.validTotal = 0;
          }
          this.refreshChart();
        })
      }
    },
    radioInputFun(){
      if (this.pageMsg.dateTimeRadio === 4){
        this.sectionShow = true;
      }else {
        this.sectionShow = false;
      }
    },
    resetClick(){
      this.secBureauArrStr = "";
      this.stockNameArrStr = "";
      this.industryArrStr = "";
      this.pageMsg = this.$options.data().pageMsg;
      this.sectionShow = false;
      this.upDateStr();
      this.initData();
      this.industryRankingInfoEcharts();
      this.secBureauRankingInfoAsEcharts();
      this.stockRankingInfoEcharts();
      this.getDateTime()
    },
  }


}



</script>



<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
