var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _vm._m(0),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "text-left" },
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.pageMsg,
                    size: "small",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "limitinputWidth",
                          attrs: { label: "账户:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "auto" },
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.pageMsg.channelName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "channelName", $$v)
                              },
                              expression: "pageMsg.channelName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c("p", { staticStyle: { opacity: "0" } }),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "limitinputWidth",
                          attrs: { label: "企业:" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "auto" },
                              attrs: {
                                filterable: "",
                                placeholder: "请填写公司名称或者股票代码",
                              },
                              model: {
                                value: _vm.pageMsg.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "companyId", $$v)
                                },
                                expression: "pageMsg.companyId",
                              },
                            },
                            _vm._l(_vm.companyOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.companyName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 7 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectionArr.length > 0
            ? _c(
                "div",
                { staticClass: "select_warn" },
                [
                  _vm._v(" 已经选择 "),
                  _c("span", [_vm._v(_vm._s(_vm.selectionArr.length))]),
                  _vm._v(" 项 "),
                  _vm.userbtn.indexOf("RELEASE_REVIEW_MOREPASS") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.passItemAll(true)
                            },
                          },
                        },
                        [_vm._v("通过")]
                      )
                    : _vm._e(),
                  _vm.userbtn.indexOf("RELEASE_REVIEW_MOREFAIL") >= 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.passItemAll(false)
                            },
                          },
                        },
                        [_vm._v("驳回")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "articleTitle",
                  label: "动态内容",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.turnDetail(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.articleTitle) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "distributeUsername",
                  label: "分发账户",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "companyName",
                  label: "关联企业",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "author",
                  label: "创建人",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createdTime",
                  label: "提交时间",
                  sortable: "",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(row.createdTime)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "state",
                  label: "状态",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.authState == 10
                          ? _c(
                              "p",
                              {
                                staticClass: "state-table",
                                staticStyle: { color: "#409eff" },
                              },
                              [
                                _c("i", {
                                  staticStyle: { background: "#409eff" },
                                }),
                                _vm._v(" 审核中 "),
                              ]
                            )
                          : _vm._e(),
                        row.authState == 20
                          ? _c(
                              "p",
                              {
                                staticClass: "state-table",
                                staticStyle: { color: "#30b08f" },
                              },
                              [
                                _c("i", {
                                  staticStyle: { background: "#30b08f" },
                                }),
                                _vm._v("成功 "),
                              ]
                            )
                          : _vm._e(),
                        row.authState == 30
                          ? _c(
                              "p",
                              {
                                staticClass: "state-table",
                                staticStyle: { color: "red" },
                              },
                              [
                                _c("i", { staticStyle: { background: "red" } }),
                                _vm._v("失败(" + _vm._s(row.failReason) + ") "),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "180",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.authState == 10 &&
                        _vm.userbtn.indexOf("RELEASE_REVIEW_PASS") >= 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.passItemTrue(row)
                                  },
                                },
                              },
                              [_vm._v(" 通过 ")]
                            )
                          : _vm._e(),
                        row.authState == 10 &&
                        _vm.userbtn.indexOf("RELEASE_REVIEW_FAIL") >= 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.passItemFalse(row)
                                  },
                                },
                              },
                              [_vm._v(" 驳回 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detailBool,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailBool = $event
            },
          },
        },
        [_c("releaseDetail", { attrs: { rowID: _vm.rowID } })],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            title: "驳回原因",
            visible: _vm.passDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formName",
              attrs: { rules: _vm.rules, model: _vm.ruleModel },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "failReason" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "auto" },
                      attrs: { placeholder: "请选择驳回原因" },
                      model: {
                        value: _vm.ruleModel.failReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleModel, "failReason", $$v)
                        },
                        expression: "ruleModel.failReason",
                      },
                    },
                    _vm._l(_vm.basicWordCode, function (item) {
                      return _c("el-option", {
                        key: item.wordName,
                        attrs: { label: item.wordName, value: item.wordName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-bottom": "30px" } }),
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addAndEditBanner("formName")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channelManagement-head" }, [
      _c("h1", [_vm._v("动态列表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }