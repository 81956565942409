<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model='pageMsg'
        size="small"
        label-position="center"
        label-width="100px">
        <el-row>

<!--          <el-col :span='8'>-->
<!--            <el-form-item label="类型:">-->
<!--              <el-select v-model="pageMsg.type" placeholder="请选择行业">-->
<!--                <el-option-->
<!--                  v-for="item in typeOptions"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span='10'>
            <el-form-item label="时间:">
              <el-date-picker
                v-model="pageMsg.timeDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
          <el-form-item label='类型:'>
            <el-radio-group v-model="pageMsg.weightType">
              <el-radio :label="0">等权</el-radio>
              <el-radio :label="1">加权</el-radio>
            </el-radio-group>
          </el-form-item>
          </el-col>

          <el-col :span='6'>
            <el-button type='primary'@click='initData'>查询</el-button>
            <el-button @click='resetCli'>重置</el-button>
          </el-col>

        </el-row>

        <el-row>

<!--          <el-col :span='8'>-->
<!--            <el-form-item label="行业:">-->
<!--              <el-select v-model="pageMsg.industryCode" placeholder="请选择行业">-->
<!--                <el-option-->
<!--                  v-for="item in industryOptions"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->



<!--          <el-col :span='10'>-->
<!--            <el-form-item label="IPR排名:">-->
<!--              <el-slider-->
<!--                v-model="pageMsg.stockNumValue"-->
<!--                show-input>-->
<!--              </el-slider>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span='6'>-->
<!--            <el-button type='primary'@click='initData'>查询</el-button>-->
<!--            <el-button @click='resetCli'>重置</el-button>-->
<!--          </el-col>-->

        </el-row>
<!--        <div style='margin-top: 10px;width: 1000px'>-->
<!--          <p style='font-size: 15px'>-->
<!--            <strong>IPR排名前{{stockNum}}的公司为:</strong>-->
<!--            {{ textStr }}</p>-->
<!--        </div>-->

      </el-form>

      <el-divider></el-divider>

<!--      Echats图表部分-->
      <div id="main" style="width: 1000px; height: 500px;"></div>


    </el-card>
  </div>
</template>

<script>
import {
  getStockDataMarketAllApi,
  getStockDataMarketByIPR100Api,
  getStockDataMarketByIPR300Api,
  getStockDataMarketByIPR500Api,
  getStockDataMarketByZZ1000Api,
  getStockDataMarketByZZ500Api,
  getStockDataMarketHSApi,
  getStockDataMarketSHApi
} from 'api/data'
import { industryCategoryTreeApi } from 'api/common'

import * as echarts from 'echarts';
export default {
  data() {
    return {
      typeName:null,
      typeOptions:[
        // {
        //   label:"全A指数",
        //   value:0
        // },
        {
          label:"上证指数",
          value:1
        },
        {
          label:"沪深300",
          value:2
        },
        {
          label:"行业对比",
          value:3
        },
        {
          label:"中证500",
          value:4
        },
        {
          label:"中证1000",
          value:5
        },
        {
          label:"BA100",
          value:6
        }
        ,
        {
          label:"BA300",
          value:7
        }
        ,
        {
          label:"BA500",
          value:8
        }
      ],
      pageMsg:{
        weightType:0,
        timeDate:null,
        startTime:null,
        endTime:null,
        type:1,
        stockNumValue:20,
        industryCode:"全部"
      },
      textStr:null,
      stockNum:null,
      industryOptions:[],
      stockNumValue:20,
      industryCode:null,
      industryName:null,
      stockNumOptions:[
        {
          value: 5,
          label: '前5'
        },
        {
          value: 10,
          label: '前10'
        },
        {
          value: 20,
          label: '前20'
        },
      ]
    }
  },
  created() {
    // this.getIndustry();
    // this.initData();
  },
  mounted() {
    this.getIndustry();
    this.initData();
  },
  methods: {
    getIndustry(){
      industryCategoryTreeApi().then(({ result }) => {
        const data = result;
        const options = [];

        const val = {
          label : "全部",
          value : "全部"
        }

        options.push(val)

        data.forEach(item => {
          const val = {
            label : item.name,
            value : item.code
          }
          options.push(val);
        })

        this.industryOptions = options;
      })
    },
    resetCli(){
      this.pageMsg.stockNumValue = 20;
      this.pageMsg.type = 0;
      this.pageMsg.industryCode = "全部";
      this.pageMsg.startTime = null;
      this.pageMsg.endTime = null;
      this.pageMsg.timeDate = null;
      this.initData();
    },
    initData(){
        this.stockNumValue = this.pageMsg.stockNumValue;
        if ("全部"=== this.pageMsg.industryCode && this.pageMsg.type === 3){
          this.$message.error("请勿选择全部行业对比!")
          return;
        }
        if (this.pageMsg.timeDate && this.pageMsg.timeDate.length === 2) {
          const startDate = this.pageMsg.timeDate[0];
          const endDate = this.pageMsg.timeDate[1];
          this.pageMsg.startTime = this.formatDate(startDate, true);
          this.pageMsg.endTime = this.formatDate(endDate, false)
        }
      let apiPromises = [];
      apiPromises.push(getStockDataMarketAllApi(this.pageMsg));
      apiPromises.push(getStockDataMarketSHApi(this.pageMsg));
      apiPromises.push(getStockDataMarketHSApi(this.pageMsg));
      apiPromises.push(getStockDataMarketByZZ500Api(this.pageMsg));
      apiPromises.push(getStockDataMarketByZZ1000Api(this.pageMsg));
      apiPromises.push(getStockDataMarketByIPR100Api(this.pageMsg));
      apiPromises.push(getStockDataMarketByIPR300Api(this.pageMsg));
      apiPromises.push(getStockDataMarketByIPR500Api(this.pageMsg));
        Promise.all(apiPromises).then(values => {
          const quanAData = values[0].result;
          const shangZhengData = values[1].result;
          const huShen300Data = values[2].result;
          const zhongZheng500Data = values[3].result;
          const zhongZheng1000Data = values[4].result;
          const ipr100Data = values[5].result;
          const ipr300Data = values[6].result;
          const ipr500Data = values[7].result;
          this.drawEchats(quanAData,shangZhengData,huShen300Data,zhongZheng500Data,zhongZheng1000Data,ipr100Data,ipr300Data,ipr500Data);
        });
    },
    drawEchats(quanAData,shangZhengData,huShen300Data,zhongZheng500Data,zhongZheng1000Data,ipr100Data,ipr300Data,ipr500Data){

        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;

      // var chartDom = document.getElementById('main');
      // var myChart = echarts.init(chartDom);
      // var option;
      var timeData = ipr100Data.map(item => item.tradeDate.split(' ')[0]);

      var quanADataChange = [];
      var shangZhengDataChange = [];
      var huShen300DataChange = [];
      var zhongZheng500DataChange = [];
      var zhongZheng1000DataChange = [];
      var ipr100DataChange = [];
      var ipr300DataChange = [];
      var ipr500DataChange = [];

      for (let i = 0; i < quanAData.length; i++) {
        if (i === 0) {
          quanADataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((quanAData[i].closePrice - quanAData[0].closePrice) / quanAData[0].closePrice * 100).toFixed(2);
          quanADataChange.push(change);
        }
      }

      for (let i = 0; i < shangZhengData.length; i++) {
        if (i === 0) {
          shangZhengDataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((shangZhengData[i].closePrice - shangZhengData[0].closePrice) / shangZhengData[0].closePrice * 100).toFixed(2);
          shangZhengDataChange.push(change);
        }
      }

      for (let i = 0; i < huShen300Data.length; i++) {
        if (i === 0) {
          huShen300DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((huShen300Data[i].closePrice - huShen300Data[0].closePrice) / huShen300Data[0].closePrice * 100).toFixed(2);
          huShen300DataChange.push(change);
        }
      }

      for (let i = 0; i < zhongZheng500Data.length; i++) {
        if (i === 0) {
          zhongZheng500DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((zhongZheng500Data[i].closePrice - zhongZheng500Data[0].closePrice) / zhongZheng500Data[0].closePrice * 100).toFixed(2);
          zhongZheng500DataChange.push(change);
        }
      }

      for (let i = 0; i < zhongZheng1000Data.length; i++) {
        if (i === 0) {
          zhongZheng1000DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((zhongZheng1000Data[i].closePrice - zhongZheng1000Data[0].closePrice) / zhongZheng1000Data[0].closePrice * 100).toFixed(2);
          zhongZheng1000DataChange.push(change);
        }
      }

      for (let i = 0; i < ipr100Data.length; i++) {
        if (i === 0) {
          ipr100DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((ipr100Data[i].closePrice - ipr100Data[0].closePrice) / ipr100Data[0].closePrice * 100).toFixed(2);
          ipr100DataChange.push(change);
        }
      }

      for (let i = 0; i < ipr300Data.length; i++) {
        if (i === 0) {
          ipr300DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((ipr300Data[i].closePrice - ipr300Data[0].closePrice) / ipr300Data[0].closePrice * 100).toFixed(2);
          ipr300DataChange.push(change);
        }
      }

      for (let i = 0; i < ipr500Data.length; i++) {
        if (i === 0) {
          ipr500DataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((ipr500Data[i].closePrice - ipr500Data[0].closePrice) / ipr500Data[0].closePrice   * 100).toFixed(2);
          ipr500DataChange.push(change);
        }
      }
      // 准备图表的配置选项
      var option = {
        // 图表标题
        title: {
          text: '指数涨跌幅对比'
        },
        // 提示框组件
        tooltip: {
          trigger: 'axis',
          // 使用自定义的提示框格式化
          // formatter: function(params) {
          //   var result = params[0].axisValue + '<br/>';
          //   params.forEach(function(item) {
          //     var index = item.seriesIndex;
          //     result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + '"></span>'; // 添加小圆点
          //     result += item.seriesName + '：' + item.data + '%';
          //     var closePrice = parseFloat([quanAData, shangZhengData, huShen300Data, zhongZheng500Data, zhongZheng1000Data, ipr100Data, ipr300Data, ipr500Data][index][item.dataIndex].closePrice); // 将字符串转换为数字
          //     result += '，当前点位：' + closePrice.toFixed(2); // 保留两位小数
          //     result += '<br/>';
          //   });
          //   return result;
          // }
          formatter: function(params) {
            var result = params[0].axisValue + '<br/>';
            params.forEach(function(item) {
              var index = item.seriesIndex;
              result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + '"></span>'; // 添加小圆点
              var closePrice = parseFloat([quanAData, shangZhengData, huShen300Data, zhongZheng500Data, zhongZheng1000Data, ipr100Data, ipr300Data, ipr500Data][index][item.dataIndex].closePrice); // 将字符串转换为数字
              result += item.seriesName + '：' + closePrice.toFixed(2) + '  ,   ' + item.data +'%';
              result += '<br/>';
            });
            return result;
          }
        },
        // 图例组件
        legend: {
          data: ['全A', '上证', '沪深300', '中证500', '中证1000', 'BA100', 'BA300', 'BA500']
        },
        // X轴配置
        xAxis: {
          type: 'category',
          data: timeData // 使用时间数据
        },
        // Y轴配置
        yAxis: {
          type: 'value'
        },
        // 数据系列
        series: [
          {
            name: '全A',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: quanADataChange
          },
          {
            name: '上证',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: shangZhengDataChange
          },
          {
            name: '沪深300',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: huShen300DataChange
          },
          {
            name: '中证500',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: zhongZheng500DataChange
          },
          {
            name: '中证1000',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: zhongZheng1000DataChange
          },
          {
            name: 'BA100',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: ipr100DataChange
          },
          {
            name: 'BA300',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: ipr300DataChange
          },
          {
            name: 'BA500',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: ipr500DataChange
          },
        ],
      };
      myChart.setOption(option);
    },

    // initData() {
    //   this.stockNumValue = this.pageMsg.stockNumValue;
    //   if ("全部"=== this.pageMsg.industryCode && this.pageMsg.type === 3){
    //     this.$message.error("请勿选择全部行业对比!")
    //     return;
    //   }
    //
    //
    //   if (this.pageMsg.timeDate && this.pageMsg.timeDate.length === 2) {
    //     const startDate = this.pageMsg.timeDate[0];
    //     const endDate = this.pageMsg.timeDate[1];
    //     this.pageMsg.startTime = this.formatDate(startDate, true);
    //     this.pageMsg.endTime = this.formatDate(endDate, false)
    //   }
    //
    //   this.stockNum = this.pageMsg.stockNumValue;
    //
    //   getStockNameByStockNumApi(this.pageMsg).then(res =>{
    //     if (res.code === 200){
    //       const stockList = res.result;
    //       // this.textStr = `IPR排名前${this.stockNumValue}的公司为:`;
    //       this.textStr = "";
    //       stockList.forEach(item => {
    //         this.textStr += `${item},`;
    //       })
    //       //对textStr最后一位接去掉,
    //       this.textStr = this.textStr.substring(0,this.textStr.length-1);
    //     }
    //   })
    //   let apiPromises = [];
    //   apiPromises.push(getStockDataMarketApi(this.pageMsg));
    //   var typeName = null;
    //
    //   if (0===this.pageMsg.type){
    //     apiPromises.push(getStockDataMarketAllApi(this.pageMsg));
    //     typeName = "全A指数";
    //   }else if (1===this.pageMsg.type){
    //     apiPromises.push(getStockDataMarketSHApi(this.pageMsg));
    //     typeName = "上证指数";
    //   }else if (2===this.pageMsg.type){
    //     apiPromises.push(getStockDataMarketHSApi(this.pageMsg));
    //     typeName = "沪深300指数";
    //   }else if(3 === this.pageMsg.type){
    //     apiPromises.push(getIndustryDataMarketApi(this.pageMsg));
    //     // typeName = "行业对比";
    //     let label = "";
    //     this.industryOptions.forEach(item => {
    //       if (item.value === this.pageMsg.industryCode){
    //         label = item.label;
    //       }
    //     })
    //     this.industryName = label;
    //     typeName = label+"("+this.pageMsg.industryCode+")";
    //   }else if (4===this.pageMsg.type){
    //     //中证500
    //     apiPromises.push(getStockDataMarketByZZ500Api(this.pageMsg));
    //     typeName = "中证500指数";
    //   }else if (5===this.pageMsg.type){
    //     //中证1000
    //     apiPromises.push(getStockDataMarketByZZ1000Api(this.pageMsg));
    //     typeName = "中证1000指数";
    //   }else if (6 === this.pageMsg.type){
    //     //IPR100
    //     apiPromises.push(getStockDataMarketByIPR100Api(this.pageMsg));
    //     typeName = "IPR100指数";
    //   }else if (7 === this.pageMsg.type){
    //     //IPR300
    //     apiPromises.push(getStockDataMarketByIPR300Api(this.pageMsg));
    //     typeName = "IPR300指数";
    //   }else if (8 === this.pageMsg.type){
    //     //IPR500
    //     apiPromises.push(getStockDataMarketByIPR500Api(this.pageMsg));
    //     typeName = "IPR500指数";
    //   }
    //   this.typeName = typeName;
    //   Promise.all(apiPromises).then(values => {
    //     const stockMarketData = values[0].result;
    //     const dataMarketData = values[1].result;
    //
    //     console.log("stockMarketData",JSON.stringify(stockMarketData));
    //
    //     if (values[0].code === 200 && values[1].code === 200) {
    //       this.drawEchats(stockMarketData,dataMarketData);
    //     }
    //   });
    // },
    // drawEchats(stockData,data) {
    //   let industryName = '';
    //   if (this.industryName!=null){
    //     industryName = this.industryName;
    //   }
    //
    //   var chartDom = document.getElementById('main');
    //   var myChart = echarts.init(chartDom);
    //   var option;
    //
    //   // 处理股票市场数据
    //   var timeData = stockData.map(item => item.tradeDate.split(' ')[0]);
    //   var incomeData = stockData.map(item => parseFloat(item.totalValue).toFixed(2));
    //   var dataTimeData = data.map(item => item.tradeDate);
    //   var dataClosePriceData = data.map(item => parseFloat(item.closePrice).toFixed(2));
    //   var stockChange = ((incomeData[incomeData.length - 1] - incomeData[0]) / incomeData[0] * 100).toFixed(2);
    //   var dataChange = ((dataClosePriceData[dataClosePriceData.length - 1] - dataClosePriceData[0]) / dataClosePriceData[0] * 100).toFixed(2);
    //
    //
    //   // 计算涨跌量
    //   var stockVolumeChange = (incomeData[incomeData.length - 1] - incomeData[0]).toFixed(2);
    //   var dataVolumeChange = (dataClosePriceData[dataClosePriceData.length - 1] - dataClosePriceData[0]).toFixed(2);
    //   option = {
    //     tooltip: {
    //       trigger: 'axis',
    //       formatter: function (params) {
    //         if (params[1] === undefined){
    //           return "";
    //         }
    //         var dynamicData = params[1].value; // 假设动态数据在第一个系列
    //         var iprData = params[0].value; // 假设IPR数据在第二个系列
    //         var change = ((iprData - dynamicData) / dynamicData * 100).toFixed(2); // 计算涨跌幅度，保留两位小数
    //         return params[0].axisValueLabel + '<br/>' +
    //           params[0].marker + params[0].seriesName +":"+ iprData+ '  相对涨跌: ' + change + '%'+'<br/>' +
    //           params[1].marker + params[1].seriesName + ': ' + dynamicData;
    //       }
    //     },
    //     legend: {
    //       data: [
    //         {
    //           name: `IPR${industryName}指数 (前${this.stockNumValue}名)`,
    //           textStyle: {
    //             color: '#d52b1e' // 设置文本颜色为红色
    //           }
    //         },
    //         {
    //           name: `${this.typeName}`,
    //           textStyle: {
    //             color: '#5c7bd9' // 设置文本颜色为红色
    //           }
    //         },
    //       ]
    //     },
    //     xAxis: {
    //       type: 'category',
    //       boundaryGap: false,
    //       data: timeData,
    //       axisLabel: {
    //         interval: 'auto', // 自动计算间隔
    //       }
    //     },
    //     yAxis: {
    //       type: 'value'
    //     },
    //     series: [
    //       {
    //         name: `IPR${industryName}指数 (前${this.stockNumValue}名)`,
    //         type: 'line',
    //         data: incomeData,
    //         smooth: true,
    //         symbol: 'none', // 为股票市场数据去掉圆圈
    //         lineStyle: {
    //           color: '#d52b1e' // 设置线条颜色为红色
    //         },
    //         itemStyle: {
    //           // 设置鼠标悬停时的数据样式
    //           color: '#d52b1e' // 设置悬停时的数据颜色为红色
    //         }
    //       },
    //       {
    //         name: `${this.typeName}`,
    //         type: 'line',
    //         data: dataClosePriceData,
    //         smooth: true,
    //         symbol: 'none', // 为股票市场数据去掉圆圈
    //         lineStyle: {
    //           color: '#5c7bd9' // 设置线条颜色为红色
    //         },
    //         itemStyle: {
    //           // 设置鼠标悬停时的数据样式
    //           color: '#5c7bd9' // 设置悬停时的数据颜色为红色
    //         }
    //       }
    //     ],
    //     title: {
    //       text: [
    //         `涨跌幅：IPR指数 (前${this.stockNumValue}名) ${stockChange}% | ${this.typeName} ${dataChange}%`,
    //         `涨跌量：IPR指数 (前${this.stockNumValue}名) ${stockVolumeChange} | ${this.typeName} ${dataVolumeChange}`
    //       ].join('\n'), // 使用\n来分行
    //       left: 'center',
    //       top: 'bottom', // 将标题移动到底部
    //       textStyle: {
    //         lineHeight:20,
    //         fontSize: 14,
    //         align: 'center' // 确保文本居中对齐
    //       }
    //     }
    //   };
    //   myChart.setOption(option);
    // },
    // 格式化单个日期
    formatDate(date, isStart) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const time = isStart ? "00:00:00" : "23:59:59";
      return `${year}-${month}-${day} ${time}`;
    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
