<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <div >
        <el-form
          :model='pageMsg'
          size='small'
          label-position="center"
          label-width="100px">

          <el-row>
            <el-col :span='16'>
              <el-form-item label='日期:'>
                <el-radio-group v-model="pageMsg.timeType" @input='dateTypeInput'>
<!--                  <el-radio :label="0">当日</el-radio>-->
                  <el-radio :label="1">本周</el-radio>
                  <el-radio :label="2">本月</el-radio>
                  <el-radio :label="5">本季度</el-radio>
                  <el-radio :label="3">本年</el-radio>
                  <el-radio :label="6">区间</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label="行业:">
                <el-select v-model="pageMsg.industryCode" placeholder="请选择行业">
                  <el-option
                    v-for="item in industryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="14" v-show='dateTimeShow'>
              <el-form-item label="时间:">
                <el-date-picker
                  v-model="pageMsg.timeDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-button type='primary' size='small' @click='initData'>查询</el-button>
              <el-button size='small' @click='resetCli'>重置</el-button>
            </el-col>

          </el-row>
        </el-form>
        <div>


          <div class="pb20"></div>


          <div>
            <el-table
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              :data="tableData"
              v-loading="tableLoading"
            >

              <el-table-column
                prop="rankNum"
                label="排名"
                header-align="center"
                align="center">
              </el-table-column>

              <el-table-column
                prop="stockName"
                label="公司名称"
                header-align="center"
                align="center">
              </el-table-column>

              <el-table-column
                prop="activeRate"
                label="活跃率"
                header-align="center"
                align="center">
              </el-table-column>

              <el-table-column
                prop="desc"
                label="活跃度"
                header-align="center"
                align="center">
              </el-table-column>

            </el-table>
          </div>


          <div class="pb20"></div>
          <el-pagination
            class="text-center"
            v-if="pageTotal > 0"
            @size-change="initData"
            @current-change="initData"
            :current-page.sync="pageMsg.pageNum"
            :page-sizes="[10, 50, 100, 200]"
            :page-size.sync="pageMsg.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { industryCategoryTreeApi } from 'api/common'
import { getStockActiveRankPageByTimeApi } from 'api/article'

export default {
  data() {
    return{
      dateTimeShow:false,
      industryOptions:[],
      tableData:[],
      tableLoading:false,
      pageTotal: 0,
      pageMsg:{
        pageNum: 1,
        pageSize: 10,
        timeType:1
      }
    }
  },
  created() {
    this.initData();
    this.getIndustry();
  },
  methods:{
    initData(){
      this.tableLoading = true

      this.pageMsg.startTime = null;
      this.pageMsg.endTime = null;

      if (this.pageMsg.timeType === 6){
        if (this.pageMsg.timeDate === null || this.pageMsg.timeDate === ""){
          this.$message.error("请选择日期!")
          return;
        }
        this.pageMsg.startTime = this.formatDate(this.pageMsg.timeDate[0])
        this.pageMsg.endTime = this.formatDate(this.pageMsg.timeDate[1])
        this.pageMsg.endTime = this.pageMsg.endTime.replace("00:00:00","23:59:59")
      }

      getStockActiveRankPageByTimeApi(this.pageMsg).then(res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        }else {
          this.tableData = []
          this.pageTotal = 0;
        }
        this.tableLoading = false
      })
    },
    resetCli(){
      this.pageMsg = this.$options.data().pageMsg
      this.initData();
    },
    getIndustry(){
      industryCategoryTreeApi().then(({ result }) => {
        const data = result;
        const options = [];
        data.forEach(item => {
          const val = {
            label : item.name,
            value : item.code
          }
          options.push(val);
        })

        this.industryOptions = options;
      })
    },
    dateTypeInput(){
      this.pageMsg.pageNum = 1;
      if (this.pageMsg.timeType===6){
        this.dateTimeShow = true;
      }else {
        this.dateTimeShow = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
