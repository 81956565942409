<template>
  <div class="channelManagement">
    <router-view />
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model='pageMsg'
        size='small'
        label-position="center"
        label-width="100px">

        <el-row>
          <el-col :span='6'>
            <el-form-item label='公司名称:'>
              <el-input v-model='pageMsg.stockName'></el-input>
            </el-form-item>
          </el-col>

          <el-col :span='12' align='center'>
            <el-button type='primary' @click='initData'>查询</el-button>
            <el-button @click='resetCli'>重置</el-button>
          </el-col>

        </el-row>

      </el-form>

      <el-tabs v-model="authStateName" @tab-click="handleClick">
        <el-tab-pane label="有效" name="20"></el-tab-pane>
        <el-tab-pane label="无效" name="30"></el-tab-pane>
      </el-tabs>


      <div style='margin-top: 35px'>
        <div class="select_warn" v-if="selectionArr.length > 0">
          已经选择
          <span>{{ selectionArr.length }}</span>
          项:
          <el-button
            type='text'
            @click="batchNoPassCli">
            批量不准确
          </el-button>
          <el-button
            type='text'
            @click="batchPassCli">
            批量准确
          </el-button>
        </div>
        <el-table
          :data="tableData"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading">

          <el-table-column type="selection" width="55"> </el-table-column>


          <el-table-column
            prop="stockName"
            width='100px'
            header-align="center"
            align="center"
            label="公司名称">
            <template slot-scope="{ row }">
              <el-button type="text" @click.native.prevent="turnDetail1(row)">
                {{ row.stockName }}
              </el-button>
            </template>
          </el-table-column>

          <el-table-column
            prop="stockCode"
            width='100px'
            header-align="center"
            align="center"
            label="股票代码">
          </el-table-column>

          <el-table-column
          prop='articleTitle'
          width='300px'
          header-align="center"
          align="center"
          label='文章标题'>
          </el-table-column>

<!--          <el-table-column-->
<!--            prop="articleUrl"-->
<!--            header-align="center"-->
<!--            align="center"-->
<!--            label="原文链接">-->

<!--            <template slot-scope="{ row }">-->
<!--              <el-button type="text" @click="openUrlCli(row)">-->
<!--               跳转至原文链接-->
<!--              </el-button>-->
<!--            </template>-->

<!--          </el-table-column>-->

          <el-table-column
            prop='checkTime'
            header-align="center"
            align="center"
            label='审核时间'>
          </el-table-column>

          <el-table-column
            prop='authState'
            header-align="center"
            align="center"
            label='AI审核结果'>

            <template slot-scope="scope">

              <el-tag type='success' v-if='scope.row.authState === 20'>有效</el-tag>
              <el-tag type='danger' v-if='scope.row.authState === 30'>无效</el-tag>
              <!--
                            <span>{{ // getAuthStateLabel(scope.row.authState) }}</span>
              -->
            </template>


          </el-table-column>

          <el-table-column
            prop='failReason'
            header-align="center"
            align="center"
            label='审核原因'>
          </el-table-column>


          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            fixed="right"
          >

            <!-- 有效按钮Dream定位 -->
            <template slot-scope="{ row }">
              <template >
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="passItemTrue(row)"
                  style="color: cornflowerblue"
                >
                  准确
                </el-button>
                <el-button    size="small"   type="text" style="color: cornflowerblue"
                              @click="passItemFalse(row)"
                >不准确</el-button>
              </template>
            </template>
          </el-table-column>

        </el-table>

        <div class="pb20"></div>

        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-sizes="[10, 50, 100, 200]"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>

      </div>
    </el-card>


<!--    单点不通过弹窗-->
    <div>
      <el-dialog title="复审不准确"
                 width="500px"
                 :visible.sync="noPassDialogVisible"
                 @close="closeNoPassDialog" >

        <el-form
        :model="reviewInfo">

          <el-form-item label="审核原因:">
            <el-radio-group v-model="reviewInfo.reviewStatus" @change='radioChange'>
              <el-radio :label="0">有效</el-radio>
              <el-radio :label="1">无效</el-radio>
            </el-radio-group>
          </el-form-item>


          <el-form-item label='有效原因' v-if='reviewStatus===0'>
            <el-cascader
              v-model="reviewInfo.reviewReason"
              placeholder="请选择有效原因"
              :options="validOptions"
              clearable></el-cascader>
          </el-form-item>

          <el-form-item label='无效原因' v-if='reviewStatus===1'>
            <el-cascader
              v-model="reviewInfo.reviewReason"
              placeholder="请选择有效原因"
              :options="invalidOptions"
              clearable></el-cascader>
          </el-form-item>

        </el-form>


        <div style='text-align: right'>
          <el-button type='primary' @click='noPassCli'>确定</el-button>
          <el-button @click='closeNoPassDialog'>取消</el-button>
        </div>

      </el-dialog>
    </div>


<!--    批量不通过-->
    <div>
      <el-dialog title="批量复审不准确"
                 width="500px"
                 :visible.sync="batchNoPassDialogVisible"
                 @close="closeBathNoPassDialog" >

        <el-form
          :model="reviewInfo">

          <el-form-item label="审核原因:">
            <el-radio-group v-model="reviewInfo.reviewStatus" @change='radioChange'>
              <el-radio :label="0">有效</el-radio>
              <el-radio :label="1">无效</el-radio>
            </el-radio-group>
          </el-form-item>


          <el-form-item label='有效原因' v-if='reviewStatus===0'>
            <el-cascader
              v-model="reviewInfo.reviewReason"
              placeholder="请选择有效原因"
              :options="validOptions"
              clearable></el-cascader>
          </el-form-item>

          <el-form-item label='无效原因' v-if='reviewStatus===1'>
            <el-cascader
              v-model="reviewInfo.reviewReason"
              placeholder="请选择有效原因"
              :options="invalidOptions"
              clearable></el-cascader>
          </el-form-item>

        </el-form>


        <div style='text-align: right'>
          <el-button type='primary' @click='batchNoPassClick'>确定</el-button>
          <el-button @click='closeBathNoPassDialog'>取消</el-button>
        </div>

      </el-dialog>
    </div>

    <el-dialog
      :visible.sync="detailBool"
      width="1200px"
    >
      <releaseDetail :rowID="rowID1"/>
    </el-dialog>

  </div>
</template>

<script>
import {
  basicWordSelectApi1,
  loadArticleReviewPageApi,
  queryBasicWordApi, reviewBatchNoPassApi, reviewBatchPassApi,
  reviewNoPassApi,
  reviewPassApi
} from 'api/article'
import releaseDetail from 'views/release/releaseDetail.vue'

export default {
  components: { releaseDetail },
  data(){
    return{
      selectionArr: [],
      tableLoading:false,
      pageTotal:0,
      pageMsg:{
        pageNum:1,
        pageSize:10,
        authState:20
      },
      tableData:[],
      reviewInfo:{

      },
      noPassDialogVisible:false,
      batchNoPassDialogVisible:false,
      reviewStatus:null,
      validOptions: [],
      invalidOptions:[],
      authStateName:'20',
      detailBool: false,
      rowID1: null,
    }
  },
  mounted() {
    this.initData();
    this.loadValidReasonOptions();
    this.loadInvalidReasonOptions();
  },
  methods: {
    turnDetail1(row) {
      this.rowID1 = row.id
      this.detailBool = true
    },
    getAuthStateLabel(value){
      if (value === 20) {
        return '有效';
      } else if (value === 30) {
        return '无效';
      }
      return '';
    },
    handleClick(){
      console.log("状态:"+this.authStateName)
      this.pageMsg.authState = this.authStateName
      this.initData();
    },
    batchNoPassClick(){
      //批量无效
      reviewBatchNoPassApi(this.reviewInfo).then(res => {
        this.$message.success("操作成功")
        this.reviewInfo = this.$options.data().reviewInfo;
        this.selectionArr = this.$options.data().selectionArr;
        this.batchNoPassDialogVisible = false;
        this.initData();
      })
    },
    batchNoPassCli(){
      let arr = []
      this.selectionArr.map(res => {
        arr.push(res.id)
      })
      this.reviewInfo.articleIdArr = arr;
      this.batchNoPassDialogVisible = true;
    },
    batchPassCli(){
      // 批量通过
      let arr = []
      this.selectionArr.map(res => {
        arr.push(res.id)
      })
      this.reviewInfo.articleIdArr = arr;
      reviewBatchPassApi(this.reviewInfo).then(res => {
        this.$message.success("批量操作成功!")
        this.selectionArr = this.$options.data().selectionArr;
        this.reviewInfo = this.$options.data().reviewInfo;
        this.initData();
      })
    },
    noPassCli(){
      reviewNoPassApi(this.reviewInfo).then(res =>{
        this.$message.success("操作成功")
        this.reviewInfo = this.$options.data().reviewInfo;
        this.noPassDialogVisible = false;
        this.initData();
      })
    },
    radioChange(e){
      this.reviewStatus = e;
      console.log(e)
    },
    passItemTrue(item){
      reviewPassApi(item.id).then(res => {
        this.$message.success("复审准确")
        this.initData();
      })
    },
    passItemFalse(item){
      this.reviewInfo.articleId = item.id;
      this.noPassDialogVisible = true;
    },
    closeNoPassDialog(){
      this.noPassDialogVisible = false;
    },
    closeBathNoPassDialog(){
      this.batchNoPassDialogVisible = false;
    },
    resetCli(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    },
    initData(){
      this.tableLoading = true;
      loadArticleReviewPageApi(this.pageMsg).then(res => {
        let { total, records } = res.result
        this.tableData = records
        this.pageTotal = total;
        this.tableLoading = false;
      })
    },
    handleSelectionChange(e) {
      this.selectionArr = e
    },
    openUrlCli(row) {
      let routeData = row.articleUrl;
      window.open(routeData);
    },
    //加载有效原因Options
    loadValidReasonOptions(){
      basicWordSelectApi1().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.wordName
            item.value = item.id
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.validOptions = result
      });
    },
    loadInvalidReasonOptions(){
      queryBasicWordApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.wordName
            item.value = item.id
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.invalidOptions = result
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }



}
</style>
