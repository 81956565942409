<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">

      <div>
        <el-form
          :model='pageMsg'
          size='small'
          label-position="center"
          label-width="100px">
          <el-row>

<!--            <el-col :span='6'>-->
<!--              <el-form-item label='页面类型:' label-width='120px'>-->
<!--                <el-select v-model="pageMsg.apiType" placeholder="请选择类型">-->
<!--                  <el-option-->
<!--                    v-for="item in options"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->

            <el-col :span='6'>
              <el-form-item label="页面名称:" label-width='120px'>
                <el-input
                  v-model='pageMsg.apiName'
                  placeholder='请输入名称'>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span='12'>

              <el-form-item label="时间范围:" label-width='120px'>
                <el-date-picker
                  v-model="pageMsg.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">

                </el-date-picker>

              </el-form-item>
            </el-col>


            <el-col :span='6' align='right'>
              <el-button type='primary' @click='initData'>查询</el-button>
              <el-button @click='reset'>重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>


      <div>
        <el-table
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          :data="tableData"
          v-loading="tableLoading"
        >

          <el-table-column
            prop="apiType"
            label="类型"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              <p v-if='scope.row.apiType === "action_front"'>
                前台
              </p>
              <p v-if='scope.row.apiType === "action"'>
                后台
              </p>
            </template>

          </el-table-column>

          <el-table-column
            prop="apiName"
            label="页面名称"
            header-align="center"
            align="center">

          </el-table-column>

          <el-table-column
            prop="apiSum"
            label="访问次数"
            header-align="center"
            align="center">

          </el-table-column>

        </el-table>

<!--        <div class="pb20"></div>-->

<!--        <el-pagination-->
<!--          class="text-center"-->
<!--          v-if="pageTotal > 0"-->
<!--          @size-change="initData"-->
<!--          @current-change="initData"-->
<!--          :current-page.sync="pageMsg.pageNum"-->
<!--          :page-sizes="[10, 50, 100, 200]"-->
<!--          :page-size.sync="pageMsg.pageSize"-->
<!--          layout="total, sizes, prev, pager, next, jumper"-->
<!--          :total="pageTotal"-->
<!--        >-->
<!--        </el-pagination>-->
      </div>

    </el-card>
  </div>
</template>


<script>
import { getRecordApiListApi } from 'api/record'

export default {
  data(){
    return{
      pageMsg:{
        // pageNum: 1,
        // pageSize: 10,
      },
      tableData:[],
      tableLoading:false,
      pageTotal: 0,
      options:[{
        value: 'action_front',
        label: '前台'
      },{
        value: 'action',
        label: '后台'
      }
        ]
    }
  },
  mounted() {
    this.initData();
  },
  methods:{
    formatDate(date, isStart) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const time = isStart ? "00:00:00" : "23:59:59";
      return `${year}-${month}-${day} ${time}`;
    },
    initData(){
      if (this.pageMsg.time){
        this.pageMsg.startTime = this.formatDate(this.pageMsg.time[0],true)
        this.pageMsg.endTime = this.formatDate(this.pageMsg.time[1],false)
      }

      if (this.pageMsg.apiName === ''){
        this.pageMsg.apiName = null;
      }

      getRecordApiListApi(this.pageMsg).then(res => {

        console.log("返回的数据"+JSON.stringify(res))
        this.tableData = res.result
        // if (res.code === 200){
        //   let { total, records } = res.result
        //   this.tableData = records
        //   this.pageTotal = total;
        // } else {
        //   this.$message({
        //     type: 'error',
        //     message: res.desc
        //   })
        // }
        this.tableLoading = false;
      })
    },
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    }
  }
}


</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
