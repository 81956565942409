<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
      <p>网站管理/新闻资讯</p>
      <h1>编辑新闻</h1>
    </div>
    <el-card class="box-card">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="新闻标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="所属行业（选填）">
          <el-cascader
            :options="options"
            @change="handleChange"
            v-model="ruleForm.industryCategoryArr"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="文章类型（选填）：">
          <el-select
            v-model="ruleForm.type"
            filterable
            placeholder="请选择文章类型"
          >
            <el-option
              v-for="item in typeArray"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新闻摘要" prop="newsConsultContent">
          <vue-tinymce
            v-model="ruleForm.newsConsultContent"
            :setting="setting"
          />
        </el-form-item>
        <el-row>
          <el-button @click="$router.go(-1)">取消</el-button>
          <el-button
            type="primary"
            @click="submit('ruleForm')"
            v-if="userbtn.indexOf('WEBSIT_NEWS_SUB') >= 0"
            >提交</el-button
          >
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import constant from 'constant'
import axios from 'axios'
import { signActionTime } from 'util/js/headerToken'
import { industryCategoryTreeApi } from 'api/common'
import {
  newsConsultAddApi,
  newsConsultEditApi,
  newsConsultDetailApi
} from 'api/websit'
export default {
  name: 'websiteMange',
  data() {
    return {
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      setting: {
        menubar: false,
        toolbar:
          'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image  table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        quickbars_selection_toolbar:
          'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: 'link image  table lists fullscreen quickbars',
        language: 'zh_CN',
        height: 350,
        images_upload_url: true,
        images_upload_handler: function (blobInfo, succFun, failFun) {
          var formData = new FormData()
          let url = '/common/upload'
          formData.append('file', blobInfo.blob()) //此处与源文档不一样

          axios
            .post(url, formData, {
              headers: signActionTime('post', formData)
            })
            .then(response => {
              console.log(response)
              succFun(response.data.result.url)
            })
            .catch(error => {
              failFun(error)
            })
        }
      },
      ruleForm: {
        industryCategoryCode: '',
        industryCategoryArr: [],
        newsConsultContent: '',
        title: '',
        type: ''
      },
      rules: {
        // industryCategoryCode:[
        //   { required: true, message: '请选择所属行业', trigger: 'blur' }
        // ],
        newsConsultContent: [
          { required: true, message: '请输入新闻摘要', trigger: 'blur' }
        ],
        title: [{ required: true, message: '请输入新闻标题', trigger: 'blur' }]
      },
      typeArray: [
        {
          name: '经营管理',
          id: 20
        },
        {
          name: '产品服务',
          id: 30
        },
        {
          name: 'ESG',
          id: 10
        }
      ],
      options: []
    }
  },
  created() {
    this.initCategoryCode()
    this.initDetail()
  },
  methods: {
    initDetail() {
      if (this.$route.query.id) {
        newsConsultDetailApi(this.$route.query.id).then(({ result }) => {
          this.ruleForm = { ...result }
          this.ruleForm.industryCategoryArr = []
          result.industryCategoryList.map(res => {
            this.ruleForm.industryCategoryArr.push(res.id)
          })
          this.ruleForm.industryCategoryCode =
            result.industryCategoryList[
              result.industryCategoryList.length - 1
            ].id
          console.log(this.ruleForm)
        })
      }
    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.options = result
      })
    },
    handleChange(val) {
      this.ruleForm.industryCategoryCode = val[val.length - 1]
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.$route.query.id) {
            newsConsultEditApi({
              ...this.ruleForm
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('新闻编辑成功！')
                this.$router.push('/websiteMange/news')
              }
            })
          } else {
            newsConsultAddApi({
              ...this.ruleForm
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('新闻新增成功！')
                this.$router.push('/websiteMange/news')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
