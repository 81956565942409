<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model="pageMsg"
        ref="formData"
        size="small"
        label-position="left"
      >
        <el-row class="text-left" :gutter="24">
          <el-col :span="8">
            <el-form-item label="名称:" >
              <el-input clearable
                        @keyup.enter.native="searchData(true)"
                        v-model="pageMsg.validWord" style="width: 200px"  placeholder="请输入有效词名称"></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item
              label="有效原因:">


              <el-cascader
                v-model="pageMsg.validArr"
                placeholder="请选择有效原因"
                :props="{ checkStrictly: true }"
                :options="validOptions"
                clearable></el-cascader>
<!--              -->
<!--              <el-cascader-->
<!--                v-model="pageMsg.validArr"-->
<!--                placeholder="请选择有效原因"-->
<!--                :options="validOptions"-->
<!--                :show-all-levels="false"></el-cascader>-->
            </el-form-item>
          </el-col>

          <el-col :span="8" class="text-right">
            <el-button
              type="primary"
              size="small"
              @click="reject()"
            >
              新建
            </el-button>
            <el-button size="small" @click="searchData(false)">
              重置
            </el-button>
            <el-button type="primary" size="small" @click="searchData(true)">
              查询
            </el-button>

          </el-col>
        </el-row>
      </el-form>

      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px;margin-top: 10px"
        row-key="id"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading"
      >

        <el-table-column
          prop="validWord"
          label="名称"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop='validReason'
          label='有效原因'
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="createdTime"
          label="创建时间"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button size="small" @click="deleteWord(row.id)"   type="text"  >
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>

    <div>

      <el-dialog
        title="新增有效词"
        :visible.sync="addValidWordDialog"
        width="50%"
        :before-close="addValidWordDialogClose">

        <el-form :model="ruleModel" ref="addValidWordForm" label-width="100px" :rules="rules"
                 :label-position="labelPosition"
                 :label-width="formLabelWidth">
            <el-form-item prop="failReason" label='请选择有效原因:'>
              <el-cascader
                v-model="ruleModel.failReason"
                placeholder="请选择有效原因"
                :options="validOptions"
                clearable></el-cascader>
            </el-form-item>

          <el-form-item label='请输入有效词:' prop='validWord'
                        v-if='ruleModel.failReason&&ruleModel.failReason!=""'>
            <el-input v-model='ruleModel.validWord' placeholder='请输入有效词' style="width: 200px"></el-input>
          </el-form-item>

        </el-form>

        <span>
          <el-button type='primary' @click="addValidWord">确定</el-button>
          <el-button  @click="addValidWordDialogClose">取消</el-button>
        </span>


      </el-dialog>

    </div>

  </div>
</template>

<script>
import {
  queryBasicWordApi,
  addValidWordApi, delValidWordApi, queryValidWordPageApi, basicWordSelectApi1
} from 'api/article'
export default {
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()
        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    }
  },
  data() {
    return {
      rules:{
        failReason: [{required: true, message: '有效原因不能为空',trigger: 'blur'}],
        validWord: [{required: true, message: '有效词不能为空',trigger: 'blur'}]
      },
      formLabelWidth: '130px',
      labelPosition: 'left',
      validOptions:[],
      ruleModel:{
        failReason:null,
        validWord:null
      },
      addValidWordDialog:false,
      tableData:[],
      tableLoading:false,
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10,
        validWord:null,
        validArr:[]
      },
    }
  },
  mounted() {
    this.initData()
    this.validSelect();
  },
  methods: {
    addValidWord(){
      this.ruleModel.failReason = this.ruleModel.failReason[0]+","+this.ruleModel.failReason[1];
      this.$refs.addValidWordForm.validate(valid => {
        if(valid){
          addValidWordApi(this.ruleModel).then(res =>{
            if(res.code === 200){
              this.$message.success("新增成功!")
              this.initData();
            }
          })
        }else {
          return;
        }
      })
      this.ruleModel = this.$options.data().ruleModel;
      this.addValidWordDialog = false;
    },
    deleteWord(id){
      delValidWordApi(id).then(res => {
        if(res.code === 200){
          this.$message.success("删除成功!");
          this.initData()
        }
      })
    },
    addValidWordDialogClose(){
      this.ruleModel = this.$options.data().ruleModel;
      this.addValidWordDialog = false;
    },
    searchData(bool){
      if (bool){
        this.initData()
        //查询
      }else {
        this.pageMsg = this.$options.data().pageMsg;
        this.initData();
        //重置
      }
    },
    validSelect(){
      basicWordSelectApi1().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            console.log("wordName"+item.wordName)
            item.label = item.wordName
            item.value = item.id
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.validOptions = result
      });
    },
    reject(){
      //新建
      this.addValidWordDialog = true;
      this.validSelect();

    },
    initData(){
      if(this.pageMsg.validWord ===""){
        this.pageMsg.validWord = null;
      }

      queryValidWordPageApi(this.pageMsg).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
}
</style>
