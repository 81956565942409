<template>
  <div class="staris_finial">
    <el-row>
      <el-col :sm="12" :lg="6">
        <el-result
          icon="success"
          title="成功提示"
          subTitle="请根据提示进行操作"
        >
          <template slot="extra">
            <el-button type="primary" size="medium">返回</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
    <el-steps :active="2" align-center>
      <el-step
        title="步骤1"
        description="这是一段很长很长很长的描述性文字"
      ></el-step>
      <el-step
        title="步骤2"
        description="这是一段很长很长很长的描述性文字"
      ></el-step>
      <el-step
        title="步骤3"
        description="这是一段很长很长很长的描述性文字"
      ></el-step>
      <el-step
        title="步骤4"
        description="这是一段很长很长很长的描述性文字"
      ></el-step>
    </el-steps>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
</style>
