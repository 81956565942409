var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _vm._m(0),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司", prop: "companyName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "companyName", $$v)
                      },
                      expression: "ruleForm.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职务名称", prop: "positionName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.positionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "positionName", $$v)
                      },
                      expression: "ruleForm.positionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "经验要求", prop: "experienceRequire" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.experienceRequire,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "experienceRequire", $$v)
                        },
                        expression: "ruleForm.experienceRequire",
                      },
                    },
                    _vm._l(_vm.WorkExperienceEnum, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.v, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学历要求", prop: "educationRequire" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.educationRequire,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "educationRequire", $$v)
                        },
                        expression: "ruleForm.educationRequire",
                      },
                    },
                    _vm._l(_vm.EducationEnum, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.v, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "薪酬范围", prop: "salaryRange" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.salaryRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "salaryRange", $$v)
                        },
                        expression: "ruleForm.salaryRange",
                      },
                    },
                    _vm._l(_vm.SalaryEnum, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.v, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职务描述", prop: "positionDescribe" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.ruleForm.positionDescribe,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "positionDescribe", $$v)
                      },
                      expression: "ruleForm.positionDescribe",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工作地址", prop: "cityId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择国家" },
                      on: { change: _vm.guoChange },
                      model: {
                        value: _vm.ruleForm.countryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "countryId", $$v)
                        },
                        expression: "ruleForm.countryId",
                      },
                    },
                    _vm._l(_vm.guo, function (item) {
                      return _c("el-option", {
                        key: item.Name,
                        attrs: { label: item.Name, value: item.Code },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择省份" },
                      on: { change: _vm.shengChange },
                      model: {
                        value: _vm.ruleForm.provinceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "provinceId", $$v)
                        },
                        expression: "ruleForm.provinceId",
                      },
                    },
                    _vm._l(_vm.sheng, function (item) {
                      return _c("el-option", {
                        key: item.Name,
                        attrs: { label: item.Name, value: item.Code },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择城市" },
                      on: { change: _vm.shiChange },
                      model: {
                        value: _vm.ruleForm.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "cityId", $$v)
                        },
                        expression: "ruleForm.cityId",
                      },
                    },
                    _vm._l(_vm.shi, function (item) {
                      return _c("el-option", {
                        key: item.Name,
                        attrs: { label: item.Name, value: item.Code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布人", prop: "publisher" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写" },
                    model: {
                      value: _vm.ruleForm.publisher,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "publisher", $$v)
                      },
                      expression: "ruleForm.publisher",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布人岗位", prop: "publisherPosition" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写" },
                    model: {
                      value: _vm.ruleForm.publisherPosition,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "publisherPosition", $$v)
                      },
                      expression: "ruleForm.publisherPosition",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职位链接", prop: "positionUrl" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.positionUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "positionUrl", $$v)
                      },
                      expression: "ruleForm.positionUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channelManagement-head" }, [
      _c("p", [_vm._v("网站管理/加入我们")]),
      _c("h1", [_vm._v("招聘发布")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }