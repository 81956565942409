<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model="pageMsg"
        ref="formData"
        size="small"
        label-position="center"
        label-width="100px"
      >
        <el-row>
          <el-col :span='8'>
            <el-form-item label='客户名称:'>
              <el-input
                        v-model="pageMsg.stockName" style="width: 150px"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='8'>
            <span>
              <el-button type='primary' size='small' @click="initData">查询</el-button>
              <el-button  size='small' @click="reset">重置</el-button>
              <el-button type='primary' size='small' @click="openClientAccount">开通</el-button>
            </span>
          </el-col>

          <el-col :span='8'>
            <el-form-item label='媒介盒子余额:'>
              <span style='font-size:18px '>
                <strong>
                {{ mediaBoxAmount }}
                </strong>
              </span>
<!--              <el-tag type="success">{{ mediaBoxAmount }}</el-tag>-->
<!--              <el-input-->
<!--                style='width: 200px'-->
<!--                v-model="mediaBoxAmount"-->
<!--                class="input-with-select"-->
<!--                disabled>-->
<!--              </el-input>-->
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px;margin-top: 10px"
        row-key="id"
        @selection-change="handleSelectionChange"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading">

        <el-table-column
          prop="stockName"
          label="公司名称"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="openAmountDetail(row)">
              {{ row.stockName }}
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="amount"
          label="余额"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="isFreeze"
          label="是否开启"
          header-align="center"
          align="center"
          fixed="right">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isFreeze"
              @change='isFreezeChange(scope.row)'>
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          fixed="right">
          <template slot-scope="scope">
            <el-button type='primary' size='mini' @click='edit(scope.row)'>编辑</el-button>
          </template>
        </el-table-column>

      </el-table>

      <div class="pb20"></div>

      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>

      <div class="channel-add">
        <el-dialog
          title="编辑客户信息"
          style="margin-left: -80px"
          width="800px"
          :visible.sync="editClientAccountDialog"
          @close="editClientAccountDialogClose" >

          <el-form
            :model="editMsg"
            ref="editRef"
            :rules='editFormRules'
            size="small"
            label-position="center"
            label-width="100px"
          >

            <el-form-item label='客户名称:' prop='stockName'>
              <el-input
                v-model="editMsg.stockName"
                style='width: 150px'
                placeholder="请输入客户名称"
                :disabled="true">
              </el-input>
            </el-form-item>

            <el-form-item label='当前余额:' prop='amount'>
              <el-input
                v-model="editMsg.amount"
                style='width: 150px'
                :disabled="true">
              </el-input>
            </el-form-item>


            <el-form-item label='操作:' prop='editType'>
              <el-radio-group v-model="editMsg.editType" size="mini" >
                <el-radio-button label="0" border>增加</el-radio-button>
                <el-radio-button label="1" border>减少</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item label='操作金额' prop='editAmount'>
              <el-input-number v-model="editMsg.editAmount"  :min="1" :max="100000"></el-input-number>
            </el-form-item>

<!--            <el-form-item label='客户状态:' prop='isFreeze'>-->
<!--              <el-switch-->
<!--                v-model="editMsg.isFreeze">-->
<!--              </el-switch>-->
<!--            </el-form-item>-->
          </el-form>

          <p>
            <el-button type='primary' size='small' @click="editClientAccount">确定</el-button>
            <el-button size='small' @click="editClientAccountDialogClose">取消</el-button>
          </p>

        </el-dialog>
      </div>

      <div class="channel-add">
        <el-dialog
          title="开通储值发客户账号"
          style="margin-left: -80px"
          width="800px"
          :visible.sync="openClientAccountDialog"
          @close="openClientAccountDialogClose" >
          <el-form
            :model="openMsg"
            size="small"
            label-position="center"
            label-width="100px"
            ref='addRef'
            :rules='addRules'
          >

            <el-form-item label="请选择公司:" prop="stockId">
              <el-select v-model="openMsg.stockId" placeholder="请选择公司" style="width: auto">
                <el-option
                  v-for="item in stockOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <!--<el-form-item label="请选择公司:" prop="stockName"-->
<!--              <el-select v-model="openMsg.stockId" placeholder="请选择公司"  style="width: auto">-->
<!--                <el-option-->
<!--                  v-for="item in stockOptions"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->

            <el-form-item label="请填写余额:" prop="amount">
              <el-input
                v-model="openMsg.amount"
                placeholder="请填写余额"
                style="width: auto">
              </el-input>
            </el-form-item>

          </el-form>
          <p>
            <el-button type='primary' @click="openClient">确定</el-button>
            <el-button @click="openClientAccountDialogClose">取消</el-button>
          </p>
        </el-dialog>
      </div>

    </el-card>

    <div>

      <el-dialog
        title="查看客户余额记录"
        :visible.sync="editStockAmountDialogVisible"
        width="85%"
        @close="editStockAmountDialogClose">

        <el-table
          :data="stockAmountData"
          style="width: 100%; margin-bottom: 20px;margin-top: 10px"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          v-loading="amountTableLoading">

          <el-table-column
            prop="changeType"
            label="类型">
          </el-table-column>

          <el-table-column
            prop="changeAmount"
            label="变更金额">
          </el-table-column>

          <el-table-column
          prop="changeBeforeAmount"
          label="变更前余额">
        </el-table-column>

          <el-table-column
            prop="changeAfterAmount"
            label="变更后余额">
          </el-table-column>

          <el-table-column
            prop="changeRemark"
            label="备注">
          </el-table-column>

          <el-table-column
            prop="updatedTime"
            label="变更时间">
            <template slot-scope="{ row }">
              {{ formatDate(row.updatedTime) }}
            </template>
          </el-table-column>

        </el-table>

        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="amountLogTotal > 0"
          @size-change="getStockAmountLogFun"
          @current-change="getStockAmountLogFun"
          :current-page.sync="amountLogMsg.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="amountLogMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="amountLogTotal"
        >
        </el-pagination>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  editStockAmountApi, getMediaBoxAmountApi, getRelevanceStockListApi, getStockAmountDetailApi,
  getStockAmountPageApi,
  getStockBySignAndSigningApi,
  openClientAccountApi,
  upStockStateApi
} from 'api/article'

export default {
  computed:{
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    },
  },
  data(){
    return{
      mediaBoxAmount:"0.0",
      amountTableLoading:false,
      stockAmountData:[],
      editStockAmountDialogVisible:false,
      amountLogTotal:0,
      pageTotal:0,
      tableLoading:false,
      openClientAccountDialog:false,
      editClientAccountDialog:false,
      pageMsg:{},
      amountLogMsg:{},
      openMsg:{},
      editMsg:{
        editAmount:10000
      },
      stockOptions:[],
      tableData:[],
      editFormRules:{
        editType:[{required:true,message:'请选择操作类型',trigger:'blur'}],
        editAmount:[{required:true,message:'请填写余额',trigger:'blur'}]
      },
      addRules: {
        stockId: [{ required: true, message: '请填写客户名称', trigger: 'blur' }],
        amount: [{ required: true, message: '请填写余额', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.initData();
    this.getMediaBoxAmount();
  },
  methods:{
    getMediaBoxAmount(){
      getMediaBoxAmountApi().then(res => {
        if(res.code === 200){
          console.log("返回的数据",JSON.stringify(res))
          this.mediaBoxAmount = res.result;
        }
      })
    },
    editStockAmountDialogClose(){
      this.editStockAmountDialogVisible = false;
      this.stockAmountData = this.$options.data().stockAmountData;
    },
    getStockAmountLogFun(){
      getStockAmountDetailApi(this.amountLogMsg).then(res => {
        this.stockAmountData = res.result.records;
        this.amountLogTotal = res.result.total;
      })
    },
    openAmountDetail(row){
      this.amountLogMsg.stockId = row.stockId;
      this.getStockAmountLogFun();
      this.editStockAmountDialogVisible = true;
    },
    //编辑客户信息
    editClientAccount(){
      this.$refs.editRef.validate(valid => {
          if (valid) {
            editStockAmountApi(this.editMsg).then(res => {
              this.$message.success("编辑成功!")
              this.initData();
              this.editClientAccountDialogClose();
            })
          }
      })
    },
    edit(row){
      this.editMsg.stockId = row.stockId;
      this.editMsg.stockName = row.stockName;
      this.editMsg.amount = row.amount
      this.editMsg.isFreeze = row.isFreeze
      this.editClientAccountDialog = true;
    },
    isFreezeChange(row){
      if (row.isFreeze){
        this.pageMsg.stockState = 0;
      }else{
        this.pageMsg.stockState = 1;
      }

      this.pageMsg.stockId = row.stockId;

      upStockStateApi(this.pageMsg).then(res => {
        this.$message.success("修改成功!")
      })
    },
    handleSelectionChange(){

    },
    initData(){
      this.tableLoading = true;
      getStockAmountPageApi(this.pageMsg).then(res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        } else {
          this.tableData = []; // API调用失败或没有数据时，确保tableData是一个空数组
        }
        this.tableLoading = false;
      })
    },
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();

    },
    openClient(){
      this.$refs.addRef.validate( valid => {
        if (valid){
          openClientAccountApi(this.openMsg).then( res=> {
            if (res.code === 200){
              this.initData();
              this.$message.success("开通成功!")
              this.openClientAccountDialogClose();
            }
          })
        }
      })
    },
    openClientAccount(){

      // 获取签约和签约中客户信息
      getStockBySignAndSigningApi().then(res => {
        // 获取已经关联的公司列表
        getRelevanceStockListApi().then(data => {
          if (data.code === 200) {
            if (res.code === 200) {
              res.result.forEach(item => {
                if (!data.result.includes(item.id)) { // 直接使用 data.result 数组进行检查
                  this.stockOptions.push({
                    value: item.id,
                    label: item.stockName
                  });
                }
              });
            }
          }
        });
      });


      //获取签约和签约中客户信息
      // getStockBySignAndSigningApi().then(res =>{
        //获取已经关联的公司列表
        // getRelevanceStockListApi().then(data =>{
        //   if (data.code ===200){
        //   }
        // })
      //   if (res.code === 200){
      //     res.result.forEach(item => {
      //       this.stockOptions.push({
      //         value:item.id,
      //         label:item.stockName
      //       })
      //     })
      //   }
      // })

      this.openClientAccountDialog = true;
    },
    openClientAccountDialogClose(){
      this.openClientAccountDialog = false;
      this.openMsg = this.$options.data().openMsg
      this.stockOptions = this.$options.data().stockOptions;
    },
    editClientAccountDialogClose(){
      this.editClientAccountDialog = false;
      this.editMsg = this.$options.data().editMsg
    }
  }


}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>