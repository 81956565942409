var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channel-add" },
    [
      _c(
        "el-form",
        {
          ref: "addChannel",
          attrs: {
            rules: _vm.userFormRules,
            model: _vm.channelForm,
            "label-position": _vm.labelPosition,
            "label-width": _vm.formLabelWidth,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "媒介名称", prop: "channelName" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.channelForm.channelName,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "channelName", $$v)
                  },
                  expression: "channelForm.channelName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "API key ", prop: "apiKey" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.channelForm.apiKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "apiKey", $$v)
                  },
                  expression: "channelForm.apiKey",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "API Secret", prop: "apiSecret" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.channelForm.apiSecret,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "apiSecret", $$v)
                  },
                  expression: "channelForm.apiSecret",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "key和Secret有效期：", prop: "dateValue" } },
            [
              _c("el-date-picker", {
                staticClass: "change-date-form",
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  type: "daterange",
                  "range-separator": "~",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  "picker-options": _vm.pickerOptions,
                  "default-value": _vm.timeDefaultShow,
                },
                on: { change: _vm.dateChange },
                model: {
                  value: _vm.channelForm.dateValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "dateValue", $$v)
                  },
                  expression: "channelForm.dateValue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }