<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">

      <el-table
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="tableData"
        v-loading="tableLoading"
      >

<!--        <el-table-column-->
<!--          label="排名"-->
<!--          header-align="center"-->
<!--          align="center">-->
<!--          <template slot-scope="scope">-->
<!--            {{ scope.$index + 1 }}-->
<!--          </template>-->
<!--        </el-table-column>        -->

        <el-table-column
          label="收益排名"
          header-align="center"
          align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column
          prop="industryName"
          label="行业名称"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          label="BA排名数"
          prop="rankNum"
          header-align="center"
          align="center">
        </el-table-column>

        <el-table-column
          prop="growthRate"
          label="收益率"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.growthRate.toFixed(2) }}%
          </template>

        </el-table-column>

      </el-table>

    </el-card>
  </div>
</template>

<script>
import { getIprRanking } from 'api/stock'

export default {
  data(){
    return{
      tableData:[],
      tableLoading:false
    }
  },
  mounted() {
    this.initData();
  },
  methods:{
    initData() {
      getIprRanking().then(res => {
        if (res.code == 200) {
          console.log("返回的数据:"+JSON.stringify(res))
          this.tableData = res.result
        }
      })
    }
  },
  created() {
    this.initData()
  },
  components: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style scoped lang='scss'>

</style>