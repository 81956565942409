var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channel-add" },
    [
      _c(
        "el-form",
        {
          ref: "addChannel",
          attrs: {
            rules: _vm.userFormRules,
            model: _vm.channelForm,
            "label-position": _vm.labelPosition,
            "label-width": _vm.formLabelWidth,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "媒介名称", prop: "channelName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.channelForm.channelName,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "channelName", $$v)
                  },
                  expression: "channelForm.channelName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权益接口", prop: "apiUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.channelForm.apiUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "apiUrl", $$v)
                  },
                  expression: "channelForm.apiUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "月活量(MAU)", prop: "monthlyActivity" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.channelForm.monthlyActivity,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "monthlyActivity", $$v)
                  },
                  expression: "channelForm.monthlyActivity",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权益次数", prop: "infiniteFlag" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.channelForm.infiniteFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.channelForm, "infiniteFlag", $$v)
                    },
                    expression: "channelForm.infiniteFlag",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("无限制")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("有限制")]),
                ],
                1
              ),
              _c("br"),
              _vm.channelForm.infiniteFlag
                ? _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1, max: 9999 },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.channelForm.equityNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.channelForm, "equityNumber", $$v)
                      },
                      expression: "channelForm.equityNumber",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remarks" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.channelForm.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.channelForm, "remarks", $$v)
                  },
                  expression: "channelForm.remarks",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.channelClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.channelAdd } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }