<template>
  <div class="channel-add">
    <el-form
      :rules="userFormRules"
      ref="addChannel"
      :model="channelForm"
      :label-position="labelPosition"
      :label-width="formLabelWidth"
    >
      <el-form-item label="媒介名称" prop="channelName">
        <el-input v-model="channelForm.channelName" disabled></el-input>
      </el-form-item>

      <!-- <el-form-item label="权益接口" prop="apiUrl">
        <el-input v-model="channelForm.apiUrl" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="API key " prop="apiKey">
        <el-input v-model="channelForm.apiKey" disabled></el-input>
      </el-form-item>
      <el-form-item label="API Secret" prop="apiSecret">
        <el-input v-model="channelForm.apiSecret" disabled></el-input>
      </el-form-item>
      <el-form-item label="key和Secret有效期：" prop="dateValue">
        <el-date-picker
          disabled
          class="change-date-form"
          v-model="channelForm.dateValue"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="dateChange"
          :picker-options="pickerOptions"
          :default-value="timeDefaultShow"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <!-- <div slot="footer" class="dialog-footer">
      <el-button @click="channelClose">取 消</el-button>
      <el-button type="primary" @click="channelAdd">确 定</el-button>
    </div> -->
  </div>
</template>
<script>
import {
  channelUpdEquityApi,
  channelAddApi,
  channelUpdApi,
  channelDetailApi
} from 'api/channelManagement'
export default {
  props: {
    apiDetailMsg: {
      type: Number,
      default: null
    }
  },
  watch: {
    apiDetailMsg: {
      handler(bool) {
        // this.$refs.addChannel.resetFields()
        if (bool) {
          console.log(this.apiDetailMsg)
          channelDetailApi(this.apiDetailMsg).then(res => {
            console.log(res)
            this.channelForm = { ...res.result }
            this.channelForm.infiniteFlag
              ? (this.channelForm.infiniteFlag = 1)
              : (this.channelForm.infiniteFlag = 0)
            function converTimeOfHMS(date) {
              var json_date = new Date(date).toJSON()
              return new Date(new Date(json_date) + 8 * 3600 * 1000)
                .toISOString()
                .replace(/T/g, ' ')
                .replace(/\.[\d]{3}Z/, '')
            }
            this.channelForm.termValidityStart = converTimeOfHMS(
              new Date(res.result.termValidityStart)
            )
            this.channelForm.termValidityEnd = converTimeOfHMS(
              new Date(res.result.termValidityEnd)
            )
            this.channelForm.dateValue = [
              res.result.termValidityStart,
              res.result.termValidityEnd
            ]
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogFormVisible: false,
      timeDefaultShow: '',
      pickerOptions: {
        // disabledDate(time) {
        //   console.log(time,'time');
        //   return time.getTime() > Date.now()
        // }
      },
      channelForm: {
        channelName: '',
        apiUrl: '',
        apiKey: '', //api Key
        apiSecret: '', //apiSecret
        termValidityEnd: '', //Key与Secret有效期结束 [type = 20]
        termValidityStart: '', //Key与Secret有效期开始 [type = 20]
        dateValue: []
      },
      userFormRules: {
        // channelName: [{ required: true, message: '媒介名称不能为空' }],
        // apiUrl: [{ required: true, message: '权益接口不能为空' }],
        // coverage: [{ required: true, message: '目标预计覆盖人群不能为空' }],
        // infiniteFlag: [{ required: true, message: '权益次数不能为空' }]
        // remarks: [{ required: true, message: '备注不能为空' }]
      },
      formLabelWidth: '160px',
      labelPosition: 'right'
    }
  },
  methods: {
    handleChange(e) {
      console.log(e)
    },
    dateChange(e) {
      this.channelForm.termValidityStart = e[0] + ' 00:00:00'
      this.channelForm.termValidityEnd = e[1] + ' 23:59:59'
    },
    channelClose() {
      this.$emit('closeAdd')
      this.channelForm = this.$options.data().channelForm
      this.$refs.addChannel.resetFields()
    },
    channelAdd() {
      this.$refs.addChannel.validate(valid => {
        if (valid) {
          channelUpdEquityApi({ ...this.channelForm }).then(res => {
            this.$emit('closeAdd')
            this.channelForm = this.$options.data().channelForm
          })
        }
      })
    }
  }
}
</script>
