var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "Box" },
      [
        _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c("el-progress", {
              attrs: {
                type: "circle",
                width: 300,
                percentage: _vm.percentage,
                "show-text": false,
              },
            }),
            _c("div", { staticClass: "time" }, [
              _vm.countdown > 0
                ? _c("h2", [_vm._v("倒计时" + _vm._s(_vm.countdown) + "秒")])
                : _c("h2", [_vm._v("倒计时结束")]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          _vm._l(_vm.timeList, function (item, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticStyle: { "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.chooseTime(item.num)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          1
        ),
        !_vm.is_pause
          ? _c(
              "el-button",
              {
                staticStyle: { "margin-top": "30px" },
                attrs: { type: "primary" },
                on: { click: _vm.timeFn },
              },
              [_vm._v("开始")]
            )
          : _vm._e(),
        _vm.is_pause
          ? _c(
              "el-button",
              {
                staticStyle: { "margin-top": "30px" },
                attrs: { type: "danger" },
                on: { click: _vm.pauseFn },
              },
              [_vm._v("暂停")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }