<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
	<el-card class="box-card">
		
		<el-form
			:model="pageMsg"
		    ref="formData"
		    ize="small"
		    label-position="left"
		    label-width="44px"
		>
		<el-row class="text-left" :gutter="24">
		    <el-col :span="6">
		        <el-form-item label="名称:" >
		            <el-input clearable
		                      @keyup.enter.native="searchData(true)"
		                      v-model="pageMsg.wordName" style="width: 200px"  placeholder="请输入词库名称"></el-input>
		        </el-form-item>
		    </el-col>
		    <!-- <el-col :span="1">
		      <p style="opacity: 0"></p>
		    </el-col> -->
		
		    <el-col :span="10">
		        <el-form-item  >
		            <el-radio-group v-model="pageMsg.wordType"  @keyup.enter.native="searchData(true)">
						<el-radio :label="10" style="margin-right: 10px">一级类目</el-radio>
						<el-radio :label="20" style="margin-right: 10px">二级类目</el-radio>
		                <el-radio :label="3" style="margin-right: 10px">有效</el-radio>
		                <el-radio :label="1" style="margin-right: 10px">无效</el-radio>
		            </el-radio-group>
		        </el-form-item>
		    </el-col>
		    <el-col :span="8" class="text-right">
		        <el-button
		                type="primary"
		                size="small"
		                @click="addParent()"
		        >
		            新建一级目录
		        </el-button>
				<el-button
				        type="primary"
				        size="small"
				        @click="addChildren()"
				>
				    新建二级目录
				</el-button>
		        <el-button size="small" @click="searchData(false)">
		            重置
		        </el-button>
		        <el-button type="primary" size="small" @click="searchData(true)">
		            查询
		        </el-button>
		
		    </el-col>
		</el-row>
		</el-form>
			
			 <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px;margin-top: 10px"
              row-key="id"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              v-loading="tableLoading"
				>
				
				<el-table-column
				 prop="id"
				 label="ID"
				 header-align="center"
				 align="center"
				 width="auto">
				</el-table-column>
				
			    <el-table-column
			     prop="wordName"
			     label="名称"
				 header-align="center"
				 align="center"
				 width="auto">
			    </el-table-column>
				<el-table-column
				  prop="parentWordName"
				  label="上级名称"
				  header-align="center"
				  align="center"
				  width="auto">
<!-- 				  <p
				          v-if="row.parentWordName == 'null'"
				  >
				      一级类目
				  </p> -->
				</el-table-column>
			    <el-table-column
			      prop="wordType"
			      label="类型"
			      header-align="center"
			      align="center"
			      width="auto">
				  <template slot-scope="{ row }">
				      <p
				              v-if="row.wordType === 1"
				      >
				          无效原因
				      </p>
				      <p
				              v-if="row.wordType === 3"
				      >
				          有效原因
				      </p>
					  <p
					          v-if="row.wordType === 5"
					  >
					      一级分类(有效)
					  </p>
					  <p
					          v-if="row.wordType === 6"
					  >
					      一级分类(无效)
					  </p>
				  </template>
			    </el-table-column>
			    <el-table-column
			      prop="createdTime"
			      label="创建时间"
				  header-align="center"
				  align="center"
				  width="auto">
				  <template slot-scope="{ row }">
				    {{ formatDate(row.createdTime) }}
				  </template>
			    </el-table-column>
				<el-table-column
				  label="操作"
				  header-align="center"
				  align="center"
				  fixed="right">
				  <template slot-scope="{ row }">
				    <el-button size="small" @click="edit(row)"   type="text" >
				      编辑
				    </el-button>
				    <el-button size="small" @click="deleteWord(row.id)"   type="text"  >
				      删除
				    </el-button>
				  </template>
				</el-table-column>
			</el-table>
			
			<!-- 分页查询 -->
			<div style="margin: 0px auto;">
				   <el-pagination
				    class="text-center"
				      v-if="pageTotal > 0"
				      @size-change="initData"
				      @current-change="initData"
              :current-page.sync="pageMsg.pageNum"
              :page-size.sync="pageMsg.pageSize"
				      layout="total, sizes, prev, pager, next, jumper"
				       :total="pageTotal">
				    </el-pagination>
			</div>
		
    </el-card>
	
	<!-- 新增一级目录Begin -->
	<el-dialog   title="新增一级目录信息" style="margin-left: -80px"   width="500px"  :visible.sync="addParentDialogShow"  @close="addParentClose" >
      <el-form
	  :model="addParentForm"
	  ref="addParentForm"
	  :rules="addParentFormRules"
	  label-position="right"
	  label-width="100px">
		    <el-form-item label="一级目录" prop="wordName">
				<el-input v-model="addParentForm.wordName" placeholder="请输入一级目录" style="width: 300px"></el-input>
		    </el-form-item>
			
			<el-form-item label="目录类型" prop="wordType">
			<el-radio-group v-model="addParentForm.wordType">
			    <el-radio :label="5" style="margin-right: 10px">有效</el-radio>
			    <el-radio :label="6" style="margin-right: 10px">无效</el-radio>
			</el-radio-group>
			</el-form-item>
	  </el-form>
	  <div align="right">
	  		  <span>
	  <el-button @click="addParentClose" >取消</el-button>
	  <el-button type="primary"  @click="addParentClick">确定</el-button>
	  		</span>
	  		</div>
	</el-dialog>
		<!-- 新增一级目录End -->
		
		<!-- 新增二级类目Begin -->
	<el-dialog   title="新增二级目录信息" style="margin-left: -80px"   width="500px"  :visible.sync="addChildrenDialogShow"  @close="addChildrenClose" >	
	<el-form
	:model="addChildrenForm"
	ref="addChildrenForm"
	:rules="addChildrenFormRules"
	label-position="right"
	label-width="100px">
			 <el-form-item label="一级类目" prop="parentCode">
				   <el-select v-model="addChildrenForm.parentCode" placeholder="请选择">
				     <el-option
				       v-for="item in options"
				       :key="item.value"
				       :label="item.label"
				       :value="item.value">
				     </el-option>
				   </el-select>
			 </el-form-item>
			 
		    <el-form-item label="二级目录" prop="wordName">
				<el-input v-model="addChildrenForm.wordName" placeholder="请输入二级目录"></el-input>
		    </el-form-item>
			 
			 
	</el-form>
	<div align="right">
			  <span>
	<el-button @click="addChildrenClose" >取消</el-button>
	<el-button type="primary"  @click="addChildrenClick">确定</el-button>
			</span>
			</div>
	
	</el-dialog>
		
		
		
		<el-dialog
		title='编辑类目'
		:visible.sync="updateDialogShow"  
		@close="updateClose">
			<el-form
			:model="updateForm"
			ref="updateForm"
			:rules="updateFormRules"
			label-position="right"
			label-width="100px">
			
			<el-form-item label="一级类目" prop="parentCode" v-if="updateForm.isParent">
							   <el-select v-model="updateForm.parentCode" placeholder="请选择一级类目">
							     <el-option
							       v-for="item in upOptions"
							       :key="item.value"
							       :label="item.label"
							       :value="item.value">
							     </el-option>
							   </el-select>
			</el-form-item>
				    <el-form-item label="类目名称" prop="wordName">
						<el-input v-model="updateForm.wordName" placeholder="请输入类目名称" style="width: 400px"></el-input>
				    </el-form-item>
			</el-form>
			<div align="right">
					  <span>
			<el-button @click="updateClose">取消</el-button>
			<el-button type="primary"  @click="updateClick">确定</el-button>
					</span>
					</div>
		</el-dialog>
		
	</div>
</template>

<script>
import {multistagePageApi,addParentApi,getParentAllApi,addChildrenApi,getChildrenByParentIdApi,deleteWordByIdApi,updateByIdApi} from 'api/article'
	export default {
		computed: {
		    formatDate() {
		        return function (oldDate) {
		            // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
		            function add0(num) {
		                return num < 10 ? '0' + num : num
		            } // 个位数的值在前面补0
		            const date = new Date(oldDate)
		            const Y = date.getFullYear()
		            const M = date.getMonth() + 1
		            const D = date.getDate()
		            const h = date.getHours()
		            const m = date.getMinutes()
		            const s = date.getSeconds()
		
		            const dateString =
		                Y +
		                '-' +
		                add0(M) +
		                '-' +
		                add0(D) +
		                '  ' +
		                add0(h) +
		                ':' +
		                add0(m) +
		                ':' +
		                add0(s)
		            return dateString
		        }
		    }
		},
		data() {
			return{
				options:[],
				upOptions:{},
				updateForm:{
					id:"",
					workName:"",
					parentName:"",
					isParent:false
				},
				addParentForm:{
					wordName:"",
					wordType:0
				},
				addChildrenForm:{
					parentCode:"",
					wordName:""
				},
				addParentFormRules: {
					wordName: [{required: true, message: '一级目录名称不能为空', trigger: 'blur'}],
					wordType: [{required: true, message: '类型不能为空' ,trigger: 'blur'}],
				},
				addChildrenFormRules: {
					parentCode: [{required: true, message: '一级目录不能为空', trigger: 'blur'}],
					wordName: [{required: true, message: '二级目录名称不能为空', trigger: 'blur'}]
				},
				updateFormRules:{
					parentCode: [{required: true, message: '一级目录名称不能为空', trigger: 'blur'}],
					wordName: [{required: true, message: '目录名称不能为空', trigger: 'blur'}]
				},
				//表格加载
				tableLoading: false,
				updateDialogShow:false, //编辑显示
				//新增一级显示
				addParentDialogShow:false,
				//新增二级显示
				addChildrenDialogShow:false,
				
				pageTotal: 0,
				pageMsg: {
				    pageNum: 1,
				    pageSize: 100,
				    wordType:undefined,
				    wordName:undefined
				},
				tableData: [],
			}
		},
		mounted() {
		this.initData()
		},
		methods: {
			updateClick(){
				//确定更改目录
				
				this.$refs.updateForm.validate(valid => {
					if(valid){
						updateByIdApi({ ...this.updateForm }).then(res => {
							if(res.code == 200){
								this.$message.success("修改成功!")
							}else{
								this.$message.error(res.desc)
							}
							//刷新
							this.initData();
							this.updateClose()
							this.updateForm = this.$options.data().updateForm;
							
						})
					}
				})
				
				

			},
			updateClose(){
				this.updateDialogShow =false;
			},
			//编辑的方法
			edit(row){
				if(row.parentCode == "" || row.parentCode == undefined || row.parentCode == null){
					//一级
					this.updateForm.isParent=false;
				}else{
					//二级
					this.updateForm.isParent=true;
				}
				
				// this.updateForm.wordName = row.wordName
				this.updateForm.id = row.id
				
				this.updateDialogShow = true
				//获取一级类目数据
				getParentAllApi().then(({ result }) => {
					const classification = result
					function classifi(arr) {
					   arr.forEach(item => {

							if(item.wordType == '5'){
								item.label = item.wordName+"---有效"
							}else if(item.wordType == '6'){
								item.label = item.wordName+"---无效"
							}
					        item.value = item.code
					    })
					}
					      classifi(classification)
						this.upOptions = result
				})
			},
			//删除方法
			deleteWord(id){
				
				this.$confirm('是否确认删除', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							getChildrenByParentIdApi(id).then(res => {
								if(res.code == 200	){
									deleteWordByIdApi(id).then(res =>{
										if(res.code == 200){
											this.$message({
											  type: 'success',
											  message: '删除成功!'
											});
											this.initData();
										}else{
											this.$message({
											  type: 'error',
											  message: '删除失败!'
											});
											this.initData();
										}
									})
								}
							})
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '已取消删除'
				          });          
				        });
			},
			//新建二级目录方法
			addChildrenClick(){
				this.$refs.addChildrenForm.validate(valid => {
					if(valid){
						addChildrenApi({ ...this.addChildrenForm }).then(res => {
							if(res.code == 200){
								this.$message.success('添加成功')
								this.addChildrenForm = this.$options.data().addChildrenForm;
								this.addChildrenDialogShow = false;
								this.initData()
							}
						})
					}
				})
			},
			//新建一级目录方法
			addParentClick(){
				this.$refs.addParentForm.validate(valid => {
					if(valid){
						addParentApi({ ...this.addParentForm }).then(res => {
						        if (res.code == 200) {
						            this.$message.success('添加成功')
									this.addParentForm = this.$options.data().addParentForm;
									this.addParentDialogShow=false;
						            this.initData()
						        }
						    })
					}
				})
			},
			//新建一级目录显示方法
			addParent() {
			    this.addParentDialogShow = true;
			},
			//新建二级目录显示方法
			addChildren(){
				this.addChildrenDialogShow = true;
				
				//获取一级类目数据
				getParentAllApi().then(({ result }) => {
					const classification = result
					function classifi(arr) {
					   arr.forEach(item => {

							if(item.wordType == '5'){
								item.label = item.wordName+"---有效"
							}else if(item.wordType == '6'){
								item.label = item.wordName+"---无效"
							}
					        item.value = item.code
					    })
					}
					      classifi(classification)
						this.options = result
				})
				
				
			},
			//新建一级目录显示关闭方法
			addParentClose(){
				this.addParentDialogShow = false;
			},
			//新增二级目录显示关闭方法
			addChildrenClose(){
				this.addChildrenDialogShow = false;
			},
			searchData(bool) {
			    if (bool) {
					// console.log("进入方法"+this.pageMsg.wordType)
			        this.pageMsg.pageNum = 1
			        this.pageMsg.pageSize = 10
			    } else {
			        this.pageMsg = this.$options.data().pageMsg
			
			    }
			    this.initData()
			},
			initData(){
				//加载
				this.tableLoading = true
				const data = {
				    ...this.pageMsg
				}
				multistagePageApi(this.pageMsg).then(res => {
				    if (res.code === 200) {
				        let { total, records } = res.result
				        this.tableData = records
				        this.pageTotal = total
				    } else {
				        this.$message({
				            type: 'error',
				            message: res.desc
				        })
				    }
				    this.tableLoading = false
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
  .channelManagement {
    width: 100%;
    .channelManagement-head {
      padding: 10px 20px;
      background: #fff;
    }
	.box-card {
	  width: calc(100% - 60px);
	  padding: 10px;
	  margin: 20px auto;
	}
  }
</style>
