import apiServer from 'api/apiServer.js'

/**
 * 资源管理
 */

// 获取模块树
export function getLoadModuleTree(params = {}) {
  return apiServer.get('/auth/module/loadModuleTree', params).then(res => {
    return Promise.resolve(res)
  })
}
// 添加模块信息
export function saveModuleMsg(params = {}) {
  return apiServer.post('/auth/module/save', params).then(res => {
    return Promise.resolve(res)
  })
}
// 修改模块信息
export function updateModuleMsg(params = {}) {
  return apiServer.put('/auth/module/update', params).then(res => {
    return Promise.resolve(res)
  })
}
//  删除模块信息
export function delModule(params = '') {
  return apiServer.del(`/auth/module/${params}`).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 角色管理
 */

// 获取角色列表
export function getRoleList(params = {}) {
  return apiServer.get('/auth/role/page', params).then(res => {
    return Promise.resolve(res)
  })
}

// 获取角色列表
export function getRoleList1(params = {}) {
    return apiServer.get('/auth/role/page1', params).then(res => {
        return Promise.resolve(res)
    })
}
// 创建角色
export function saveRole(params = {}) {
  return apiServer.post('/auth/role/save', params).then(res => {
    return Promise.resolve(res)
  })
}
// 更新角色
export function updateRole(params = {}) {
  return apiServer.put('/auth/role/upd', params).then(res => {
    return Promise.resolve(res)
  })
}
// 移除权限
export function delRoleRight(params = {}) {
  return apiServer.del('/auth/role/permission/remove', params).then(res => {
    return Promise.resolve(res)
  })
}
// 设置权限
export function setRoleRight(params = {}) {
  return apiServer.post('/auth/role/permission/set', params).then(res => {
    return Promise.resolve(res)
  })
}
// 角色权限-视图
export function roleRightView(params = {}) {
  return apiServer.get('/auth/role/views', params).then(res => {
    return Promise.resolve(res)
  })
}
// 角色权限-菜单
export function roleRightMenu(params = '') {
  return apiServer.get(`/auth/role/menus/${params}`).then(res => {
    return Promise.resolve(res)
  })
}
// 角色启用/禁用
export function enableDisableRoleApi(params = {}) {
  return apiServer.put(`/auth/role/disable/upd`, params).then(res => {
    return Promise.resolve(res)
  })
}

/**
 * 用户管理
 */

// 用户列表查询
export function getUserList(params = {}) {
  return apiServer.get(`/auth/user/page`, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getMarketRelevanceListApi(params = {}) {
  return apiServer.get(`/client/getMarketRelevanceList`, params).then(res => {
    return Promise.resolve(res)
  })
}


export function getUserList1(params = {}) {
    return apiServer.get(`/auth/user/pageList`, params).then(res => {
        return Promise.resolve(res)
    })
}

export function getUserNewList(params = {}) {
  return apiServer.get(`/auth/user/pageNewList`, params).then(res => {
    return Promise.resolve(res)
  })
}

// 用户新增
export function addUser(params = {}) {
  return apiServer.post(`/auth/user/create`, params).then(res => {
    return Promise.resolve(res)
  })
}
// 用户编辑
export function editUser(params = {}) {
  return apiServer.put(`/auth/user/update`, params).then(res => {
    return Promise.resolve(res)
  })
}
// 用户启用/禁用
export function enableDisableUserApi(params = {}) {
  return apiServer.put(`/auth/user/status/upd`, params).then(res => {
    return Promise.resolve(res)
  })
}

// 公司列表-运营后台(导出)
export function platformExportApi(params = {}) {
  return apiServer
    .downfileStream("/auth/user/export", params)
    .then((res) => {
      return Promise.resolve(res);
    });
}
