<template>
  <div class="staris_finial">
    <div class="success" v-if="publishSuccess.status">
      <el-row>
        <el-col :span="24">
          <el-result icon="success" title="提交成功" subTitle="">
            <template slot="extra"> </template>
          </el-result>
        </el-col>
      </el-row>
      <p class="reseturl">
        提交成功后，5s内自动跳转至分发中心，如需立刻返回，请点击返回列表按钮进行返回
      </p>
      <h4>动态信息</h4>
      <div class="reason_div">
        <p class="reason_title">
          任务标题：<span>{{ publishSuccess.reason }}</span>
        </p>
        <p>
          上传人：<span>{{ publishSuccess.people }}</span>
        </p>
        <p>
          提交时间：<span>{{ new Date().toLocaleString() }}</span>
        </p>
      </div>
      <el-steps :active="2" align-center>
        <el-step title="创建动态" description=""></el-step>
        <el-step title="系统初审" description=""></el-step>
        <el-step title="媒介复审" description=""></el-step>
        <el-step title="分发成功" description=""></el-step>
      </el-steps>
      <div class="success_btns">
        <el-button
          type="primary"
          size="medium"
          @click="$router.push('/release')"
          >返回列表</el-button
        >
      </div>
    </div>
    <div class="fail" v-else>
      <el-row>
        <el-col :span="24">
          <el-result
            icon="error"
            title="提交失败"
            subTitle="请核对并修改以下信息后，再重新提交。"
          >
            <template slot="extra"> </template>
          </el-result>
          <div class="fail_text">
            <h4>您提交的内容有如下错误：</h4>
            <p>
              <i class="el-icon-warning-outline"></i>
              {{ errReason || publishSuccess.reason }}
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="success_btns">
        <el-button type="primary" size="medium" @click="closePage"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      errReason: ''
    }
  },
  props: {
    publishSuccess: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    console.log('aadasdsada')
    this.errReason = localStorage.getItem('RequestErrmessage')
    if(this.publishSuccess.status == true){

    }
  },
  methods: {
    closePage() {
      this.$emit('closePage')
    }
  }
}
</script>
<style lang="scss" scoped>
.staris_finial {
  width: 100%;
  .reseturl {
    text-align: center;
  }
  .success {
    width: 60%;
    margin: 10% auto;
    .reason_div {
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 40px;
      .reason_title {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 限制行数
        -webkit-box-orient: vertical;
      }
    }
    /deep/.el-result {
      width: 100%;
      font-weight: 800 !important;
      padding-bottom: 10px;
    }
  }
  .fail {
    width: 50%;
    margin: 10% auto;
    .el-result {
      width: 100%;
    }
    /deep/.el-result__title {
      p {
        font-weight: 600;
      }
    }
    .fail_text {
      i {
        color: red;
      }
    }
  }
  .success_btns {
    width: 100px;
    margin: 30px auto;
  }
}
</style>
