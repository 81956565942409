<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">

      <el-form
        :model="pageMsg"
        ref="formData"
        size="small"
        label-position="center"
        label-width="100px"
      >
        <el-row>
          <el-col :span='8'>
            <el-form-item label='公司名称:'>
              <el-input
                v-model="pageMsg.stockName" style="width: 150px"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
            <el-form-item label='手机号:'>
              <el-input
                v-model="pageMsg.phoneNumber" style="width: 150px"  placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
            <span>
              <el-button type='primary' size='small' @click="initData">查询</el-button>
              <el-button  size='small' @click="reset">重置</el-button>
            </span>
          </el-col>
        </el-row>

      </el-form>


      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px;margin-top: 10px"
        row-key="id"
        @selection-change="handleSelectionChange"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading">

        <el-table-column
          prop="stockName"
          label="公司名称"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="userName"
          label="姓名"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="phoneNumber"
          label="手机号"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="unsubscribeTime"
          label="退订时间"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.unsubscribeTime) }}
          </template>
        </el-table-column>


      </el-table>

      <div class="pb20"></div>

      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>

    </el-card>
  </div>
</template>

<script>
import { getUnsubscribePageApi } from 'api/stock'

export default {
  data() {
    return {
      pageMsg:{},
      tableData:[],
      tableLoading:false,
      pageTotal:0,

    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    handleSelectionChange(){

    },
    initData(){
      this.tableLoading = true
      getUnsubscribePageApi(this.pageMsg).then(res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        } else {
          this.tableData = [];
        }
        this.tableLoading = false;
      })
    },
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    },
    formatDate(dateTimeString){
      const dateTime = new Date(dateTimeString);
      const year = dateTime.getFullYear();
      const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
      const day = dateTime.getDate().toString().padStart(2, '0');
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');
      const seconds = dateTime.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
