var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-admin" },
    [
      _vm._m(0),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "text-left" },
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.pageMsg,
                    size: "small",
                    "label-position": "left",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "limitinputWidth",
                          attrs: { label: "名称：" },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.pageMsg.searchName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "searchName", $$v)
                              },
                              expression: "pageMsg.searchName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c("p", { staticStyle: { opacity: "0" } }, [_vm._v("1")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "limitinputWidth",
                          attrs: { label: "角色：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.pageMsg.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "type", $$v)
                                },
                                expression: "pageMsg.type",
                              },
                            },
                            _vm._l(_vm.stateOption, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 10 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openHandleDialog()
                            },
                          },
                        },
                        [_vm._v(" 新增角色 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
                data: _vm.tableData,
                "row-key": "id",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "header-align": "center",
                  align: "center",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "名称",
                  "header-align": "center",
                  align: "center",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remarks",
                  label: "备注",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-tooltip", [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(_vm._s(row.remarks))]
                          ),
                          _c("div", { staticClass: "tdRemarks" }, [
                            _vm._v(_vm._s(row.remarks)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "disable",
                  label: "启禁状态",
                  "header-align": "center",
                  align: "center",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !row.disable
                          ? _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v("已启用"),
                            ])
                          : _c(
                              "el-button",
                              { attrs: { type: "text", disabled: "" } },
                              [_vm._v("已禁用")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "180",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.code !== "SUPER_ADMIN" &&
                        _vm.userbtn.indexOf("ROLE_POWER") >= 0
                          ? _c(
                              "el-button",
                              {
                                style:
                                  row.code === "SUPER_ADMIN"
                                    ? "color:#7d7d7d"
                                    : "",
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled: row.code === "SUPER_ADMIN",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.lookright(row)
                                  },
                                },
                              },
                              [_vm._v(" 角色权限 ")]
                            )
                          : _vm._e(),
                        row.code !== "SUPER_ADMIN" &&
                        _vm.userbtn.indexOf("ROLE_EDIT") >= 0
                          ? _c(
                              "el-button",
                              {
                                style:
                                  row.code === "HALL_USER_ADMIN"
                                    ? "color:#7d7d7d"
                                    : "",
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled:
                                    row.code === "HALL_USER_ADMIN" ||
                                    row.code === "SUPER_ADMIN",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openHandleDialog(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          : _vm._e(),
                        row.code !== "SUPER_ADMIN"
                          ? _c(
                              "el-button",
                              {
                                style:
                                  row.code === "HALL_USER_ADMIN"
                                    ? "color:#7d7d7d"
                                    : "",
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled:
                                    row.code === "HALL_USER_ADMIN" ||
                                    row.code === "SUPER_ADMIN",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.enableDisablingRole(row)
                                  },
                                },
                              },
                              [
                                !row.disable &&
                                _vm.userbtn.indexOf("ROLE_HIDE") >= 0
                                  ? _c("span", [_vm._v("禁用")])
                                  : _vm._e(),
                                row.disable &&
                                _vm.userbtn.indexOf("ROLE_UP") >= 0
                                  ? _c("span", [_vm._v("启用")])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            visible: _vm.roleDetailVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleDetailVisible = $event
            },
            close: _vm.roleDialogClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(_vm._s(_vm.sourceDetailOperate))]
          ),
          _c(
            "el-form",
            {
              ref: "roleDetail",
              attrs: {
                model: _vm.roleFormDetail,
                rules: _vm.roleFormRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.roleFormDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleFormDetail, "name", $$v)
                      },
                      expression: "roleFormDetail.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色备注：", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", type: "textarea" },
                    model: {
                      value: _vm.roleFormDetail.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleFormDetail, "remarks", $$v)
                      },
                      expression: "roleFormDetail.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.roleDetailVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveMsg.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            visible: _vm.roleRightListVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleRightListVisible = $event
            },
            close: _vm.roleRightListDialogClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("角色权限")]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-left",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_vm._v("菜单")]
                      ),
                      _vm.roleRightListVisible
                        ? _c("el-tree", {
                            attrs: {
                              data: _vm.treeLeftdata,
                              props: _vm.treeLeftprops,
                              "default-checked-keys": _vm.defaultCheckedMenu,
                              "check-strictly": true,
                              "show-checkbox": "",
                              "node-key": "id",
                            },
                            on: {
                              "node-click": _vm.lookView,
                              "check-change": _vm.changeState,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channelManagement-head" }, [
      _c("h1", [_vm._v("角色列表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }