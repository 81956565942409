var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "text-left" },
            [
              _c(
                "el-col",
                {
                  staticClass: "text-right",
                  staticStyle: { padding: "10px 0" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.add()
                        },
                      },
                    },
                    [_vm._v(" 新增 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "名称", "header-": "" },
              }),
              _c("el-table-column", {
                attrs: { prop: "typeName", label: "模块类型", "header-": "" },
              }),
              _c("el-table-column", {
                attrs: { prop: "icon", label: "模块icon", "header-": "" },
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "模块组件", "header-": "" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-": "",
                  width: "250",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.add(row)
                              },
                            },
                          },
                          [_vm._v(" 新增 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.edit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { title: "确定删除该模块儿吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.del(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "small",
                                },
                                slot: "reference",
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            visible: _vm.sourceDetailVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sourceDetailVisible = $event
            },
            close: _vm.sourceDialogClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(_vm._s(_vm.sourceDetailOperate))]
          ),
          _c(
            "el-form",
            {
              ref: "sourceDetail",
              attrs: {
                model: _vm.sourceFormDetail,
                rules: _vm.sourceFormRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属模块：", prop: "parentId" } },
                [
                  _vm.sourceDetailVisible
                    ? _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled:
                            _vm.isunderModule || _vm.tableData.length <= 0,
                          options: _vm.tableData,
                          props: _vm.tableDataMap,
                          clearable: "",
                        },
                        model: {
                          value: _vm.sourceFormDetail.parentId,
                          callback: function ($$v) {
                            _vm.$set(_vm.sourceFormDetail, "parentId", $$v)
                          },
                          expression: "sourceFormDetail.parentId",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模块名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.sourceFormDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.sourceFormDetail, "name", $$v)
                      },
                      expression: "sourceFormDetail.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.sourceFormDetail.type != 3
                        ? "模块组件："
                        : "模块code：",
                    prop: "code",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.sourceFormDetail.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.sourceFormDetail, "code", $$v)
                      },
                      expression: "sourceFormDetail.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模块类型：", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.sourceFormDetail.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.sourceFormDetail, "type", $$v)
                        },
                        expression: "sourceFormDetail.type",
                      },
                    },
                    _vm._l(_vm.moduleType, function (item) {
                      return _c("el-option", {
                        key: parseInt(item.k),
                        attrs: { label: item.v, value: parseInt(item.k) },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模块路径：", prop: "path" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.sourceFormDetail.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.sourceFormDetail, "path", $$v)
                      },
                      expression: "sourceFormDetail.path",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模块icon：", prop: "icon" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.sourceFormDetail.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.sourceFormDetail, "icon", $$v)
                      },
                      expression: "sourceFormDetail.icon",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模块备注：", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", type: "textarea" },
                    model: {
                      value: _vm.sourceFormDetail.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.sourceFormDetail, "remarks", $$v)
                      },
                      expression: "sourceFormDetail.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.sourceDetailVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveMsg.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }