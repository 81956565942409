var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company_add" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "账户", prop: "username" } },
            [
              _c("el-input", {
                attrs: { maxLength: "11", placeholder: "输入手机号" },
                model: {
                  value: _vm.ruleForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "username", $$v)
                  },
                  expression: "ruleForm.username",
                },
              }),
            ],
            1
          ),
          !_vm.$route.query.id
            ? _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", placeholder: "设置密码" },
                    model: {
                      value: _vm.ruleForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "password", $$v)
                      },
                      expression: "ruleForm.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$route.query.id
            ? _c(
                "el-form-item",
                { attrs: { label: "密码" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: "设置密码(不填写即原密码)",
                    },
                    model: {
                      value: _vm.ruleForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "password", $$v)
                      },
                      expression: "ruleForm.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "客户名称", prop: "companyName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入公司名称" },
                model: {
                  value: _vm.ruleForm.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "companyName", $$v)
                  },
                  expression: "ruleForm.companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权益次数", prop: "equityNumber" } },
            [
              _c("el-input-number", {
                attrs: { "controls-position": "right", min: 1, max: 9999 },
                model: {
                  value: _vm.ruleForm.equityNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "equityNumber", $$v)
                  },
                  expression: "ruleForm.equityNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行业类型", prop: "industryCategoryCode" } },
            [
              _c("el-cascader", {
                attrs: { options: _vm.options },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.ruleForm.industryCategoryArr,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "industryCategoryArr", $$v)
                  },
                  expression: "ruleForm.industryCategoryArr",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "昵称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入昵称" },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国家", prop: "countryId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择国家" },
                  on: { change: _vm.guoChange },
                  model: {
                    value: _vm.ruleForm.countryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "countryId", $$v)
                    },
                    expression: "ruleForm.countryId",
                  },
                },
                _vm._l(_vm.guo, function (item) {
                  return _c("el-option", {
                    key: item.Name,
                    attrs: { label: item.Name, value: item.Code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "省份", prop: "provinceId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择省份" },
                  on: { change: _vm.shengChange },
                  model: {
                    value: _vm.ruleForm.provinceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "provinceId", $$v)
                    },
                    expression: "ruleForm.provinceId",
                  },
                },
                _vm._l(_vm.sheng, function (item) {
                  return _c("el-option", {
                    key: item.Name,
                    attrs: { label: item.Name, value: item.Code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "城市", prop: "cityId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择城市" },
                  on: { change: _vm.shiChange },
                  model: {
                    value: _vm.ruleForm.cityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "cityId", $$v)
                    },
                    expression: "ruleForm.cityId",
                  },
                },
                _vm._l(_vm.shi, function (item) {
                  return _c("el-option", {
                    key: item.Name,
                    attrs: { label: item.Name, value: item.Code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "街道地址", prop: "streetAddress" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入具体地址" },
                model: {
                  value: _vm.ruleForm.streetAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "streetAddress", $$v)
                  },
                  expression: "ruleForm.streetAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "comImage",
              attrs: { label: "logo", prop: "logoDocId" },
            },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.uploadUrl,
                    headers: _vm.myHeaders,
                    "list-type": "picture-card",
                    accept: "image/jpeg,image/jpg,image/png",
                    "on-change": _vm.uploadChange,
                    "on-success": _vm.uploadReturn,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-exceed": _vm.handleExceed,
                    limit: 1,
                    "file-list": _vm.fileList,
                    "on-remove": _vm.companyUploadRemove,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "comImage",
              attrs: { label: "上传附件", prop: "docIdList" },
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    drag: "",
                    action: _vm.uploadUrl,
                    headers: _vm.myHeaders,
                    multiple: "",
                    "on-change": _vm.docIdListuploadChange,
                    "on-success": _vm.docIdListuploadReturn,
                    "before-upload": _vm.docIdListbeforeAvatarUpload,
                    "on-exceed": _vm.docIdListhandleExceed,
                    limit: 5,
                    "file-list": _vm.docIdList,
                    "on-remove": _vm.docIdListuploadRemove,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(" 支持扩展名：.rar .zip .doc .docx .pdf .jpg... ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.editId ? "立即编辑" : "立即创建"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }