<template>
  <div class="role-admin">
    <div class="channelManagement-head">
      <h1>角色列表</h1>
    </div>
    <el-card class="box-card">
      <el-row class="text-left">
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
        >
          <el-col :span="6">
            <el-form-item label="名称：" class="limitinputWidth">
              <el-input clearable v-model="pageMsg.searchName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1"> <p style="opacity: 0">1</p> </el-col>
          <el-col :span="6">
            <el-form-item label="角色：" class="limitinputWidth">
              <el-select v-model="pageMsg.type" placeholder="请选择">
                <el-option
                  v-for="item in stateOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                clearable
                v-model="pageMsg.roleId"
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="10" class="text-right">
            <el-button type="primary" size="small" @click="searchData(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData(false)">
              重置
            </el-button>

            <el-button
              type="primary"
              size="small"
              @click.native.prevent="openHandleDialog()"
            >
              新增角色
            </el-button>
          </el-col>
        </el-form>
      </el-row>
      <el-table
        v-loading="tableLoading"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
      >
        <el-table-column
          prop="id"
          label="ID"
          header-align="center"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          header-align="center"
          align="center"
          width="200"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="code"
          label="角色code"
          header-align="center"
          align="center"
          width="200"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="typeName"
          label="角色类型"
          header-align="center"
          align="center"
          width="160"
        >
        </el-table-column> -->
        <el-table-column
          prop="remarks"
          label="备注"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            <!-- 悬浮提示 -->
            <el-tooltip>
              <!-- 注意 显示单元格浮框时 v-model 和 disabled 属性要一起使用才有效果 -->
              <div slot="content">{{ row.remarks }}</div>
              <div class="tdRemarks">{{ row.remarks }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="disable"
          label="启禁状态"
          header-align="center"
          align="center"
          width="140"
        >
          <template slot-scope="{ row }">
            <el-button type="text" v-if="!row.disable">已启用</el-button>
            <el-button type="text" v-else disabled>已禁用</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="lookright(row)"
              :style="row.code === 'SUPER_ADMIN' ? 'color:#7d7d7d' : ''"
              :disabled="row.code === 'SUPER_ADMIN'"
              v-if="
                row.code !== 'SUPER_ADMIN' && userbtn.indexOf('ROLE_POWER') >= 0
              "
            >
              角色权限
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.native.prevent="openHandleDialog(row)"
              :style="row.code === 'HALL_USER_ADMIN' ? 'color:#7d7d7d' : ''"
              :disabled="
                row.code === 'HALL_USER_ADMIN' || row.code === 'SUPER_ADMIN'
              "
              v-if="
                row.code !== 'SUPER_ADMIN' && userbtn.indexOf('ROLE_EDIT') >= 0
              "
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              :style="row.code === 'HALL_USER_ADMIN' ? 'color:#7d7d7d' : ''"
              :disabled="
                row.code === 'HALL_USER_ADMIN' || row.code === 'SUPER_ADMIN'
              "
              @click.native.prevent="enableDisablingRole(row)"
              v-if="row.code !== 'SUPER_ADMIN'"
            >
              <span v-if="!row.disable && userbtn.indexOf('ROLE_HIDE') >= 0"
                >禁用</span
              >
              <span v-if="row.disable && userbtn.indexOf('ROLE_UP') >= 0"
                >启用</span
              >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      width="30%"
      :visible.sync="roleDetailVisible"
      @close="roleDialogClose"
      :close-on-click-modal="false"
    >
      <div slot="title" class="text-left">{{ sourceDetailOperate }}</div>
      <el-form
        ref="roleDetail"
        :model="roleFormDetail"
        :rules="roleFormRules"
        label-width="100px"
      >
        <el-form-item label="角色名称：" prop="name">
          <el-input clearable v-model="roleFormDetail.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="角色code：" prop="code">
          <el-input clearable v-model="roleFormDetail.code"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="角色类型：" prop="type">
          <el-select
            v-model="roleFormDetail.type"
            style="width: 100%"
            v-if="sourceDetailOperate === '编辑角色'"
            disabled
          >
            <el-option
              v-for="item in roleTypeList"
              :key="parseInt(item.k)"
              :label="item.v"
              :value="parseInt(item.k)"
            ></el-option>
          </el-select>
          <el-select v-model="roleFormDetail.type" style="width: 100%" v-else>
            <el-option
              v-for="item in onSystemRoleType"
              :key="parseInt(item.k)"
              :label="item.v"
              :value="parseInt(item.k)"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="启停状态：" prop="disable">
          <el-radio-group v-model="roleFormDetail.disable">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="角色备注：" prop="remarks">
          <el-input
            clearable
            type="textarea"
            v-model="roleFormDetail.remarks"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button
          size="small"
          @click.native.prevent="roleDetailVisible = false"
        >
          取 消
        </el-button>
        <el-button size="small" type="primary" @click.native.prevent="saveMsg">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 角色权限列表 -->
    <el-dialog
      width="30%"
      :visible.sync="roleRightListVisible"
      @close="roleRightListDialogClose"
      :close-on-click-modal="false"
    >
      <!-- <div slot="title" class="text-left">角色权限 - {{ roleName }}</div> -->
      <div slot="title" class="text-left">角色权限</div>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card>
            <div slot="header" class="text-left">菜单</div>
            <el-tree
              v-if="roleRightListVisible"
              :data="treeLeftdata"
              :props="treeLeftprops"
              @node-click="lookView"
              @check-change="changeState"
              :default-checked-keys="defaultCheckedMenu"
              :check-strictly="true"
              show-checkbox
              node-key="id"
            ></el-tree>
          </el-card>
        </el-col>
        <!-- <el-col :span="12">
          <el-card>
            <div slot="header" class="text-left">视图</div>
            <el-tree
              v-if="roleRightListVisible"
              :data="treeRightdata"
              :props="treeRightprops"
              @check-change="changeState"
              show-checkbox
              :check-strictly="true"
              :default-checked-keys="defaultChecked"
              node-key="id"
            ></el-tree>
          </el-card>
        </el-col> -->
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoleList,
  saveRole,
  updateRole,
  delRoleRight,
  setRoleRight,
  roleRightView,
  roleRightMenu,
  enableDisableRoleApi
} from 'api/rightsetting'
// import { lookDic } from "api/common";
export default {
  name: 'rolelist',
  data() {
    return {
      tableLoading: false,
      roleDetailVisible: false,
      roleFormDetail: {
        name: '',
        code: '',
        type: '非系统角色',
        // disable: "",
        remarks: ''
      },
      roleFormRules: {
        name: [{ required: true, message: '角色名称不能为空', trigger: blur }],
        code: [{ required: true, message: '角色code不能为空', trigger: blur }]
        // disable: [
        //   { required: true, message: "启停状态不能为空", trigger: blur },
        // ],
      },
      tableData: [],
      sourceDetailOperate: '',
      roleRightListVisible: false,
      roleName: '',
      treeLeftdata: [],
      treeLeftprops: {
        label: 'name'
      },
      treeRightdata: [],
      treeRightprops: {
        label: 'name'
      },
      pageMsg: {
        pageSize: 10,
        pageNum: 1
      },
      pageTotal: 0,
      onSystemRoleType: [], // 非系统角色
      stateOption: [],
      roleTypeList: [
        {
          groupKey: 'role_type_enum',
          groupName: '角色类型(后台运营)',
          id: 58,
          k: '1',
          sort: 1,
          v: '系统角色'
        },
        {
          groupKey: 'role_type_enum',
          groupName: '角色类型(后台运营)',
          id: 59,
          k: '2',
          sort: 2,
          v: '非系统角色'
        }
      ], //角色类型
      currentRole: '', //当前操作的
      defaultChecked: [], // 默认勾选id
      defaultCheckedMenu: [] // 默认勾选菜单id
    }
  },
  mounted() {
    this.initData()
    this.initRoles()
  },
  watch: {
    sourceDetailOperate(type) {
      if (type === '新增角色') {
        const onlyType = this.roleTypeList.filter(item => {
          // 过滤掉系统角色
          if (item.k == 2) {
            return item
          }
        })
        this.onSystemRoleType = onlyType
      }
    }
  },
  methods: {
    initData() {
      // v-if="sourceDetailOperate === '编辑角色'"
      // let sessionDicRoleType = sessionStorage.getItem("DIC_ROLETYPEENUM");
      // let roleTypeArr = JSON.parse(sessionStorage.getItem("DIC_ROLETYPEENUM"));
      // if (sessionDicRoleType) {
      //   this.roleTypeList = roleTypeArr;
      // } else {
      //   lookDic("role_type_enum").then((res) => {
      //     if (res.code === 200) {
      //       if (this.sourceDetailOperate === "新增角色") {
      //         this.roleTypeList = [res.result[1]];
      //       }
      //       this.roleTypeList = res.result;
      //       sessionStorage.setItem(
      //         "DIC_ROLETYPEENUM",
      //         JSON.stringify(res.result)
      //       );
      //     } else {
      //       this.$message({
      //         message: res.desc,
      //         type: "error",
      //       });
      //     }
      //   });
      // }
      this.initList({ ...this.pageMsg })
    },
    async initList(pageMsg = {}) {
      this.tableLoading = true
      await getRoleList(pageMsg).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          let { pageSize, pageNum, total, records } = res.result
          this.tableData = records
          this.pageTotal = total
          // this.pageMsg = { pageSize, pageNum, total }
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    initRoles() {
      getRoleList().then(res => {
        this.stateOption = res.result.records
      })
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    // 编辑新增 dialog
    openHandleDialog(data = '') {
      // 判断是否是模块下新增
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!data) {
        this.roleFormDetail = { ...data }
        this.roleFormDetail.type = data.type
        this.roleFormDetail.disable = this.roleFormDetail.disable ? 1 : 0
        this.sourceDetailOperate = '编辑角色'
      } else {
        this.sourceDetailOperate = '新增角色'
      }
      this.roleDetailVisible = true
    },
    // 启用禁用
    enableDisablingRole(data = '') {
      let disable = data.disable ? 0 : 1 // 启用传0
      // !row.disable 去禁用 disable为0
      enableDisableRoleApi({ id: data.id, disable }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: !disable ? '启用成功' : '禁用成功',
            type: 'success'
          })
          this.initList({ pageSize: 10, pageNum: 1, total: 0 })
        } else {
          // this.$message({
          //   message: res.desc,
          //   type: 'error'
          // })
        }
      })
    },
    handleSizeChange(val) {
      this.pageMsg.pageSize = val
      this.pageMsg.pageNum = 1
      let { pageSize, pageNum } = this.pageMsg
      let sendData = { page: pageNum, size: pageSize }
      this.initList(sendData)
    },
    handleCurrentChange(val) {
      this.pageMsg.pageNum = val
      let { pageSize, pageNum } = this.pageMsg
      let sendData = { page: pageNum, size: pageSize }
      this.initList(sendData)
    },
    roleDialogClose() {
      this.$refs.roleDetail.resetFields()

      for (let item in this.roleFormDetail) {
        delete this.roleFormDetail[item]
      }
    },
    saveMsg() {
      if (this.roleFormDetail.code) {
      } else {
        this.roleFormDetail.code = this.roleFormDetail.name
        this.roleFormDetail.type = 2
      }
      this.$refs.roleDetail.validate(valid => {
        if (valid) {
          // if (this.roleFormDetail.type == '非系统角色') {
          // }
          if (this.roleFormDetail.id) {
            updateRole(this.roleFormDetail).then(res => {
              this.roleDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  message: '更新成功',
                  type: 'success'
                })
                this.initList({
                  pageSize: 10,
                  pageNum: 1,
                  total: 0
                })
              }
            })
          } else {
            saveRole(this.roleFormDetail).then(res => {
              this.roleDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.initList({
                  pageSize: 10,
                  pageNum: 1,
                  total: 0
                })
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    lookView(data) {
      if (data.children && data.children.length > 0) {
        return
      }
      roleRightView({ menuId: data.id, roleId: this.currentRole }).then(res => {
        if (res.code === 200) {
          this.roleRightListVisible = true
          this.defaultChecked = [] // 先置空
          res.result.forEach(val => {
            if (val.checked) {
              this.defaultChecked.push(val.id)
            }
            if (val.children.length) {
              val.children.forEach(e => {
                if (e.checked) {
                  this.defaultChecked.push(e.id)
                }
              })
            }
          })
          this.treeRightdata = res.result
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    lookright(data = {}) {
      let lookRightLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.currentRole = data.id
      roleRightMenu(data.id).then(res => {
        lookRightLoading.close()
        if (res.code === 200) {
          this.roleRightListVisible = true
          this.treeLeftdata = res.result
          this.roleName = data.typeName
          this.defaultCheckedMenu = [] // 先置空
          console.log(res.result);
          res.result.forEach(val => {
            if (val.checked == true) {
              this.defaultCheckedMenu.push(val.id)
            }
            if (val.children.length) {
              val.children.forEach(e => {
                if (e.checked == true) {
                  this.defaultCheckedMenu.push(e.id)
                }
                if (e.children.length) {
                  // if (e.checked == true) {
                  //   this.defaultCheckedMenu.push(e.id)
                  // }
                  e.children.forEach(v => {
                    if (v.checked == true) {
                      this.defaultCheckedMenu.push(v.id)
                    }
                    // if (v.children.length) {
                    //   if (v.checked == true) {
                    //     this.defaultCheckedMenu.push(v.id)
                    //   }
                    // }
                  })
                }
              })
            }
          })
          console.log(this.defaultCheckedMenu)
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    changeState(data, node) {
      if (!node) {
        // true change to false
        delRoleRight({ moduleId: data.id, roleId: this.currentRole }).then(
          res => {
            if (res.code !== 200) {
              this.$message({
                type: 'error',
                message: res.desc
              })
            }
          }
        )
      } else {
        // false change to true
        setRoleRight({ moduleId: data.id, roleId: this.currentRole }).then(
          res => {
            if (res.code !== 200) {
              this.$message({
                type: 'error',
                message: res.desc
              })
            }
          }
        )
      }
    },
    roleRightListDialogClose() {
      this.treeRightdata = []
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog .el-card__body {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}
// /deep/ .el-dialog__header {
// border-bottom: solid 1px #eee !important;
// }
.tdRemarks {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.role-admin {
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>
