var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "staris_finial" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { sm: 12, lg: 6 } },
            [
              _c(
                "el-result",
                {
                  attrs: {
                    icon: "success",
                    title: "成功提示",
                    subTitle: "请根据提示进行操作",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "extra" },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary", size: "medium" } },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-steps",
        { attrs: { active: 2, "align-center": "" } },
        [
          _c("el-step", {
            attrs: {
              title: "步骤1",
              description: "这是一段很长很长很长的描述性文字",
            },
          }),
          _c("el-step", {
            attrs: {
              title: "步骤2",
              description: "这是一段很长很长很长的描述性文字",
            },
          }),
          _c("el-step", {
            attrs: {
              title: "步骤3",
              description: "这是一段很长很长很长的描述性文字",
            },
          }),
          _c("el-step", {
            attrs: {
              title: "步骤4",
              description: "这是一段很长很长很长的描述性文字",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }