<template>
  <div class="mine">
    <el-form label-width="100px">
      <div class="mine-form">
        <el-row>
          <el-col :span="16">
            <el-form-item label="账户">
              <el-input
                placeholder="请输入内容"
                v-model="username"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="密码">
              <el-button type="primary" @click="uploadBtn">修改密码 </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="昵称">
              <el-input
                placeholder="请输入昵称"
                v-model="name"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-form-item label="头像">
          <el-upload
            :action="uploadUrl"
            :headers="myHeaders"
            list-type="picture-card"
            accept="image/jpeg,image/jpg,image/png"
            :on-change="uploadChange"
            :on-success="uploadReturn"
            :before-upload="beforeAvatarUpload"
            :on-exceed="handleExceed"
            :limit="1"
            :file-list="fileList"
            :on-remove="companyUploadRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import constant from 'constant'
import { editFaceApi, getUserInfoApi } from 'api/user/index'
import { editUser } from 'api/rightsetting'
export default {
  data() {
    return {
      id: null,
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      username: '',
      name: '',
      faceDocId: null,
      fileList: [],
      roleId: null
    }
  },
  created() {
    getUserInfoApi().then(res => {
      this.username = res.result.username
      this.name = res.result.name
      this.id = res.result.id
      this.roleId = res.result.rolesInfo[0].id
      this.fileList = [
        {
          url: res.result.faceDoc.url,
          name: res.result.faceDoc.docName,
          id: res.result.faceDoc.id
        }
      ]
    })
  },
  methods: {
    uploadBtn() {
      // this.$store.commit('user/SET_UPDPWDDIALOG', true)
      // this.$router.push('/forget')
      this.$router.push('/edit')
    },

    uploadChange(file, fileList) {
      this.fileList = fileList
      console.log(this.fileList)
    },
    uploadReturn(res) {
      if (res.code === 200) {
        this.faceDocId = res.result.id
        editFaceApi({
          faceDocId: this.faceDocId
          // id: this.id,
          // name: this.name,
          // username: this.username,
          // roleId: this.roleId
        }).then(item => {
          if (item.code == 200) {
            this.$message.success('修改成功')
          }
          console.log(item)
        })
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    },
    handleExceed() {
      this.$message.warning(`当前限制上传 1 张图片`)
    },
    companyUploadRemove(file) {
      this.faceDocId = null
      this.fileList = this.fileList.filter(item => {
        return item.url != file.url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mine {
  padding: 30px;
  margin: 20px;
  background: #fff;
  border-radius: 4px;
  padding-bottom: 100px;
  .el-form {
    display: flex;
  }
  .mine-form {
    width: 600px;
  }
}
</style>
