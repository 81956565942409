import apiServer from 'api/apiServer.js'

// 媒介管理  列表查询
export function channelPageApi(params = {}) {
  let url = `/operate/channel/page` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// 媒介管理  新增媒介
export function channelAddApi(params = {}) {
  let url = `/operate/channel/add` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 媒介管理  编辑媒介
export function channelUpdApi(params = {}) {
  let url = `/operate/channel/upd` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


//根据媒介id获取子类
export function getChannelChildrenByIdApi(params = {}) {
  let url = `/operate/channel/type/getChannelChildrenById/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 获取媒介详情
export function channelDetailApi(params = {}) {
  let url = `/operate/channel/${params}` 
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 修改媒介状态-修改为冻结
export function frozenApi(params = {}) {
  let url = `/operate/channel/updFreezeState/frozen/${params}` 
  return apiServer.put(url).then(res => {
    return Promise.resolve(res)
  })
}
// 修改媒介状态-修改为正常
export function normalApi(params = {}) {
  let url = `/operate/channel/updFreezeState/normal/${params}` 
  return apiServer.put(url).then(res => {
    return Promise.resolve(res)
  })
}

// 编辑媒介权益
export function channelUpdEquityApi(params = {}) {
  let url = `/operate/channel/upd/equity` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function channelUpdPLFPApi(params = {}) {
    let url = `/operate/channel/selectPlfp` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}
export function channelUpdPLJCApi(params = {}) {
    let url = `/operate/channel/selectPljc` //post methods
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

///
// 获取终端类型
export function validTerminalApi() {
  let url = `/operate/channel/validTerminal` 
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

// 媒介列表-运营后台(导出)
export function platformExportApi(params = {}) {
  return apiServer
    .downfileStream("/operate/channel/export", params)
    .then((res) => {
      return Promise.resolve(res);
    });
}

// 获取有效的API类型
export function getValidTypeApi() {
  let url = `/operate/channel/type/getValidType` 
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}


//获取所有媒介类型
export function getChannelInfoApi() {
  let url = `/operate/channel/type/getChannelInfo`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//获取所有媒介类型最新
export function getChannelInfoNewApi() {
  let url = `/operate/channel/type/getChannelInfoNew`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

//保存最新排序
export function upSequenceApi(params = {}) {
  let url = `/operate/channel/type/upSequence`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//保存最新的子类排序
export function upChildrenSequenceApi(params = {}) {
  let url = `/operate/channel/type/upChildrenSequence`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//保存最新排序New
export function upSequenceNewApi(params = {}) {
  let url = `/operate/channel/type/upSequenceNew`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function addRelevanceApi(params = {}) {
  let url = `/operate/channel/relevance/addRelevance`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function queryManageRelevanceApi(params = {}) {
  let url = `/operate/channel/relevance/queryManageRelevance`
  return apiServer.get(url, {params}).then(res => {
    return Promise.resolve(res)
  })
}

export function queryPushListApi(params = {}) {
  let url = `/operate/channel/relevance/queryPushList/${params}`
  return apiServer.get(url, {params}).then(res => {
    return Promise.resolve(res)
  })
}


export function queryRelevancePushListApi(params = {}) {
  let url = `/operate/channel/relevance/queryRelevancePushList/${params}`
  return apiServer.get(url, {params}).then(res => {
    return Promise.resolve(res)
  })
}

export function upPushApiRelevanceApi(params = {}) {
  let url = `/operate/channel/relevance/upPushApiRelevance`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

