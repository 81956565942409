import { render, staticRenderFns } from "./index.vue?vue&type=template&id=aaa5e7c6&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=aaa5e7c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa5e7c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\DtbWork\\action\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aaa5e7c6')) {
      api.createRecord('aaa5e7c6', component.options)
    } else {
      api.reload('aaa5e7c6', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=aaa5e7c6&scoped=true&", function () {
      api.rerender('aaa5e7c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/basicWord/shield/index.vue"
export default component.exports