var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.pageMsg,
                size: "small",
                "label-position": "left",
                "label-width": "60px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "简称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入公司简称",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.getPinYin(_vm.pageMsg.stockName)
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchData(true)
                              },
                            },
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "市场:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              model: {
                                value: _vm.pageMsg.contentType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "contentType", $$v)
                                },
                                expression: "pageMsg.contentType",
                              },
                            },
                            _vm._l(_vm.optionContent, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportContactClick },
                        },
                        [_vm._v(" 导出客户联系方式 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分配:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: { placeholder: "请选择账户名称" },
                              model: {
                                value: _vm.pageMsg.userId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "userId", $$v)
                                },
                                expression: "pageMsg.userId",
                              },
                            },
                            _vm._l(_vm.optionUserId1, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "辖区:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.pageMsg.secBureau,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "secBureau", $$v)
                                },
                                expression: "pageMsg.secBureau",
                              },
                            },
                            _vm._l(_vm.secBureauOptions, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.pageMsg.validOrInvalidType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageMsg,
                                    "validOrInvalidType",
                                    $$v
                                  )
                                },
                                expression: "pageMsg.validOrInvalidType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("有效"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("无效"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.relevant },
                        },
                        [_vm._v("批量操作")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9, align: "left" } },
                    [
                      _vm.userbtn.indexOf("BASIC_STOCK_ADD") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.reject()
                                },
                              },
                            },
                            [_vm._v(" 添加信息 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("BASIC_STOCK_PLQY") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.plkq(_vm.pageMsg)
                                },
                              },
                            },
                            [_vm._v(" 批量启用 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("BASIC_STOCK_PLJY") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.plgb(_vm.pageMsg)
                                },
                              },
                            },
                            [_vm._v(" 批量禁用 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("BASIC_STOCK_PLFP") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.plfp(_vm.pageMsg)
                                },
                              },
                            },
                            [_vm._v(" 批量分配 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("BASIC_STOCK_PLJC") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.pljc(_vm.pageMsg)
                                },
                              },
                            },
                            [_vm._v(" 批量解除 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "margin-top": "10px",
              },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "60", content: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockName",
                  label: "公司简称",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v(_vm._s(row.stockName))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockCode",
                  label: "股票代码",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "industryCatePathName",
                  label: "行业类型",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockType",
                  label: "股票类型",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "email",
                  label: "邮箱",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "website",
                  label: "公司网址",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "secBureau",
                  label: "辖区",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validSum",
                  label: "有效条数",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invalidSum",
                  label: "无效条数",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "relatedSalesName",
                  label: "销售人员",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockStart",
                  label: "客户状态",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.trialSetClick(row)
                              },
                            },
                          },
                          [_vm._v(" 试用设置 ")]
                        ),
                        row.show === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getRelevanceInfoClick(row.id)
                                  },
                                },
                              },
                              [_vm._v(" 已关联 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-sizes": [100, 200, 300, 400],
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "新增客户信息",
            width: "800px",
            visible: _vm.passLoading1,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading1 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel",
                  attrs: {
                    rules: _vm.userFormRules,
                    model: _vm.channelForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司简称:", prop: "stockName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "215px" },
                        attrs: { placeholder: "请输入公司简称" },
                        model: {
                          value: _vm.channelForm.stockName,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "stockName", $$v)
                          },
                          expression: "channelForm.stockName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "股票代码:", prop: "stockCode" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "215px" },
                        attrs: { placeholder: "请输入股票代码" },
                        model: {
                          value: _vm.channelForm.stockCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "stockCode", $$v)
                          },
                          expression: "channelForm.stockCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "股票类型:", prop: "stockType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "auto" },
                          attrs: { placeholder: "请选择股票类型" },
                          model: {
                            value: _vm.channelForm.stockType,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm, "stockType", $$v)
                            },
                            expression: "channelForm.stockType",
                          },
                        },
                        _vm._l(_vm.optionContent, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属行业:", prop: "categoryCode" } },
                    [
                      _c("el-cascader", {
                        attrs: { options: _vm.options },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.channelForm.industryCategoryArr,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.channelForm,
                              "industryCategoryArr",
                              $$v
                            )
                          },
                          expression: "channelForm.industryCategoryArr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司网址:", prop: "website" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入公司网址" },
                        model: {
                          value: _vm.channelForm.website,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "website", $$v)
                          },
                          expression: "channelForm.website",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司邮箱:", prop: "email" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入公司邮箱" },
                        model: {
                          value: _vm.channelForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "email", $$v)
                          },
                          expression: "channelForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司所在地(省):", prop: "province" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入公司所在地" },
                        model: {
                          value: _vm.channelForm.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "province", $$v)
                          },
                          expression: "channelForm.province",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司所在地(市):", prop: "city" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入公司所在地" },
                        model: {
                          value: _vm.channelForm.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "city", $$v)
                          },
                          expression: "channelForm.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司联系方式:", prop: "phone" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入公司联系方式" },
                        model: {
                          value: _vm.channelForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "phone", $$v)
                          },
                          expression: "channelForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司办公地址:", prop: "address" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入公司办公地址" },
                        model: {
                          value: _vm.channelForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "address", $$v)
                          },
                          expression: "channelForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.channelAdd },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "修改客户信息",
            width: "1200px",
            visible: _vm.passLoading2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading2 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel1",
                  attrs: {
                    model: _vm.channelForm1,
                    rules: _vm.userFormRules1,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司简称:",
                        prop: "stockName",
                        placeholder: "请输入公司简称",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "215px" },
                        model: {
                          value: _vm.channelForm1.stockName,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "stockName", $$v)
                          },
                          expression: "channelForm1.stockName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "股票代码:",
                        prop: "stockCode",
                        placeholder: "请输入股票代码",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "215px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.channelForm1.stockCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "stockCode", $$v)
                          },
                          expression: "channelForm1.stockCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "股票类型:", prop: "stockType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "auto" },
                          attrs: { placeholder: "请选择股票类型" },
                          model: {
                            value: _vm.channelForm1.stockType,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "stockType", $$v)
                            },
                            expression: "channelForm1.stockType",
                          },
                        },
                        _vm._l(_vm.optionContent, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司客户:", prop: "isCum" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.channelForm1.isCum,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "isCum", $$v)
                            },
                            expression: "channelForm1.isCum",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { label: 0 },
                            },
                            [_vm._v("潜在")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { label: 1 },
                            },
                            [_vm._v("试用")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { label: 2 },
                            },
                            [_vm._v("签约")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司网址:",
                        prop: "website",
                        placeholder: "请输入公司网址",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.channelForm1.website,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "website", $$v)
                          },
                          expression: "channelForm1.website",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.channelForm1.contactList, function (item, i) {
                    return _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "新增联系人", prop: "contactList" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "120px",
                                "margin-right": "20px",
                              },
                              attrs: { clearable: "", placeholder: "姓名" },
                              model: {
                                value:
                                  _vm.channelForm1.contactList[i].clientName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.channelForm1.contactList[i],
                                    "clientName",
                                    $$v
                                  )
                                },
                                expression:
                                  "channelForm1.contactList[i].clientName",
                              },
                            }),
                            _c("el-input", {
                              staticStyle: {
                                width: "120px",
                                "margin-right": "20px",
                              },
                              attrs: { clearable: "", placeholder: "职务" },
                              model: {
                                value: _vm.channelForm1.contactList[i].job,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.channelForm1.contactList[i],
                                    "job",
                                    $$v
                                  )
                                },
                                expression: "channelForm1.contactList[i].job",
                              },
                            }),
                            _c("el-input", {
                              staticStyle: {
                                width: "200px",
                                "margin-right": "20px",
                              },
                              attrs: { clearable: "", placeholder: "邮箱号" },
                              model: {
                                value: _vm.channelForm1.contactList[i].email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.channelForm1.contactList[i],
                                    "email",
                                    $$v
                                  )
                                },
                                expression: "channelForm1.contactList[i].email",
                              },
                            }),
                            _c("el-input", {
                              staticStyle: {
                                width: "180px",
                                "margin-right": "20px",
                              },
                              attrs: { clearable: "", placeholder: "手机号" },
                              model: {
                                value:
                                  _vm.channelForm1.contactList[i].phoneNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.channelForm1.contactList[i],
                                    "phoneNumber",
                                    $$v
                                  )
                                },
                                expression:
                                  "channelForm1.contactList[i].phoneNumber",
                              },
                            }),
                            _c("el-input", {
                              staticStyle: {
                                width: "120px",
                                "margin-right": "20px",
                              },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入备注",
                              },
                              model: {
                                value: _vm.channelForm1.contactList[i].remark,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.channelForm1.contactList[i],
                                    "remark",
                                    $$v
                                  )
                                },
                                expression:
                                  "channelForm1.contactList[i].remark",
                              },
                            }),
                            _c("el-switch", {
                              staticStyle: { "margin-right": "20px" },
                              on: {
                                input: function ($event) {
                                  return _vm.upIsDel(i)
                                },
                              },
                              model: {
                                value: _vm.channelForm1.contactList[i].isDel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.channelForm1.contactList[i],
                                    "isDel",
                                    $$v
                                  )
                                },
                                expression: "channelForm1.contactList[i].isDel",
                              },
                            }),
                            _c("el-button", {
                              attrs: { circle: "", icon: "el-icon-plus" },
                              on: {
                                click: function ($event) {
                                  return _vm.addContactList()
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: { circle: "", icon: "el-icon-minus" },
                              on: {
                                click: function ($event) {
                                  return _vm.subContactList(i)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否发送短信", prop: "isSendMsg" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择分发报告发送周期" },
                          model: {
                            value: _vm.channelForm1.isSendMsg,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "isSendMsg", $$v)
                            },
                            expression: "channelForm1.isSendMsg",
                          },
                        },
                        _vm._l(_vm.isSendMsgOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否发送邮件", prop: "isSendEmail" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择分发报告发送周期" },
                          model: {
                            value: _vm.channelForm1.isSendEmail,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "isSendEmail", $$v)
                            },
                            expression: "channelForm1.isSendEmail",
                          },
                        },
                        _vm._l(_vm.isSendEmailOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "分发报告发送周期", prop: "sendPeriod" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择分发报告发送周期" },
                          model: {
                            value: _vm.channelForm1.sendPeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "sendPeriod", $$v)
                            },
                            expression: "channelForm1.sendPeriod",
                          },
                        },
                        _vm._l(_vm.sendPeriodOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开通前端登录:" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openClient(_vm.channelForm1.id)
                            },
                          },
                        },
                        [_vm._v("去设置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择推送媒介" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.selectPushApi(
                                _vm.channelForm1.stockName
                              )
                            },
                          },
                        },
                        [_vm._v("去设置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户状态:", prop: "stockStart" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择客户状态" },
                          model: {
                            value: _vm.channelForm1.stockStart,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "stockStart", $$v)
                            },
                            expression: "channelForm1.stockStart",
                          },
                        },
                        _vm._l(_vm.stockStartOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司邮箱:",
                        placeholder: "请输入公司邮箱",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.channelForm1.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "email", $$v)
                          },
                          expression: "channelForm1.email",
                        },
                      }),
                      _c("p", { staticStyle: { margin: "0px" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              "多个邮箱请用英文逗号隔开,注释信息请在小括号中填写"
                            ),
                          ]
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("例如:zs@163.com(张三),ls@163.com(李四)"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司联系方式:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.channelForm1.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "phone", $$v)
                          },
                          expression: "channelForm1.phone",
                        },
                      }),
                      _c("p", { staticStyle: { margin: "0px" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _vm._v(
                              "多个手机号请用英文逗号隔开,注释信息请在小括号中填写"
                            ),
                          ]
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("例如:13661398887(张三),13961399965(李四)"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司所在地(省):",
                        prop: "province",
                        placeholder: "请输入公司所在地",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.channelForm1.province,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "province", $$v)
                          },
                          expression: "channelForm1.province",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司所在地(市):", prop: "city" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.channelForm1.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "city", $$v)
                          },
                          expression: "channelForm1.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司办公地址:", prop: "address" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.channelForm1.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm1, "address", $$v)
                          },
                          expression: "channelForm1.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate1 } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading1 },
                        on: { click: _vm.channelAdd1 },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "开通前端登录",
            visible: _vm.openClientVisible,
            width: "50%",
            "before-close": _vm.openClientHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openClientVisible = $event
            },
          },
        },
        [
          _vm.isOpenClient
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "30px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("p", [_c("strong", [_vm._v("当前开通账号:")])]),
                      ]),
                      _c("el-col", { attrs: { span: 19 } }, [
                        _c("p", [
                          _c("strong", [_vm._v(_vm._s(_vm.openClientPhone))]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isOpenClient
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "30px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "openClientRef",
                      attrs: {
                        rules: _vm.openClientFormRules,
                        model: _vm.openClientForm,
                        "label-width": "150px",
                        "label-position": "left",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "请选择客户手机号:",
                            prop: "clientPhone",
                            placeholder: "请选择客户手机号",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.openClientForm.clientPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.openClientForm,
                                    "clientPhone",
                                    $$v
                                  )
                                },
                                expression: "openClientForm.clientPhone",
                              },
                            },
                            _vm._l(_vm.openClientOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.openClientHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.openClientClient } },
            [_vm._v("确 定")]
          ),
          _vm.isOpenClient
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.freezeClient } },
                [_vm._v("冻结")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑邮件",
            width: "800px",
            visible: _vm.passLoading4,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading4 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                { ref: "addChannel2", attrs: { model: _vm.contentData } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题：", prop: "subject" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.contentData.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.contentData, "subject", $$v)
                          },
                          expression: "contentData.subject",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容：", prop: "articleContent" } },
                    [
                      _c("vue-tinymce", {
                        attrs: { setting: _vm.setting },
                        model: {
                          value: _vm.contentData.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.contentData, "content", $$v)
                          },
                          expression: "contentData.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate2 } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading1 },
                        on: { click: _vm.channelAdd2 },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "800px",
            visible: _vm.passDialog,
            title: "选择分配人员",
          },
          on: {
            "update:visible": function ($event) {
              _vm.passDialog = $event
            },
            close: _vm.dialogCreate2,
          },
        },
        [
          _c(
            "el-form",
            { ref: "formName", attrs: { model: _vm.ruleModel } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分配用户:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "auto" },
                      attrs: { placeholder: "请选择分配用户" },
                      model: {
                        value: _vm.ruleModel.assignUserId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleModel, "assignUserId", $$v)
                        },
                        expression: "ruleModel.assignUserId",
                      },
                    },
                    _vm._l(_vm.optionUserId, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-bottom": "30px" } }),
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleClose2 },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm.canSubmit
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addAndEditBanner2("formName")
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "关联公司信息",
                visible: _vm.relevanceVisible,
                width: "30%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.relevanceVisible = $event
                },
              },
            },
            [
              _c("p", [_vm._v("关联人名称: " + _vm._s(_vm.userInfo.name))]),
              _c("p", [_vm._v("关联人账号: " + _vm._s(_vm.userInfo.username))]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "试用客户设置",
                visible: _vm.trialSetVisible,
                width: "50%",
                "before-close": _vm.trialSetClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.trialSetVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "trialRef",
                      attrs: {
                        model: _vm.trialSetForm,
                        rules: _vm.trialSetFormRules,
                        "label-position": _vm.labelPositions,
                        "label-width": _vm.formLabelWidths,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司简称:", prop: "stockName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.trialSetForm.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.trialSetForm, "stockName", $$v)
                              },
                              expression: "trialSetForm.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "选择试用时间:", prop: "trialTime" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: _vm.valueChangeClick },
                              model: {
                                value: _vm.trialSetForm.trialTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trialSetForm, "trialTime", $$v)
                                },
                                expression: "trialSetForm.trialTime",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: 0, border: "" } },
                                [_vm._v("按月")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: 1, border: "" } },
                                [_vm._v("按区间")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.trialSelectShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择试用时间:",
                                prop: "trialMonth",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.trialSetForm.trialMonth,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.trialSetForm,
                                        "trialMonth",
                                        $$v
                                      )
                                    },
                                    expression: "trialSetForm.trialMonth",
                                  },
                                },
                                _vm._l(_vm.trialOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.trialPickerShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择试用日期:",
                                prop: "trialDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间",
                                },
                                model: {
                                  value: _vm.trialSetForm.trialDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trialSetForm, "trialDate", $$v)
                                  },
                                  expression: "trialSetForm.trialDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.trialSetVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.trialSet } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "当前客户试用时间",
                visible: _vm.viewVisible,
                width: "25%",
                "before-close": _vm.viewClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.viewVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "试用起始日期:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.trialTimeStart,
                              callback: function ($$v) {
                                _vm.trialTimeStart = $$v
                              },
                              expression: "trialTimeStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "试用结束日期:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "215px" },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.trialTimeEnd,
                              callback: function ($$v) {
                                _vm.trialTimeEnd = $$v
                              },
                              expression: "trialTimeEnd",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.viewVisible = false
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.anewSet } },
                    [_vm._v("重新设置")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.resetClick },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "批量添加/删除销售人",
                visible: _vm.relevantVisible,
                width: "50%",
                "before-close": _vm.relevantClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.relevantVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "relevantRef",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                    model: _vm.relevantForm,
                    rules: _vm.relevantRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择辖区:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.relevantForm.secBureau,
                            callback: function ($$v) {
                              _vm.$set(_vm.relevantForm, "secBureau", $$v)
                            },
                            expression: "relevantForm.secBureau",
                          },
                        },
                        _vm._l(_vm.secBureauOptions, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择行业:" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.options,
                          props: { checkStrictly: "true" },
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.relevantForm.industryCategoryArr,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.relevantForm,
                              "industryCategoryArr",
                              $$v
                            )
                          },
                          expression: "relevantForm.industryCategoryArr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择客户状态:", prop: "stockType" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.relevantForm.stockType,
                            callback: function ($$v) {
                              _vm.$set(_vm.relevantForm, "stockType", $$v)
                            },
                            expression: "relevantForm.stockType",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "0" } }, [
                            _vm._v("试用"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "1" } }, [
                            _vm._v("签约"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "3" } }, [
                            _vm._v("潜在"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "4" } }, [
                            _vm._v("全部"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售:", prop: "relevantInfo" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.relevanInfoUp },
                          model: {
                            value: _vm.relevantForm.radio,
                            callback: function ($$v) {
                              _vm.$set(_vm.relevantForm, "radio", $$v)
                            },
                            expression: "relevantForm.radio",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("选择"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("填写信息"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isAllocation
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "请选择:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择账户名称" },
                              model: {
                                value: _vm.relevantForm.marketId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.relevantForm, "marketId", $$v)
                                },
                                expression: "relevantForm.marketId",
                              },
                            },
                            _vm._l(_vm.marketOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.marketName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAllocation
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px" },
                                attrs: { placeholder: "请输入姓名" },
                                model: {
                                  value: _vm.relevantForm.relevantName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.relevantForm,
                                      "relevantName",
                                      $$v
                                    )
                                  },
                                  expression: "relevantForm.relevantName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "职务" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px" },
                                attrs: { placeholder: "请输入职务" },
                                model: {
                                  value: _vm.relevantForm.relevantJob,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.relevantForm,
                                      "relevantJob",
                                      $$v
                                    )
                                  },
                                  expression: "relevantForm.relevantJob",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮箱" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px" },
                                attrs: { placeholder: "请输入邮箱" },
                                model: {
                                  value: _vm.relevantForm.relevantEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.relevantForm,
                                      "relevantEmail",
                                      $$v
                                    )
                                  },
                                  expression: "relevantForm.relevantEmail",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px" },
                                attrs: { placeholder: "请输入手机号" },
                                model: {
                                  value: _vm.relevantForm.relevantPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.relevantForm,
                                      "relevantPhone",
                                      $$v
                                    )
                                  },
                                  expression: "relevantForm.relevantPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px" },
                                attrs: { placeholder: "请输入备注" },
                                model: {
                                  value: _vm.relevantForm.relevantRemark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.relevantForm,
                                      "relevantRemark",
                                      $$v
                                    )
                                  },
                                  expression: "relevantForm.relevantRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.relevantSubmitForm()
                          },
                        },
                      },
                      [_vm._v("添加")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.relevantDelForm()
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.relevantReset()
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                    _c("el-button", { on: { click: _vm.relevantCloseForm } }, [
                      _vm._v("取消"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择推送媒介",
            visible: _vm.selectPushApiVisible,
            width: "50%",
            "before-close": _vm.selectPushApiVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectPushApiVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.selectPushApiForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "媒介列表:" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { input: _vm.selectPushApiFormChange },
                      model: {
                        value: _vm.selectPushApiForm.pushApiList,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectPushApiForm, "pushApiList", $$v)
                        },
                        expression: "selectPushApiForm.pushApiList",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                        },
                        _vm._l(_vm.pushApiList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticStyle: {
                                "flex-basis": "20%",
                                "box-sizing": "border-box",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c("el-checkbox", {
                                attrs: { label: item.apiParentName },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.noPushRelevanceCli },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                  _c(
                    "el-button",
                    { on: { click: _vm.selectPushApiVisibleClose } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }