import apiServer from 'api/apiServer.js'

export function cooperativePartnerPageApi(params = {}) {
  let url = `/operate/cooperativePartner/page`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// 新增合作伙伴
export function cooperativePartnerAddApi(params = {}) {
  let url = `/operate/cooperativePartner/add`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 删除合作伙伴
export function cooperativePartnerDelApi(params = {}) {
  let url = `/operate/cooperativePartner/delBatch`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 更新合作伙伴
export function cooperativePartnerUpdApi(params = {}) {
  let url = `/operate/cooperativePartner/upd`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

//获取动态热词
export function dynamicHotWordsApi(params = '') {
  return apiServer
    .get('/operate/website/data/dynamicHotWords', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 轮播图编辑
export function carouselChartApi(params = {}) {
  let url = `/operate/website/data/edit/carouselChart`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 编辑动态热词
export function dynamicHotWordsEditApi(params = {}) {
  let url = `/operate/website/data/edit/dynamicHotWords`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 编辑企业动态
export function enterpriseDynamicsEditApi(params = {}) {
  let url = `/operate/website/data/edit/enterpriseDynamics`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 编辑企业发布数量设置
export function enterpriseReleaseDataEditApi(params = {}) {
  let url = `/operate/website/data/edit/enterpriseReleaseData`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 获取企业动态
export function enterpriseDynamics(params = '') {
  return apiServer
    .get('/operate/website/data/enterpriseDynamics', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 获取企业发布数量设置
export function enterpriseReleaseData(params = '') {
  return apiServer
    .get('/operate/website/data/enterpriseReleaseData', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 获取轮播图
export function getCarouselChart(params = '') {
  return apiServer
    .get('/operate/website/data/getCarouselChart', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 新增新闻咨询
export function newsConsultAddApi(params = {}) {
  let url = `/operate/newsConsult/add`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 批量删除 新闻咨询
export function delBatchApi(params = {}) {
  let url = `/operate/newsConsult/delBatch`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 获取新闻咨询内容
export function newsConsultDetailApi(params = '') {
  return apiServer.get(`/operate/newsConsult/details/${params}`).then(res => {
    return Promise.resolve(res)
  })
}

// 编辑新闻咨询
export function newsConsultEditApi(params = {}) {
  let url = `/operate/newsConsult/edit`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 表单导出  新闻咨询
export function newsConsultExportApi(params = {}) {
  return apiServer
    .downfileStream('/operate/newsConsult/export', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 列表查询 新闻咨询
export function newsConsultPageApi(params = {}) {
  let url = `/operate/newsConsult/page`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 新增招聘职位
export function positionRecruitAddApi(params = {}) {
  let url = `/operate/positionRecruit/add`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 批量删除  招聘职位
export function positionRecruitDelApi(params = {}) {
  let url = `/operate/positionRecruit/delBatch`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 获取职位详情 
export function positionRecruitDetailApi(params = '') {
  return apiServer
    .get(`/operate/positionRecruit/details/${params}`)
    .then(res => {
      return Promise.resolve(res)
    })
}

// 编辑职位招聘
export function positionRecruitEditApi(params = {}) {
  let url = `/operate/positionRecruit/edit`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 列表查询 招聘职位
export function positionRecruitPageApi(params = {}) {
  let url = `/operate/positionRecruit/page`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 表单导出  招聘职位
export function positionRecruitExportApi(params = {}) {
  return apiServer
    .downfileStream('/operate/positionRecruit/export', params)
    .then(res => {
      return Promise.resolve(res)
    })
}

