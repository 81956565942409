<template>
    <div class="channelManagement">
        <div class="channelManagement-head">
        </div>
        <el-card class="box-card">
            <el-form
                    :model="pageMsg"
                    ref="formData"
                    size="small"
                    label-position="left"
                    label-width="75px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item
                                label="合同名称:"
                        >
                            <el-input   v-model="pageMsg.stockName" style="width: 160px"
                                        placeholder="请填写合同名称"
                                        @keyup.enter.native="searchData(true)"></el-input>
                        </el-form-item>
                    </el-col>


                    <el-col :span="8" >
                        <el-form-item
                            label="销售人:"
                    >
                        <el-input   v-model="pageMsg.userName" style="width: 160px"
                                    placeholder="请填写销售人姓名"
                                    @keyup.enter.native="searchData(true)"></el-input>
                    </el-form-item></el-col>
                    <el-col :span="8" >
                        <el-form-item  style="margin-left: -10px">
                            <el-button size="small" type="primary"  @click="reject()"
                                       v-if="userbtn.indexOf('CONTRACT_CREATE') >= 0"
                            >新建</el-button>
                            <el-button size="small"  @click="searchData(false)">
                                重置
                            </el-button>
                            <el-button type="primary" size="small"  @click="searchData(true)">查询</el-button>
                            <el-button
                                    type="primary"
                                    size="small"   @click="downloadPattern" >
                                导出
                            </el-button>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12"  style="margin-left: -75px" >
                        <el-form-item prop="dateType"  >
                            <el-radio-group v-model="pageMsg.dateType" >
                                <el-radio :label="2" style="margin-right: 10px">日</el-radio>
                                <!--   <el-radio :label="0" style="margin-right: 10px">本周</el-radio>
                                   <el-radio :label="1" style="margin-right: 10px">当月</el-radio>
                                   <el-radio :label="3" style="margin-right: 10px">年度</el-radio>-->
                                <el-radio :label="4" style="margin-right: 50px">区间</el-radio>
                            </el-radio-group>
                        </el-form-item>


                        <el-form-item prop="year" v-if="pageMsg.dateType === 3 ">
                            <el-date-picker style="margin-left: -80px;width: 180px"
                                            v-model="pageMsg.year"
                                            type="year"
                                            value-format="yyyy"
                                            placeholder="选择年">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item prop="number" v-if="pageMsg.dateType === 2">
                            <el-date-picker style="width: 180px"
                                            v-model="pageMsg.day"
                                            type="date"
                                            format="yyyy 年 MM 月 dd 日"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item prop="number" v-if="pageMsg.dateType === 4">
                            <el-date-picker style="width: 180px"
                                            v-model="pageMsg.startMonths"
                                            type="date"
                                            format="yyyy 年 MM 月 dd 日"
                                            value-format="yyyy-MM-dd"
                                            placeholder="开始日期">
                            </el-date-picker>
                            <el-date-picker
                                    style="width: 180px"
                                    v-model="pageMsg.endMonths"
                                    type="date"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6"  style="margin-left: -163px" >
                        <el-form-item prop="dateType"  >
                            <el-radio-group v-model="pageMsg.dateTypeByNY" >
                                <el-radio :label="5" style="margin-right: 10px">内部</el-radio>
                                <!--   <el-radio :label="0" style="margin-right: 10px">本周</el-radio>
                                   <el-radio :label="1" style="margin-right: 10px">当月</el-radio>
                                   <el-radio :label="3" style="margin-right: 10px">年度</el-radio>-->
                                <el-radio :label="6" style="margin-right: 10px">外部</el-radio>
                            </el-radio-group>
                        </el-form-item>


                    </el-col>
                </el-row>



            </el-form>

            <el-table
                    ref="multipleTable"
                    :data="tableData"
                    style="width: 100%; margin-bottom: 20px"
                    row-key="id"
                    @selection-change="handleSelectionChange"
                    header-row-class-name="tableHead"
                    :header-cell-style="{ background: 'rgb(250,250,250)' }"
                    v-loading="tableLoading"
            >


                <el-table-column
                        prop="contractName"
                        label="合同名称"
                        header-align="center"
                        align="center"
                >
                    <template slot-scope="{ row }">
                        <el-button type="text" @click.native.prevent="Detail(row)">

                            {{ row.contractName }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        prop="contractAmount"
                        label="合同金额"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="contractShell"
                        label="销售成本"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="contractTd"
                        label="销售提点"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        sortable
                        prop="contractTc"
                        label="销售提成"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="salesMan"
                        label="销售人"
                        header-align="center"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        sortable
                        prop="startEndDate"
                        label="签约日期"
                        header-align="center"
                        align="center"
                >
                </el-table-column>

                <el-table-column
                        label="操作"
                        header-align="center"
                        align="center"
                        fixed="right"
                >
                    <template slot-scope="{ row }">


                        <el-button size="small"
                                   v-if="userbtn.indexOf('CONTRACT_DELETE') >= 0"
                                   @click="basicJobDeleteOne(row.id)"   type="text"  >
                            删除
                        </el-button>


                    </template>
                </el-table-column>
            </el-table>
            <div class="pb20"></div>
            <el-pagination
                    class="text-center"
                    v-if="pageTotal > 0"
                    @size-change="initData"
                    @current-change="initData"
                    :current-page.sync="pageMsg.pageNum"
                    :page-size.sync="pageMsg.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal"
            >
            </el-pagination>
        </el-card>
        <el-dialog   title="新建合同信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading1"  @close="dialogCreate" >

            <div class="channel-add">
                <el-form
                        :rules="userFormRules"
                        :model="channelForm"
                        ref="addChannel"
                        :label-position="labelPosition"
                        :label-width="formLabelWidth"
                >

                    <el-form-item label="合同名称:" prop="contractName"   >
                        <el-input   v-model="channelForm.contractName" style="width: 220px"
                                    placeholder="请填写合同名称"></el-input>
                    </el-form-item>
                    <el-form-item label="合同金额(元):" prop="contractAmount"  >
                        <el-input   v-model="channelForm.contractAmount" type="number" style="width: 220px"
                                    placeholder="请填写合同金额"></el-input>
                    </el-form-item>
                    <el-form-item label="销售成本(元):" prop="contractShell"  >
                        <el-input   v-model="channelForm.contractShell" type="number" style="width: 220px"
                                    placeholder="请填写销售成本"></el-input>
                    </el-form-item>
                    <el-form-item label="销售提点:" prop="contractTd"   >
                        <el-select v-model="channelForm.contractTd" placeholder="请选择销售提点"    style="width: 220px">
                            <el-option
                                    v-for="item in optionWay"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="销售人:" prop="salesMan"  >
                        <el-input   v-model="channelForm.salesMan" style="width: 220px"
                                    placeholder="请填写销售人"></el-input>
                    </el-form-item>
                    <el-form-item label="签约开始日期:" prop="startDate"   >
                        <el-date-picker
                                v-model="channelForm.startDate"
                                type="date"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                                placeholder="签约开始日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="签约结束日期:" prop="endDate"   >
                        <el-date-picker
                                v-model="channelForm.endDate"
                                type="date"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                                placeholder="签约结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="合同描述:" prop="contractContent">
                        <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                maxlength="200"
                                show-word-limit
                                v-model="channelForm.contractContent">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="上传合同图片：" prop="faceDocId" class="comImage">
                        <el-upload
                                :action="uploadUrl"
                                :headers="myHeaders"
                                list-type="picture-card"
                                accept="image/jpeg,image/jpg,image/png"
                                :on-change="uploadChange"
                                :on-success="uploadReturn"
                                :on-preview="handlePictureCardPreview"
                                :before-upload="beforeAvatarUpload"
                                :on-exceed="handleExceed"
                                :on-remove="companyUploadRemove"
                                :file-list="fileList"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div align="center">   <span  >
      <el-button @click="dialogCreate" >取消</el-button>
      <el-button type="primary"  :loading="loading" @click="channelAdd">确定</el-button>
    </span></div>

            </div>

        </el-dialog>


        <el-dialog   title="查看合同信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading2"  @close="dialogCreate1" >

            <div class="channel-add">
                <el-form
                        :rules="userFormRules"
                        :model="channelForm"
                        ref="addChannel"
                        :label-position="labelPosition"
                        :label-width="formLabelWidth"
                >

                    <el-form-item label="合同名称:" prop="contractName"   >
                        <el-input   v-model="channelForm.contractName" disabled style="width: 220px"
                                    placeholder="请填写合同名称"></el-input>
                    </el-form-item>
                    <el-form-item label="合同金额(元):" prop="contractAmount"  >
                        <el-input   v-model="channelForm.contractAmount" disabled style="width: 220px"
                                    placeholder="请填写合同名称"></el-input>
                    </el-form-item>
                    <el-form-item label="销售成本(元):" prop="contractShell"  >
                        <el-input   v-model="channelForm.contractShell" disabled style="width: 220px"
                                    placeholder="请填写销售成本"></el-input>
                    </el-form-item>
                    <el-form-item label="销售提点:" prop="contractTd"  >
                        <el-input   v-model="channelForm.contractTd" disabled style="width: 220px"
                                    placeholder="请填写销售成本"></el-input>
                    </el-form-item>
                    <el-form-item label="销售提成(元):" prop="contractTc"  >
                        <el-input   v-model="channelForm.contractTc" disabled style="width: 220px"
                                    placeholder="请填写销售成本"></el-input>
                    </el-form-item>
                    <el-form-item label="销售人:" prop="salesMan"  >
                        <el-input   v-model="channelForm.salesMan" disabled style="width: 220px"
                                    placeholder="请填写合同名称"></el-input>

                    </el-form-item>
                    <el-form-item label="签约开始日期:" prop="startDate"   >

                        <el-input   v-model="channelForm.startDate" disabled style="width: 220px"
                                    placeholder="请填写合同名称"></el-input>

                    </el-form-item>
                    <el-form-item label="签约结束日期:" prop="endDate"   >


                        <el-input   v-model="channelForm.endDate" disabled style="width: 220px"
                                    placeholder="请填写合同名称"></el-input>

                    </el-form-item>
                    <el-form-item label="合同描述:" prop="contractContent">
                        <el-input
                                disabled
                                type="textarea"
                                placeholder="请输入内容"
                                maxlength="200"
                                show-word-limit
                                v-model="channelForm.contractContent">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="上传合同图片：" prop="faceDocId" class="comImage">
                        <el-upload
                                :action="uploadUrl"
                                :headers="myHeaders"
                                list-type="picture-card"
                                accept="image/jpeg,image/jpg,image/png"
                                :on-change="uploadChange"
                                :on-success="uploadReturn"
                                :before-upload="beforeAvatarUpload"
                                :on-preview="handlePictureCardPreview"
                                :on-exceed="handleExceed"
                                :on-remove="companyUploadRemove"
                                :file-list="fileList"
                                disabled
                        >
                            <i class="el-icon-plus"></i>

                        </el-upload>



                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>
</template>

<script>
import {contractManageExportCountApi,acontractManagePageApi,acontractManageAddApi,acontractManageDelApi,acontractManageGetApi} from 'api/article'
import constant from 'constant'
export default {

    name: 'contentReview',

    computed: {
        formatDate() {
            return function (oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()

                const dateString =
                    Y +
                    '-' +
                    add0(M) +
                    '-' +
                    add0(D) +
                    '  ' +
                    add0(h) +
                    ':' +
                    add0(m) +
                    ':' +
                    add0(s)
                return dateString
            }
        }
    },

    data() {
        return {
            rules: {
                failReason: [{ required: true, message: '请填写驳回原因' }]
            },
            passDialog: false,
            oneDialog: false,
            ruleModel: {
                articleId: null,
                failReason: ''
            },
            months: '',
            options: [],
            pageForm:{
                pageNum: 1,
                pageSize: 10,
                stockName: '',
                num: 0,
                stringTime: ''
            },
            dialogImageUrl: '',
            formLabelWidth: '160px',
            labelPosition: 'right',
            companyOptions: [],
            selectionArr: [],
            tableData: [],
            tableData1: [],
            pageTotal: 0,
            pageTotal1: 0,
            fileList: [],
            uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
            myHeaders: {},
            optionAuth: [{
                value: '全部',
                label: '全部'
            },{
                value: '有效',
                label: '有效'
            }, {
                value: '无效',
                label: '无效'
            }],
            optionContent: [{
                value: 'A股',
                label: 'A股'
            }, {
                value: '港股',
                label: '港股'
            }, {
                value: '中概股',
                label: '中概股'
            }],
            optionWay: [{
                value: '2%',
                label: '2%'
            }, {
                value: '5%',
                label: '5%'
            },{
              value: '15%',
              label: '15%'
            },{
              value: '25%',
              label: '25%'
            }],
            optionUserId: [],
            optionIndustryCategoryCode: [],
            pageMsg: {
                industryCategoryArr: [],
                industryCategoryCode: null,
                dateType: 0,
                dateTypeByNY: 0,
                contentType: null,
                year: undefined,
                number: undefined,
                pageNum: 1,
                userName: undefined,
                pageSize: 100,
                descs: ['created_time'],
                state: null,
                stockName: null,
                userId: null,
                day: '',
                month: '',
                startMonths: '',
                endMonths: '',
            },
            dialogVisible: false,
            tableLoading: false,
            tableLoading1: false,
            rowID: null,
            rowStockName: null,
            detailBool: false,
            detailBool1: false,
            passLoading1:false,
            passLoading2:false,
            channelForm: {
                contractName:undefined,
                contractTc:undefined,
                contractTd:undefined,
                contractAmount:undefined,
                salesMan:undefined,
                contractShell:undefined,
                startDate: undefined,
                endDate: undefined,
                contractContent: undefined,
                faceDocIds: [],
                picDTOList: null
            },
            loading: false,
            userFormRules: {
                contractName: [{required: true, message: '合同名称不能为空',trigger: 'blur'}],
                contractTd: [{required: true, message: '销售提点不能为空',trigger: 'blur'}],
                contractShell: [{required: true, message: '销售成本不能为空',trigger: 'blur'}],
                contractAmount: [{required: true, message: '合同金额不能为空',trigger: 'blur'}],
                salesMan: [{required: true, message: '销售人不能为空',trigger: 'blur'}],
                startDate: [{required: true, message: '签约开始时间不能为空',trigger: 'blur'}],
                endDate: [{required: true, message: '签约结束时间不能为空',trigger: 'blur'}],
                contractContent: [{required: true, message: '签约描述不能为空',trigger: 'blur'}],
            },
        }
    },
    mounted() {

        this.initData()

    },
    created() {

    },
    methods: {
        downloadPattern() {
            contractManageExportCountApi({
                ...this.pageMsg
            }).then(data => {
                let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
                // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                if (this.pageMsg.dateType === 0) {
                    link.download = `销售考核.csv`
                }else if (this.pageMsg.dateType === 2) {
                    let dateXls =  this.pageMsg.day;
                    link.download = `${dateXls}销售考核.csv`
                }else if (this.pageMsg.dateType === 4) {
                    let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
                    link.download = `${dateXls}销售考核.csv`
                }

                link.href = blobUrl;
                link.click()
                link.remove()
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
            })
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        Detail(row) {
            this.passLoading2 = true;
            acontractManageGetApi(row.id).then(data => {
                this.channelForm = data.result
                this.fileList = data.result.picDTOList
                console.log('data',data)
            })

        },
        companyUploadRemove(file) {
            this.channelForm.faceDocId = ''
            this.$refs.addChannel.validateField('faceDocId')
            this.fileList = this.fileList.filter(item => {
                return item.url != file.url
            })
        },
        handleExceed() {
            this.$message.warning(`当前限制上传 1 张图片`)
        },
        uploadChange(file, fileList) {
            this.fileList = fileList
            console.log('this.fileList',this.fileList)
            /*  for (let i = 0; i < this.fileList.length; i++) {
                     this.channelForm.faceDocIds = this.fileList[i].url
              }*/
        },
        uploadReturn(res) {
            if (res.code === 200) {
                this.channelForm.faceDocId = res.result.id
                this.$refs.addChannel.validateField('faceDocId')
            }
        },
        beforeAvatarUpload(file) {
            const isJPG =
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message({
                    message: '图片只支持上传jpg,jpeg,png格式!',
                    type: 'error'
                })
            }
            if (!isLt2M) {
                this.$message({
                    message: '图片大小不能超过 2MB!',
                    type: 'error'
                })
            }
            return isJPG && isLt2M
        },
        basicJobDeleteOne(id) {

            this.$confirm('是否确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                return  acontractManageDelApi(id)
            }).then(data => {
                this.$message.success('删除成功')
                this.initData()
            })
        },
        channelAdd() {

            this.$refs.addChannel.validate(valid => {

                if (valid) {
                    this.loading = true
                    let arr = [];
                    for (let i = 0; i <  this.fileList.length; i++) {
                        arr.push(this.fileList[i].response.result.url);
                    }
                    this.channelForm.faceDocIds = arr
                    console.log('  this.channelForm.faceDocIds',  this.channelForm.faceDocIds)
                    acontractManageAddApi({ ...this.channelForm }).then(res => {
                        if (res.code == 200) {

                            this.$message.success('保存成功')
                            this.channelForm = this.$options.data().channelForm
                            this.passLoading1 = false
                            this.fileList = [];
                            this.loading = false
                            this.initData()
                        }
                        this.loading = false

                    })
                }
            })
        },
        dialogCreate() {
            this.passLoading1 = false
        },
        dialogCreate1() {
            this.passLoading2 = false
            this.fileList = [];
        },
        reject() {
            this.passLoading1 = true;
            this.channelForm = this.$options.data().channelForm
        },
        initData() {
            if (this.pageMsg.dateType === 4 ) {
                if (this.pageMsg.startMonths === '' || this.pageMsg.endMonths === '') {
                    this.$message({
                        type: 'error',
                        message: '请选择日期!'
                    })
                    return
                }
            }
            this.tableLoading = true

            const data = {
                ...this.pageMsg
            }
            acontractManagePageApi(this.pageMsg).then(res => {

                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData = records
                    this.pageTotal = total
                    if (this.pageMsg.dateType === 0) {
                        this.pageMsg.day = '';
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 1) {
                        this.pageMsg.day = '';
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 2) {
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 3) {
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.day = '';
                    }else if (this.pageMsg.dateType === 4) {
                        this.pageMsg.year = '';
                        this.pageMsg.day = '';
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading = false
            })

        },
        formatData(row, column, cellValue, index) {
            //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
            //最终返回 s 就可以了
            var s = new Date(cellValue).toLocaleString()
            return s
            // 测试参数含义：不知道参数是什么含义的就打印出来
        },
        searchData(bool) {
            if (bool) {
                this.pageMsg.pageNum = 1
                this.pageMsg.pageSize = 10
                if (this.pageMsg.dateType === 3 && !this.pageMsg.year) {
                    this.$message({
                        type: 'error',
                        message: '请选择年份'
                    });
                    return;
                } else if (this.pageMsg.dateType === 2 && !this.pageMsg.day) {
                    this.$message({
                        type: 'error',
                        message: '请选择日期'
                    });
                    return;
                }
                if (this.pageMsg.dateType === 4 && this.pageMsg.startMonths && this.pageMsg.endMonths) {
                    this.pageMsg.month = this.pageMsg.startMonths + ',' + this.pageMsg.endMonths;
                } else if(this.pageMsg.dateType === 4 && !this.pageMsg.startMonths && !this.pageMsg.endMonths){
                    this.$message({
                        type: 'error',
                        message: '请选择日期'
                    });
                    return;
                }
            } else {
                this.pageMsg = this.$options.data().pageMsg
            }

            this.initData()

        },
        handleSelectionChange(e) {
            this.selectionArr = e
        },

    }
}
</script>

<style lang="scss" scoped>
    .channelManagement {
        width: 100%;
        .channelManagement-head {
            padding: 10px 20px;
            background: #fff;
        }
        .box-card {
            width: calc(100% - 60px);
            padding: 10px;
            margin: 20px auto;
        }
        /deep/.state-table {
            i {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 4px;
            }
        }
        .select_warn {
            background: rgb(230, 247, 255);
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            span {
                color: $primary;
            }
        }
    }
</style>
