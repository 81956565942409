<template>
  <div class="channelManagement">

    <div class="channelManagement-head">
    </div>

    <el-card class="box-card">
      <el-form
        :model="pageMsg"
        ref="formData"
        size="small"
      >
        <el-row  :gutter="24">

          <el-col :span='12'>
            <el-form-item label='日期:'>
              <el-date-picker
                v-model="pageMsg.createdTime"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span='12'>
            <el-form-item label='客户:'>
              <el-input clearable   @keyup.enter.native="searchData(true)"
                        v-model="pageMsg.stockName"  @input="getPinYin(pageMsg.stockName)" style="width: 150px"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row  :gutter="24">
          <el-col :span='12'>
            <el-form-item label='类型:'>
              <el-select v-model="pageMsg.sendType" placeholder="请选择">
                <el-option
                  v-for="item in sendTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" >
            <el-button type="primary" size="small" @click="searchData(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData(false)">
              重置
            </el-button>
          </el-col>
        </el-row>

      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px;margin-top: 10px"
        row-key="id"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading"
      >

        <el-table-column
          prop="taskType"
          label="任务类型"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="stockName"
          label="客户名称"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="articleTitle"
          label="文章"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="failReason"
          label="失败原因"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop="createdTime"
          label="发送时间"
          header-align="center"
          align="center"
          width="auto"
          :formatter="formatData"
        >
        </el-table-column>

        <el-table-column
          prop="sendType"
          label="发送类型"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="{ row }">
            <p v-if='row.sendType===0' style='color:green;'>
              <el-button type="success" size='mini' disabled plain >成功</el-button>
            </p>
            <p v-if='row.sendType===1'  style='color:red;'>
              <el-button type="danger" size='mini' plain @click='checkFailReason(row.failLogId)'>失败</el-button>
            </p>

          </template>

        </el-table-column>

      </el-table>

      <div class="pb20"></div>

      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>

    </el-card>


<!--    失败原因-->
    <el-dialog
      title="失败原因"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">

      <div v-loading="failReasonLoading">

        <p>
          <el-row>
            <el-col :span='6'>
              客户联系方式:
            </el-col>
            <el-col :span='18'>
              {{ failReason.stockContact }}
            </el-col>
          </el-row>
        </p>

        <p>
          <el-row>
            <el-col :span='6'>
              错误类型:
            </el-col>
            <el-col :span='18'>
              {{ failReason.errorType }}
            </el-col>
          </el-row>
        </p>

        <p>
          <el-row>
            <el-col :span='6'>
              失败原因:
            </el-col>
            <el-col :span='18'>
              {{ failReason.errorReason }}
            </el-col>
          </el-row>
        </p>


        <p>
          <el-row>
            <el-col :span='6'>
              错误信息:
            </el-col>
            <el-col :span='18'>
              {{ failReason.errorMsg }}
            </el-col>
          </el-row>
        </p>


        <p>
          <el-row>
            <el-col :span='6'>
              文章标题:
            </el-col>
            <el-col :span='18'>
              {{ failReason.articleTitle }}
            </el-col>
          </el-row>
        </p>


        <p>
          <el-row>
            <el-col :span='6'>
              文件路径:
            </el-col>
            <el-col :span='18'>
              {{ failReason.filePath }}
            </el-col>
          </el-row>
        </p>

      </div>
  <span>
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>


  </div>
</template>

<script>

import {timerTaskLogPageApi,getTimerTaskErrorLogById} from 'api/log';

export default {
  data() {
    return {
      failReasonLoading:false,
      failReason:{
        stockContact:null,
        errorType:null,
        errorReason:null,
        errorMsg:null,
        articleTitle:null,
        filePath:null
      },
      dialogVisible:false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableData:null,
      tableLoading:false,
      pageTotal:0,
      pageMsg:{
        startTime:null, //开始时间
        endTime:null, //结束时间
        createdTime:null, //创建时间
        taskType:null, //任务类型
        sendType:null, //发送类型
        stockName:null, //公司名称
        failReason:null //错误原因
      },
      timerTypeOptions:[
        {
          value: '每天',
          label: '每天'
        },
        {
          value: '每周',
          label: '每周'
        },
        {
          value: '每月',
          label: '每月'
        }
      ],
      sendTypeOptions:[
        {
          value: 0,
          label: '成功'
        },
        {
          value: 1,
          label: '失败'
        },
      ],
      failReasonOptions:[
        {
          value: '文件损坏',
          label: '文件损坏'
        },
        {
          value: '模板渲染失败',
          label: '模板渲染失败'
        },
        {
          value: 'DOC转PDF失败',
          label: 'DOC转PDF失败'
        },
        {
          value: '邮件发送失败',
          label: '邮件发送失败'
        },
        {
          value: '短信发送失败',
          label: '短信发送失败'
        },
        {
          value: '频繁发送',
          label: '频繁发送'
        },
      ]
    };
  },
  mounted() {
    this.initData()
  },
  methods: {
    handleClose(){
      this.dialogVisible = false;
    },
    checkFailReason(id){
      //加载数据
      this.dialogVisible = true;
      this.failReasonLoading = true;

      setTimeout(()=>{
        getTimerTaskErrorLogById(id).then(res=>{
          if (res.code === 200){
            this.failReason = res.result;
          }
          this.failReasonLoading = false;
        })
      },1000)
    },
    initData(){
      this.tableLoading = true

      if (this.pageMsg.createdTime!=null){
        this.pageMsg.startTime = this.pageMsg.createdTime[0]
        this.pageMsg.endTime = this.pageMsg.createdTime[1]
      }

      if (this.pageMsg.stockName === "" || this.pageMsg.stockName === " "){
        this.pageMsg.stockName=null;
      }

      timerTaskLogPageApi(this.pageMsg).then(res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        }else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
        // this.pageMsg = this.$options.data().pageMsg;
      })
    },
    formatData(row, column, cellValue, index) {
      //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
      //最终返回 s 就可以了
      var s = new Date(cellValue).toLocaleString()
      return s

      // 测试参数含义：不知道参数是什么含义的就打印出来
    },
    searchData(flag){
      if (flag){
        this.initData();
      }else {
        this.pageMsg = this.$options.data().pageMsg;
        this.initData();
      }
    },
    getPinYin(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */

      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg.stockName =name.toUpperCase();

      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg.stockName)
    },

  },
};
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
