var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data_statistics" }, [
    _c(
      "div",
      { staticClass: "channelManagement-head" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/clientData")
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "statistics_detail" },
      [
        _c(
          "el-row",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "border-bottom": "1px solid #ddd",
            },
          },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "h4",
                { staticStyle: { "font-size": "25px", margin: "20px 0" } },
                [_vm._v(" " + _vm._s(_vm.articleParams.articleTitle) + " ")]
              ),
            ]),
            _c(
              "el-col",
              { staticClass: "text-right", attrs: { span: 12 } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.exportReportClick },
                  },
                  [_vm._v("下载报告")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "statistics_detail" }, [
          _c("div", { staticClass: "statistics_top" }, [
            _c("p", { staticStyle: { color: "#9e9e9e" } }, [
              _vm._v(
                " 分发至今 " +
                  _vm._s(_vm.articleParams.createdTime) +
                  " - " +
                  _vm._s(_vm.articleParams.nowTime) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "data_top_wrap" }, [
              _c("div", { staticClass: "data_top_item" }, [
                _c("h3", [_vm._v(_vm._s(_vm.topParams.pushApiNum))]),
                _c("h3", [_vm._v("分发媒介数")]),
              ]),
              _c("div", { staticClass: "data_top_item" }, [
                _c("h3", [_vm._v(_vm._s(_vm.topParams.channelTypeNum))]),
                _c("h3", [_vm._v("媒介类型")]),
              ]),
              _c("div", { staticClass: "data_top_item" }, [
                !_vm.isEditReadNum
                  ? _c("h3", [_vm._v(_vm._s(_vm.topParams.readNum))])
                  : _vm._e(),
                _c("h3", [
                  _vm._v(" 阅读量"),
                  _vm.userbtn.indexOf("DATASTATISITICE_EDIT_READNUM") >= 0
                    ? _c("i")
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { attrs: { id: "content" } },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6, align: "center" } }, [
                  _c("div", {
                    staticStyle: { width: "500px", height: "450px" },
                    attrs: { id: "main" },
                  }),
                ]),
                _c("el-col", { attrs: { span: 18, align: "left" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { padding: "10px", "text-align": "center" },
                    },
                    _vm._l(_vm.detailParams1, function (item) {
                      return _vm.detailParams1
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "vertical-align": "top",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "background-color": "#ffaa00",
                                    "border-radius": "20px",
                                    padding: "2px",
                                    "margin-right": "20px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.channelName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                            },
                                          },
                                          [_vm._v(" 阅读量 ")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(item.list, function (childrenItem) {
                                return _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          "margin-top": "15px",
                                          "margin-bottom": "15px",
                                          "margin-right": "50px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          childrenItem.channelName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          childrenItem.monthlyActivity
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("p", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("同业分发排名:"),
                      ]),
                      _c("div", {
                        staticStyle: { width: "500px", height: "450px" },
                        attrs: { id: "industry" },
                      }),
                      _c("p", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(" 您公司当前在同行业中发布数量排名为第"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.rankingNumber)),
                        ]),
                        _vm._v("名! "),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("p", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("同辖区发排名:"),
                      ]),
                      _c("div", {
                        staticStyle: { width: "500px", height: "450px" },
                        attrs: { id: "bureau" },
                      }),
                      _c("p", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(" 您公司当前在同辖区中发布数量排名为第"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.bureauRankingNumber)),
                        ]),
                        _vm._v("名! "),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }