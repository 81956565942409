var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("router-view"),
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.pageMsg,
                size: "small",
                "label-position": "center",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司名称:" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.initData },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("el-button", { on: { click: _vm.resetCli } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.authStateName,
                callback: function ($$v) {
                  _vm.authStateName = $$v
                },
                expression: "authStateName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "有效", name: "20" } }),
              _c("el-tab-pane", { attrs: { label: "无效", name: "30" } }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "35px" } },
            [
              _vm.selectionArr.length > 0
                ? _c(
                    "div",
                    { staticClass: "select_warn" },
                    [
                      _vm._v(" 已经选择 "),
                      _c("span", [_vm._v(_vm._s(_vm.selectionArr.length))]),
                      _vm._v(" 项: "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.batchNoPassCli },
                        },
                        [_vm._v(" 批量不准确 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.batchPassCli },
                        },
                        [_vm._v(" 批量准确 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticStyle: { width: "100%", "margin-bottom": "20px" },
                  attrs: {
                    data: _vm.tableData,
                    "header-row-class-name": "tableHead",
                    "header-cell-style": { background: "rgb(250,250,250)" },
                    "row-key": "id",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stockName",
                      width: "100px",
                      "header-align": "center",
                      align: "center",
                      label: "公司名称",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.turnDetail1(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.stockName) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stockCode",
                      width: "100px",
                      "header-align": "center",
                      align: "center",
                      label: "股票代码",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "articleTitle",
                      width: "300px",
                      "header-align": "center",
                      align: "center",
                      label: "文章标题",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "checkTime",
                      "header-align": "center",
                      align: "center",
                      label: "审核时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "authState",
                      "header-align": "center",
                      align: "center",
                      label: "AI审核结果",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.authState === 20
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("有效"),
                                ])
                              : _vm._e(),
                            scope.row.authState === 30
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("无效"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "failReason",
                      "header-align": "center",
                      align: "center",
                      label: "审核原因",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "header-align": "center",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "cornflowerblue" },
                                  attrs: { type: "text", size: "small" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.passItemTrue(row)
                                    },
                                  },
                                },
                                [_vm._v(" 准确 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "cornflowerblue" },
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.passItemFalse(row)
                                    },
                                  },
                                },
                                [_vm._v("不准确")]
                              ),
                            ],
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "pb20" }),
              _vm.pageTotal > 0
                ? _c("el-pagination", {
                    staticClass: "text-center",
                    attrs: {
                      "current-page": _vm.pageMsg.pageNum,
                      "page-sizes": [10, 50, 100, 200],
                      "page-size": _vm.pageMsg.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageTotal,
                    },
                    on: {
                      "size-change": _vm.initData,
                      "current-change": _vm.initData,
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageNum", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageNum", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageSize", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "复审不准确",
                width: "500px",
                visible: _vm.noPassDialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.noPassDialogVisible = $event
                },
                close: _vm.closeNoPassDialog,
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.reviewInfo } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核原因:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.radioChange },
                          model: {
                            value: _vm.reviewInfo.reviewStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.reviewInfo, "reviewStatus", $$v)
                            },
                            expression: "reviewInfo.reviewStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("有效"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("无效"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.reviewStatus === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "有效原因" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择有效原因",
                              options: _vm.validOptions,
                              clearable: "",
                            },
                            model: {
                              value: _vm.reviewInfo.reviewReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.reviewInfo, "reviewReason", $$v)
                              },
                              expression: "reviewInfo.reviewReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reviewStatus === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "无效原因" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择有效原因",
                              options: _vm.invalidOptions,
                              clearable: "",
                            },
                            model: {
                              value: _vm.reviewInfo.reviewReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.reviewInfo, "reviewReason", $$v)
                              },
                              expression: "reviewInfo.reviewReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.noPassCli },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.closeNoPassDialog } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "批量复审不准确",
                width: "500px",
                visible: _vm.batchNoPassDialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.batchNoPassDialogVisible = $event
                },
                close: _vm.closeBathNoPassDialog,
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.reviewInfo } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核原因:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.radioChange },
                          model: {
                            value: _vm.reviewInfo.reviewStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.reviewInfo, "reviewStatus", $$v)
                            },
                            expression: "reviewInfo.reviewStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("有效"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("无效"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.reviewStatus === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "有效原因" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择有效原因",
                              options: _vm.validOptions,
                              clearable: "",
                            },
                            model: {
                              value: _vm.reviewInfo.reviewReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.reviewInfo, "reviewReason", $$v)
                              },
                              expression: "reviewInfo.reviewReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reviewStatus === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "无效原因" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择有效原因",
                              options: _vm.invalidOptions,
                              clearable: "",
                            },
                            model: {
                              value: _vm.reviewInfo.reviewReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.reviewInfo, "reviewReason", $$v)
                              },
                              expression: "reviewInfo.reviewReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.batchNoPassClick },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    { on: { click: _vm.closeBathNoPassDialog } },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.detailBool, width: "1200px" },
          on: {
            "update:visible": function ($event) {
              _vm.detailBool = $event
            },
          },
        },
        [_c("releaseDetail", { attrs: { rowID: _vm.rowID1 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }