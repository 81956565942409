<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <div>

        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px"
        >
        <el-row>
          <el-col :span='12'>
            <el-form-item label='客户名称:'>
              <el-input v-model="pageMsg.stockName" style="width: 150px"  placeholder="请输入公司简称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-button type='primary' size='small' @click="search(true)">查询</el-button>
            <el-button size='small' @click="search(false)">重置</el-button>
            <el-button type='primary' size='small' @click="add">新建</el-button>
          </el-col>
        </el-row>
        </el-form>
      </div>

        <el-table
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          :data="tableData"
          v-loading="tableLoading"
        >
          <el-table-column label="公司名称" prop="stockName">
          </el-table-column>

          <el-table-column label="权限模块" prop="moduleInfo">
          </el-table-column>

          <el-table-column
            fixed="right"
            label="操作"
            width="120">
            <template slot-scope="{ row }">
              <el-button
                @click='edit(row)'
                type="text"
                size="small">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>

      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>

    </el-card>

    <div>
      <el-dialog
        title="编辑客户权限"
        style="margin-left: -80px"
        :visible.sync="editVisible"
        width="30%"
        :before-close="editClose">
          <el-form
          :model="editForm"
          :label-position="labelPosition"
          :label-width="formLabelWidth">
            <el-form-item label='公司名称:' prop="stockName">
              <el-input v-model='editForm.stockName' disabled style='width: 200px'></el-input>
            </el-form-item>
            <el-form-item label="客户模块:" prop='moduleInfo'>
              <el-checkbox-group v-model="editForm.moduleInfo" @input='editInput'>
                <el-checkbox label="程序通">程序通</el-checkbox>
                <el-checkbox label="数据汇">数据汇</el-checkbox>
                <el-checkbox label="同业眼">同业眼</el-checkbox>
                <el-checkbox label="指定达">指定达</el-checkbox>
                <el-checkbox label="邮件达">邮件达</el-checkbox>
                <el-checkbox label="短信达">短信达</el-checkbox>
                <el-checkbox label="BA">BA</el-checkbox>
                <el-checkbox label="BA指数">BA指数</el-checkbox>
                <el-checkbox label="BA指标">BA指标</el-checkbox>
                <el-checkbox label="储值发">储值发</el-checkbox>
                <el-checkbox label="财经商业">财经商业</el-checkbox>
                <el-checkbox label="健康医疗">健康医疗</el-checkbox>
                <el-checkbox label="IT科技">IT科技</el-checkbox>
                <el-checkbox label="装备制造">装备制造</el-checkbox>
                <el-checkbox label="酒店旅游">酒店旅游</el-checkbox>
                <el-checkbox label="能源环境">能源环境</el-checkbox>
                <el-checkbox label="全部">全部</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
    <el-button @click="editClose">取 消</el-button>
    <el-button type="primary" @click="editOK">确 定</el-button>
      </el-dialog>

    </div>

    <div>

      <el-dialog
        title="新建客户权限"
        style="margin-left: -80px"
        :visible.sync="addVisible"
        width="80%"
        :before-close="addClose">

        <el-form :model="addForm">

          <el-form-item label='客户名称:'prop="stockName">
            <el-select v-model="addForm.stockName" placeholder="请选择">
              <el-option
                v-for="item in addOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="客户模块:" prop='addModuleInfo'>
            <el-checkbox-group v-model="addForm.moduleInfo" @input='addInput'>
              <el-checkbox label="程序通">程序通</el-checkbox>
              <el-checkbox label="数据汇">数据汇</el-checkbox>
              <el-checkbox label="同业眼">同业眼</el-checkbox>
              <el-checkbox label="指定达">指定达</el-checkbox>
              <el-checkbox label="邮件达">邮件达</el-checkbox>
              <el-checkbox label="短信达">短信达</el-checkbox>
              <el-checkbox label="BA">BA</el-checkbox>
              <el-checkbox label="BA指数">BA指数</el-checkbox>
              <el-checkbox label="BA指标">BA指标</el-checkbox>
              <el-checkbox label="储值发">储值发</el-checkbox>
              <el-checkbox label="财经商业">财经商业</el-checkbox>
              <el-checkbox label="健康医疗">健康医疗</el-checkbox>
              <el-checkbox label="IT科技">IT科技</el-checkbox>
              <el-checkbox label="装备制造">装备制造</el-checkbox>
              <el-checkbox label="酒店旅游">酒店旅游</el-checkbox>
              <el-checkbox label="能源环境">能源环境</el-checkbox>
              <el-checkbox label="全部">全部</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

        </el-form>


    <el-button @click="addClose">取 消</el-button>
    <el-button type="primary" @click="addOK">确 定</el-button>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { queryClientModulePageApi, upClientModuleInfoApi, queryClientApi, addClientModuleInfoApi } from 'api/client'

export default {
  data() {
    return{
      addVisible:false,
      editVisible:false,
      addOptions:[],
      formLabelWidth: '100px',
      labelPosition: 'left',
      tableData:[],
      addForm:{
        stockName:null,
        moduleInfo:['程序通','数据汇','同业眼','指定达','邮件达','短信达',"BA","BA指数","BA指标",'储值发','财经商业'],
      },
      editForm:{
        stockName:null,
        moduleInfo:[],
      },
      pageMsg:{
        stockName:null,
        pageNum: 1,
        pageSize: 100,
      },
      tableLoading:false,
      pageTotal: 0,

    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    queryClient(){
      queryClientApi().then(res => {
        if(res.code === 200){
          this.addOptions = res.result.map(name =>{
            return {
              label: name,
              value: name
            };
          })
        }
      })
    },
    add(){
      this.addVisible = true;
      this.queryClient();
    },
    addOK(){

      if (this.addForm.moduleInfo.includes("全部")){
        this.addForm.moduleInfo = ["程序通","数据汇","同业眼",'指定达',"邮件达","短信达","BA","BA指数","BA指标","储值发","财经商业","健康医疗","IT科技","装备制造","酒店旅游","能源环境"]
      }

      addClientModuleInfoApi(this.addForm).then(res => {
        if(res.code === 200){
          this.$message.success("添加成功!");
          this.addForm = this.$options.data().addForm;
          this.initData();
          this.addVisible = false;
        }
      })

    },
    search(bool){
      if (bool){
        this.initData();
      }else {
        this.pageMsg.stockName = null;
        this.initData()
      }
    },
    addInput(){
      if (this.addForm.moduleInfo.includes("全部")){
        this.addForm.moduleInfo = ["程序通","数据汇","同业眼",'指定达',"邮件达","短信达","BA","BA指数","BA指标","储值发","财经商业","健康医疗","IT科技","装备制造","酒店旅游","能源环境","全部"]
      }
    },
    editInput(){
      if (this.editForm.moduleInfo.includes("全部")){
        this.editForm.moduleInfo = ["程序通","数据汇","同业眼",'指定达',"邮件达","短信达","BA","BA指数","BA指标","储值发","财经商业","健康医疗","IT科技","装备制造","酒店旅游","能源环境","全部"]
      }
    },
    editOK(){
      if (this.editForm.moduleInfo.includes("全部")){
        this.editForm.moduleInfo = ["程序通","数据汇","同业眼",'指定达',"邮件达","短信达","BA","BA指数","BA指标","储值发","财经商业","健康医疗","IT科技","装备制造","酒店旅游","能源环境"]
      }
      upClientModuleInfoApi(this.editForm).then(res => {
        if(res.code === 200){
          this.$message.success("修改成功!");
          this.editForm = this.$options.data().editForm;
          this.initData();
          this.editVisible = false;
        }
      })
    },
    addClose(){
      this.addVisible = false;
      this.addForm = this.$options.data().addForm;
    },
    editClose(){
      this.editForm = this.$options.data().editForm;
      this.editForm.moduleInfo = [];
      this.editVisible = false
    },
    edit(row){
      this.editVisible = true;
      this.editForm = row;
      this.editForm.moduleInfo = row.moduleInfo ? row.moduleInfo.split(',') : [];
    },
    initData(){
      this.tableLoading = true
      queryClientModulePageApi(this.pageMsg).then(res => {

        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
