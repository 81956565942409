<template>
  <div>
    <router-view />
    <div class="release" v-if="$route.path == '/tryForm'">
      <div class="channelManagement-head">
        <h1>试用列表</h1>
      </div>
      <el-card class="box-card">
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
        >
          <el-row class="text-left">
            <el-col :span="6">
              <el-form-item label="企业：" class="limitinputWidth">
                <el-input clearable v-model="pageMsg.companyName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <p style="opacity: 0"></p>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号：" class="limitinputWidth">
                <el-input clearable v-model="pageMsg.phoneNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10" class="text-right">
              <el-button type="primary" size="small" @click="searchData(true)">
                查询
              </el-button>
              <el-button size="small" @click="searchData(false)">
                重置
              </el-button>
              <el-button type="primary" @click="downloadPattern" size="small"
              v-if="userbtn.indexOf('TRYFORM_EXPORT') >= 0">
                导出列表
              </el-button>
            </el-col>
          </el-row>
        </el-form>
        <div class="select_warn" v-if="selectionArr.length > 0">
          已经选择
          <span>{{ selectionArr.length }}</span>
          项
          <el-button type="text" @click="lookdetail"
          v-if="userbtn.indexOf('TRYFORM_MOREDEL') >= 0">删除</el-button>
        </div>
        <el-table
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column
            prop="companyName"
            label="企业名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="applyName"
            label="姓名"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="phoneNumber"
            label="手机号"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="officeMailbox"
            label="邮箱"
            header-align="center"
            align="center"
            width="250"
          >
            <!-- :formatter="formatData" -->
          </el-table-column>
          <el-table-column
            prop="position"
            label="职务"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="180"
            fixed="right"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click.native.prevent="lookdetail(row)"
                v-if="userbtn.indexOf('TRYFORM_DEL') >= 0"
              >
                删除
              </el-button>
              <!-- <el-button
                type="text"
                size="small"
              >
                导出
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </el-card>
    </div>
    <vue-html2pdf
      :show-layout="showLayout"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="数据统计"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      pdf-orientation="landscape"
      pdf-content-width="1000px"
      :html-to-pdf-options="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column
            prop="companyName"
            label="企业名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="applyName"
            label="姓名"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="phoneNumber"
            label="手机号"
            header-align="center"
            align="center"
            :formatter="formatData"
          >
          </el-table-column>
          <el-table-column
            prop="officeMailbox"
            label="邮箱"
            header-align="center"
            align="center"
            width="250"
          >
            <!-- :formatter="formatData" -->
          </el-table-column>
          <el-table-column
            prop="position"
            label="职务"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { enableDisableUserApi } from 'api/rightsetting'
import { applyPageApi, handleTrialApplyApi, applyExportApi } from 'api/tryuse'
import { companyPageApi } from 'api/company'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: 'userlist',
  components: {
    VueHtml2pdf
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: '文章详情.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,
        html2canvas: {
          scale: 2,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a3',
          orientation: 'portrait'
        }
      }
    }
  },
  data() {
    return {
      showLayout: false,
      topListStatus: {},
      timeDefaultShow: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      publisherDate: [], //时间选择器筛选
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 100,
        descs: ['created_time'],
        companyName: '',
        phoneNumber: ''
      },
      userDetailOperate: '新增用户',
      companyOptions: [],
      authStateArr: [
        {
          label: '审核中',
          value: 10
        },
        {
          label: '成功',
          value: 20
        },
        {
          label: '失败',
          value: 30
        }
      ],
      tableLoading: false
    }
  },
  created() {
    this.timeDefaultShow = new Date()
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
  },
  mounted() {
    this.initData()
    this.initCompany()
  },
  watch: {
    $route: {
      handler(to) {
        // 初始化操作
        if (to.path === '/release') {
          this.pageMsg = this.$options.data().pageMsg
          this.initData()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    show: function () {
      this.showLayout = !this.showLayout
    },
    downloadPattern() {
      this.downLoading = true
      applyExportApi({
        companyName: this.pageMsg.companyName,
        phoneNumber: this.pageMsg.phoneNumber
      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        link.download = '试用列表.csv'
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
        this.downLoading = false
      })
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      applyPageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },

    initCompany() {
      //初始化公司列表
      companyPageApi({
        pageNum: 1,
        pageSize: 100
      }).then(res => {
        if (res.code === 200) {
          this.companyOptions = res.result.records
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    formatData(row, column, cellValue, index) {
      //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
      //最终返回 s 就可以了
      var s = new Date(cellValue).toLocaleString()
      return s

      // 测试参数含义：不知道参数是什么含义的就打印出来
    },

    // 启用禁用
    enableDisablingRole(data = '') {
      let status = data.status === 1 ? 2 : 1
      // data.status 为1 是启用 去禁用 disable为0
      enableDisableUserApi({ id: data.id, status }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: status === 1 ? '启用成功' : '禁用成功',
            type: 'success'
          })
          this.initData()
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },

    lookdetail(data) {
      let list = []
      if (data.id) {
        list = [data.id]
      } else {
        this.selectionArr.map(res => {
          list.push(res.id)
        })
      }
      // this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      //   .then(() => {
      handleTrialApplyApi({
        list
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.initData()
        }
      })
      // })
      // .catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除'
      //   })
      // })
    },
    addAndEditDialog(data = '') {
      // if (data) {
      //   this.userFormDetail = { ...data }
      //   if (data.rolesInfoStr && data.rolesInfoStr.length > 0) {
      //     this.userFormDetail.roles = data.rolesInfoStr.split(',')
      //   }
      //   this.userDetailOperate = '编辑用户'
      // } else {
      //   this.userDetailOperate = '新增用户'
      // }
      // this.userDetailVisible = true
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 100
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    handleSelectionChange(e) {
      this.selectionArr = e
    },
    dateChange() {
      if (this.publisherDate && this.publisherDate.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg.startTime = this.publisherDate[0]
        this.pageMsg.endTime = this.publisherDate[1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.release {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  .release_head {
    display: flex;
    width: 100%;
    background: #fff;
    justify-content: space-around;
    margin-bottom: 20px;
    .item {
      width: 30%;
      border-right: 1px solid #8584844a;
      text-align: center;
      &:nth-child(3) {
        border: none;
      }
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
