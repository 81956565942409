import apiServer from 'api/apiServer.js'


export function queryClientModulePageApi(params = {}) {
  let url = `/client/queryClientModulePage` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function queryClientApi(params = {}) {
  let url = `/client/queryClient` //post methods
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function upClientModuleInfoApi(params = {}) {
  let url = `/client/upClientModuleInfo` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function addClientModuleInfoApi(params = {}) {
  let url = `/client/addClientModuleInfo` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}
