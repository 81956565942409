var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement-wrap" },
    [
      _c("router-view"),
      _vm.$route.path == "/channelManagement"
        ? _c(
            "div",
            { staticClass: "channelManagement" },
            [
              _vm._m(0),
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-row",
                    { staticClass: "text-left", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "formData",
                          attrs: {
                            model: _vm.pageMsg,
                            size: "small",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "limitinputWidth",
                                  attrs: {
                                    label: "媒介：",
                                    "label-width": "54px",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.pageMsg.channelName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageMsg,
                                          "channelName",
                                          $$v
                                        )
                                      },
                                      expression: "pageMsg.channelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "limitinputWidth",
                                  attrs: {
                                    label: "状态：",
                                    "label-width": "54px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.pageMsg.state,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.pageMsg, "state", $$v)
                                        },
                                        expression: "pageMsg.state",
                                      },
                                    },
                                    _vm._l(_vm.stateOption, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 5 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "limitinputWidth",
                                  attrs: {
                                    label: "媒介类别：",
                                    "label-width": "84px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.pageMsg.channelTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pageMsg,
                                            "channelTypeId",
                                            $$v
                                          )
                                        },
                                        expression: "pageMsg.channelTypeId",
                                      },
                                    },
                                    _vm._l(_vm.terminalIdList, function (d) {
                                      return _c("el-option", {
                                        key: d.id,
                                        attrs: { label: d.name, value: d.id },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "limitinputWidth",
                                  attrs: {
                                    label: "类型：",
                                    "label-width": "54px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.pageMsg.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.pageMsg, "type", $$v)
                                        },
                                        expression: "pageMsg.type",
                                      },
                                    },
                                    _vm._l(_vm.apiCategoryList, function (d) {
                                      return _c("el-option", {
                                        key: d.key,
                                        attrs: { label: d.key, value: d.value },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "分配:",
                                    "label-width": "54px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.pageMsg.userId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.pageMsg, "userId", $$v)
                                        },
                                        expression: "pageMsg.userId",
                                      },
                                    },
                                    _vm._l(_vm.optionUserId1, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "text-left",
                      staticStyle: { "margin-bottom": "10px" },
                    },
                    [
                      _vm.userbtn.indexOf("API_ADD") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/channelManagement/add"
                                  )
                                },
                              },
                            },
                            [_vm._v(" 新建API ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("API_EXPORT") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.platformExport },
                            },
                            [_vm._v(" 导出列表 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("MANAGE_MENT_PLFP") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.plfp(_vm.pageMsg)
                                },
                              },
                            },
                            [_vm._v(" 批量分配 ")]
                          )
                        : _vm._e(),
                      _vm.userbtn.indexOf("MANAGE_MENT_PLJC") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.pljc(_vm.pageMsg)
                                },
                              },
                            },
                            [_vm._v(" 批量解除 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.sequenceClick },
                        },
                        [_vm._v(" 分配顺序 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.sequenceChildrenClick },
                        },
                        [_vm._v(" 媒介小类排序 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.apiRelevanceClick },
                        },
                        [_vm._v(" 关联媒介渠道 ")]
                      ),
                    ],
                    1
                  ),
                  _vm.selectionArr.length > 0
                    ? _c(
                        "div",
                        { staticClass: "select_warn" },
                        [
                          _vm._v(" 已经选择 "),
                          _c("span", [_vm._v(_vm._s(_vm.selectionArr.length))]),
                          _vm._v(" 项 "),
                          _vm.userbtn.indexOf("API_MORE_HIDE") >= 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.endList },
                                },
                                [_vm._v("冻结")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: {
                        "header-row-class-name": "tableHead",
                        "header-cell-style": { background: "rgb(250,250,250)" },
                        data: _vm.tableData,
                        "row-key": "id",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "channelName",
                          label: "媒介名称",
                          "header-align": "center",
                          align: "center",
                          width: "150",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _vm.userbtn.indexOf("API_SWITCH_BTN") >= 0
                                        ? _c("el-switch", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.switchEvent(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.statusVal,
                                              callback: function ($$v) {
                                                _vm.$set(row, "statusVal", $$v)
                                              },
                                              expression: "row.statusVal",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                        },
                                        [_vm._v(_vm._s(row.channelName))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          660837023
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "apiUrl",
                          label: "API地址",
                          "header-align": "center",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mediaManager",
                          label: "媒介经理",
                          "header-align": "center",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "apiUrl",
                          label: "类型",
                          "header-align": "center",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.type == 20 ? "拉取" : "推送") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3164371926
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "70",
                          label: "权益",
                          "header-align": "center",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.userbtn.indexOf("API_GOOD_SET") >= 0 &&
                                  row.type == 10
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setDialog(row)
                                            },
                                          },
                                        },
                                        [_vm._v("设置")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          312751760
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "key和Secret",
                          width: "110",
                          "header-align": "center",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.userbtn.indexOf("API_GOOD_SET") >= 0 &&
                                  row.type == 20
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setKeyDialog(row)
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3262500762
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "updatedTime",
                          label: "更新时间",
                          sortable: "",
                          "header-align": "center",
                          align: "center",
                          width: "150",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatDate(row.updatedTime)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2773302281
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "header-align": "center",
                          align: "center",
                          width: "180",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.userbtn.indexOf("API_EDIT") >= 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.lookdetail(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 编辑 ")]
                                      )
                                    : _vm._e(),
                                  row.state == 10 &&
                                  _vm.userbtn.indexOf("API_HIDE") >= 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.addAndEditDialog(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 冻结 ")]
                                      )
                                    : _vm._e(),
                                  row.state == 20 &&
                                  _vm.userbtn.indexOf("API_UP") >= 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.noAndEditDialog(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 解冻 ")]
                                      )
                                    : _vm._e(),
                                  row.show === 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                        },
                                        [_vm._v(" 已关联 ")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1011097737
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "pb20" }),
                  _vm.pageTotal > 0
                    ? _c("el-pagination", {
                        staticClass: "text-center",
                        attrs: {
                          "current-page": _vm.pageMsg.pageNum,
                          "page-size": _vm.pageMsg.pageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.pageTotal,
                        },
                        on: {
                          "size-change": _vm.initData,
                          "current-change": _vm.initData,
                          "update:currentPage": function ($event) {
                            return _vm.$set(_vm.pageMsg, "pageNum", $event)
                          },
                          "update:current-page": function ($event) {
                            return _vm.$set(_vm.pageMsg, "pageNum", $event)
                          },
                          "update:pageSize": function ($event) {
                            return _vm.$set(_vm.pageMsg, "pageSize", $event)
                          },
                          "update:page-size": function ($event) {
                            return _vm.$set(_vm.pageMsg, "pageSize", $event)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "show-close": false,
                    "close-on-click-modal": false,
                    "close-on-press-escape": false,
                    title: "权益设置",
                    visible: _vm.dialogSet,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogSet = $event
                    },
                  },
                },
                [
                  _c("sets", {
                    attrs: { apiDetailMsg: _vm.apiDetailMsg },
                    on: { closeAdd: _vm.closeAdd },
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-click-modal": false,
                    "close-on-press-escape": false,
                    title: "key和Secret",
                    visible: _vm.dialogSetKeys,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogSetKeys = $event
                    },
                  },
                },
                [
                  _c("setkey", {
                    attrs: { apiDetailMsg: _vm.apiDetailMsg },
                    on: { closeAdd: _vm.closeAdd },
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "800px",
                    visible: _vm.passDialog,
                    title: "选择分配人员",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.passDialog = $event
                    },
                    close: _vm.dialogCreate2,
                  },
                },
                [
                  _c(
                    "el-form",
                    { ref: "formName", attrs: { model: _vm.ruleModel } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分配用户:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "auto" },
                              attrs: { placeholder: "请选择分配用户" },
                              model: {
                                value: _vm.ruleModel.assignUserId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleModel, "assignUserId", $$v)
                                },
                                expression: "ruleModel.assignUserId",
                              },
                            },
                            _vm._l(_vm.optionUserId, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { "margin-bottom": "30px" } }),
                      _c(
                        "div",
                        { staticClass: "dialog-footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleClose2 },
                            },
                            [_vm._v("取 消")]
                          ),
                          _vm.canSubmit
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addAndEditBanner2("formName")
                                    },
                                  },
                                },
                                [_vm._v("确 定")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "媒介类型分配顺序",
            visible: _vm.sequenceVisible,
            width: "35%",
            "before-close": _vm.sequenceVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sequenceVisible = $event
            },
          },
        },
        [
          _c(
            "draggable",
            {
              attrs: {
                chosenClass: "chosen",
                forceFallback: "true",
                group: "people",
                animation: "1000",
              },
              on: { start: _vm.onStart, end: _vm.onEnd },
              model: {
                value: _vm.pushChannelList,
                callback: function ($$v) {
                  _vm.pushChannelList = $$v
                },
                expression: "pushChannelList",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.pushChannelList, function (element) {
                  return _c(
                    "div",
                    { key: element.channelTypeId, staticClass: "item" },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            width: "70%",
                            margin: "0px auto",
                            "background-color": "#bbe3ea",
                            padding: "15px",
                            "border-radius": "25px",
                            "text-align": "center",
                            color: "black",
                            "font-size": "20px",
                          },
                        },
                        [_vm._v(" " + _vm._s(element.channelTypeName) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px", "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sequenceVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitNewClick },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择媒介类型",
            visible: _vm.selectChildrenVisible,
            width: "20%",
            "before-close": _vm.selectChildrenVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectChildrenVisible = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择媒介类型" },
              model: {
                value: _vm.selectChildValue,
                callback: function ($$v) {
                  _vm.selectChildValue = $$v
                },
                expression: "selectChildValue",
              },
            },
            _vm._l(_vm.selectChildOptions, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c("div", { staticStyle: { "margin-top": "50px" } }),
          _c(
            "p",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.selectChildrenVisibleClose },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.openMediumSequence },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "媒介子类型分配顺序",
            visible: _vm.sequenceChildrenVisible,
            width: "25%",
            "before-close": _vm.sequenceChildrenVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sequenceChildrenVisible = $event
            },
          },
        },
        [
          _c(
            "draggable",
            {
              attrs: {
                chosenClass: "chosen",
                forceFallback: "true",
                group: "people",
                animation: "1000",
              },
              on: { start: _vm.onStart, end: _vm.onEnd },
              model: {
                value: _vm.pushChannelChildrenList,
                callback: function ($$v) {
                  _vm.pushChannelChildrenList = $$v
                },
                expression: "pushChannelChildrenList",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.pushChannelChildrenList, function (element) {
                  return _c(
                    "div",
                    { key: element.channelTypeId, staticClass: "item" },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            width: "70%",
                            margin: "0px auto",
                            "background-color": "#bbe3ea",
                            padding: "15px",
                            "border-radius": "25px",
                            "text-align": "center",
                            color: "black",
                            "font-size": "20px",
                          },
                        },
                        [_vm._v(" " + _vm._s(element.channelTypeName) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px", "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sequenceChildrenVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitChildrenClick },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联媒介渠道",
            visible: _vm.apiRelevanceVisible,
            width: "30%",
            "before-close": _vm.apiRelevanceVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.apiRelevanceVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12, align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addRelevance },
                    },
                    [_vm._v("新建关系")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.manageRelevance },
                    },
                    [_vm._v("管理关系")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建媒介关联",
            visible: _vm.addRelevanceVisible,
            width: "30%",
            "before-close": _vm.addRelevanceVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addRelevanceVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "addRelevanceRef",
                  attrs: {
                    rules: _vm.addRelevanceFormRules,
                    model: _vm.addRelevanceForm,
                    "label-position": "left",
                    "label-width": "150",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "渠道名称:", prop: "apiParentName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入渠道名称" },
                        model: {
                          value: _vm.addRelevanceForm.apiParentName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRelevanceForm, "apiParentName", $$v)
                          },
                          expression: "addRelevanceForm.apiParentName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addRelevanceFormSubmit },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        { on: { click: _vm.addRelevanceVisibleClose } },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "管理媒介关系",
            visible: _vm.manageRelevanceVisible,
            width: "80%",
            "before-close": _vm.manageRelevanceVisibleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.manageRelevanceVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "manageRelevanceRef",
              attrs: {
                rules: _vm.manageRelevanceFormRules,
                model: _vm.manageRelevanceForm,
                "label-position": "left",
                "label-width": "150",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请选择父级媒介名称:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { input: _vm.selectManageInput },
                      model: {
                        value: _vm.manageRelevanceForm.apiParentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.manageRelevanceForm, "apiParentId", $$v)
                        },
                        expression: "manageRelevanceForm.apiParentId",
                      },
                    },
                    _vm._l(_vm.manageRelevanceOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.pushApiListShow
                ? _c(
                    "el-form-item",
                    { attrs: { label: "请选择子级媒介:" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.manageRelevanceForm.pushApiList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.manageRelevanceForm,
                                "pushApiList",
                                $$v
                              )
                            },
                            expression: "manageRelevanceForm.pushApiList",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                              },
                            },
                            _vm._l(_vm.pushApiAllList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticStyle: {
                                    "flex-basis": "20%",
                                    "box-sizing": "border-box",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: { label: item.channelName },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.manageRelevanceSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.manageRelevanceClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channelManagement-head" }, [
      _c("h1", [_vm._v("API设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }