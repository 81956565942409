<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <div>
      <el-card class="box-card">

        <div>
          <el-form
            :model='pageMsg'
            size='small'
            label-position="center"
            label-width="100px">
            <el-row>
              <el-col :span='12'>
                <el-form-item label="公司名称:" label-width='120px'>
                  <el-input
                    style='width: 220px'
                    v-model='pageMsg.companyName'
                    placeholder='请输入公司名称'>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span='12' align='center'>
                <el-button type='primary' size='small' @click='created'>新建</el-button>
                <el-button type='primary' size='small' @click='initData'>查询</el-button>
                <el-button size='small' @click='reset'>重置</el-button>
              </el-col>

            </el-row>
          </el-form>
        </div>

        <div style='margin-top: 50px'>
          <el-table
            :data="tableData"
            style="width: 100%"
            v-loading="tableLoading">


            <el-table-column
              prop="companyName"
              label="公司简称"
            >
            </el-table-column>

            <el-table-column
              prop="equityNum"
              label="权益次数"
            >
            </el-table-column>

            <el-table-column
              prop="expireTime"
              label="到期时间"
            >

              <template slot-scope="{ row }">
                {{ formatDate(row.expireTime) }}
              </template>

            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              width="200">
              <template slot-scope="scope">
                <el-button @click="editCompanyInfo(scope.row)" type="text" size="small">编辑</el-button>
                <el-button @click="viewAssociatedCompanies(scope.row)" type="text" size="small">查看关联公司</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="pb20"></div>

          <el-pagination
            class="text-center"
            v-if="pageTotal > 0"
            @size-change="initData"
            @current-change="initData"
            :current-page.sync="pageMsg.pageNum"
            :page-sizes="[10, 50, 100, 200]"
            :page-size.sync="pageMsg.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination>

        </div>


        <div>
          <el-dialog title="新建公关公司"
                     style="margin-left: -80px"
                     width="800px"
                     :visible.sync="addPublicCompanyDialogVisible"
                     @close="addPublicCompanyDialogVisible = false" >

            <el-form
              :model='addMsg'
              size='small'
              ref="addRef"
              :rules='addFormRules'
              label-position="center"
              label-width="100px">

              <el-form-item label='公司名称:' label-width='120px' prop='companyName'>
                <el-input type='text' v-model='addMsg.companyName' style='width: 220px' placeholder='请输入公司名称'></el-input>
              </el-form-item>

              <el-form-item label='公司账号:' label-width='120px' prop='companyAccount'>
                <el-input type='text' v-model='addMsg.companyAccount' style='width: 220px' placeholder='请输入公司账号'></el-input>
              </el-form-item>

              <el-form-item label='公司密码:' label-width='120px' prop='companyPassword'>
                <el-input type='password' v-model='addMsg.companyPassword' style='width: 220px' placeholder='请输入公司密码'></el-input>
              </el-form-item>

              <el-form-item label='权益次数:' label-width='120px' prop='equityNum'>
                <el-input type='text' v-model='addMsg.equityNum' style='width: 220px' placeholder='请输入权益次数'></el-input>
              </el-form-item>

              <el-form-item label='到期时间' label-width='120px' prop='expireTime'>
                <el-date-picker
                    v-model="addMsg.expireTime"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
              </el-form-item>

              <span>
                <el-button type='primary' size='small' @click='addPublicCompany'>确定</el-button>
                <el-button size='small' @click='addPublicCompanyDialogVisible = false'>取消</el-button>
              </span>

            </el-form>
          </el-dialog>
        </div>


        <div>
          <el-dialog title="编辑公关公司"
                     style="margin-left: -80px"
                     width="800px"
                     :visible.sync="editPublicCompanyDialogVisible"
                     @close="editPublicCompanyDialogVisible = false" >

            <el-form
              :model='editMsg'
              size='small'
              ref="editRef"
              :rules='editFormRules'
              label-position="center"
              label-width="100px">

              <el-form-item label='公司名称:' label-width='120px'>
                <el-input type='text' v-model='editMsg.companyName' disabled style='width: 220px' placeholder='请输入公司名称'></el-input>
              </el-form-item>

              <el-form-item label='操作:' prop='editType'>
                <el-radio-group v-model="editMsg.editType" size="mini" >
                  <el-radio-button label="0" border>增加</el-radio-button>
                  <el-radio-button label="1" border>减少</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item label='权益次数:' label-width='120px'>
                <el-input-number v-model="editMsg.equityNum"  :min="1" :max="100000"></el-input-number>
              </el-form-item>

              <el-form-item label='到期时间' label-width='120px'>
                <el-date-picker
                  v-model="editMsg.expireTime"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>

              <span>
                <el-button type='primary' size='small' @click='editPublicCompany'>确定</el-button>
                <el-button size='small' @click='editPublicCompanyDialogVisible = false'>取消</el-button>
              </span>

            </el-form>
          </el-dialog>
        </div>

        <div>
          <el-dialog title="查看关联信息"
                     style="margin-left: -80px"
                     width="800px"
                     height="1000px"
                     :visible.sync="selectCompanyDialogVisible"
                     @close="selectCompanyDialogVisible = false" >

            <div>
              <el-row>
                <el-col :span='24' >
                  <el-row class="centered-row">
                    <el-col :span='6' >
                      <div class="container" >
                        <p style='text-align: center;background-color: #f5f7fa;margin: 0 auto;border-radius: 10px 10px 0 0;font-weight: bold'>待选公司</p>
                        <el-input v-model="searchValue" placeholder="请输入关键词">
                          <el-button slot="append" icon="el-icon-search" @click='searchKey'></el-button>
                        </el-input>
                        <div class="content">
                          <el-checkbox-group v-model="filterDataList">
                            <div v-for="item in filterData" style='display: inline-block;margin-left: 10px' :key="item.id">
                              <el-checkbox :label="item.key+'-'+item.label">{{item.label}}</el-checkbox>
                            </div>
                          </el-checkbox-group>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div style='margin: 0 auto;text-align: center'>
                        <p>
                          <el-button type='primary' @click='addSameIndustry'  icon="el-icon-right">加入</el-button>
                        </p>
                        <p>
                          <el-button type='primary' @click='cancelSameIndustry'  icon="el-icon-back">删除</el-button>
                        </p>
                      </div>
                    </el-col>
                    <el-col :span='6'>
                      <div class="container">
                        <p style='text-align: center;background-color: #f5f7fa;margin: 0 auto;border-radius: 10px 10px 0 0;font-weight: bold'>已选公司</p>
                        <div class="content">
                          <el-checkbox-group v-model="sameIndustryList" >
                            <div style='display: inline-block;margin-left: 10px' v-for="item in sameIndustryData" :key="item.id">
                              <el-checkbox :label="item.key+'-'+item.label">{{item.label}}</el-checkbox>
                            </div>
                          </el-checkbox-group>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-dialog>
        </div>
      </el-card>
    </div>
    </div>
</template>

<script>
import {
  addPublicCompanyApi, associatedCompanyApi, editPublicCompanyInfoApi,
  getPublicCompanyPageApi,
  getUnifyAffiliatedCompanyListApi,
  queryStockByNameApi, selectPublicCompanyInfoApi, unassociatedCompanyApi
} from 'api/company'

export default {
  data() {
    return{
      stockName:null,
      selectCompanyDialogVisible:false,
      addPublicCompanyDialogVisible:false,
      editPublicCompanyDialogVisible:false,
      editFormRules:{
        editType:[{required:true,message:'请选择操作类型',trigger:'blur'}],
        equityNum:[{required:true,message:'请填写权益次数',trigger:'blur'}]
      },
      addFormRules:{
        companyName:[{required:true,message:'请填写公司名称',trigger:'blur'}],
        companyAccount:[{required:true,message:'请填写公司账号',trigger:'blur'}],
        companyPassword:[{required:true,message:'请填写公司密码',trigger:'blur'}],
        equityNum:[{required:true,message:'请填写权益次数',trigger:'blur'}],
        expireTime:[{required:true,message:'请选择到期时间',trigger:'blur'}],
      },
      pageTotal: 0,
      tableData:[

      ],
      editMsg:{

      },
      addMsg:{
        companyName:null,
        equityNum:null,
        expireTime:null
      },
      pageMsg:{
        companyName:null
      },
      tableLoading:false,
      searchValue:null,
      filterDataList:[],
      filterData:[],
      sameIndustryList:[],
      sameIndustryData:[],
    }
  },
  created() {
    this.initData();
  },
  methods: {
    editPublicCompany(){
      this.$refs.editRef.validate(valid => {
          if (valid) {
            this.editMsg.companyName = this.stockName
            this.editMsg.expireTime = this.formatDate(this.editMsg.expireTime)
            editPublicCompanyInfoApi(this.editMsg).then(res => {
              if (res.code === 200){
                this.$message.success("编辑成功!")
                this.initData();
                this.editPublicCompanyDialogVisible = false;
              }
            })
          }
      })
    },
    searchKey(){
      if (this.searchValue === ''||this.searchValue===null||this.searchValue===undefined){
        this.$message.error("关键词不能为空!");
        return;
      }
      queryStockByNameApi(this.searchValue).then(res => {
        if (res.code === 200){
          const data = res.result;
          this.filterData = data.map(item => {
            return {
              label: item.stockName +"---"+ item.stockCode,
              key: item.id
            };
          });
        }
      })
    },

    addSameIndustry(stockName){
      if (this.filterDataList==null||this.filterDataList==''||this.filterDataList=='undefined'){
        return;
      }
      const stringData = this.filterDataList.join(',');
      const processedData = stringData.split(',').map(item => {
        const [key, label] = item.split('-');
        return { key: parseInt(key), label };
      });
      if ((this.sameIndustryData.length+processedData.length)>20){
        this.$message.error("最多添加20家")
        return;
      }

      // 去重 processedData
      const uniqueProcessedData = processedData.filter((item, index, self) =>
        index === self.findIndex(t => t.key === item.key)
      );

      // 合并并去重
      const mergedData = [...processedData, ...this.sameIndustryData];
      const uniqueMergedData = mergedData.filter((item, index, self) =>
        index === self.findIndex(t => t.key === item.key)
      );

      // 更新 sameIndustryData
      this.sameIndustryData = uniqueMergedData;

      //去掉待选区的
      const sameIndustryKeys = this.sameIndustryData.map(item => item.key);
      this.filterData = this.filterData.filter(item => !sameIndustryKeys.includes(item.key));
      const processedNewData = this.filterData.map(item => ({
        label: item.label,
        key: item.key
      }));
      this.filterData = processedNewData;
      const params = {
        stockName:this.stockName,
        sameIndustryList:this.sameIndustryData
      }
      associatedCompanyApi(params).then(res => {
        if(res.code === 200){
          this.$message.success("加入成功")
        }
      })

      console.log("params",JSON.stringify(params))
      this.filterDataList = this.$options.data().filterDataList;
    },
    cancelSameIndustry(){
      if (!this.sameIndustryList || this.sameIndustryList.length === 0) {
        return;
      }
      const data = this.sameIndustryList.join(',');
      const proData = data.split(',').map(item => {
        const [key, label] = item.split('-');
        return { key: parseInt(key), label };
      });
      const params = {
        stockName:this.stockName,
        sameIndustryList:proData
      }

      unassociatedCompanyApi(params).then(res => {
        if(res.code === 200){
          this.$message.success("删除成功")
        }
      })

      console.log("params",JSON.stringify(params))

      // 将proData中的元素插入到filterData的最前面
      this.filterData = [...proData, ...this.filterData];

      // 从sameIndustryList中删除处理过的数据
      this.sameIndustryList = this.sameIndustryList.filter(item => !data.includes(item));

      //取消已选区
      const filterKeys = this.filterData.map(item => item.key)
      this.sameIndustryData = this.sameIndustryData.filter(item => !filterKeys.includes(item.key))
      const proNewData = this.sameIndustryData.map(item => ({
        label: item.label,
        key: item.key
      }));
      this.sameIndustryData = proNewData;
    },
    addPublicCompany(){
      this.$refs.addRef.validate(valid => {
        if (valid) {
          this.addMsg.expireTime = this.formatDate(this.addMsg.expireTime)
          addPublicCompanyApi(this.addMsg).then(res => {
            if(res.code === 200){
              this.$message.success("添加成功!")
            }
            this.initData();
            this.addPublicCompanyDialogVisible = false;
          })
        }
      })
    },
    initData(){
      this.tableLoading = true
      getPublicCompanyPageApi(this.pageMsg).then( res => {
        if (res.code === 200){
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total;
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    created(){
      this.addMsg = this.$options.data().addMsg;
      this.addPublicCompanyDialogVisible = true;
    },
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
    },
    editCompanyInfo(row){
      this.stockName = row.companyName;
      selectPublicCompanyInfoApi(row.companyName).then(res => {
        console.log("返回的信息:"+JSON.stringify(res))
        this.editMsg = res.result;
        this.editMsg.equityNum = 1000;
      })

      this.editPublicCompanyDialogVisible = true;
    },
    viewAssociatedCompanies(row){

      //先重置
      this.filterData = this.$options.data().filterData;
      this.sameIndustryData = this.$options.data().sameIndustryData;
      this.searchValue = this.$options.data().searchValue;

      const companyName = row.companyName;
      getUnifyAffiliatedCompanyListApi(companyName).then(res => {
        if (res.code === 200){
          const data = res.result;
          this.sameIndustryData = data.map(item => {
            return {
              label: item.label,
              key: item.key
            };
          });
        }
      })
      this.selectCompanyDialogVisible = true;
      this.stockName = companyName;
    },
    formatDate(dateTimeString){
      const dateTime = new Date(dateTimeString);
      const year = dateTime.getFullYear();
      const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
      const day = dateTime.getDate().toString().padStart(2, '0');
      const hours = dateTime.getHours().toString().padStart(2, '0');
      const minutes = dateTime.getMinutes().toString().padStart(2, '0');
      const seconds = dateTime.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  }
}

</script>


<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.center-content {
  text-align: center;
}
.container {
  border: #dfe5eb solid 1px;
  //width: 180px;
  width: 100%;
  height: 200px; /* 调整容器的高度 */
  margin: 0 auto;
  //text-align:  center;  //text-align:  center;
  border-radius: 10px;
}

.content {
  max-height: 120px; /* 设置内容区域的最大高度 */
  overflow-y: auto; /* 仅在内容溢出时显示滚动条 */
  padding: 10px;
}

.centered-row {
  display: flex;
  align-items: center;
  justify-content: space-around; /* 根据需要，可以使用其他值来调整按钮与两个相邻的 <div> 之间的间距 */
}


</style>