var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: {
                "header-cell-style": { background: "rgb(250,250,250)" },
                data: _vm.tableData,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "收益排名",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "industryName",
                  label: "行业名称",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "BA排名数",
                  prop: "rankNum",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "growthRate",
                  label: "收益率",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.growthRate.toFixed(2)) + "% "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }