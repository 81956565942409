<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model="pageMsg"
        ref="formData"
        size="small"
        label-position="left"
        label-width="44px"
      >
        <el-row class="text-left" :gutter="24">
          <el-col :span="6">
            <el-form-item label="名称:" >
              <el-input clearable
                        @keyup.enter.native="initData()"
                        v-model="pageMsg.wordName" style="width: 200px"  placeholder="请输入ESG名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="text-right">
            <el-button
              type="primary"
              size="small"
              @click="addEsgWordCli()"
            >
              新建
            </el-button>
            <el-button size="small" @click="reset()">
              重置
            </el-button>
            <el-button type="primary" size="small" @click="initData()">
              查询
            </el-button>

          </el-col>
        </el-row>
      </el-form>

      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px;margin-top: 10px"
        row-key="id"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        v-loading="tableLoading"
      >

        <el-table-column
          prop="wordName"
          label="名称"
          header-align="center"
          align="center"
          width="auto"
        >

        </el-table-column>

        <el-table-column
          prop="createdTime"
          label="创建时间"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button size="small" @click="deleteWord(row.esgWordId)"   type="text"  >
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>


    <div>
      <el-dialog
        title="新增词库信息"
        style=""
        width="500px"
        :visible.sync="addDialog"
        @close="addDialog = false" >
        <el-form
          :model="addForm"
          ref="addChannel"
          :label-position="labelPosition"
          :label-width="formLabelWidth"
        >

          <el-form-item label="名称:" prop="wordName">
            <el-input v-model="addForm.wordName" style="width: 200px"></el-input>
          </el-form-item>

          <span>
            <el-button type='primary' @click='addCli'>添加</el-button>
            <el-button >取消</el-button>
          </span>

        </el-form>


      </el-dialog>


    </div>

  </div>
</template>

<script>
import { addEsgWordApi, deleteEsgWordApi, loadEsgWordPageApi } from 'api/article'

export default {
  computed: {
    formatDate() {
      return function (oldDate) {
        // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
        function add0(num) {
          return num < 10 ? '0' + num : num
        } // 个位数的值在前面补0
        const date = new Date(oldDate)
        const Y = date.getFullYear()
        const M = date.getMonth() + 1
        const D = date.getDate()
        const h = date.getHours()
        const m = date.getMinutes()
        const s = date.getSeconds()

        const dateString =
          Y +
          '-' +
          add0(M) +
          '-' +
          add0(D) +
          '  ' +
          add0(h) +
          ':' +
          add0(m) +
          ':' +
          add0(s)
        return dateString
      }
    }
  },
  data() {
    return {
      addForm:{},
      addDialog:false,
      formLabelWidth: '80px',
      labelPosition: 'right',
      tableData:[],
      tableLoading:false,
      pageTotal:0,
      pageMsg: {
        pageNum:1,
        pageSize:10
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    addCli(){
      const esgWord = this.addForm.wordName;
      addEsgWordApi(esgWord).then(res => {
        if (res.code === 200) {
          this.$message.success("添加成功!")
          this.addDialog = false;
          this.initData();
          this.addForm = this.$options.data().addForm;
        }
      })
    },
    initData(){
      loadEsgWordPageApi(this.pageMsg).then(res =>{
        let { total, records } = res.result
        this.tableData = records
        this.pageTotal = total
      })

    },
    reset(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    },
    addEsgWordCli(){
      this.addDialog = true;
    },
    deleteWord(id) {
      this.$confirm('是否确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return deleteEsgWordApi(id)
      }).then(data => {
        this.$message.success('删除成功')
        this.initData()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
