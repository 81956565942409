var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.pageMsg,
                size: "small",
                "label-position": "left",
                "label-width": "75px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "160px" },
                            attrs: { placeholder: "请填写合同名称" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchData(true)
                              },
                            },
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售人:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "160px" },
                            attrs: { placeholder: "请填写销售人姓名" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchData(true)
                              },
                            },
                            model: {
                              value: _vm.pageMsg.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "userName", $$v)
                              },
                              expression: "pageMsg.userName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { "margin-left": "-10px" } },
                        [
                          _vm.userbtn.indexOf("CONTRACT_CREATE") >= 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reject()
                                    },
                                  },
                                },
                                [_vm._v("新建")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchData(false)
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchData(true)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.downloadPattern },
                            },
                            [_vm._v(" 导出 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "-75px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "dateType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.pageMsg.dateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "dateType", $$v)
                                },
                                expression: "pageMsg.dateType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 2 },
                                },
                                [_vm._v("日")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "50px" },
                                  attrs: { label: 4 },
                                },
                                [_vm._v("区间")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.pageMsg.dateType === 3
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "year" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: {
                                  "margin-left": "-80px",
                                  width: "180px",
                                },
                                attrs: {
                                  type: "year",
                                  "value-format": "yyyy",
                                  placeholder: "选择年",
                                },
                                model: {
                                  value: _vm.pageMsg.year,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "year", $$v)
                                  },
                                  expression: "pageMsg.year",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pageMsg.dateType === 2
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "number" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: _vm.pageMsg.day,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "day", $$v)
                                  },
                                  expression: "pageMsg.day",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pageMsg.dateType === 4
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "number" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "开始日期",
                                },
                                model: {
                                  value: _vm.pageMsg.startMonths,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "startMonths", $$v)
                                  },
                                  expression: "pageMsg.startMonths",
                                },
                              }),
                              _c("el-date-picker", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "结束日期",
                                },
                                model: {
                                  value: _vm.pageMsg.endMonths,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "endMonths", $$v)
                                  },
                                  expression: "pageMsg.endMonths",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "-163px" },
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "dateType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.pageMsg.dateTypeByNY,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "dateTypeByNY", $$v)
                                },
                                expression: "pageMsg.dateTypeByNY",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 5 },
                                },
                                [_vm._v("内部")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 6 },
                                },
                                [_vm._v("外部")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "contractName",
                  label: "合同名称",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.Detail(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.contractName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "contractAmount",
                  label: "合同金额",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contractShell",
                  label: "销售成本",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contractTd",
                  label: "销售提点",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "contractTc",
                  label: "销售提成",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "salesMan",
                  label: "销售人",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  prop: "startEndDate",
                  label: "签约日期",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.userbtn.indexOf("CONTRACT_DELETE") >= 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.basicJobDeleteOne(row.id)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "新建合同信息",
            width: "800px",
            visible: _vm.passLoading1,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading1 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel",
                  attrs: {
                    rules: _vm.userFormRules,
                    model: _vm.channelForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同名称:", prop: "contractName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请填写合同名称" },
                        model: {
                          value: _vm.channelForm.contractName,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractName", $$v)
                          },
                          expression: "channelForm.contractName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "合同金额(元):", prop: "contractAmount" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          type: "number",
                          placeholder: "请填写合同金额",
                        },
                        model: {
                          value: _vm.channelForm.contractAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractAmount", $$v)
                          },
                          expression: "channelForm.contractAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "销售成本(元):", prop: "contractShell" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          type: "number",
                          placeholder: "请填写销售成本",
                        },
                        model: {
                          value: _vm.channelForm.contractShell,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractShell", $$v)
                          },
                          expression: "channelForm.contractShell",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售提点:", prop: "contractTd" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: { placeholder: "请选择销售提点" },
                          model: {
                            value: _vm.channelForm.contractTd,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm, "contractTd", $$v)
                            },
                            expression: "channelForm.contractTd",
                          },
                        },
                        _vm._l(_vm.optionWay, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售人:", prop: "salesMan" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请填写销售人" },
                        model: {
                          value: _vm.channelForm.salesMan,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "salesMan", $$v)
                          },
                          expression: "channelForm.salesMan",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约开始日期:", prop: "startDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "签约开始日期",
                        },
                        model: {
                          value: _vm.channelForm.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "startDate", $$v)
                          },
                          expression: "channelForm.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约结束日期:", prop: "endDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "签约结束日期",
                        },
                        model: {
                          value: _vm.channelForm.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "endDate", $$v)
                          },
                          expression: "channelForm.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同描述:", prop: "contractContent" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入内容",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.channelForm.contractContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractContent", $$v)
                          },
                          expression: "channelForm.contractContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "comImage",
                      attrs: { label: "上传合同图片：", prop: "faceDocId" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadUrl,
                            headers: _vm.myHeaders,
                            "list-type": "picture-card",
                            accept: "image/jpeg,image/jpg,image/png",
                            "on-change": _vm.uploadChange,
                            "on-success": _vm.uploadReturn,
                            "on-preview": _vm.handlePictureCardPreview,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-exceed": _vm.handleExceed,
                            "on-remove": _vm.companyUploadRemove,
                            "file-list": _vm.fileList,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.channelAdd },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "查看合同信息",
            width: "800px",
            visible: _vm.passLoading2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading2 = $event
            },
            close: _vm.dialogCreate1,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel",
                  attrs: {
                    rules: _vm.userFormRules,
                    model: _vm.channelForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同名称:", prop: "contractName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写合同名称" },
                        model: {
                          value: _vm.channelForm.contractName,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractName", $$v)
                          },
                          expression: "channelForm.contractName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "合同金额(元):", prop: "contractAmount" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写合同名称" },
                        model: {
                          value: _vm.channelForm.contractAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractAmount", $$v)
                          },
                          expression: "channelForm.contractAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "销售成本(元):", prop: "contractShell" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写销售成本" },
                        model: {
                          value: _vm.channelForm.contractShell,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractShell", $$v)
                          },
                          expression: "channelForm.contractShell",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售提点:", prop: "contractTd" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写销售成本" },
                        model: {
                          value: _vm.channelForm.contractTd,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractTd", $$v)
                          },
                          expression: "channelForm.contractTd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售提成(元):", prop: "contractTc" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写销售成本" },
                        model: {
                          value: _vm.channelForm.contractTc,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractTc", $$v)
                          },
                          expression: "channelForm.contractTc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售人:", prop: "salesMan" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写合同名称" },
                        model: {
                          value: _vm.channelForm.salesMan,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "salesMan", $$v)
                          },
                          expression: "channelForm.salesMan",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约开始日期:", prop: "startDate" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写合同名称" },
                        model: {
                          value: _vm.channelForm.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "startDate", $$v)
                          },
                          expression: "channelForm.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约结束日期:", prop: "endDate" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "", placeholder: "请填写合同名称" },
                        model: {
                          value: _vm.channelForm.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "endDate", $$v)
                          },
                          expression: "channelForm.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同描述:", prop: "contractContent" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          type: "textarea",
                          placeholder: "请输入内容",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.channelForm.contractContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "contractContent", $$v)
                          },
                          expression: "channelForm.contractContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "comImage",
                      attrs: { label: "上传合同图片：", prop: "faceDocId" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadUrl,
                            headers: _vm.myHeaders,
                            "list-type": "picture-card",
                            accept: "image/jpeg,image/jpg,image/png",
                            "on-change": _vm.uploadChange,
                            "on-success": _vm.uploadReturn,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-exceed": _vm.handleExceed,
                            "on-remove": _vm.companyUploadRemove,
                            "file-list": _vm.fileList,
                            disabled: "",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }