var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.pageMsg,
                  size: "small",
                  "label-position": "center",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "类型:", prop: "interactType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择类型" },
                                model: {
                                  value: _vm.pageMsg.interactType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "interactType", $$v)
                                  },
                                  expression: "pageMsg.interactType",
                                },
                              },
                              _vm._l(_vm.iprTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.initData },
                            },
                            [_vm._v("查询")]
                          ),
                          _c("el-button", { on: { click: _vm.resetCli } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading",
                  },
                ],
                attrs: {
                  "header-cell-style": { background: "rgb(250,250,250)" },
                  data: _vm.tableData,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "iprType",
                    label: "IPR类型",
                    "header-align": "center",
                    align: "center",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }