<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">


    <!--  <el-button
              type="primary"
              size="small"
              @click="reject()"
      >

        新建
      </el-button>-->
        <el-form
                :model="pageMsg"
                ref="formData"
                size="small"
                label-position="left"
                label-width="44px"
        >
            <el-row class="text-left" :gutter="24">
                <el-col :span="6">
                    <el-form-item label="名称:" >
                        <el-input clearable
                                  @keyup.enter.native="searchData(true)"
                                  v-model="pageMsg.wordName" style="width: 200px"  placeholder="请输入词库名称"></el-input>
                    </el-form-item>

                </el-col>
                <!-- <el-col :span="1">
                  <p style="opacity: 0"></p>
                </el-col> -->

                <el-col :span="10">
                    <el-form-item  >
                        <el-radio-group v-model="pageMsg.wordType"  @keyup.enter.native="searchData(true)">
                            <el-radio :label="1" style="margin-right: 10px">屏蔽词</el-radio>
                            <el-radio :label="3" style="margin-right: 10px">有效词</el-radio>
                            <el-radio :label="4" style="margin-right: 10px">有效原因</el-radio>
                            <el-radio :label="2" style="margin-right: 10px">无效原因</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="8" class="text-right">
                    <el-button
                            type="primary"
                            size="small"
                            @click="reject()"
                    >
                        新建
                    </el-button>
                    <el-button size="small" @click="searchData(false)">
                        重置
                    </el-button>
                    <el-button type="primary" size="small" @click="searchData(true)">
                        查询
                    </el-button>

                </el-col>
            </el-row>
        </el-form>

      <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px;margin-top: 10px"
              row-key="id"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              v-loading="tableLoading"
      >

        <el-table-column
                prop="wordName"
                label="名称"
                header-align="center"
                align="center"
                width="auto"
        >

        </el-table-column>

        <el-table-column
                prop="wordType"
                label="类型"
                header-align="center"
                align="center"
                width="auto"
        >
            <template slot-scope="{ row }">
                <p
                        v-if="row.wordType === 0"
                >
                    屏蔽词
                </p>
                <p
                        v-if="row.wordType === 1"
                >
                    无效原因
                </p>
                <p
                        v-if="row.wordType === 2"
                >
                    有效词
                </p>
                <p
                        v-if="row.wordType === 3"
                >
                    有效原因
                </p>
            </template>

        </el-table-column>

        <el-table-column
                prop="createdTime"
                label="创建时间"
                header-align="center"
                align="center"
                width="auto"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column
                label="操作"
                header-align="center"
                align="center"
                fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button size="small" @click="edit(row)"   type="text" >
              编辑
            </el-button>
            <el-button size="small" @click="deleteWord(row.id)"   type="text"  >
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="pb20"></div>
      <el-pagination
              class="text-center"
              v-if="pageTotal > 0"
              @size-change="initData"
              @current-change="initData"
              :current-page.sync="pageMsg.pageNum"
              :page-size.sync="pageMsg.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <el-dialog   title="新增词库信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading1"  @close="dialogCreate" >

        <div class="channel-add">
      <el-form
              :rules="userFormRules"
              :model="channelForm"
              ref="addChannel"
              :label-position="labelPosition"
              :label-width="formLabelWidth"
      >
        <el-form-item label="名称:" prop="wordName">
          <el-input v-model="channelForm.wordName" style="width: 500px"></el-input>

        </el-form-item>
		
        <el-form-item label="类型:" prop="wordType">
          <el-radio-group v-model="channelForm.wordType" @input="parentClassChange">
            <el-radio :label="0" style="margin-right: 10px">无效词</el-radio>
              <el-radio :label="2" style="margin-right: 10px">有效词</el-radio>
            <el-radio :label="1" style="margin-right: 10px" >无效原因</el-radio>
              <el-radio :label="3" style="margin-right: 10px">有效原因</el-radio>
              <!-- <el-radio :label="4" style="margin-right: 10px" >一级类目</el-radio> -->
          </el-radio-group>

        </el-form-item>

      </el-form>
          <div align="center">   
		  <span>
      <el-button @click="dialogCreate" >取消</el-button>
      <el-button type="primary"  :loading="loading" @click="channelAdd">确定</el-button>
		</span>
		</div>

        </div>

    </el-dialog>

      <el-dialog   title="修改词库信息" style="margin-left: -80px"   width="800px"  :visible.sync="passLoading2"  @close="dialogCreate1" >

          <div class="channel-add">
              <el-form
                      :model="channelForm1"
                      ref="addChannel1"
                      :rules="userFormRules1"
                      :label-position="labelPosition"
                      :label-width="formLabelWidth"
              >
                  <el-form-item label="名称:" prop="wordName">
                      <el-input v-model="channelForm1.wordName" style="width: 500px"></el-input>
                  </el-form-item>
                  <el-form-item label="类型:" prop="wordType">
                      <el-radio-group v-model="channelForm1.wordType">
                          <el-radio :label="0" style="margin-right: 10px">屏蔽词</el-radio>
                          <el-radio :label="2" style="margin-right: 10px">有效词</el-radio>
                          <el-radio :label="1" style="margin-right: 10px">无效原因</el-radio>
                          <el-radio :label="3" style="margin-right: 10px">有效原因</el-radio>
                      </el-radio-group>

                  </el-form-item>

              </el-form>
              <div align="center">   <span  >
      <el-button @click="dialogCreate1" >取消</el-button>
      <el-button type="primary" :loading="loading1"  @click="channelAdd1">确定</el-button>
    </span></div>

          </div>

      </el-dialog>

  </div>
</template>

<script>
import {basicPageApi,basicWordAddApi,basicWordEditApi,basicWordDelete } from 'api/article'
export default {

    name: 'contentReview',

    computed: {
        formatDate() {
            return function (oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()

                const dateString =
                    Y +
                    '-' +
                    add0(M) +
                    '-' +
                    add0(D) +
                    '  ' +
                    add0(h) +
                    ':' +
                    add0(m) +
                    ':' +
                    add0(s)
                return dateString
            }
        }
    },

    data() {
        return {
			// parentClass:false, //一级类目有效or无效显示
            loading: false,
            loading1: false,

            channelForm: {
                wordName: undefined,
                wordType: 0,
            },
			parentForm: {
				workType: 0,
			},
            channelForm1: {
                id: '',
                wordName: '',
                wordType: 0,
            },
            userFormRules: {
                wordName: [{required: true, message: '名称不能为空', trigger: 'blur'}],
                wordType: [{required: true, message: '类型不能为空', trigger: 'blur'}],
            },
            userFormRules1: {
                wordName: [{required: true, message: '名称不能为空' ,trigger: 'blur'}],
                wordType: [{required: true, message: '类型不能为空' ,trigger: 'blur'}],
            },
            passLoading1: false,
            passLoading2: false,
            pageForm:{
                pageNum: 1,
                pageSize: 10,
                wordType:null,
            },
            formLabelWidth: '160px',
            labelPosition: 'right',
            tableData: [],
            pageTotal: 0,
            pageMsg: {
                pageNum: 1,
                pageSize: 10,
                wordType:undefined,
                wordName:undefined

            },
            tableLoading: false,
        }
    },
    mounted() {

        this.initData()

    },
    methods: {
		//一级类目按钮事件
		// parentClassChange(){
		// 	if(this.channelForm.wordType === 4){
		// 		this.parentClass = true;
		// 	}
			
		// 	if(this.channelForm.wordType!=4){
		// 		this.parentClass = false;
		// 	}
		// 	console.log(this.channelForm.wordType)
		// },
		// //无效原因按钮事件
		// invalidChange(){
		// },
		// //无效原因按钮事件
		// validChange(){
		// },
        searchData(bool) {
            if (bool) {
                this.pageMsg.pageNum = 1
                this.pageMsg.pageSize = 10

            } else {
                this.pageMsg = this.$options.data().pageMsg

            }
            this.initData()
        },
        edit(row){

            this.passLoading2 = true;

            this.channelForm1.wordName = row.wordName
            this.channelForm1.wordType = row.wordType
            this.channelForm1.id = row.id
        },
        dialogCreate() {
            this.channelForm.wordName = undefined;
            this.passLoading1 = false
        },
        dialogCreate1() {
            this.passLoading2 = false
        },
		//新建方法-Dream
        reject() {
            this.passLoading1 = true;
            this.channelForm = this.$options.data().channelForm
        },
        channelAdd() {

            this.$refs.addChannel.validate(valid => {
                this.loading = true
                if (valid) {
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicWordAddApi({ ...this.channelForm }).then(res => {
                            if (res.code == 200) {

                                this.$message.success('保存成功')
                                this.channelForm = this.$options.data().channelForm
                                this.passLoading1 = false
                                this.loading = false
                                this.initData()
                            }
                        this.loading = false
                        })
                }
            })
        },
        deleteWord(id) {

                this.$confirm('是否确认删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    return basicWordDelete(id)
                }).then(data => {
                    this.$message.success('删除成功')
                    this.initData()
                })

        },

        channelAdd1() {
            this.$refs.addChannel1.validate(valid => {
                this.passLoading2 = true
                if (valid) {
                    this.loading1 = true
                    //delete this.channelForm.dateValue
                    // delete this.channelForm.createdByUser
                    basicWordEditApi({ ...this.channelForm1 }).then(res => {
                        if (res.code == 200) {

                            this.$message.success('修改成功')

                            this.channelForm1 = this.$options.data().channelForm1
                            this.passLoading2 = false

                            this.loading1 = false

                            this.initData()
                        }

                    })
                }
            })
        },
        initData() {
            this.tableLoading = true
            const data = {
                ...this.pageMsg
            }
            basicPageApi(this.pageMsg).then(res => {
                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData = records
                    this.pageTotal = total
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading = false
            })
        },

    }
}
</script>

<style lang="scss" scoped>
  .channelManagement {
    width: 100%;
    .channelManagement-head {
      padding: 10px 20px;
      background: #fff;
    }
    .box-card {
      width: calc(100% - 60px);
      padding: 10px;
      margin: 20px auto;
    }
    /deep/.state-table {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .select_warn {
      background: rgb(230, 247, 255);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      span {
        color: $primary;
      }
    }
  }
</style>
