<template>
  <div class="userlist">
    <div class="channelManagement-head">
      <h1>用户列表</h1>
    </div>
    <el-card class="box-card">
      <el-row class="text-left">
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
        >
          <el-col :span="6">
            <el-form-item label="账户：" class="limitinputWidth">
              <el-input clearable v-model="pageMsg.searchWord"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1"> <p style="opacity: 0">1</p> </el-col>
          <el-col :span="6">
            <el-form-item label="角色：" class="limitinputWidth">
              <el-select v-model="pageMsg.roleId" placeholder="请选择">
                <el-option
                  v-for="item in stateOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                clearable
                v-model="pageMsg.roleId"
              ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="10" class="text-right">
            <el-button type="primary" size="small" @click="searchData(true)">
              查询
            </el-button>
            <el-button size="small" @click="searchData(false)">
              重置
            </el-button>

            <!-- <el-col class="text-right" style="padding: 10px 0"> -->
            <el-button
              type="primary"
              size="small"
              @click="addAndEditDialog()"
              v-if="userbtn.indexOf('USERLIST_ADD') >= 0"
            >
              新增用户
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="platformExport"
              v-if="userbtn.indexOf('USERLIST_EXPORT') >= 0"
            >
              导出列表
            </el-button>
          </el-col>
        </el-form>
      </el-row>
      <div class="select_warn" v-if="selectionArr.length > 0">
        已经选择{{ selectionArr.length }}项
        <el-button
          type="text"
          @click="endList(2)"
          v-if="userbtn.indexOf('USERLIST_MOREHIDE') >= 0"
          >禁用</el-button
        >
        <el-button
          type="text"
          @click="endList(1)"
          v-if="userbtn.indexOf('USERLIST_MOREUP') >= 0"
          >启用</el-button
        >
      </div>
      <el-table
        v-loading="tableLoading"
        header-row-class-name="tableHead"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        ref="multipleTable"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="username"
          label="账户(手机号)"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <!-- <el-table-column
          prop="mobile"
          label="手机"
          header-align="center"
          align="center"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="email"
          label="邮箱"
          header-align="center"
          align="center"
          width="250"
        >
        </el-table-column> -->
        <el-table-column
          prop="rolesInfoName"
          label="用户角色"
          header-align="center"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="rolesInfoName"
          label="角色备注"
          header-align="center"
          align="center"
          width="180"
        >
          <template slot-scope="{ row }">
            {{ row.rolesInfo[0].remarks }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="status"
          label="用户状态"
          header-align="center"
          align="center"
        >
          <template slot-scope="{ row }">
            {{
              row.status == -1 ? '未激活' : row.status == 1 ? '在职' : '离职'
            }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="updatedTime"
          label="更新时间"
          header-align="center"
          align="center"
          width="180"
        >
          <template slot-scope="{ row }">
            {{ new Date(row.updatedTime).toLocaleString() }}
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <!-- v-if="$index !== 0" -->
              <!-- <el-button
                type="text"
                size="small"
                @click.native.prevent="lookdetail(row)"
              >
                查看
              </el-button> -->
              <el-button
                type="text"
                size="small"
                v-if="userbtn.indexOf('USERLIST_EDIT') >= 0"
                @click.native.prevent="addAndEditDialog(row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="row.status === 1 && userbtn.indexOf('USERLIST_HIDE') >= 0"
                @click.native.prevent="enableDisablingRole(row)"
              >
                禁用
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="row.status !== 1 && userbtn.indexOf('USERLIST_UP') >= 0"
                @click.native.prevent="enableDisablingRole(row)"
              >
                启用
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      width="40%"
      :visible.sync="userDetailVisible"
      @close="userDialogClose"
      :close-on-click-modal="false"
    >
      <div slot="title" class="text-left">
        {{ userDetailOperate }}
      </div>
      <el-form
        ref="userDetail"
        :model="userFormDetail"
        :rules="userFormRules"
        label-width="150px"
      >
        <el-form-item label="用户账号(手机号)：" prop="username">
          <el-input
            maxlength="11"
            clearable
            v-model="userFormDetail.username"
          ></el-input>
        </el-form-item>
        <!-- (不填写即原密码) -->
        <el-form-item label="密码：" v-if="userDetailOperate == '编辑用户'">
          <el-input
            clearable
            v-model="userFormDetail.updPassword"
            placeholder="(不填写即原密码)"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="addPassword"
          v-if="userDetailOperate == '新增用户'"
        >
          <el-input
            show-password
            clearable
            v-model="userFormDetail.addPassword"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="name">
          <el-input
            clearable
            v-model="userFormDetail.name"
            placeholder="输入昵称"
          ></el-input>
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
          <el-input
            clearable
            v-model="userFormDetail.email"
            placeholder="输入邮箱"
          ></el-input>
        </el-form-item>



        <!-- <el-form-item label="用户邮箱：" prop="email">
          <el-input clearable v-model="userFormDetail.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="mobile">
          <el-input clearable v-model="userFormDetail.mobile"></el-input>
        </el-form-item> -->
        <!-- prop="roles" -->
        <el-form-item label="用户角色：" prop="roleId">
          <!-- filterable -->
          <!-- <el-select
            v-model="userFormDetail.roles"
            remote
            reserve-keyword
            placeholder="请输入关键词搜索"
            :remote-method="remoteMethod"
            style="width: 100%"
            @change="changeRoleList"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id + ''"
              :label="item.name"
              :value="item.id + ''"
            ></el-option>
          </el-select> -->
          <el-select v-model="userFormDetail.roleId" placeholder="请选择">
            <el-option
              v-for="item in newRoleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片：" prop="faceDocId" class="comImage">
          <el-upload
            :action="uploadUrl"
            :headers="myHeaders"
            list-type="picture-card"
            accept="image/jpeg,image/jpg,image/png"
            :on-change="uploadChange"
            :on-success="uploadReturn"
            :before-upload="beforeAvatarUpload"
            :on-exceed="handleExceed"
            :on-remove="companyUploadRemove"
            :limit="1"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer text-center">
        <el-button
          size="small"
          @click.native.prevent="userDetailVisible = false"
        >
          取 消
        </el-button>
        <el-button size="small" type="primary" @click.native.prevent="saveMsg">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="40%"
      :visible.sync="userDetailTextVisible"
      :close-on-click-modal="false"
    >
      <div slot="title" class="text-left">查看用户</div>
      <el-form label-width="150px">
        <el-form-item label="用户账号(手机号)：" prop="username">
          {{ userDetailText.username }}
        </el-form-item>
        <el-form-item label="用户名称：" prop="name">
          {{ userDetailText.name }}
        </el-form-item>
        <el-form-item label="用户角色：" prop="rolesInfoName">
          {{ userDetailText.rolesInfoName }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getUserList,
  addUser,
  editUser,
  getRoleList1,
  enableDisableUserApi,
  platformExportApi
} from 'api/rightsetting'
import constant from 'constant'
export default {
  name: 'userlist',
  data() {
    let checkname = (rule, value, callback) => {
      let check = /^1[3-9]\d{9}$/.test(value)
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!check) {
        callback(new Error('手机号格式错误!'))
      } else {
        callback()
      }
    }
    let validateImage = (rule, value, callback) => {
      //验证器
      console.log(this.userFormDetail.faceDocId)
      if (!this.userFormDetail.faceDocId) {
        //为true代表图片在  false报错
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: { pageNum: 1, pageSize: 10 },
      userDetailVisible: false,
      userDetailTextVisible: false,
      userDetailOperate: '新增用户',
      roleList: [],
      newRoleList: [],
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      imageUrl: '',
      userFormDetail: {
        username: '',
        name: '',
        password: '',
        email:null,
        roleId: null,
        faceDocId: ''
      },
      userFormRules: {
        username: [
          { required: true, message: '用户账号(手机号)不能为空' },
          { max: 11, message: '最多11个字符', trigger: 'blur' },
          { validator: checkname, trigger: 'blur' }
        ],
        addPassword: [
          { required: true, message: '密码不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '昵称不能为空' },
          { max: 20, message: '最多20个字符', trigger: 'blur' }
        ],
        email:[
          { required: true, message: '邮箱不能为空' },
          { max: 50, message: '最多50个字符', trigger: 'blur' }
        ],
        roleId: [{ required: true, message: '角色不能为空' }],
        faceDocId: [
          { required: true, validator: validateImage, trigger: 'change' }
        ]
      },
      userDetailText: {},
      chosedRoleList: [],
      stateOption: [],
      tableLoading: false,
      fileList: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    uploadChange(file, fileList) {
      this.fileList = fileList
    },
    uploadReturn(res) {
      if (res.code === 200) {
        this.userFormDetail.faceDocId = res.result.id
        this.$refs.userDetail.validateField('faceDocId')
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    },
    handleExceed() {
      this.$message.warning(`当前限制上传 1 张图片`)
    },
    companyUploadRemove(file) {
      this.userFormDetail.faceDocId = ''
      this.$refs.userDetail.validateField('faceDocId')
      this.fileList = this.fileList.filter(item => {
        return item.url != file.url
      })
    },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      getUserList(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
      this.initRoles()
    },
    initRoles() {
      getRoleList1().then(res => {
        this.newRoleList = res.result.records
        this.stateOption = res.result.records
          console.log('dsdkjhdkjv', this.stateOption)
      })
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    endList(status) {
      console.log(status)
      console.log(this.selectionArr)
      this.selectionArr.map((res, index) => {
        enableDisableUserApi({ id: res.id, status }).then(res => {
          if (res.code === 200) {
            if (index + 1 == this.selectionArr.length) {
              this.$message({
                message: status === 1 ? '启用成功' : '禁用成功',
                type: 'success'
              })
              this.initData()
            }
          } else {
            this.$message({
              message: res.desc,
              type: 'error'
            })
          }
        })
      })
    },
    platformExport() {
      platformExportApi({
        ...this.pageMsg
      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = new Date().toLocaleDateString()
        link.download = `用户导出列表${dateXls}.csv`
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    // 启用禁用
    enableDisablingRole(data = '') {
      let status = data.status === 1 ? 2 : 1
      // data.status 为1 是启用 去禁用 disable为0
      enableDisableUserApi({ id: data.id, status }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: status === 1 ? '启用成功' : '禁用成功',
            type: 'success'
          })
          this.initData()
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },
    userDialogClose() {
      this.$refs.userDetail.resetFields()
      for (let item in this.userFormDetail) {
        delete this.userFormDetail[item]
      }
    },
    lookdetail(data) {
      this.userDetailText = { ...data }
      this.userDetailTextVisible = true
    },
    addAndEditDialog(data = '') {
      if (data) {
        console.log(data)
        this.userFormDetail = {
          username: data.username,
          name: data.name,
          email: data.email,
          password: '',
          id: data.id,
          roleId: null,
          roles: data.rolesInfo
        }
        this.fileList = data.faceDoc ? [{ ...data.faceDoc }] : []
        this.userFormDetail.faceDocId = data.faceDoc ? data.faceDoc.id : null
        this.roleList = data.rolesInfo
        if (data.rolesInfoStr) {
          this.userFormDetail.roleId = Number(data.rolesInfoStr)
        }
        this.userDetailOperate = '编辑用户'
      } else {
        this.userDetailOperate = '新增用户'
      }
      this.userDetailVisible = true
    },
    saveMsg() {
      this.$refs.userDetail.validate(valid => {
        if (valid) {
          console.log(this.userDetailOperate)
          if (this.userDetailOperate == '编辑用户') {
            let sendData = { ...this.userFormDetail }
            // sendData.pageSize = this.pageMsg.pageSize
            // sendData.pageNum = this.pageMsg.pageNum
            // this.userFormDetail.roleId
            console.log(this.newRoleList)
            let newRoles = this.newRoleList.map(newres => {
              if (newres.id == this.userFormDetail.id) {
                return newres
              }
            })
            console.log(newRoles)
            this.userFormDetail.roles = [newRoles]
            sendData.roles = [newRoles]
            if (this.userFormDetail.updPassword !== '') {
              sendData.password = this.userFormDetail.updPassword
            }

            console.log(sendData, 'sendData')
            editUser(sendData).then(res => {
              this.userDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '更新成功'
                })
                if (
                  sendData.id ==
                  JSON.parse(localStorage.getItem('USER_ALL_INFO')).id
                ) {
                  this.$store.dispatch('user/getInfo').then(() => {
                    location.reload()
                  })
                }
                let { pageSize, pageNum } = this.pageMsg
                this.initData({ size: pageSize, num: pageNum })
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          } else {
            let sendData = { ...this.userFormDetail }
            // sendData.size = this.pageMsg.pageSize
            // sendData.page = this.pageMsg.pageNum
            sendData.password = this.userFormDetail.addPassword
            addUser(sendData).then(res => {
              this.userDetailVisible = false
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '添加成功'
                })
                let { pageSize, pageNum } = this.pageMsg
                this.initData({ size: pageSize, num: pageNum })
              } else {
                this.$message({
                  message: res.desc,
                  type: 'error'
                })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // handleSizeChange(val) {
    //   this.pageMsg.pageSize = val;
    //   this.pageMsg.pageNum = 1;
    //   let { pageSize, pageNum } = this.pageMsg;
    //   let sendData = { page: pageNum, size: pageSize };
    //   this.initData(sendData);
    // },
    // handleCurrentChange(val) {
    //   this.pageMsg.pageNum = val;
    //   let { pageSize, pageNum } = this.pageMsg;
    //   let sendData = { page: pageNum, size: pageSize };
    //   this.initData(sendData);
    // },
    remoteMethod(val) {
      if (val) {
        getRoleList({ keywords: val, disable: 0 }).then(res => {
          if (res.code === 200) {
            this.roleList = res.result.records
          } else {
            this.$message({
              type: 'error',
              message: res.desc
            })
          }
        })
      } else {
        this.roleList = []
      }
    },
    changeRole(data) {
      console.log(data)
    },
    changeRoleList(data) {
      console.log(data, 'data')
      console.log(this.roleList, 'this.roleList')
      data.map(item => {
        return { id: item, name: this.roleList }
      })
      let sendRoleList = []
      for (let i = 0; i < this.roleList.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (this.roleList[i].id == data[j]) {
            sendRoleList.push({ id: data[j], name: this.roleList[i].name })
          }
        }
      }
      this.chosedRoleList = sendRoleList
      // console.log(this.chosedRoleList, 'this.chosedRoleList')
      this.$forceUpdate()
    },
    handleSelectionChange(e) {
      console.log(e)
      this.selectionArr = e
    },
    uploadReturnLogo(res) {
      if (res.code === 200) {
        console.log(res)
        // this.formData.publishLogoId = res.result.id
      }
    },
    beforeAvatarUploadLogo(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style lang="scss" scoped>
.userlist {
  /deep/ .el-dialog__header {
    border-bottom: solid 1px #eee !important;
  }
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    span {
      color: $primary;
    }
  }
  .el-upload-list__item {
    transition: none !important;
  }
  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
  .el-upload-list__item.is-ready {
    display: none;
  }
}
</style>