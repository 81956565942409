
<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">总阅读量</div>

    <div class="water-level-chart-details">
      <dv-digital-flop :config="config" style="width:200px;height:50px;" />
    </div>
  </div>
</template>

<script>

import { queryTopDataApi } from 'api/data'

export default {
  name: 'reading',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      config:{}
    }
  },
  created() {
    this.initData();
  },
  methods:{
    initData(){
      queryTopDataApi(this.dateType).then(res => {
        if (res.code === 200){
          const config = {
            number: [res.result.readNum],
            toFixed: 0,
            content: '{nt}',
          }
          this.config = config;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#water-level-chart {
  width: 31%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 30%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 100px;
    height: 100px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
