var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("router-view"),
      _vm.$route.path == "/websiteMange"
        ? _c(
            "div",
            { staticClass: "channelManagement" },
            [
              _vm._m(0),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  { staticClass: "cloum" },
                  [
                    _c("p", { staticClass: "cloum_tit" }, [
                      _vm._v("轮播图设置："),
                    ]),
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        staticStyle: { width: "600px" },
                        attrs: {
                          drag: "",
                          action: _vm.uploadUrl,
                          headers: _vm.myHeaders,
                          accept: "image/jpeg,image/jpg,image/png",
                          "on-change": _vm.uploadChange,
                          "on-success": _vm.uploadReturn,
                          "before-upload": _vm.beforeAvatarUpload,
                          "on-exceed": _vm.handleExceed,
                          limit: 10,
                          multiple: "",
                          "file-list": _vm.fileList,
                          "show-file-list": false,
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v(" 将文件拖到此处，或"),
                          _c("em", [_vm._v("点击上传")]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [
                            _vm._v(
                              " 只能上传jpg/png文件，且不超过2M (轮播图建议尺寸 宽:850px; 高:380px;) "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "144px",
                      "margin-bottom": "30px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "carousel_warps" },
                      _vm._l(_vm.carouselList, function (d) {
                        return _c(
                          "div",
                          { key: d.id, staticClass: "carousel_item" },
                          [
                            _c("i", {
                              staticClass: "el-icon-circle-close close_icon",
                              on: {
                                click: function ($event) {
                                  return _vm.companyUploadRemove(d)
                                },
                              },
                            }),
                            _c("img", {
                              attrs: { src: d.url || d.data, alt: "" },
                            }),
                            _c("p", [_vm._v(_vm._s(d.docRealName))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.userbtn.indexOf("WEBSIT_CAROUSEL_SET") >= 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "-10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.submitCarouse },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "cloum" }, [
                  _c("p", { staticClass: "cloum_tit" }, [
                    _vm._v("动态企业设置："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cloum_con" },
                    [
                      _c("span", [
                        _vm._v("请输入企业名称，不同企业名称之间用 "),
                        _c("b", { staticStyle: { color: "red" } }, [
                          _vm._v("中文；符号"),
                        ]),
                        _vm._v(" 分割，结尾不需要加；字符最多不超过300字"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder:
                            "请输入企业名称，不同企业名称之间用中文；分割，字符最多不超过300字",
                          maxlength: "300",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.categoryText,
                          callback: function ($$v) {
                            _vm.categoryText = $$v
                          },
                          expression: "categoryText",
                        },
                      }),
                      _vm.userbtn.indexOf("WEBSIT_CATEGORY_SET") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitCategory },
                            },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "cloum" }, [
                  _c("p", { staticClass: "cloum_tit" }, [
                    _vm._v("动态热词设置："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cloum_con" },
                    [
                      _c("span", [
                        _vm._v("请输入热词，不同热词之间用 "),
                        _c("b", { staticStyle: { color: "red" } }, [
                          _vm._v("中文；符号"),
                        ]),
                        _vm._v(" 分割，结尾不需要加；字符最多不超过300字"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder:
                            "请输入热词，不同热词之间用；分割，字符最多不超过300字",
                          maxlength: "300",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.hotWord,
                          callback: function ($$v) {
                            _vm.hotWord = $$v
                          },
                          expression: "hotWord",
                        },
                      }),
                      _vm.userbtn.indexOf("WEBSIT_HOTWORD_SET") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitHotWord },
                            },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "cloum" }, [
                  _c("p", { staticClass: "cloum_tit" }, [
                    _vm._v("企业发布数据设置："),
                  ]),
                  _c("div", { staticClass: "cloum_wrap" }, [
                    _c(
                      "div",
                      { staticClass: "cloum_left" },
                      [
                        _c(
                          "div",
                          { staticClass: "cloum_item" },
                          [
                            _c("p", [_vm._v("开关：")]),
                            _c("el-switch", {
                              model: {
                                value: _vm.sendDetail.isOpen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendDetail, "isOpen", $$v)
                                },
                                expression: "sendDetail.isOpen",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.sendDetail.data, function (d, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "cloum_item" },
                            [
                              [
                                d.isFixed
                                  ? _c("p", [_vm._v(_vm._s(d.name))])
                                  : _c("el-input", {
                                      model: {
                                        value: d.name,
                                        callback: function ($$v) {
                                          _vm.$set(d, "name", $$v)
                                        },
                                        expression: "d.name",
                                      },
                                    }),
                              ],
                              _c("el-input", {
                                on: { blur: _vm.frontChartBar },
                                model: {
                                  value: d.val,
                                  callback: function ($$v) {
                                    _vm.$set(d, "val", $$v)
                                  },
                                  expression: "d.val",
                                },
                              }),
                              !d.isFixed
                                ? _c("i", {
                                    staticClass: "el-icon-circle-close",
                                    staticStyle: {
                                      "margin-left": "10px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delSendDetailItem(i)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.addIndexItem },
                          },
                          [_vm._v("+ 新增指标")]
                        ),
                        _vm.userbtn.indexOf("WEBSIT_DATA_SET") >= 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitSend },
                              },
                              [_vm._v("保存")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ring_wrap" },
                    [_c("chart-box", { ref: "bottom1" })],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "channelManagement-head" }, [
      _c("h1", [_vm._v("基本设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }