var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            { ref: "formData", attrs: { model: _vm.pageMsg, size: "small" } },
            [
              _c(
                "el-row",
                { staticClass: "text-left", attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.reject()
                            },
                          },
                        },
                        [_vm._v(" 添加任务 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "任务类型:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择任务类型" },
                              model: {
                                value: _vm.pageMsg.jobType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.pageMsg, "jobType", $$v)
                                },
                                expression: "pageMsg.jobType",
                              },
                            },
                            _vm._l(_vm.optionContent, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8, align: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(false)
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.searchData(true)
                            },
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "margin-top": "10px",
              },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                "header-row-class-name": "tableHead",
                "header-cell-style": { background: "rgb(250,250,250)" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "jobType",
                  label: "任务类型",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "jobTime",
                  label: "执行时间",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isShop",
                  label: "交易时间",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.isShop === 0 ? _c("p", [_vm._v(" 是 ")]) : _vm._e(),
                        row.isShop === 1 ? _c("p", [_vm._v(" 否 ")]) : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isDay",
                  label: "工作日",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.isDay === 0
                          ? _c("p", [_vm._v(" 每天 ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "stockName",
                  label: "公司简称",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: "行业名称",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.isDel === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.basicJobDeleteOne(row.id)
                                  },
                                },
                              },
                              [_vm._v(" 禁用 ")]
                            )
                          : _vm._e(),
                        row.isDel === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.basicJobDeleteZero(row.id)
                                  },
                                },
                              },
                              [_vm._v(" 启用 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "新增任务信息",
            width: "800px",
            visible: _vm.passLoading1,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading1 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel",
                  attrs: {
                    rules: _vm.userFormRules,
                    model: _vm.channelForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务类型:", prop: "jobType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "auto" },
                          attrs: { placeholder: "请选择任务类型" },
                          model: {
                            value: _vm.channelForm.jobType,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm, "jobType", $$v)
                            },
                            expression: "channelForm.jobType",
                          },
                        },
                        _vm._l(_vm.optionContent, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.channelForm.jobType === "竞品信息推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属行业:", prop: "categoryCode" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.options,
                              props: { checkStrictly: "true" },
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.channelForm.industryCategoryArr,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.channelForm,
                                  "industryCategoryArr",
                                  $$v
                                )
                              },
                              expression: "channelForm.industryCategoryArr",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm.jobType === "竞品信息推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送时间:", prop: "jobTime" } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              "picker-options": {
                                start: "00:00",
                                step: "00:03",
                                end: "23:57",
                              },
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.channelForm.jobTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "jobTime", $$v)
                              },
                              expression: "channelForm.jobTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm.jobType === "分发报告推送" &&
                  _vm.isAdmin === true
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送公司:", prop: "stockName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请填写公司简称" },
                            model: {
                              value: _vm.channelForm.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "stockName", $$v)
                              },
                              expression: "channelForm.stockName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm.jobType === "分发报告推送" &&
                  _vm.isAdmin === false
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送公司:", prop: "stockName" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.channelForm.stockName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.channelForm, "stockName", $$v)
                                },
                                expression: "channelForm.stockName",
                              },
                            },
                            _vm._l(_vm.stockClientOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm.jobType === "分发报告推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送日期:", prop: "isDay" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.channelForm.isDay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.channelForm, "isDay", $$v)
                                },
                                expression: "channelForm.isDay",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 0 },
                                },
                                [_vm._v("每天")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm.jobType === "分发报告推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送时间:", prop: "jobTime" } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              "picker-options": {
                                start: "00:00",
                                step: "00:03",
                                end: "23:57",
                              },
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.channelForm.jobTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "jobTime", $$v)
                              },
                              expression: "channelForm.jobTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.channelAdd },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-left": "-80px" },
          attrs: {
            title: "修改客户信息",
            width: "800px",
            visible: _vm.passLoading2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading2 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                {
                  ref: "addChannel1",
                  attrs: {
                    model: _vm.channelForm1,
                    rules: _vm.userFormRules1,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务类型:", prop: "jobType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "auto" },
                          attrs: { placeholder: "请选择任务类型" },
                          model: {
                            value: _vm.channelForm1.jobType,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm1, "jobType", $$v)
                            },
                            expression: "channelForm1.jobType",
                          },
                        },
                        _vm._l(_vm.optionContent, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.channelForm1.jobType === "竞品信息推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属行业:", prop: "categoryCode" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.options,
                              props: { checkStrictly: "true" },
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.channelForm1.industryCategoryArr,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.channelForm1,
                                  "industryCategoryArr",
                                  $$v
                                )
                              },
                              expression: "channelForm1.industryCategoryArr",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm1.jobType === "竞品信息推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送时间:", prop: "jobTime" } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              "picker-options": {
                                start: "08:30",
                                step: "00:15",
                                end: "18:30",
                              },
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.channelForm.jobTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "jobTime", $$v)
                              },
                              expression: "channelForm.jobTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm1.jobType === "分发报告推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送公司:", prop: "stockName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请填写公司简称" },
                            model: {
                              value: _vm.channelForm.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "stockName", $$v)
                              },
                              expression: "channelForm.stockName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm1.jobType === "竞品信息推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "交易时间:", prop: "isShop" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.channelForm.isShop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.channelForm, "isShop", $$v)
                                },
                                expression: "channelForm.isShop",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 0 },
                                },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 1 },
                                },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm1.jobType === "分发报告推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送日期:", prop: "isDay" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.channelForm.isDay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.channelForm, "isDay", $$v)
                                },
                                expression: "channelForm.isDay",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { label: 0 },
                                },
                                [_vm._v("每天")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelForm1.jobType === "分发报告推送"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推送时间:", prop: "jobTime" } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              "picker-options": {
                                start: "08:30",
                                step: "00:15",
                                end: "18:30",
                              },
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.channelForm.jobTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "jobTime", $$v)
                              },
                              expression: "channelForm.jobTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate1 } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading1 },
                        on: { click: _vm.channelAdd1 },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑邮件",
            width: "800px",
            visible: _vm.passLoading4,
          },
          on: {
            "update:visible": function ($event) {
              _vm.passLoading4 = $event
            },
            close: _vm.dialogCreate,
          },
        },
        [
          _c(
            "div",
            { staticClass: "channel-add" },
            [
              _c(
                "el-form",
                { ref: "addChannel2", attrs: { model: _vm.contentData } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题：", prop: "subject" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.contentData.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.contentData, "subject", $$v)
                          },
                          expression: "contentData.subject",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容：", prop: "articleContent" } },
                    [
                      _c("vue-tinymce", {
                        attrs: { setting: _vm.setting },
                        model: {
                          value: _vm.contentData.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.contentData, "content", $$v)
                          },
                          expression: "contentData.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { attrs: { align: "center" } }, [
                _c(
                  "span",
                  [
                    _c("el-button", { on: { click: _vm.dialogCreate2 } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading1 },
                        on: { click: _vm.channelAdd2 },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }