<template>
  <div class="channel-add">
    <el-form
      :rules="userFormRules"
      ref="addChannel"
      :model="channelForm"
      :label-position="labelPosition"
      :label-width="formLabelWidth"
    >
      <el-form-item label="媒介名称" prop="channelName">
        <el-input v-model="channelForm.channelName"></el-input>
      </el-form-item>

      <el-form-item label="权益接口" prop="apiUrl">
        <el-input v-model="channelForm.apiUrl"></el-input>
      </el-form-item>
      <!-- 字段修改下 -->
      <el-form-item label="月活量(MAU)" prop="monthlyActivity">
        <el-input v-model="channelForm.monthlyActivity"></el-input>
      </el-form-item>
      <el-form-item label="权益次数" prop="infiniteFlag">
        <el-radio-group v-model="channelForm.infiniteFlag">
          <el-radio :label="0">无限制</el-radio>
          <el-radio :label="1">有限制</el-radio>
        </el-radio-group>
        <br />
        <el-input-number
          v-if="channelForm.infiniteFlag"
          v-model="channelForm.equityNumber"
          controls-position="right"
          @change="handleChange"
          :min="1"
          :max="9999"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="channelForm.remarks"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="channelClose">取 消</el-button>
      <el-button type="primary" @click="channelAdd">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  channelUpdEquityApi,
  channelAddApi,
  channelUpdApi,
  channelDetailApi
} from 'api/channelManagement'
export default {
  props: {
    apiDetailMsg: {
      type: Number,
      default: null
    }
  },
  watch: {
    apiDetailMsg: {
      handler(bool) {
        // this.$refs.addChannel.resetFields()
        if (bool) {
          console.log(this.apiDetailMsg)
          channelDetailApi(this.apiDetailMsg).then(res => {
            console.log(res)
            this.channelForm = { ...res.result }
            this.channelForm.infiniteFlag
              ? (this.channelForm.infiniteFlag = 1)
              : (this.channelForm.infiniteFlag = 0)
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogFormVisible: false,
      channelForm: {
        channelName: '',
        apiUrl: '',
        monthlyActivity: '',
        equityNumber: '',
        remarks: '',
        infiniteFlag: 0
      },
      userFormRules: {
        // channelName: [{ required: true, message: '媒介名称不能为空' }],
        // apiUrl: [{ required: true, message: '权益接口不能为空' }],
        // monthlyActivity: [{ required: true, message: '月活量不能为空' }],
        // infiniteFlag: [{ required: true, message: '权益次数不能为空' }]
        // remarks: [{ required: true, message: '备注不能为空' }]
      },
      formLabelWidth: '140px',
      labelPosition: 'right'
    }
  },
  methods: {
    handleChange(e) {
      console.log(e)
    },
    channelClose() {
      this.$emit('closeAdd')
      this.channelForm = this.$options.data().channelForm
      this.$refs.addChannel.resetFields()
    },
    channelAdd() {
      this.$refs.addChannel.validate(valid => {
        if (valid) {
          channelUpdEquityApi({ ...this.channelForm }).then(res => {
            if (res.code == 200) {
              this.$emit('closeAdd')
              this.channelForm = this.$options.data().channelForm
            }
          })
        }
      })
    }
  }
}
</script>
