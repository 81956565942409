<template>
  <div>
    <div class="channelManagement" >

      <div class="channelManagement-head">

      </div>
      <el-card class="box-card">

        <h2>公司端</h2>

        <el-form
          :model="companyMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>

            <el-col :span='8'>
              <el-form-item label='公司名称:' prop="stockName">
                <el-input v-model="companyMsg.stockName" style='width: 150px;' placeholder="请输入公司名称"></el-input>
              </el-form-item>
            </el-col>


            <el-col :span='8'>
              <el-form-item label='年份:' prop="dateTypeYear">
                <el-date-picker
                  v-model="companyMsg.dateTypeYear"
                  type="year"
                  placeholder="选择年">
                </el-date-picker>
                <span style='margin-right: 20px'></span>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='时间:' prop="timeTypeDate">
                <el-cascader
                  v-model="companyMsg.dateType"
                  :options="timeTypeDateOptions"
                  :show-all-levels="false"
                  @change="handleChange">
                </el-cascader>
              </el-form-item>

            </el-col>

          </el-row>

          <el-row>

            <el-col :span='8'>
              <el-form-item label='股票类型:' prop="stockType">
                <el-select v-model="companyMsg.stockType" placeholder="请选择股票类型">
                  <el-option
                    v-for="item in stockTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span='10'>
              <el-form-item label='排名列数:'>
                <el-slider
                  v-model="companyMsg.rankingNum"
                  show-input>
                </el-slider>
              </el-form-item>
            </el-col>

            <el-col :span='6' align='right'>
              <el-button type='primary' size='small' @click="createdCompany">生成稿件</el-button>
              <el-button size='small'  @click="resetCompany">重置</el-button>
            </el-col>

          </el-row>

          <el-row>
          </el-row>

        </el-form>

      </el-card>


      <el-card class="box-card">
        <h2>公司端-自动生成</h2>
        <el-form
          :model="companysMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>
            <el-col :span='8'>
              <el-form-item label='年份:' prop="dateTypeYear">
                <el-date-picker
                  v-model="companysMsg.dateTypeYear"
                  type="year"
                  placeholder="选择年">
                </el-date-picker>
                <span style='margin-right: 20px'></span>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='时间:' prop="timeTypeDate">
                <el-cascader
                  v-model="companysMsg.dateType"
                  :options="timeTypeDateOptions"
                  :show-all-levels="false"
                  @change="companysHandleChange">
                </el-cascader>
              </el-form-item>

            </el-col>

          </el-row>

          <el-row>

            <el-col :span='8'>
              <el-form-item label='股票类型:' prop="stockType">
                <el-select v-model="companysMsg.stockType" placeholder="请选择股票类型">
                  <el-option
                    v-for="item in stockTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span='10'>
              <el-form-item label='排名列数:'>
                <el-slider
                  v-model="companysMsg.rankingNum"
                  show-input>
                </el-slider>
              </el-form-item>
            </el-col>

            <el-col :span='6' align='right'>
              <el-button type='primary' size='small' @click="createdCompanys">生成稿件</el-button>
              <el-button size='small'  @click="resetCompanys">重置</el-button>
            </el-col>

          </el-row>

          <el-row>
          </el-row>

        </el-form>

      </el-card>

      <el-card class="box-card">

        <h2>市场端</h2>

        <el-form
          :model="pressMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

        <el-row>
          <el-col :span='8'>
            <el-form-item label='年份:' prop="dateTypeYear">
              <el-date-picker
                v-model="pressMsg.dateTypeYear"
                type="year"
                placeholder="选择年">
              </el-date-picker>
              <span style='margin-right: 20px'></span>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
            <el-form-item label='时间:' prop="timeTypeDate">
              <el-cascader
                v-model="pressMsg.dateType"
                :options="timeTypeDateOptions"
                :show-all-levels="false"
                @change="pressHandleChange">
              </el-cascader>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
            <el-form-item label='股票类型:' prop="stockType">
              <el-select v-model="pressMsg.stockType" placeholder="请选择股票类型">
                <el-option
                  v-for="item in stockTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

          <el-row>
            <el-col :span='8'>
              <el-button type='primary' size='small' @click="createdPress">生成稿件</el-button>
              <el-button size='small'  @click="resetPress">重置</el-button>
            </el-col>

          </el-row>

        </el-form>

      </el-card>


      <el-card class="box-card">
        <h2>
          互动易/易互动
        </h2>

        <el-form
          :model="interactMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>

            <el-col :span='8'>
              <el-form-item label='类型:' prop="interactType">
                <el-select v-model="interactMsg.interactType" placeholder="请选择类型">
                  <el-option
                    v-for="item in interactOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='年份:' prop="dateTypeYear">
                <el-date-picker
                  v-model="interactMsg.dateTypeYear"
                  type="year"
                  placeholder="选择年">
                </el-date-picker>
                <span style='margin-right: 20px'></span>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='时间:' prop="timeTypeDate">
                <el-cascader
                  v-model="interactMsg.dateType"
                  :options="timeTypeDateOptions"
                  :show-all-levels="false"
                  @change="interactHandleChange">
                </el-cascader>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row>

            <el-col :span='12'>
              <el-form-item label='排名列数:'>
                <el-slider
                  v-model="interactMsg.rankingNum"
                  show-input>
                </el-slider>
              </el-form-item>
            </el-col>

            <el-col :span='12' align='center'>
              <el-button type='primary' size='small' @click="createdInteract">生成稿件</el-button>
              <el-button size='small'  @click="resetInteract">重置</el-button>
            </el-col>

          </el-row>
        </el-form>
      </el-card>


      <el-card class="box-card">
        <h2>
          互动信息-自动生成
        </h2>

        <el-form
          :model="interactsMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>

            <el-col :span='8'>
              <el-form-item label='年份:' prop="dateTypeYear">
                <el-date-picker
                  v-model="interactsMsg.dateTypeYear"
                  type="year"
                  placeholder="选择年">
                </el-date-picker>
                <span style='margin-right: 20px'></span>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='时间:' prop="timeTypeDate">
                <el-cascader
                  v-model="interactsMsg.dateType"
                  :options="timeTypeDateOptions"
                  :show-all-levels="false"
                  @change="interactsHandleChange">
                </el-cascader>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row>

            <el-col :span='12'>
              <el-form-item label='排名列数:'>
                <el-slider
                  v-model="interactsMsg.rankingNum"
                  show-input>
                </el-slider>
              </el-form-item>
            </el-col>

            <el-col :span='12' align='center'>
              <el-button type='primary' size='small' @click="createdInteracts">生成稿件</el-button>
              <el-button size='small'  @click="resetInteracts">重置</el-button>
            </el-col>

          </el-row>
        </el-form>
      </el-card>


      <el-card class="box-card">
        <h2>
          ESG指标
        </h2>
        <el-form
          :model="esgMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>

            <el-col :span='8'>
              <el-form-item label='年份:' prop="dateTypeYear">
                <el-date-picker
                  v-model="esgMsg.dateTypeYear"
                  type="year"
                  placeholder="选择年">
                </el-date-picker>
                <span style='margin-right: 20px'></span>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='时间:' prop="timeTypeDate">
                <el-cascader
                  v-model="esgMsg.dateType"
                  :options="timeTypeDateOptions"
                  :show-all-levels="false"
                  @change="esgHandleChange">
                </el-cascader>
              </el-form-item>
            </el-col>


            <el-col :span='8'>
              <el-form-item label="行业:">
                <el-select v-model="esgMsg.industry" placeholder="请选择行业">
                  <el-option
                    v-for="item in industryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row>

            <el-col :span='12'>
              <el-form-item label='排名列数:'>
                <el-slider
                  v-model="esgMsg.rankingNum"
                  show-input>
                </el-slider>
              </el-form-item>
            </el-col>

            <el-col :span='12' align='center'>
              <el-button type='primary' size='small' @click="createdEsg">生成稿件</el-button>
              <el-button size='small'  @click="resetEsg">重置</el-button>
            </el-col>

          </el-row>
        </el-form>


      </el-card>


      <el-card class="box-card">
        <h2>
          ESG指标-自动生成
        </h2>
        <el-form
          :model="esgsMsg"
          ref="formData"
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>

            <el-col :span='8'>
              <el-form-item label='年份:' prop="dateTypeYear">
                <el-date-picker
                  v-model="esgsMsg.dateTypeYear"
                  type="year"
                  placeholder="选择年">
                </el-date-picker>
                <span style='margin-right: 20px'></span>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <el-form-item label='时间:' prop="timeTypeDate">
                <el-cascader
                  v-model="esgsMsg.dateType"
                  :options="timeTypeDateOptions"
                  :show-all-levels="false"
                  @change="esgsHandleChange">
                </el-cascader>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row>

            <el-col :span='12'>
              <el-form-item label='排名列数:'>
                <el-slider
                  v-model="esgsMsg.rankingNum"
                  show-input>
                </el-slider>
              </el-form-item>
            </el-col>

            <el-col :span='12' align='center'>
              <el-button type='primary' size='small' @click="createdEsgs">生成稿件</el-button>
              <el-button size='small'  @click="resetEsgs">重置</el-button>
            </el-col>

          </el-row>
        </el-form>


      </el-card>


      <div style='margin-bottom: 100px'>

      </div>

    </div>
  </div>
</template>


<script>
import { industryCategoryTreeApi } from 'api/common'

import {
  automationCreatedCompanyWordApi, automationCreatedEsgWordApi, automationCreatedInteractWordApi,
  createdCompanyWordApi,
  createdEsgWordApi,
  createdInteractWordApi,
  createdPressWordApi,
  getSecBureauAllApi
} from 'api/article'

export default {
  data(){
    return{
      esgMsg:{
        rankingNum:5,
        stockType:"A股",
        timeType:null,
        dateTypeYear:null,
        dateType:null,
        dateTypeParent:null,
        dateTypeChildren:null,
      },
      esgsMsg:{
        rankingNum:5,
        stockType:"A股",
        timeType:null,
        dateTypeYear:null,
        dateType:null,
        dateTypeParent:null,
        dateTypeChildren:null,
      },
      companyMsg:{
        stockName:null,
        timeType:null,
        stockType:"A股",
        dateTypeYear:null,
        dateType:null,
        dateTypeParent:null,
        dateTypeChildren:null,
        rankingNum:5
      },
      companysMsg: {
        timeType:null,
        stockType:"A股",
        dateTypeYear:null,
        dateType:null,
        dateTypeParent:null,
        dateTypeChildren:null,
        rankingNum:5
      },
      pressMsg:{
        rankingNum:5,
      },
      interactsMsg:{
        interactType:null,
        rankingNum:5
      },
      interactMsg:{
        interactType:null,
        rankingNum:5
      },
      timeTypeDateOptions:[],
      timeTypeOptions:[],
      stockTypeOptions:[
        {
          label:"A股",
          value:"A股"
        },
      ],
      interactOptions:[
        {
          label:"互动易",
          value:"深交所"
        },
        {
          label:"E互动",
          value:"上证"
        },
      ],
      industryOptions:[],
      secBureauOptions:[],
      rankingNumOptions:[
        {
          label:5,
          value:5
        },{
          label:10,
          value:10
        },{
          label:15,
          value:15
        },{
          label:20,
          value:20
        }

      ]
    }
  },
  created() {
  },
  mounted() {
    this.getIndustry();
    this.timeTypeDate()
    this.secBureauData();
  },
  methods:{
    createdEsgs(){
      if (this.esgsMsg.dateTypeYear===null || this.esgsMsg.interactType === ""){
        return this.$message.error("请先选择年份!")
      }

      if (this.esgsMsg.dateType===null || this.esgsMsg.dateType === ""){
        return this.$message.error("请先选择时间!")
      }
      if (this.esgsMsg.rankingNum===0){
        return this.$message.error("排名列数至少大于1!")
      }
      this.esgsMsg.dateTypeYear = new Date(this.esgsMsg.dateTypeYear).getFullYear().toString();
      automationCreatedEsgWordApi(this.esgsMsg).then(res => {
        if (res.code === 200){
          this.$message.success("提交成功,请等待")
        }
      })
    },
    createdEsg(){

      if (this.esgMsg.dateTypeYear===null || this.esgMsg.interactType === ""){
        return this.$message.error("请先选择年份!")
      }

      if (this.esgMsg.dateType===null || this.esgMsg.dateType === ""){
        return this.$message.error("请先选择时间!")
      }
      if (this.esgMsg.rankingNum===0){
        return this.$message.error("排名列数至少大于1!")
      }
      this.esgMsg.dateTypeYear = new Date(this.esgMsg.dateTypeYear).getFullYear().toString();
      createdEsgWordApi(this.esgMsg).then( data => {
        let blob = new Blob([data.data])
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = "动态宝ESG活跃度指标";
        let time = this.formatDateTime(new Date());
        link.download = time+"-"+`${dateXls}".doc`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })

    },

    createdInteracts(){
      if (this.interactsMsg.dateTypeYear===null || this.interactsMsg.interactType === ""){
        return this.$message.error("请先选择年份!")
      }

      if (this.interactsMsg.dateType===null || this.interactsMsg.dateType === ""){
        return this.$message.error("请先选择时间!")
      }

      if (this.interactsMsg.rankingNum===0){
        return this.$message.error("排名列数至少大于1!")
      }
      this.interactsMsg.dateTypeYear = new Date(this.interactsMsg.dateTypeYear).getFullYear().toString();
      automationCreatedInteractWordApi(this.interactsMsg).then( res => {
        if (res.code === 200){
          this.$message.success("提交成功,请等待")
        }
      })
    },
    createdInteract(){

      if (this.interactMsg.interactType===null || this.interactMsg.interactType === ""){
        return this.$message.error("请先选择类型!")
      }

      if (this.interactMsg.dateTypeYear===null || this.interactMsg.interactType === ""){
        return this.$message.error("请先选择年份!")
      }

      if (this.interactMsg.dateType===null || this.interactMsg.dateType === ""){
        return this.$message.error("请先选择时间!")
      }

      if (this.interactMsg.rankingNum===0){
        return this.$message.error("排名列数至少大于1!")
      }

      this.interactMsg.dateTypeYear = new Date(this.interactMsg.dateTypeYear).getFullYear().toString();
      createdInteractWordApi(this.interactMsg).then( data => {
        let blob = new Blob([data.data])
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = "";
        if (this.interactMsg.interactType==="深交所"){
          dateXls = "动态宝稿件-互动易端";
        }else {
          dateXls = "动态宝稿件-E互动端";
        }
        let time = this.formatDateTime(new Date());
        link.download = time+"-"+`${dateXls}".doc`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    resetEsgs(){
      this.esgsMsg = this.$options.data().esgsMsg;
    },
    resetEsg(){
      this.esgMsg = this.$options.data().esgMsg;
    },
    resetInteracts(){
      this.interactsMsg = this.$options.data().interactsMsg;
    },
    resetInteract(){
      this.interactMsg = this.$options.data().interactMsg;
    },
    resetCompany(){
      this.companyMsg = this.$options.data().companyMsg;
    },
    resetCompanys(){
      this.companysMsg = this.$options.data().companysMsg;
    },
    resetPress(){
      this.pressMsg = this.$options.data().pressMsg;
    },
    createdPress(){
      this.pressMsg.dateTypeYear = new Date(this.pressMsg.dateTypeYear).getFullYear().toString();
      createdPressWordApi(this.pressMsg).then( data => {
        let blob = new Blob([data.data])
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = "动态宝稿件-市场端";
        let time = this.formatDateTime(new Date());
        link.download = time+"-"+`${dateXls}".doc`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    createdCompanys(){
      this.companysMsg.dateTypeYear = new Date(this.companysMsg.dateTypeYear).getFullYear().toString();
      automationCreatedCompanyWordApi(this.companysMsg).then(res => {
        if (res.code === 200){
          this.$message.success("提交成功,请等待")
        }
      })
    },
    createdCompany(){
      this.companyMsg.dateTypeYear = new Date(this.companyMsg.dateTypeYear).getFullYear().toString();
      createdCompanyWordApi(this.companyMsg).then( data => {
        let blob = new Blob([data.data])
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = "动态宝稿件-公司端";
        let time = this.formatDateTime(new Date());
        link.download = time+"-"+`${dateXls}".doc`
        link.href = blobUrl
        link.click()
        link.remove()
// 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    formatDateTime(date) {
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2);
      let day = ('0' + date.getDate()).slice(-2);
      let hours = ('0' + date.getHours()).slice(-2);
      let minutes = ('0' + date.getMinutes()).slice(-2);
      let seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    secBureauData(){
      getSecBureauAllApi().then(res=>{
        this.secBureauOptions = res.result;
      })
    },
    timeTypeDate(){
      const seasonChildrenData = [];
      for (let i = 1; i <= 4; i++) {
        const data = {
          label:"第"+i+"季度",
          value:i
        }
        seasonChildrenData.push(data)
      }

      const seasonData = {
        value: '季度',
        label: '季度',
        children:[]
      }
      seasonData.children = seasonChildrenData;
      this.timeTypeDateOptions.push(seasonData)

      const monthChildrenData = [];
      for (let i = 1; i <=12; i++) {
        const data = {
          label:i+"月份",
          value:i
        }
        monthChildrenData.push(data)
      }
      const monthData = {
        value: '月',
        label: '月份',
        children:[]
      }
      monthData.children = monthChildrenData;
      this.timeTypeDateOptions.push(monthData)

      const yearData = {
        value: '年',
        label: '年',
      }

      this.timeTypeDateOptions.push(yearData)

    },
    pressHandleChange(){
      console.log(this.pressMsg.dateType)
      this.pressMsg.dateTypeParent = this.pressMsg.dateType[0];
      this.pressMsg.dateTypeChildren = this.pressMsg.dateType[1]
      console.log("类型:"+this.pressMsg.dateTypeParent)
      console.log("时间:"+this.pressMsg.dateTypeChildren)
    },
    esgsHandleChange(){
      console.log(this.esgsMsg.dateType)
      this.esgsMsg.dateTypeParent = this.esgsMsg.dateType[0];
      this.esgsMsg.dateTypeChildren = this.esgsMsg.dateType[1]
      console.log("类型:"+this.esgsMsg.dateTypeParent)
      console.log("时间:"+this.esgsMsg.dateTypeChildren)
    },
    esgHandleChange(){
      console.log(this.esgMsg.dateType)
      this.esgMsg.dateTypeParent = this.esgMsg.dateType[0];
      this.esgMsg.dateTypeChildren = this.esgMsg.dateType[1]
      console.log("类型:"+this.esgMsg.dateTypeParent)
      console.log("时间:"+this.esgMsg.dateTypeChildren)
    },
    interactHandleChange(){
      console.log(this.interactMsg.dateType)
      this.interactMsg.dateTypeParent = this.interactMsg.dateType[0];
      this.interactMsg.dateTypeChildren = this.interactMsg.dateType[1]
      console.log("类型:"+this.interactMsg.dateTypeParent)
      console.log("时间:"+this.interactMsg.dateTypeChildren)
    },
    interactsHandleChange(){
      console.log(this.interactsMsg.dateType)
      this.interactsMsg.dateTypeParent = this.interactsMsg.dateType[0];
      this.interactsMsg.dateTypeChildren = this.interactsMsg.dateType[1]
      console.log("类型:"+this.interactsMsg.dateTypeParent)
      console.log("时间:"+this.interactsMsg.dateTypeChildren)
    },
    companysHandleChange(){
      this.companysMsg.dateTypeParent = this.companysMsg.dateType[0];
      if (this.companysMsg.dateTypeParent === "年"){
        this.companysMsg.dateTypeChildren = 1;
      }else {
        this.companysMsg.dateTypeChildren = this.companysMsg.dateType[1]
      }
    },
    handleChange(){
      console.log(this.companyMsg.dateType)
      this.companyMsg.dateTypeParent = this.companyMsg.dateType[0];
      if (this.companyMsg.dateTypeParent === "年"){
        this.companyMsg.dateTypeChildren = 1;
      }else {
        this.companyMsg.dateTypeChildren = this.companyMsg.dateType[1]
      }

      console.log("类型:"+this.companyMsg.dateTypeParent)
      console.log("时间:"+this.companyMsg.dateTypeChildren)
    },
    getIndustry(){
      industryCategoryTreeApi().then(({ result }) => {
        const data = result;
        const options = [];

        data.forEach(item => {
          const val = {
            label : item.name,
            value : item.code
          }
          options.push(val);
        })

        this.industryOptions = options;
      })
    },
  }
}

</script>



<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>
