<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model='pageMsg'
        size="small"
        label-position="center"
        label-width="100px">

        <el-row>
          <el-col :span='14'>
            <el-form-item label="时间:">
              <el-date-picker
                v-model="pageMsg.timeDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>


          <el-col :span='10'>
            <el-form-item label='类型:'>
              <el-radio-group v-model="pageMsg.weightType">
                <el-radio :label="0">等权</el-radio>
                <el-radio :label="1">加权</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col :span='8'>
            <el-form-item label="行业:">
              <el-select v-model="pageMsg.industryCode" placeholder="请选择行业">
                <el-option
                  v-for="item in industryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span='6'>
            <el-button type='primary' @click='initData'>查询</el-button>
            <el-button @click='resetCli'>重置</el-button>
          </el-col>
        </el-row>

      </el-form>

      <el-divider></el-divider>

      <div id="main" style="width: 1000px; height: 500px;"></div>


    </el-card>
  </div>
</template>

<script>
import { industryCategoryTreeApi } from 'api/common'
import {
  getIndustryDataByIndustryCodeApi,
  getIndustryDataMarketApi, getIndustryEtfCodeApi,
  getIndustryETFDataApi,
  transitionIndustry
} from 'api/stock'
import * as echarts from 'echarts'

export default {
  data() {
    return{
      timer:null,
      pageMsg:{
        weightType:0,
        timeDate:null,
        startTime:null,
        endTime:null,
        industryCode:"110000",
        industryStockCode:null
      },
      industryOptions:[],
      industryName:"",
      industryCodeList:{}

    }
  },
  created() {
    this.transitionIndustry();
  },
  mounted() {
    this.getIndustry()
    this.timer = setInterval(()=>{
      this.initData();
      this.clear();
    },1000);
  },
  methods: {
    transitionIndustry(){
      transitionIndustry().then( res=> {
        this.industryCodeMap = new Map();
        this.industryCodeList = res.result;
      })
    },
    resetCli(){
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    },
    initData(){
      this.industryOptions.forEach(item => {
        if(item.value === this.pageMsg.industryCode){
          this.industryName = item.label
        }
      })
      this.industryCodeList.forEach(item => {
        if(item.industryCode === this.pageMsg.industryCode){
          this.pageMsg.industryStockCode = item.industryStockCode
        }
      })
      if (this.pageMsg.timeDate && this.pageMsg.timeDate.length === 2) {
        const startDate = this.pageMsg.timeDate[0];
        const endDate = this.pageMsg.timeDate[1];
        this.pageMsg.startTime = this.formatDate(startDate, true);
        this.pageMsg.endTime = this.formatDate(endDate, false)
      }
      let apiPromises = [];
      apiPromises.push(getIndustryDataByIndustryCodeApi(this.pageMsg));
      apiPromises.push(getIndustryDataMarketApi(this.pageMsg));
      apiPromises.push(getIndustryETFDataApi(this.pageMsg));
      apiPromises.push(getIndustryEtfCodeApi(this.pageMsg));
      Promise.all(apiPromises).then(values => {
        const iprIndustryData = values[0].result;
        const industryData = values[1].result;
        const industryEtfData = values[2].result;
        const etf = values[3].result;
        this.drawEchats(iprIndustryData,industryData,industryEtfData,etf);
      })
    },
    clear(){
      clearInterval(this.timer);
      this.timer = null;
    },
    drawEchats(iprIndustryData,industryData,industryEtfData,etf){

      let etfName = etf.split('@')[0];
      let etfCode = etf.split('@')[1];

      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      var timeData = iprIndustryData.map(item => item.tradeDate.split(' ')[0]);

      var iprIndustryDataChange = [];
      var industryDataChange = [];
      var industryEtfDataChange = [];

      for (let i = 0; i < iprIndustryData.length; i++) {
        if (i === 0) {
          iprIndustryDataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((iprIndustryData[i].closePrice - iprIndustryData[0].closePrice) / iprIndustryData[0].closePrice * 100).toFixed(2);
          iprIndustryDataChange.push(change);
        }
      }

      for (let i = 0; i < industryData.length; i++) {
        if (i === 0) {
          industryDataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((industryData[i].closePrice - industryData[0].closePrice) / industryData[0].closePrice * 100).toFixed(2);
          industryDataChange.push(change);
        }
      }

      console.log("industryEtfData:"+JSON.stringify(industryEtfData))
      for (let i = 0; i < industryEtfData.length; i++) {
        if (i === 0) {
          industryEtfDataChange.push(0); // 第一天增长幅度为0
        } else {
          const change = ((industryEtfData[i].closePrice - industryEtfData[0].closePrice) / industryEtfData[0].closePrice * 100).toFixed(2);
          industryEtfDataChange.push(change);
        }
      }

      // 准备图表的配置选项
      var option = {
        // 图表标题
        title: {
          text: '指数涨跌幅对比'
        },
        // 提示框组件
        tooltip: {
          trigger: 'axis',
          // 使用自定义的提示框格式化
          formatter: function(params) {
            var result = params[0].axisValue + '<br/>';
            params.forEach(function(item) {
              var index = item.seriesIndex;
              result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + '"></span>'; // 添加小圆点
              result += item.seriesName + '：' + item.data + '%';
              var closePrice = parseFloat([iprIndustryData, industryData,industryEtfData][index][item.dataIndex].closePrice); // 将字符串转换为数字
              result += '，当前点位：' + closePrice.toFixed(2); // 保留两位小数
              result += '<br/>';
            });
            return result;
          }
        },
        // 图例组件
        legend: {
          data: ['BA'+this.industryName+'20指数', this.industryName+'('+this.pageMsg.industryStockCode+')',etfName+'('+etfCode+')']
        },
        // X轴配置
        xAxis: {
          type: 'category',
          data: timeData // 使用时间数据
        },
        // Y轴配置
        yAxis: {
          type: 'value'
        },
        // 数据系列
        series: [
          {
            name: 'BA'+this.industryName+'20指数',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: iprIndustryDataChange
          },
          {
            name:   this.industryName+'('+this.pageMsg.industryStockCode+')',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: industryDataChange
          },
          {
            name: etfName+'('+etfCode+')',
            type: 'line',
            smooth: true, // 曲线图
            symbol: 'none', // 去除圆点
            data: industryEtfDataChange
          }
        ],
      };
      myChart.setOption(option);

    },
    getIndustry(){
      industryCategoryTreeApi().then(({ result }) => {
        const data = result;
        const options = [];
        data.forEach(item => {
          const val = {
            label : item.name,
            value : item.code
          }
          options.push(val);
        })
        this.industryOptions = options;
      })
    },
    formatDate(date, isStart) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const time = isStart ? "00:00:00" : "23:59:59";
      return `${year}-${month}-${day} ${time}`;
    }
  }
}

</script>

<style scoped lang='scss'>

</style>