import apiServer from 'api/apiServer.js'


export function addRoadShowApi(params = {}) {
  let url = `/process/roadShow/addRoadShow` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}


export function queryRoadShowPageApi(params = {}) {
  let url = `/process/roadShow/queryRoadShowPage` //post methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}




export function delRecordApi(params = {}) {
  let url = `/process/roadShow/delRecord/${params}`
  return apiServer.get(url).then(res => {
    return Promise.resolve(res)
  })
}

