<template>
  <div>
    <router-view></router-view>
    <div class="channelManagement" v-if="$route.path == '/websiteMange/news'">
      <div class="channelManagement-head">
        <h1>任务列表</h1>
      </div>
      <el-card class="box-card">
        <el-row class="text-left">
          <el-form
            :model="pageMsg"
            ref="formData"
            size="small"
            label-position="left"
            label-width="80px"
          >
            <el-col :span="8">
              <el-form-item label="新闻：" class="limitinputWidth">
                <el-input clearable v-model="pageMsg.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"> <p style="opacity: 0"></p> </el-col>
            <el-col :span="8">
              <el-form-item label="所属行业" class="limitinputWidth">
                <el-cascader
                  :options="options"
                  @change="handleChange"
                  v-model="pageMsg.industryCategoryCode"
                ></el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="7" class="text-right">
              <el-button type="primary" size="small" @click="searchData(true)">
                查询
              </el-button>
              <el-button size="small" @click="searchData(false)">
                重置
              </el-button>
            </el-col>
          </el-form>
        </el-row>
        <el-button
          type="primary"
          @click="$router.push('/websiteMange/newsDetail')"
          size="small"
          style="margin-bottom: 10px"
          v-if="userbtn.indexOf('WEBSIT_NEWS_ADD') >= 0"
          >新建新闻</el-button
        >
        <div class="select_warn" v-if="selectionArr.length > 0">
          已经选择
          <span>{{ selectionArr.length }}</span>
          项
          <el-button type="text" @click="exportAll()" v-if="userbtn.indexOf('WEBSIT_NEWS_MOREEXPORT') >= 0">导出</el-button>
          <el-button type="text" @click="delList()" v-if="userbtn.indexOf('WEBSIT_NEWS_MOREDEL') >= 0">删除</el-button>
        </div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="title"
            label="新闻"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            label="文章类型"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.type == 10">ESG</span>
              <span v-if="row.type == 20">经营管理</span>
              <span v-if="row.type == 30">产品服务</span>
              <span v-if="row.type == 40">发展战略</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="industryCategoryName"
            label="所属行业"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createdTime"
            label="提交时间"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="180"
            fixed="right"
          >
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="small"
                @click.native.prevent="passItemTrue(row)"
                v-if="userbtn.indexOf('WEBSIT_NEWS_EDIT') >= 0"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click.native.prevent="delList(row)"
                v-if="userbtn.indexOf('WEBSIT_NEWS_DEL') >= 0"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
import { industryCategoryTreeApi } from 'api/common'
import {
  newsConsultPageApi,
  delBatchApi,
  newsConsultExportApi
} from 'api/websit'
export default {
  name: 'news',
  data() {
    return {
      options: [],
      selectionArr: [],
      tableData: [],
      pageTotal: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10,
        descs: ['created_time'],
        title: '',
        industryCategoryCode: ''
      },
      tableLoading: false
    }
  },
  mounted() {
    this.initCategoryCode()
    this.initData()
  },
  watch: {
    $route: {
      handler(to) {
        // 初始化操作
        if (to.path === '/websiteMange/news') {
          this.initData()
          this.pageMsg = this.$options.data().pageMsg
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      newsConsultPageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData = records
          this.pageTotal = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.options = result
      })
    },
    //编辑
    passItemTrue(row) {
      this.$router.push({
        path: '/websiteMange/newsDetail',
        query: {
          id: row.id
        }
      })
    },
    exportAll() {
      let arr = []
      this.selectionArr.map(item => {
        arr.push(item.id)
      })
      newsConsultExportApi({
        selectIdList: arr,
        industryCategoryCode: this.pageMsg.industryCategoryCode,
        title: this.pageMsg.title
      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = new Date().toLocaleDateString()
        link.download = `新闻导出列表${dateXls}.csv`
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    delList(row) {
      let arr = []
      if (row) {
        arr = [row.id]
      } else {
        this.selectionArr.map(item => {
          arr.push(item.id)
        })
      }
      delBatchApi({ list: arr }).then(res => {
        if (res.code == 200) {
          this.$message.success('删除成功！')
          this.initData()
        }
      })
    },
    handleChange(val) {
      this.pageMsg.industryCategoryCode = val[val.length - 1]
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10
      } else {
        this.pageMsg = this.$options.data().pageMsg
      }
      this.initData()
    },
    handleSelectionChange(e) {
      this.selectionArr = e
    }
  }
}
</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
