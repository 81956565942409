<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">


        <el-form
                :model="pageMsg"
                ref="formData"
                size="small"
                label-position="left"
                label-width="80px"
        >
      <!--    <el-row>
            <el-col :span="8">
              <el-form-item
                      label="账户名称:"

              >

                <el-select v-model="pageMsg.userId"   placeholder="请选择账户名称"    style="width: auto">
                  <el-option
                          v-for="item in optionUserId"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                      label="股票类型:"

              >

                <el-select v-model="pageMsg.contentType"  placeholder="请选择股票类型"    style="width: 190px">
                  <el-option
                          v-for="item in optionContent"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>


            </el-col >

      </el-row>-->
          <el-row>
            <el-col :span="16">
            <el-form-item prop="dateType" >
              <el-radio-group v-model="pageMsg.dateType"  @keyup.enter.native="searchData(true)" style="margin-left: -80px">
                <el-radio :label="2" style="margin-right: 10px">日</el-radio>
                <el-radio :label="0" style="margin-right: 10px">本周</el-radio>
                <el-radio :label="1" style="margin-right: 10px">当月</el-radio>
                <el-radio :label="3" style="margin-right: 10px">年度</el-radio>
                <el-radio :label="4" style="margin-right: 10px">区间</el-radio>

              </el-radio-group>
            </el-form-item>




            <el-form-item prop="year" v-if="pageMsg.dateType === 3 ">
              <el-date-picker style="margin-left: -80px"
                      v-model="pageMsg.year"
                      type="year"
                      value-format="yyyy"
                      placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="number" v-if="pageMsg.dateType === 2">
              <el-date-picker style="margin-left: -80px"
                      v-model="pageMsg.day"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="number" v-if="pageMsg.dateType === 4">
              <el-date-picker style="margin-left: -80px"
                      v-model="pageMsg.startMonths"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="开始日期">
              </el-date-picker>
              <el-date-picker
                      v-model="pageMsg.endMonths"
                      type="date"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item  style="margin-left: 0px">
                <el-button type="primary" size="small"  @click="searchData(true)">统计</el-button>
                <!--          <el-button type="primary" icon="el-icon-download" @click="exportExcelSelect">导出到EXCEL</el-button>-->
                <el-button size="small"  @click="searchData(false)">
                  重置
                </el-button>
                <el-button
                        type="primary"
                        size="small"   @click="downloadPattern" >
                  导出
                </el-button></el-form-item>
            </el-col>
          </el-row>
        </el-form>

      <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%; margin-bottom: 20px"
              row-key="id"
              @selection-change="handleSelectionChange"
              header-row-class-name="tableHead"
              :header-cell-style="{ background: 'rgb(250,250,250)' }"
              v-loading="tableLoading"
      >

        <el-table-column
                prop="stockName"
                label="公司名称"
                header-align="center"
                align="center"
                width="140"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click.native.prevent="Detail(row)">

              {{ row.stockName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
                prop="contentType"
                label="股票类型"
                header-align="center"
                align="center"
                width="140"
        >
        </el-table-column>
        <el-table-column
                prop="num"
                label="发布数量"
                sortable
                header-align="center"
                align="center"
                width="140"
        >
        </el-table-column>
        <el-table-column
                prop="passNumber"
                label="爬取数量"
                sortable
                header-align="center"
                align="center"
                width="140"
        >
        </el-table-column>
        <el-table-column
                prop="rejectNumber"
                label="手动数量"
                sortable
                header-align="center"
                align="center"
                width="140"
        >
        </el-table-column>
        <el-table-column
                prop="passingRate"
                label="爬取率"
                sortable
                header-align="center"
                align="center"
                width="140"
        >
        </el-table-column>
        <el-table-column
                prop="rejectRate"
                label="手动率"
                sortable
                header-align="center"
                align="center"
                width="140"
        >
        </el-table-column>
      </el-table>
      <div class="pb20"></div>
      <el-pagination
              class="text-center"
              v-if="pageTotal > 0"
              @size-change="initData"
              @current-change="initData"
              :current-page.sync="pageMsg.pageNum"
              :page-size.sync="pageMsg.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageTotal"
      >
      </el-pagination>
    </el-card>
    <el-dialog
            :visible.sync="detailBool"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="1200px"
    >
      <releaseDetail :rowID="rowID"/>
    </el-dialog>
<el-dialog   width="1000px"  title="文章列表" :visible.sync="detailBool1" @close="dialogCreate" >
  <el-form
          :model="pageForm"
          ref="formData"
          size="small"
          label-position="left"
          label-width="80px"
  >
    <el-row>
      <el-col :span="12">
    <el-form-item
    >
      <el-radio-group v-model="pageForm.num" style="margin-left: -80px">
        <el-radio :label="0" style="margin-right: 10px">全部</el-radio>
        <el-radio :label="1" style="margin-right: 10px">有效</el-radio>
        <el-radio :label="2" style="margin-right: 10px">无效</el-radio>
        <el-radio :label="4" style="margin-right: 10px">审核中</el-radio>
        <el-radio :label="3" style="margin-right: 10px">待发布</el-radio>
        <el-radio :label="5" style="margin-right: 10px">超时</el-radio>
        <el-radio :label="6" style="margin-right: 10px">不合格</el-radio>

      </el-radio-group>
    </el-form-item>


      </el-col>

      <el-col :span="12" >
    <el-form-item  >
      <!--          <el-button type="primary" icon="el-icon-download" @click="exportExcelSelect">导出到EXCEL</el-button>-->
      <el-button size="small"   type="primary"  @click="Detail(pageForm)">
        查询
      </el-button>
      <el-button size="small"  @click="searchDetail">
        重置
      </el-button>
      <el-button
              type="primary"
              size="small"   @click="downloadPatternTitle" >
        导出
      </el-button>
    </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <el-table
          ref="multipleTable"
          :data="tableData1"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          v-loading="tableLoading1"
  >

    <el-table-column
            prop="articleTitle"
            label="文章名称"
            header-align="center"
            align="center"
    >
      <template slot-scope="{ row }">
        <el-button type="text" @click.native.prevent="turnDetail(row)">
          {{ row.articleTitle }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
            prop="passOrReject"
            label="审核结果"
            header-align="center"
            align="center"
    >

    </el-table-column>
    <el-table-column
            prop="stringTime"
            label="发布时间"
            sortable
            :formatter="formatData"
            header-align="center"
            align="center"
    >
    </el-table-column>
  </el-table>
  <el-pagination
          class="text-center"
          v-if="pageTotal1 > 0"
          @size-change="Detail(pageForm)"
          @current-change="Detail(pageForm)"
          :current-page.sync="pageForm.pageNum"
          :page-size.sync="pageForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal1"
  >
  </el-pagination>
</el-dialog>


  </div>
</template>

<script>
import { jsConutApi,articleExportJsCountApi,selectApi,articleExportSelectListApi,treeContentypeApi } from 'api/article'
import { industryCategoryTreeApi } from 'api/common'
import {

    getUserList1

} from 'api/rightsetting'

import moment from "moment"

import releaseDetail from '@/views/release/releaseDetail.vue'
export default {

    name: 'contentReview',
    components: {
        releaseDetail,

    },
    computed: {
        formatDate() {
            return function (oldDate) {
                // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
                function add0(num) {
                    return num < 10 ? '0' + num : num
                } // 个位数的值在前面补0
                const date = new Date(oldDate)
                const Y = date.getFullYear()
                const M = date.getMonth() + 1
                const D = date.getDate()
                const h = date.getHours()
                const m = date.getMinutes()
                const s = date.getSeconds()

                const dateString =
                    Y +
                    '-' +
                    add0(M) +
                    '-' +
                    add0(D) +
                    '  ' +
                    add0(h) +
                    ':' +
                    add0(m) +
                    ':' +
                    add0(s)
                return dateString
            }
        }
    },

    data() {
        return {
            rules: {
                failReason: [{ required: true, message: '请填写驳回原因' }]
            },
            passDialog: false,
            oneDialog: false,
            ruleModel: {
                articleId: null,
                failReason: ''
            },
            months: '',
            options: [],
            pageForm:{
                pageNum: 1,
                pageSize: 10,
                stockName: '',
                num: 0,
                stringTime: ''
            },
            companyOptions: [],
            selectionArr: [],
            tableData: [],
            tableData1: [],
            pageTotal: 0,
            pageTotal1: 0,
            optionAuth: [{
                value: '全部',
                label: '全部'
            },{
                value: '有效',
                label: '有效'
            }, {
                value: '无效',
                label: '无效'
            }],
            optionContent: [{
                value: 'A股',
                label: 'A股'
            }, {
                value: '港股',
                label: '港股'
            }, {
                value: '中概股',
                label: '中概股'
            }],
            optionUserId: [],
            optionIndustryCategoryCode: [],
            pageMsg: {
                industryCategoryArr: [],
                industryCategoryCode: null,
                dateType: 0,
                contentType: null,
                year: undefined,
                number: undefined,
                pageNum: 1,
                pageSize: 10,
                descs: ['created_time'],
                state: null,
                stockName: null,
                userId: null,
                day: '',
                month: '',
                startMonths: '',
                endMonths: '',
            },

            tableLoading: false,
            tableLoading1: false,
            rowID: null,
            rowStockName: null,
            detailBool: false,
            detailBool1: false
        }
    },
    mounted() {
        this.tree()
        this.initData()
        this.initRoles()
        this.initCategoryCode()
    },
    created() {
        this.initCategoryCode()
    },
    methods: {
        initRoles() {
            getUserList1().then(res => {
                this.optionUserId = res.result.records

            })
        },
        initCategoryCode() {
            industryCategoryTreeApi().then(({ result }) => {
                const classification = result
                function classifi(arr) {
                    arr.forEach(item => {
                        item.label = item.name
                        item.value = item.code
                        if (item.children && item.children.length > 0) {
                            classifi(item.children)
                        } else {
                            delete item['children']
                        }
                    })
                }
                classifi(classification)
                this.options = result
            })
        },
        handleChange(val) {
            this.pageMsg.industryCategoryCode = val[val.length - 1]
        },
        dialogCreate () {
            this.detailBool1 = false
            this.pageForm.num = 0;

        },
        tree() {
            treeContentypeApi().then(data => {
                this.optionIndustryCategoryCode = data.result.industryCategoryList
            });
        },
        searchDetail() {
            this.pageForm.num = 0
            this.pageForm.userId = ''
           this.Detail( this.pageForm)
        },
        downloadPatternTitle() {
            articleExportSelectListApi({
                ...this.pageForm
            }).then(data => {
                let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
                // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                // let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths
                link.download = `发布文章.csv`
                link.href = blobUrl;
                link.click()
                link.remove()
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
            })
        },
        downloadPattern() {
            articleExportJsCountApi({
                ...this.pageMsg
            }).then(data => {
                let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
                // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let blobUrl = window.URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                if (this.pageMsg.dateType === 0) {
                    let currDate = moment(new Date(),"YYYY-MM-DD");

                    let weekOfday = parseInt(moment().format('d')) // 计算今天是这周第几天 周日为一周中的第一天
                    let monday = moment().weekday(1).format('YYYY-MM-DD') //本周一
                    let sunday = moment().weekday(7).format('YYYY-MM-DD') //本周日

                    let dateXls = monday+'至'+sunday;
                    link.download = `${dateXls}技术考核分析.csv`
                    //当月
                }else if (this.pageMsg.dateType === 1) {
                    let currDate = moment(new Date(),"YYYY-MM-DD");
                    var firstDay= moment(currDate.startOf("month").valueOf()).format('YYYY-MM-DD');//获取该月份第一天的时间戳
                    var endDay = moment(currDate.endOf("month").valueOf()).format('YYYY-MM-DD');//获取该月份最后一天的时间戳
                    let dateXls = firstDay+'至'+endDay;
                    link.download = `${dateXls}技术考核分析.csv`
                    //当日
                }else if (this.pageMsg.dateType === 2) {
                    let dateXls =  this.pageMsg.day;
                    link.download = `${dateXls}技术考核分析.csv`
                }else if (this.pageMsg.dateType === 3) {
                    let dateXls =  this.pageMsg.year+'年';
                    link.download = `${dateXls}技术考核分析.csv`
                }else if (this.pageMsg.dateType === 4) {
                    let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
                    link.download = `${dateXls}技术考核分析.csv`
                }
                link.href = blobUrl;
                link.click()
                link.remove()
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(blobUrl)
            })
        },
        initData() {
            this.tableLoading = true
            const data = {
                ...this.pageMsg
            }
            jsConutApi(this.pageMsg).then(res => {

                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData = records
                    this.pageTotal = total
                    if (this.pageMsg.dateType === 0) {
                        this.pageMsg.day = '';
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 1) {
                        this.pageMsg.day = '';
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 2) {
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.year = '';
                    }else if (this.pageMsg.dateType === 3) {
                        this.pageMsg.startMonths = '';
                        this.pageMsg.endMonths = '';
                        this.pageMsg.day = '';
                    }else if (this.pageMsg.dateType === 4) {
                        this.pageMsg.year = '';
                        this.pageMsg.day = '';
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading = false
            })
            this.initRoles()
        },

        turnDetail(row) {
            // this.$router.push({
            //   path: '/releaseDetail',
            //   query: {
            //     id: row.id
            //   }
            // })
            this.rowID = row.id
            this.detailBool = true
        },
        Detail(row) {
            // this.$router.push({
            //   path: '/releaseDetail',
            //   query: {
            //     id: row.id
            //   }
            // })

            this.detailBool1 = true
            this.tableLoading1 = true
            this.pageForm.stockName = row.stockName

            selectApi(this.pageForm).then(res => {

                if (res.code === 200) {
                    let { total, records } = res.result
                    this.tableData1 = records

                    if (records.size > 0) {
                        this.pageForm.stockName = records[0].stockName;
                    }

                    this.pageTotal1 = total
                } else {
                    this.$message({
                        type: 'error',
                        message: res.desc
                    })
                }
                this.tableLoading1 = false
            })
        },
        DateToStr(dd) {

        },
        formatData(row, column, cellValue, index) {
            //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
            //最终返回 s 就可以了
            var s = new Date(cellValue).toLocaleString()
            return s

            // 测试参数含义：不知道参数是什么含义的就打印出来
        },
        searchData(bool) {
            if (bool) {
                this.pageMsg.pageNum = 1
                this.pageMsg.pageSize = 10
                 if (this.pageMsg.dateType === 3 && !this.pageMsg.year) {
                    this.$message({
                        type: 'error',
                        message: '请选择年份'
                    });
                    return;
                } else if (this.pageMsg.dateType === 2 && !this.pageMsg.day) {
                    this.$message({
                        type: 'error',
                        message: '请选择日期'
                    });
                    return;
                }
                if (this.pageMsg.dateType === 4 && this.pageMsg.startMonths && this.pageMsg.endMonths) {
                    this.pageMsg.month = this.pageMsg.startMonths + ',' + this.pageMsg.endMonths;
                } else if(this.pageMsg.dateType === 4 && !this.pageMsg.startMonths && !this.pageMsg.endMonths){
                    this.$message({
                        type: 'error',
                        message: '请选择日期'
                    });
                    return;
                }
            } else {
                this.pageMsg = this.$options.data().pageMsg
            }

            this.initData()

        },
        handleSelectionChange(e) {
            this.selectionArr = e
        },
        closeAdd() {
            this.dialogAdd = false
            this.dialogSet = false
            this.initData()
        }
    }
}
</script>

<style lang="scss" scoped>
  .channelManagement {
    width: 100%;
    .channelManagement-head {
      padding: 10px 20px;
      background: #fff;
    }
    .box-card {
      width: calc(100% - 60px);
      padding: 10px;
      margin: 20px auto;
    }
    /deep/.state-table {
      i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .select_warn {
      background: rgb(230, 247, 255);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      span {
        color: $primary;
      }
    }
  }
</style>
