<template>
  <div class="company_add">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="账户" prop="username">
        <el-input
          maxLength="11"
          v-model="ruleForm.username"
          placeholder="输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!$route.query.id">
        <el-input
          type="password"
          v-model="ruleForm.password"
          placeholder="设置密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" v-if="$route.query.id">
        <el-input
          type="password"
          v-model="ruleForm.password"
          placeholder="设置密码(不填写即原密码)"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户名称" prop="companyName">
        <el-input
          v-model="ruleForm.companyName"
          placeholder="请输入公司名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="权益次数" prop="equityNumber">
        <el-input-number
          v-model="ruleForm.equityNumber"
          controls-position="right"
          :min="1"
          :max="9999"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="行业类型" prop="industryCategoryCode">
        <el-cascader
          :options="options"
          @change="handleChange"
          v-model="ruleForm.industryCategoryArr"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="昵称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="国家" prop="countryId">
        <el-select
          v-model="ruleForm.countryId"
          placeholder="请选择国家"
          @change="guoChange"
        >
          <el-option
            v-for="item in guo"
            :key="item.Name"
            :label="item.Name"
            :value="item.Code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="省份" prop="provinceId">
        <el-select
          v-model="ruleForm.provinceId"
          placeholder="请选择省份"
          @change="shengChange"
        >
          <el-option
            v-for="item in sheng"
            :key="item.Name"
            :label="item.Name"
            :value="item.Code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市" prop="cityId">
        <el-select
          v-model="ruleForm.cityId"
          placeholder="请选择城市"
          @change="shiChange"
        >
          <el-option
            v-for="item in shi"
            :key="item.Name"
            :label="item.Name"
            :value="item.Code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="街道地址" prop="streetAddress">
        <el-input
          v-model="ruleForm.streetAddress"
          placeholder="请输入具体地址"
        ></el-input>
      </el-form-item>
      <!-- logo -->
      <el-form-item label="logo" prop="logoDocId" class="comImage">
        <el-upload
          :action="uploadUrl"
          :headers="myHeaders"
          list-type="picture-card"
          accept="image/jpeg,image/jpg,image/png"
          :on-change="uploadChange"
          :on-success="uploadReturn"
          :before-upload="beforeAvatarUpload"
          :on-exceed="handleExceed"
          :limit="1"
          :file-list="fileList"
          :on-remove="companyUploadRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <!-- 上传附件 -->
      <el-form-item label="上传附件" prop="docIdList" class="comImage">
        <el-upload
          class="upload-demo"
          drag
          :action="uploadUrl"
          :headers="myHeaders"
          multiple
          :on-change="docIdListuploadChange"
          :on-success="docIdListuploadReturn"
          :before-upload="docIdListbeforeAvatarUpload"
          :on-exceed="docIdListhandleExceed"
          :limit="5"
          :file-list="docIdList"
          :on-remove="docIdListuploadRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            支持扩展名：.rar .zip .doc .docx .pdf .jpg...
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{
          editId ? '立即编辑' : '立即创建'
        }}</el-button>
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { area } from '@/assets/area'
import { industryCategoryTreeApi } from 'api/common'
import constant from 'constant'
import {
  companyAndUserAddApi,
  companyDetailsApi,
  companyDetailsEditApi
} from '@/api/company'
export default {
  data() {
    let checkname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入账号'))
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error('手机格式不正确!'))
      } else {
        callback()
      }
    }
    let validateImage = (rule, value, callback) => {
      //验证器
      if (!this.ruleForm.logoDocId) {
        //为true代表图片在  false报错
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    let validateDOC = (rule, value, callback) => {

      //验证器
      if (!this.ruleForm.docIdList.length) {
        //为true代表图片在  false报错
        callback(new Error('请上传附件'))
      } else {
        callback()
      }
    }
    return {
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      fileList: [],
      docIdList: [],
      ruleForm: {
        companyName: '', //企业名称
        docUrlList: [], //附件
        equityNumber: '', //权益次数
        faceImg: '', //头像
        name: '', //姓名
        password: '', //密码
        streetAddress: '', //街道地址
        username: '', //用户名称--账号
        countryId: '', //国家id
        countryName: '', //国家名称
        provinceId: '', //省id
        provinceName: '', //省名称
        cityId: '', //市id
        cityName: '', //市名称
        logoDocId: '',
        docIdList: [],
        industryCategoryCode: '',
        industryCategoryArr: []
      },
      rules: {
        name: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { validator: checkname, trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        equityNumber: [
          { required: true, message: '请输入权益次数', trigger: 'blur' }
        ],
        streetAddress: [
          { required: true, message: '请输入街道地址', trigger: 'blur' }
        ],
        cityId: [{ required: true, message: '请选择城市' }],
        provinceId: [{ required: true, message: '请输入省份' }],
        countryId: [{ required: true, message: '请选择国家' }],
        logoDocId: [
          { required: true, validator: validateImage, trigger: 'change' }
        ],
        docIdList: [
          { required: true, validator: validateDOC, trigger: 'change' }
        ],
        industryCategoryCode: [
          { required: true, message: '请选择所属行业', trigger: 'change' }
        ]
      },
      countryId: '',
      provinceId: '',
      cityId: '',
      countryName: '',
      provinceName: '',
      cityName: '',
      guo: [],
      sheng: [],
      shi: [],
      areaList: [],
      editId: null,
      options: []
    }
  },
  created() {
    this.initData()
    this.areaList = area.Location.CountryRegion
    this.guo = this.areaList
  },
  methods: {
    initData() {
      if (this.$route.query.id) {
        this.editId = this.$route.query.id
        companyDetailsApi(this.editId).then(res => {

          // res.result.records.map(item => {
          //   if (item.id == this.$route.query.id) {
          //     console.log(item)
          let industryCategoryArr = []
          if (
            res.result.industryCategoryList &&
            res.result.industryCategoryList.length > 0
          ) {
            res.result.industryCategoryList.map(item => {
              industryCategoryArr.push(item.id)
            })
          }
          res.result.industryCategoryArr = industryCategoryArr
          this.ruleForm = { ...res.result }

          this.guoChange(res.result.countryId)

          if (res.result.logo) {
            this.fileList = [{ url: res.result.logo.url }]
            this.ruleForm.logoDocId = res.result.logo.id
          } else {
            this.fileList = []
          }
          this.docIdList = []
          if (!this.ruleForm.docIdList) {
            // this.ruleForm.docIdList = res.result.docList
            let linshiList = []
            res.result.docList.map(items => {
              items.name = items.docRealName
              linshiList.push(items.id)
            })
            this.ruleForm.docIdList = linshiList
            this.docIdList = res.result.docList
          }
    
          //  fileList: [],
          // docIdList: [],
          //   }
          // })
        })
      } else {
        // console.log(111);
      }
      this.initCategoryCode()
    },
    handleChange(val) {
      console.log(val)
      if (val) {
        this.ruleForm.industryCategoryCode = val[val.length - 1]
      }
    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.options = result
      })
    },
    //
    docIdListuploadChange(file, fileList) {
      console.log(fileList)
      this.docIdList = fileList
      console.log(this.docIdList)
      // this.docIdList.push(fileList)
    },
    docIdListuploadReturn(res) {
      if (res.code === 200) {
        console.log(this.ruleForm.docIdList, 'docIdList')
        this.ruleForm.docIdList.push(res.result.id)
        this.$refs.ruleForm.validateField('docIdList')
      }
    },
    docIdListbeforeAvatarUpload() {
      return true
    },
    docIdListhandleExceed() {
      this.$message.warning(`当前限制上传 5 张图片`)
    },
    docIdListuploadRemove(file) {
      console.log(file)
      let docList = this.ruleForm.docIdList
      let newfileList = this.docIdList
      console.log('newfileList', this.fileList, 'file', file)
      newfileList.map((item, index) => {
        console.log(item, file)
        if (item.url == file.url) {
          docList.splice(index, 1)
        }
      })
      this.ruleForm.docIdList = docList
      console.log(this.ruleForm.docIdList)
      this.docIdList = this.docIdList.filter((item, index) => {
        return item.url != file.url
      })
      this.$refs.ruleForm.validateField('docIdList')
    },
    //
    uploadChange(file, fileList) {
      this.fileList = fileList
      console.log(this.fileList)
    },
    uploadReturn(res) {
      if (res.code === 200) {
        this.ruleForm.logoDocId = res.result.id
        this.$refs.ruleForm.validateField('logoDocId')
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message({
          message: '图片只支持上传jpg,jpeg,png格式!',
          type: 'error'
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '图片大小不能超过 2MB!',
          type: 'error'
        })
      }
      return isJPG && isLt2M
    },
    handleExceed() {
      this.$message.warning(`当前限制上传 1 张图片`)
    },
    companyUploadRemove(file) {
      this.ruleForm.logoDocId = ''
      this.$refs.ruleForm.validateField('logoDocId')
      this.fileList = this.fileList.filter(item => {
        return item.url != file.url
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.$route.query.id) {
            companyDetailsEditApi({ ...this.ruleForm }).then(res => {
              if (res.code == 200) {
                this.$message.success('企业修改成功！')
                this.$router.push('/businessManagement')
              }
            })
          } else {
            companyAndUserAddApi({ ...this.ruleForm }).then(res => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('新建企业成功！')
                this.$router.push('/businessManagement')
              }
            })
          }
          console.log(this.ruleForm)
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    guoChange(e) {
      this.areaList.map(res => {
        if (e == res.Code) {
          this.ruleForm.countryName = res.Name
          if (res.State.City) {
            this.sheng = res.State.City
          } else {
            this.sheng = res.State
          }
          this.ruleForm.provinceId = this.sheng[0].Code
          this.shengChange(this.ruleForm.provinceId)
        }
      })
    },
    shengChange(e) {
      this.sheng.map(res => {
        if (e == res.Code) {
          this.ruleForm.provinceName = res.Name
          if (res.City) {
            this.shi = res.City
          } else {
            this.shi = [{ ...res }]
          }
          this.ruleForm.cityId = this.shi[0].Code
          this.ruleForm.cityName = this.shi[0].Name
        }
      })
    },
    shiChange(e) {
      this.shi.map(res => {
        if (res.Code == e) {
          this.ruleForm.cityName = res.Name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.company_add {
  width: calc(100% - 240px);
  padding: 10px;
  margin: 20px auto;
  padding: 100px;
  padding-top: 30px;
  border-radius: 6px;
  background: #fff;
  .el-form {
    width: 600px;
  }
  .el-upload-list__item {
    transition: none !important;
  }
  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
  .el-upload-list__item.is-ready {
    display: none;
  }
}
</style>
