var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "channelManagement" }, [
    _c("div", { staticClass: "channelManagement-head" }),
    _c(
      "div",
      [
        _c(
          "el-card",
          { staticClass: "box-card" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "clientName",
                    label: "公司简称",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "clientUrl", label: "公司网址", width: "180" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "clientPhone", label: "联系方式" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "clientEmail", label: "公司邮箱" },
                }),
                _c("el-table-column", {
                  attrs: { fixed: "right", label: "操作", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.examineClick(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.auditClick(scope.row)
                                },
                              },
                            },
                            [_vm._v("审核")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "0px auto" } },
              [
                _vm.pageTotal > 0
                  ? _c("el-pagination", {
                      staticClass: "text-center",
                      attrs: {
                        "current-page": _vm.pageMsg.pageNum,
                        "page-size": _vm.pageMsg.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.pageTotal,
                      },
                      on: {
                        "size-change": _vm.initData,
                        "current-change": _vm.initData,
                        "update:currentPage": function ($event) {
                          return _vm.$set(_vm.pageMsg, "pageNum", $event)
                        },
                        "update:current-page": function ($event) {
                          return _vm.$set(_vm.pageMsg, "pageNum", $event)
                        },
                        "update:pageSize": function ($event) {
                          return _vm.$set(_vm.pageMsg, "pageSize", $event)
                        },
                        "update:page-size": function ($event) {
                          return _vm.$set(_vm.pageMsg, "pageSize", $event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "客户信息",
                  visible: _vm.examineVisible,
                  width: "800px",
                  "before-close": _vm.handleClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.examineVisible = $event
                  },
                  close: _vm.handleClose,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "clientForm",
                    attrs: {
                      model: _vm.clientForm,
                      "label-position": _vm.labelPosition,
                      rules: _vm.clientFormRules,
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公司简称",
                          placeholder: "请输入公司简称",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientForm.clientName,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientForm, "clientName", $$v)
                            },
                            expression: "clientForm.clientName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公司股票代码",
                          prop: "clientStockCode",
                          placeholder: "请输入公司股票代码",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientForm.clientStockCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientForm, "clientStockCode", $$v)
                            },
                            expression: "clientForm.clientStockCode",
                          },
                        }),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("例如:SZ000949/SH603738"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公司网址",
                          placeholder: "请输入公司网址",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientForm.clientUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientForm, "clientUrl", $$v)
                            },
                            expression: "clientForm.clientUrl",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公司微信公众号名称",
                          placeholder: "请输入公司微信公众号名称",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientForm.clientGzhName,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientForm, "clientGzhName", $$v)
                            },
                            expression: "clientForm.clientGzhName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公司邮箱",
                          placeholder: "请输入公司邮箱",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientForm.clientEmail,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientForm, "clientEmail", $$v)
                            },
                            expression: "clientForm.clientEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司联系方式" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientForm.clientPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientForm, "clientPhone", $$v)
                            },
                            expression: "clientForm.clientPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.save()
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "提示",
                  visible: _vm.auditVisible,
                  width: "20%",
                  "before-close": _vm.auditleClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.auditVisible = $event
                  },
                },
              },
              [
                _c("span", [_vm._v("确定审核成功吗?")]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.auditVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.auditOKClick },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "提示",
                  visible: _vm.auditsVisible,
                  width: "35%",
                  "before-close": _vm.auditlesClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.auditsVisible = $event
                  },
                },
              },
              [
                _vm.isCheckState
                  ? _c(
                      "div",
                      {
                        staticStyle: { color: "red", "margin-bottom": "30px" },
                      },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.stockNameResult))]),
                        _c("p", [_vm._v(_vm._s(_vm.emailResult))]),
                        _c("p", [_vm._v(_vm._s(_vm.phoneResult))]),
                        _c("p", [_vm._v(_vm._s(_vm.gzhNameResult))]),
                        _c("p", [_vm._v(_vm._s(_vm.urlResult))]),
                      ]
                    )
                  : _vm._e(),
                _c("p", [_vm._v("确定审核成功吗?")]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.auditsVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.auditOKsClick },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }