<!-- 新建/修改新闻 -->
<template>
  <div>
    <div class="releaseForm" v-if="$route.path == '/rssPushForm'">
      <el-card class="box-card" v-if="!isPublish"  >
        <el-form
          :model="formData"
          :rules="formRules"
          ref="formData"
          size="small"
          label-width="140px"
        >
          <section class="self-descriptions">
            <el-row>
              <el-col :span="9">
                <el-form-item
                  label="文章标题："
                  prop="articleTitle"
                  class="limitinputWidth"
                >
                  <el-input
                    placeholder="请输入标题名称"
                    clearable
                    v-model="formData.articleTitle"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item
                  label="公司名称:"
                  prop="author"
                  class="limitinputWidth"
                >
                  <el-input
                    clearable
                    v-model="formData.stockName"
                    @change="autoCode(formData.stockName)"
                    style="width: 300px"
                    placeholder="填写为上市公司名称"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9"  v-if="show">
                <el-form-item label="所属行业:"    class="limitinputWidth"  >
                  <el-input
                    clearable
                    disabled
                    v-model="formData.categoryCodeFirstName"
                    style="width: 300px"
                    placeholder="填输入所属行业"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="show1">
                <el-form-item
                  label="行业类型:"
                >
                  <el-cascader
                    :options="options"
                    @change="handleChange"
                    v-model="formData.industryCategoryArr"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9" v-if="show" >
                <el-form-item label="内容类型:"   >
                  <el-input
                    clearable
                    disabled
                    v-model="formData.stockType"
                    style="width: 300px"
                    placeholder="请输入内容类型"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9" v-if="show1">
                <el-form-item
                  label="股票类型:"

                >

                  <el-select v-model="formData.stockType" placeholder="请选择股票类型"    style="width: auto">
                    <el-option
                      v-for="item in optionContent"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>


              </el-col >
            </el-row>

            <el-row>
              <el-col :span="9">
                <el-form-item
                  label="关联公司："

                  prop="companyId"
                  class="limitinputWidth"
                >
                  <!-- <el-input
                    clearable
                    v-model="formData.companyId"
                    style="width: 455px"
                    placeholder="请填写公司名称或者股票代码"
                  ></el-input> -->
                  <el-select
                    style="width: 300px"
                    v-model="formData.companyId"
                    filterable
                    placeholder="请填写公司名称或者股票代码"
                  >
                    <el-option
                      v-for="item in companyOptions"
                      :key="item.id"
                      :label="item.companyName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="原文链接:"   >
                  <el-input
                    clearable
                    v-model="formData.contentUrl"
                    style="width: 400px"
                    placeholder="请输入原文链接"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="内容摘要：" prop="articleContent">
                  <vue-tinymce
                    v-model="formData.articleContent"
                    :setting="setting"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </section>

          <el-row class="pt40">
            <el-col :span="24">
              <el-form-item>
                <el-button @click="goBack">返回 </el-button>
                <el-button type="primary" :loading="loading"  @click="submitForm('formData')"
                >立即发布
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
<!--      <finial-->
<!--        v-if="isPublish"-->
<!--        :publishSuccess="publishSuccess"-->
<!--        @closePage="closePage"-->
<!--      />-->
    </div>
  </div>
</template>

<script>
import { signActionTime } from 'util/js/headerToken'
import constant from 'constant'
import { articlecontentApi, rssArticleAddApi } from 'api/article'
import { companyPageApi } from 'api/company'
// import finial from './finial.vue'
import { industryCategoryTreeApi } from 'api/common'
import { authStockName,treeContentypeApi } from 'api/article'

import axios from 'axios'
export default {
  name: 'rssPushForm',
  // components: { finial },
  data() {
    return {
      setting: {
        menubar: false,
        toolbar:
          'undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image  table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        quickbars_selection_toolbar:
          'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: 'link image  table lists fullscreen quickbars',
        language: 'zh_CN',
        height: 350,
        images_upload_url: true,
        images_upload_handler: function (blobInfo, succFun, failFun) {
          var formData = new FormData()
          let url = '/common/upload'
          formData.append('file', blobInfo.blob()) //此处与源文档不一样

          axios
            .post(url, formData, {
              headers: signActionTime('post', formData)
            })
            .then(response => {
              succFun(response.data.result.url)
            })
            .catch(error => {
              failFun(error)
            })
        }
      },
      optionIndustryCategoryCode: [],
      optionContent: [{
        value: 'A股',
        label: 'A股'
      }, {
        value: '港股',
        label: '港股'
      }, {
        value: '中概股',
        label: '中概股'
      }],
      typeArray: [
        {
          name: '产品服务',
          id: 30
        },
        {
          name: '经营管理',
          id: 20
        },
        {
          name: 'ESG',
          id: 10
        }

        // {
        //   name: '发展战略',
        //   id: 40
        // }
      ],
      companyOptions: [],
      options: [],
      showUpload: false,
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      myHeaders: {},
      fileList: [],
      fileListLogo: [],
      releaseId: '',
      loading: false,
      closePublish: false,
      formData: {
        // type: '',
        id: '',
        contentUrl: '',
        articleContent: '', //文章内容
        articleTitle: '', //文章标题
        author: '', //作者
        contentType: '', //内容类型
        companyId: '', //企业id
        contentId: '', //企业id
        industryCategoryCode: '',
        industryCategoryArr: [],
        stockType: '',
        categoryName: '',
        categoryCode: '',
        stockName: '',
        city: '',
        categoryCodeFirstName: '',


      },
      formRules: {
        articleTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { max: 100, message: '长度不能超过 100 个字符', trigger: 'blur' }
        ],
        contentUrl: [
          { required: true, message: '请输入原文链接', trigger: 'blur' },
          { max: 100, message: '长度不能超过 100 个字符', trigger: 'blur' }
        ],
        // industryCategoryCode: [
        //   { required: true, message: '请选择所属行业', trigger: 'change' }
        // ],
        newsSource: [
          {
            required: true,
            message: '请填写公司名称或者股票代码',
            trigger: 'blur'
          },
          { max: 200, message: '长度不能超过 200 个字符', trigger: 'blur' }
        ],
        articleContent: [
          { required: true, message: '请输入内容摘要', trigger: 'blur' }
        ],

        companyId: [
          { required: true, message: '请输入关联公司', trigger: 'change' }
        ],
        author: [{ min: 0, max: 10, message: '最多不超过10个字符' }],
        // type: [{ required: true, message: '请选择文章类型', trigger: 'blur' }],
        contentType: [
          { required: true, message: '请选择内容类型', trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
        ]
      },
      inputVisible: false,
      inputValue: '',
      show: false,
      show1: false,
      isPublish: false,
      publishSuccess: {
        status: false,
        reason: '',
        people: JSON.parse(localStorage.getItem('USER_ALL_INFO')).username
      }
    }
  },
  computed: {},
  created() {
    this.initCompany()
    let { id } = this.$route.query
    this.releaseId = id
    if (id) {
      this.getNewsDetail(id)
    }
    this.initCategoryCode()
    this.tree();
  },
  mounted() {
    // this.getNewsTypeList()

  },
  methods: {
    tree() {
      treeContentypeApi().then(data => {
        this.optionIndustryCategoryCode = data.result.industryCategoryList
      });
    },
    autoCode(stockName) {
      if (stockName) {
        authStockName(stockName).then(({result}) => {
          if (result) {
            this.formData.categoryCodeFirstName = result.categoryCodeFirstName;
            this.formData.stockType = result.stockType;
            this.formData.stockName = result.stockName;
            this.formData.industryCategoryCode = result.categoryCode;
            if ( result.categoryCodeFirstName) {
              this.show = true;
              this.show1 = false;
            } else {
              this.show1 = true;
              this.show = false;
            }

          } else {

            this.formData.industryCategoryCode = "";

            //this.formData = this.$options.data().formData
            this.show1 = true;

            this.show = false;

          }
        });
      }

    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.options = result
      })
    },
    handleChange(val) {
      this.formData.industryCategoryCode = val[val.length - 1]
    },
    closePage() {
      this.isPublish = false
    },
    initCompany() {
      companyPageApi({
        pageNum: 1,
        pageSize: 100
      }).then(res => {
        if (res.code === 200) {
          this.companyOptions = res.result.records
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading = false
      })
    },
    getNewsDetail(id) {
      articlecontentApi(id).then(({ result }) => {

        if (result.categoryName === null ||  result.categoryName === '') {
          //  this.formData = this.$options.data().formData
          this.show1 = true;

          this.show = false;
        } else {

          this.show = true;
          this.show1 = false;
        }
        let industryCategoryArr = [];
        if (
          result.industryCategoryList &&
          result.industryCategoryList.length > 0
        ) {
          result.industryCategoryList.map(item => {
            industryCategoryArr.push(item.id)
          })
        }

        this.formData = {
          id: result.articleId,
          type: result.type,
          articleContent: result.articleContent, //文章内容
          articleTitle: result.articleTitle, //文章标题
          author: result.author, //作者
          companyId: result.company.id, //企业id
          contentId: result.contentId, //企业id
          industryCategoryCode: result.industryCategoryCode,
          industryCategoryArr: industryCategoryArr,
          stockType:result.contentType,
          categoryName:result.categoryName,
          categoryCodeFirstName:result.categoryCodeFirstName,
          contentType: result.contentType,
          stockName: result.stockName,
          categoryCode:result.industryCategoryCodePath

        }
      })
    },
    submitForm() {
      this.$refs.formData.validate(valid => {
        this.loading = true
        if (valid) {
          let obj = { ...this.formData }
          if (obj.author == '') {
            obj.author = '动态宝'
          }
          if (this.$route.query.id) {
            obj.parentId = Number(this.$route.query.id)
          }
          rssArticleAddApi(obj).then(res => {
            if (res.code == 200) {
              this.$message.success("文章发布成功!")
            } else {
              // this.$message.error(res.desc)
            }
            this.isPublish = true
            this.loading = false
            this.$router.go(-1);
          })
        }
      })
    },
    // 返回列表页面
    goBack() {
      this.formData = this.$options.data().formData
      this.$router.go(-1)
    },

    goBack1() {
      this.formData = this.$options.data().formData
      this.isPublish = true},
  }
}
</script>

<style lang="scss" scoped>
.releaseForm {
  min-height: calc(100vh - 80px);
  display: flex;
  // justify-content: center;
  // align-items: center;
  background: #fff;
  /deep/.el-card {
    // width: 1200px;
    // margin: 0 auto;
    width: 100%;
  }
}
/deep/ .el-card__header {
  padding: 18px 20px;
  // border-bottom: 1px solid #ebeef5 !important;
}
/deep/ .el-form-item__label {
  width: 122px !important;
}
/deep/ .el-form-item__content {
  margin-left: 130px !important;
}
.firstSelect {
  margin-right: 20px;
}
/deep/.el-tag.el-tag--light {
  margin-right: 10px;
}
</style>
