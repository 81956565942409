var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "channelManagement" }, [
    _c("div", { staticClass: "channelManagement-head" }),
    _c(
      "div",
      [
        _c("el-card", { staticClass: "box-card" }, [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.pageMsg,
                    size: "small",
                    "label-position": "center",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司名称:",
                                "label-width": "120px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "220px" },
                                attrs: { placeholder: "请输入公司名称" },
                                model: {
                                  value: _vm.pageMsg.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "companyName", $$v)
                                  },
                                  expression: "pageMsg.companyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, align: "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.created },
                            },
                            [_vm._v("新建")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.initData },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "companyName", label: "公司简称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "equityNum", label: "权益次数" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "expireTime", label: "到期时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.formatDate(row.expireTime)) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editCompanyInfo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewAssociatedCompanies(
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看关联公司")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "pb20" }),
              _vm.pageTotal > 0
                ? _c("el-pagination", {
                    staticClass: "text-center",
                    attrs: {
                      "current-page": _vm.pageMsg.pageNum,
                      "page-sizes": [10, 50, 100, 200],
                      "page-size": _vm.pageMsg.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageTotal,
                    },
                    on: {
                      "size-change": _vm.initData,
                      "current-change": _vm.initData,
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageNum", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageNum", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.pageMsg, "pageSize", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticStyle: { "margin-left": "-80px" },
                  attrs: {
                    title: "新建公关公司",
                    width: "800px",
                    visible: _vm.addPublicCompanyDialogVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.addPublicCompanyDialogVisible = $event
                    },
                    close: function ($event) {
                      _vm.addPublicCompanyDialogVisible = false
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addRef",
                      attrs: {
                        model: _vm.addMsg,
                        size: "small",
                        rules: _vm.addFormRules,
                        "label-position": "center",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司名称:",
                            "label-width": "120px",
                            prop: "companyName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入公司名称",
                            },
                            model: {
                              value: _vm.addMsg.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addMsg, "companyName", $$v)
                              },
                              expression: "addMsg.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司账号:",
                            "label-width": "120px",
                            prop: "companyAccount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入公司账号",
                            },
                            model: {
                              value: _vm.addMsg.companyAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.addMsg, "companyAccount", $$v)
                              },
                              expression: "addMsg.companyAccount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司密码:",
                            "label-width": "120px",
                            prop: "companyPassword",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "password",
                              placeholder: "请输入公司密码",
                            },
                            model: {
                              value: _vm.addMsg.companyPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.addMsg, "companyPassword", $$v)
                              },
                              expression: "addMsg.companyPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "权益次数:",
                            "label-width": "120px",
                            prop: "equityNum",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "text",
                              placeholder: "请输入权益次数",
                            },
                            model: {
                              value: _vm.addMsg.equityNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.addMsg, "equityNum", $$v)
                              },
                              expression: "addMsg.equityNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "到期时间",
                            "label-width": "120px",
                            prop: "expireTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: { type: "date", placeholder: "选择日期" },
                            model: {
                              value: _vm.addMsg.expireTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addMsg, "expireTime", $$v)
                              },
                              expression: "addMsg.expireTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.addPublicCompany },
                            },
                            [_vm._v("确定")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.addPublicCompanyDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticStyle: { "margin-left": "-80px" },
                  attrs: {
                    title: "编辑公关公司",
                    width: "800px",
                    visible: _vm.editPublicCompanyDialogVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.editPublicCompanyDialogVisible = $event
                    },
                    close: function ($event) {
                      _vm.editPublicCompanyDialogVisible = false
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "editRef",
                      attrs: {
                        model: _vm.editMsg,
                        size: "small",
                        rules: _vm.editFormRules,
                        "label-position": "center",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "公司名称:", "label-width": "120px" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "text",
                              disabled: "",
                              placeholder: "请输入公司名称",
                            },
                            model: {
                              value: _vm.editMsg.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editMsg, "companyName", $$v)
                              },
                              expression: "editMsg.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作:", prop: "editType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.editMsg.editType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editMsg, "editType", $$v)
                                },
                                expression: "editMsg.editType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "0", border: "" } },
                                [_vm._v("增加")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "1", border: "" } },
                                [_vm._v("减少")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "权益次数:", "label-width": "120px" },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, max: 100000 },
                            model: {
                              value: _vm.editMsg.equityNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.editMsg, "equityNum", $$v)
                              },
                              expression: "editMsg.equityNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "到期时间", "label-width": "120px" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: { type: "date", placeholder: "选择日期" },
                            model: {
                              value: _vm.editMsg.expireTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.editMsg, "expireTime", $$v)
                              },
                              expression: "editMsg.expireTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.editPublicCompany },
                            },
                            [_vm._v("确定")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.editPublicCompanyDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticStyle: { "margin-left": "-80px" },
                  attrs: {
                    title: "查看关联信息",
                    width: "800px",
                    height: "1000px",
                    visible: _vm.selectCompanyDialogVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.selectCompanyDialogVisible = $event
                    },
                    close: function ($event) {
                      _vm.selectCompanyDialogVisible = false
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-row",
                                { staticClass: "centered-row" },
                                [
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "container" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              "background-color": "#f5f7fa",
                                              margin: "0 auto",
                                              "border-radius": "10px 10px 0 0",
                                              "font-weight": "bold",
                                            },
                                          },
                                          [_vm._v("待选公司")]
                                        ),
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              placeholder: "请输入关键词",
                                            },
                                            model: {
                                              value: _vm.searchValue,
                                              callback: function ($$v) {
                                                _vm.searchValue = $$v
                                              },
                                              expression: "searchValue",
                                            },
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                icon: "el-icon-search",
                                              },
                                              on: { click: _vm.searchKey },
                                              slot: "append",
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "content" },
                                          [
                                            _c(
                                              "el-checkbox-group",
                                              {
                                                model: {
                                                  value: _vm.filterDataList,
                                                  callback: function ($$v) {
                                                    _vm.filterDataList = $$v
                                                  },
                                                  expression: "filterDataList",
                                                },
                                              },
                                              _vm._l(
                                                _vm.filterData,
                                                function (item) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: item.id,
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        "margin-left": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox",
                                                        {
                                                          attrs: {
                                                            label:
                                                              item.key +
                                                              "-" +
                                                              item.label,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.label)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 5 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          margin: "0 auto",
                                          "text-align": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-right",
                                                },
                                                on: {
                                                  click: _vm.addSameIndustry,
                                                },
                                              },
                                              [_vm._v("加入")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-back",
                                                },
                                                on: {
                                                  click: _vm.cancelSameIndustry,
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _c("div", { staticClass: "container" }, [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                            "background-color": "#f5f7fa",
                                            margin: "0 auto",
                                            "border-radius": "10px 10px 0 0",
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("已选公司")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "content" },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              model: {
                                                value: _vm.sameIndustryList,
                                                callback: function ($$v) {
                                                  _vm.sameIndustryList = $$v
                                                },
                                                expression: "sameIndustryList",
                                              },
                                            },
                                            _vm._l(
                                              _vm.sameIndustryData,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label:
                                                            item.key +
                                                            "-" +
                                                            item.label,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }