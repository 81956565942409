var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.pageMsg,
                    size: "small",
                    "label-position": "center",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户名称:" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "150px" },
                                attrs: { placeholder: "请输入公司简称" },
                                model: {
                                  value: _vm.pageMsg.stockName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "stockName", $$v)
                                  },
                                  expression: "pageMsg.stockName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.search(true)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.search(false)
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.add },
                            },
                            [_vm._v("新建")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: {
                "header-cell-style": { background: "rgb(250,250,250)" },
                data: _vm.tableData,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "公司名称", prop: "stockName" },
              }),
              _c("el-table-column", {
                attrs: { label: "权限模块", prop: "moduleInfo" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticStyle: { "margin-left": "-80px" },
              attrs: {
                title: "编辑客户权限",
                visible: _vm.editVisible,
                width: "30%",
                "before-close": _vm.editClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.editForm,
                    "label-position": _vm.labelPosition,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司名称:", prop: "stockName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.editForm.stockName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "stockName", $$v)
                          },
                          expression: "editForm.stockName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户模块:", prop: "moduleInfo" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { input: _vm.editInput },
                          model: {
                            value: _vm.editForm.moduleInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "moduleInfo", $$v)
                            },
                            expression: "editForm.moduleInfo",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "程序通" } }, [
                            _vm._v("程序通"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "数据汇" } }, [
                            _vm._v("数据汇"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "同业眼" } }, [
                            _vm._v("同业眼"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "指定达" } }, [
                            _vm._v("指定达"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "邮件达" } }, [
                            _vm._v("邮件达"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "短信达" } }, [
                            _vm._v("短信达"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "BA" } }, [
                            _vm._v("BA"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "BA指数" } }, [
                            _vm._v("BA指数"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "BA指标" } }, [
                            _vm._v("BA指标"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "储值发" } }, [
                            _vm._v("储值发"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "财经商业" } }, [
                            _vm._v("财经商业"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "健康医疗" } }, [
                            _vm._v("健康医疗"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "IT科技" } }, [
                            _vm._v("IT科技"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "装备制造" } }, [
                            _vm._v("装备制造"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "酒店旅游" } }, [
                            _vm._v("酒店旅游"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "能源环境" } }, [
                            _vm._v("能源环境"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "全部" } }, [
                            _vm._v("全部"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-button", { on: { click: _vm.editClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editOK } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticStyle: { "margin-left": "-80px" },
              attrs: {
                title: "新建客户权限",
                visible: _vm.addVisible,
                width: "80%",
                "before-close": _vm.addClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.addVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.addForm } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称:", prop: "stockName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.addForm.stockName,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "stockName", $$v)
                            },
                            expression: "addForm.stockName",
                          },
                        },
                        _vm._l(_vm.addOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户模块:", prop: "addModuleInfo" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { input: _vm.addInput },
                          model: {
                            value: _vm.addForm.moduleInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "moduleInfo", $$v)
                            },
                            expression: "addForm.moduleInfo",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "程序通" } }, [
                            _vm._v("程序通"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "数据汇" } }, [
                            _vm._v("数据汇"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "同业眼" } }, [
                            _vm._v("同业眼"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "指定达" } }, [
                            _vm._v("指定达"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "邮件达" } }, [
                            _vm._v("邮件达"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "短信达" } }, [
                            _vm._v("短信达"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "BA" } }, [
                            _vm._v("BA"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "BA指数" } }, [
                            _vm._v("BA指数"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "BA指标" } }, [
                            _vm._v("BA指标"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "储值发" } }, [
                            _vm._v("储值发"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "财经商业" } }, [
                            _vm._v("财经商业"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "健康医疗" } }, [
                            _vm._v("健康医疗"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "IT科技" } }, [
                            _vm._v("IT科技"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "装备制造" } }, [
                            _vm._v("装备制造"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "酒店旅游" } }, [
                            _vm._v("酒店旅游"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "能源环境" } }, [
                            _vm._v("能源环境"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "全部" } }, [
                            _vm._v("全部"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-button", { on: { click: _vm.addClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addOK } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }