export const area = {
  Location: {
    CountryRegion: [
      {
        Name: '中国',
        Code: '1',
        State: [
          {
            Name: '北京',
            Code: '11',
            City: [
              {
                Name: '东城',
                Code: '1'
              },
              {
                Name: '西城',
                Code: '2'
              },
              {
                Name: '朝阳',
                Code: '5'
              },
              {
                Name: '丰台',
                Code: '6'
              },
              {
                Name: '石景山',
                Code: '7'
              },
              {
                Name: '海淀',
                Code: '8'
              },
              {
                Name: '门头沟',
                Code: '9'
              },
              {
                Name: '房山',
                Code: '11'
              },
              {
                Name: '通州',
                Code: '12'
              },
              {
                Name: '顺义',
                Code: '13'
              },
              {
                Name: '昌平',
                Code: '21'
              },
              {
                Name: '大兴',
                Code: '24'
              },
              {
                Name: '平谷',
                Code: '26'
              },
              {
                Name: '怀柔',
                Code: '27'
              },
              {
                Name: '密云',
                Code: '28'
              },
              {
                Name: '延庆',
                Code: '29'
              }
            ]
          },
          {
            Name: '天津',
            Code: '12',
            City: [
              {
                Name: '和平',
                Code: '1'
              },
              {
                Name: '河东',
                Code: '2'
              },
              {
                Name: '河西',
                Code: '3'
              },
              {
                Name: '南开',
                Code: '4'
              },
              {
                Name: '河北',
                Code: '5'
              },
              {
                Name: '红桥',
                Code: '6'
              },
              {
                Name: '滨海新区',
                Code: '26'
              },
              {
                Name: '东丽',
                Code: '10'
              },
              {
                Name: '西青',
                Code: '11'
              },
              {
                Name: '津南',
                Code: '12'
              },
              {
                Name: '北辰',
                Code: '13'
              },
              {
                Name: '宁河',
                Code: '21'
              },
              {
                Name: '武清',
                Code: '22'
              },
              {
                Name: '静海',
                Code: '23'
              },
              {
                Name: '宝坻',
                Code: '24'
              },
              {
                Name: '蓟县',
                Code: '25'
              }
            ]
          },
          {
            Name: '河北',
            Code: '13',
            City: [
              {
                Name: '石家庄',
                Code: '1',
                Region: [
                  {
                    Name: '长安区',
                    Code: '2'
                  },
                  {
                    Name: '桥东区',
                    Code: '3'
                  },
                  {
                    Name: '桥西区',
                    Code: '4'
                  },
                  {
                    Name: '新华区',
                    Code: '5'
                  },
                  {
                    Name: '井陉矿区',
                    Code: '7'
                  },
                  {
                    Name: '裕华区',
                    Code: '8'
                  },
                  {
                    Name: '井陉县',
                    Code: '21'
                  },
                  {
                    Name: '正定县',
                    Code: '23'
                  },
                  {
                    Name: '栾城县',
                    Code: '24'
                  },
                  {
                    Name: '行唐县',
                    Code: '25'
                  },
                  {
                    Name: '灵寿县',
                    Code: '26'
                  },
                  {
                    Name: '高邑县',
                    Code: '27'
                  },
                  {
                    Name: '深泽县',
                    Code: '28'
                  },
                  {
                    Name: '赞皇县',
                    Code: '29'
                  },
                  {
                    Name: '无极县',
                    Code: '30'
                  },
                  {
                    Name: '平山县',
                    Code: '31'
                  },
                  {
                    Name: '元氏县',
                    Code: '32'
                  },
                  {
                    Name: '赵县',
                    Code: '33'
                  },
                  {
                    Name: '辛集市',
                    Code: '81'
                  },
                  {
                    Name: '藁城市',
                    Code: '82'
                  },
                  {
                    Name: '晋州市',
                    Code: '83'
                  },
                  {
                    Name: '新乐市',
                    Code: '84'
                  },
                  {
                    Name: '鹿泉市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '唐山',
                Code: '2',
                Region: [
                  {
                    Name: '路南区',
                    Code: '2'
                  },
                  {
                    Name: '路北区',
                    Code: '3'
                  },
                  {
                    Name: '古冶区',
                    Code: '4'
                  },
                  {
                    Name: '开平区',
                    Code: '5'
                  },
                  {
                    Name: '丰南区',
                    Code: '7'
                  },
                  {
                    Name: '丰润区',
                    Code: '8'
                  },
                  {
                    Name: '曹妃甸区',
                    Code: '9'
                  },
                  {
                    Name: '滦县',
                    Code: '23'
                  },
                  {
                    Name: '滦南县',
                    Code: '24'
                  },
                  {
                    Name: '乐亭县',
                    Code: '25'
                  },
                  {
                    Name: '迁西县',
                    Code: '27'
                  },
                  {
                    Name: '玉田县',
                    Code: '29'
                  },
                  {
                    Name: '遵化市',
                    Code: '81'
                  },
                  {
                    Name: '迁安市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '秦皇岛',
                Code: '3',
                Region: [
                  {
                    Name: '海港区',
                    Code: '2'
                  },
                  {
                    Name: '山海关区',
                    Code: '3'
                  },
                  {
                    Name: '北戴河区',
                    Code: '4'
                  },
                  {
                    Name: '青龙满族自治县',
                    Code: '21'
                  },
                  {
                    Name: '昌黎县',
                    Code: '22'
                  },
                  {
                    Name: '抚宁县',
                    Code: '23'
                  },
                  {
                    Name: '卢龙县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '邯郸',
                Code: '4',
                Region: [
                  {
                    Name: '邯山区',
                    Code: '2'
                  },
                  {
                    Name: '丛台区',
                    Code: '3'
                  },
                  {
                    Name: '复兴区',
                    Code: '4'
                  },
                  {
                    Name: '峰峰矿区',
                    Code: '6'
                  },
                  {
                    Name: '邯郸县',
                    Code: '21'
                  },
                  {
                    Name: '临漳县',
                    Code: '23'
                  },
                  {
                    Name: '成安县',
                    Code: '24'
                  },
                  {
                    Name: '大名县',
                    Code: '25'
                  },
                  {
                    Name: '涉县',
                    Code: '26'
                  },
                  {
                    Name: '磁县',
                    Code: '27'
                  },
                  {
                    Name: '肥乡县',
                    Code: '28'
                  },
                  {
                    Name: '永年县',
                    Code: '29'
                  },
                  {
                    Name: '邱县',
                    Code: '30'
                  },
                  {
                    Name: '鸡泽县',
                    Code: '31'
                  },
                  {
                    Name: '广平县',
                    Code: '32'
                  },
                  {
                    Name: '馆陶县',
                    Code: '33'
                  },
                  {
                    Name: '魏县',
                    Code: '34'
                  },
                  {
                    Name: '曲周县',
                    Code: '35'
                  },
                  {
                    Name: '武安市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '邢台',
                Code: '5',
                Region: [
                  {
                    Name: '桥东区',
                    Code: '2'
                  },
                  {
                    Name: '桥西区',
                    Code: '3'
                  },
                  {
                    Name: '邢台县',
                    Code: '21'
                  },
                  {
                    Name: '临城县',
                    Code: '22'
                  },
                  {
                    Name: '内丘县',
                    Code: '23'
                  },
                  {
                    Name: '柏乡县',
                    Code: '24'
                  },
                  {
                    Name: '隆尧县',
                    Code: '25'
                  },
                  {
                    Name: '任县',
                    Code: '26'
                  },
                  {
                    Name: '南和县',
                    Code: '27'
                  },
                  {
                    Name: '宁晋县',
                    Code: '28'
                  },
                  {
                    Name: '巨鹿县',
                    Code: '29'
                  },
                  {
                    Name: '新河县',
                    Code: '30'
                  },
                  {
                    Name: '广宗县',
                    Code: '31'
                  },
                  {
                    Name: '平乡县',
                    Code: '32'
                  },
                  {
                    Name: '威县',
                    Code: '33'
                  },
                  {
                    Name: '清河县',
                    Code: '34'
                  },
                  {
                    Name: '临西县',
                    Code: '35'
                  },
                  {
                    Name: '南宫市',
                    Code: '81'
                  },
                  {
                    Name: '沙河市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '保定',
                Code: '6',
                Region: [
                  {
                    Name: '新市区',
                    Code: '2'
                  },
                  {
                    Name: '北市区',
                    Code: '3'
                  },
                  {
                    Name: '南市区',
                    Code: '4'
                  },
                  {
                    Name: '满城县',
                    Code: '21'
                  },
                  {
                    Name: '清苑县',
                    Code: '22'
                  },
                  {
                    Name: '涞水县',
                    Code: '23'
                  },
                  {
                    Name: '阜平县',
                    Code: '24'
                  },
                  {
                    Name: '徐水县',
                    Code: '25'
                  },
                  {
                    Name: '定兴县',
                    Code: '26'
                  },
                  {
                    Name: '唐县',
                    Code: '27'
                  },
                  {
                    Name: '高阳县',
                    Code: '28'
                  },
                  {
                    Name: '容城县',
                    Code: '29'
                  },
                  {
                    Name: '涞源县',
                    Code: '30'
                  },
                  {
                    Name: '望都县',
                    Code: '31'
                  },
                  {
                    Name: '安新县',
                    Code: '32'
                  },
                  {
                    Name: '易县',
                    Code: '33'
                  },
                  {
                    Name: '曲阳县',
                    Code: '34'
                  },
                  {
                    Name: '蠡县',
                    Code: '35'
                  },
                  {
                    Name: '顺平县',
                    Code: '36'
                  },
                  {
                    Name: '博野县',
                    Code: '37'
                  },
                  {
                    Name: '雄县',
                    Code: '38'
                  },
                  {
                    Name: '涿州市',
                    Code: '81'
                  },
                  {
                    Name: '定州市',
                    Code: '82'
                  },
                  {
                    Name: '安国市',
                    Code: '83'
                  },
                  {
                    Name: '高碑店市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '张家口',
                Code: '7',
                Region: [
                  {
                    Name: '桥东区',
                    Code: '2'
                  },
                  {
                    Name: '桥西区',
                    Code: '3'
                  },
                  {
                    Name: '宣化区',
                    Code: '5'
                  },
                  {
                    Name: '下花园区',
                    Code: '6'
                  },
                  {
                    Name: '宣化县',
                    Code: '21'
                  },
                  {
                    Name: '张北县',
                    Code: '22'
                  },
                  {
                    Name: '康保县',
                    Code: '23'
                  },
                  {
                    Name: '沽源县',
                    Code: '24'
                  },
                  {
                    Name: '尚义县',
                    Code: '25'
                  },
                  {
                    Name: '蔚县',
                    Code: '26'
                  },
                  {
                    Name: '阳原县',
                    Code: '27'
                  },
                  {
                    Name: '怀安县',
                    Code: '28'
                  },
                  {
                    Name: '万全县',
                    Code: '29'
                  },
                  {
                    Name: '怀来县',
                    Code: '30'
                  },
                  {
                    Name: '涿鹿县',
                    Code: '31'
                  },
                  {
                    Name: '赤城县',
                    Code: '32'
                  },
                  {
                    Name: '崇礼县',
                    Code: '33'
                  }
                ]
              },
              {
                Name: '承德',
                Code: '8',
                Region: [
                  {
                    Name: '双桥区',
                    Code: '2'
                  },
                  {
                    Name: '双滦区',
                    Code: '3'
                  },
                  {
                    Name: '鹰手营子矿区',
                    Code: '4'
                  },
                  {
                    Name: '承德县',
                    Code: '21'
                  },
                  {
                    Name: '兴隆县',
                    Code: '22'
                  },
                  {
                    Name: '平泉县',
                    Code: '23'
                  },
                  {
                    Name: '滦平县',
                    Code: '24'
                  },
                  {
                    Name: '隆化县',
                    Code: '25'
                  },
                  {
                    Name: '丰宁满族自治县',
                    Code: '26'
                  },
                  {
                    Name: '宽城满族自治县',
                    Code: '27'
                  },
                  {
                    Name: '围场满族蒙古族自治县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '沧州',
                Code: '9',
                Region: [
                  {
                    Name: '新华区',
                    Code: '2'
                  },
                  {
                    Name: '运河区',
                    Code: '3'
                  },
                  {
                    Name: '沧县',
                    Code: '21'
                  },
                  {
                    Name: '青县',
                    Code: '22'
                  },
                  {
                    Name: '东光县',
                    Code: '23'
                  },
                  {
                    Name: '海兴县',
                    Code: '24'
                  },
                  {
                    Name: '盐山县',
                    Code: '25'
                  },
                  {
                    Name: '肃宁县',
                    Code: '26'
                  },
                  {
                    Name: '南皮县',
                    Code: '27'
                  },
                  {
                    Name: '吴桥县',
                    Code: '28'
                  },
                  {
                    Name: '献县',
                    Code: '29'
                  },
                  {
                    Name: '孟村回族自治县',
                    Code: '30'
                  },
                  {
                    Name: '泊头市',
                    Code: '81'
                  },
                  {
                    Name: '任丘市',
                    Code: '82'
                  },
                  {
                    Name: '黄骅市',
                    Code: '83'
                  },
                  {
                    Name: '河间市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '廊坊',
                Code: '10',
                Region: [
                  {
                    Name: '安次区',
                    Code: '2'
                  },
                  {
                    Name: '广阳区',
                    Code: '3'
                  },
                  {
                    Name: '固安县',
                    Code: '22'
                  },
                  {
                    Name: '永清县',
                    Code: '23'
                  },
                  {
                    Name: '香河县',
                    Code: '24'
                  },
                  {
                    Name: '大城县',
                    Code: '25'
                  },
                  {
                    Name: '文安县',
                    Code: '26'
                  },
                  {
                    Name: '大厂回族自治县',
                    Code: '28'
                  },
                  {
                    Name: '霸州市',
                    Code: '81'
                  },
                  {
                    Name: '三河市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '衡水',
                Code: '11',
                Region: [
                  {
                    Name: '桃城区',
                    Code: '2'
                  },
                  {
                    Name: '枣强县',
                    Code: '21'
                  },
                  {
                    Name: '武邑县',
                    Code: '22'
                  },
                  {
                    Name: '武强县',
                    Code: '23'
                  },
                  {
                    Name: '饶阳县',
                    Code: '24'
                  },
                  {
                    Name: '安平县',
                    Code: '25'
                  },
                  {
                    Name: '故城县',
                    Code: '26'
                  },
                  {
                    Name: '景县',
                    Code: '27'
                  },
                  {
                    Name: '阜城县',
                    Code: '28'
                  },
                  {
                    Name: '冀州市',
                    Code: '81'
                  },
                  {
                    Name: '深州市',
                    Code: '82'
                  }
                ]
              }
            ]
          },
          {
            Name: '山西',
            Code: '14',
            City: [
              {
                Name: '太原',
                Code: '1',
                Region: [
                  {
                    Name: '小店区',
                    Code: '5'
                  },
                  {
                    Name: '迎泽区',
                    Code: '6'
                  },
                  {
                    Name: '杏花岭区',
                    Code: '7'
                  },
                  {
                    Name: '尖草坪区',
                    Code: '8'
                  },
                  {
                    Name: '万柏林区',
                    Code: '9'
                  },
                  {
                    Name: '晋源区',
                    Code: '10'
                  },
                  {
                    Name: '清徐县',
                    Code: '21'
                  },
                  {
                    Name: '阳曲县',
                    Code: '22'
                  },
                  {
                    Name: '娄烦县',
                    Code: '23'
                  },
                  {
                    Name: '古交市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '大同',
                Code: '2',
                Region: [
                  {
                    Name: '城区',
                    Code: '2'
                  },
                  {
                    Name: '矿区',
                    Code: '3'
                  },
                  {
                    Name: '南郊区',
                    Code: '11'
                  },
                  {
                    Name: '新荣区',
                    Code: '12'
                  },
                  {
                    Name: '阳高县',
                    Code: '21'
                  },
                  {
                    Name: '天镇县',
                    Code: '22'
                  },
                  {
                    Name: '广灵县',
                    Code: '23'
                  },
                  {
                    Name: '灵丘县',
                    Code: '24'
                  },
                  {
                    Name: '浑源县',
                    Code: '25'
                  },
                  {
                    Name: '左云县',
                    Code: '26'
                  },
                  {
                    Name: '大同县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '阳泉',
                Code: '3',
                Region: [
                  {
                    Name: '城区',
                    Code: '2'
                  },
                  {
                    Name: '矿区',
                    Code: '3'
                  },
                  {
                    Name: '郊区',
                    Code: '11'
                  },
                  {
                    Name: '平定县',
                    Code: '21'
                  },
                  {
                    Name: '盂县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '长治',
                Code: '4',
                Region: [
                  {
                    Name: '城区',
                    Code: '2'
                  },
                  {
                    Name: '郊区',
                    Code: '11'
                  },
                  {
                    Name: '长治县',
                    Code: '21'
                  },
                  {
                    Name: '襄垣县',
                    Code: '23'
                  },
                  {
                    Name: '屯留县',
                    Code: '24'
                  },
                  {
                    Name: '平顺县',
                    Code: '25'
                  },
                  {
                    Name: '黎城县',
                    Code: '26'
                  },
                  {
                    Name: '壶关县',
                    Code: '27'
                  },
                  {
                    Name: '长子县',
                    Code: '28'
                  },
                  {
                    Name: '武乡县',
                    Code: '29'
                  },
                  {
                    Name: '沁县',
                    Code: '30'
                  },
                  {
                    Name: '沁源县',
                    Code: '31'
                  },
                  {
                    Name: '潞城市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '晋城',
                Code: '5',
                Region: [
                  {
                    Name: '城区',
                    Code: '2'
                  },
                  {
                    Name: '沁水县',
                    Code: '21'
                  },
                  {
                    Name: '阳城县',
                    Code: '22'
                  },
                  {
                    Name: '陵川县',
                    Code: '24'
                  },
                  {
                    Name: '泽州县',
                    Code: '25'
                  },
                  {
                    Name: '高平市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '朔州',
                Code: '6',
                Region: [
                  {
                    Name: '朔城区',
                    Code: '2'
                  },
                  {
                    Name: '平鲁区',
                    Code: '3'
                  },
                  {
                    Name: '山阴县',
                    Code: '21'
                  },
                  {
                    Name: '应县',
                    Code: '22'
                  },
                  {
                    Name: '右玉县',
                    Code: '23'
                  },
                  {
                    Name: '怀仁县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '晋中',
                Code: '7',
                Region: [
                  {
                    Name: '榆次区',
                    Code: '2'
                  },
                  {
                    Name: '榆社县',
                    Code: '21'
                  },
                  {
                    Name: '左权县',
                    Code: '22'
                  },
                  {
                    Name: '和顺县',
                    Code: '23'
                  },
                  {
                    Name: '昔阳县',
                    Code: '24'
                  },
                  {
                    Name: '寿阳县',
                    Code: '25'
                  },
                  {
                    Name: '太谷县',
                    Code: '26'
                  },
                  {
                    Name: '祁县',
                    Code: '27'
                  },
                  {
                    Name: '平遥县',
                    Code: '28'
                  },
                  {
                    Name: '灵石县',
                    Code: '29'
                  },
                  {
                    Name: '介休市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '运城',
                Code: '8',
                Region: [
                  {
                    Name: '盐湖区',
                    Code: '2'
                  },
                  {
                    Name: '临猗县',
                    Code: '21'
                  },
                  {
                    Name: '万荣县',
                    Code: '22'
                  },
                  {
                    Name: '闻喜县',
                    Code: '23'
                  },
                  {
                    Name: '稷山县',
                    Code: '24'
                  },
                  {
                    Name: '新绛县',
                    Code: '25'
                  },
                  {
                    Name: '绛县',
                    Code: '26'
                  },
                  {
                    Name: '垣曲县',
                    Code: '27'
                  },
                  {
                    Name: '夏县',
                    Code: '28'
                  },
                  {
                    Name: '平陆县',
                    Code: '29'
                  },
                  {
                    Name: '芮城县',
                    Code: '30'
                  },
                  {
                    Name: '永济市',
                    Code: '81'
                  },
                  {
                    Name: '河津市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '忻州',
                Code: '9',
                Region: [
                  {
                    Name: '忻府区',
                    Code: '2'
                  },
                  {
                    Name: '定襄县',
                    Code: '21'
                  },
                  {
                    Name: '五台县',
                    Code: '22'
                  },
                  {
                    Name: '代县',
                    Code: '23'
                  },
                  {
                    Name: '繁峙县',
                    Code: '24'
                  },
                  {
                    Name: '宁武县',
                    Code: '25'
                  },
                  {
                    Name: '静乐县',
                    Code: '26'
                  },
                  {
                    Name: '神池县',
                    Code: '27'
                  },
                  {
                    Name: '五寨县',
                    Code: '28'
                  },
                  {
                    Name: '岢岚县',
                    Code: '29'
                  },
                  {
                    Name: '河曲县',
                    Code: '30'
                  },
                  {
                    Name: '保德县',
                    Code: '31'
                  },
                  {
                    Name: '偏关县',
                    Code: '32'
                  },
                  {
                    Name: '原平市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '临汾',
                Code: '10',
                Region: [
                  {
                    Name: '尧都区',
                    Code: '2'
                  },
                  {
                    Name: '曲沃县',
                    Code: '21'
                  },
                  {
                    Name: '翼城县',
                    Code: '22'
                  },
                  {
                    Name: '襄汾县',
                    Code: '23'
                  },
                  {
                    Name: '洪洞县',
                    Code: '24'
                  },
                  {
                    Name: '古县',
                    Code: '25'
                  },
                  {
                    Name: '安泽县',
                    Code: '26'
                  },
                  {
                    Name: '浮山县',
                    Code: '27'
                  },
                  {
                    Name: '吉县',
                    Code: '28'
                  },
                  {
                    Name: '乡宁县',
                    Code: '29'
                  },
                  {
                    Name: '大宁县',
                    Code: '30'
                  },
                  {
                    Name: '隰县',
                    Code: '31'
                  },
                  {
                    Name: '永和县',
                    Code: '32'
                  },
                  {
                    Name: '蒲县',
                    Code: '33'
                  },
                  {
                    Name: '汾西县',
                    Code: '34'
                  },
                  {
                    Name: '侯马市',
                    Code: '81'
                  },
                  {
                    Name: '霍州市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '吕梁',
                Code: '11',
                Region: [
                  {
                    Name: '离石区',
                    Code: '2'
                  },
                  {
                    Name: '文水县',
                    Code: '21'
                  },
                  {
                    Name: '交城县',
                    Code: '22'
                  },
                  {
                    Name: '兴县',
                    Code: '23'
                  },
                  {
                    Name: '临县',
                    Code: '24'
                  },
                  {
                    Name: '柳林县',
                    Code: '25'
                  },
                  {
                    Name: '石楼县',
                    Code: '26'
                  },
                  {
                    Name: '岚县',
                    Code: '27'
                  },
                  {
                    Name: '方山县',
                    Code: '28'
                  },
                  {
                    Name: '中阳县',
                    Code: '29'
                  },
                  {
                    Name: '交口县',
                    Code: '30'
                  },
                  {
                    Name: '孝义市',
                    Code: '81'
                  },
                  {
                    Name: '汾阳市',
                    Code: '82'
                  }
                ]
              }
            ]
          },
          {
            Name: '内蒙古',
            Code: '15',
            City: [
              {
                Name: '呼和浩特',
                Code: '1',
                Region: [
                  {
                    Name: '新城区',
                    Code: '2'
                  },
                  {
                    Name: '回民区',
                    Code: '3'
                  },
                  {
                    Name: '玉泉区',
                    Code: '4'
                  },
                  {
                    Name: '赛罕区',
                    Code: '5'
                  },
                  {
                    Name: '土默特左旗',
                    Code: '21'
                  },
                  {
                    Name: '托克托县',
                    Code: '22'
                  },
                  {
                    Name: '和林格尔县',
                    Code: '23'
                  },
                  {
                    Name: '清水河县',
                    Code: '24'
                  },
                  {
                    Name: '武川县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '包头',
                Code: '2',
                Region: [
                  {
                    Name: '东河区',
                    Code: '2'
                  },
                  {
                    Name: '昆都仑区',
                    Code: '3'
                  },
                  {
                    Name: '青山区',
                    Code: '4'
                  },
                  {
                    Name: '石拐区',
                    Code: '5'
                  },
                  {
                    Name: '白云鄂博矿区',
                    Code: '6'
                  },
                  {
                    Name: '九原区',
                    Code: '7'
                  },
                  {
                    Name: '土默特右旗',
                    Code: '21'
                  },
                  {
                    Name: '固阳县',
                    Code: '22'
                  },
                  {
                    Name: '达尔罕茂明安联合旗',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '乌海',
                Code: '3',
                Region: [
                  {
                    Name: '海勃湾区',
                    Code: '2'
                  },
                  {
                    Name: '海南区',
                    Code: '3'
                  },
                  {
                    Name: '乌达区',
                    Code: '4'
                  }
                ]
              },
              {
                Name: '赤峰',
                Code: '4',
                Region: [
                  {
                    Name: '红山区',
                    Code: '2'
                  },
                  {
                    Name: '元宝山区',
                    Code: '3'
                  },
                  {
                    Name: '松山区',
                    Code: '4'
                  },
                  {
                    Name: '阿鲁科尔沁旗',
                    Code: '21'
                  },
                  {
                    Name: '巴林左旗',
                    Code: '22'
                  },
                  {
                    Name: '巴林右旗',
                    Code: '23'
                  },
                  {
                    Name: '林西县',
                    Code: '24'
                  },
                  {
                    Name: '克什克腾旗',
                    Code: '25'
                  },
                  {
                    Name: '翁牛特旗',
                    Code: '26'
                  },
                  {
                    Name: '喀喇沁旗',
                    Code: '28'
                  },
                  {
                    Name: '宁城县',
                    Code: '29'
                  },
                  {
                    Name: '敖汉旗',
                    Code: '30'
                  }
                ]
              },
              {
                Name: '通辽',
                Code: '5',
                Region: [
                  {
                    Name: '科尔沁区',
                    Code: '2'
                  },
                  {
                    Name: '科尔沁左翼中旗',
                    Code: '21'
                  },
                  {
                    Name: '科尔沁左翼后旗',
                    Code: '22'
                  },
                  {
                    Name: '开鲁县',
                    Code: '23'
                  },
                  {
                    Name: '库伦旗',
                    Code: '24'
                  },
                  {
                    Name: '奈曼旗',
                    Code: '25'
                  },
                  {
                    Name: '扎鲁特旗',
                    Code: '26'
                  },
                  {
                    Name: '霍林郭勒市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '鄂尔多斯',
                Code: '6',
                Region: [
                  {
                    Name: '东胜区',
                    Code: '2'
                  },
                  {
                    Name: '达拉特旗',
                    Code: '21'
                  },
                  {
                    Name: '准格尔旗',
                    Code: '22'
                  },
                  {
                    Name: '鄂托克前旗',
                    Code: '23'
                  },
                  {
                    Name: '鄂托克旗',
                    Code: '24'
                  },
                  {
                    Name: '杭锦旗',
                    Code: '25'
                  },
                  {
                    Name: '乌审旗',
                    Code: '26'
                  },
                  {
                    Name: '伊金霍洛旗',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '呼伦贝尔',
                Code: '7',
                Region: [
                  {
                    Name: '海拉尔区',
                    Code: '2'
                  },
                  {
                    Name: '阿荣旗',
                    Code: '21'
                  },
                  {
                    Name: '莫力达瓦达斡尔族自治旗',
                    Code: '22'
                  },
                  {
                    Name: '鄂伦春自治旗',
                    Code: '23'
                  },
                  {
                    Name: '鄂温克族自治旗',
                    Code: '24'
                  },
                  {
                    Name: '陈巴尔虎旗',
                    Code: '25'
                  },
                  {
                    Name: '新巴尔虎左旗',
                    Code: '26'
                  },
                  {
                    Name: '新巴尔虎右旗',
                    Code: '27'
                  },
                  {
                    Name: '满洲里市',
                    Code: '81'
                  },
                  {
                    Name: '牙克石市',
                    Code: '82'
                  },
                  {
                    Name: '扎兰屯市',
                    Code: '83'
                  },
                  {
                    Name: '额尔古纳市',
                    Code: '84'
                  },
                  {
                    Name: '根河市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '巴彦淖尔',
                Code: '8',
                Region: [
                  {
                    Name: '临河区',
                    Code: '2'
                  },
                  {
                    Name: '五原县',
                    Code: '21'
                  },
                  {
                    Name: '磴口县',
                    Code: '22'
                  },
                  {
                    Name: '乌拉特前旗',
                    Code: '23'
                  },
                  {
                    Name: '乌拉特中旗',
                    Code: '24'
                  },
                  {
                    Name: '乌拉特后旗',
                    Code: '25'
                  },
                  {
                    Name: '杭锦后旗',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '乌兰察布',
                Code: '9',
                Region: [
                  {
                    Name: '集宁区',
                    Code: '2'
                  },
                  {
                    Name: '卓资县',
                    Code: '21'
                  },
                  {
                    Name: '化德县',
                    Code: '22'
                  },
                  {
                    Name: '商都县',
                    Code: '23'
                  },
                  {
                    Name: '兴和县',
                    Code: '24'
                  },
                  {
                    Name: '凉城县',
                    Code: '25'
                  },
                  {
                    Name: '察哈尔右翼前旗',
                    Code: '26'
                  },
                  {
                    Name: '察哈尔右翼中旗',
                    Code: '27'
                  },
                  {
                    Name: '察哈尔右翼后旗',
                    Code: '28'
                  },
                  {
                    Name: '四子王旗',
                    Code: '29'
                  },
                  {
                    Name: '丰镇市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '兴安',
                Code: '22',
                Region: [
                  {
                    Name: '乌兰浩特市',
                    Code: '1'
                  },
                  {
                    Name: '阿尔山市',
                    Code: '2'
                  },
                  {
                    Name: '科尔沁右翼前旗',
                    Code: '21'
                  },
                  {
                    Name: '科尔沁右翼中旗',
                    Code: '22'
                  },
                  {
                    Name: '扎赉特旗',
                    Code: '23'
                  },
                  {
                    Name: '突泉县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '锡林郭勒',
                Code: '25',
                Region: [
                  {
                    Name: '二连浩特市',
                    Code: '1'
                  },
                  {
                    Name: '锡林浩特市',
                    Code: '2'
                  },
                  {
                    Name: '阿巴嘎旗',
                    Code: '22'
                  },
                  {
                    Name: '苏尼特左旗',
                    Code: '23'
                  },
                  {
                    Name: '苏尼特右旗',
                    Code: '24'
                  },
                  {
                    Name: '东乌珠穆沁旗',
                    Code: '25'
                  },
                  {
                    Name: '西乌珠穆沁旗',
                    Code: '26'
                  },
                  {
                    Name: '太仆寺旗',
                    Code: '27'
                  },
                  {
                    Name: '镶黄旗',
                    Code: '28'
                  },
                  {
                    Name: '正镶白旗',
                    Code: '29'
                  },
                  {
                    Name: '正蓝旗',
                    Code: '30'
                  },
                  {
                    Name: '多伦县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '阿拉善',
                Code: '29',
                Region: [
                  {
                    Name: '阿拉善左旗',
                    Code: '21'
                  },
                  {
                    Name: '阿拉善右旗',
                    Code: '22'
                  },
                  {
                    Name: '额济纳旗',
                    Code: '23'
                  }
                ]
              }
            ]
          },
          {
            Name: '辽宁',
            Code: '21',
            City: [
              {
                Name: '沈阳',
                Code: '1',
                Region: [
                  {
                    Name: '和平区',
                    Code: '2'
                  },
                  {
                    Name: '沈河区',
                    Code: '3'
                  },
                  {
                    Name: '大东区',
                    Code: '4'
                  },
                  {
                    Name: '皇姑区',
                    Code: '5'
                  },
                  {
                    Name: '铁西区',
                    Code: '6'
                  },
                  {
                    Name: '苏家屯区',
                    Code: '11'
                  },
                  {
                    Name: '浑南区',
                    Code: '12'
                  },
                  {
                    Name: '沈北新区',
                    Code: '13'
                  },
                  {
                    Name: '于洪区',
                    Code: '14'
                  },
                  {
                    Name: '辽中县',
                    Code: '22'
                  },
                  {
                    Name: '康平县',
                    Code: '23'
                  },
                  {
                    Name: '法库县',
                    Code: '24'
                  },
                  {
                    Name: '新民市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '大连',
                Code: '2',
                Region: [
                  {
                    Name: '中山区',
                    Code: '2'
                  },
                  {
                    Name: '西岗区',
                    Code: '3'
                  },
                  {
                    Name: '沙河口区',
                    Code: '4'
                  },
                  {
                    Name: '甘井子区',
                    Code: '11'
                  },
                  {
                    Name: '旅顺口区',
                    Code: '12'
                  },
                  {
                    Name: '金州区',
                    Code: '13'
                  },
                  {
                    Name: '长海县',
                    Code: '24'
                  },
                  {
                    Name: '瓦房店市',
                    Code: '81'
                  },
                  {
                    Name: '普兰店市',
                    Code: '82'
                  },
                  {
                    Name: '庄河市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '鞍山',
                Code: '3',
                Region: [
                  {
                    Name: '铁东区',
                    Code: '2'
                  },
                  {
                    Name: '铁西区',
                    Code: '3'
                  },
                  {
                    Name: '立山区',
                    Code: '4'
                  },
                  {
                    Name: '千山区',
                    Code: '11'
                  },
                  {
                    Name: '台安县',
                    Code: '21'
                  },
                  {
                    Name: '岫岩满族自治县',
                    Code: '23'
                  },
                  {
                    Name: '海城市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '抚顺',
                Code: '4',
                Region: [
                  {
                    Name: '新抚区',
                    Code: '2'
                  },
                  {
                    Name: '东洲区',
                    Code: '3'
                  },
                  {
                    Name: '望花区',
                    Code: '4'
                  },
                  {
                    Name: '顺城区',
                    Code: '11'
                  },
                  {
                    Name: '抚顺县',
                    Code: '21'
                  },
                  {
                    Name: '新宾满族自治县',
                    Code: '22'
                  },
                  {
                    Name: '清原满族自治县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '本溪',
                Code: '5',
                Region: [
                  {
                    Name: '平山区',
                    Code: '2'
                  },
                  {
                    Name: '溪湖区',
                    Code: '3'
                  },
                  {
                    Name: '明山区',
                    Code: '4'
                  },
                  {
                    Name: '南芬区',
                    Code: '5'
                  },
                  {
                    Name: '本溪满族自治县',
                    Code: '21'
                  },
                  {
                    Name: '桓仁满族自治县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '丹东',
                Code: '6',
                Region: [
                  {
                    Name: '元宝区',
                    Code: '2'
                  },
                  {
                    Name: '振兴区',
                    Code: '3'
                  },
                  {
                    Name: '振安区',
                    Code: '4'
                  },
                  {
                    Name: '宽甸满族自治县',
                    Code: '24'
                  },
                  {
                    Name: '东港市',
                    Code: '81'
                  },
                  {
                    Name: '凤城市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '锦州',
                Code: '7',
                Region: [
                  {
                    Name: '古塔区',
                    Code: '2'
                  },
                  {
                    Name: '凌河区',
                    Code: '3'
                  },
                  {
                    Name: '太和区',
                    Code: '11'
                  },
                  {
                    Name: '黑山县',
                    Code: '26'
                  },
                  {
                    Name: '义县',
                    Code: '27'
                  },
                  {
                    Name: '凌海市',
                    Code: '81'
                  },
                  {
                    Name: '北镇市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '营口',
                Code: '8',
                Region: [
                  {
                    Name: '站前区',
                    Code: '2'
                  },
                  {
                    Name: '西市区',
                    Code: '3'
                  },
                  {
                    Name: '鲅鱼圈区',
                    Code: '4'
                  },
                  {
                    Name: '老边区',
                    Code: '11'
                  },
                  {
                    Name: '盖州市',
                    Code: '81'
                  },
                  {
                    Name: '大石桥市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '阜新',
                Code: '9',
                Region: [
                  {
                    Name: '海州区',
                    Code: '2'
                  },
                  {
                    Name: '新邱区',
                    Code: '3'
                  },
                  {
                    Name: '太平区',
                    Code: '4'
                  },
                  {
                    Name: '清河门区',
                    Code: '5'
                  },
                  {
                    Name: '细河区',
                    Code: '11'
                  },
                  {
                    Name: '阜新蒙古族自治县',
                    Code: '21'
                  },
                  {
                    Name: '彰武县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '辽阳',
                Code: '10',
                Region: [
                  {
                    Name: '白塔区',
                    Code: '2'
                  },
                  {
                    Name: '文圣区',
                    Code: '3'
                  },
                  {
                    Name: '宏伟区',
                    Code: '4'
                  },
                  {
                    Name: '弓长岭区',
                    Code: '5'
                  },
                  {
                    Name: '太子河区',
                    Code: '11'
                  },
                  {
                    Name: '辽阳县',
                    Code: '21'
                  },
                  {
                    Name: '灯塔市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '盘锦',
                Code: '11',
                Region: [
                  {
                    Name: '双台子区',
                    Code: '2'
                  },
                  {
                    Name: '兴隆台区',
                    Code: '3'
                  },
                  {
                    Name: '大洼县',
                    Code: '21'
                  },
                  {
                    Name: '盘山县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '铁岭',
                Code: '12',
                Region: [
                  {
                    Name: '银州区',
                    Code: '2'
                  },
                  {
                    Name: '清河区',
                    Code: '4'
                  },
                  {
                    Name: '铁岭县',
                    Code: '21'
                  },
                  {
                    Name: '西丰县',
                    Code: '23'
                  },
                  {
                    Name: '昌图县',
                    Code: '24'
                  },
                  {
                    Name: '调兵山市',
                    Code: '81'
                  },
                  {
                    Name: '开原市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '朝阳',
                Code: '13',
                Region: [
                  {
                    Name: '双塔区',
                    Code: '2'
                  },
                  {
                    Name: '龙城区',
                    Code: '3'
                  },
                  {
                    Name: '朝阳县',
                    Code: '21'
                  },
                  {
                    Name: '建平县',
                    Code: '22'
                  },
                  {
                    Name: '喀喇沁左翼蒙古族自治县',
                    Code: '24'
                  },
                  {
                    Name: '北票市',
                    Code: '81'
                  },
                  {
                    Name: '凌源市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '葫芦岛',
                Code: '14',
                Region: [
                  {
                    Name: '连山区',
                    Code: '2'
                  },
                  {
                    Name: '龙港区',
                    Code: '3'
                  },
                  {
                    Name: '南票区',
                    Code: '4'
                  },
                  {
                    Name: '绥中县',
                    Code: '21'
                  },
                  {
                    Name: '建昌县',
                    Code: '22'
                  },
                  {
                    Name: '兴城市',
                    Code: '81'
                  }
                ]
              }
            ]
          },
          {
            Name: '吉林',
            Code: '22',
            City: [
              {
                Name: '长春',
                Code: '1',
                Region: [
                  {
                    Name: '南关区',
                    Code: '2'
                  },
                  {
                    Name: '宽城区',
                    Code: '3'
                  },
                  {
                    Name: '朝阳区',
                    Code: '4'
                  },
                  {
                    Name: '二道区',
                    Code: '5'
                  },
                  {
                    Name: '绿园区',
                    Code: '6'
                  },
                  {
                    Name: '双阳区',
                    Code: '12'
                  },
                  {
                    Name: '农安县',
                    Code: '22'
                  },
                  {
                    Name: '九台市',
                    Code: '81'
                  },
                  {
                    Name: '榆树市',
                    Code: '82'
                  },
                  {
                    Name: '德惠市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '吉林',
                Code: '2',
                Region: [
                  {
                    Name: '昌邑区',
                    Code: '2'
                  },
                  {
                    Name: '龙潭区',
                    Code: '3'
                  },
                  {
                    Name: '船营区',
                    Code: '4'
                  },
                  {
                    Name: '丰满区',
                    Code: '11'
                  },
                  {
                    Name: '永吉县',
                    Code: '21'
                  },
                  {
                    Name: '蛟河市',
                    Code: '81'
                  },
                  {
                    Name: '桦甸市',
                    Code: '82'
                  },
                  {
                    Name: '舒兰市',
                    Code: '83'
                  },
                  {
                    Name: '磐石市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '四平',
                Code: '3',
                Region: [
                  {
                    Name: '铁西区',
                    Code: '2'
                  },
                  {
                    Name: '铁东区',
                    Code: '3'
                  },
                  {
                    Name: '梨树县',
                    Code: '22'
                  },
                  {
                    Name: '伊通满族自治县',
                    Code: '23'
                  },
                  {
                    Name: '公主岭市',
                    Code: '81'
                  },
                  {
                    Name: '双辽市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '辽源',
                Code: '4',
                Region: [
                  {
                    Name: '龙山区',
                    Code: '2'
                  },
                  {
                    Name: '西安区',
                    Code: '3'
                  },
                  {
                    Name: '东丰县',
                    Code: '21'
                  },
                  {
                    Name: '东辽县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '通化',
                Code: '5',
                Region: [
                  {
                    Name: '东昌区',
                    Code: '2'
                  },
                  {
                    Name: '二道江区',
                    Code: '3'
                  },
                  {
                    Name: '通化县',
                    Code: '21'
                  },
                  {
                    Name: '辉南县',
                    Code: '23'
                  },
                  {
                    Name: '柳河县',
                    Code: '24'
                  },
                  {
                    Name: '梅河口市',
                    Code: '81'
                  },
                  {
                    Name: '集安市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '白山',
                Code: '6',
                Region: [
                  {
                    Name: '八道江区',
                    Code: '2'
                  },
                  {
                    Name: '江源区',
                    Code: '5'
                  },
                  {
                    Name: '抚松县',
                    Code: '21'
                  },
                  {
                    Name: '靖宇县',
                    Code: '22'
                  },
                  {
                    Name: '长白朝鲜族自治县',
                    Code: '23'
                  },
                  {
                    Name: '临江市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '松原',
                Code: '7',
                Region: [
                  {
                    Name: '宁江区',
                    Code: '2'
                  },
                  {
                    Name: '前郭尔罗斯蒙古族自治县',
                    Code: '21'
                  },
                  {
                    Name: '长岭县',
                    Code: '22'
                  },
                  {
                    Name: '乾安县',
                    Code: '23'
                  },
                  {
                    Name: '扶余县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '白城',
                Code: '8',
                Region: [
                  {
                    Name: '洮北区',
                    Code: '2'
                  },
                  {
                    Name: '镇赉县',
                    Code: '21'
                  },
                  {
                    Name: '通榆县',
                    Code: '22'
                  },
                  {
                    Name: '洮南市',
                    Code: '81'
                  },
                  {
                    Name: '大安市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '延边',
                Code: '24',
                Region: [
                  {
                    Name: '延吉市',
                    Code: '1'
                  },
                  {
                    Name: '图们市',
                    Code: '2'
                  },
                  {
                    Name: '敦化市',
                    Code: '3'
                  },
                  {
                    Name: '珲春市',
                    Code: '4'
                  },
                  {
                    Name: '龙井市',
                    Code: '5'
                  },
                  {
                    Name: '和龙市',
                    Code: '6'
                  },
                  {
                    Name: '汪清县',
                    Code: '24'
                  },
                  {
                    Name: '安图县',
                    Code: '26'
                  }
                ]
              }
            ]
          },
          {
            Name: '黑龙江',
            Code: '23',
            City: [
              {
                Name: '哈尔滨',
                Code: '1',
                Region: [
                  {
                    Name: '道里区',
                    Code: '2'
                  },
                  {
                    Name: '南岗区',
                    Code: '3'
                  },
                  {
                    Name: '道外区',
                    Code: '4'
                  },
                  {
                    Name: '平房区',
                    Code: '8'
                  },
                  {
                    Name: '松北区',
                    Code: '9'
                  },
                  {
                    Name: '香坊区',
                    Code: '10'
                  },
                  {
                    Name: '呼兰区',
                    Code: '11'
                  },
                  {
                    Name: '阿城区',
                    Code: '12'
                  },
                  {
                    Name: '依兰县',
                    Code: '23'
                  },
                  {
                    Name: '方正县',
                    Code: '24'
                  },
                  {
                    Name: '宾县',
                    Code: '25'
                  },
                  {
                    Name: '巴彦县',
                    Code: '26'
                  },
                  {
                    Name: '木兰县',
                    Code: '27'
                  },
                  {
                    Name: '通河县',
                    Code: '28'
                  },
                  {
                    Name: '延寿县',
                    Code: '29'
                  },
                  {
                    Name: '双城市',
                    Code: '82'
                  },
                  {
                    Name: '尚志市',
                    Code: '83'
                  },
                  {
                    Name: '五常市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '齐齐哈尔',
                Code: '2',
                Region: [
                  {
                    Name: '龙沙区',
                    Code: '2'
                  },
                  {
                    Name: '建华区',
                    Code: '3'
                  },
                  {
                    Name: '铁锋区',
                    Code: '4'
                  },
                  {
                    Name: '昂昂溪区',
                    Code: '5'
                  },
                  {
                    Name: '富拉尔基区',
                    Code: '6'
                  },
                  {
                    Name: '碾子山区',
                    Code: '7'
                  },
                  {
                    Name: '梅里斯达斡尔族区',
                    Code: '8'
                  },
                  {
                    Name: '龙江县',
                    Code: '21'
                  },
                  {
                    Name: '依安县',
                    Code: '23'
                  },
                  {
                    Name: '泰来县',
                    Code: '24'
                  },
                  {
                    Name: '甘南县',
                    Code: '25'
                  },
                  {
                    Name: '富裕县',
                    Code: '27'
                  },
                  {
                    Name: '克山县',
                    Code: '29'
                  },
                  {
                    Name: '克东县',
                    Code: '30'
                  },
                  {
                    Name: '拜泉县',
                    Code: '31'
                  },
                  {
                    Name: '讷河市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '鸡西',
                Code: '3',
                Region: [
                  {
                    Name: '鸡冠区',
                    Code: '2'
                  },
                  {
                    Name: '恒山区',
                    Code: '3'
                  },
                  {
                    Name: '滴道区',
                    Code: '4'
                  },
                  {
                    Name: '梨树区',
                    Code: '5'
                  },
                  {
                    Name: '城子河区',
                    Code: '6'
                  },
                  {
                    Name: '麻山区',
                    Code: '7'
                  },
                  {
                    Name: '鸡东县',
                    Code: '21'
                  },
                  {
                    Name: '虎林市',
                    Code: '81'
                  },
                  {
                    Name: '密山市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '鹤岗',
                Code: '4',
                Region: [
                  {
                    Name: '向阳区',
                    Code: '2'
                  },
                  {
                    Name: '工农区',
                    Code: '3'
                  },
                  {
                    Name: '南山区',
                    Code: '4'
                  },
                  {
                    Name: '兴安区',
                    Code: '5'
                  },
                  {
                    Name: '东山区',
                    Code: '6'
                  },
                  {
                    Name: '兴山区',
                    Code: '7'
                  },
                  {
                    Name: '萝北县',
                    Code: '21'
                  },
                  {
                    Name: '绥滨县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '双鸭山',
                Code: '5',
                Region: [
                  {
                    Name: '尖山区',
                    Code: '2'
                  },
                  {
                    Name: '岭东区',
                    Code: '3'
                  },
                  {
                    Name: '四方台区',
                    Code: '5'
                  },
                  {
                    Name: '宝山区',
                    Code: '6'
                  },
                  {
                    Name: '集贤县',
                    Code: '21'
                  },
                  {
                    Name: '友谊县',
                    Code: '22'
                  },
                  {
                    Name: '宝清县',
                    Code: '23'
                  },
                  {
                    Name: '饶河县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '大庆',
                Code: '6',
                Region: [
                  {
                    Name: '萨尔图区',
                    Code: '2'
                  },
                  {
                    Name: '龙凤区',
                    Code: '3'
                  },
                  {
                    Name: '让胡路区',
                    Code: '4'
                  },
                  {
                    Name: '红岗区',
                    Code: '5'
                  },
                  {
                    Name: '大同区',
                    Code: '6'
                  },
                  {
                    Name: '肇州县',
                    Code: '21'
                  },
                  {
                    Name: '肇源县',
                    Code: '22'
                  },
                  {
                    Name: '林甸县',
                    Code: '23'
                  },
                  {
                    Name: '杜尔伯特蒙古族自治县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '伊春',
                Code: '7',
                Region: [
                  {
                    Name: '伊春区',
                    Code: '2'
                  },
                  {
                    Name: '南岔区',
                    Code: '3'
                  },
                  {
                    Name: '友好区',
                    Code: '4'
                  },
                  {
                    Name: '西林区',
                    Code: '5'
                  },
                  {
                    Name: '翠峦区',
                    Code: '6'
                  },
                  {
                    Name: '新青区',
                    Code: '7'
                  },
                  {
                    Name: '美溪区',
                    Code: '8'
                  },
                  {
                    Name: '金山屯区',
                    Code: '9'
                  },
                  {
                    Name: '五营区',
                    Code: '10'
                  },
                  {
                    Name: '乌马河区',
                    Code: '11'
                  },
                  {
                    Name: '汤旺河区',
                    Code: '12'
                  },
                  {
                    Name: '带岭区',
                    Code: '13'
                  },
                  {
                    Name: '乌伊岭区',
                    Code: '14'
                  },
                  {
                    Name: '红星区',
                    Code: '15'
                  },
                  {
                    Name: '上甘岭区',
                    Code: '16'
                  },
                  {
                    Name: '嘉荫县',
                    Code: '22'
                  },
                  {
                    Name: '铁力市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '佳木斯',
                Code: '8',
                Region: [
                  {
                    Name: '向阳区',
                    Code: '3'
                  },
                  {
                    Name: '前进区',
                    Code: '4'
                  },
                  {
                    Name: '东风区',
                    Code: '5'
                  },
                  {
                    Name: '郊区',
                    Code: '11'
                  },
                  {
                    Name: '桦南县',
                    Code: '22'
                  },
                  {
                    Name: '桦川县',
                    Code: '26'
                  },
                  {
                    Name: '汤原县',
                    Code: '28'
                  },
                  {
                    Name: '抚远县',
                    Code: '33'
                  },
                  {
                    Name: '同江市',
                    Code: '81'
                  },
                  {
                    Name: '富锦市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '七台河',
                Code: '9',
                Region: [
                  {
                    Name: '新兴区',
                    Code: '2'
                  },
                  {
                    Name: '桃山区',
                    Code: '3'
                  },
                  {
                    Name: '茄子河区',
                    Code: '4'
                  },
                  {
                    Name: '勃利县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '牡丹江',
                Code: '10',
                Region: [
                  {
                    Name: '东安区',
                    Code: '2'
                  },
                  {
                    Name: '阳明区',
                    Code: '3'
                  },
                  {
                    Name: '爱民区',
                    Code: '4'
                  },
                  {
                    Name: '西安区',
                    Code: '5'
                  },
                  {
                    Name: '东宁县',
                    Code: '24'
                  },
                  {
                    Name: '林口县',
                    Code: '25'
                  },
                  {
                    Name: '绥芬河市',
                    Code: '81'
                  },
                  {
                    Name: '海林市',
                    Code: '83'
                  },
                  {
                    Name: '宁安市',
                    Code: '84'
                  },
                  {
                    Name: '穆棱市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '黑河',
                Code: '11',
                Region: [
                  {
                    Name: '爱辉区',
                    Code: '2'
                  },
                  {
                    Name: '嫩江县',
                    Code: '21'
                  },
                  {
                    Name: '逊克县',
                    Code: '23'
                  },
                  {
                    Name: '孙吴县',
                    Code: '24'
                  },
                  {
                    Name: '北安市',
                    Code: '81'
                  },
                  {
                    Name: '五大连池市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '绥化',
                Code: '12',
                Region: [
                  {
                    Name: '北林区',
                    Code: '2'
                  },
                  {
                    Name: '望奎县',
                    Code: '21'
                  },
                  {
                    Name: '兰西县',
                    Code: '22'
                  },
                  {
                    Name: '青冈县',
                    Code: '23'
                  },
                  {
                    Name: '庆安县',
                    Code: '24'
                  },
                  {
                    Name: '明水县',
                    Code: '25'
                  },
                  {
                    Name: '绥棱县',
                    Code: '26'
                  },
                  {
                    Name: '安达市',
                    Code: '81'
                  },
                  {
                    Name: '肇东市',
                    Code: '82'
                  },
                  {
                    Name: '海伦市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '大兴安岭',
                Code: '27',
                Region: [
                  {
                    Name: '呼玛县',
                    Code: '21'
                  },
                  {
                    Name: '塔河县',
                    Code: '22'
                  },
                  {
                    Name: '漠河县',
                    Code: '23'
                  }
                ]
              }
            ]
          },
          {
            Name: '上海',
            Code: '31',
            City: [
              {
                Name: '黄浦',
                Code: '1'
              },
              {
                Name: '卢湾',
                Code: '3'
              },
              {
                Name: '徐汇',
                Code: '4'
              },
              {
                Name: '长宁',
                Code: '5'
              },
              {
                Name: '静安',
                Code: '6'
              },
              {
                Name: '普陀',
                Code: '7'
              },
              {
                Name: '闸北',
                Code: '8'
              },
              {
                Name: '虹口',
                Code: '9'
              },
              {
                Name: '杨浦',
                Code: '11'
              },
              {
                Name: '闵行',
                Code: '12'
              },
              {
                Name: '宝山',
                Code: '13'
              },
              {
                Name: '嘉定',
                Code: '14'
              },
              {
                Name: '浦东新区',
                Code: '15'
              },
              {
                Name: '金山',
                Code: '16'
              },
              {
                Name: '松江',
                Code: '17'
              },
              {
                Name: '奉贤',
                Code: '26'
              },
              {
                Name: '青浦',
                Code: '29'
              },
              {
                Name: '崇明',
                Code: '30'
              }
            ]
          },
          {
            Name: '江苏',
            Code: '32',
            City: [
              {
                Name: '南京',
                Code: '1',
                Region: [
                  {
                    Name: '玄武区',
                    Code: '2'
                  },
                  {
                    Name: '白下区',
                    Code: '3'
                  },
                  {
                    Name: '秦淮区',
                    Code: '4'
                  },
                  {
                    Name: '建邺区',
                    Code: '5'
                  },
                  {
                    Name: '鼓楼区',
                    Code: '6'
                  },
                  {
                    Name: '下关区',
                    Code: '7'
                  },
                  {
                    Name: '浦口区',
                    Code: '11'
                  },
                  {
                    Name: '栖霞区',
                    Code: '13'
                  },
                  {
                    Name: '雨花台区',
                    Code: '14'
                  },
                  {
                    Name: '江宁区',
                    Code: '15'
                  },
                  {
                    Name: '六合区',
                    Code: '16'
                  },
                  {
                    Name: '溧水县',
                    Code: '24'
                  },
                  {
                    Name: '高淳县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '无锡',
                Code: '2',
                Region: [
                  {
                    Name: '崇安区',
                    Code: '2'
                  },
                  {
                    Name: '南长区',
                    Code: '3'
                  },
                  {
                    Name: '北塘区',
                    Code: '4'
                  },
                  {
                    Name: '锡山区',
                    Code: '5'
                  },
                  {
                    Name: '惠山区',
                    Code: '6'
                  },
                  {
                    Name: '滨湖区',
                    Code: '11'
                  },
                  {
                    Name: '江阴市',
                    Code: '81'
                  },
                  {
                    Name: '宜兴市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '徐州',
                Code: '3',
                Region: [
                  {
                    Name: '鼓楼区',
                    Code: '2'
                  },
                  {
                    Name: '云龙区',
                    Code: '3'
                  },
                  {
                    Name: '九里区',
                    Code: '4'
                  },
                  {
                    Name: '贾汪区',
                    Code: '5'
                  },
                  {
                    Name: '泉山区',
                    Code: '11'
                  },
                  {
                    Name: '丰县',
                    Code: '21'
                  },
                  {
                    Name: '沛县',
                    Code: '22'
                  },
                  {
                    Name: '铜山县',
                    Code: '23'
                  },
                  {
                    Name: '睢宁县',
                    Code: '24'
                  },
                  {
                    Name: '新沂市',
                    Code: '81'
                  },
                  {
                    Name: '邳州市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '常州',
                Code: '4',
                Region: [
                  {
                    Name: '天宁区',
                    Code: '2'
                  },
                  {
                    Name: '钟楼区',
                    Code: '4'
                  },
                  {
                    Name: '戚墅堰区',
                    Code: '5'
                  },
                  {
                    Name: '新北区',
                    Code: '11'
                  },
                  {
                    Name: '武进区',
                    Code: '12'
                  },
                  {
                    Name: '溧阳市',
                    Code: '81'
                  },
                  {
                    Name: '金坛市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '苏州',
                Code: '5',
                Region: [
                  {
                    Name: '姑苏区',
                    Code: '4'
                  },
                  {
                    Name: '虎丘区',
                    Code: '5'
                  },
                  {
                    Name: '吴中区',
                    Code: '6'
                  },
                  {
                    Name: '相城区',
                    Code: '7'
                  },
                  {
                    Name: '吴江区',
                    Code: '8'
                  },
                  {
                    Name: '常熟市',
                    Code: '81'
                  },
                  {
                    Name: '张家港市',
                    Code: '82'
                  },
                  {
                    Name: '昆山市',
                    Code: '83'
                  },
                  {
                    Name: '太仓市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '南通',
                Code: '6',
                Region: [
                  {
                    Name: '崇川区',
                    Code: '2'
                  },
                  {
                    Name: '港闸区',
                    Code: '11'
                  },
                  {
                    Name: '海安县',
                    Code: '21'
                  },
                  {
                    Name: '如东县',
                    Code: '23'
                  },
                  {
                    Name: '启东市',
                    Code: '81'
                  },
                  {
                    Name: '如皋市',
                    Code: '82'
                  },
                  {
                    Name: '通州市',
                    Code: '83'
                  },
                  {
                    Name: '海门市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '连云港',
                Code: '7',
                Region: [
                  {
                    Name: '连云区',
                    Code: '3'
                  },
                  {
                    Name: '新浦区',
                    Code: '5'
                  },
                  {
                    Name: '海州区',
                    Code: '6'
                  },
                  {
                    Name: '赣榆县',
                    Code: '21'
                  },
                  {
                    Name: '东海县',
                    Code: '22'
                  },
                  {
                    Name: '灌云县',
                    Code: '23'
                  },
                  {
                    Name: '灌南县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '淮安',
                Code: '8',
                Region: [
                  {
                    Name: '清河区',
                    Code: '2'
                  },
                  {
                    Name: '淮安区',
                    Code: '3'
                  },
                  {
                    Name: '淮阴区',
                    Code: '4'
                  },
                  {
                    Name: '清浦区',
                    Code: '11'
                  },
                  {
                    Name: '涟水县',
                    Code: '26'
                  },
                  {
                    Name: '洪泽县',
                    Code: '29'
                  },
                  {
                    Name: '盱眙县',
                    Code: '30'
                  },
                  {
                    Name: '金湖县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '盐城',
                Code: '9',
                Region: [
                  {
                    Name: '亭湖区',
                    Code: '2'
                  },
                  {
                    Name: '盐都区',
                    Code: '3'
                  },
                  {
                    Name: '响水县',
                    Code: '21'
                  },
                  {
                    Name: '滨海县',
                    Code: '22'
                  },
                  {
                    Name: '阜宁县',
                    Code: '23'
                  },
                  {
                    Name: '射阳县',
                    Code: '24'
                  },
                  {
                    Name: '建湖县',
                    Code: '25'
                  },
                  {
                    Name: '东台市',
                    Code: '81'
                  },
                  {
                    Name: '大丰市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '扬州',
                Code: '10',
                Region: [
                  {
                    Name: '广陵区',
                    Code: '2'
                  },
                  {
                    Name: '邗江区',
                    Code: '3'
                  },
                  {
                    Name: '维扬区',
                    Code: '11'
                  },
                  {
                    Name: '宝应县',
                    Code: '23'
                  },
                  {
                    Name: '仪征市',
                    Code: '81'
                  },
                  {
                    Name: '高邮市',
                    Code: '84'
                  },
                  {
                    Name: '江都市',
                    Code: '88'
                  }
                ]
              },
              {
                Name: '镇江',
                Code: '11',
                Region: [
                  {
                    Name: '京口区',
                    Code: '2'
                  },
                  {
                    Name: '润州区',
                    Code: '11'
                  },
                  {
                    Name: '丹徒区',
                    Code: '12'
                  },
                  {
                    Name: '丹阳市',
                    Code: '81'
                  },
                  {
                    Name: '扬中市',
                    Code: '82'
                  },
                  {
                    Name: '句容市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '泰州',
                Code: '12',
                Region: [
                  {
                    Name: '海陵区',
                    Code: '2'
                  },
                  {
                    Name: '高港区',
                    Code: '3'
                  },
                  {
                    Name: '兴化市',
                    Code: '81'
                  },
                  {
                    Name: '靖江市',
                    Code: '82'
                  },
                  {
                    Name: '泰兴市',
                    Code: '83'
                  },
                  {
                    Name: '姜堰市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '宿迁',
                Code: '13',
                Region: [
                  {
                    Name: '宿城区',
                    Code: '2'
                  },
                  {
                    Name: '宿豫区',
                    Code: '11'
                  },
                  {
                    Name: '沭阳县',
                    Code: '22'
                  },
                  {
                    Name: '泗阳县',
                    Code: '23'
                  },
                  {
                    Name: '泗洪县',
                    Code: '24'
                  }
                ]
              }
            ]
          },
          {
            Name: '浙江',
            Code: '33',
            City: [
              {
                Name: '杭州',
                Code: '1',
                Region: [
                  {
                    Name: '上城区',
                    Code: '2'
                  },
                  {
                    Name: '下城区',
                    Code: '3'
                  },
                  {
                    Name: '江干区',
                    Code: '4'
                  },
                  {
                    Name: '拱墅区',
                    Code: '5'
                  },
                  {
                    Name: '西湖区',
                    Code: '6'
                  },
                  {
                    Name: '滨江区',
                    Code: '8'
                  },
                  {
                    Name: '萧山区',
                    Code: '9'
                  },
                  {
                    Name: '余杭区',
                    Code: '10'
                  },
                  {
                    Name: '桐庐县',
                    Code: '22'
                  },
                  {
                    Name: '淳安县',
                    Code: '27'
                  },
                  {
                    Name: '建德市',
                    Code: '82'
                  },
                  {
                    Name: '富阳市',
                    Code: '83'
                  },
                  {
                    Name: '临安市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '宁波',
                Code: '2',
                Region: [
                  {
                    Name: '海曙区',
                    Code: '3'
                  },
                  {
                    Name: '江东区',
                    Code: '4'
                  },
                  {
                    Name: '江北区',
                    Code: '5'
                  },
                  {
                    Name: '北仑区',
                    Code: '6'
                  },
                  {
                    Name: '镇海区',
                    Code: '11'
                  },
                  {
                    Name: '鄞州区',
                    Code: '12'
                  },
                  {
                    Name: '象山县',
                    Code: '25'
                  },
                  {
                    Name: '宁海县',
                    Code: '26'
                  },
                  {
                    Name: '余姚市',
                    Code: '81'
                  },
                  {
                    Name: '慈溪市',
                    Code: '82'
                  },
                  {
                    Name: '奉化市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '温州',
                Code: '3',
                Region: [
                  {
                    Name: '鹿城区',
                    Code: '2'
                  },
                  {
                    Name: '龙湾区',
                    Code: '3'
                  },
                  {
                    Name: '瓯海区',
                    Code: '4'
                  },
                  {
                    Name: '洞头县',
                    Code: '22'
                  },
                  {
                    Name: '永嘉县',
                    Code: '24'
                  },
                  {
                    Name: '平阳县',
                    Code: '26'
                  },
                  {
                    Name: '苍南县',
                    Code: '27'
                  },
                  {
                    Name: '文成县',
                    Code: '28'
                  },
                  {
                    Name: '泰顺县',
                    Code: '29'
                  },
                  {
                    Name: '瑞安市',
                    Code: '81'
                  },
                  {
                    Name: '乐清市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '嘉兴',
                Code: '4',
                Region: [
                  {
                    Name: '南湖区',
                    Code: '2'
                  },
                  {
                    Name: '秀洲区',
                    Code: '11'
                  },
                  {
                    Name: '嘉善县',
                    Code: '21'
                  },
                  {
                    Name: '海盐县',
                    Code: '24'
                  },
                  {
                    Name: '海宁市',
                    Code: '81'
                  },
                  {
                    Name: '平湖市',
                    Code: '82'
                  },
                  {
                    Name: '桐乡市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '湖州',
                Code: '5',
                Region: [
                  {
                    Name: '吴兴区',
                    Code: '2'
                  },
                  {
                    Name: '南浔区',
                    Code: '3'
                  },
                  {
                    Name: '德清县',
                    Code: '21'
                  },
                  {
                    Name: '长兴县',
                    Code: '22'
                  },
                  {
                    Name: '安吉县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '绍兴',
                Code: '6',
                Region: [
                  {
                    Name: '越城区',
                    Code: '2'
                  },
                  {
                    Name: '绍兴县',
                    Code: '21'
                  },
                  {
                    Name: '新昌县',
                    Code: '24'
                  },
                  {
                    Name: '诸暨市',
                    Code: '81'
                  },
                  {
                    Name: '上虞市',
                    Code: '82'
                  },
                  {
                    Name: '嵊州市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '金华',
                Code: '7',
                Region: [
                  {
                    Name: '婺城区',
                    Code: '2'
                  },
                  {
                    Name: '金东区',
                    Code: '3'
                  },
                  {
                    Name: '武义县',
                    Code: '23'
                  },
                  {
                    Name: '浦江县',
                    Code: '26'
                  },
                  {
                    Name: '磐安县',
                    Code: '27'
                  },
                  {
                    Name: '兰溪市',
                    Code: '81'
                  },
                  {
                    Name: '义乌市',
                    Code: '82'
                  },
                  {
                    Name: '东阳市',
                    Code: '83'
                  },
                  {
                    Name: '永康市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '衢州',
                Code: '8',
                Region: [
                  {
                    Name: '柯城区',
                    Code: '2'
                  },
                  {
                    Name: '衢江区',
                    Code: '3'
                  },
                  {
                    Name: '常山县',
                    Code: '22'
                  },
                  {
                    Name: '开化县',
                    Code: '24'
                  },
                  {
                    Name: '龙游县',
                    Code: '25'
                  },
                  {
                    Name: '江山市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '舟山',
                Code: '9',
                Region: [
                  {
                    Name: '定海区',
                    Code: '2'
                  },
                  {
                    Name: '普陀区',
                    Code: '3'
                  },
                  {
                    Name: '岱山县',
                    Code: '21'
                  },
                  {
                    Name: '嵊泗县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '台州',
                Code: '10',
                Region: [
                  {
                    Name: '椒江区',
                    Code: '2'
                  },
                  {
                    Name: '黄岩区',
                    Code: '3'
                  },
                  {
                    Name: '路桥区',
                    Code: '4'
                  },
                  {
                    Name: '玉环县',
                    Code: '21'
                  },
                  {
                    Name: '三门县',
                    Code: '22'
                  },
                  {
                    Name: '天台县',
                    Code: '23'
                  },
                  {
                    Name: '仙居县',
                    Code: '24'
                  },
                  {
                    Name: '温岭市',
                    Code: '81'
                  },
                  {
                    Name: '临海市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '丽水',
                Code: '11',
                Region: [
                  {
                    Name: '莲都区',
                    Code: '2'
                  },
                  {
                    Name: '青田县',
                    Code: '21'
                  },
                  {
                    Name: '缙云县',
                    Code: '22'
                  },
                  {
                    Name: '遂昌县',
                    Code: '23'
                  },
                  {
                    Name: '松阳县',
                    Code: '24'
                  },
                  {
                    Name: '云和县',
                    Code: '25'
                  },
                  {
                    Name: '庆元县',
                    Code: '26'
                  },
                  {
                    Name: '景宁畲族自治县',
                    Code: '27'
                  },
                  {
                    Name: '龙泉市',
                    Code: '81'
                  }
                ]
              }
            ]
          },
          {
            Name: '安徽',
            Code: '34',
            City: [
              {
                Name: '合肥',
                Code: '1',
                Region: [
                  {
                    Name: '瑶海区',
                    Code: '2'
                  },
                  {
                    Name: '庐阳区',
                    Code: '3'
                  },
                  {
                    Name: '蜀山区',
                    Code: '4'
                  },
                  {
                    Name: '包河区',
                    Code: '11'
                  },
                  {
                    Name: '长丰县',
                    Code: '21'
                  },
                  {
                    Name: '肥东县',
                    Code: '22'
                  },
                  {
                    Name: '肥西县',
                    Code: '23'
                  },
                  {
                    Name: '庐江县',
                    Code: '24'
                  },
                  {
                    Name: '巢湖市',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '芜湖',
                Code: '2',
                Region: [
                  {
                    Name: '镜湖区',
                    Code: '2'
                  },
                  {
                    Name: '弋江区',
                    Code: '3'
                  },
                  {
                    Name: '鸠江区',
                    Code: '7'
                  },
                  {
                    Name: '三山区',
                    Code: '8'
                  },
                  {
                    Name: '芜湖县',
                    Code: '21'
                  },
                  {
                    Name: '繁昌县',
                    Code: '22'
                  },
                  {
                    Name: '南陵县',
                    Code: '23'
                  },
                  {
                    Name: '无为县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '蚌埠',
                Code: '3',
                Region: [
                  {
                    Name: '龙子湖区',
                    Code: '2'
                  },
                  {
                    Name: '蚌山区',
                    Code: '3'
                  },
                  {
                    Name: '禹会区',
                    Code: '4'
                  },
                  {
                    Name: '淮上区',
                    Code: '11'
                  },
                  {
                    Name: '怀远县',
                    Code: '21'
                  },
                  {
                    Name: '五河县',
                    Code: '22'
                  },
                  {
                    Name: '固镇县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '淮南',
                Code: '4',
                Region: [
                  {
                    Name: '大通区',
                    Code: '2'
                  },
                  {
                    Name: '田家庵区',
                    Code: '3'
                  },
                  {
                    Name: '谢家集区',
                    Code: '4'
                  },
                  {
                    Name: '八公山区',
                    Code: '5'
                  },
                  {
                    Name: '潘集区',
                    Code: '6'
                  },
                  {
                    Name: '凤台县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '马鞍山',
                Code: '5',
                Region: [
                  {
                    Name: '花山区',
                    Code: '3'
                  },
                  {
                    Name: '雨山区',
                    Code: '4'
                  },
                  {
                    Name: '博望区',
                    Code: '5'
                  },
                  {
                    Name: '当涂县',
                    Code: '21'
                  },
                  {
                    Name: '含山县',
                    Code: '22'
                  },
                  {
                    Name: '和县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '淮北',
                Code: '6',
                Region: [
                  {
                    Name: '杜集区',
                    Code: '2'
                  },
                  {
                    Name: '相山区',
                    Code: '3'
                  },
                  {
                    Name: '烈山区',
                    Code: '4'
                  },
                  {
                    Name: '濉溪县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '铜陵',
                Code: '7',
                Region: [
                  {
                    Name: '铜官山区',
                    Code: '2'
                  },
                  {
                    Name: '狮子山区',
                    Code: '3'
                  },
                  {
                    Name: '郊区',
                    Code: '11'
                  },
                  {
                    Name: '铜陵县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '安庆',
                Code: '8',
                Region: [
                  {
                    Name: '迎江区',
                    Code: '2'
                  },
                  {
                    Name: '大观区',
                    Code: '3'
                  },
                  {
                    Name: '宜秀区',
                    Code: '11'
                  },
                  {
                    Name: '怀宁县',
                    Code: '22'
                  },
                  {
                    Name: '枞阳县',
                    Code: '23'
                  },
                  {
                    Name: '潜山县',
                    Code: '24'
                  },
                  {
                    Name: '太湖县',
                    Code: '25'
                  },
                  {
                    Name: '宿松县',
                    Code: '26'
                  },
                  {
                    Name: '望江县',
                    Code: '27'
                  },
                  {
                    Name: '岳西县',
                    Code: '28'
                  },
                  {
                    Name: '桐城市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '黄山',
                Code: '10',
                Region: [
                  {
                    Name: '屯溪区',
                    Code: '2'
                  },
                  {
                    Name: '黄山区',
                    Code: '3'
                  },
                  {
                    Name: '徽州区',
                    Code: '4'
                  },
                  {
                    Name: '歙县',
                    Code: '21'
                  },
                  {
                    Name: '休宁县',
                    Code: '22'
                  },
                  {
                    Name: '黟县',
                    Code: '23'
                  },
                  {
                    Name: '祁门县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '滁州',
                Code: '11',
                Region: [
                  {
                    Name: '琅琊区',
                    Code: '2'
                  },
                  {
                    Name: '南谯区',
                    Code: '3'
                  },
                  {
                    Name: '来安县',
                    Code: '22'
                  },
                  {
                    Name: '全椒县',
                    Code: '24'
                  },
                  {
                    Name: '定远县',
                    Code: '25'
                  },
                  {
                    Name: '凤阳县',
                    Code: '26'
                  },
                  {
                    Name: '天长市',
                    Code: '81'
                  },
                  {
                    Name: '明光市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '阜阳',
                Code: '12',
                Region: [
                  {
                    Name: '颍州区',
                    Code: '2'
                  },
                  {
                    Name: '颍东区',
                    Code: '3'
                  },
                  {
                    Name: '颍泉区',
                    Code: '4'
                  },
                  {
                    Name: '临泉县',
                    Code: '21'
                  },
                  {
                    Name: '太和县',
                    Code: '22'
                  },
                  {
                    Name: '阜南县',
                    Code: '25'
                  },
                  {
                    Name: '颍上县',
                    Code: '26'
                  },
                  {
                    Name: '界首市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '宿州',
                Code: '13',
                Region: [
                  {
                    Name: '埇桥区',
                    Code: '2'
                  },
                  {
                    Name: '砀山县',
                    Code: '21'
                  },
                  {
                    Name: '萧县',
                    Code: '22'
                  },
                  {
                    Name: '灵璧县',
                    Code: '23'
                  },
                  {
                    Name: '泗县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '六安',
                Code: '15',
                Region: [
                  {
                    Name: '金安区',
                    Code: '2'
                  },
                  {
                    Name: '裕安区',
                    Code: '3'
                  },
                  {
                    Name: '寿县',
                    Code: '21'
                  },
                  {
                    Name: '霍邱县',
                    Code: '22'
                  },
                  {
                    Name: '舒城县',
                    Code: '23'
                  },
                  {
                    Name: '金寨县',
                    Code: '24'
                  },
                  {
                    Name: '霍山县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '亳州',
                Code: '16',
                Region: [
                  {
                    Name: '谯城区',
                    Code: '2'
                  },
                  {
                    Name: '涡阳县',
                    Code: '21'
                  },
                  {
                    Name: '蒙城县',
                    Code: '22'
                  },
                  {
                    Name: '利辛县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '池州',
                Code: '17',
                Region: [
                  {
                    Name: '贵池区',
                    Code: '2'
                  },
                  {
                    Name: '东至县',
                    Code: '21'
                  },
                  {
                    Name: '石台县',
                    Code: '22'
                  },
                  {
                    Name: '青阳县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '宣城',
                Code: '18',
                Region: [
                  {
                    Name: '宣州区',
                    Code: '2'
                  },
                  {
                    Name: '郎溪县',
                    Code: '21'
                  },
                  {
                    Name: '广德县',
                    Code: '22'
                  },
                  {
                    Name: '泾县',
                    Code: '23'
                  },
                  {
                    Name: '绩溪县',
                    Code: '24'
                  },
                  {
                    Name: '旌德县',
                    Code: '25'
                  },
                  {
                    Name: '宁国市',
                    Code: '81'
                  }
                ]
              }
            ]
          },
          {
            Name: '福建',
            Code: '35',
            City: [
              {
                Name: '福州',
                Code: '1',
                Region: [
                  {
                    Name: '鼓楼区',
                    Code: '2'
                  },
                  {
                    Name: '台江区',
                    Code: '3'
                  },
                  {
                    Name: '仓山区',
                    Code: '4'
                  },
                  {
                    Name: '马尾区',
                    Code: '5'
                  },
                  {
                    Name: '晋安区',
                    Code: '11'
                  },
                  {
                    Name: '闽侯县',
                    Code: '21'
                  },
                  {
                    Name: '连江县',
                    Code: '22'
                  },
                  {
                    Name: '罗源县',
                    Code: '23'
                  },
                  {
                    Name: '闽清县',
                    Code: '24'
                  },
                  {
                    Name: '永泰县',
                    Code: '25'
                  },
                  {
                    Name: '平潭县',
                    Code: '28'
                  },
                  {
                    Name: '福清市',
                    Code: '81'
                  },
                  {
                    Name: '长乐市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '厦门',
                Code: '2',
                Region: [
                  {
                    Name: '思明区',
                    Code: '3'
                  },
                  {
                    Name: '海沧区',
                    Code: '5'
                  },
                  {
                    Name: '湖里区',
                    Code: '6'
                  },
                  {
                    Name: '集美区',
                    Code: '11'
                  },
                  {
                    Name: '同安区',
                    Code: '12'
                  },
                  {
                    Name: '翔安区',
                    Code: '13'
                  }
                ]
              },
              {
                Name: '莆田',
                Code: '3',
                Region: [
                  {
                    Name: '城厢区',
                    Code: '2'
                  },
                  {
                    Name: '涵江区',
                    Code: '3'
                  },
                  {
                    Name: '荔城区',
                    Code: '4'
                  },
                  {
                    Name: '秀屿区',
                    Code: '5'
                  },
                  {
                    Name: '仙游县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '三明',
                Code: '4',
                Region: [
                  {
                    Name: '梅列区',
                    Code: '2'
                  },
                  {
                    Name: '三元区',
                    Code: '3'
                  },
                  {
                    Name: '明溪县',
                    Code: '21'
                  },
                  {
                    Name: '清流县',
                    Code: '23'
                  },
                  {
                    Name: '宁化县',
                    Code: '24'
                  },
                  {
                    Name: '大田县',
                    Code: '25'
                  },
                  {
                    Name: '尤溪县',
                    Code: '26'
                  },
                  {
                    Name: '沙县',
                    Code: '27'
                  },
                  {
                    Name: '将乐县',
                    Code: '28'
                  },
                  {
                    Name: '泰宁县',
                    Code: '29'
                  },
                  {
                    Name: '建宁县',
                    Code: '30'
                  },
                  {
                    Name: '永安市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '泉州',
                Code: '5',
                Region: [
                  {
                    Name: '鲤城区',
                    Code: '2'
                  },
                  {
                    Name: '丰泽区',
                    Code: '3'
                  },
                  {
                    Name: '洛江区',
                    Code: '4'
                  },
                  {
                    Name: '泉港区',
                    Code: '5'
                  },
                  {
                    Name: '惠安县',
                    Code: '21'
                  },
                  {
                    Name: '安溪县',
                    Code: '24'
                  },
                  {
                    Name: '永春县',
                    Code: '25'
                  },
                  {
                    Name: '德化县',
                    Code: '26'
                  },
                  {
                    Name: '金门县',
                    Code: '27'
                  },
                  {
                    Name: '石狮市',
                    Code: '81'
                  },
                  {
                    Name: '晋江市',
                    Code: '82'
                  },
                  {
                    Name: '南安市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '漳州',
                Code: '6',
                Region: [
                  {
                    Name: '芗城区',
                    Code: '2'
                  },
                  {
                    Name: '龙文区',
                    Code: '3'
                  },
                  {
                    Name: '云霄县',
                    Code: '22'
                  },
                  {
                    Name: '漳浦县',
                    Code: '23'
                  },
                  {
                    Name: '诏安县',
                    Code: '24'
                  },
                  {
                    Name: '长泰县',
                    Code: '25'
                  },
                  {
                    Name: '东山县',
                    Code: '26'
                  },
                  {
                    Name: '南靖县',
                    Code: '27'
                  },
                  {
                    Name: '平和县',
                    Code: '28'
                  },
                  {
                    Name: '华安县',
                    Code: '29'
                  },
                  {
                    Name: '龙海市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '南平',
                Code: '7',
                Region: [
                  {
                    Name: '延平区',
                    Code: '2'
                  },
                  {
                    Name: '顺昌县',
                    Code: '21'
                  },
                  {
                    Name: '浦城县',
                    Code: '22'
                  },
                  {
                    Name: '光泽县',
                    Code: '23'
                  },
                  {
                    Name: '松溪县',
                    Code: '24'
                  },
                  {
                    Name: '政和县',
                    Code: '25'
                  },
                  {
                    Name: '邵武市',
                    Code: '81'
                  },
                  {
                    Name: '武夷山市',
                    Code: '82'
                  },
                  {
                    Name: '建瓯市',
                    Code: '83'
                  },
                  {
                    Name: '建阳市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '龙岩',
                Code: '8',
                Region: [
                  {
                    Name: '新罗区',
                    Code: '2'
                  },
                  {
                    Name: '长汀县',
                    Code: '21'
                  },
                  {
                    Name: '永定县',
                    Code: '22'
                  },
                  {
                    Name: '上杭县',
                    Code: '23'
                  },
                  {
                    Name: '武平县',
                    Code: '24'
                  },
                  {
                    Name: '连城县',
                    Code: '25'
                  },
                  {
                    Name: '漳平市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '宁德',
                Code: '9',
                Region: [
                  {
                    Name: '蕉城区',
                    Code: '2'
                  },
                  {
                    Name: '霞浦县',
                    Code: '21'
                  },
                  {
                    Name: '古田县',
                    Code: '22'
                  },
                  {
                    Name: '屏南县',
                    Code: '23'
                  },
                  {
                    Name: '寿宁县',
                    Code: '24'
                  },
                  {
                    Name: '周宁县',
                    Code: '25'
                  },
                  {
                    Name: '柘荣县',
                    Code: '26'
                  },
                  {
                    Name: '福安市',
                    Code: '81'
                  },
                  {
                    Name: '福鼎市',
                    Code: '82'
                  }
                ]
              }
            ]
          },
          {
            Name: '江西',
            Code: '36',
            City: [
              {
                Name: '南昌',
                Code: '1',
                Region: [
                  {
                    Name: '东湖区',
                    Code: '2'
                  },
                  {
                    Name: '西湖区',
                    Code: '3'
                  },
                  {
                    Name: '青云谱区',
                    Code: '4'
                  },
                  {
                    Name: '湾里区',
                    Code: '5'
                  },
                  {
                    Name: '青山湖区',
                    Code: '11'
                  },
                  {
                    Name: '南昌县',
                    Code: '21'
                  },
                  {
                    Name: '新建县',
                    Code: '22'
                  },
                  {
                    Name: '安义县',
                    Code: '23'
                  },
                  {
                    Name: '进贤县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '景德镇',
                Code: '2',
                Region: [
                  {
                    Name: '昌江区',
                    Code: '2'
                  },
                  {
                    Name: '珠山区',
                    Code: '3'
                  },
                  {
                    Name: '浮梁县',
                    Code: '22'
                  },
                  {
                    Name: '乐平市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '萍乡',
                Code: '3',
                Region: [
                  {
                    Name: '安源区',
                    Code: '2'
                  },
                  {
                    Name: '湘东区',
                    Code: '13'
                  },
                  {
                    Name: '莲花县',
                    Code: '21'
                  },
                  {
                    Name: '上栗县',
                    Code: '22'
                  },
                  {
                    Name: '芦溪县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '九江',
                Code: '4',
                Region: [
                  {
                    Name: '庐山区',
                    Code: '2'
                  },
                  {
                    Name: '浔阳区',
                    Code: '3'
                  },
                  {
                    Name: '九江县',
                    Code: '21'
                  },
                  {
                    Name: '武宁县',
                    Code: '23'
                  },
                  {
                    Name: '修水县',
                    Code: '24'
                  },
                  {
                    Name: '永修县',
                    Code: '25'
                  },
                  {
                    Name: '德安县',
                    Code: '26'
                  },
                  {
                    Name: '星子县',
                    Code: '27'
                  },
                  {
                    Name: '都昌县',
                    Code: '28'
                  },
                  {
                    Name: '湖口县',
                    Code: '29'
                  },
                  {
                    Name: '彭泽县',
                    Code: '30'
                  },
                  {
                    Name: '瑞昌市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '新余',
                Code: '5',
                Region: [
                  {
                    Name: '渝水区',
                    Code: '2'
                  },
                  {
                    Name: '分宜县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '鹰潭',
                Code: '6',
                Region: [
                  {
                    Name: '月湖区',
                    Code: '2'
                  },
                  {
                    Name: '余江县',
                    Code: '22'
                  },
                  {
                    Name: '贵溪市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '赣州',
                Code: '7',
                Region: [
                  {
                    Name: '章贡区',
                    Code: '2'
                  },
                  {
                    Name: '赣县',
                    Code: '21'
                  },
                  {
                    Name: '信丰县',
                    Code: '22'
                  },
                  {
                    Name: '大余县',
                    Code: '23'
                  },
                  {
                    Name: '上犹县',
                    Code: '24'
                  },
                  {
                    Name: '崇义县',
                    Code: '25'
                  },
                  {
                    Name: '安远县',
                    Code: '26'
                  },
                  {
                    Name: '龙南县',
                    Code: '27'
                  },
                  {
                    Name: '定南县',
                    Code: '28'
                  },
                  {
                    Name: '全南县',
                    Code: '29'
                  },
                  {
                    Name: '宁都县',
                    Code: '30'
                  },
                  {
                    Name: '于都县',
                    Code: '31'
                  },
                  {
                    Name: '兴国县',
                    Code: '32'
                  },
                  {
                    Name: '会昌县',
                    Code: '33'
                  },
                  {
                    Name: '寻乌县',
                    Code: '34'
                  },
                  {
                    Name: '石城县',
                    Code: '35'
                  },
                  {
                    Name: '瑞金市',
                    Code: '81'
                  },
                  {
                    Name: '南康市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '吉安',
                Code: '8',
                Region: [
                  {
                    Name: '吉州区',
                    Code: '2'
                  },
                  {
                    Name: '青原区',
                    Code: '3'
                  },
                  {
                    Name: '吉安县',
                    Code: '21'
                  },
                  {
                    Name: '吉水县',
                    Code: '22'
                  },
                  {
                    Name: '峡江县',
                    Code: '23'
                  },
                  {
                    Name: '新干县',
                    Code: '24'
                  },
                  {
                    Name: '永丰县',
                    Code: '25'
                  },
                  {
                    Name: '泰和县',
                    Code: '26'
                  },
                  {
                    Name: '遂川县',
                    Code: '27'
                  },
                  {
                    Name: '万安县',
                    Code: '28'
                  },
                  {
                    Name: '安福县',
                    Code: '29'
                  },
                  {
                    Name: '永新县',
                    Code: '30'
                  },
                  {
                    Name: '井冈山市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '宜春',
                Code: '9',
                Region: [
                  {
                    Name: '袁州区',
                    Code: '2'
                  },
                  {
                    Name: '奉新县',
                    Code: '21'
                  },
                  {
                    Name: '万载县',
                    Code: '22'
                  },
                  {
                    Name: '上高县',
                    Code: '23'
                  },
                  {
                    Name: '宜丰县',
                    Code: '24'
                  },
                  {
                    Name: '靖安县',
                    Code: '25'
                  },
                  {
                    Name: '铜鼓县',
                    Code: '26'
                  },
                  {
                    Name: '丰城市',
                    Code: '81'
                  },
                  {
                    Name: '樟树市',
                    Code: '82'
                  },
                  {
                    Name: '高安市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '抚州',
                Code: '10',
                Region: [
                  {
                    Name: '临川区',
                    Code: '2'
                  },
                  {
                    Name: '南城县',
                    Code: '21'
                  },
                  {
                    Name: '黎川县',
                    Code: '22'
                  },
                  {
                    Name: '南丰县',
                    Code: '23'
                  },
                  {
                    Name: '崇仁县',
                    Code: '24'
                  },
                  {
                    Name: '乐安县',
                    Code: '25'
                  },
                  {
                    Name: '宜黄县',
                    Code: '26'
                  },
                  {
                    Name: '金溪县',
                    Code: '27'
                  },
                  {
                    Name: '资溪县',
                    Code: '28'
                  },
                  {
                    Name: '东乡县',
                    Code: '29'
                  },
                  {
                    Name: '广昌县',
                    Code: '30'
                  }
                ]
              },
              {
                Name: '上饶',
                Code: '11',
                Region: [
                  {
                    Name: '信州区',
                    Code: '2'
                  },
                  {
                    Name: '上饶县',
                    Code: '21'
                  },
                  {
                    Name: '广丰县',
                    Code: '22'
                  },
                  {
                    Name: '玉山县',
                    Code: '23'
                  },
                  {
                    Name: '铅山县',
                    Code: '24'
                  },
                  {
                    Name: '横峰县',
                    Code: '25'
                  },
                  {
                    Name: '弋阳县',
                    Code: '26'
                  },
                  {
                    Name: '余干县',
                    Code: '27'
                  },
                  {
                    Name: '鄱阳县',
                    Code: '28'
                  },
                  {
                    Name: '万年县',
                    Code: '29'
                  },
                  {
                    Name: '婺源县',
                    Code: '30'
                  },
                  {
                    Name: '德兴市',
                    Code: '81'
                  }
                ]
              }
            ]
          },
          {
            Name: '山东',
            Code: '37',
            City: [
              {
                Name: '济南',
                Code: '1',
                Region: [
                  {
                    Name: '历下区',
                    Code: '2'
                  },
                  {
                    Name: '市中区',
                    Code: '3'
                  },
                  {
                    Name: '槐荫区',
                    Code: '4'
                  },
                  {
                    Name: '天桥区',
                    Code: '5'
                  },
                  {
                    Name: '历城区',
                    Code: '12'
                  },
                  {
                    Name: '长清区',
                    Code: '13'
                  },
                  {
                    Name: '平阴县',
                    Code: '24'
                  },
                  {
                    Name: '济阳县',
                    Code: '25'
                  },
                  {
                    Name: '商河县',
                    Code: '26'
                  },
                  {
                    Name: '章丘市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '青岛',
                Code: '2',
                Region: [
                  {
                    Name: '市南区',
                    Code: '2'
                  },
                  {
                    Name: '市北区',
                    Code: '3'
                  },
                  {
                    Name: '四方区',
                    Code: '5'
                  },
                  {
                    Name: '黄岛区',
                    Code: '11'
                  },
                  {
                    Name: '崂山区',
                    Code: '12'
                  },
                  {
                    Name: '李沧区',
                    Code: '13'
                  },
                  {
                    Name: '城阳区',
                    Code: '14'
                  },
                  {
                    Name: '胶州市',
                    Code: '81'
                  },
                  {
                    Name: '即墨市',
                    Code: '82'
                  },
                  {
                    Name: '平度市',
                    Code: '83'
                  },
                  {
                    Name: '胶南市',
                    Code: '84'
                  },
                  {
                    Name: '莱西市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '淄博',
                Code: '3',
                Region: [
                  {
                    Name: '淄川区',
                    Code: '2'
                  },
                  {
                    Name: '张店区',
                    Code: '3'
                  },
                  {
                    Name: '博山区',
                    Code: '4'
                  },
                  {
                    Name: '临淄区',
                    Code: '5'
                  },
                  {
                    Name: '周村区',
                    Code: '6'
                  },
                  {
                    Name: '桓台县',
                    Code: '21'
                  },
                  {
                    Name: '高青县',
                    Code: '22'
                  },
                  {
                    Name: '沂源县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '枣庄',
                Code: '4',
                Region: [
                  {
                    Name: '市中区',
                    Code: '2'
                  },
                  {
                    Name: '薛城区',
                    Code: '3'
                  },
                  {
                    Name: '峄城区',
                    Code: '4'
                  },
                  {
                    Name: '台儿庄区',
                    Code: '5'
                  },
                  {
                    Name: '山亭区',
                    Code: '6'
                  },
                  {
                    Name: '滕州市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '东营',
                Code: '5',
                Region: [
                  {
                    Name: '东营区',
                    Code: '2'
                  },
                  {
                    Name: '河口区',
                    Code: '3'
                  },
                  {
                    Name: '垦利县',
                    Code: '21'
                  },
                  {
                    Name: '利津县',
                    Code: '22'
                  },
                  {
                    Name: '广饶县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '烟台',
                Code: '6',
                Region: [
                  {
                    Name: '芝罘区',
                    Code: '2'
                  },
                  {
                    Name: '福山区',
                    Code: '11'
                  },
                  {
                    Name: '牟平区',
                    Code: '12'
                  },
                  {
                    Name: '莱山区',
                    Code: '13'
                  },
                  {
                    Name: '长岛县',
                    Code: '34'
                  },
                  {
                    Name: '龙口市',
                    Code: '81'
                  },
                  {
                    Name: '莱阳市',
                    Code: '82'
                  },
                  {
                    Name: '莱州市',
                    Code: '83'
                  },
                  {
                    Name: '蓬莱市',
                    Code: '84'
                  },
                  {
                    Name: '招远市',
                    Code: '85'
                  },
                  {
                    Name: '栖霞市',
                    Code: '86'
                  },
                  {
                    Name: '海阳市',
                    Code: '87'
                  }
                ]
              },
              {
                Name: '潍坊',
                Code: '7',
                Region: [
                  {
                    Name: '潍城区',
                    Code: '2'
                  },
                  {
                    Name: '寒亭区',
                    Code: '3'
                  },
                  {
                    Name: '坊子区',
                    Code: '4'
                  },
                  {
                    Name: '奎文区',
                    Code: '5'
                  },
                  {
                    Name: '临朐县',
                    Code: '24'
                  },
                  {
                    Name: '昌乐县',
                    Code: '25'
                  },
                  {
                    Name: '青州市',
                    Code: '81'
                  },
                  {
                    Name: '诸城市',
                    Code: '82'
                  },
                  {
                    Name: '寿光市',
                    Code: '83'
                  },
                  {
                    Name: '安丘市',
                    Code: '84'
                  },
                  {
                    Name: '高密市',
                    Code: '85'
                  },
                  {
                    Name: '昌邑市',
                    Code: '86'
                  }
                ]
              },
              {
                Name: '济宁',
                Code: '8',
                Region: [
                  {
                    Name: '市中区',
                    Code: '2'
                  },
                  {
                    Name: '任城区',
                    Code: '11'
                  },
                  {
                    Name: '微山县',
                    Code: '26'
                  },
                  {
                    Name: '鱼台县',
                    Code: '27'
                  },
                  {
                    Name: '金乡县',
                    Code: '28'
                  },
                  {
                    Name: '嘉祥县',
                    Code: '29'
                  },
                  {
                    Name: '汶上县',
                    Code: '30'
                  },
                  {
                    Name: '泗水县',
                    Code: '31'
                  },
                  {
                    Name: '梁山县',
                    Code: '32'
                  },
                  {
                    Name: '曲阜市',
                    Code: '81'
                  },
                  {
                    Name: '兖州市',
                    Code: '82'
                  },
                  {
                    Name: '邹城市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '泰安',
                Code: '9',
                Region: [
                  {
                    Name: '泰山区',
                    Code: '2'
                  },
                  {
                    Name: '岱岳区',
                    Code: '11'
                  },
                  {
                    Name: '宁阳县',
                    Code: '21'
                  },
                  {
                    Name: '东平县',
                    Code: '23'
                  },
                  {
                    Name: '新泰市',
                    Code: '82'
                  },
                  {
                    Name: '肥城市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '威海',
                Code: '10',
                Region: [
                  {
                    Name: '环翠区',
                    Code: '2'
                  },
                  {
                    Name: '文登市',
                    Code: '81'
                  },
                  {
                    Name: '荣成市',
                    Code: '82'
                  },
                  {
                    Name: '乳山市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '日照',
                Code: '11',
                Region: [
                  {
                    Name: '东港区',
                    Code: '2'
                  },
                  {
                    Name: '岚山区',
                    Code: '3'
                  },
                  {
                    Name: '五莲县',
                    Code: '21'
                  },
                  {
                    Name: '莒县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '莱芜',
                Code: '12',
                Region: [
                  {
                    Name: '莱城区',
                    Code: '2'
                  },
                  {
                    Name: '钢城区',
                    Code: '3'
                  }
                ]
              },
              {
                Name: '临沂',
                Code: '13',
                Region: [
                  {
                    Name: '兰山区',
                    Code: '2'
                  },
                  {
                    Name: '罗庄区',
                    Code: '11'
                  },
                  {
                    Name: '河东区',
                    Code: '12'
                  },
                  {
                    Name: '沂南县',
                    Code: '21'
                  },
                  {
                    Name: '郯城县',
                    Code: '22'
                  },
                  {
                    Name: '沂水县',
                    Code: '23'
                  },
                  {
                    Name: '苍山县',
                    Code: '24'
                  },
                  {
                    Name: '费县',
                    Code: '25'
                  },
                  {
                    Name: '平邑县',
                    Code: '26'
                  },
                  {
                    Name: '莒南县',
                    Code: '27'
                  },
                  {
                    Name: '蒙阴县',
                    Code: '28'
                  },
                  {
                    Name: '临沭县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '德州',
                Code: '14',
                Region: [
                  {
                    Name: '德城区',
                    Code: '2'
                  },
                  {
                    Name: '陵县',
                    Code: '21'
                  },
                  {
                    Name: '宁津县',
                    Code: '22'
                  },
                  {
                    Name: '庆云县',
                    Code: '23'
                  },
                  {
                    Name: '临邑县',
                    Code: '24'
                  },
                  {
                    Name: '齐河县',
                    Code: '25'
                  },
                  {
                    Name: '平原县',
                    Code: '26'
                  },
                  {
                    Name: '夏津县',
                    Code: '27'
                  },
                  {
                    Name: '武城县',
                    Code: '28'
                  },
                  {
                    Name: '乐陵市',
                    Code: '81'
                  },
                  {
                    Name: '禹城市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '聊城',
                Code: '15',
                Region: [
                  {
                    Name: '东昌府区',
                    Code: '2'
                  },
                  {
                    Name: '阳谷县',
                    Code: '21'
                  },
                  {
                    Name: '莘县',
                    Code: '22'
                  },
                  {
                    Name: '茌平县',
                    Code: '23'
                  },
                  {
                    Name: '东阿县',
                    Code: '24'
                  },
                  {
                    Name: '冠县',
                    Code: '25'
                  },
                  {
                    Name: '高唐县',
                    Code: '26'
                  },
                  {
                    Name: '临清市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '滨州',
                Code: '16',
                Region: [
                  {
                    Name: '滨城区',
                    Code: '2'
                  },
                  {
                    Name: '惠民县',
                    Code: '21'
                  },
                  {
                    Name: '阳信县',
                    Code: '22'
                  },
                  {
                    Name: '无棣县',
                    Code: '23'
                  },
                  {
                    Name: '沾化县',
                    Code: '24'
                  },
                  {
                    Name: '博兴县',
                    Code: '25'
                  },
                  {
                    Name: '邹平县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '菏泽',
                Code: '17',
                Region: [
                  {
                    Name: '牡丹区',
                    Code: '2'
                  },
                  {
                    Name: '曹县',
                    Code: '21'
                  },
                  {
                    Name: '单县',
                    Code: '22'
                  },
                  {
                    Name: '成武县',
                    Code: '23'
                  },
                  {
                    Name: '巨野县',
                    Code: '24'
                  },
                  {
                    Name: '郓城县',
                    Code: '25'
                  },
                  {
                    Name: '鄄城县',
                    Code: '26'
                  },
                  {
                    Name: '定陶县',
                    Code: '27'
                  },
                  {
                    Name: '东明县',
                    Code: '28'
                  }
                ]
              }
            ]
          },
          {
            Name: '河南',
            Code: '41',
            City: [
              {
                Name: '郑州',
                Code: '1',
                Region: [
                  {
                    Name: '中原区',
                    Code: '2'
                  },
                  {
                    Name: '二七区',
                    Code: '3'
                  },
                  {
                    Name: '管城回族区',
                    Code: '4'
                  },
                  {
                    Name: '金水区',
                    Code: '5'
                  },
                  {
                    Name: '上街区',
                    Code: '6'
                  },
                  {
                    Name: '惠济区',
                    Code: '8'
                  },
                  {
                    Name: '中牟县',
                    Code: '22'
                  },
                  {
                    Name: '巩义市',
                    Code: '81'
                  },
                  {
                    Name: '荥阳市',
                    Code: '82'
                  },
                  {
                    Name: '新密市',
                    Code: '83'
                  },
                  {
                    Name: '新郑市',
                    Code: '84'
                  },
                  {
                    Name: '登封市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '开封',
                Code: '2',
                Region: [
                  {
                    Name: '龙亭区',
                    Code: '2'
                  },
                  {
                    Name: '顺河回族区',
                    Code: '3'
                  },
                  {
                    Name: '鼓楼区',
                    Code: '4'
                  },
                  {
                    Name: '禹王台区',
                    Code: '5'
                  },
                  {
                    Name: '金明区',
                    Code: '11'
                  },
                  {
                    Name: '杞县',
                    Code: '21'
                  },
                  {
                    Name: '通许县',
                    Code: '22'
                  },
                  {
                    Name: '尉氏县',
                    Code: '23'
                  },
                  {
                    Name: '开封县',
                    Code: '24'
                  },
                  {
                    Name: '兰考县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '洛阳',
                Code: '3',
                Region: [
                  {
                    Name: '老城区',
                    Code: '2'
                  },
                  {
                    Name: '西工区',
                    Code: '3'
                  },
                  {
                    Name: '瀍河回族区',
                    Code: '4'
                  },
                  {
                    Name: '涧西区',
                    Code: '5'
                  },
                  {
                    Name: '吉利区',
                    Code: '6'
                  },
                  {
                    Name: '洛龙区',
                    Code: '11'
                  },
                  {
                    Name: '孟津县',
                    Code: '22'
                  },
                  {
                    Name: '新安县',
                    Code: '23'
                  },
                  {
                    Name: '栾川县',
                    Code: '24'
                  },
                  {
                    Name: '嵩县',
                    Code: '25'
                  },
                  {
                    Name: '汝阳县',
                    Code: '26'
                  },
                  {
                    Name: '宜阳县',
                    Code: '27'
                  },
                  {
                    Name: '洛宁县',
                    Code: '28'
                  },
                  {
                    Name: '伊川县',
                    Code: '29'
                  },
                  {
                    Name: '偃师市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '平顶山',
                Code: '4',
                Region: [
                  {
                    Name: '新华区',
                    Code: '2'
                  },
                  {
                    Name: '卫东区',
                    Code: '3'
                  },
                  {
                    Name: '石龙区',
                    Code: '4'
                  },
                  {
                    Name: '湛河区',
                    Code: '11'
                  },
                  {
                    Name: '宝丰县',
                    Code: '21'
                  },
                  {
                    Name: '叶县',
                    Code: '22'
                  },
                  {
                    Name: '鲁山县',
                    Code: '23'
                  },
                  {
                    Name: '郏县',
                    Code: '25'
                  },
                  {
                    Name: '舞钢市',
                    Code: '81'
                  },
                  {
                    Name: '汝州市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '安阳',
                Code: '5',
                Region: [
                  {
                    Name: '文峰区',
                    Code: '2'
                  },
                  {
                    Name: '北关区',
                    Code: '3'
                  },
                  {
                    Name: '殷都区',
                    Code: '5'
                  },
                  {
                    Name: '龙安区',
                    Code: '6'
                  },
                  {
                    Name: '安阳县',
                    Code: '22'
                  },
                  {
                    Name: '汤阴县',
                    Code: '23'
                  },
                  {
                    Name: '滑县',
                    Code: '26'
                  },
                  {
                    Name: '内黄县',
                    Code: '27'
                  },
                  {
                    Name: '林州市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '鹤壁',
                Code: '6',
                Region: [
                  {
                    Name: '鹤山区',
                    Code: '2'
                  },
                  {
                    Name: '山城区',
                    Code: '3'
                  },
                  {
                    Name: '淇滨区',
                    Code: '11'
                  },
                  {
                    Name: '浚县',
                    Code: '21'
                  },
                  {
                    Name: '淇县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '新乡',
                Code: '7',
                Region: [
                  {
                    Name: '红旗区',
                    Code: '2'
                  },
                  {
                    Name: '卫滨区',
                    Code: '3'
                  },
                  {
                    Name: '凤泉区',
                    Code: '4'
                  },
                  {
                    Name: '牧野区',
                    Code: '11'
                  },
                  {
                    Name: '新乡县',
                    Code: '21'
                  },
                  {
                    Name: '获嘉县',
                    Code: '24'
                  },
                  {
                    Name: '原阳县',
                    Code: '25'
                  },
                  {
                    Name: '延津县',
                    Code: '26'
                  },
                  {
                    Name: '封丘县',
                    Code: '27'
                  },
                  {
                    Name: '长垣县',
                    Code: '28'
                  },
                  {
                    Name: '卫辉市',
                    Code: '81'
                  },
                  {
                    Name: '辉县市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '焦作',
                Code: '8',
                Region: [
                  {
                    Name: '解放区',
                    Code: '2'
                  },
                  {
                    Name: '中站区',
                    Code: '3'
                  },
                  {
                    Name: '马村区',
                    Code: '4'
                  },
                  {
                    Name: '山阳区',
                    Code: '11'
                  },
                  {
                    Name: '修武县',
                    Code: '21'
                  },
                  {
                    Name: '博爱县',
                    Code: '22'
                  },
                  {
                    Name: '武陟县',
                    Code: '23'
                  },
                  {
                    Name: '温县',
                    Code: '25'
                  },
                  {
                    Name: '沁阳市',
                    Code: '82'
                  },
                  {
                    Name: '孟州市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '濮阳',
                Code: '9',
                Region: [
                  {
                    Name: '华龙区',
                    Code: '2'
                  },
                  {
                    Name: '清丰县',
                    Code: '22'
                  },
                  {
                    Name: '南乐县',
                    Code: '23'
                  },
                  {
                    Name: '范县',
                    Code: '26'
                  },
                  {
                    Name: '台前县',
                    Code: '27'
                  },
                  {
                    Name: '濮阳县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '许昌',
                Code: '10',
                Region: [
                  {
                    Name: '魏都区',
                    Code: '2'
                  },
                  {
                    Name: '许昌县',
                    Code: '23'
                  },
                  {
                    Name: '鄢陵县',
                    Code: '24'
                  },
                  {
                    Name: '襄城县',
                    Code: '25'
                  },
                  {
                    Name: '禹州市',
                    Code: '81'
                  },
                  {
                    Name: '长葛市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '漯河',
                Code: '11',
                Region: [
                  {
                    Name: '源汇区',
                    Code: '2'
                  },
                  {
                    Name: '郾城区',
                    Code: '3'
                  },
                  {
                    Name: '召陵区',
                    Code: '4'
                  },
                  {
                    Name: '舞阳县',
                    Code: '21'
                  },
                  {
                    Name: '临颍县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '三门峡',
                Code: '12',
                Region: [
                  {
                    Name: '湖滨区',
                    Code: '2'
                  },
                  {
                    Name: '渑池县',
                    Code: '21'
                  },
                  {
                    Name: '陕县',
                    Code: '22'
                  },
                  {
                    Name: '卢氏县',
                    Code: '24'
                  },
                  {
                    Name: '义马市',
                    Code: '81'
                  },
                  {
                    Name: '灵宝市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '南阳',
                Code: '13',
                Region: [
                  {
                    Name: '宛城区',
                    Code: '2'
                  },
                  {
                    Name: '卧龙区',
                    Code: '3'
                  },
                  {
                    Name: '南召县',
                    Code: '21'
                  },
                  {
                    Name: '方城县',
                    Code: '22'
                  },
                  {
                    Name: '西峡县',
                    Code: '23'
                  },
                  {
                    Name: '镇平县',
                    Code: '24'
                  },
                  {
                    Name: '内乡县',
                    Code: '25'
                  },
                  {
                    Name: '淅川县',
                    Code: '26'
                  },
                  {
                    Name: '社旗县',
                    Code: '27'
                  },
                  {
                    Name: '唐河县',
                    Code: '28'
                  },
                  {
                    Name: '新野县',
                    Code: '29'
                  },
                  {
                    Name: '桐柏县',
                    Code: '30'
                  },
                  {
                    Name: '邓州市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '商丘',
                Code: '14',
                Region: [
                  {
                    Name: '梁园区',
                    Code: '2'
                  },
                  {
                    Name: '睢阳区',
                    Code: '3'
                  },
                  {
                    Name: '民权县',
                    Code: '21'
                  },
                  {
                    Name: '睢县',
                    Code: '22'
                  },
                  {
                    Name: '宁陵县',
                    Code: '23'
                  },
                  {
                    Name: '柘城县',
                    Code: '24'
                  },
                  {
                    Name: '虞城县',
                    Code: '25'
                  },
                  {
                    Name: '夏邑县',
                    Code: '26'
                  },
                  {
                    Name: '永城市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '信阳',
                Code: '15',
                Region: [
                  {
                    Name: '浉河区',
                    Code: '2'
                  },
                  {
                    Name: '平桥区',
                    Code: '3'
                  },
                  {
                    Name: '罗山县',
                    Code: '21'
                  },
                  {
                    Name: '光山县',
                    Code: '22'
                  },
                  {
                    Name: '新县',
                    Code: '23'
                  },
                  {
                    Name: '商城县',
                    Code: '24'
                  },
                  {
                    Name: '固始县',
                    Code: '25'
                  },
                  {
                    Name: '潢川县',
                    Code: '26'
                  },
                  {
                    Name: '淮滨县',
                    Code: '27'
                  },
                  {
                    Name: '息县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '周口',
                Code: '16',
                Region: [
                  {
                    Name: '川汇区',
                    Code: '2'
                  },
                  {
                    Name: '扶沟县',
                    Code: '21'
                  },
                  {
                    Name: '西华县',
                    Code: '22'
                  },
                  {
                    Name: '商水县',
                    Code: '23'
                  },
                  {
                    Name: '沈丘县',
                    Code: '24'
                  },
                  {
                    Name: '郸城县',
                    Code: '25'
                  },
                  {
                    Name: '淮阳县',
                    Code: '26'
                  },
                  {
                    Name: '太康县',
                    Code: '27'
                  },
                  {
                    Name: '鹿邑县',
                    Code: '28'
                  },
                  {
                    Name: '项城市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '驻马店',
                Code: '17',
                Region: [
                  {
                    Name: '驿城区',
                    Code: '2'
                  },
                  {
                    Name: '西平县',
                    Code: '21'
                  },
                  {
                    Name: '上蔡县',
                    Code: '22'
                  },
                  {
                    Name: '平舆县',
                    Code: '23'
                  },
                  {
                    Name: '正阳县',
                    Code: '24'
                  },
                  {
                    Name: '确山县',
                    Code: '25'
                  },
                  {
                    Name: '泌阳县',
                    Code: '26'
                  },
                  {
                    Name: '汝南县',
                    Code: '27'
                  },
                  {
                    Name: '遂平县',
                    Code: '28'
                  },
                  {
                    Name: '新蔡县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '济源',
                Code: '18'
              }
            ]
          },
          {
            Name: '湖北',
            Code: '42',
            City: [
              {
                Name: '武汉',
                Code: '1',
                Region: [
                  {
                    Name: '江岸区',
                    Code: '2'
                  },
                  {
                    Name: '江汉区',
                    Code: '3'
                  },
                  {
                    Name: '硚口区',
                    Code: '4'
                  },
                  {
                    Name: '汉阳区',
                    Code: '5'
                  },
                  {
                    Name: '武昌区',
                    Code: '6'
                  },
                  {
                    Name: '青山区',
                    Code: '7'
                  },
                  {
                    Name: '洪山区',
                    Code: '11'
                  },
                  {
                    Name: '东西湖区',
                    Code: '12'
                  },
                  {
                    Name: '汉南区',
                    Code: '13'
                  },
                  {
                    Name: '蔡甸区',
                    Code: '14'
                  },
                  {
                    Name: '江夏区',
                    Code: '15'
                  },
                  {
                    Name: '黄陂区',
                    Code: '16'
                  },
                  {
                    Name: '新洲区',
                    Code: '17'
                  }
                ]
              },
              {
                Name: '黄石',
                Code: '2',
                Region: [
                  {
                    Name: '黄石港区',
                    Code: '2'
                  },
                  {
                    Name: '西塞山区',
                    Code: '3'
                  },
                  {
                    Name: '下陆区',
                    Code: '4'
                  },
                  {
                    Name: '铁山区',
                    Code: '5'
                  },
                  {
                    Name: '阳新县',
                    Code: '22'
                  },
                  {
                    Name: '大冶市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '十堰',
                Code: '3',
                Region: [
                  {
                    Name: '茅箭区',
                    Code: '2'
                  },
                  {
                    Name: '张湾区',
                    Code: '3'
                  },
                  {
                    Name: '郧县',
                    Code: '21'
                  },
                  {
                    Name: '郧西县',
                    Code: '22'
                  },
                  {
                    Name: '竹山县',
                    Code: '23'
                  },
                  {
                    Name: '竹溪县',
                    Code: '24'
                  },
                  {
                    Name: '房县',
                    Code: '25'
                  },
                  {
                    Name: '丹江口市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '宜昌',
                Code: '5',
                Region: [
                  {
                    Name: '西陵区',
                    Code: '2'
                  },
                  {
                    Name: '伍家岗区',
                    Code: '3'
                  },
                  {
                    Name: '点军区',
                    Code: '4'
                  },
                  {
                    Name: '猇亭区',
                    Code: '5'
                  },
                  {
                    Name: '夷陵区',
                    Code: '6'
                  },
                  {
                    Name: '远安县',
                    Code: '25'
                  },
                  {
                    Name: '兴山县',
                    Code: '26'
                  },
                  {
                    Name: '秭归县',
                    Code: '27'
                  },
                  {
                    Name: '长阳土家族自治县',
                    Code: '28'
                  },
                  {
                    Name: '五峰土家族自治县',
                    Code: '29'
                  },
                  {
                    Name: '宜都市',
                    Code: '81'
                  },
                  {
                    Name: '当阳市',
                    Code: '82'
                  },
                  {
                    Name: '枝江市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '襄阳',
                Code: '6',
                Region: [
                  {
                    Name: '襄城区',
                    Code: '2'
                  },
                  {
                    Name: '樊城区',
                    Code: '6'
                  },
                  {
                    Name: '襄州区',
                    Code: '7'
                  },
                  {
                    Name: '南漳县',
                    Code: '24'
                  },
                  {
                    Name: '谷城县',
                    Code: '25'
                  },
                  {
                    Name: '保康县',
                    Code: '26'
                  },
                  {
                    Name: '老河口市',
                    Code: '82'
                  },
                  {
                    Name: '枣阳市',
                    Code: '83'
                  },
                  {
                    Name: '宜城市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '鄂州',
                Code: '7',
                Region: [
                  {
                    Name: '梁子湖区',
                    Code: '2'
                  },
                  {
                    Name: '华容区',
                    Code: '3'
                  },
                  {
                    Name: '鄂城区',
                    Code: '4'
                  }
                ]
              },
              {
                Name: '荆门',
                Code: '8',
                Region: [
                  {
                    Name: '东宝区',
                    Code: '2'
                  },
                  {
                    Name: '掇刀区',
                    Code: '4'
                  },
                  {
                    Name: '京山县',
                    Code: '21'
                  },
                  {
                    Name: '沙洋县',
                    Code: '22'
                  },
                  {
                    Name: '钟祥市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '孝感',
                Code: '9',
                Region: [
                  {
                    Name: '孝南区',
                    Code: '2'
                  },
                  {
                    Name: '孝昌县',
                    Code: '21'
                  },
                  {
                    Name: '大悟县',
                    Code: '22'
                  },
                  {
                    Name: '云梦县',
                    Code: '23'
                  },
                  {
                    Name: '应城市',
                    Code: '81'
                  },
                  {
                    Name: '安陆市',
                    Code: '82'
                  },
                  {
                    Name: '汉川市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '荆州',
                Code: '10',
                Region: [
                  {
                    Name: '沙市区',
                    Code: '2'
                  },
                  {
                    Name: '荆州区',
                    Code: '3'
                  },
                  {
                    Name: '公安县',
                    Code: '22'
                  },
                  {
                    Name: '监利县',
                    Code: '23'
                  },
                  {
                    Name: '江陵县',
                    Code: '24'
                  },
                  {
                    Name: '石首市',
                    Code: '81'
                  },
                  {
                    Name: '洪湖市',
                    Code: '83'
                  },
                  {
                    Name: '松滋市',
                    Code: '87'
                  }
                ]
              },
              {
                Name: '黄冈',
                Code: '11',
                Region: [
                  {
                    Name: '黄州区',
                    Code: '2'
                  },
                  {
                    Name: '团风县',
                    Code: '21'
                  },
                  {
                    Name: '红安县',
                    Code: '22'
                  },
                  {
                    Name: '罗田县',
                    Code: '23'
                  },
                  {
                    Name: '英山县',
                    Code: '24'
                  },
                  {
                    Name: '浠水县',
                    Code: '25'
                  },
                  {
                    Name: '蕲春县',
                    Code: '26'
                  },
                  {
                    Name: '黄梅县',
                    Code: '27'
                  },
                  {
                    Name: '麻城市',
                    Code: '81'
                  },
                  {
                    Name: '武穴市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '咸宁',
                Code: '12',
                Region: [
                  {
                    Name: '咸安区',
                    Code: '2'
                  },
                  {
                    Name: '嘉鱼县',
                    Code: '21'
                  },
                  {
                    Name: '通城县',
                    Code: '22'
                  },
                  {
                    Name: '崇阳县',
                    Code: '23'
                  },
                  {
                    Name: '通山县',
                    Code: '24'
                  },
                  {
                    Name: '赤壁市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '随州',
                Code: '13',
                Region: [
                  {
                    Name: '曾都区',
                    Code: '2'
                  },
                  {
                    Name: '随县',
                    Code: '21'
                  },
                  {
                    Name: '广水市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '恩施',
                Code: '28',
                Region: [
                  {
                    Name: '恩施市',
                    Code: '1'
                  },
                  {
                    Name: '利川市',
                    Code: '2'
                  },
                  {
                    Name: '建始县',
                    Code: '22'
                  },
                  {
                    Name: '巴东县',
                    Code: '23'
                  },
                  {
                    Name: '宣恩县',
                    Code: '25'
                  },
                  {
                    Name: '咸丰县',
                    Code: '26'
                  },
                  {
                    Name: '来凤县',
                    Code: '27'
                  },
                  {
                    Name: '鹤峰县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '仙桃',
                Code: '94'
              },
              {
                Name: '潜江',
                Code: '95'
              },
              {
                Name: '天门',
                Code: '96'
              },
              {
                Name: '神农架',
                Code: 'A21'
              }
            ]
          },
          {
            Name: '湖南',
            Code: '43',
            City: [
              {
                Name: '长沙',
                Code: '1',
                Region: [
                  {
                    Name: '芙蓉区',
                    Code: '2'
                  },
                  {
                    Name: '天心区',
                    Code: '3'
                  },
                  {
                    Name: '岳麓区',
                    Code: '4'
                  },
                  {
                    Name: '开福区',
                    Code: '5'
                  },
                  {
                    Name: '雨花区',
                    Code: '11'
                  },
                  {
                    Name: '长沙县',
                    Code: '21'
                  },
                  {
                    Name: '望城区',
                    Code: '22'
                  },
                  {
                    Name: '宁乡县',
                    Code: '24'
                  },
                  {
                    Name: '浏阳市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '株洲',
                Code: '2',
                Region: [
                  {
                    Name: '荷塘区',
                    Code: '2'
                  },
                  {
                    Name: '芦淞区',
                    Code: '3'
                  },
                  {
                    Name: '石峰区',
                    Code: '4'
                  },
                  {
                    Name: '天元区',
                    Code: '11'
                  },
                  {
                    Name: '株洲县',
                    Code: '21'
                  },
                  {
                    Name: '攸县',
                    Code: '23'
                  },
                  {
                    Name: '茶陵县',
                    Code: '24'
                  },
                  {
                    Name: '炎陵县',
                    Code: '25'
                  },
                  {
                    Name: '醴陵市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '湘潭',
                Code: '3',
                Region: [
                  {
                    Name: '雨湖区',
                    Code: '2'
                  },
                  {
                    Name: '岳塘区',
                    Code: '4'
                  },
                  {
                    Name: '湘潭县',
                    Code: '21'
                  },
                  {
                    Name: '湘乡市',
                    Code: '81'
                  },
                  {
                    Name: '韶山市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '衡阳',
                Code: '4',
                Region: [
                  {
                    Name: '珠晖区',
                    Code: '5'
                  },
                  {
                    Name: '雁峰区',
                    Code: '6'
                  },
                  {
                    Name: '石鼓区',
                    Code: '7'
                  },
                  {
                    Name: '蒸湘区',
                    Code: '8'
                  },
                  {
                    Name: '南岳区',
                    Code: '12'
                  },
                  {
                    Name: '衡阳县',
                    Code: '21'
                  },
                  {
                    Name: '衡南县',
                    Code: '22'
                  },
                  {
                    Name: '衡山县',
                    Code: '23'
                  },
                  {
                    Name: '衡东县',
                    Code: '24'
                  },
                  {
                    Name: '祁东县',
                    Code: '26'
                  },
                  {
                    Name: '耒阳市',
                    Code: '81'
                  },
                  {
                    Name: '常宁市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '邵阳',
                Code: '5',
                Region: [
                  {
                    Name: '双清区',
                    Code: '2'
                  },
                  {
                    Name: '大祥区',
                    Code: '3'
                  },
                  {
                    Name: '北塔区',
                    Code: '11'
                  },
                  {
                    Name: '邵东县',
                    Code: '21'
                  },
                  {
                    Name: '新邵县',
                    Code: '22'
                  },
                  {
                    Name: '邵阳县',
                    Code: '23'
                  },
                  {
                    Name: '隆回县',
                    Code: '24'
                  },
                  {
                    Name: '洞口县',
                    Code: '25'
                  },
                  {
                    Name: '绥宁县',
                    Code: '27'
                  },
                  {
                    Name: '新宁县',
                    Code: '28'
                  },
                  {
                    Name: '城步苗族自治县',
                    Code: '29'
                  },
                  {
                    Name: '武冈市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '岳阳',
                Code: '6',
                Region: [
                  {
                    Name: '岳阳楼区',
                    Code: '2'
                  },
                  {
                    Name: '云溪区',
                    Code: '3'
                  },
                  {
                    Name: '君山区',
                    Code: '11'
                  },
                  {
                    Name: '岳阳县',
                    Code: '21'
                  },
                  {
                    Name: '华容县',
                    Code: '23'
                  },
                  {
                    Name: '湘阴县',
                    Code: '24'
                  },
                  {
                    Name: '平江县',
                    Code: '26'
                  },
                  {
                    Name: '汨罗市',
                    Code: '81'
                  },
                  {
                    Name: '临湘市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '常德',
                Code: '7',
                Region: [
                  {
                    Name: '武陵区',
                    Code: '2'
                  },
                  {
                    Name: '鼎城区',
                    Code: '3'
                  },
                  {
                    Name: '安乡县',
                    Code: '21'
                  },
                  {
                    Name: '汉寿县',
                    Code: '22'
                  },
                  {
                    Name: '澧县',
                    Code: '23'
                  },
                  {
                    Name: '临澧县',
                    Code: '24'
                  },
                  {
                    Name: '桃源县',
                    Code: '25'
                  },
                  {
                    Name: '石门县',
                    Code: '26'
                  },
                  {
                    Name: '津市市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '张家界',
                Code: '8',
                Region: [
                  {
                    Name: '永定区',
                    Code: '2'
                  },
                  {
                    Name: '武陵源区',
                    Code: '11'
                  },
                  {
                    Name: '慈利县',
                    Code: '21'
                  },
                  {
                    Name: '桑植县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '益阳',
                Code: '9',
                Region: [
                  {
                    Name: '资阳区',
                    Code: '2'
                  },
                  {
                    Name: '赫山区',
                    Code: '3'
                  },
                  {
                    Name: '南县',
                    Code: '21'
                  },
                  {
                    Name: '桃江县',
                    Code: '22'
                  },
                  {
                    Name: '安化县',
                    Code: '23'
                  },
                  {
                    Name: '沅江市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '郴州',
                Code: '10',
                Region: [
                  {
                    Name: '北湖区',
                    Code: '2'
                  },
                  {
                    Name: '苏仙区',
                    Code: '3'
                  },
                  {
                    Name: '桂阳县',
                    Code: '21'
                  },
                  {
                    Name: '宜章县',
                    Code: '22'
                  },
                  {
                    Name: '永兴县',
                    Code: '23'
                  },
                  {
                    Name: '嘉禾县',
                    Code: '24'
                  },
                  {
                    Name: '临武县',
                    Code: '25'
                  },
                  {
                    Name: '汝城县',
                    Code: '26'
                  },
                  {
                    Name: '桂东县',
                    Code: '27'
                  },
                  {
                    Name: '安仁县',
                    Code: '28'
                  },
                  {
                    Name: '资兴市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '永州',
                Code: '11',
                Region: [
                  {
                    Name: '零陵区',
                    Code: '2'
                  },
                  {
                    Name: '冷水滩区',
                    Code: '3'
                  },
                  {
                    Name: '祁阳县',
                    Code: '21'
                  },
                  {
                    Name: '东安县',
                    Code: '22'
                  },
                  {
                    Name: '双牌县',
                    Code: '23'
                  },
                  {
                    Name: '道县',
                    Code: '24'
                  },
                  {
                    Name: '江永县',
                    Code: '25'
                  },
                  {
                    Name: '宁远县',
                    Code: '26'
                  },
                  {
                    Name: '蓝山县',
                    Code: '27'
                  },
                  {
                    Name: '新田县',
                    Code: '28'
                  },
                  {
                    Name: '江华瑶族自治县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '怀化',
                Code: '12',
                Region: [
                  {
                    Name: '鹤城区',
                    Code: '2'
                  },
                  {
                    Name: '中方县',
                    Code: '21'
                  },
                  {
                    Name: '沅陵县',
                    Code: '22'
                  },
                  {
                    Name: '辰溪县',
                    Code: '23'
                  },
                  {
                    Name: '溆浦县',
                    Code: '24'
                  },
                  {
                    Name: '会同县',
                    Code: '25'
                  },
                  {
                    Name: '麻阳苗族自治县',
                    Code: '26'
                  },
                  {
                    Name: '新晃侗族自治县',
                    Code: '27'
                  },
                  {
                    Name: '芷江侗族自治县',
                    Code: '28'
                  },
                  {
                    Name: '靖州苗族侗族自治县',
                    Code: '29'
                  },
                  {
                    Name: '通道侗族自治县',
                    Code: '30'
                  },
                  {
                    Name: '洪江市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '娄底',
                Code: '13',
                Region: [
                  {
                    Name: '娄星区',
                    Code: '2'
                  },
                  {
                    Name: '双峰县',
                    Code: '21'
                  },
                  {
                    Name: '新化县',
                    Code: '22'
                  },
                  {
                    Name: '冷水江市',
                    Code: '81'
                  },
                  {
                    Name: '涟源市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '湘西',
                Code: '31',
                Region: [
                  {
                    Name: '吉首市',
                    Code: '1'
                  },
                  {
                    Name: '泸溪县',
                    Code: '22'
                  },
                  {
                    Name: '凤凰县',
                    Code: '23'
                  },
                  {
                    Name: '花垣县',
                    Code: '24'
                  },
                  {
                    Name: '保靖县',
                    Code: '25'
                  },
                  {
                    Name: '古丈县',
                    Code: '26'
                  },
                  {
                    Name: '永顺县',
                    Code: '27'
                  },
                  {
                    Name: '龙山县',
                    Code: '30'
                  }
                ]
              }
            ]
          },
          {
            Name: '广东',
            Code: '44',
            City: [
              {
                Name: '广州',
                Code: '1',
                Region: [
                  {
                    Name: '荔湾区',
                    Code: '3'
                  },
                  {
                    Name: '越秀区',
                    Code: '4'
                  },
                  {
                    Name: '海珠区',
                    Code: '5'
                  },
                  {
                    Name: '天河区',
                    Code: '6'
                  },
                  {
                    Name: '白云区',
                    Code: '11'
                  },
                  {
                    Name: '黄埔区',
                    Code: '12'
                  },
                  {
                    Name: '番禺区',
                    Code: '13'
                  },
                  {
                    Name: '花都区',
                    Code: '14'
                  },
                  {
                    Name: '南沙区',
                    Code: '15'
                  },
                  {
                    Name: '萝岗区',
                    Code: '16'
                  },
                  {
                    Name: '增城市',
                    Code: '83'
                  },
                  {
                    Name: '从化市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '韶关',
                Code: '2',
                Region: [
                  {
                    Name: '武江区',
                    Code: '3'
                  },
                  {
                    Name: '浈江区',
                    Code: '4'
                  },
                  {
                    Name: '曲江区',
                    Code: '5'
                  },
                  {
                    Name: '始兴县',
                    Code: '22'
                  },
                  {
                    Name: '仁化县',
                    Code: '24'
                  },
                  {
                    Name: '翁源县',
                    Code: '29'
                  },
                  {
                    Name: '乳源瑶族自治县',
                    Code: '32'
                  },
                  {
                    Name: '新丰县',
                    Code: '33'
                  },
                  {
                    Name: '乐昌市',
                    Code: '81'
                  },
                  {
                    Name: '南雄市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '深圳',
                Code: '3',
                Region: [
                  {
                    Name: '罗湖区',
                    Code: '3'
                  },
                  {
                    Name: '福田区',
                    Code: '4'
                  },
                  {
                    Name: '南山区',
                    Code: '5'
                  },
                  {
                    Name: '宝安区',
                    Code: '6'
                  },
                  {
                    Name: '龙岗区',
                    Code: '7'
                  },
                  {
                    Name: '盐田区',
                    Code: '8'
                  }
                ]
              },
              {
                Name: '珠海',
                Code: '4',
                Region: [
                  {
                    Name: '香洲区',
                    Code: '2'
                  },
                  {
                    Name: '斗门区',
                    Code: '3'
                  },
                  {
                    Name: '金湾区',
                    Code: '4'
                  }
                ]
              },
              {
                Name: '汕头',
                Code: '5',
                Region: [
                  {
                    Name: '龙湖区',
                    Code: '7'
                  },
                  {
                    Name: '金平区',
                    Code: '11'
                  },
                  {
                    Name: '濠江区',
                    Code: '12'
                  },
                  {
                    Name: '潮阳区',
                    Code: '13'
                  },
                  {
                    Name: '潮南区',
                    Code: '14'
                  },
                  {
                    Name: '澄海区',
                    Code: '15'
                  },
                  {
                    Name: '南澳县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '佛山',
                Code: '6',
                Region: [
                  {
                    Name: '禅城区',
                    Code: '4'
                  },
                  {
                    Name: '南海区',
                    Code: '5'
                  },
                  {
                    Name: '顺德区',
                    Code: '6'
                  },
                  {
                    Name: '三水区',
                    Code: '7'
                  },
                  {
                    Name: '高明区',
                    Code: '8'
                  }
                ]
              },
              {
                Name: '江门',
                Code: '7',
                Region: [
                  {
                    Name: '蓬江区',
                    Code: '3'
                  },
                  {
                    Name: '江海区',
                    Code: '4'
                  },
                  {
                    Name: '新会区',
                    Code: '5'
                  },
                  {
                    Name: '台山市',
                    Code: '81'
                  },
                  {
                    Name: '开平市',
                    Code: '83'
                  },
                  {
                    Name: '鹤山市',
                    Code: '84'
                  },
                  {
                    Name: '恩平市',
                    Code: '85'
                  }
                ]
              },
              {
                Name: '湛江',
                Code: '8',
                Region: [
                  {
                    Name: '赤坎区',
                    Code: '2'
                  },
                  {
                    Name: '霞山区',
                    Code: '3'
                  },
                  {
                    Name: '坡头区',
                    Code: '4'
                  },
                  {
                    Name: '麻章区',
                    Code: '11'
                  },
                  {
                    Name: '遂溪县',
                    Code: '23'
                  },
                  {
                    Name: '徐闻县',
                    Code: '25'
                  },
                  {
                    Name: '廉江市',
                    Code: '81'
                  },
                  {
                    Name: '雷州市',
                    Code: '82'
                  },
                  {
                    Name: '吴川市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '茂名',
                Code: '9',
                Region: [
                  {
                    Name: '茂南区',
                    Code: '2'
                  },
                  {
                    Name: '茂港区',
                    Code: '3'
                  },
                  {
                    Name: '电白县',
                    Code: '23'
                  },
                  {
                    Name: '高州市',
                    Code: '81'
                  },
                  {
                    Name: '化州市',
                    Code: '82'
                  },
                  {
                    Name: '信宜市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '肇庆',
                Code: '12',
                Region: [
                  {
                    Name: '端州区',
                    Code: '2'
                  },
                  {
                    Name: '鼎湖区',
                    Code: '3'
                  },
                  {
                    Name: '广宁县',
                    Code: '23'
                  },
                  {
                    Name: '怀集县',
                    Code: '24'
                  },
                  {
                    Name: '封开县',
                    Code: '25'
                  },
                  {
                    Name: '德庆县',
                    Code: '26'
                  },
                  {
                    Name: '高要市',
                    Code: '83'
                  },
                  {
                    Name: '四会市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '惠州',
                Code: '13',
                Region: [
                  {
                    Name: '惠城区',
                    Code: '2'
                  },
                  {
                    Name: '惠阳区',
                    Code: '3'
                  },
                  {
                    Name: '博罗县',
                    Code: '22'
                  },
                  {
                    Name: '惠东县',
                    Code: '23'
                  },
                  {
                    Name: '龙门县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '梅州',
                Code: '14',
                Region: [
                  {
                    Name: '梅江区',
                    Code: '2'
                  },
                  {
                    Name: '梅县',
                    Code: '21'
                  },
                  {
                    Name: '大埔县',
                    Code: '22'
                  },
                  {
                    Name: '丰顺县',
                    Code: '23'
                  },
                  {
                    Name: '五华县',
                    Code: '24'
                  },
                  {
                    Name: '平远县',
                    Code: '26'
                  },
                  {
                    Name: '蕉岭县',
                    Code: '27'
                  },
                  {
                    Name: '兴宁市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '汕尾',
                Code: '15',
                Region: [
                  {
                    Name: '城区',
                    Code: '2'
                  },
                  {
                    Name: '海丰县',
                    Code: '21'
                  },
                  {
                    Name: '陆河县',
                    Code: '23'
                  },
                  {
                    Name: '陆丰市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '河源',
                Code: '16',
                Region: [
                  {
                    Name: '源城区',
                    Code: '2'
                  },
                  {
                    Name: '紫金县',
                    Code: '21'
                  },
                  {
                    Name: '龙川县',
                    Code: '22'
                  },
                  {
                    Name: '连平县',
                    Code: '23'
                  },
                  {
                    Name: '和平县',
                    Code: '24'
                  },
                  {
                    Name: '东源县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '阳江',
                Code: '17',
                Region: [
                  {
                    Name: '江城区',
                    Code: '2'
                  },
                  {
                    Name: '阳西县',
                    Code: '21'
                  },
                  {
                    Name: '阳东县',
                    Code: '23'
                  },
                  {
                    Name: '阳春市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '清远',
                Code: '18',
                Region: [
                  {
                    Name: '清城区',
                    Code: '2'
                  },
                  {
                    Name: '佛冈县',
                    Code: '21'
                  },
                  {
                    Name: '阳山县',
                    Code: '23'
                  },
                  {
                    Name: '连山壮族瑶族自治县',
                    Code: '25'
                  },
                  {
                    Name: '连南瑶族自治县',
                    Code: '26'
                  },
                  {
                    Name: '清新县',
                    Code: '27'
                  },
                  {
                    Name: '英德市',
                    Code: '81'
                  },
                  {
                    Name: '连州市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '东莞',
                Code: '19',
                Region: {
                  Name: '东莞市',
                  Code: '0'
                }
              },
              {
                Name: '中山',
                Code: '20',
                Region: {
                  Name: '中山市',
                  Code: '0'
                }
              },
              {
                Name: '潮州',
                Code: '51',
                Region: [
                  {
                    Name: '湘桥区',
                    Code: '2'
                  },
                  {
                    Name: '潮安县',
                    Code: '21'
                  },
                  {
                    Name: '饶平县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '揭阳',
                Code: '52',
                Region: [
                  {
                    Name: '榕城区',
                    Code: '2'
                  },
                  {
                    Name: '揭东县',
                    Code: '21'
                  },
                  {
                    Name: '揭西县',
                    Code: '22'
                  },
                  {
                    Name: '惠来县',
                    Code: '24'
                  },
                  {
                    Name: '普宁市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '云浮',
                Code: '53',
                Region: [
                  {
                    Name: '云城区',
                    Code: '2'
                  },
                  {
                    Name: '新兴县',
                    Code: '21'
                  },
                  {
                    Name: '郁南县',
                    Code: '22'
                  },
                  {
                    Name: '云安县',
                    Code: '23'
                  },
                  {
                    Name: '罗定市',
                    Code: '81'
                  }
                ]
              }
            ]
          },
          {
            Name: '广西',
            Code: '45',
            City: [
              {
                Name: '南宁',
                Code: '1',
                Region: [
                  {
                    Name: '兴宁区',
                    Code: '2'
                  },
                  {
                    Name: '青秀区',
                    Code: '3'
                  },
                  {
                    Name: '江南区',
                    Code: '5'
                  },
                  {
                    Name: '西乡塘区',
                    Code: '7'
                  },
                  {
                    Name: '良庆区',
                    Code: '8'
                  },
                  {
                    Name: '邕宁区',
                    Code: '9'
                  },
                  {
                    Name: '武鸣县',
                    Code: '22'
                  },
                  {
                    Name: '隆安县',
                    Code: '23'
                  },
                  {
                    Name: '马山县',
                    Code: '24'
                  },
                  {
                    Name: '上林县',
                    Code: '25'
                  },
                  {
                    Name: '宾阳县',
                    Code: '26'
                  },
                  {
                    Name: '横县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '柳州',
                Code: '2',
                Region: [
                  {
                    Name: '城中区',
                    Code: '2'
                  },
                  {
                    Name: '鱼峰区',
                    Code: '3'
                  },
                  {
                    Name: '柳南区',
                    Code: '4'
                  },
                  {
                    Name: '柳北区',
                    Code: '5'
                  },
                  {
                    Name: '柳江县',
                    Code: '21'
                  },
                  {
                    Name: '柳城县',
                    Code: '22'
                  },
                  {
                    Name: '鹿寨县',
                    Code: '23'
                  },
                  {
                    Name: '融安县',
                    Code: '24'
                  },
                  {
                    Name: '融水苗族自治县',
                    Code: '25'
                  },
                  {
                    Name: '三江侗族自治县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '桂林',
                Code: '3',
                Region: [
                  {
                    Name: '秀峰区',
                    Code: '2'
                  },
                  {
                    Name: '叠彩区',
                    Code: '3'
                  },
                  {
                    Name: '象山区',
                    Code: '4'
                  },
                  {
                    Name: '七星区',
                    Code: '5'
                  },
                  {
                    Name: '雁山区',
                    Code: '11'
                  },
                  {
                    Name: '阳朔县',
                    Code: '21'
                  },
                  {
                    Name: '临桂县',
                    Code: '22'
                  },
                  {
                    Name: '灵川县',
                    Code: '23'
                  },
                  {
                    Name: '全州县',
                    Code: '24'
                  },
                  {
                    Name: '兴安县',
                    Code: '25'
                  },
                  {
                    Name: '永福县',
                    Code: '26'
                  },
                  {
                    Name: '灌阳县',
                    Code: '27'
                  },
                  {
                    Name: '龙胜各族自治县',
                    Code: '28'
                  },
                  {
                    Name: '资源县',
                    Code: '29'
                  },
                  {
                    Name: '平乐县',
                    Code: '30'
                  },
                  {
                    Name: '荔浦县',
                    Code: '31'
                  },
                  {
                    Name: '恭城瑶族自治县',
                    Code: '32'
                  }
                ]
              },
              {
                Name: '梧州',
                Code: '4',
                Region: [
                  {
                    Name: '万秀区',
                    Code: '3'
                  },
                  {
                    Name: '蝶山区',
                    Code: '4'
                  },
                  {
                    Name: '长洲区',
                    Code: '5'
                  },
                  {
                    Name: '苍梧县',
                    Code: '21'
                  },
                  {
                    Name: '藤县',
                    Code: '22'
                  },
                  {
                    Name: '蒙山县',
                    Code: '23'
                  },
                  {
                    Name: '岑溪市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '北海',
                Code: '5',
                Region: [
                  {
                    Name: '海城区',
                    Code: '2'
                  },
                  {
                    Name: '银海区',
                    Code: '3'
                  },
                  {
                    Name: '铁山港区',
                    Code: '12'
                  },
                  {
                    Name: '合浦县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '防城港',
                Code: '6',
                Region: [
                  {
                    Name: '港口区',
                    Code: '2'
                  },
                  {
                    Name: '防城区',
                    Code: '3'
                  },
                  {
                    Name: '上思县',
                    Code: '21'
                  },
                  {
                    Name: '东兴市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '钦州',
                Code: '7',
                Region: [
                  {
                    Name: '钦南区',
                    Code: '2'
                  },
                  {
                    Name: '钦北区',
                    Code: '3'
                  },
                  {
                    Name: '灵山县',
                    Code: '21'
                  },
                  {
                    Name: '浦北县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '贵港',
                Code: '8',
                Region: [
                  {
                    Name: '港北区',
                    Code: '2'
                  },
                  {
                    Name: '港南区',
                    Code: '3'
                  },
                  {
                    Name: '覃塘区',
                    Code: '4'
                  },
                  {
                    Name: '平南县',
                    Code: '21'
                  },
                  {
                    Name: '桂平市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '玉林',
                Code: '9',
                Region: [
                  {
                    Name: '玉州区',
                    Code: '2'
                  },
                  {
                    Name: '容县',
                    Code: '21'
                  },
                  {
                    Name: '陆川县',
                    Code: '22'
                  },
                  {
                    Name: '博白县',
                    Code: '23'
                  },
                  {
                    Name: '兴业县',
                    Code: '24'
                  },
                  {
                    Name: '北流市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '百色',
                Code: '10',
                Region: [
                  {
                    Name: '右江区',
                    Code: '2'
                  },
                  {
                    Name: '田阳县',
                    Code: '21'
                  },
                  {
                    Name: '田东县',
                    Code: '22'
                  },
                  {
                    Name: '平果县',
                    Code: '23'
                  },
                  {
                    Name: '德保县',
                    Code: '24'
                  },
                  {
                    Name: '靖西县',
                    Code: '25'
                  },
                  {
                    Name: '那坡县',
                    Code: '26'
                  },
                  {
                    Name: '凌云县',
                    Code: '27'
                  },
                  {
                    Name: '乐业县',
                    Code: '28'
                  },
                  {
                    Name: '田林县',
                    Code: '29'
                  },
                  {
                    Name: '西林县',
                    Code: '30'
                  },
                  {
                    Name: '隆林各族自治县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '贺州',
                Code: '11',
                Region: [
                  {
                    Name: '八步区',
                    Code: '2'
                  },
                  {
                    Name: '昭平县',
                    Code: '21'
                  },
                  {
                    Name: '钟山县',
                    Code: '22'
                  },
                  {
                    Name: '富川瑶族自治县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '河池',
                Code: '12',
                Region: [
                  {
                    Name: '金城江区',
                    Code: '2'
                  },
                  {
                    Name: '南丹县',
                    Code: '21'
                  },
                  {
                    Name: '天峨县',
                    Code: '22'
                  },
                  {
                    Name: '凤山县',
                    Code: '23'
                  },
                  {
                    Name: '东兰县',
                    Code: '24'
                  },
                  {
                    Name: '罗城仫佬族自治县',
                    Code: '25'
                  },
                  {
                    Name: '环江毛南族自治县',
                    Code: '26'
                  },
                  {
                    Name: '巴马瑶族自治县',
                    Code: '27'
                  },
                  {
                    Name: '都安瑶族自治县',
                    Code: '28'
                  },
                  {
                    Name: '大化瑶族自治县',
                    Code: '29'
                  },
                  {
                    Name: '宜州市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '来宾',
                Code: '13',
                Region: [
                  {
                    Name: '兴宾区',
                    Code: '2'
                  },
                  {
                    Name: '忻城县',
                    Code: '21'
                  },
                  {
                    Name: '象州县',
                    Code: '22'
                  },
                  {
                    Name: '武宣县',
                    Code: '23'
                  },
                  {
                    Name: '金秀瑶族自治县',
                    Code: '24'
                  },
                  {
                    Name: '合山市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '崇左',
                Code: '14',
                Region: [
                  {
                    Name: '江洲区',
                    Code: '2'
                  },
                  {
                    Name: '扶绥县',
                    Code: '21'
                  },
                  {
                    Name: '宁明县',
                    Code: '22'
                  },
                  {
                    Name: '龙州县',
                    Code: '23'
                  },
                  {
                    Name: '大新县',
                    Code: '24'
                  },
                  {
                    Name: '天等县',
                    Code: '25'
                  },
                  {
                    Name: '凭祥市',
                    Code: '81'
                  }
                ]
              }
            ]
          },
          {
            Name: '海南',
            Code: '46',
            City: [
              {
                Name: '海口',
                Code: '1',
                Region: [
                  {
                    Name: '秀英区',
                    Code: '5'
                  },
                  {
                    Name: '龙华区',
                    Code: '6'
                  },
                  {
                    Name: '琼山区',
                    Code: '7'
                  },
                  {
                    Name: '美兰区',
                    Code: '8'
                  }
                ]
              },
              {
                Name: '三亚',
                Code: '2'
              },
              {
                Name: '三沙',
                Code: '3'
              },
              {
                Name: '五指山',
                Code: '91'
              },
              {
                Name: '琼海',
                Code: '92'
              },
              {
                Name: '儋州',
                Code: '93'
              },
              {
                Name: '文昌',
                Code: '95'
              },
              {
                Name: '万宁',
                Code: '96'
              },
              {
                Name: '东方',
                Code: '97'
              },
              {
                Name: '定安',
                Code: 'A25'
              },
              {
                Name: '屯昌',
                Code: 'A26'
              },
              {
                Name: '澄迈',
                Code: 'A27'
              },
              {
                Name: '临高',
                Code: 'A28'
              },
              {
                Name: '白沙',
                Code: 'A30'
              },
              {
                Name: '昌江',
                Code: 'A31'
              },
              {
                Name: '乐东',
                Code: 'A33'
              },
              {
                Name: '陵水',
                Code: 'A34'
              },
              {
                Name: '保亭',
                Code: 'A35'
              },
              {
                Name: '琼中',
                Code: 'A36'
              }
            ]
          },
          {
            Name: '重庆',
            Code: '50',
            City: [
              {
                Name: '万州',
                Code: '1'
              },
              {
                Name: '涪陵',
                Code: '2'
              },
              {
                Name: '渝中',
                Code: '3'
              },
              {
                Name: '大渡口',
                Code: '4'
              },
              {
                Name: '江北',
                Code: '5'
              },
              {
                Name: '沙坪坝',
                Code: '6'
              },
              {
                Name: '九龙坡',
                Code: '7'
              },
              {
                Name: '南岸',
                Code: '8'
              },
              {
                Name: '北碚',
                Code: '9'
              },
              {
                Name: '两江新区',
                Code: '85'
              },
              {
                Name: '万盛',
                Code: '10'
              },
              {
                Name: '双桥',
                Code: '11'
              },
              {
                Name: '渝北',
                Code: '12'
              },
              {
                Name: '巴南',
                Code: '13'
              },
              {
                Name: '长寿',
                Code: '21'
              },
              {
                Name: '綦江',
                Code: '22'
              },
              {
                Name: '潼南',
                Code: '23'
              },
              {
                Name: '铜梁',
                Code: '24'
              },
              {
                Name: '大足',
                Code: '25'
              },
              {
                Name: '荣昌',
                Code: '26'
              },
              {
                Name: '璧山',
                Code: '27'
              },
              {
                Name: '梁平',
                Code: '28'
              },
              {
                Name: '城口',
                Code: '29'
              },
              {
                Name: '丰都',
                Code: '30'
              },
              {
                Name: '垫江',
                Code: '31'
              },
              {
                Name: '武隆',
                Code: '32'
              },
              {
                Name: '忠县',
                Code: '33'
              },
              {
                Name: '开县',
                Code: '34'
              },
              {
                Name: '云阳',
                Code: '35'
              },
              {
                Name: '奉节',
                Code: '36'
              },
              {
                Name: '巫山',
                Code: '37'
              },
              {
                Name: '巫溪',
                Code: '38'
              },
              {
                Name: '黔江',
                Code: '39'
              },
              {
                Name: '石柱',
                Code: '40'
              },
              {
                Name: '秀山',
                Code: '41'
              },
              {
                Name: '酉阳',
                Code: '42'
              },
              {
                Name: '彭水',
                Code: '43'
              },
              {
                Name: '江津',
                Code: '81'
              },
              {
                Name: '合川',
                Code: '82'
              },
              {
                Name: '永川',
                Code: '83'
              },
              {
                Name: '南川',
                Code: '84'
              }
            ]
          },
          {
            Name: '四川',
            Code: '51',
            City: [
              {
                Name: '成都',
                Code: '1',
                Region: [
                  {
                    Name: '锦江区',
                    Code: '4'
                  },
                  {
                    Name: '青羊区',
                    Code: '5'
                  },
                  {
                    Name: '金牛区',
                    Code: '6'
                  },
                  {
                    Name: '武侯区',
                    Code: '7'
                  },
                  {
                    Name: '成华区',
                    Code: '8'
                  },
                  {
                    Name: '龙泉驿区',
                    Code: '12'
                  },
                  {
                    Name: '青白江区',
                    Code: '13'
                  },
                  {
                    Name: '新都区',
                    Code: '14'
                  },
                  {
                    Name: '温江区',
                    Code: '15'
                  },
                  {
                    Name: '金堂县',
                    Code: '21'
                  },
                  {
                    Name: '双流县',
                    Code: '22'
                  },
                  {
                    Name: '郫县',
                    Code: '24'
                  },
                  {
                    Name: '大邑县',
                    Code: '29'
                  },
                  {
                    Name: '蒲江县',
                    Code: '31'
                  },
                  {
                    Name: '新津县',
                    Code: '32'
                  },
                  {
                    Name: '都江堰市',
                    Code: '81'
                  },
                  {
                    Name: '彭州市',
                    Code: '82'
                  },
                  {
                    Name: '邛崃市',
                    Code: '83'
                  },
                  {
                    Name: '崇州市',
                    Code: '84'
                  }
                ]
              },
              {
                Name: '自贡',
                Code: '3',
                Region: [
                  {
                    Name: '自流井区',
                    Code: '2'
                  },
                  {
                    Name: '贡井区',
                    Code: '3'
                  },
                  {
                    Name: '大安区',
                    Code: '4'
                  },
                  {
                    Name: '沿滩区',
                    Code: '11'
                  },
                  {
                    Name: '荣县',
                    Code: '21'
                  },
                  {
                    Name: '富顺县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '攀枝花',
                Code: '4',
                Region: [
                  {
                    Name: '东区',
                    Code: '2'
                  },
                  {
                    Name: '西区',
                    Code: '3'
                  },
                  {
                    Name: '仁和区',
                    Code: '11'
                  },
                  {
                    Name: '米易县',
                    Code: '21'
                  },
                  {
                    Name: '盐边县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '泸州',
                Code: '5',
                Region: [
                  {
                    Name: '江阳区',
                    Code: '2'
                  },
                  {
                    Name: '纳溪区',
                    Code: '3'
                  },
                  {
                    Name: '龙马潭区',
                    Code: '4'
                  },
                  {
                    Name: '泸县',
                    Code: '21'
                  },
                  {
                    Name: '合江县',
                    Code: '22'
                  },
                  {
                    Name: '叙永县',
                    Code: '24'
                  },
                  {
                    Name: '古蔺县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '德阳',
                Code: '6',
                Region: [
                  {
                    Name: '旌阳区',
                    Code: '3'
                  },
                  {
                    Name: '中江县',
                    Code: '23'
                  },
                  {
                    Name: '罗江县',
                    Code: '26'
                  },
                  {
                    Name: '广汉市',
                    Code: '81'
                  },
                  {
                    Name: '什邡市',
                    Code: '82'
                  },
                  {
                    Name: '绵竹市',
                    Code: '83'
                  }
                ]
              },
              {
                Name: '绵阳',
                Code: '7',
                Region: [
                  {
                    Name: '涪城区',
                    Code: '3'
                  },
                  {
                    Name: '游仙区',
                    Code: '4'
                  },
                  {
                    Name: '三台县',
                    Code: '22'
                  },
                  {
                    Name: '盐亭县',
                    Code: '23'
                  },
                  {
                    Name: '安县',
                    Code: '24'
                  },
                  {
                    Name: '梓潼县',
                    Code: '25'
                  },
                  {
                    Name: '北川羌族自治县',
                    Code: '26'
                  },
                  {
                    Name: '平武县',
                    Code: '27'
                  },
                  {
                    Name: '江油市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '广元',
                Code: '8',
                Region: [
                  {
                    Name: '市中区',
                    Code: '2'
                  },
                  {
                    Name: '元坝区',
                    Code: '11'
                  },
                  {
                    Name: '朝天区',
                    Code: '12'
                  },
                  {
                    Name: '旺苍县',
                    Code: '21'
                  },
                  {
                    Name: '青川县',
                    Code: '22'
                  },
                  {
                    Name: '剑阁县',
                    Code: '23'
                  },
                  {
                    Name: '苍溪县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '遂宁',
                Code: '9',
                Region: [
                  {
                    Name: '船山区',
                    Code: '3'
                  },
                  {
                    Name: '安居区',
                    Code: '4'
                  },
                  {
                    Name: '蓬溪县',
                    Code: '21'
                  },
                  {
                    Name: '射洪县',
                    Code: '22'
                  },
                  {
                    Name: '大英县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '内江',
                Code: '10',
                Region: [
                  {
                    Name: '市中区',
                    Code: '2'
                  },
                  {
                    Name: '东兴区',
                    Code: '11'
                  },
                  {
                    Name: '威远县',
                    Code: '24'
                  },
                  {
                    Name: '资中县',
                    Code: '25'
                  },
                  {
                    Name: '隆昌县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '乐山',
                Code: '11',
                Region: [
                  {
                    Name: '市中区',
                    Code: '2'
                  },
                  {
                    Name: '沙湾区',
                    Code: '11'
                  },
                  {
                    Name: '五通桥区',
                    Code: '12'
                  },
                  {
                    Name: '金口河区',
                    Code: '13'
                  },
                  {
                    Name: '犍为县',
                    Code: '23'
                  },
                  {
                    Name: '井研县',
                    Code: '24'
                  },
                  {
                    Name: '夹江县',
                    Code: '26'
                  },
                  {
                    Name: '沐川县',
                    Code: '29'
                  },
                  {
                    Name: '峨边彝族自治县',
                    Code: '32'
                  },
                  {
                    Name: '马边彝族自治县',
                    Code: '33'
                  },
                  {
                    Name: '峨眉山市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '南充',
                Code: '13',
                Region: [
                  {
                    Name: '顺庆区',
                    Code: '2'
                  },
                  {
                    Name: '高坪区',
                    Code: '3'
                  },
                  {
                    Name: '嘉陵区',
                    Code: '4'
                  },
                  {
                    Name: '南部县',
                    Code: '21'
                  },
                  {
                    Name: '营山县',
                    Code: '22'
                  },
                  {
                    Name: '蓬安县',
                    Code: '23'
                  },
                  {
                    Name: '仪陇县',
                    Code: '24'
                  },
                  {
                    Name: '西充县',
                    Code: '25'
                  },
                  {
                    Name: '阆中市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '眉山',
                Code: '14',
                Region: [
                  {
                    Name: '东坡区',
                    Code: '2'
                  },
                  {
                    Name: '仁寿县',
                    Code: '21'
                  },
                  {
                    Name: '彭山县',
                    Code: '22'
                  },
                  {
                    Name: '洪雅县',
                    Code: '23'
                  },
                  {
                    Name: '丹棱县',
                    Code: '24'
                  },
                  {
                    Name: '青神县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '宜宾',
                Code: '15',
                Region: [
                  {
                    Name: '翠屏区',
                    Code: '2'
                  },
                  {
                    Name: '宜宾县',
                    Code: '21'
                  },
                  {
                    Name: '南溪县',
                    Code: '22'
                  },
                  {
                    Name: '江安县',
                    Code: '23'
                  },
                  {
                    Name: '长宁县',
                    Code: '24'
                  },
                  {
                    Name: '高县',
                    Code: '25'
                  },
                  {
                    Name: '珙县',
                    Code: '26'
                  },
                  {
                    Name: '筠连县',
                    Code: '27'
                  },
                  {
                    Name: '兴文县',
                    Code: '28'
                  },
                  {
                    Name: '屏山县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '广安',
                Code: '16',
                Region: [
                  {
                    Name: '广安区',
                    Code: '2'
                  },
                  {
                    Name: '岳池县',
                    Code: '21'
                  },
                  {
                    Name: '武胜县',
                    Code: '22'
                  },
                  {
                    Name: '邻水县',
                    Code: '23'
                  },
                  {
                    Name: '华蓥市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '达州',
                Code: '17',
                Region: [
                  {
                    Name: '通川区',
                    Code: '2'
                  },
                  {
                    Name: '达县',
                    Code: '21'
                  },
                  {
                    Name: '宣汉县',
                    Code: '22'
                  },
                  {
                    Name: '开江县',
                    Code: '23'
                  },
                  {
                    Name: '大竹县',
                    Code: '24'
                  },
                  {
                    Name: '渠县',
                    Code: '25'
                  },
                  {
                    Name: '万源市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '雅安',
                Code: '18',
                Region: [
                  {
                    Name: '雨城区',
                    Code: '2'
                  },
                  {
                    Name: '名山县',
                    Code: '21'
                  },
                  {
                    Name: '荥经县',
                    Code: '22'
                  },
                  {
                    Name: '汉源县',
                    Code: '23'
                  },
                  {
                    Name: '石棉县',
                    Code: '24'
                  },
                  {
                    Name: '天全县',
                    Code: '25'
                  },
                  {
                    Name: '芦山县',
                    Code: '26'
                  },
                  {
                    Name: '宝兴县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '巴中',
                Code: '19',
                Region: [
                  {
                    Name: '巴州区',
                    Code: '2'
                  },
                  {
                    Name: '通江县',
                    Code: '21'
                  },
                  {
                    Name: '南江县',
                    Code: '22'
                  },
                  {
                    Name: '平昌县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '资阳',
                Code: '20',
                Region: [
                  {
                    Name: '雁江区',
                    Code: '2'
                  },
                  {
                    Name: '安岳县',
                    Code: '21'
                  },
                  {
                    Name: '乐至县',
                    Code: '22'
                  },
                  {
                    Name: '简阳市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '阿坝',
                Code: '32',
                Region: [
                  {
                    Name: '汶川县',
                    Code: '21'
                  },
                  {
                    Name: '理县',
                    Code: '22'
                  },
                  {
                    Name: '茂县',
                    Code: '23'
                  },
                  {
                    Name: '松潘县',
                    Code: '24'
                  },
                  {
                    Name: '九寨沟县',
                    Code: '25'
                  },
                  {
                    Name: '金川县',
                    Code: '26'
                  },
                  {
                    Name: '小金县',
                    Code: '27'
                  },
                  {
                    Name: '黑水县',
                    Code: '28'
                  },
                  {
                    Name: '马尔康县',
                    Code: '29'
                  },
                  {
                    Name: '壤塘县',
                    Code: '30'
                  },
                  {
                    Name: '阿坝县',
                    Code: '31'
                  },
                  {
                    Name: '若尔盖县',
                    Code: '32'
                  },
                  {
                    Name: '红原县',
                    Code: '33'
                  }
                ]
              },
              {
                Name: '甘孜',
                Code: '33',
                Region: [
                  {
                    Name: '康定县',
                    Code: '21'
                  },
                  {
                    Name: '泸定县',
                    Code: '22'
                  },
                  {
                    Name: '丹巴县',
                    Code: '23'
                  },
                  {
                    Name: '九龙县',
                    Code: '24'
                  },
                  {
                    Name: '雅江县',
                    Code: '25'
                  },
                  {
                    Name: '道孚县',
                    Code: '26'
                  },
                  {
                    Name: '炉霍县',
                    Code: '27'
                  },
                  {
                    Name: '甘孜县',
                    Code: '28'
                  },
                  {
                    Name: '新龙县',
                    Code: '29'
                  },
                  {
                    Name: '德格县',
                    Code: '30'
                  },
                  {
                    Name: '白玉县',
                    Code: '31'
                  },
                  {
                    Name: '石渠县',
                    Code: '32'
                  },
                  {
                    Name: '色达县',
                    Code: '33'
                  },
                  {
                    Name: '理塘县',
                    Code: '34'
                  },
                  {
                    Name: '巴塘县',
                    Code: '35'
                  },
                  {
                    Name: '乡城县',
                    Code: '36'
                  },
                  {
                    Name: '稻城县',
                    Code: '37'
                  },
                  {
                    Name: '得荣县',
                    Code: '38'
                  }
                ]
              },
              {
                Name: '凉山',
                Code: '34',
                Region: [
                  {
                    Name: '西昌市',
                    Code: '1'
                  },
                  {
                    Name: '木里藏族自治县',
                    Code: '22'
                  },
                  {
                    Name: '盐源县',
                    Code: '23'
                  },
                  {
                    Name: '德昌县',
                    Code: '24'
                  },
                  {
                    Name: '会理县',
                    Code: '25'
                  },
                  {
                    Name: '会东县',
                    Code: '26'
                  },
                  {
                    Name: '宁南县',
                    Code: '27'
                  },
                  {
                    Name: '普格县',
                    Code: '28'
                  },
                  {
                    Name: '布拖县',
                    Code: '29'
                  },
                  {
                    Name: '金阳县',
                    Code: '30'
                  },
                  {
                    Name: '昭觉县',
                    Code: '31'
                  },
                  {
                    Name: '喜德县',
                    Code: '32'
                  },
                  {
                    Name: '冕宁县',
                    Code: '33'
                  },
                  {
                    Name: '越西县',
                    Code: '34'
                  },
                  {
                    Name: '甘洛县',
                    Code: '35'
                  },
                  {
                    Name: '美姑县',
                    Code: '36'
                  },
                  {
                    Name: '雷波县',
                    Code: '37'
                  }
                ]
              }
            ]
          },
          {
            Name: '贵州',
            Code: '52',
            City: [
              {
                Name: '贵阳',
                Code: '1',
                Region: [
                  {
                    Name: '南明区',
                    Code: '2'
                  },
                  {
                    Name: '云岩区',
                    Code: '3'
                  },
                  {
                    Name: '花溪区',
                    Code: '11'
                  },
                  {
                    Name: '乌当区',
                    Code: '12'
                  },
                  {
                    Name: '白云区',
                    Code: '13'
                  },
                  {
                    Name: '小河区',
                    Code: '14'
                  },
                  {
                    Name: '开阳县',
                    Code: '21'
                  },
                  {
                    Name: '息烽县',
                    Code: '22'
                  },
                  {
                    Name: '修文县',
                    Code: '23'
                  },
                  {
                    Name: '清镇市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '六盘水',
                Code: '2',
                Region: [
                  {
                    Name: '钟山区',
                    Code: '1'
                  },
                  {
                    Name: '六枝特区',
                    Code: '3'
                  },
                  {
                    Name: '水城县',
                    Code: '21'
                  },
                  {
                    Name: '盘县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '遵义',
                Code: '3',
                Region: [
                  {
                    Name: '红花岗区',
                    Code: '2'
                  },
                  {
                    Name: '汇川区',
                    Code: '3'
                  },
                  {
                    Name: '遵义县',
                    Code: '21'
                  },
                  {
                    Name: '桐梓县',
                    Code: '22'
                  },
                  {
                    Name: '绥阳县',
                    Code: '23'
                  },
                  {
                    Name: '正安县',
                    Code: '24'
                  },
                  {
                    Name: '道真仡佬族苗族自治县',
                    Code: '25'
                  },
                  {
                    Name: '务川仡佬族苗族自治县',
                    Code: '26'
                  },
                  {
                    Name: '凤冈县',
                    Code: '27'
                  },
                  {
                    Name: '湄潭县',
                    Code: '28'
                  },
                  {
                    Name: '余庆县',
                    Code: '29'
                  },
                  {
                    Name: '习水县',
                    Code: '30'
                  },
                  {
                    Name: '赤水市',
                    Code: '81'
                  },
                  {
                    Name: '仁怀市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '安顺',
                Code: '4',
                Region: [
                  {
                    Name: '西秀区',
                    Code: '2'
                  },
                  {
                    Name: '平坝县',
                    Code: '21'
                  },
                  {
                    Name: '普定县',
                    Code: '22'
                  },
                  {
                    Name: '镇宁布依族苗族自治县',
                    Code: '23'
                  },
                  {
                    Name: '关岭布依族苗族自治县',
                    Code: '24'
                  },
                  {
                    Name: '紫云苗族布依族自治县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '铜仁',
                Code: '22',
                Region: [
                  {
                    Name: '铜仁市',
                    Code: '1'
                  },
                  {
                    Name: '江口县',
                    Code: '22'
                  },
                  {
                    Name: '玉屏侗族自治县',
                    Code: '23'
                  },
                  {
                    Name: '石阡县',
                    Code: '24'
                  },
                  {
                    Name: '思南县',
                    Code: '25'
                  },
                  {
                    Name: '印江土家族苗族自治县',
                    Code: '26'
                  },
                  {
                    Name: '德江县',
                    Code: '27'
                  },
                  {
                    Name: '沿河土家族自治县',
                    Code: '28'
                  },
                  {
                    Name: '松桃苗族自治县',
                    Code: '29'
                  },
                  {
                    Name: '万山特区',
                    Code: '30'
                  }
                ]
              },
              {
                Name: '黔西南',
                Code: '23',
                Region: [
                  {
                    Name: '兴义市',
                    Code: '1'
                  },
                  {
                    Name: '兴仁县',
                    Code: '22'
                  },
                  {
                    Name: '普安县',
                    Code: '23'
                  },
                  {
                    Name: '晴隆县',
                    Code: '24'
                  },
                  {
                    Name: '贞丰县',
                    Code: '25'
                  },
                  {
                    Name: '望谟县',
                    Code: '26'
                  },
                  {
                    Name: '册亨县',
                    Code: '27'
                  },
                  {
                    Name: '安龙县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '毕节',
                Code: '24',
                Region: [
                  {
                    Name: '毕节市',
                    Code: '1'
                  },
                  {
                    Name: '大方县',
                    Code: '22'
                  },
                  {
                    Name: '黔西县',
                    Code: '23'
                  },
                  {
                    Name: '金沙县',
                    Code: '24'
                  },
                  {
                    Name: '织金县',
                    Code: '25'
                  },
                  {
                    Name: '纳雍县',
                    Code: '26'
                  },
                  {
                    Name: '威宁彝族回族苗族自治县',
                    Code: '27'
                  },
                  {
                    Name: '赫章县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '黔东南',
                Code: '26',
                Region: [
                  {
                    Name: '凯里市',
                    Code: '1'
                  },
                  {
                    Name: '黄平县',
                    Code: '22'
                  },
                  {
                    Name: '施秉县',
                    Code: '23'
                  },
                  {
                    Name: '三穗县',
                    Code: '24'
                  },
                  {
                    Name: '镇远县',
                    Code: '25'
                  },
                  {
                    Name: '岑巩县',
                    Code: '26'
                  },
                  {
                    Name: '天柱县',
                    Code: '27'
                  },
                  {
                    Name: '锦屏县',
                    Code: '28'
                  },
                  {
                    Name: '剑河县',
                    Code: '29'
                  },
                  {
                    Name: '台江县',
                    Code: '30'
                  },
                  {
                    Name: '黎平县',
                    Code: '31'
                  },
                  {
                    Name: '榕江县',
                    Code: '32'
                  },
                  {
                    Name: '从江县',
                    Code: '33'
                  },
                  {
                    Name: '雷山县',
                    Code: '34'
                  },
                  {
                    Name: '麻江县',
                    Code: '35'
                  },
                  {
                    Name: '丹寨县',
                    Code: '36'
                  }
                ]
              },
              {
                Name: '黔南',
                Code: '27',
                Region: [
                  {
                    Name: '都匀市',
                    Code: '1'
                  },
                  {
                    Name: '福泉市',
                    Code: '2'
                  },
                  {
                    Name: '荔波县',
                    Code: '22'
                  },
                  {
                    Name: '贵定县',
                    Code: '23'
                  },
                  {
                    Name: '瓮安县',
                    Code: '25'
                  },
                  {
                    Name: '独山县',
                    Code: '26'
                  },
                  {
                    Name: '平塘县',
                    Code: '27'
                  },
                  {
                    Name: '罗甸县',
                    Code: '28'
                  },
                  {
                    Name: '长顺县',
                    Code: '29'
                  },
                  {
                    Name: '龙里县',
                    Code: '30'
                  },
                  {
                    Name: '惠水县',
                    Code: '31'
                  },
                  {
                    Name: '三都水族自治县',
                    Code: '32'
                  }
                ]
              }
            ]
          },
          {
            Name: '云南',
            Code: '53',
            City: [
              {
                Name: '昆明',
                Code: '1',
                Region: [
                  {
                    Name: '五华区',
                    Code: '2'
                  },
                  {
                    Name: '盘龙区',
                    Code: '3'
                  },
                  {
                    Name: '官渡区',
                    Code: '11'
                  },
                  {
                    Name: '西山区',
                    Code: '12'
                  },
                  {
                    Name: '东川区',
                    Code: '13'
                  },
                  {
                    Name: '呈贡县',
                    Code: '21'
                  },
                  {
                    Name: '晋宁县',
                    Code: '22'
                  },
                  {
                    Name: '富民县',
                    Code: '24'
                  },
                  {
                    Name: '宜良县',
                    Code: '25'
                  },
                  {
                    Name: '石林彝族自治县',
                    Code: '26'
                  },
                  {
                    Name: '嵩明县',
                    Code: '27'
                  },
                  {
                    Name: '禄劝彝族苗族自治县',
                    Code: '28'
                  },
                  {
                    Name: '寻甸回族彝族自治县',
                    Code: '29'
                  },
                  {
                    Name: '安宁市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '曲靖',
                Code: '3',
                Region: [
                  {
                    Name: '麒麟区',
                    Code: '2'
                  },
                  {
                    Name: '马龙县',
                    Code: '21'
                  },
                  {
                    Name: '陆良县',
                    Code: '22'
                  },
                  {
                    Name: '师宗县',
                    Code: '23'
                  },
                  {
                    Name: '罗平县',
                    Code: '24'
                  },
                  {
                    Name: '富源县',
                    Code: '25'
                  },
                  {
                    Name: '会泽县',
                    Code: '26'
                  },
                  {
                    Name: '沾益县',
                    Code: '28'
                  },
                  {
                    Name: '宣威市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '玉溪',
                Code: '4',
                Region: [
                  {
                    Name: '红塔区',
                    Code: '2'
                  },
                  {
                    Name: '江川县',
                    Code: '21'
                  },
                  {
                    Name: '澄江县',
                    Code: '22'
                  },
                  {
                    Name: '通海县',
                    Code: '23'
                  },
                  {
                    Name: '华宁县',
                    Code: '24'
                  },
                  {
                    Name: '易门县',
                    Code: '25'
                  },
                  {
                    Name: '峨山彝族自治县',
                    Code: '26'
                  },
                  {
                    Name: '新平彝族傣族自治县',
                    Code: '27'
                  },
                  {
                    Name: '元江哈尼族彝族傣族自治县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '保山',
                Code: '5',
                Region: [
                  {
                    Name: '隆阳区',
                    Code: '2'
                  },
                  {
                    Name: '施甸县',
                    Code: '21'
                  },
                  {
                    Name: '腾冲县',
                    Code: '22'
                  },
                  {
                    Name: '龙陵县',
                    Code: '23'
                  },
                  {
                    Name: '昌宁县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '昭通',
                Code: '6',
                Region: [
                  {
                    Name: '昭阳区',
                    Code: '2'
                  },
                  {
                    Name: '鲁甸县',
                    Code: '21'
                  },
                  {
                    Name: '巧家县',
                    Code: '22'
                  },
                  {
                    Name: '盐津县',
                    Code: '23'
                  },
                  {
                    Name: '大关县',
                    Code: '24'
                  },
                  {
                    Name: '永善县',
                    Code: '25'
                  },
                  {
                    Name: '绥江县',
                    Code: '26'
                  },
                  {
                    Name: '镇雄县',
                    Code: '27'
                  },
                  {
                    Name: '彝良县',
                    Code: '28'
                  },
                  {
                    Name: '威信县',
                    Code: '29'
                  },
                  {
                    Name: '水富县',
                    Code: '30'
                  }
                ]
              },
              {
                Name: '丽江',
                Code: '7',
                Region: [
                  {
                    Name: '古城区',
                    Code: '2'
                  },
                  {
                    Name: '玉龙纳西族自治县',
                    Code: '21'
                  },
                  {
                    Name: '永胜县',
                    Code: '22'
                  },
                  {
                    Name: '华坪县',
                    Code: '23'
                  },
                  {
                    Name: '宁蒗彝族自治县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '普洱',
                Code: '8',
                Region: [
                  {
                    Name: '思茅区',
                    Code: '2'
                  },
                  {
                    Name: '宁洱哈尼族彝族自治县',
                    Code: '21'
                  },
                  {
                    Name: '墨江哈尼族自治县',
                    Code: '22'
                  },
                  {
                    Name: '景东彝族自治县',
                    Code: '23'
                  },
                  {
                    Name: '景谷傣族彝族自治县',
                    Code: '24'
                  },
                  {
                    Name: '镇沅彝族哈尼族拉祜族自治县',
                    Code: '25'
                  },
                  {
                    Name: '江城哈尼族彝族自治县',
                    Code: '26'
                  },
                  {
                    Name: '孟连傣族拉祜族佤族自治县',
                    Code: '27'
                  },
                  {
                    Name: '澜沧拉祜族自治县',
                    Code: '28'
                  },
                  {
                    Name: '西盟佤族自治县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '临沧',
                Code: '9',
                Region: [
                  {
                    Name: '临翔区',
                    Code: '2'
                  },
                  {
                    Name: '凤庆县',
                    Code: '21'
                  },
                  {
                    Name: '云县',
                    Code: '22'
                  },
                  {
                    Name: '永德县',
                    Code: '23'
                  },
                  {
                    Name: '镇康县',
                    Code: '24'
                  },
                  {
                    Name: '双江拉祜族佤族布朗族傣族自治县',
                    Code: '25'
                  },
                  {
                    Name: '耿马傣族佤族自治县',
                    Code: '26'
                  },
                  {
                    Name: '沧源佤族自治县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '楚雄',
                Code: '23',
                Region: [
                  {
                    Name: '楚雄市',
                    Code: '1'
                  },
                  {
                    Name: '双柏县',
                    Code: '22'
                  },
                  {
                    Name: '牟定县',
                    Code: '23'
                  },
                  {
                    Name: '南华县',
                    Code: '24'
                  },
                  {
                    Name: '姚安县',
                    Code: '25'
                  },
                  {
                    Name: '大姚县',
                    Code: '26'
                  },
                  {
                    Name: '永仁县',
                    Code: '27'
                  },
                  {
                    Name: '元谋县',
                    Code: '28'
                  },
                  {
                    Name: '武定县',
                    Code: '29'
                  },
                  {
                    Name: '禄丰县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '红河',
                Code: '25',
                Region: [
                  {
                    Name: '个旧市',
                    Code: '1'
                  },
                  {
                    Name: '开远市',
                    Code: '2'
                  },
                  {
                    Name: '蒙自县',
                    Code: '22'
                  },
                  {
                    Name: '屏边苗族自治县',
                    Code: '23'
                  },
                  {
                    Name: '建水县',
                    Code: '24'
                  },
                  {
                    Name: '石屏县',
                    Code: '25'
                  },
                  {
                    Name: '弥勒县',
                    Code: '26'
                  },
                  {
                    Name: '泸西县',
                    Code: '27'
                  },
                  {
                    Name: '元阳县',
                    Code: '28'
                  },
                  {
                    Name: '红河县',
                    Code: '29'
                  },
                  {
                    Name: '金平苗族瑶族傣族自治县',
                    Code: '30'
                  },
                  {
                    Name: '绿春县',
                    Code: '31'
                  },
                  {
                    Name: '河口瑶族自治县',
                    Code: '32'
                  }
                ]
              },
              {
                Name: '文山',
                Code: '26',
                Region: [
                  {
                    Name: '文山县',
                    Code: '21'
                  },
                  {
                    Name: '砚山县',
                    Code: '22'
                  },
                  {
                    Name: '西畴县',
                    Code: '23'
                  },
                  {
                    Name: '麻栗坡县',
                    Code: '24'
                  },
                  {
                    Name: '马关县',
                    Code: '25'
                  },
                  {
                    Name: '丘北县',
                    Code: '26'
                  },
                  {
                    Name: '广南县',
                    Code: '27'
                  },
                  {
                    Name: '富宁县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '西双版纳',
                Code: '28',
                Region: [
                  {
                    Name: '景洪市',
                    Code: '1'
                  },
                  {
                    Name: '勐海县',
                    Code: '22'
                  },
                  {
                    Name: '勐腊县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '大理',
                Code: '29',
                Region: [
                  {
                    Name: '大理市',
                    Code: '1'
                  },
                  {
                    Name: '漾濞彝族自治县',
                    Code: '22'
                  },
                  {
                    Name: '祥云县',
                    Code: '23'
                  },
                  {
                    Name: '宾川县',
                    Code: '24'
                  },
                  {
                    Name: '弥渡县',
                    Code: '25'
                  },
                  {
                    Name: '南涧彝族自治县',
                    Code: '26'
                  },
                  {
                    Name: '巍山彝族回族自治县',
                    Code: '27'
                  },
                  {
                    Name: '永平县',
                    Code: '28'
                  },
                  {
                    Name: '云龙县',
                    Code: '29'
                  },
                  {
                    Name: '洱源县',
                    Code: '30'
                  },
                  {
                    Name: '剑川县',
                    Code: '31'
                  },
                  {
                    Name: '鹤庆县',
                    Code: '32'
                  }
                ]
              },
              {
                Name: '德宏',
                Code: '31',
                Region: [
                  {
                    Name: '瑞丽市',
                    Code: '2'
                  },
                  {
                    Name: '潞西市',
                    Code: '3'
                  },
                  {
                    Name: '梁河县',
                    Code: '22'
                  },
                  {
                    Name: '盈江县',
                    Code: '23'
                  },
                  {
                    Name: '陇川县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '怒江',
                Code: '33',
                Region: [
                  {
                    Name: '泸水县',
                    Code: '21'
                  },
                  {
                    Name: '福贡县',
                    Code: '23'
                  },
                  {
                    Name: '贡山独龙族怒族自治县',
                    Code: '24'
                  },
                  {
                    Name: '兰坪白族普米族自治县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '迪庆',
                Code: '34',
                Region: [
                  {
                    Name: '香格里拉县',
                    Code: '21'
                  },
                  {
                    Name: '德钦县',
                    Code: '22'
                  },
                  {
                    Name: '维西傈僳族自治县',
                    Code: '23'
                  }
                ]
              }
            ]
          },
          {
            Name: '西藏',
            Code: '54',
            City: [
              {
                Name: '拉萨',
                Code: '1',
                Region: [
                  {
                    Name: '城关区',
                    Code: '2'
                  },
                  {
                    Name: '林周县',
                    Code: '21'
                  },
                  {
                    Name: '当雄县',
                    Code: '22'
                  },
                  {
                    Name: '尼木县',
                    Code: '23'
                  },
                  {
                    Name: '曲水县',
                    Code: '24'
                  },
                  {
                    Name: '堆龙德庆县',
                    Code: '25'
                  },
                  {
                    Name: '达孜县',
                    Code: '26'
                  },
                  {
                    Name: '墨竹工卡县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '昌都',
                Code: '21',
                Region: [
                  {
                    Name: '昌都县',
                    Code: '21'
                  },
                  {
                    Name: '江达县',
                    Code: '22'
                  },
                  {
                    Name: '贡觉县',
                    Code: '23'
                  },
                  {
                    Name: '类乌齐县',
                    Code: '24'
                  },
                  {
                    Name: '丁青县',
                    Code: '25'
                  },
                  {
                    Name: '察雅县',
                    Code: '26'
                  },
                  {
                    Name: '八宿县',
                    Code: '27'
                  },
                  {
                    Name: '左贡县',
                    Code: '28'
                  },
                  {
                    Name: '芒康县',
                    Code: '29'
                  },
                  {
                    Name: '洛隆县',
                    Code: '32'
                  },
                  {
                    Name: '边坝县',
                    Code: '33'
                  }
                ]
              },
              {
                Name: '山南',
                Code: '22',
                Region: [
                  {
                    Name: '乃东县',
                    Code: '21'
                  },
                  {
                    Name: '扎囊县',
                    Code: '22'
                  },
                  {
                    Name: '贡嘎县',
                    Code: '23'
                  },
                  {
                    Name: '桑日县',
                    Code: '24'
                  },
                  {
                    Name: '琼结县',
                    Code: '25'
                  },
                  {
                    Name: '曲松县',
                    Code: '26'
                  },
                  {
                    Name: '措美县',
                    Code: '27'
                  },
                  {
                    Name: '洛扎县',
                    Code: '28'
                  },
                  {
                    Name: '加查县',
                    Code: '29'
                  },
                  {
                    Name: '隆子县',
                    Code: '31'
                  },
                  {
                    Name: '错那县',
                    Code: '32'
                  },
                  {
                    Name: '浪卡子县',
                    Code: '33'
                  }
                ]
              },
              {
                Name: '日喀则',
                Code: '23',
                Region: [
                  {
                    Name: '日喀则市',
                    Code: '1'
                  },
                  {
                    Name: '南木林县',
                    Code: '22'
                  },
                  {
                    Name: '江孜县',
                    Code: '23'
                  },
                  {
                    Name: '定日县',
                    Code: '24'
                  },
                  {
                    Name: '萨迦县',
                    Code: '25'
                  },
                  {
                    Name: '拉孜县',
                    Code: '26'
                  },
                  {
                    Name: '昂仁县',
                    Code: '27'
                  },
                  {
                    Name: '谢通门县',
                    Code: '28'
                  },
                  {
                    Name: '白朗县',
                    Code: '29'
                  },
                  {
                    Name: '仁布县',
                    Code: '30'
                  },
                  {
                    Name: '康马县',
                    Code: '31'
                  },
                  {
                    Name: '定结县',
                    Code: '32'
                  },
                  {
                    Name: '仲巴县',
                    Code: '33'
                  },
                  {
                    Name: '亚东县',
                    Code: '34'
                  },
                  {
                    Name: '吉隆县',
                    Code: '35'
                  },
                  {
                    Name: '聂拉木县',
                    Code: '36'
                  },
                  {
                    Name: '萨嘎县',
                    Code: '37'
                  },
                  {
                    Name: '岗巴县',
                    Code: '38'
                  }
                ]
              },
              {
                Name: '那曲',
                Code: '24',
                Region: [
                  {
                    Name: '那曲县',
                    Code: '21'
                  },
                  {
                    Name: '嘉黎县',
                    Code: '22'
                  },
                  {
                    Name: '比如县',
                    Code: '23'
                  },
                  {
                    Name: '聂荣县',
                    Code: '24'
                  },
                  {
                    Name: '安多县',
                    Code: '25'
                  },
                  {
                    Name: '申扎县',
                    Code: '26'
                  },
                  {
                    Name: '索县',
                    Code: '27'
                  },
                  {
                    Name: '班戈县',
                    Code: '28'
                  },
                  {
                    Name: '巴青县',
                    Code: '29'
                  },
                  {
                    Name: '尼玛县',
                    Code: '30'
                  }
                ]
              },
              {
                Name: '阿里',
                Code: '25',
                Region: [
                  {
                    Name: '普兰县',
                    Code: '21'
                  },
                  {
                    Name: '札达县',
                    Code: '22'
                  },
                  {
                    Name: '噶尔县',
                    Code: '23'
                  },
                  {
                    Name: '日土县',
                    Code: '24'
                  },
                  {
                    Name: '革吉县',
                    Code: '25'
                  },
                  {
                    Name: '改则县',
                    Code: '26'
                  },
                  {
                    Name: '措勤县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '林芝',
                Code: '26',
                Region: [
                  {
                    Name: '林芝县',
                    Code: '21'
                  },
                  {
                    Name: '工布江达县',
                    Code: '22'
                  },
                  {
                    Name: '米林县',
                    Code: '23'
                  },
                  {
                    Name: '墨脱县',
                    Code: '24'
                  },
                  {
                    Name: '波密县',
                    Code: '25'
                  },
                  {
                    Name: '察隅县',
                    Code: '26'
                  },
                  {
                    Name: '朗县',
                    Code: '27'
                  }
                ]
              }
            ]
          },
          {
            Name: '陕西',
            Code: '61',
            City: [
              {
                Name: '西安',
                Code: '1',
                Region: [
                  {
                    Name: '新城区',
                    Code: '2'
                  },
                  {
                    Name: '碑林区',
                    Code: '3'
                  },
                  {
                    Name: '莲湖区',
                    Code: '4'
                  },
                  {
                    Name: '灞桥区',
                    Code: '11'
                  },
                  {
                    Name: '未央区',
                    Code: '12'
                  },
                  {
                    Name: '雁塔区',
                    Code: '13'
                  },
                  {
                    Name: '阎良区',
                    Code: '14'
                  },
                  {
                    Name: '临潼区',
                    Code: '15'
                  },
                  {
                    Name: '长安区',
                    Code: '16'
                  },
                  {
                    Name: '蓝田县',
                    Code: '22'
                  },
                  {
                    Name: '周至县',
                    Code: '24'
                  },
                  {
                    Name: '户县',
                    Code: '25'
                  },
                  {
                    Name: '高陵县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '铜川',
                Code: '2',
                Region: [
                  {
                    Name: '王益区',
                    Code: '2'
                  },
                  {
                    Name: '印台区',
                    Code: '3'
                  },
                  {
                    Name: '耀州区',
                    Code: '4'
                  },
                  {
                    Name: '宜君县',
                    Code: '22'
                  }
                ]
              },
              {
                Name: '宝鸡',
                Code: '3',
                Region: [
                  {
                    Name: '渭滨区',
                    Code: '2'
                  },
                  {
                    Name: '金台区',
                    Code: '3'
                  },
                  {
                    Name: '陈仓区',
                    Code: '4'
                  },
                  {
                    Name: '凤翔县',
                    Code: '22'
                  },
                  {
                    Name: '岐山县',
                    Code: '23'
                  },
                  {
                    Name: '扶风县',
                    Code: '24'
                  },
                  {
                    Name: '眉县',
                    Code: '26'
                  },
                  {
                    Name: '陇县',
                    Code: '27'
                  },
                  {
                    Name: '千阳县',
                    Code: '28'
                  },
                  {
                    Name: '麟游县',
                    Code: '29'
                  },
                  {
                    Name: '凤县',
                    Code: '30'
                  },
                  {
                    Name: '太白县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '咸阳',
                Code: '4',
                Region: [
                  {
                    Name: '秦都区',
                    Code: '2'
                  },
                  {
                    Name: '杨凌区',
                    Code: '3'
                  },
                  {
                    Name: '渭城区',
                    Code: '4'
                  },
                  {
                    Name: '三原县',
                    Code: '22'
                  },
                  {
                    Name: '泾阳县',
                    Code: '23'
                  },
                  {
                    Name: '乾县',
                    Code: '24'
                  },
                  {
                    Name: '礼泉县',
                    Code: '25'
                  },
                  {
                    Name: '永寿县',
                    Code: '26'
                  },
                  {
                    Name: '彬县',
                    Code: '27'
                  },
                  {
                    Name: '长武县',
                    Code: '28'
                  },
                  {
                    Name: '旬邑县',
                    Code: '29'
                  },
                  {
                    Name: '淳化县',
                    Code: '30'
                  },
                  {
                    Name: '武功县',
                    Code: '31'
                  },
                  {
                    Name: '兴平市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '渭南',
                Code: '5',
                Region: [
                  {
                    Name: '临渭区',
                    Code: '2'
                  },
                  {
                    Name: '华县',
                    Code: '21'
                  },
                  {
                    Name: '潼关县',
                    Code: '22'
                  },
                  {
                    Name: '大荔县',
                    Code: '23'
                  },
                  {
                    Name: '合阳县',
                    Code: '24'
                  },
                  {
                    Name: '澄城县',
                    Code: '25'
                  },
                  {
                    Name: '蒲城县',
                    Code: '26'
                  },
                  {
                    Name: '白水县',
                    Code: '27'
                  },
                  {
                    Name: '富平县',
                    Code: '28'
                  },
                  {
                    Name: '韩城市',
                    Code: '81'
                  },
                  {
                    Name: '华阴市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '延安',
                Code: '6',
                Region: [
                  {
                    Name: '宝塔区',
                    Code: '2'
                  },
                  {
                    Name: '延长县',
                    Code: '21'
                  },
                  {
                    Name: '延川县',
                    Code: '22'
                  },
                  {
                    Name: '子长县',
                    Code: '23'
                  },
                  {
                    Name: '安塞县',
                    Code: '24'
                  },
                  {
                    Name: '志丹县',
                    Code: '25'
                  },
                  {
                    Name: '吴起县',
                    Code: '26'
                  },
                  {
                    Name: '甘泉县',
                    Code: '27'
                  },
                  {
                    Name: '富县',
                    Code: '28'
                  },
                  {
                    Name: '洛川县',
                    Code: '29'
                  },
                  {
                    Name: '宜川县',
                    Code: '30'
                  },
                  {
                    Name: '黄龙县',
                    Code: '31'
                  },
                  {
                    Name: '黄陵县',
                    Code: '32'
                  }
                ]
              },
              {
                Name: '汉中',
                Code: '7',
                Region: [
                  {
                    Name: '汉台区',
                    Code: '2'
                  },
                  {
                    Name: '南郑县',
                    Code: '21'
                  },
                  {
                    Name: '城固县',
                    Code: '22'
                  },
                  {
                    Name: '洋县',
                    Code: '23'
                  },
                  {
                    Name: '西乡县',
                    Code: '24'
                  },
                  {
                    Name: '勉县',
                    Code: '25'
                  },
                  {
                    Name: '宁强县',
                    Code: '26'
                  },
                  {
                    Name: '略阳县',
                    Code: '27'
                  },
                  {
                    Name: '镇巴县',
                    Code: '28'
                  },
                  {
                    Name: '留坝县',
                    Code: '29'
                  },
                  {
                    Name: '佛坪县',
                    Code: '30'
                  }
                ]
              },
              {
                Name: '榆林',
                Code: '8',
                Region: [
                  {
                    Name: '榆阳区',
                    Code: '2'
                  },
                  {
                    Name: '神木县',
                    Code: '21'
                  },
                  {
                    Name: '府谷县',
                    Code: '22'
                  },
                  {
                    Name: '横山县',
                    Code: '23'
                  },
                  {
                    Name: '靖边县',
                    Code: '24'
                  },
                  {
                    Name: '定边县',
                    Code: '25'
                  },
                  {
                    Name: '绥德县',
                    Code: '26'
                  },
                  {
                    Name: '米脂县',
                    Code: '27'
                  },
                  {
                    Name: '佳县',
                    Code: '28'
                  },
                  {
                    Name: '吴堡县',
                    Code: '29'
                  },
                  {
                    Name: '清涧县',
                    Code: '30'
                  },
                  {
                    Name: '子洲县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '安康',
                Code: '9',
                Region: [
                  {
                    Name: '汉滨区',
                    Code: '2'
                  },
                  {
                    Name: '汉阴县',
                    Code: '21'
                  },
                  {
                    Name: '石泉县',
                    Code: '22'
                  },
                  {
                    Name: '宁陕县',
                    Code: '23'
                  },
                  {
                    Name: '紫阳县',
                    Code: '24'
                  },
                  {
                    Name: '岚皋县',
                    Code: '25'
                  },
                  {
                    Name: '平利县',
                    Code: '26'
                  },
                  {
                    Name: '镇坪县',
                    Code: '27'
                  },
                  {
                    Name: '旬阳县',
                    Code: '28'
                  },
                  {
                    Name: '白河县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '商洛',
                Code: '10',
                Region: [
                  {
                    Name: '商州区',
                    Code: '2'
                  },
                  {
                    Name: '洛南县',
                    Code: '21'
                  },
                  {
                    Name: '丹凤县',
                    Code: '22'
                  },
                  {
                    Name: '商南县',
                    Code: '23'
                  },
                  {
                    Name: '山阳县',
                    Code: '24'
                  },
                  {
                    Name: '镇安县',
                    Code: '25'
                  },
                  {
                    Name: '柞水县',
                    Code: '26'
                  }
                ]
              }
            ]
          },
          {
            Name: '甘肃',
            Code: '62',
            City: [
              {
                Name: '兰州市',
                Code: '1',
                Region: [
                  {
                    Name: '城关区',
                    Code: '2'
                  },
                  {
                    Name: '七里河区',
                    Code: '3'
                  },
                  {
                    Name: '西固区',
                    Code: '4'
                  },
                  {
                    Name: '安宁区',
                    Code: '5'
                  },
                  {
                    Name: '红古区',
                    Code: '11'
                  },
                  {
                    Name: '永登县',
                    Code: '21'
                  },
                  {
                    Name: '皋兰县',
                    Code: '22'
                  },
                  {
                    Name: '榆中县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '嘉峪关',
                Code: '2',
                Region: {
                  Name: '嘉峪关市',
                  Code: '0'
                }
              },
              {
                Name: '金昌',
                Code: '3',
                Region: [
                  {
                    Name: '金川区',
                    Code: '2'
                  },
                  {
                    Name: '永昌县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '白银',
                Code: '4',
                Region: [
                  {
                    Name: '白银区',
                    Code: '2'
                  },
                  {
                    Name: '平川区',
                    Code: '3'
                  },
                  {
                    Name: '靖远县',
                    Code: '21'
                  },
                  {
                    Name: '会宁县',
                    Code: '22'
                  },
                  {
                    Name: '景泰县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '天水',
                Code: '5',
                Region: [
                  {
                    Name: '秦州区',
                    Code: '2'
                  },
                  {
                    Name: '麦积区',
                    Code: '3'
                  },
                  {
                    Name: '清水县',
                    Code: '21'
                  },
                  {
                    Name: '秦安县',
                    Code: '22'
                  },
                  {
                    Name: '甘谷县',
                    Code: '23'
                  },
                  {
                    Name: '武山县',
                    Code: '24'
                  },
                  {
                    Name: '张家川回族自治县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '武威',
                Code: '6',
                Region: [
                  {
                    Name: '凉州区',
                    Code: '2'
                  },
                  {
                    Name: '民勤县',
                    Code: '21'
                  },
                  {
                    Name: '古浪县',
                    Code: '22'
                  },
                  {
                    Name: '天祝藏族自治县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '张掖',
                Code: '7',
                Region: [
                  {
                    Name: '甘州区',
                    Code: '2'
                  },
                  {
                    Name: '肃南裕固族自治县',
                    Code: '21'
                  },
                  {
                    Name: '民乐县',
                    Code: '22'
                  },
                  {
                    Name: '临泽县',
                    Code: '23'
                  },
                  {
                    Name: '高台县',
                    Code: '24'
                  },
                  {
                    Name: '山丹县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '平凉',
                Code: '8',
                Region: [
                  {
                    Name: '崆峒区',
                    Code: '2'
                  },
                  {
                    Name: '泾川县',
                    Code: '21'
                  },
                  {
                    Name: '灵台县',
                    Code: '22'
                  },
                  {
                    Name: '崇信县',
                    Code: '23'
                  },
                  {
                    Name: '华亭县',
                    Code: '24'
                  },
                  {
                    Name: '庄浪县',
                    Code: '25'
                  },
                  {
                    Name: '静宁县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '酒泉',
                Code: '9',
                Region: [
                  {
                    Name: '肃州区',
                    Code: '2'
                  },
                  {
                    Name: '金塔县',
                    Code: '21'
                  },
                  {
                    Name: '瓜州县',
                    Code: '22'
                  },
                  {
                    Name: '肃北蒙古族自治县',
                    Code: '23'
                  },
                  {
                    Name: '阿克塞哈萨克族自治县',
                    Code: '24'
                  },
                  {
                    Name: '玉门市',
                    Code: '81'
                  },
                  {
                    Name: '敦煌市',
                    Code: '82'
                  }
                ]
              },
              {
                Name: '庆阳',
                Code: '10',
                Region: [
                  {
                    Name: '西峰区',
                    Code: '2'
                  },
                  {
                    Name: '庆城县',
                    Code: '21'
                  },
                  {
                    Name: '环县',
                    Code: '22'
                  },
                  {
                    Name: '华池县',
                    Code: '23'
                  },
                  {
                    Name: '合水县',
                    Code: '24'
                  },
                  {
                    Name: '正宁县',
                    Code: '25'
                  },
                  {
                    Name: '宁县',
                    Code: '26'
                  },
                  {
                    Name: '镇原县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '定西',
                Code: '11',
                Region: [
                  {
                    Name: '安定区',
                    Code: '2'
                  },
                  {
                    Name: '通渭县',
                    Code: '21'
                  },
                  {
                    Name: '陇西县',
                    Code: '22'
                  },
                  {
                    Name: '渭源县',
                    Code: '23'
                  },
                  {
                    Name: '临洮县',
                    Code: '24'
                  },
                  {
                    Name: '漳县',
                    Code: '25'
                  },
                  {
                    Name: '岷县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '陇南',
                Code: '12',
                Region: [
                  {
                    Name: '武都区',
                    Code: '2'
                  },
                  {
                    Name: '成县',
                    Code: '21'
                  },
                  {
                    Name: '文县',
                    Code: '22'
                  },
                  {
                    Name: '宕昌县',
                    Code: '23'
                  },
                  {
                    Name: '康县',
                    Code: '24'
                  },
                  {
                    Name: '西和县',
                    Code: '25'
                  },
                  {
                    Name: '礼县',
                    Code: '26'
                  },
                  {
                    Name: '徽县',
                    Code: '27'
                  },
                  {
                    Name: '两当县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '临夏',
                Code: '29',
                Region: [
                  {
                    Name: '临夏市',
                    Code: '1'
                  },
                  {
                    Name: '临夏县',
                    Code: '21'
                  },
                  {
                    Name: '康乐县',
                    Code: '22'
                  },
                  {
                    Name: '永靖县',
                    Code: '23'
                  },
                  {
                    Name: '广河县',
                    Code: '24'
                  },
                  {
                    Name: '和政县',
                    Code: '25'
                  },
                  {
                    Name: '东乡族自治县',
                    Code: '26'
                  },
                  {
                    Name: '积石山保安族东乡族撒拉族自治县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '甘南',
                Code: '30',
                Region: [
                  {
                    Name: '合作市',
                    Code: '1'
                  },
                  {
                    Name: '临潭县',
                    Code: '21'
                  },
                  {
                    Name: '卓尼县',
                    Code: '22'
                  },
                  {
                    Name: '舟曲县',
                    Code: '23'
                  },
                  {
                    Name: '迭部县',
                    Code: '24'
                  },
                  {
                    Name: '玛曲县',
                    Code: '25'
                  },
                  {
                    Name: '碌曲县',
                    Code: '26'
                  },
                  {
                    Name: '夏河县',
                    Code: '27'
                  }
                ]
              }
            ]
          },
          {
            Name: '青海',
            Code: '63',
            City: [
              {
                Name: '西宁',
                Code: '1',
                Region: [
                  {
                    Name: '城东区',
                    Code: '2'
                  },
                  {
                    Name: '城中区',
                    Code: '3'
                  },
                  {
                    Name: '城西区',
                    Code: '4'
                  },
                  {
                    Name: '城北区',
                    Code: '5'
                  },
                  {
                    Name: '大通回族土族自治县',
                    Code: '21'
                  },
                  {
                    Name: '湟中县',
                    Code: '22'
                  },
                  {
                    Name: '湟源县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '海东',
                Code: '21',
                Region: [
                  {
                    Name: '平安县',
                    Code: '21'
                  },
                  {
                    Name: '民和回族土族自治县',
                    Code: '22'
                  },
                  {
                    Name: '乐都县',
                    Code: '23'
                  },
                  {
                    Name: '互助土族自治县',
                    Code: '26'
                  },
                  {
                    Name: '化隆回族自治县',
                    Code: '27'
                  },
                  {
                    Name: '循化撒拉族自治县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '海北',
                Code: '22',
                Region: [
                  {
                    Name: '门源回族自治县',
                    Code: '21'
                  },
                  {
                    Name: '祁连县',
                    Code: '22'
                  },
                  {
                    Name: '海晏县',
                    Code: '23'
                  },
                  {
                    Name: '刚察县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '黄南',
                Code: '23',
                Region: [
                  {
                    Name: '同仁县',
                    Code: '21'
                  },
                  {
                    Name: '尖扎县',
                    Code: '22'
                  },
                  {
                    Name: '泽库县',
                    Code: '23'
                  },
                  {
                    Name: '河南蒙古族自治县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '海南',
                Code: '25',
                Region: [
                  {
                    Name: '共和县',
                    Code: '21'
                  },
                  {
                    Name: '同德县',
                    Code: '22'
                  },
                  {
                    Name: '贵德县',
                    Code: '23'
                  },
                  {
                    Name: '兴海县',
                    Code: '24'
                  },
                  {
                    Name: '贵南县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '果洛',
                Code: '26',
                Region: [
                  {
                    Name: '玛沁县',
                    Code: '21'
                  },
                  {
                    Name: '班玛县',
                    Code: '22'
                  },
                  {
                    Name: '甘德县',
                    Code: '23'
                  },
                  {
                    Name: '达日县',
                    Code: '24'
                  },
                  {
                    Name: '久治县',
                    Code: '25'
                  },
                  {
                    Name: '玛多县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '玉树',
                Code: '27',
                Region: [
                  {
                    Name: '玉树县',
                    Code: '21'
                  },
                  {
                    Name: '杂多县',
                    Code: '22'
                  },
                  {
                    Name: '称多县',
                    Code: '23'
                  },
                  {
                    Name: '治多县',
                    Code: '24'
                  },
                  {
                    Name: '囊谦县',
                    Code: '25'
                  },
                  {
                    Name: '曲麻莱县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '海西',
                Code: '28',
                Region: [
                  {
                    Name: '格尔木市',
                    Code: '1'
                  },
                  {
                    Name: '德令哈市',
                    Code: '2'
                  },
                  {
                    Name: '乌兰县',
                    Code: '21'
                  },
                  {
                    Name: '都兰县',
                    Code: '22'
                  },
                  {
                    Name: '天峻县',
                    Code: '23'
                  }
                ]
              }
            ]
          },
          {
            Name: '宁夏',
            Code: '64',
            City: [
              {
                Name: '银川',
                Code: '1',
                Region: [
                  {
                    Name: '兴庆区',
                    Code: '4'
                  },
                  {
                    Name: '西夏区',
                    Code: '5'
                  },
                  {
                    Name: '金凤区',
                    Code: '6'
                  },
                  {
                    Name: '永宁县',
                    Code: '21'
                  },
                  {
                    Name: '贺兰县',
                    Code: '22'
                  },
                  {
                    Name: '灵武市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '石嘴山',
                Code: '2',
                Region: [
                  {
                    Name: '大武口区',
                    Code: '2'
                  },
                  {
                    Name: '惠农区',
                    Code: '5'
                  },
                  {
                    Name: '平罗县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '吴忠',
                Code: '3',
                Region: [
                  {
                    Name: '利通区',
                    Code: '2'
                  },
                  {
                    Name: '红寺堡区',
                    Code: '3'
                  },
                  {
                    Name: '盐池县',
                    Code: '23'
                  },
                  {
                    Name: '同心县',
                    Code: '24'
                  },
                  {
                    Name: '青铜峡市',
                    Code: '81'
                  }
                ]
              },
              {
                Name: '固原',
                Code: '4',
                Region: [
                  {
                    Name: '原州区',
                    Code: '2'
                  },
                  {
                    Name: '西吉县',
                    Code: '22'
                  },
                  {
                    Name: '隆德县',
                    Code: '23'
                  },
                  {
                    Name: '泾源县',
                    Code: '24'
                  },
                  {
                    Name: '彭阳县',
                    Code: '25'
                  }
                ]
              },
              {
                Name: '中卫',
                Code: '5',
                Region: [
                  {
                    Name: '沙坡头区',
                    Code: '2'
                  },
                  {
                    Name: '中宁县',
                    Code: '21'
                  },
                  {
                    Name: '海原县',
                    Code: '22'
                  }
                ]
              }
            ]
          },
          {
            Name: '新疆',
            Code: '65',
            City: [
              {
                Name: '乌鲁木齐',
                Code: '1',
                Region: [
                  {
                    Name: '天山区',
                    Code: '2'
                  },
                  {
                    Name: '沙依巴克区',
                    Code: '3'
                  },
                  {
                    Name: '新市区',
                    Code: '4'
                  },
                  {
                    Name: '水磨沟区',
                    Code: '5'
                  },
                  {
                    Name: '头屯河区',
                    Code: '6'
                  },
                  {
                    Name: '达坂城区',
                    Code: '7'
                  },
                  {
                    Name: '米东区',
                    Code: '9'
                  },
                  {
                    Name: '乌鲁木齐县',
                    Code: '21'
                  }
                ]
              },
              {
                Name: '克拉玛依',
                Code: '2',
                Region: [
                  {
                    Name: '独山子区',
                    Code: '2'
                  },
                  {
                    Name: '克拉玛依区',
                    Code: '3'
                  },
                  {
                    Name: '白碱滩区',
                    Code: '4'
                  },
                  {
                    Name: '乌尔禾区',
                    Code: '5'
                  }
                ]
              },
              {
                Name: '吐鲁番',
                Code: '21',
                Region: [
                  {
                    Name: '吐鲁番市',
                    Code: '1'
                  },
                  {
                    Name: '鄯善县',
                    Code: '22'
                  },
                  {
                    Name: '托克逊县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '哈密',
                Code: '22',
                Region: [
                  {
                    Name: '哈密市',
                    Code: '1'
                  },
                  {
                    Name: '巴里坤哈萨克自治县',
                    Code: '22'
                  },
                  {
                    Name: '伊吾县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '昌吉',
                Code: '23',
                Region: [
                  {
                    Name: '昌吉市',
                    Code: '1'
                  },
                  {
                    Name: '阜康市',
                    Code: '2'
                  },
                  {
                    Name: '呼图壁县',
                    Code: '23'
                  },
                  {
                    Name: '玛纳斯县',
                    Code: '24'
                  },
                  {
                    Name: '奇台县',
                    Code: '25'
                  },
                  {
                    Name: '吉木萨尔县',
                    Code: '27'
                  },
                  {
                    Name: '木垒哈萨克自治县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '博尔塔拉',
                Code: '27',
                Region: [
                  {
                    Name: '博乐市',
                    Code: '1'
                  },
                  {
                    Name: '精河县',
                    Code: '22'
                  },
                  {
                    Name: '温泉县',
                    Code: '23'
                  }
                ]
              },
              {
                Name: '巴音郭楞',
                Code: '28',
                Region: [
                  {
                    Name: '库尔勒市',
                    Code: '1'
                  },
                  {
                    Name: '轮台县',
                    Code: '22'
                  },
                  {
                    Name: '尉犁县',
                    Code: '23'
                  },
                  {
                    Name: '若羌县',
                    Code: '24'
                  },
                  {
                    Name: '且末县',
                    Code: '25'
                  },
                  {
                    Name: '焉耆回族自治县',
                    Code: '26'
                  },
                  {
                    Name: '和静县',
                    Code: '27'
                  },
                  {
                    Name: '和硕县',
                    Code: '28'
                  },
                  {
                    Name: '博湖县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '阿克苏',
                Code: '29',
                Region: [
                  {
                    Name: '阿克苏市',
                    Code: '1'
                  },
                  {
                    Name: '温宿县',
                    Code: '22'
                  },
                  {
                    Name: '库车县',
                    Code: '23'
                  },
                  {
                    Name: '沙雅县',
                    Code: '24'
                  },
                  {
                    Name: '新和县',
                    Code: '25'
                  },
                  {
                    Name: '拜城县',
                    Code: '26'
                  },
                  {
                    Name: '乌什县',
                    Code: '27'
                  },
                  {
                    Name: '阿瓦提县',
                    Code: '28'
                  },
                  {
                    Name: '柯坪县',
                    Code: '29'
                  }
                ]
              },
              {
                Name: '克孜勒苏',
                Code: '30',
                Region: [
                  {
                    Name: '阿图什市',
                    Code: '1'
                  },
                  {
                    Name: '阿克陶县',
                    Code: '22'
                  },
                  {
                    Name: '阿合奇县',
                    Code: '23'
                  },
                  {
                    Name: '乌恰县',
                    Code: '24'
                  }
                ]
              },
              {
                Name: '喀什',
                Code: '31',
                Region: [
                  {
                    Name: '喀什市',
                    Code: '1'
                  },
                  {
                    Name: '疏附县',
                    Code: '21'
                  },
                  {
                    Name: '疏勒县',
                    Code: '22'
                  },
                  {
                    Name: '英吉沙县',
                    Code: '23'
                  },
                  {
                    Name: '泽普县',
                    Code: '24'
                  },
                  {
                    Name: '莎车县',
                    Code: '25'
                  },
                  {
                    Name: '叶城县',
                    Code: '26'
                  },
                  {
                    Name: '麦盖提县',
                    Code: '27'
                  },
                  {
                    Name: '岳普湖县',
                    Code: '28'
                  },
                  {
                    Name: '伽师县',
                    Code: '29'
                  },
                  {
                    Name: '巴楚县',
                    Code: '30'
                  },
                  {
                    Name: '塔什库尔干塔吉克自治县',
                    Code: '31'
                  }
                ]
              },
              {
                Name: '和田',
                Code: '32',
                Region: [
                  {
                    Name: '和田市',
                    Code: '1'
                  },
                  {
                    Name: '和田县',
                    Code: '21'
                  },
                  {
                    Name: '墨玉县',
                    Code: '22'
                  },
                  {
                    Name: '皮山县',
                    Code: '23'
                  },
                  {
                    Name: '洛浦县',
                    Code: '24'
                  },
                  {
                    Name: '策勒县',
                    Code: '25'
                  },
                  {
                    Name: '于田县',
                    Code: '26'
                  },
                  {
                    Name: '民丰县',
                    Code: '27'
                  }
                ]
              },
              {
                Name: '伊犁',
                Code: '40',
                Region: [
                  {
                    Name: '伊宁市',
                    Code: '2'
                  },
                  {
                    Name: '奎屯市',
                    Code: '3'
                  },
                  {
                    Name: '伊宁县',
                    Code: '21'
                  },
                  {
                    Name: '察布查尔锡伯自治县',
                    Code: '22'
                  },
                  {
                    Name: '霍城县',
                    Code: '23'
                  },
                  {
                    Name: '巩留县',
                    Code: '24'
                  },
                  {
                    Name: '新源县',
                    Code: '25'
                  },
                  {
                    Name: '昭苏县',
                    Code: '26'
                  },
                  {
                    Name: '特克斯县',
                    Code: '27'
                  },
                  {
                    Name: '尼勒克县',
                    Code: '28'
                  }
                ]
              },
              {
                Name: '塔城',
                Code: '42',
                Region: [
                  {
                    Name: '塔城市',
                    Code: '1'
                  },
                  {
                    Name: '乌苏市',
                    Code: '2'
                  },
                  {
                    Name: '额敏县',
                    Code: '21'
                  },
                  {
                    Name: '沙湾县',
                    Code: '23'
                  },
                  {
                    Name: '托里县',
                    Code: '24'
                  },
                  {
                    Name: '裕民县',
                    Code: '25'
                  },
                  {
                    Name: '和布克赛尔蒙古自治县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '阿勒泰',
                Code: '43',
                Region: [
                  {
                    Name: '阿勒泰市',
                    Code: '1'
                  },
                  {
                    Name: '布尔津县',
                    Code: '21'
                  },
                  {
                    Name: '富蕴县',
                    Code: '22'
                  },
                  {
                    Name: '福海县',
                    Code: '23'
                  },
                  {
                    Name: '哈巴河县',
                    Code: '24'
                  },
                  {
                    Name: '青河县',
                    Code: '25'
                  },
                  {
                    Name: '吉木乃县',
                    Code: '26'
                  }
                ]
              },
              {
                Name: '石河子',
                Code: '91'
              },
              {
                Name: '阿拉尔',
                Code: '92'
              },
              {
                Name: '图木舒克',
                Code: '93'
              },
              {
                Name: '五家渠',
                Code: '94'
              },
              {
                Name: '北屯',
                Code: '95'
              }
            ]
          },
          {
            Name: '台湾',
            Code: '71',
            City: [
              {
                Name: '台北市',
                Code: '1'
              },
              {
                Name: '高雄市',
                Code: '2'
              },
              {
                Name: '基隆市',
                Code: '3'
              },
              {
                Name: '台中市',
                Code: '4'
              },
              {
                Name: '台南市',
                Code: '5'
              },
              {
                Name: '新竹市',
                Code: '6'
              },
              {
                Name: '嘉义市',
                Code: '7'
              },
              {
                Name: '台北县',
                Code: '8'
              },
              {
                Name: '宜兰县',
                Code: '9'
              },
              {
                Name: '桃园县',
                Code: '10'
              },
              {
                Name: '新竹县',
                Code: '11'
              },
              {
                Name: '苗栗县',
                Code: '12'
              },
              {
                Name: '台中县',
                Code: '13'
              },
              {
                Name: '彰化县',
                Code: '14'
              },
              {
                Name: '南投县',
                Code: '15'
              },
              {
                Name: '云林县',
                Code: '16'
              },
              {
                Name: '嘉义县',
                Code: '17'
              },
              {
                Name: '台南县',
                Code: '18'
              },
              {
                Name: '高雄县',
                Code: '19'
              },
              {
                Name: '屏东县',
                Code: '20'
              },
              {
                Name: '台东县',
                Code: '22'
              },
              {
                Name: '花莲县',
                Code: '23'
              },
              {
                Name: '澎湖县',
                Code: '21'
              }
            ]
          },
          {
            Name: '香港',
            Code: '81',
            City: [
              {
                Name: '中西区',
                Code: 'HCW'
              },
              {
                Name: '东区',
                Code: 'HEA'
              },
              {
                Name: '九龙城区',
                Code: 'KKC'
              },
              {
                Name: '观塘区',
                Code: 'KKT'
              },
              {
                Name: '南区',
                Code: 'HSO'
              },
              {
                Name: '深水埗区',
                Code: 'KSS'
              },
              {
                Name: '黄大仙区',
                Code: 'KWT'
              },
              {
                Name: '湾仔区',
                Code: 'HWC'
              },
              {
                Name: '油尖旺区',
                Code: 'KYT'
              },
              {
                Name: '离岛区',
                Code: 'NIS'
              },
              {
                Name: '葵青区',
                Code: 'NKT'
              },
              {
                Name: '北区',
                Code: 'NNO'
              },
              {
                Name: '西贡区',
                Code: 'NSK'
              },
              {
                Name: '沙田区',
                Code: 'NST'
              },
              {
                Name: '屯门区',
                Code: 'NTM'
              },
              {
                Name: '大埔区',
                Code: 'NTP'
              },
              {
                Name: '荃湾区',
                Code: 'NTW'
              },
              {
                Name: '元朗区',
                Code: 'NYL'
              }
            ]
          },
          {
            Name: '澳门',
            Code: '82',
            City: [
              {
                Name: '花地玛堂区',
                Code: 'OLF'
              },
              {
                Name: '圣安多尼堂区',
                Code: 'ANT'
              },
              {
                Name: '大堂区',
                Code: 'CAT'
              },
              {
                Name: '望德堂区',
                Code: 'LAW'
              },
              {
                Name: '风顺堂区',
                Code: 'LAZ'
              },
              {
                Name: '氹仔',
                Code: 'TPA'
              },
              {
                Name: '路环',
                Code: 'CLN'
              }
            ]
          }
        ]
      },
      {
        Name: '阿尔巴尼亚',
        Code: 'ALB',
        State: {
          City: [
            {
              Name: '爱尔巴桑',
              Code: 'EL'
            },
            {
              Name: '迪勃拉',
              Code: 'DI'
            },
            {
              Name: '地拉那',
              Code: 'TR'
            },
            {
              Name: '都拉斯',
              Code: 'DR'
            },
            {
              Name: '发罗拉',
              Code: 'VL'
            },
            {
              Name: '费里',
              Code: 'FR'
            },
            {
              Name: '吉诺卡斯特',
              Code: 'GJ'
            },
            {
              Name: '科尔察',
              Code: 'KO'
            },
            {
              Name: '库克斯',
              Code: 'KU'
            },
            {
              Name: '莱什',
              Code: 'LE'
            },
            {
              Name: '培拉特',
              Code: 'BR'
            },
            {
              Name: '斯库台',
              Code: 'SH'
            }
          ]
        }
      },
      {
        Name: '阿尔及利亚',
        Code: 'DZA',
        State: {
          City: [
            {
              Name: '阿德拉尔',
              Code: 'ADR'
            },
            {
              Name: '阿尔及尔',
              Code: 'ALG'
            },
            {
              Name: '艾因·德夫拉',
              Code: 'ADE'
            },
            {
              Name: '艾因·蒂姆尚特',
              Code: 'ATE'
            },
            {
              Name: '安纳巴',
              Code: 'AAE'
            },
            {
              Name: '奥兰',
              Code: 'ORA'
            },
            {
              Name: '巴特纳',
              Code: 'BAT'
            },
            {
              Name: '贝贾亚',
              Code: 'BJA'
            },
            {
              Name: '贝沙尔',
              Code: 'BEC'
            },
            {
              Name: '贝伊德',
              Code: 'EBA'
            },
            {
              Name: '比斯克拉',
              Code: 'BIS'
            },
            {
              Name: '布尔吉·布阿雷里吉',
              Code: 'BOR'
            },
            {
              Name: '布利达',
              Code: 'BLI'
            },
            {
              Name: '布迈德斯',
              Code: 'BOU'
            },
            {
              Name: '布依拉',
              Code: 'BOA'
            },
            {
              Name: '蒂巴扎',
              Code: 'TIP'
            },
            {
              Name: '蒂斯姆西勒特',
              Code: 'TIS'
            },
            {
              Name: '盖尔达耶',
              Code: 'GHA'
            },
            {
              Name: '盖尔马',
              Code: 'GUE'
            },
            {
              Name: '罕西拉',
              Code: 'KHE'
            },
            {
              Name: '赫利赞',
              Code: 'REL'
            },
            {
              Name: '吉杰尔',
              Code: 'JIJ'
            },
            {
              Name: '杰勒法',
              Code: 'DJE'
            },
            {
              Name: '君士坦丁',
              Code: 'CZL'
            },
            {
              Name: '拉格瓦特',
              Code: 'LAG'
            },
            {
              Name: '马斯卡拉',
              Code: 'MUA'
            },
            {
              Name: '麦迪亚',
              Code: 'MED'
            },
            {
              Name: '密拉',
              Code: 'MIL'
            },
            {
              Name: '莫斯塔加纳姆',
              Code: 'MOS'
            },
            {
              Name: '姆西拉',
              Code: 'MSI'
            },
            {
              Name: '纳阿马',
              Code: 'NAA'
            },
            {
              Name: '塞蒂夫',
              Code: 'SET'
            },
            {
              Name: '赛伊达',
              Code: 'SAI'
            },
            {
              Name: '斯基克达',
              Code: 'SKI'
            },
            {
              Name: '苏克·阿赫拉斯',
              Code: 'SAH'
            },
            {
              Name: '塔里夫',
              Code: 'ETA'
            },
            {
              Name: '塔曼拉塞特',
              Code: 'TAM'
            },
            {
              Name: '特贝萨',
              Code: 'TEB'
            },
            {
              Name: '特莱姆森',
              Code: 'TLE'
            },
            {
              Name: '提济乌祖',
              Code: 'IOU'
            },
            {
              Name: '提亚雷特',
              Code: 'TIA'
            },
            {
              Name: '廷杜夫',
              Code: 'TIN'
            },
            {
              Name: '瓦德',
              Code: 'EOU'
            },
            {
              Name: '瓦尔格拉',
              Code: 'OUA'
            },
            {
              Name: '乌姆布阿基',
              Code: 'OEB'
            },
            {
              Name: '西迪贝勒阿贝斯',
              Code: 'SBA'
            },
            {
              Name: '谢里夫',
              Code: 'CHL'
            },
            {
              Name: '伊利齐',
              Code: 'ILL'
            }
          ]
        }
      },
      {
        Name: '阿富汗',
        Code: 'AFG',
        State: {
          City: [
            {
              Name: '赫拉特',
              Code: 'HEA'
            },
            {
              Name: '喀布尔',
              Code: 'KBL'
            },
            {
              Name: '坎大哈',
              Code: 'KDH'
            },
            {
              Name: '马扎里沙里夫',
              Code: 'MZR'
            }
          ]
        }
      },
      {
        Name: '阿根廷',
        Code: 'ARG',
        State: {
          City: [
            {
              Name: '巴拉那',
              Code: 'PRA'
            },
            {
              Name: '别德马',
              Code: 'VDM'
            },
            {
              Name: '波萨达斯',
              Code: 'PSS'
            },
            {
              Name: '布兰卡港',
              Code: 'BHI'
            },
            {
              Name: '布宜诺斯艾利斯',
              Code: 'BUE'
            },
            {
              Name: '福莫萨',
              Code: 'FMA'
            },
            {
              Name: '胡胡伊',
              Code: 'JUJ'
            },
            {
              Name: '卡塔马卡',
              Code: 'CTC'
            },
            {
              Name: '科尔多瓦',
              Code: 'COR'
            },
            {
              Name: '科连特斯',
              Code: 'CNQ'
            },
            {
              Name: '克劳斯城',
              Code: 'VLK'
            },
            {
              Name: '肯考迪娅',
              Code: 'COC'
            },
            {
              Name: '拉里奥哈',
              Code: 'IRJ'
            },
            {
              Name: '拉普拉塔',
              Code: 'LPG'
            },
            {
              Name: '雷西斯滕匹亚',
              Code: 'RES'
            },
            {
              Name: '里奥加耶戈斯',
              Code: 'RGL'
            },
            {
              Name: '里奥夸尔托',
              Code: 'RCU'
            },
            {
              Name: '里瓦达维亚海军准将城',
              Code: 'CRD'
            },
            {
              Name: '罗萨里奥',
              Code: 'ROS'
            },
            {
              Name: '罗森',
              Code: 'RWO'
            },
            {
              Name: '马德普拉塔',
              Code: 'MDQ'
            },
            {
              Name: '门多萨',
              Code: 'MDZ'
            },
            {
              Name: '内乌肯',
              Code: 'NQN'
            },
            {
              Name: '萨尔塔',
              Code: 'SLA'
            },
            {
              Name: '圣地亚哥-德尔埃斯特罗',
              Code: 'SDE'
            },
            {
              Name: '圣菲',
              Code: 'SFN'
            },
            {
              Name: '圣胡安',
              Code: 'UAQ'
            },
            {
              Name: '圣拉斐尔',
              Code: 'AFA'
            },
            {
              Name: '圣路易斯',
              Code: 'LUQ'
            },
            {
              Name: '圣罗莎',
              Code: 'RSA'
            },
            {
              Name: '圣米格尔-德图库曼',
              Code: 'SMC'
            },
            {
              Name: '圣尼古拉斯',
              Code: 'SNS'
            },
            {
              Name: '特雷利乌',
              Code: 'REL'
            },
            {
              Name: '乌斯怀亚',
              Code: 'USH'
            }
          ]
        }
      },
      {
        Name: '阿拉伯联合酋长国',
        Code: 'ARE',
        State: {
          City: [
            {
              Name: '阿布扎比',
              Code: 'AZ'
            },
            {
              Name: '艾因',
              Code: 'AL'
            },
            {
              Name: '迪拜',
              Code: 'DU'
            },
            {
              Name: '沙迦',
              Code: 'SH'
            }
          ]
        }
      },
      {
        Name: '阿鲁巴',
        Code: 'ABW'
      },
      {
        Name: '阿曼',
        Code: 'OMN',
        State: {
          City: [
            {
              Name: '巴提奈地区',
              Code: 'BA'
            },
            {
              Name: '达希莱地区',
              Code: 'ZA'
            },
            {
              Name: '东部地区',
              Code: 'SH'
            },
            {
              Name: '马斯喀特省',
              Code: 'MA'
            },
            {
              Name: '穆桑达姆省',
              Code: 'MU'
            },
            {
              Name: '内地地区',
              Code: 'DA'
            },
            {
              Name: '中部地区',
              Code: 'WU'
            },
            {
              Name: '佐法尔省',
              Code: 'ZU'
            }
          ]
        }
      },
      {
        Name: '阿塞拜疆',
        Code: 'AZE',
        State: {
          City: [
            {
              Name: '阿布歇隆',
              Code: 'ABS'
            },
            {
              Name: '哈奇马斯',
              Code: 'XAC'
            },
            {
              Name: '卡尔巴卡尔',
              Code: 'KAL'
            },
            {
              Name: '卡扎赫',
              Code: 'QAZ'
            },
            {
              Name: '连科兰',
              Code: 'LAN'
            },
            {
              Name: '密尔-卡拉巴赫',
              Code: 'MQA'
            },
            {
              Name: '穆甘-萨连',
              Code: 'MSA'
            },
            {
              Name: '纳戈尔诺－卡拉巴赫',
              Code: 'NQA'
            },
            {
              Name: '纳希切万',
              Code: 'NX'
            },
            {
              Name: '普利亚拉克斯',
              Code: 'PRI'
            },
            {
              Name: '舍基',
              Code: 'SA'
            },
            {
              Name: '苏姆盖特',
              Code: 'SMC'
            },
            {
              Name: '锡尔万',
              Code: 'SIR'
            },
            {
              Name: '占贾',
              Code: 'GA'
            }
          ]
        }
      },
      {
        Name: '阿森松岛',
        Code: 'ASC'
      },
      {
        Name: '埃及',
        Code: 'EGY',
        State: {
          City: [
            {
              Name: '阿斯旺',
              Code: 'ASW'
            },
            {
              Name: '古尔代盖',
              Code: 'GBY'
            },
            {
              Name: '开罗',
              Code: 'CAI'
            },
            {
              Name: '苏布拉开马',
              Code: 'SKH'
            },
            {
              Name: '亚历山大',
              Code: 'ALY'
            }
          ]
        }
      },
      {
        Name: '埃塞俄比亚',
        Code: 'ETH',
        State: {
          City: [
            {
              Name: '阿法尔',
              Code: 'AF'
            },
            {
              Name: '阿姆哈拉',
              Code: 'AH'
            },
            {
              Name: '奥罗米亚',
              Code: 'OR'
            },
            {
              Name: '宾香古尔',
              Code: 'BG'
            },
            {
              Name: '德雷达瓦',
              Code: 'DD'
            },
            {
              Name: '甘贝拉各族',
              Code: 'GB'
            },
            {
              Name: '哈勒里民族',
              Code: 'HR'
            },
            {
              Name: '南方各族',
              Code: 'SN'
            },
            {
              Name: '索马里',
              Code: 'SM'
            },
            {
              Name: '提格雷',
              Code: 'TG'
            },
            {
              Name: '亚的斯亚贝巴',
              Code: 'AA'
            }
          ]
        }
      },
      {
        Name: '爱尔兰',
        Code: 'IRL',
        State: {
          City: [
            {
              Name: '奥法利',
              Code: 'OF'
            },
            {
              Name: '蒂珀雷里',
              Code: 'TP'
            },
            {
              Name: '都柏林',
              Code: 'DB'
            },
            {
              Name: '多内加尔',
              Code: 'DG'
            },
            {
              Name: '戈尔韦',
              Code: 'GW'
            },
            {
              Name: '基尔代尔',
              Code: 'KD'
            },
            {
              Name: '基尔肯尼',
              Code: 'KK'
            },
            {
              Name: '卡范',
              Code: 'CV'
            },
            {
              Name: '卡洛',
              Code: 'CW'
            },
            {
              Name: '凯里',
              Code: 'KR'
            },
            {
              Name: '科克',
              Code: 'CK'
            },
            {
              Name: '克莱尔',
              Code: 'CL'
            },
            {
              Name: '朗福德',
              Code: 'LF'
            },
            {
              Name: '劳斯',
              Code: 'LT'
            },
            {
              Name: '崂斯',
              Code: 'LA'
            },
            {
              Name: '利默里克',
              Code: 'LM'
            },
            {
              Name: '利特里姆',
              Code: 'LR'
            },
            {
              Name: '罗斯康芒',
              Code: 'RC'
            },
            {
              Name: '梅奥',
              Code: 'MY'
            },
            {
              Name: '米斯',
              Code: 'MT'
            },
            {
              Name: '莫内根',
              Code: 'MG'
            },
            {
              Name: '斯莱戈',
              Code: 'SL'
            },
            {
              Name: '威克洛',
              Code: 'WK'
            },
            {
              Name: '韦克斯福德',
              Code: 'WX'
            },
            {
              Name: '沃特福德',
              Code: 'WF'
            },
            {
              Name: '西米斯',
              Code: 'WM'
            }
          ]
        }
      },
      {
        Name: '爱沙尼亚',
        Code: 'EST',
        State: {
          City: [
            {
              Name: '贝尔瓦',
              Code: '65'
            },
            {
              Name: '哈留',
              Code: '37'
            },
            {
              Name: '拉普拉',
              Code: '70'
            },
            {
              Name: '里亚内',
              Code: '57'
            },
            {
              Name: '帕尔努',
              Code: '67'
            },
            {
              Name: '萨雷',
              Code: '74'
            },
            {
              Name: '塔尔图',
              Code: '78'
            },
            {
              Name: '瓦尔加',
              Code: '82'
            },
            {
              Name: '维良地',
              Code: '84'
            },
            {
              Name: '维鲁',
              Code: '59'
            },
            {
              Name: '沃鲁',
              Code: '86'
            },
            {
              Name: '希尤',
              Code: '39'
            },
            {
              Name: '耶尔韦',
              Code: '51'
            },
            {
              Name: '耶盖瓦',
              Code: '49'
            },
            {
              Name: '依达－维鲁',
              Code: '44'
            }
          ]
        }
      },
      {
        Name: '安道尔',
        Code: 'AND',
        State: {
          City: [
            {
              Name: '安道尔城',
              Code: '7'
            },
            {
              Name: '奥尔迪诺',
              Code: '5'
            },
            {
              Name: '恩坎普',
              Code: '3'
            },
            {
              Name: '卡尼略',
              Code: '2'
            },
            {
              Name: '莱塞斯卡尔德－恩戈尔达',
              Code: '8'
            },
            {
              Name: '马萨纳',
              Code: '4'
            },
            {
              Name: '圣胡利娅－德洛里亚',
              Code: '6'
            }
          ]
        }
      },
      {
        Name: '安哥拉',
        Code: 'AGO',
        State: {
          City: [
            {
              Name: '北宽扎',
              Code: 'CNO'
            },
            {
              Name: '北隆达',
              Code: 'LNO'
            },
            {
              Name: '本戈',
              Code: 'BGO'
            },
            {
              Name: '本格拉',
              Code: 'BGU'
            },
            {
              Name: '比耶',
              Code: 'BIE'
            },
            {
              Name: '卡宾达',
              Code: 'CAB'
            },
            {
              Name: '库内内',
              Code: 'CNN'
            },
            {
              Name: '宽多库邦戈',
              Code: 'CCU'
            },
            {
              Name: '罗安达',
              Code: 'LUA'
            },
            {
              Name: '马兰热',
              Code: 'MAL'
            },
            {
              Name: '莫希科',
              Code: 'MOX'
            },
            {
              Name: '纳米贝',
              Code: 'NAM'
            },
            {
              Name: '南宽扎',
              Code: 'CUS'
            },
            {
              Name: '南隆达',
              Code: 'LSU'
            },
            {
              Name: '万博',
              Code: 'HUA'
            },
            {
              Name: '威拉',
              Code: 'HUI'
            },
            {
              Name: '威热',
              Code: 'UIG'
            },
            {
              Name: '扎伊尔',
              Code: 'ZAI'
            }
          ]
        }
      },
      {
        Name: '安圭拉',
        Code: 'AIA'
      },
      {
        Name: '安提瓜岛和巴布达',
        Code: 'ATG'
      },
      {
        Name: '澳大利亚',
        Code: 'AUS',
        State: [
          {
            Name: '北部地区',
            Code: 'NT',
            City: [
              {
                Name: '北帕默斯顿',
                Code: 'PAL'
              },
              {
                Name: '达尔文',
                Code: 'DRW'
              }
            ]
          },
          {
            Name: '堪培拉',
            Code: 'ACT',
            City: {
              Name: '堪培拉',
              Code: 'CBR'
            }
          },
          {
            Name: '昆士兰',
            Code: 'QLD',
            City: [
              {
                Name: '布里斯班',
                Code: 'BNE'
              },
              {
                Name: '黄金海岸',
                Code: 'OOL'
              },
              {
                Name: '凯恩斯',
                Code: 'CNS'
              },
              {
                Name: '日光海岸',
                Code: 'CUD'
              },
              {
                Name: '汤斯维尔',
                Code: 'TSV'
              },
              {
                Name: '图文巴',
                Code: 'TWB'
              }
            ]
          },
          {
            Name: '南澳大利亚',
            Code: 'SA',
            City: [
              {
                Name: '阿德莱德',
                Code: 'ADL'
              },
              {
                Name: '奥古斯塔港',
                Code: 'PUG'
              },
              {
                Name: '甘比亚山',
                Code: 'MGB'
              },
              {
                Name: '怀阿拉',
                Code: 'WAY'
              },
              {
                Name: '林肯港',
                Code: 'PLO'
              },
              {
                Name: '默里布里奇',
                Code: 'MYB'
              },
              {
                Name: '皮里港',
                Code: 'PPI'
              },
              {
                Name: '维克托港',
                Code: 'VHA'
              }
            ]
          },
          {
            Name: '塔斯马尼亚',
            Code: 'TAS',
            City: [
              {
                Name: '伯尼港',
                Code: 'BWT'
              },
              {
                Name: '德文波特',
                Code: 'DPO'
              },
              {
                Name: '霍巴特',
                Code: 'HBA'
              },
              {
                Name: '朗塞斯顿',
                Code: 'LST'
              }
            ]
          },
          {
            Name: '维多利亚',
            Code: 'VIC',
            City: [
              {
                Name: '吉朗',
                Code: 'GEX'
              },
              {
                Name: '墨尔本',
                Code: 'MEL'
              }
            ]
          },
          {
            Name: '西澳大利亚',
            Code: 'WA',
            City: [
              {
                Name: '奥尔巴尼',
                Code: 'ALH'
              },
              {
                Name: '班伯里',
                Code: 'BUY'
              },
              {
                Name: '弗里曼特尔港',
                Code: 'FRE'
              },
              {
                Name: '杰拉尔顿',
                Code: 'GET'
              },
              {
                Name: '卡尔古利',
                Code: 'KGI'
              },
              {
                Name: '曼哲拉',
                Code: 'MDU'
              },
              {
                Name: '珀斯',
                Code: 'PER'
              }
            ]
          },
          {
            Name: '新南威尔士',
            Code: 'NSW',
            City: [
              {
                Name: '纽卡斯尔',
                Code: 'NTL'
              },
              {
                Name: '伍伦贡',
                Code: 'WOL'
              },
              {
                Name: '悉尼',
                Code: 'HBS'
              }
            ]
          }
        ]
      },
      {
        Name: '奥地利',
        Code: 'AUT',
        State: {
          City: [
            {
              Name: '布尔根兰',
              Code: 'BUR'
            },
            {
              Name: '蒂罗尔',
              Code: 'TYR'
            },
            {
              Name: '福拉尔贝格',
              Code: 'VOR'
            },
            {
              Name: '克恩顿',
              Code: 'CAT'
            },
            {
              Name: '萨尔茨堡',
              Code: 'SZG'
            },
            {
              Name: '上奥地利',
              Code: 'UAU'
            },
            {
              Name: '施蒂利亚',
              Code: 'STY'
            },
            {
              Name: '维也纳',
              Code: 'VDD'
            },
            {
              Name: '下奥地利',
              Code: 'LAU'
            }
          ]
        }
      },
      {
        Name: '奥兰群岛',
        Code: 'ALA'
      },
      {
        Name: '巴巴多斯岛',
        Code: 'BRB'
      },
      {
        Name: '巴布亚新几内亚',
        Code: 'PNG',
        State: {
          City: [
            {
              Name: '北部',
              Code: 'NO'
            },
            {
              Name: '布干维尔',
              Code: 'BV'
            },
            {
              Name: '东部高地',
              Code: 'EH'
            },
            {
              Name: '东塞皮克',
              Code: 'ES'
            },
            {
              Name: '东新不列颠',
              Code: 'EB'
            },
            {
              Name: '恩加',
              Code: 'EN'
            },
            {
              Name: '海湾',
              Code: 'GU'
            },
            {
              Name: '马当',
              Code: 'MD'
            },
            {
              Name: '马努斯',
              Code: 'MN'
            },
            {
              Name: '米尔恩湾',
              Code: 'MB'
            },
            {
              Name: '莫尔兹比港',
              Code: 'NC'
            },
            {
              Name: '莫罗贝',
              Code: 'MR'
            },
            {
              Name: '南部高地',
              Code: 'SH'
            },
            {
              Name: '钦布',
              Code: 'SI'
            },
            {
              Name: '桑道恩',
              Code: 'SA'
            },
            {
              Name: '西部',
              Code: 'WE'
            },
            {
              Name: '西部高地',
              Code: 'WH'
            },
            {
              Name: '西新不列颠',
              Code: 'WB'
            },
            {
              Name: '新爱尔兰',
              Code: 'NI'
            }
          ]
        }
      },
      {
        Name: '巴哈马',
        Code: 'BHS'
      },
      {
        Name: '巴基斯坦',
        Code: 'PAK',
        State: {
          City: [
            {
              Name: '白沙瓦',
              Code: 'PEW'
            },
            {
              Name: '费萨拉巴德',
              Code: 'LYP'
            },
            {
              Name: '故吉软瓦拉',
              Code: 'GUJ'
            },
            {
              Name: '海德拉巴',
              Code: 'HDD'
            },
            {
              Name: '卡拉奇',
              Code: 'KCT'
            },
            {
              Name: '拉合尔',
              Code: 'LHE'
            },
            {
              Name: '拉瓦尔品第',
              Code: 'RWP'
            },
            {
              Name: '木尔坦',
              Code: 'MUX'
            },
            {
              Name: '伊斯兰堡',
              Code: 'ISB'
            }
          ]
        }
      },
      {
        Name: '巴拉圭',
        Code: 'PRY',
        State: {
          City: [
            {
              Name: '阿曼拜',
              Code: 'AM'
            },
            {
              Name: '阿耶斯总统省',
              Code: 'PH'
            },
            {
              Name: '巴拉瓜里',
              Code: 'PA'
            },
            {
              Name: '博克龙',
              Code: 'BO'
            },
            {
              Name: '瓜伊拉',
              Code: 'GU'
            },
            {
              Name: '卡瓜苏',
              Code: 'CG'
            },
            {
              Name: '卡嫩迪尤',
              Code: 'CN'
            },
            {
              Name: '卡萨帕',
              Code: 'CZ'
            },
            {
              Name: '康塞普西翁',
              Code: 'CC'
            },
            {
              Name: '科迪勒拉',
              Code: 'CD'
            },
            {
              Name: '米西奥内斯',
              Code: 'MI'
            },
            {
              Name: '涅恩布库',
              Code: 'NE'
            },
            {
              Name: '上巴拉圭',
              Code: 'AG'
            },
            {
              Name: '上巴拉那',
              Code: 'AN'
            },
            {
              Name: '圣佩德罗',
              Code: 'SP'
            },
            {
              Name: '亚松森特别区',
              Code: 'AS'
            },
            {
              Name: '伊塔普亚',
              Code: 'IT'
            },
            {
              Name: '中央',
              Code: 'CE'
            }
          ]
        }
      },
      {
        Name: '巴勒斯坦',
        Code: 'PSE',
        State: {
          City: [
            {
              Name: '加沙地带',
              Code: 'GZ'
            },
            {
              Name: '西岸',
              Code: 'WE'
            }
          ]
        }
      },
      {
        Name: '巴林',
        Code: 'BHR',
        State: {
          City: [
            {
              Name: '北部',
              Code: '5'
            },
            {
              Name: '哈德',
              Code: '1'
            },
            {
              Name: '哈马德',
              Code: '12'
            },
            {
              Name: '里法',
              Code: '9'
            },
            {
              Name: '麦纳麦',
              Code: '3'
            },
            {
              Name: '穆哈拉格',
              Code: '2'
            },
            {
              Name: '西部',
              Code: '10'
            },
            {
              Name: '伊萨城',
              Code: '8'
            },
            {
              Name: '中部',
              Code: '7'
            }
          ]
        }
      },
      {
        Name: '巴拿马',
        Code: 'PAN'
      },
      {
        Name: '巴西',
        Code: 'BRA',
        State: {
          City: [
            {
              Name: '阿克里',
              Code: 'AC'
            },
            {
              Name: '阿拉戈斯',
              Code: 'AL'
            },
            {
              Name: '阿马帕',
              Code: 'AP'
            },
            {
              Name: '巴拉那',
              Code: 'PR'
            },
            {
              Name: '巴西利亚',
              Code: 'BSB'
            },
            {
              Name: '巴伊亚',
              Code: 'BA'
            },
            {
              Name: '北里奥格兰德',
              Code: 'RN'
            },
            {
              Name: '伯南布哥',
              Code: 'PE'
            },
            {
              Name: '戈亚斯',
              Code: 'GO'
            },
            {
              Name: '朗多尼亚',
              Code: 'RO'
            },
            {
              Name: '里约热内卢',
              Code: 'RJ'
            },
            {
              Name: '罗赖马',
              Code: 'RR'
            },
            {
              Name: '马拉尼昂',
              Code: 'MA'
            },
            {
              Name: '马托格罗索',
              Code: 'MT'
            },
            {
              Name: '米纳斯吉拉斯',
              Code: 'MG'
            },
            {
              Name: '南里奥格兰德',
              Code: 'RS'
            },
            {
              Name: '南马托格罗索',
              Code: 'MS'
            },
            {
              Name: '帕拉',
              Code: 'PA'
            },
            {
              Name: '帕拉伊巴',
              Code: 'PB'
            },
            {
              Name: '皮奥伊',
              Code: 'PI'
            },
            {
              Name: '塞阿拉',
              Code: 'CE'
            },
            {
              Name: '塞尔希培',
              Code: 'SE'
            },
            {
              Name: '圣埃斯皮里图',
              Code: 'ES'
            },
            {
              Name: '圣保罗',
              Code: 'SP'
            },
            {
              Name: '圣卡塔琳娜',
              Code: 'SC'
            },
            {
              Name: '托坎廷斯',
              Code: 'TO'
            },
            {
              Name: '亚马孙',
              Code: 'AM'
            }
          ]
        }
      },
      {
        Name: '白俄罗斯',
        Code: 'BLR',
        State: {
          City: [
            {
              Name: '布列斯特',
              Code: 'BR'
            },
            {
              Name: '戈梅利',
              Code: 'HO'
            },
            {
              Name: '格罗德诺',
              Code: 'HR'
            },
            {
              Name: '明斯克市',
              Code: 'MI'
            },
            {
              Name: '莫吉廖夫',
              Code: 'MA'
            },
            {
              Name: '维捷布斯克',
              Code: 'VI'
            }
          ]
        }
      },
      {
        Name: '百慕大',
        Code: 'BMU'
      },
      {
        Name: '保加利亚',
        Code: 'BGR',
        State: {
          City: [
            {
              Name: '布尔加斯',
              Code: 'BOJ'
            },
            {
              Name: '卡斯科伏',
              Code: 'KHO'
            },
            {
              Name: '鲁塞',
              Code: 'ROU'
            },
            {
              Name: '洛维奇',
              Code: 'LVP'
            },
            {
              Name: '蒙塔纳',
              Code: 'OZA'
            },
            {
              Name: '普罗夫迪夫',
              Code: 'PDV'
            },
            {
              Name: '索非亚',
              Code: 'SOF'
            },
            {
              Name: '索非亚市',
              Code: 'GSO'
            },
            {
              Name: '瓦尔纳',
              Code: 'VAR'
            }
          ]
        }
      },
      {
        Name: '北马里亚纳群岛',
        Code: 'MNP'
      },
      {
        Name: '贝宁',
        Code: 'BEN',
        State: {
          City: [
            {
              Name: '阿黎博里',
              Code: 'AL'
            },
            {
              Name: '阿塔科拉',
              Code: 'AK'
            },
            {
              Name: '滨海',
              Code: 'LI'
            },
            {
              Name: '波希康市',
              Code: 'BOH'
            },
            {
              Name: '博尔古',
              Code: 'BO'
            },
            {
              Name: '大西洋',
              Code: 'AQ'
            },
            {
              Name: '高原',
              Code: 'PL'
            },
            {
              Name: '库福',
              Code: 'KO'
            },
            {
              Name: '莫诺',
              Code: 'MO'
            },
            {
              Name: '丘陵',
              Code: 'CO'
            },
            {
              Name: '韦梅',
              Code: 'OU'
            },
            {
              Name: '峡谷',
              Code: 'DO'
            },
            {
              Name: '祖',
              Code: 'ZO'
            }
          ]
        }
      },
      {
        Name: '比利时',
        Code: 'BEL',
        State: {
          City: [
            {
              Name: '埃诺',
              Code: 'WHT'
            },
            {
              Name: '安特卫普',
              Code: 'VAN'
            },
            {
              Name: '布拉班特-瓦隆',
              Code: 'WBR'
            },
            {
              Name: '布鲁塞尔',
              Code: 'BRU'
            },
            {
              Name: '东佛兰德',
              Code: 'VOV'
            },
            {
              Name: '佛兰芒-布拉班特',
              Code: 'VBR'
            },
            {
              Name: '列日',
              Code: 'WLG'
            },
            {
              Name: '林堡',
              Code: 'VLI'
            },
            {
              Name: '卢森堡',
              Code: 'WLX'
            },
            {
              Name: '那慕尔',
              Code: 'WNA'
            },
            {
              Name: '西佛兰德',
              Code: 'VWV'
            }
          ]
        }
      },
      {
        Name: '冰岛',
        Code: 'ISL'
      },
      {
        Name: '波多黎各',
        Code: 'PRI'
      },
      {
        Name: '波兰',
        Code: 'POL',
        State: {
          City: [
            {
              Name: '埃尔布隆格',
              Code: 'ELB'
            },
            {
              Name: '奥尔什丁',
              Code: 'OLS'
            },
            {
              Name: '奥斯特罗文卡',
              Code: 'OSS'
            },
            {
              Name: '比得哥什',
              Code: 'BZG'
            },
            {
              Name: '彼得库夫',
              Code: 'PIO'
            },
            {
              Name: '比托姆',
              Code: 'BYT'
            },
            {
              Name: '比亚瓦波德拉斯卡',
              Code: 'BAP'
            },
            {
              Name: '比亚维斯托克',
              Code: 'BIA'
            },
            {
              Name: '波莱',
              Code: 'OPO'
            },
            {
              Name: '波兹南',
              Code: 'POZ'
            },
            {
              Name: '达布罗瓦戈尼察',
              Code: 'DAB'
            },
            {
              Name: '大波兰地区戈茹夫',
              Code: 'GOW'
            },
            {
              Name: '弗罗茨瓦夫',
              Code: 'WRO'
            },
            {
              Name: '弗沃茨瓦韦克',
              Code: 'WLO'
            },
            {
              Name: '格但斯克',
              Code: 'GDN'
            },
            {
              Name: '格丁尼亚',
              Code: 'GDY'
            },
            {
              Name: '格利维采',
              Code: 'GWC'
            },
            {
              Name: '格鲁琼兹',
              Code: 'GRU'
            },
            {
              Name: '海乌姆',
              Code: 'CHO'
            },
            {
              Name: '华沙',
              Code: 'WAW'
            },
            {
              Name: '霍茹夫',
              Code: 'CHZ'
            },
            {
              Name: '卡利什',
              Code: 'KAL'
            },
            {
              Name: '卡托维兹',
              Code: 'KTW'
            },
            {
              Name: '凯尔采',
              Code: 'KLC'
            },
            {
              Name: '科宁',
              Code: 'KON'
            },
            {
              Name: '科沙林',
              Code: 'OSZ'
            },
            {
              Name: '克拉科夫',
              Code: 'KRK'
            },
            {
              Name: '克罗斯诺',
              Code: 'KRO'
            },
            {
              Name: '拉多姆',
              Code: 'RDM'
            },
            {
              Name: '莱格尼察',
              Code: 'LEG'
            },
            {
              Name: '莱什诺',
              Code: 'LEZ'
            },
            {
              Name: '卢布林',
              Code: 'LUL'
            },
            {
              Name: '鲁达',
              Code: 'RDS'
            },
            {
              Name: '罗兹',
              Code: 'LOD'
            },
            {
              Name: '绿山城',
              Code: 'IEG'
            },
            {
              Name: '米什洛维采',
              Code: 'MYL'
            },
            {
              Name: '皮瓦',
              Code: 'PIL'
            },
            {
              Name: '普热梅希尔',
              Code: 'PRZ'
            },
            {
              Name: '普沃茨克',
              Code: 'PLO'
            },
            {
              Name: '切哈努夫',
              Code: 'CIE'
            },
            {
              Name: '热舒夫',
              Code: 'RZE'
            },
            {
              Name: '什切青',
              Code: 'SZZ'
            },
            {
              Name: '斯凯尔涅维采',
              Code: 'SKI'
            },
            {
              Name: '斯武普斯克',
              Code: 'SLP'
            },
            {
              Name: '苏瓦乌基',
              Code: 'SWL'
            },
            {
              Name: '索波特',
              Code: 'SOP'
            },
            {
              Name: '索斯诺维茨',
              Code: 'SWC'
            },
            {
              Name: '塔尔努夫',
              Code: 'TAR'
            },
            {
              Name: '塔尔诺布热格',
              Code: 'QEP'
            },
            {
              Name: '特切',
              Code: 'TYY'
            },
            {
              Name: '托伦',
              Code: 'TOR'
            },
            {
              Name: '瓦乌布日赫',
              Code: 'WZH'
            },
            {
              Name: '沃姆扎',
              Code: 'QOY'
            },
            {
              Name: '希米亚诺维采',
              Code: 'SOW'
            },
            {
              Name: '希维诺乌伊希切',
              Code: 'SWI'
            },
            {
              Name: '希维托赫洛维采',
              Code: 'SWT'
            },
            {
              Name: '谢德尔采',
              Code: 'SDC'
            },
            {
              Name: '谢拉兹',
              Code: 'SIR'
            },
            {
              Name: '新松奇',
              Code: 'NOW'
            },
            {
              Name: '雅沃兹诺',
              Code: 'JAW'
            },
            {
              Name: '耶莱尼亚古拉',
              Code: 'JEG'
            },
            {
              Name: '扎布热',
              Code: 'ZAB'
            },
            {
              Name: '扎莫希奇',
              Code: 'ZAM'
            }
          ]
        }
      },
      {
        Name: '玻利维亚',
        Code: 'BOL',
        State: {
          City: [
            {
              Name: '奥尔托',
              Code: 'ALT'
            },
            {
              Name: '奥鲁罗',
              Code: 'ORU'
            },
            {
              Name: '贝尼',
              Code: 'BEN'
            },
            {
              Name: '波多西',
              Code: 'POI'
            },
            {
              Name: '基拉科洛',
              Code: 'QUI'
            },
            {
              Name: '科恰班巴',
              Code: 'CBB'
            },
            {
              Name: '拉巴斯',
              Code: 'LPB'
            },
            {
              Name: '潘多',
              Code: 'PAN'
            },
            {
              Name: '丘基萨卡',
              Code: 'CHU'
            },
            {
              Name: '萨卡巴',
              Code: 'SAC'
            },
            {
              Name: '圣克鲁斯',
              Code: 'SRZ'
            },
            {
              Name: '塔里哈',
              Code: 'TJA'
            }
          ]
        }
      },
      {
        Name: '波斯尼亚和黑塞哥维那',
        Code: 'BIH',
        State: {
          City: [
            {
              Name: '波萨维纳',
              Code: 'FPO'
            },
            {
              Name: '波斯尼亚－波德里涅',
              Code: 'FBP'
            },
            {
              Name: '多米斯拉夫格勒',
              Code: 'FTO'
            },
            {
              Name: '黑塞哥维那－涅雷特瓦',
              Code: 'FHN'
            },
            {
              Name: '萨拉热窝',
              Code: 'FSA'
            },
            {
              Name: '图兹拉－波德里涅',
              Code: 'FTU'
            },
            {
              Name: '乌纳－萨纳',
              Code: 'FUS'
            },
            {
              Name: '西波斯尼亚',
              Code: 'FHB'
            },
            {
              Name: '西黑塞哥维那',
              Code: 'FZH'
            },
            {
              Name: '泽尼察－多博伊',
              Code: 'FZE'
            },
            {
              Name: '中波斯尼亚',
              Code: 'FSB'
            }
          ]
        }
      },
      {
        Name: '博茨瓦纳',
        Code: 'BWA'
      },
      {
        Name: '伯利兹',
        Code: 'BLZ',
        State: {
          City: [
            {
              Name: '伯利兹',
              Code: 'BZ'
            },
            {
              Name: '橘园',
              Code: 'OW'
            },
            {
              Name: '卡约',
              Code: 'CY'
            },
            {
              Name: '科罗萨尔',
              Code: 'CR'
            },
            {
              Name: '斯坦港',
              Code: 'SC'
            },
            {
              Name: '托莱多',
              Code: 'TO'
            }
          ]
        }
      },
      {
        Name: '不丹',
        Code: 'BTN'
      },
      {
        Name: '布基纳法索',
        Code: 'BFA',
        State: {
          City: [
            {
              Name: '巴雷',
              Code: 'BAL'
            },
            {
              Name: '巴姆',
              Code: 'BAM'
            },
            {
              Name: '巴瓦',
              Code: 'BAN'
            },
            {
              Name: '巴泽加',
              Code: 'BAZ'
            },
            {
              Name: '波尼',
              Code: 'PON'
            },
            {
              Name: '布尔古',
              Code: 'BLG'
            },
            {
              Name: '布尔基恩德',
              Code: 'BOK'
            },
            {
              Name: '布古里巴',
              Code: 'BOR'
            },
            {
              Name: '冈祖尔古',
              Code: 'GAN'
            },
            {
              Name: '古尔马',
              Code: 'GOU'
            },
            {
              Name: '济罗',
              Code: 'ZIR'
            },
            {
              Name: '卡焦戈',
              Code: 'KAD'
            },
            {
              Name: '凯内杜古',
              Code: 'KEN'
            },
            {
              Name: '科蒙加里',
              Code: 'KOO'
            },
            {
              Name: '科莫埃',
              Code: 'COM'
            },
            {
              Name: '孔皮恩加',
              Code: 'KOP'
            },
            {
              Name: '孔西',
              Code: 'KOS'
            },
            {
              Name: '库尔佩罗戈',
              Code: 'KOL'
            },
            {
              Name: '库尔维奥戈',
              Code: 'KOW'
            },
            {
              Name: '库里滕加',
              Code: 'KOT'
            },
            {
              Name: '雷拉巴',
              Code: 'LER'
            },
            {
              Name: '罗卢姆',
              Code: 'LOR'
            },
            {
              Name: '穆翁',
              Code: 'MOU'
            },
            {
              Name: '纳门滕加',
              Code: 'NAM'
            },
            {
              Name: '纳乌里',
              Code: 'NAH'
            },
            {
              Name: '纳亚拉',
              Code: 'NAY'
            },
            {
              Name: '尼亚尼亚',
              Code: 'GNA'
            },
            {
              Name: '努姆比埃尔',
              Code: 'NOU'
            },
            {
              Name: '帕索雷',
              Code: 'PAS'
            },
            {
              Name: '塞诺',
              Code: 'SEN'
            },
            {
              Name: '桑吉',
              Code: 'SAG'
            },
            {
              Name: '桑马滕加',
              Code: 'SAM'
            },
            {
              Name: '苏鲁',
              Code: 'SOR'
            },
            {
              Name: '苏姆',
              Code: 'SOM'
            },
            {
              Name: '塔波阿',
              Code: 'TAP'
            },
            {
              Name: '图伊',
              Code: 'TUY'
            },
            {
              Name: '乌埃',
              Code: 'HOU'
            },
            {
              Name: '乌布里滕加',
              Code: 'OUB'
            },
            {
              Name: '乌达兰',
              Code: 'OUD'
            },
            {
              Name: '锡西里',
              Code: 'SIS'
            },
            {
              Name: '亚加',
              Code: 'YAG'
            },
            {
              Name: '亚滕加',
              Code: 'YAT'
            },
            {
              Name: '伊奥巴',
              Code: 'IOA'
            },
            {
              Name: '宗德韦奥戈',
              Code: 'ZOW'
            },
            {
              Name: '宗多马',
              Code: 'ZOD'
            }
          ]
        }
      },
      {
        Name: '布隆迪',
        Code: 'BDI',
        State: {
          City: [
            {
              Name: '布班扎',
              Code: 'BB'
            },
            {
              Name: '布鲁里',
              Code: 'BR'
            },
            {
              Name: '布琼布拉城市',
              Code: 'BM'
            },
            {
              Name: '布琼布拉乡村',
              Code: 'BU'
            },
            {
              Name: '恩戈齐',
              Code: 'NG'
            },
            {
              Name: '基龙多',
              Code: 'KI'
            },
            {
              Name: '基特加',
              Code: 'GI'
            },
            {
              Name: '卡鲁济',
              Code: 'KR'
            },
            {
              Name: '卡扬扎',
              Code: 'KY'
            },
            {
              Name: '坎库佐',
              Code: 'CA'
            },
            {
              Name: '鲁塔纳',
              Code: 'RT'
            },
            {
              Name: '鲁伊吉',
              Code: 'RY'
            },
            {
              Name: '马坎巴',
              Code: 'MA'
            },
            {
              Name: '穆拉姆维亚',
              Code: 'MU'
            },
            {
              Name: '穆瓦洛',
              Code: 'MW'
            },
            {
              Name: '穆因加',
              Code: 'MY'
            },
            {
              Name: '锡比托凯',
              Code: 'CI'
            }
          ]
        }
      },
      {
        Name: '布韦岛',
        Code: 'BVT'
      },
      {
        Name: '朝鲜',
        Code: 'PRK',
        State: {
          City: [
            {
              Name: '海州',
              Code: 'HAE'
            },
            {
              Name: '惠山',
              Code: 'HYE'
            },
            {
              Name: '江界',
              Code: 'KAN'
            },
            {
              Name: '开城',
              Code: 'KSN'
            },
            {
              Name: '罗先',
              Code: 'NAS'
            },
            {
              Name: '南浦',
              Code: 'NAM'
            },
            {
              Name: '平壤',
              Code: 'FNJ'
            },
            {
              Name: '清津',
              Code: 'CHO'
            },
            {
              Name: '沙里院',
              Code: 'SAR'
            },
            {
              Name: '咸兴',
              Code: 'HAM'
            },
            {
              Name: '新义州',
              Code: 'SII'
            },
            {
              Name: '元山',
              Code: 'WON'
            }
          ]
        }
      },
      {
        Name: '丹麦',
        Code: 'DNK',
        State: {
          City: [
            {
              Name: '奥胡斯',
              Code: 'AR'
            },
            {
              Name: '北日德兰',
              Code: 'VSV'
            },
            {
              Name: '博恩霍尔姆',
              Code: 'BO'
            },
            {
              Name: '菲特烈堡',
              Code: 'FRE'
            },
            {
              Name: '菲茵',
              Code: 'FY'
            },
            {
              Name: '哥本哈根',
              Code: 'CPH'
            },
            {
              Name: '里伯',
              Code: 'RIB'
            },
            {
              Name: '灵克宾',
              Code: 'RKG'
            },
            {
              Name: '罗斯基勒',
              Code: 'RKE'
            },
            {
              Name: '南日德兰',
              Code: 'VBI'
            },
            {
              Name: '斯多斯特姆',
              Code: 'ST'
            },
            {
              Name: '维堡',
              Code: 'VIB'
            },
            {
              Name: '维厄勒',
              Code: 'VEJ'
            },
            {
              Name: '西希兰',
              Code: 'VS'
            }
          ]
        }
      },
      {
        Name: '德国',
        Code: 'DEU',
        State: {
          City: [
            {
              Name: '阿恩斯贝格',
              Code: 'ARN'
            },
            {
              Name: '爱尔福特',
              Code: 'ERF'
            },
            {
              Name: '安斯巴格',
              Code: 'ANS'
            },
            {
              Name: '奥格斯堡',
              Code: 'AGB'
            },
            {
              Name: '柏林',
              Code: 'BE'
            },
            {
              Name: '拜伊罗特',
              Code: 'BYU'
            },
            {
              Name: '比勒费尔德',
              Code: 'BFE'
            },
            {
              Name: '波茨坦',
              Code: 'POT'
            },
            {
              Name: '波鸿',
              Code: 'BOM'
            },
            {
              Name: '不来梅',
              Code: 'HB'
            },
            {
              Name: '不伦瑞克',
              Code: 'BRW'
            },
            {
              Name: '达姆施塔特',
              Code: 'DAR'
            },
            {
              Name: '代特莫尔特',
              Code: 'DET'
            },
            {
              Name: '德累斯顿',
              Code: 'DRS'
            },
            {
              Name: '德绍',
              Code: 'DES'
            },
            {
              Name: '杜塞尔多夫',
              Code: 'DUS'
            },
            {
              Name: '法兰克福',
              Code: 'FFO'
            },
            {
              Name: '弗赖堡',
              Code: 'FBG'
            },
            {
              Name: '哈雷',
              Code: 'HAE'
            },
            {
              Name: '汉堡',
              Code: 'HH'
            },
            {
              Name: '汉诺威',
              Code: 'HAJ'
            },
            {
              Name: '基尔',
              Code: 'KEL'
            },
            {
              Name: '吉森',
              Code: 'GBN'
            },
            {
              Name: '卡尔斯鲁厄',
              Code: 'KAE'
            },
            {
              Name: '卡塞尔',
              Code: 'KAS'
            },
            {
              Name: '开姆尼斯',
              Code: 'CHE'
            },
            {
              Name: '科布伦次',
              Code: 'KOB'
            },
            {
              Name: '科隆',
              Code: 'CGN'
            },
            {
              Name: '莱比锡',
              Code: 'LEJ'
            },
            {
              Name: '兰茨胡特',
              Code: 'LDH'
            },
            {
              Name: '吕讷堡',
              Code: 'LBG'
            },
            {
              Name: '马格德堡',
              Code: 'MAG'
            },
            {
              Name: '曼海姆',
              Code: 'MHG'
            },
            {
              Name: '美因兹',
              Code: 'MAI'
            },
            {
              Name: '明斯特',
              Code: 'MUN'
            },
            {
              Name: '慕尼黑',
              Code: 'MUC'
            },
            {
              Name: '纽伦堡',
              Code: 'NUE'
            },
            {
              Name: '什未林',
              Code: 'SWH'
            },
            {
              Name: '斯图加特',
              Code: 'STR'
            },
            {
              Name: '特里尔',
              Code: 'TRI'
            },
            {
              Name: '威斯巴登',
              Code: 'WIB'
            },
            {
              Name: '维尔茨堡',
              Code: 'WUG'
            }
          ]
        }
      },
      {
        Name: '东帝汶',
        Code: 'TLS',
        State: {
          City: [
            {
              Name: '阿伊莱乌',
              Code: 'AL'
            },
            {
              Name: '阿伊纳罗',
              Code: 'AN'
            },
            {
              Name: '埃尔梅拉',
              Code: 'ER'
            },
            {
              Name: '安贝诺',
              Code: 'AM'
            },
            {
              Name: '包考',
              Code: 'BA'
            },
            {
              Name: '博博纳罗',
              Code: 'BO'
            },
            {
              Name: '帝力',
              Code: 'DI'
            },
            {
              Name: '科瓦利马',
              Code: 'KO'
            },
            {
              Name: '劳滕',
              Code: 'LA'
            },
            {
              Name: '利基卡',
              Code: 'LI'
            },
            {
              Name: '马纳图托',
              Code: 'MT'
            },
            {
              Name: '马努法伊',
              Code: 'MF'
            },
            {
              Name: '维克克',
              Code: 'VI'
            }
          ]
        }
      },
      {
        Name: '多哥',
        Code: 'TGO',
        State: {
          City: [
            {
              Name: '滨海区',
              Code: 'M'
            },
            {
              Name: '草原区',
              Code: 'S'
            },
            {
              Name: '高原区',
              Code: 'P'
            },
            {
              Name: '卡拉区',
              Code: 'K'
            },
            {
              Name: '中部区',
              Code: 'C'
            }
          ]
        }
      },
      {
        Name: '多米尼加',
        Code: 'DMA'
      },
      {
        Name: '多米尼加共和国',
        Code: 'DOM'
      },
      {
        Name: '俄罗斯',
        Code: 'RUS',
        State: {
          City: [
            {
              Name: '阿巴坎',
              Code: 'ABA'
            },
            {
              Name: '阿尔汉格尔斯克',
              Code: 'ARK'
            },
            {
              Name: '阿金斯科耶',
              Code: 'AGI'
            },
            {
              Name: '阿纳德尔',
              Code: 'DYR'
            },
            {
              Name: '阿斯特拉罕',
              Code: 'AST'
            },
            {
              Name: '埃利斯塔',
              Code: 'ESL'
            },
            {
              Name: '奥廖尔',
              Code: 'ORL'
            },
            {
              Name: '奥伦堡',
              Code: 'ORE'
            },
            {
              Name: '巴尔瑙尔',
              Code: 'BAX'
            },
            {
              Name: '奔萨',
              Code: 'PNZ'
            },
            {
              Name: '彼得罗巴甫洛夫斯克',
              Code: 'PKC'
            },
            {
              Name: '彼得罗扎沃茨克',
              Code: 'PES'
            },
            {
              Name: '彼尔姆',
              Code: 'PER'
            },
            {
              Name: '比罗比詹',
              Code: 'BBZ'
            },
            {
              Name: '别尔哥罗德',
              Code: 'BEL'
            },
            {
              Name: '伯力',
              Code: 'COK'
            },
            {
              Name: '布拉戈维申斯克',
              Code: 'BQS'
            },
            {
              Name: '布良斯克',
              Code: 'BRY'
            },
            {
              Name: '车里雅宾斯克',
              Code: 'CHE'
            },
            {
              Name: '赤塔',
              Code: 'CHI'
            },
            {
              Name: '顿河畔罗斯托夫',
              Code: 'ROS'
            },
            {
              Name: '鄂木斯克',
              Code: 'OMS'
            },
            {
              Name: '伏尔加格勒',
              Code: 'VOG'
            },
            {
              Name: '弗拉基米尔',
              Code: 'VMR'
            },
            {
              Name: '弗拉季高加索',
              Code: 'VLA'
            },
            {
              Name: '戈尔诺－阿尔泰斯克',
              Code: 'GOA'
            },
            {
              Name: '格罗兹尼',
              Code: 'GRV'
            },
            {
              Name: '海参崴',
              Code: 'VVO'
            },
            {
              Name: '汉特－曼西斯克',
              Code: 'KHM'
            },
            {
              Name: '基洛夫',
              Code: 'KIR'
            },
            {
              Name: '加里宁格勒',
              Code: 'KGD'
            },
            {
              Name: '喀山',
              Code: 'KZN'
            },
            {
              Name: '卡卢加',
              Code: 'KLF'
            },
            {
              Name: '科斯特罗马',
              Code: 'KOS'
            },
            {
              Name: '克拉斯诺达尔',
              Code: 'KRR'
            },
            {
              Name: '克拉斯诺亚尔斯克',
              Code: 'KYA'
            },
            {
              Name: '克麦罗沃',
              Code: 'KEM'
            },
            {
              Name: '克孜勒',
              Code: 'KYZ'
            },
            {
              Name: '库德姆卡尔',
              Code: 'KUD'
            },
            {
              Name: '库尔干',
              Code: 'KRO'
            },
            {
              Name: '库尔斯克',
              Code: 'URS'
            },
            {
              Name: '利佩茨克',
              Code: 'LIP'
            },
            {
              Name: '梁赞',
              Code: 'RYA'
            },
            {
              Name: '马哈奇卡拉',
              Code: 'MCX'
            },
            {
              Name: '马加丹',
              Code: 'MAG'
            },
            {
              Name: '马加斯',
              Code: 'IN'
            },
            {
              Name: '迈科普',
              Code: 'MAY'
            },
            {
              Name: '摩尔曼斯克',
              Code: 'MMK'
            },
            {
              Name: '莫斯科',
              Code: 'MOW'
            },
            {
              Name: '纳尔奇克',
              Code: 'NAL'
            },
            {
              Name: '纳里扬马尔',
              Code: 'NNM'
            },
            {
              Name: '南萨哈林斯克',
              Code: 'JSA'
            },
            {
              Name: '诺夫哥罗德',
              Code: 'VUS'
            },
            {
              Name: '帕拉纳',
              Code: 'PAL'
            },
            {
              Name: '普斯科夫',
              Code: 'PSK'
            },
            {
              Name: '切博克萨雷',
              Code: 'CSY'
            },
            {
              Name: '切尔克斯克',
              Code: 'CKS'
            },
            {
              Name: '秋明',
              Code: 'TYU'
            },
            {
              Name: '萨拉托夫',
              Code: 'SAR'
            },
            {
              Name: '萨兰斯克',
              Code: 'SKX'
            },
            {
              Name: '萨列哈尔德',
              Code: 'SLY'
            },
            {
              Name: '萨马拉',
              Code: 'SAM'
            },
            {
              Name: '瑟克特夫卡尔',
              Code: 'SCW'
            },
            {
              Name: '圣彼得堡',
              Code: 'SPE'
            },
            {
              Name: '斯摩棱斯克',
              Code: 'LNX'
            },
            {
              Name: '斯塔夫罗波尔',
              Code: 'STA'
            },
            {
              Name: '坦波夫',
              Code: 'TAM'
            },
            {
              Name: '特维尔',
              Code: 'TVE'
            },
            {
              Name: '图拉',
              Code: 'TUL'
            },
            {
              Name: '托木斯克',
              Code: 'TOM'
            },
            {
              Name: '沃罗涅什',
              Code: 'VOR'
            },
            {
              Name: '沃洛格达',
              Code: 'VLG'
            },
            {
              Name: '乌法',
              Code: 'UFA'
            },
            {
              Name: '乌兰乌德',
              Code: 'UUD'
            },
            {
              Name: '乌里扬诺夫斯克',
              Code: 'ULY'
            },
            {
              Name: '乌斯季奥尔登斯基',
              Code: 'UOB'
            },
            {
              Name: '下诺夫哥罗德',
              Code: 'GOJ'
            },
            {
              Name: '新西伯利亚',
              Code: 'NVS'
            },
            {
              Name: '雅库茨克',
              Code: 'JAK'
            },
            {
              Name: '雅罗斯拉夫尔',
              Code: 'JAR'
            },
            {
              Name: '叶卡捷林堡',
              Code: 'JEK'
            },
            {
              Name: '伊尔库茨克',
              Code: 'IKT'
            },
            {
              Name: '伊热夫斯克',
              Code: 'IJK'
            },
            {
              Name: '伊万诺沃',
              Code: 'IVO'
            },
            {
              Name: '约什卡尔奥拉',
              Code: 'YOL'
            }
          ]
        }
      },
      {
        Name: '厄瓜多尔',
        Code: 'ECU',
        State: {
          City: [
            {
              Name: '阿苏艾',
              Code: 'A'
            },
            {
              Name: '埃尔奥罗',
              Code: 'O'
            },
            {
              Name: '埃斯梅拉尔达斯',
              Code: 'E'
            },
            {
              Name: '玻利瓦尔',
              Code: 'B'
            },
            {
              Name: '瓜亚斯',
              Code: 'G'
            },
            {
              Name: '加拉帕戈斯',
              Code: 'W'
            },
            {
              Name: '卡尔奇',
              Code: 'C'
            },
            {
              Name: '卡尼亚尔',
              Code: 'F'
            },
            {
              Name: '科托帕希',
              Code: 'X'
            },
            {
              Name: '洛哈',
              Code: 'L'
            },
            {
              Name: '洛斯里奥斯',
              Code: 'R'
            },
            {
              Name: '马纳比',
              Code: 'M'
            },
            {
              Name: '莫罗纳－圣地亚哥',
              Code: 'S'
            },
            {
              Name: '纳波，奥雷利亚纳',
              Code: 'D'
            },
            {
              Name: '帕斯塔萨',
              Code: 'Y'
            },
            {
              Name: '皮钦查',
              Code: 'P'
            },
            {
              Name: '钦博拉索',
              Code: 'H'
            },
            {
              Name: '萨莫拉－钦奇佩',
              Code: 'Z'
            },
            {
              Name: '苏昆毕奥斯',
              Code: 'U'
            },
            {
              Name: '通古拉瓦',
              Code: 'T'
            },
            {
              Name: '因巴布拉',
              Code: 'I'
            }
          ]
        }
      },
      {
        Name: '厄立特里亚',
        Code: 'ERI',
        State: {
          City: [
            {
              Name: '安塞巴',
              Code: 'KE'
            },
            {
              Name: '北红海',
              Code: 'SK'
            },
            {
              Name: '加什·巴尔卡',
              Code: 'BR'
            },
            {
              Name: '南部',
              Code: 'DE'
            },
            {
              Name: '南红海',
              Code: 'DK'
            },
            {
              Name: '中部',
              Code: 'MA'
            }
          ]
        }
      },
      {
        Name: '法国',
        Code: 'FRA',
        State: {
          City: [
            {
              Name: '阿尔勒',
              Code: 'ARL'
            },
            {
              Name: '阿雅克修',
              Code: 'AJA'
            },
            {
              Name: '艾克斯',
              Code: 'QXB'
            },
            {
              Name: '奥尔良',
              Code: 'ORR'
            },
            {
              Name: '巴黎',
              Code: 'PAR'
            },
            {
              Name: '贝桑松',
              Code: 'BSN'
            },
            {
              Name: '第戎',
              Code: 'DIJ'
            },
            {
              Name: '弗雷瑞斯',
              Code: 'FRJ'
            },
            {
              Name: '卡昂',
              Code: 'CFR'
            },
            {
              Name: '雷恩',
              Code: 'RNS'
            },
            {
              Name: '里昂',
              Code: 'LIO'
            },
            {
              Name: '里尔',
              Code: 'LLE'
            },
            {
              Name: '利摩日',
              Code: 'LIG'
            },
            {
              Name: '鲁昂',
              Code: 'URO'
            },
            {
              Name: '马赛',
              Code: 'MRS'
            },
            {
              Name: '梅斯',
              Code: 'MZM'
            },
            {
              Name: '蒙彼利埃',
              Code: 'MPL'
            },
            {
              Name: '南特',
              Code: 'NTE'
            },
            {
              Name: '尼斯',
              Code: 'NCE'
            },
            {
              Name: '沙隆',
              Code: 'CSM'
            },
            {
              Name: '图卢兹',
              Code: 'TLS'
            },
            {
              Name: '瓦朗斯',
              Code: 'VAA'
            },
            {
              Name: '亚眠',
              Code: 'AMI'
            }
          ]
        }
      },
      {
        Name: '法罗群岛',
        Code: 'FRO'
      },
      {
        Name: '法属波利尼西亚',
        Code: 'PYF'
      },
      {
        Name: '法属圭亚那',
        Code: 'GUF'
      },
      {
        Name: '法属南部领地',
        Code: 'ATF'
      },
      {
        Name: '梵蒂冈',
        Code: 'VAT'
      },
      {
        Name: '菲律宾',
        Code: 'PHL',
        State: {
          City: [
            {
              Name: '达沃',
              Code: 'DOR'
            },
            {
              Name: '卡卢坎',
              Code: 'CAO'
            },
            {
              Name: '马尼拉',
              Code: 'MNL'
            },
            {
              Name: '宿务',
              Code: 'CEB'
            }
          ]
        }
      },
      {
        Name: '斐济',
        Code: 'FJI'
      },
      {
        Name: '芬兰',
        Code: 'FIN',
        State: {
          City: [
            {
              Name: '埃斯波',
              Code: 'ESP'
            },
            {
              Name: '奥卢',
              Code: 'OLU'
            },
            {
              Name: '波里',
              Code: 'POR'
            },
            {
              Name: '博尔沃',
              Code: 'PRV'
            },
            {
              Name: '海门林纳',
              Code: 'HMY'
            },
            {
              Name: '赫尔辛基',
              Code: 'HEL'
            },
            {
              Name: '卡亚尼',
              Code: 'KAJ'
            },
            {
              Name: '科科拉',
              Code: 'KOK'
            },
            {
              Name: '科特卡',
              Code: 'KTK'
            },
            {
              Name: '库奥皮奥',
              Code: 'KUO'
            },
            {
              Name: '拉赫蒂',
              Code: 'LHI'
            },
            {
              Name: '拉彭兰塔',
              Code: 'LPP'
            },
            {
              Name: '罗瓦涅米',
              Code: 'RVN'
            },
            {
              Name: '玛丽港',
              Code: 'MHQ'
            },
            {
              Name: '米凯利',
              Code: 'MIK'
            },
            {
              Name: '坦佩雷',
              Code: 'TMP'
            },
            {
              Name: '图尔库',
              Code: 'TKU'
            },
            {
              Name: '瓦萨',
              Code: 'VAA'
            },
            {
              Name: '万塔',
              Code: 'VAT'
            },
            {
              Name: '约恩苏',
              Code: 'JOE'
            }
          ]
        }
      },
      {
        Name: '佛得角',
        Code: 'CPV',
        State: {
          City: [
            {
              Name: '保尔',
              Code: 'PA'
            },
            {
              Name: '波多诺伏',
              Code: 'PN'
            },
            {
              Name: '博阿维斯塔岛',
              Code: 'BV'
            },
            {
              Name: '布拉瓦岛',
              Code: 'BR'
            },
            {
              Name: '大里贝拉',
              Code: 'RG'
            },
            {
              Name: '福古岛',
              Code: 'FO'
            },
            {
              Name: '马尤岛',
              Code: 'MA'
            },
            {
              Name: '莫斯特罗',
              Code: 'MO'
            },
            {
              Name: '普拉亚',
              Code: 'PR'
            },
            {
              Name: '萨尔岛',
              Code: 'SL'
            },
            {
              Name: '圣安唐岛',
              Code: 'SA'
            },
            {
              Name: '圣地亚哥岛',
              Code: 'IA'
            },
            {
              Name: '圣多明戈',
              Code: 'SD'
            },
            {
              Name: '圣菲利普',
              Code: 'SF'
            },
            {
              Name: '圣卡塔琳娜',
              Code: 'CA'
            },
            {
              Name: '圣克鲁斯',
              Code: 'CR'
            },
            {
              Name: '圣米戈尔',
              Code: 'SM'
            },
            {
              Name: '圣尼古拉岛',
              Code: 'SN'
            },
            {
              Name: '圣维森特岛',
              Code: 'SV'
            },
            {
              Name: '塔拉法尔',
              Code: 'TA'
            }
          ]
        }
      },
      {
        Name: '弗兰克群岛',
        Code: 'FLK'
      },
      {
        Name: '冈比亚',
        Code: 'GMB'
      },
      {
        Name: '刚果',
        Code: 'COG'
      },
      {
        Name: '刚果民主共和国',
        Code: 'COD'
      },
      {
        Name: '哥伦比亚',
        Code: 'COL',
        State: {
          City: [
            {
              Name: '阿劳卡',
              Code: 'ARA'
            },
            {
              Name: '安提奥基亚',
              Code: 'ANT'
            },
            {
              Name: '北桑坦德',
              Code: 'NDS'
            },
            {
              Name: '波哥大首都区',
              Code: 'BDC'
            },
            {
              Name: '博利瓦尔',
              Code: 'BOL'
            },
            {
              Name: '博亚卡',
              Code: 'BOY'
            },
            {
              Name: '大西洋',
              Code: 'ATL'
            },
            {
              Name: '瓜维亚雷',
              Code: 'GVR'
            },
            {
              Name: '瓜希拉',
              Code: 'GJR'
            },
            {
              Name: '瓜伊尼亚',
              Code: 'GNA'
            },
            {
              Name: '金迪奥',
              Code: 'QUI'
            },
            {
              Name: '卡尔达斯',
              Code: 'CAL'
            },
            {
              Name: '卡克塔',
              Code: 'CAQ'
            },
            {
              Name: '卡萨纳雷',
              Code: 'CAS'
            },
            {
              Name: '考卡',
              Code: 'CAU'
            },
            {
              Name: '考卡山谷',
              Code: 'VDC'
            },
            {
              Name: '科尔多巴',
              Code: 'COR'
            },
            {
              Name: '昆迪纳马卡',
              Code: 'CAM'
            },
            {
              Name: '利萨拉尔达',
              Code: 'RIS'
            },
            {
              Name: '马格达雷那',
              Code: 'MAG'
            },
            {
              Name: '梅塔',
              Code: 'MET'
            },
            {
              Name: '纳里尼奥',
              Code: 'NAR'
            },
            {
              Name: '普图马约',
              Code: 'PUT'
            },
            {
              Name: '乔科',
              Code: 'CHO'
            },
            {
              Name: '塞萨尔',
              Code: 'CES'
            },
            {
              Name: '桑坦德',
              Code: 'SAN'
            },
            {
              Name: '圣安德烈斯-普罗维登西亚',
              Code: 'SAP'
            },
            {
              Name: '苏克雷',
              Code: 'SUC'
            },
            {
              Name: '托利马',
              Code: 'TOL'
            },
            {
              Name: '维查达',
              Code: 'VIC'
            },
            {
              Name: '沃佩斯',
              Code: 'VAU'
            },
            {
              Name: '乌伊拉',
              Code: 'HUI'
            },
            {
              Name: '亚马孙',
              Code: 'AMZ'
            }
          ]
        }
      },
      {
        Name: '哥斯达黎加',
        Code: 'CRI',
        State: {
          City: [
            {
              Name: '阿拉胡埃拉',
              Code: 'A'
            },
            {
              Name: '埃雷迪亚',
              Code: 'H'
            },
            {
              Name: '瓜纳卡斯特',
              Code: 'G'
            },
            {
              Name: '卡塔戈',
              Code: 'C'
            },
            {
              Name: '利蒙',
              Code: 'L'
            },
            {
              Name: '蓬塔雷纳斯',
              Code: 'P'
            },
            {
              Name: '圣何塞',
              Code: 'SJ'
            }
          ]
        }
      },
      {
        Name: '格恩西岛',
        Code: 'GGY'
      },
      {
        Name: '格林纳达',
        Code: 'GRD'
      },
      {
        Name: '格陵兰',
        Code: 'GRL'
      },
      {
        Name: '古巴',
        Code: 'CUB',
        State: {
          City: [
            {
              Name: '奥尔金',
              Code: '11'
            },
            {
              Name: '比那尔德里奥',
              Code: '1'
            },
            {
              Name: '比亚克拉拉',
              Code: '5'
            },
            {
              Name: '格拉玛',
              Code: '12'
            },
            {
              Name: '关塔那摩',
              Code: '14'
            },
            {
              Name: '哈瓦那',
              Code: '2'
            },
            {
              Name: '哈瓦那城',
              Code: '3'
            },
            {
              Name: '卡马圭',
              Code: '9'
            },
            {
              Name: '拉斯图纳斯',
              Code: '10'
            },
            {
              Name: '马坦萨斯',
              Code: '4'
            },
            {
              Name: '马亚里',
              Code: 'MAY'
            },
            {
              Name: '曼萨尼罗',
              Code: 'MZO'
            },
            {
              Name: '青年岛特区',
              Code: '99'
            },
            {
              Name: '圣地亚哥',
              Code: '13'
            },
            {
              Name: '圣斯皮里图斯',
              Code: '7'
            },
            {
              Name: '西恩富戈斯',
              Code: '6'
            },
            {
              Name: '谢戈德阿维拉',
              Code: '8'
            }
          ]
        }
      },
      {
        Name: '瓜德罗普',
        Code: 'GLP'
      },
      {
        Name: '关岛',
        Code: 'GUM'
      },
      {
        Name: '圭亚那',
        Code: 'GUY',
        State: {
          City: [
            {
              Name: '埃塞奎博群岛-西德梅拉拉',
              Code: 'EW'
            },
            {
              Name: '巴里马-瓦伊尼',
              Code: 'BW'
            },
            {
              Name: '波默伦-苏佩纳姆',
              Code: 'PM'
            },
            {
              Name: '波塔罗-锡帕鲁尼',
              Code: 'PI'
            },
            {
              Name: '德梅拉拉-马海卡',
              Code: 'DM'
            },
            {
              Name: '东伯比斯-科兰太因',
              Code: 'EC'
            },
            {
              Name: '库尤尼-马扎鲁尼',
              Code: 'CM'
            },
            {
              Name: '马海卡-伯比斯',
              Code: 'MB'
            },
            {
              Name: '上德梅拉拉-伯比斯',
              Code: 'UD'
            },
            {
              Name: '上塔库图-上埃塞奎博',
              Code: 'UT'
            }
          ]
        }
      },
      {
        Name: '哈萨克斯坦',
        Code: 'KAZ',
        State: {
          City: [
            {
              Name: '阿尔卡累克',
              Code: 'AYK'
            },
            {
              Name: '阿克莫拉',
              Code: 'AKM'
            },
            {
              Name: '阿克苏',
              Code: 'AKS'
            },
            {
              Name: '阿克托别',
              Code: 'AKT'
            },
            {
              Name: '阿拉木图',
              Code: 'ALA'
            },
            {
              Name: '阿雷斯',
              Code: 'ARY'
            },
            {
              Name: '阿斯塔纳市',
              Code: 'AST'
            },
            {
              Name: '阿特劳',
              Code: 'ATY'
            },
            {
              Name: '埃基巴斯图兹',
              Code: 'EKB'
            },
            {
              Name: '巴尔喀什',
              Code: 'BXH'
            },
            {
              Name: '巴甫洛达尔',
              Code: 'PAV'
            },
            {
              Name: '北哈萨克斯坦',
              Code: 'SEV'
            },
            {
              Name: '东哈萨克斯坦',
              Code: 'VOS'
            },
            {
              Name: '济良诺夫斯克',
              Code: 'ZYR'
            },
            {
              Name: '江布尔',
              Code: 'DMB'
            },
            {
              Name: '杰兹卡兹甘',
              Code: 'DZH'
            },
            {
              Name: '卡拉干达',
              Code: 'KAR'
            },
            {
              Name: '卡拉扎尔',
              Code: 'KZO'
            },
            {
              Name: '卡普恰盖',
              Code: 'KAP'
            },
            {
              Name: '科斯塔奈',
              Code: 'KST'
            },
            {
              Name: '克孜勒奥尔达',
              Code: 'KZY'
            },
            {
              Name: '肯套',
              Code: 'KEN'
            },
            {
              Name: '库尔恰托夫',
              Code: 'KUR'
            },
            {
              Name: '利萨科夫斯克',
              Code: 'LKK'
            },
            {
              Name: '列宁诺戈尔斯克',
              Code: 'LEN'
            },
            {
              Name: '鲁德内',
              Code: 'RUD'
            },
            {
              Name: '曼格斯套',
              Code: 'MAN'
            },
            {
              Name: '南哈萨克斯坦',
              Code: 'KGT'
            },
            {
              Name: '萨兰',
              Code: 'SAR'
            },
            {
              Name: '塞梅伊',
              Code: 'SEM'
            },
            {
              Name: '沙赫京斯克',
              Code: 'SAK'
            },
            {
              Name: '斯捷普诺戈尔斯克',
              Code: 'STE'
            },
            {
              Name: '铁克利',
              Code: 'TEK'
            },
            {
              Name: '铁米尔套',
              Code: 'TEM'
            },
            {
              Name: '突厥斯坦',
              Code: 'TUR'
            },
            {
              Name: '西哈萨克斯坦',
              Code: 'ZAP'
            },
            {
              Name: '扎纳奥津',
              Code: 'ZHA'
            }
          ]
        }
      },
      {
        Name: '海地',
        Code: 'HTI'
      },
      {
        Name: '韩国',
        Code: 'KOR',
        State: [
          {
            Name: '大邱',
            Code: '27',
            City: [
              {
                Name: '达城郡',
                Code: 'DSG'
              },
              {
                Name: '大邱',
                Code: 'TAE'
              },
              {
                Name: '寿城区',
                Code: 'SUS'
              }
            ]
          },
          {
            Name: '大田',
            Code: '30'
          },
          {
            Name: '釜山',
            Code: '26'
          },
          {
            Name: '光州',
            Code: '29'
          },
          {
            Name: '济州特别自治道',
            Code: '41'
          },
          {
            Name: '江原道',
            Code: '42',
            City: [
              {
                Name: '春川市',
                Code: 'CHC'
              },
              {
                Name: '东海市',
                Code: 'TGH'
              },
              {
                Name: '高城郡',
                Code: 'GSG'
              },
              {
                Name: '横城郡',
                Code: 'HSG'
              },
              {
                Name: '洪川郡',
                Code: 'HCN'
              },
              {
                Name: '华川郡',
                Code: 'HCH'
              },
              {
                Name: '江陵市',
                Code: 'KAG'
              },
              {
                Name: '旌善郡',
                Code: 'JSE'
              },
              {
                Name: '麟蹄郡',
                Code: 'IJE'
              },
              {
                Name: '宁越郡',
                Code: 'YWL'
              },
              {
                Name: '平昌郡',
                Code: 'POG'
              },
              {
                Name: '三陟市',
                Code: 'SUK'
              },
              {
                Name: '束草市',
                Code: 'SHO'
              },
              {
                Name: '太白市',
                Code: 'TBK'
              },
              {
                Name: '铁原郡',
                Code: 'CWN'
              },
              {
                Name: '襄阳郡',
                Code: 'YNY'
              },
              {
                Name: '杨口郡',
                Code: 'YGU'
              },
              {
                Name: '原州市',
                Code: 'WJU'
              }
            ]
          },
          {
            Name: '京畿道',
            Code: '49',
            City: [
              {
                Name: '安城市',
                Code: 'ASG'
              },
              {
                Name: '安山市',
                Code: 'ASN'
              },
              {
                Name: '安养市',
                Code: 'ANY'
              },
              {
                Name: '抱川市',
                Code: 'POC'
              },
              {
                Name: '城南市',
                Code: 'SEO'
              },
              {
                Name: '东豆川市',
                Code: 'DDC'
              },
              {
                Name: '富川市',
                Code: 'BCN'
              },
              {
                Name: '高阳市',
                Code: 'GYG'
              },
              {
                Name: '光明市',
                Code: 'GMG'
              },
              {
                Name: '广州市',
                Code: 'KWU'
              },
              {
                Name: '果川市',
                Code: 'GCN'
              },
              {
                Name: '河南市',
                Code: 'HNM'
              },
              {
                Name: '华城市',
                Code: 'HCH'
              },
              {
                Name: '加平郡',
                Code: 'GPG'
              },
              {
                Name: '金浦市',
                Code: 'GMP'
              },
              {
                Name: '九里市',
                Code: 'GRI'
              },
              {
                Name: '军浦市',
                Code: 'GUN'
              },
              {
                Name: '骊州郡',
                Code: 'YJU'
              },
              {
                Name: '利川市',
                Code: 'ICE'
              },
              {
                Name: '涟川郡',
                Code: 'YCN'
              },
              {
                Name: '龙仁市',
                Code: 'YNG'
              },
              {
                Name: '南杨州市',
                Code: 'NYU'
              },
              {
                Name: '平泽市',
                Code: 'PTK'
              },
              {
                Name: '坡州市',
                Code: 'PJU'
              },
              {
                Name: '始兴市',
                Code: 'SHE'
              },
              {
                Name: '水原市',
                Code: 'SUO'
              },
              {
                Name: '乌山市',
                Code: 'OSN'
              },
              {
                Name: '扬平郡',
                Code: 'YPG'
              },
              {
                Name: '杨州市',
                Code: 'YYU'
              },
              {
                Name: '仪旺市',
                Code: 'UWN'
              },
              {
                Name: '议政府市',
                Code: 'UIJ'
              }
            ]
          },
          {
            Name: '庆尚北道',
            Code: '43',
            City: [
              {
                Name: '安东市',
                Code: 'ADG'
              },
              {
                Name: '奉化郡',
                Code: 'BHA'
              },
              {
                Name: '高灵郡',
                Code: 'GRG'
              },
              {
                Name: '龟尾市',
                Code: 'KUM'
              },
              {
                Name: '金泉市',
                Code: 'KMC'
              },
              {
                Name: '军威郡',
                Code: 'GWI'
              },
              {
                Name: '醴泉郡',
                Code: 'YEC'
              },
              {
                Name: '浦项市',
                Code: 'KPO'
              },
              {
                Name: '漆谷郡',
                Code: 'CGK'
              },
              {
                Name: '淸道郡',
                Code: 'CDO'
              },
              {
                Name: '靑松郡',
                Code: 'CSG'
              },
              {
                Name: '庆山市',
                Code: 'GYS'
              },
              {
                Name: '庆州市',
                Code: 'GJU'
              },
              {
                Name: '荣州市',
                Code: 'YEJ'
              },
              {
                Name: '尙州市',
                Code: 'SJU'
              },
              {
                Name: '蔚珍郡',
                Code: 'UJN'
              },
              {
                Name: '闻庆市',
                Code: 'MGG'
              },
              {
                Name: '星州郡',
                Code: 'SEJ'
              },
              {
                Name: '义城郡',
                Code: 'USG'
              },
              {
                Name: '英阳郡',
                Code: 'YYG'
              },
              {
                Name: '盈德郡',
                Code: 'YDK'
              },
              {
                Name: '永川市',
                Code: 'YCH'
              },
              {
                Name: '郁陵郡',
                Code: 'ULG'
              }
            ]
          },
          {
            Name: '庆尚南道',
            Code: '44',
            City: [
              {
                Name: '昌宁郡',
                Code: 'CNG'
              },
              {
                Name: '昌原市',
                Code: 'CHW'
              },
              {
                Name: '固城郡',
                Code: 'GSO'
              },
              {
                Name: '河东郡',
                Code: 'HDG'
              },
              {
                Name: '金海市',
                Code: 'KMH'
              },
              {
                Name: '晋州市',
                Code: 'HIN'
              },
              {
                Name: '居昌郡',
                Code: 'GCH'
              },
              {
                Name: '巨济市',
                Code: 'KJE'
              },
              {
                Name: '梁山市',
                Code: 'YSN'
              },
              {
                Name: '马山市',
                Code: 'MAS'
              },
              {
                Name: '密阳市',
                Code: 'MIR'
              },
              {
                Name: '南海郡',
                Code: 'NHE'
              },
              {
                Name: '山淸郡',
                Code: 'SCH'
              },
              {
                Name: '泗川市',
                Code: 'SAH'
              },
              {
                Name: '统营市',
                Code: 'TYG'
              },
              {
                Name: '陜川郡',
                Code: 'HCE'
              },
              {
                Name: '咸安郡',
                Code: 'HAN'
              },
              {
                Name: '咸阳郡',
                Code: 'HYG'
              },
              {
                Name: '宜宁郡',
                Code: 'URG'
              },
              {
                Name: '鎭海市',
                Code: 'CHF'
              }
            ]
          },
          {
            Name: '全罗北道',
            Code: '47',
            City: [
              {
                Name: '淳昌郡',
                Code: 'SCG'
              },
              {
                Name: '扶安郡',
                Code: 'PUS'
              },
              {
                Name: '高敞郡',
                Code: 'GCG'
              },
              {
                Name: '金堤市',
                Code: 'GJE'
              },
              {
                Name: '井邑市',
                Code: 'JEO'
              },
              {
                Name: '茂朱郡',
                Code: 'MJU'
              },
              {
                Name: '南原市',
                Code: 'NWN'
              },
              {
                Name: '全州市',
                Code: 'JNJ'
              },
              {
                Name: '群山市',
                Code: 'KUV'
              },
              {
                Name: '任实郡',
                Code: 'ISL'
              },
              {
                Name: '完州郡',
                Code: 'WAJ'
              },
              {
                Name: '益山市',
                Code: 'IKS'
              },
              {
                Name: '长水郡',
                Code: 'JSU'
              },
              {
                Name: '鎭安郡',
                Code: 'JAN'
              }
            ]
          },
          {
            Name: '全罗南道',
            Code: '48',
            City: [
              {
                Name: '宝城郡',
                Code: 'BSG'
              },
              {
                Name: '高兴郡',
                Code: 'GHG'
              },
              {
                Name: '谷城郡',
                Code: 'GSE'
              },
              {
                Name: '莞岛郡',
                Code: 'WND'
              },
              {
                Name: '光阳市',
                Code: 'KAN'
              },
              {
                Name: '海南郡',
                Code: 'HAE'
              },
              {
                Name: '和顺郡',
                Code: 'HSN'
              },
              {
                Name: '康津郡',
                Code: 'GJN'
              },
              {
                Name: '丽水市',
                Code: 'YOS'
              },
              {
                Name: '灵光郡',
                Code: 'YGG'
              },
              {
                Name: '灵岩郡',
                Code: 'YAM'
              },
              {
                Name: '罗州市',
                Code: 'NJU'
              },
              {
                Name: '木浦市',
                Code: 'MOK'
              },
              {
                Name: '求礼郡',
                Code: 'GRE'
              },
              {
                Name: '顺天市',
                Code: 'SYS'
              },
              {
                Name: '潭阳郡',
                Code: 'DYA'
              },
              {
                Name: '务安郡',
                Code: 'MAN'
              },
              {
                Name: '咸平郡',
                Code: 'HPG'
              },
              {
                Name: '新安郡',
                Code: 'SAN'
              },
              {
                Name: '长城郡',
                Code: 'JSN'
              },
              {
                Name: '长兴郡',
                Code: 'JHG'
              },
              {
                Name: '珍岛郡',
                Code: 'JDO'
              }
            ]
          },
          {
            Name: '仁川',
            Code: '28'
          },
          {
            Name: '首尔',
            Code: '11'
          },
          {
            Name: '蔚山',
            Code: '31'
          },
          {
            Name: '忠清北道',
            Code: '45',
            City: [
              {
                Name: '报恩郡',
                Code: 'BEN'
              },
              {
                Name: '曾坪郡',
                Code: 'JYG'
              },
              {
                Name: '丹阳郡',
                Code: 'DYG'
              },
              {
                Name: '堤川市',
                Code: 'JCH'
              },
              {
                Name: '槐山郡',
                Code: 'GSN'
              },
              {
                Name: '淸原郡',
                Code: 'CWO'
              },
              {
                Name: '淸州市',
                Code: 'CJJ'
              },
              {
                Name: '沃川郡',
                Code: 'OCN'
              },
              {
                Name: '阴城郡',
                Code: 'ESG'
              },
              {
                Name: '永同郡',
                Code: 'YDG'
              },
              {
                Name: '鎭川郡',
                Code: 'JCN'
              },
              {
                Name: '忠州市',
                Code: 'CHU'
              }
            ]
          },
          {
            Name: '忠清南道',
            Code: '46',
            City: [
              {
                Name: '保宁市',
                Code: 'BOR'
              },
              {
                Name: '扶余郡',
                Code: 'BYO'
              },
              {
                Name: '公州市',
                Code: 'GOJ'
              },
              {
                Name: '洪城郡',
                Code: 'HSE'
              },
              {
                Name: '鸡龙市',
                Code: 'GYE'
              },
              {
                Name: '锦山郡',
                Code: 'GSA'
              },
              {
                Name: '礼山郡',
                Code: 'YOS'
              },
              {
                Name: '论山市',
                Code: 'NSN'
              },
              {
                Name: '青阳郡',
                Code: 'CYG'
              },
              {
                Name: '瑞山市',
                Code: 'SSA'
              },
              {
                Name: '舒川郡',
                Code: 'SCE'
              },
              {
                Name: '泰安郡',
                Code: 'TAN'
              },
              {
                Name: '唐津郡',
                Code: 'TJI'
              },
              {
                Name: '天安市',
                Code: 'CHO'
              },
              {
                Name: '牙山市',
                Code: 'ASA'
              },
              {
                Name: '燕岐郡',
                Code: 'YGI'
              }
            ]
          }
        ]
      },
      {
        Name: '荷兰',
        Code: 'NLD',
        State: {
          City: [
            {
              Name: '阿尔梅勒',
              Code: 'AER'
            },
            {
              Name: '阿默斯福特',
              Code: 'AME'
            },
            {
              Name: '阿姆斯特丹',
              Code: 'AMS'
            },
            {
              Name: '阿纳姆',
              Code: 'ARN'
            },
            {
              Name: '阿珀尔多伦',
              Code: 'APE'
            },
            {
              Name: '阿森',
              Code: 'ASS'
            },
            {
              Name: '埃德',
              Code: 'EDE'
            },
            {
              Name: '埃门',
              Code: 'EMM'
            },
            {
              Name: '埃因霍芬',
              Code: 'EIN'
            },
            {
              Name: '布雷达',
              Code: 'BRD'
            },
            {
              Name: '蒂尔堡',
              Code: 'TLB'
            },
            {
              Name: '多德雷赫特',
              Code: 'DOR'
            },
            {
              Name: '恩斯赫德',
              Code: 'ENS'
            },
            {
              Name: '格罗宁根',
              Code: 'GRQ'
            },
            {
              Name: '哈勒姆',
              Code: 'HRA'
            },
            {
              Name: '海牙',
              Code: 'HAG'
            },
            {
              Name: '霍夫多尔普',
              Code: 'HFD'
            },
            {
              Name: '莱顿',
              Code: 'LID'
            },
            {
              Name: '莱利斯塔德',
              Code: 'LEY'
            },
            {
              Name: '鹿特丹',
              Code: 'RTM'
            },
            {
              Name: '吕伐登',
              Code: 'LWR'
            },
            {
              Name: '马斯特里赫特',
              Code: 'MST'
            },
            {
              Name: '米德尔堡',
              Code: 'MDL'
            },
            {
              Name: '奈梅亨',
              Code: 'NIJ'
            },
            {
              Name: '斯海尔托亨博思',
              Code: 'HTB'
            },
            {
              Name: '乌得勒支',
              Code: 'UTC'
            },
            {
              Name: '兹沃勒',
              Code: 'ZWO'
            },
            {
              Name: '佐特尔梅',
              Code: 'ZTM'
            }
          ]
        }
      },
      {
        Name: '荷属安地列斯',
        Code: 'ANT'
      },
      {
        Name: '赫德和麦克唐纳群岛',
        Code: 'HMD'
      },
      {
        Name: '洪都拉斯',
        Code: 'HND',
        State: {
          City: [
            {
              Name: '阿特兰蒂达',
              Code: 'AT'
            },
            {
              Name: '埃尔帕拉伊索',
              Code: 'PA'
            },
            {
              Name: '奥科特佩克',
              Code: 'OC'
            },
            {
              Name: '奥兰乔',
              Code: 'OL'
            },
            {
              Name: '弗朗西斯科-莫拉桑',
              Code: 'FM'
            },
            {
              Name: '格拉西亚斯-阿迪奥斯',
              Code: 'GD'
            },
            {
              Name: '海湾群岛',
              Code: 'IB'
            },
            {
              Name: '科尔特斯',
              Code: 'CR'
            },
            {
              Name: '科隆',
              Code: 'CL'
            },
            {
              Name: '科马亚瓜',
              Code: 'CM'
            },
            {
              Name: '科潘',
              Code: 'CP'
            },
            {
              Name: '拉巴斯',
              Code: 'PZ'
            },
            {
              Name: '伦皮拉',
              Code: 'LE'
            },
            {
              Name: '乔卢特卡',
              Code: 'CH'
            },
            {
              Name: '乔罗马',
              Code: 'CHO'
            },
            {
              Name: '山谷',
              Code: 'VA'
            },
            {
              Name: '圣巴巴拉',
              Code: 'SB'
            },
            {
              Name: '因蒂布卡',
              Code: 'IN'
            },
            {
              Name: '约罗',
              Code: 'YO'
            }
          ]
        }
      },
      {
        Name: '基里巴斯',
        Code: 'KIR',
        State: {
          City: [
            {
              Name: '菲尼克斯群岛',
              Code: 'PHO'
            },
            {
              Name: '吉尔伯特群岛',
              Code: 'GIL'
            },
            {
              Name: '莱恩群岛',
              Code: 'LIN'
            }
          ]
        }
      },
      {
        Name: '吉布提',
        Code: 'DJI',
        State: {
          City: [
            {
              Name: '阿里萨比赫区',
              Code: 'S'
            },
            {
              Name: '奥博克区',
              Code: 'O'
            },
            {
              Name: '迪基勒区',
              Code: 'K'
            },
            {
              Name: '塔朱拉区',
              Code: 'T'
            }
          ]
        }
      },
      {
        Name: '吉尔吉斯斯坦',
        Code: 'KGZ',
        State: {
          City: [
            {
              Name: '奥什',
              Code: 'O'
            },
            {
              Name: '巴特肯',
              Code: 'B'
            },
            {
              Name: '比什凯克市',
              Code: 'GB'
            },
            {
              Name: '楚河',
              Code: 'C'
            },
            {
              Name: '贾拉拉巴德',
              Code: 'J'
            },
            {
              Name: '卡拉巴尔塔',
              Code: 'KBA'
            },
            {
              Name: '卡拉库尔',
              Code: 'KKO'
            },
            {
              Name: '坎特',
              Code: 'KAN'
            },
            {
              Name: '科克扬加克',
              Code: 'KJ'
            },
            {
              Name: '迈利赛',
              Code: 'MS'
            },
            {
              Name: '纳伦',
              Code: 'N'
            },
            {
              Name: '苏卢克图',
              Code: 'SU'
            },
            {
              Name: '塔拉斯',
              Code: 'T'
            },
            {
              Name: '塔什库梅尔',
              Code: 'TK'
            },
            {
              Name: '乌兹根',
              Code: 'UG'
            },
            {
              Name: '伊塞克湖',
              Code: 'Y'
            }
          ]
        }
      },
      {
        Name: '几内亚',
        Code: 'GIN',
        State: {
          City: [
            {
              Name: '博凯',
              Code: 'BOK'
            },
            {
              Name: '恩泽雷科雷',
              Code: 'NZR'
            },
            {
              Name: '法拉纳',
              Code: 'FRN'
            },
            {
              Name: '金迪亚',
              Code: 'KND'
            },
            {
              Name: '康康',
              Code: 'KNK'
            },
            {
              Name: '科纳克里',
              Code: 'CNK'
            },
            {
              Name: '拉贝',
              Code: 'LAB'
            },
            {
              Name: '玛木',
              Code: 'MAM'
            }
          ]
        }
      },
      {
        Name: '几内亚比绍',
        Code: 'GNB'
      },
      {
        Name: '加拿大',
        Code: 'CAN',
        State: {
          City: [
            {
              Name: '阿伯茨福',
              Code: 'ABB'
            },
            {
              Name: '埃德蒙顿',
              Code: 'EDM'
            },
            {
              Name: '奥沙瓦',
              Code: 'OSH'
            },
            {
              Name: '巴里',
              Code: 'BAR'
            },
            {
              Name: '布列塔尼角',
              Code: 'CBR'
            },
            {
              Name: '多伦多',
              Code: 'TOR'
            },
            {
              Name: '弗雷德里顿',
              Code: 'FRE'
            },
            {
              Name: '圭尔夫',
              Code: 'GLP'
            },
            {
              Name: '哈利法克斯',
              Code: 'HAL'
            },
            {
              Name: '哈密尔顿',
              Code: 'HAM'
            },
            {
              Name: '怀特霍斯',
              Code: 'YXY'
            },
            {
              Name: '基劳纳',
              Code: 'KWL'
            },
            {
              Name: '基奇纳',
              Code: 'BRP'
            },
            {
              Name: '金斯敦',
              Code: 'KGN'
            },
            {
              Name: '卡里加里',
              Code: 'CAL'
            },
            {
              Name: '魁北克',
              Code: 'QUE'
            },
            {
              Name: '里贾纳',
              Code: 'REG'
            },
            {
              Name: '伦敦',
              Code: 'LOD'
            },
            {
              Name: '蒙特利尔',
              Code: 'MTR'
            },
            {
              Name: '萨德伯里',
              Code: 'SUD'
            },
            {
              Name: '萨斯卡通',
              Code: 'SAK'
            },
            {
              Name: '三河城',
              Code: 'TRR'
            },
            {
              Name: '桑德贝',
              Code: 'THU'
            },
            {
              Name: '舍布鲁克',
              Code: 'SBE'
            },
            {
              Name: '圣卡塔琳娜',
              Code: 'SCA'
            },
            {
              Name: '圣约翰斯',
              Code: 'SJB'
            },
            {
              Name: '维多利亚',
              Code: 'VIC'
            },
            {
              Name: '温哥华',
              Code: 'VAN'
            },
            {
              Name: '温尼伯',
              Code: 'WNP'
            },
            {
              Name: '温莎',
              Code: 'WDR'
            },
            {
              Name: '渥太华',
              Code: 'OTT'
            },
            {
              Name: '夏洛特敦',
              Code: 'CHA'
            },
            {
              Name: '耶洛奈夫',
              Code: 'YZF'
            },
            {
              Name: '伊魁特',
              Code: 'IQL'
            }
          ]
        }
      },
      {
        Name: '加纳',
        Code: 'GHA',
        State: {
          City: [
            {
              Name: '阿散蒂',
              Code: 'AS'
            },
            {
              Name: '奥布阿西',
              Code: 'OBU'
            },
            {
              Name: '北部',
              Code: 'NO'
            },
            {
              Name: '布朗阿哈福',
              Code: 'BA'
            },
            {
              Name: '大阿克拉',
              Code: 'GA'
            },
            {
              Name: '东部',
              Code: 'EA'
            },
            {
              Name: '上东部',
              Code: 'UE'
            },
            {
              Name: '上西部',
              Code: 'UW'
            },
            {
              Name: '沃尔特',
              Code: 'VO'
            },
            {
              Name: '西部',
              Code: 'WE'
            },
            {
              Name: '中部',
              Code: 'CE'
            }
          ]
        }
      },
      {
        Name: '加蓬',
        Code: 'GAB',
        State: {
          City: [
            {
              Name: '奥果韦-洛洛',
              Code: 'OL'
            },
            {
              Name: '奥果韦-伊温多',
              Code: 'OI'
            },
            {
              Name: '滨海奥果韦',
              Code: 'OM'
            },
            {
              Name: '恩古涅',
              Code: 'NG'
            },
            {
              Name: '河口',
              Code: 'ES'
            },
            {
              Name: '尼扬加',
              Code: 'NY'
            },
            {
              Name: '上奥果韦',
              Code: 'HO'
            },
            {
              Name: '沃勒-恩特姆',
              Code: 'WN'
            },
            {
              Name: '中奥果韦',
              Code: 'MO'
            }
          ]
        }
      },
      {
        Name: '柬埔寨',
        Code: 'KHM',
        State: {
          City: [
            {
              Name: '奥多棉吉',
              Code: 'OC'
            },
            {
              Name: '白马市',
              Code: 'KB'
            },
            {
              Name: '柏威夏',
              Code: 'PR'
            },
            {
              Name: '拜林市',
              Code: 'PL'
            },
            {
              Name: '班迭棉吉',
              Code: 'BM'
            },
            {
              Name: '磅清扬',
              Code: 'KZC'
            },
            {
              Name: '磅士卑',
              Code: 'KO'
            },
            {
              Name: '磅同',
              Code: 'KZK'
            },
            {
              Name: '磅湛',
              Code: 'KM'
            },
            {
              Name: '波罗勉',
              Code: 'PG'
            },
            {
              Name: '茶胶',
              Code: 'TK'
            },
            {
              Name: '柴桢',
              Code: 'SVR'
            },
            {
              Name: '干丹',
              Code: 'KL'
            },
            {
              Name: '戈公',
              Code: 'KKZ'
            },
            {
              Name: '贡布',
              Code: 'KMT'
            },
            {
              Name: '金边市',
              Code: 'PNH'
            },
            {
              Name: '桔井',
              Code: 'KH'
            },
            {
              Name: '腊塔纳基里',
              Code: 'RBE'
            },
            {
              Name: '马德望',
              Code: 'BA'
            },
            {
              Name: '蒙多基里',
              Code: 'MWV'
            },
            {
              Name: '菩萨',
              Code: 'PO'
            },
            {
              Name: '上丁',
              Code: 'TNX'
            },
            {
              Name: '西哈努克市',
              Code: 'KA'
            },
            {
              Name: '暹粒',
              Code: 'REP'
            }
          ]
        }
      },
      {
        Name: '捷克共和国',
        Code: 'CZE',
        State: {
          City: [
            {
              Name: '奥洛穆茨',
              Code: 'OL'
            },
            {
              Name: '比尔森',
              Code: 'PL'
            },
            {
              Name: '布拉格直辖市',
              Code: 'PR'
            },
            {
              Name: '赫拉德茨-克拉洛韦',
              Code: 'KR'
            },
            {
              Name: '卡罗维发利',
              Code: 'KA'
            },
            {
              Name: '利贝雷克',
              Code: 'LI'
            },
            {
              Name: '摩拉维亚-西里西亚',
              Code: 'MO'
            },
            {
              Name: '南摩拉维亚',
              Code: 'JC'
            },
            {
              Name: '帕尔杜比采',
              Code: 'PA'
            },
            {
              Name: '维索基纳',
              Code: 'VY'
            },
            {
              Name: '乌斯季',
              Code: 'US'
            },
            {
              Name: '中捷克',
              Code: 'ST'
            },
            {
              Name: '兹林',
              Code: 'ZL'
            }
          ]
        }
      },
      {
        Name: '津巴布韦',
        Code: 'ZWE',
        State: {
          City: [
            {
              Name: '北马塔贝莱兰',
              Code: 'MN'
            },
            {
              Name: '布拉瓦约',
              Code: 'BU'
            },
            {
              Name: '东马绍纳兰',
              Code: 'ME'
            },
            {
              Name: '哈拉雷',
              Code: 'HA'
            },
            {
              Name: '马尼卡兰',
              Code: 'ML'
            },
            {
              Name: '马斯温戈',
              Code: 'MV'
            },
            {
              Name: '南马塔贝莱兰',
              Code: 'MS'
            },
            {
              Name: '西马绍纳兰',
              Code: 'MW'
            },
            {
              Name: '中部',
              Code: 'MD'
            },
            {
              Name: '中马绍纳兰',
              Code: 'MC'
            }
          ]
        }
      },
      {
        Name: '喀麦隆',
        Code: 'CMR',
        State: {
          City: [
            {
              Name: '阿达马瓦',
              Code: 'ADA'
            },
            {
              Name: '北部',
              Code: 'NOR'
            },
            {
              Name: '北端',
              Code: 'EXN'
            },
            {
              Name: '滨海',
              Code: 'LIT'
            },
            {
              Name: '东部',
              Code: 'EST'
            },
            {
              Name: '南部',
              Code: 'SUD'
            },
            {
              Name: '西北',
              Code: 'NOT'
            },
            {
              Name: '西部',
              Code: 'OUE'
            },
            {
              Name: '西南',
              Code: 'SOU'
            },
            {
              Name: '中央',
              Code: 'CEN'
            }
          ]
        }
      },
      {
        Name: '卡塔尔',
        Code: 'QAT',
        State: {
          City: [
            {
              Name: '北部',
              Code: 'MS'
            },
            {
              Name: '多哈',
              Code: 'DW'
            },
            {
              Name: '古韦里耶',
              Code: 'GW'
            },
            {
              Name: '豪尔',
              Code: 'KR'
            },
            {
              Name: '杰里扬拜特奈',
              Code: 'JB'
            },
            {
              Name: '赖扬',
              Code: 'RN'
            },
            {
              Name: '沃克拉',
              Code: 'WK'
            },
            {
              Name: '乌姆锡拉勒',
              Code: 'UL'
            },
            {
              Name: '朱迈利耶',
              Code: 'JM'
            }
          ]
        }
      },
      {
        Name: '开曼群岛',
        Code: 'CYM'
      },
      {
        Name: '科科斯群岛',
        Code: 'CCK'
      },
      {
        Name: '科摩罗',
        Code: 'COM'
      },
      {
        Name: '科特迪瓦',
        Code: 'CIV',
        State: {
          City: [
            {
              Name: '阿涅比',
              Code: 'AG'
            },
            {
              Name: '巴芬',
              Code: 'BF'
            },
            {
              Name: '邦达马河谷',
              Code: 'VB'
            },
            {
              Name: '登盖莱',
              Code: 'DE'
            },
            {
              Name: '恩济－科莫埃',
              Code: 'NC'
            },
            {
              Name: '弗罗马格尔',
              Code: 'FR'
            },
            {
              Name: '湖泊',
              Code: 'LC'
            },
            {
              Name: '马拉韦',
              Code: 'MR'
            },
            {
              Name: '南邦达马',
              Code: 'SB'
            },
            {
              Name: '南科莫埃',
              Code: 'SC'
            },
            {
              Name: '萨桑德拉',
              Code: 'HT'
            },
            {
              Name: '萨瓦纳',
              Code: 'SV'
            },
            {
              Name: '山地',
              Code: 'DH'
            },
            {
              Name: '沃罗杜古',
              Code: 'WR'
            },
            {
              Name: '下萨桑德拉',
              Code: 'BS'
            },
            {
              Name: '泻湖',
              Code: 'LG'
            },
            {
              Name: '赞赞',
              Code: 'ZA'
            },
            {
              Name: '中卡瓦利',
              Code: 'MV'
            },
            {
              Name: '中科莫埃',
              Code: 'MC'
            }
          ]
        }
      },
      {
        Name: '科威特',
        Code: 'KWT'
      },
      {
        Name: '克罗地亚',
        Code: 'HRV',
        State: {
          City: [
            {
              Name: '奥西耶克-巴拉尼亚',
              Code: '14'
            },
            {
              Name: '别洛瓦尔-比洛戈拉',
              Code: '7'
            },
            {
              Name: '滨海和山区',
              Code: '8'
            },
            {
              Name: '波热加-斯拉沃尼亚',
              Code: '11'
            },
            {
              Name: '布罗德-波萨维纳',
              Code: '12'
            },
            {
              Name: '杜布罗夫斯克-内雷特瓦',
              Code: '19'
            },
            {
              Name: '卡尔洛瓦茨',
              Code: '4'
            },
            {
              Name: '科普里夫尼察-克里热夫齐',
              Code: '6'
            },
            {
              Name: '克拉皮纳-扎戈列',
              Code: '2'
            },
            {
              Name: '利卡-塞尼',
              Code: '9'
            },
            {
              Name: '梅吉穆列',
              Code: '20'
            },
            {
              Name: '萨格勒布',
              Code: '1'
            },
            {
              Name: '萨格勒布市',
              Code: '21'
            },
            {
              Name: '斯普利特-达尔马提亚',
              Code: '17'
            },
            {
              Name: '瓦拉日丁',
              Code: '5'
            },
            {
              Name: '维罗维蒂察-波德拉维纳',
              Code: '10'
            },
            {
              Name: '武科瓦尔-斯里耶姆',
              Code: '16'
            },
            {
              Name: '希贝尼克-克宁',
              Code: '15'
            },
            {
              Name: '锡萨克-莫斯拉维纳',
              Code: '3'
            },
            {
              Name: '伊斯特拉',
              Code: '18'
            },
            {
              Name: '扎达尔',
              Code: '13'
            }
          ]
        }
      },
      {
        Name: '肯尼亚',
        Code: 'KEN',
        State: {
          City: [
            {
              Name: '埃尔格约-马拉奎特',
              Code: 'EMA'
            },
            {
              Name: '巴林戈',
              Code: 'BAR'
            },
            {
              Name: '邦戈马',
              Code: 'BUN'
            },
            {
              Name: '博美特',
              Code: 'BOM'
            },
            {
              Name: '布希亚',
              Code: 'BUS'
            },
            {
              Name: '恩布',
              Code: 'EMB'
            },
            {
              Name: '霍马湾',
              Code: 'HOB'
            },
            {
              Name: '基安布',
              Code: 'KIA'
            },
            {
              Name: '基里菲',
              Code: 'KIL'
            },
            {
              Name: '基里尼亚加',
              Code: 'KIR'
            },
            {
              Name: '基苏木',
              Code: 'KIS'
            },
            {
              Name: '基图伊',
              Code: 'KIT'
            },
            {
              Name: '基西',
              Code: 'KII'
            },
            {
              Name: '加里萨',
              Code: 'GAS'
            },
            {
              Name: '卡卡梅加',
              Code: 'KAK'
            },
            {
              Name: '卡耶亚多',
              Code: 'KAJ'
            },
            {
              Name: '凯里乔',
              Code: 'KEY'
            },
            {
              Name: '夸勒',
              Code: 'KWA'
            },
            {
              Name: '拉木',
              Code: 'LAU'
            },
            {
              Name: '莱基皮亚',
              Code: 'LAI'
            },
            {
              Name: '马查科斯',
              Code: 'MAC'
            },
            {
              Name: '马瓜尼',
              Code: 'MAK'
            },
            {
              Name: '马萨布布',
              Code: 'RBT'
            },
            {
              Name: '曼德拉',
              Code: 'MAN'
            },
            {
              Name: '梅鲁',
              Code: 'MER'
            },
            {
              Name: '蒙巴萨',
              Code: 'MOM'
            },
            {
              Name: '米戈利',
              Code: 'MIG'
            },
            {
              Name: '穆兰卡',
              Code: 'MUR'
            },
            {
              Name: '纳库鲁',
              Code: 'NUU'
            },
            {
              Name: '纳罗克',
              Code: 'NAR'
            },
            {
              Name: '南迪',
              Code: 'NAN'
            },
            {
              Name: '内罗毕',
              Code: 'NA'
            },
            {
              Name: '尼蒂',
              Code: 'NIT'
            },
            {
              Name: '尼亚米拉',
              Code: 'NYM'
            },
            {
              Name: '年达鲁阿',
              Code: 'NYN'
            },
            {
              Name: '涅里',
              Code: 'NYE'
            },
            {
              Name: '桑布卢',
              Code: 'UAS'
            },
            {
              Name: '塔纳河',
              Code: 'TRI'
            },
            {
              Name: '泰塔塔维塔',
              Code: 'TTA'
            },
            {
              Name: '特兰斯-恩佐亚',
              Code: 'TNZ'
            },
            {
              Name: '图尔卡纳',
              Code: 'TUR'
            },
            {
              Name: '瓦吉尔',
              Code: 'WJR'
            },
            {
              Name: '瓦辛基苏',
              Code: 'UGI'
            },
            {
              Name: '韦希加',
              Code: 'VIH'
            },
            {
              Name: '西波克特',
              Code: 'WPO'
            },
            {
              Name: '夏亚',
              Code: 'SIA'
            },
            {
              Name: '伊希约洛',
              Code: 'ISI'
            },
            {
              Name: '中央',
              Code: 'CE'
            }
          ]
        }
      },
      {
        Name: '库克群岛',
        Code: 'COK'
      },
      {
        Name: '拉脱维亚',
        Code: 'LVA',
        State: {
          City: [
            {
              Name: '阿卢克斯内',
              Code: 'ALU'
            },
            {
              Name: '爱兹克劳克雷',
              Code: 'AIZ'
            },
            {
              Name: '奥格雷',
              Code: 'OGR'
            },
            {
              Name: '巴尔维',
              Code: 'BAL'
            },
            {
              Name: '包斯卡',
              Code: 'BAU'
            },
            {
              Name: '采西斯',
              Code: 'CES'
            },
            {
              Name: '多贝莱',
              Code: 'DOB'
            },
            {
              Name: '古尔贝内',
              Code: 'GUL'
            },
            {
              Name: '杰卡布皮尔斯',
              Code: 'JEK'
            },
            {
              Name: '克拉斯拉瓦',
              Code: 'KRA'
            },
            {
              Name: '库尔迪加',
              Code: 'KUL'
            },
            {
              Name: '雷泽克内',
              Code: 'RZR'
            },
            {
              Name: '里加',
              Code: 'RGA'
            },
            {
              Name: '利耶帕亚',
              Code: 'LPK'
            },
            {
              Name: '林巴济',
              Code: 'LIM'
            },
            {
              Name: '卢扎',
              Code: 'LUD'
            },
            {
              Name: '马多纳',
              Code: 'MAD'
            },
            {
              Name: '普雷利',
              Code: 'PRE'
            },
            {
              Name: '萨尔杜斯',
              Code: 'SAL'
            },
            {
              Name: '塔尔西',
              Code: 'TAL'
            },
            {
              Name: '陶格夫皮尔斯',
              Code: 'DGR'
            },
            {
              Name: '图库马',
              Code: 'TUK'
            },
            {
              Name: '瓦尔加',
              Code: 'VLK'
            },
            {
              Name: '瓦尔米耶拉',
              Code: 'VLM'
            },
            {
              Name: '文茨皮尔斯',
              Code: 'VSL'
            },
            {
              Name: '叶尔加瓦',
              Code: 'JGR'
            }
          ]
        }
      },
      {
        Name: '莱索托',
        Code: 'LSO',
        State: {
          City: [
            {
              Name: '伯里亚',
              Code: 'D'
            },
            {
              Name: '布塔布泰',
              Code: 'B'
            },
            {
              Name: '古廷',
              Code: 'G'
            },
            {
              Name: '加查斯内克',
              Code: 'H'
            },
            {
              Name: '莱里贝',
              Code: 'C'
            },
            {
              Name: '马费滕',
              Code: 'E'
            },
            {
              Name: '马塞卢',
              Code: 'A'
            },
            {
              Name: '莫哈莱斯胡克',
              Code: 'F'
            },
            {
              Name: '莫霍特隆',
              Code: 'J'
            },
            {
              Name: '塔巴采卡',
              Code: 'K'
            }
          ]
        }
      },
      {
        Name: '老挝',
        Code: 'LAO',
        State: {
          City: [
            {
              Name: '阿速坡',
              Code: 'AT'
            },
            {
              Name: '波里坎赛',
              Code: 'BL'
            },
            {
              Name: '博乔',
              Code: 'BK'
            },
            {
              Name: '川圹',
              Code: 'XI'
            },
            {
              Name: '丰沙里',
              Code: 'PH'
            },
            {
              Name: '甘蒙',
              Code: 'KH'
            },
            {
              Name: '华潘',
              Code: 'HO'
            },
            {
              Name: '琅勃拉邦',
              Code: 'LP'
            },
            {
              Name: '琅南塔',
              Code: 'LM'
            },
            {
              Name: '赛宋本行政特区',
              Code: 'XN'
            },
            {
              Name: '色贡',
              Code: 'XE'
            },
            {
              Name: '沙拉湾',
              Code: 'SL'
            },
            {
              Name: '沙湾拿吉',
              Code: 'SV'
            },
            {
              Name: '沙耶武里',
              Code: 'XA'
            },
            {
              Name: '万象',
              Code: 'VI'
            },
            {
              Name: '乌多姆赛',
              Code: 'OU'
            },
            {
              Name: '占巴塞',
              Code: 'CH'
            }
          ]
        }
      },
      {
        Name: '黎巴嫩',
        Code: 'LBN',
        State: {
          City: [
            {
              Name: '北部',
              Code: 'AS'
            },
            {
              Name: '贝卡',
              Code: 'BI'
            },
            {
              Name: '贝鲁特',
              Code: 'BA'
            },
            {
              Name: '黎巴嫩山',
              Code: 'JL'
            },
            {
              Name: '奈拜提耶市',
              Code: 'NA'
            },
            {
              Name: '南部',
              Code: 'JA'
            }
          ]
        }
      },
      {
        Name: '利比里亚',
        Code: 'LBR',
        State: {
          City: [
            {
              Name: '巴波卢',
              Code: 'GBA'
            },
            {
              Name: '邦',
              Code: 'BG'
            },
            {
              Name: '博波卢',
              Code: 'BOP'
            },
            {
              Name: '博米',
              Code: 'BM'
            },
            {
              Name: '大巴萨',
              Code: 'GB'
            },
            {
              Name: '大吉德',
              Code: 'GG'
            },
            {
              Name: '大角山',
              Code: 'CM'
            },
            {
              Name: '大克鲁',
              Code: 'GK'
            },
            {
              Name: '菲什敦',
              Code: 'FT'
            },
            {
              Name: '吉河',
              Code: 'RG'
            },
            {
              Name: '里弗塞斯',
              Code: 'RI'
            },
            {
              Name: '洛法',
              Code: 'LO'
            },
            {
              Name: '马吉比',
              Code: 'MG'
            },
            {
              Name: '马里兰',
              Code: 'MY'
            },
            {
              Name: '蒙特塞拉多',
              Code: 'MO'
            },
            {
              Name: '宁巴',
              Code: 'NI'
            },
            {
              Name: '锡诺',
              Code: 'SI'
            }
          ]
        }
      },
      {
        Name: '利比亚',
        Code: 'LBY'
      },
      {
        Name: '立陶宛',
        Code: 'LTU',
        State: {
          City: [
            {
              Name: '阿利图斯',
              Code: 'AL'
            },
            {
              Name: '考纳斯',
              Code: 'KA'
            },
            {
              Name: '克莱佩达',
              Code: 'KL'
            },
            {
              Name: '马里扬泊列',
              Code: 'MA'
            },
            {
              Name: '帕涅韦日斯',
              Code: 'PA'
            },
            {
              Name: '陶拉格',
              Code: 'TA'
            },
            {
              Name: '特尔希艾',
              Code: 'TE'
            },
            {
              Name: '维尔纽斯',
              Code: 'VI'
            },
            {
              Name: '乌田纳',
              Code: 'UT'
            },
            {
              Name: '希奥利艾',
              Code: 'SI'
            },
            {
              Name: '亚克曼',
              Code: 'AKM'
            }
          ]
        }
      },
      {
        Name: '列支敦士登',
        Code: 'LIE'
      },
      {
        Name: '留尼旺岛',
        Code: 'REU'
      },
      {
        Name: '卢森堡',
        Code: 'LUX',
        State: {
          City: [
            {
              Name: '迪基希',
              Code: 'DD'
            },
            {
              Name: '格雷文马赫',
              Code: 'GG'
            },
            {
              Name: '卢森堡',
              Code: 'LL'
            }
          ]
        }
      },
      {
        Name: '卢旺达',
        Code: 'RWA',
        State: {
          City: [
            {
              Name: '比温巴',
              Code: 'BY'
            },
            {
              Name: '布塔雷',
              Code: 'BU'
            },
            {
              Name: '恩延扎',
              Code: 'NY'
            },
            {
              Name: '基本古',
              Code: 'KG'
            },
            {
              Name: '基布耶',
              Code: 'KY'
            },
            {
              Name: '基加利-恩加利',
              Code: 'KR'
            },
            {
              Name: '基加利市',
              Code: 'KV'
            },
            {
              Name: '吉孔戈罗',
              Code: 'GK'
            },
            {
              Name: '吉塞尼',
              Code: 'GS'
            },
            {
              Name: '吉塔拉马',
              Code: 'GT'
            },
            {
              Name: '卡布加',
              Code: 'KA'
            },
            {
              Name: '卢瓦马加纳',
              Code: 'RW'
            },
            {
              Name: '鲁汉戈',
              Code: 'RH'
            },
            {
              Name: '鲁亨盖里',
              Code: 'RU'
            },
            {
              Name: '尚古古',
              Code: 'CY'
            },
            {
              Name: '乌姆塔拉',
              Code: 'UM'
            }
          ]
        }
      },
      {
        Name: '罗马尼亚',
        Code: 'ROU',
        State: {
          City: [
            {
              Name: '阿尔巴尤利亚',
              Code: 'AL'
            },
            {
              Name: '阿拉德',
              Code: 'AR'
            },
            {
              Name: '奥拉迪亚',
              Code: 'OR'
            },
            {
              Name: '巴克乌',
              Code: 'BA'
            },
            {
              Name: '巴亚马雷',
              Code: 'BM'
            },
            {
              Name: '比斯特里察',
              Code: 'BN'
            },
            {
              Name: '博托沙尼',
              Code: 'BO'
            },
            {
              Name: '布加勒斯特',
              Code: 'BC'
            },
            {
              Name: '布拉索夫',
              Code: 'BS'
            },
            {
              Name: '布勒伊拉',
              Code: 'BL'
            },
            {
              Name: '布泽乌',
              Code: 'BZ'
            },
            {
              Name: '德罗贝塔-塞维林堡',
              Code: 'DT'
            },
            {
              Name: '德瓦',
              Code: 'DE'
            },
            {
              Name: '蒂米什瓦拉',
              Code: 'TI'
            },
            {
              Name: '福克沙尼',
              Code: 'FO'
            },
            {
              Name: '加拉茨',
              Code: 'GL'
            },
            {
              Name: '久尔久',
              Code: 'GG'
            },
            {
              Name: '康斯坦察',
              Code: 'CT'
            },
            {
              Name: '克拉约瓦',
              Code: 'DO'
            },
            {
              Name: '克勒拉希',
              Code: 'CR'
            },
            {
              Name: '克卢日纳波卡',
              Code: 'CN'
            },
            {
              Name: '勒姆尼库沃尔恰',
              Code: 'VI'
            },
            {
              Name: '雷希察',
              Code: 'RE'
            },
            {
              Name: '梅尔库里亚丘克',
              Code: 'MC'
            },
            {
              Name: '皮特什蒂',
              Code: 'PI'
            },
            {
              Name: '皮亚特拉尼亚姆茨',
              Code: 'PN'
            },
            {
              Name: '普洛耶什蒂',
              Code: 'PL'
            },
            {
              Name: '萨图·马雷',
              Code: 'SM'
            },
            {
              Name: '圣格奥尔基',
              Code: 'SG'
            },
            {
              Name: '斯拉蒂纳',
              Code: 'ST'
            },
            {
              Name: '斯洛博齐亚',
              Code: 'SB'
            },
            {
              Name: '苏恰瓦',
              Code: 'SU'
            },
            {
              Name: '特尔戈维什泰',
              Code: 'TA'
            },
            {
              Name: '特尔古穆列什',
              Code: 'TM'
            },
            {
              Name: '特尔古日乌',
              Code: 'TJ'
            },
            {
              Name: '图尔恰',
              Code: 'TU'
            },
            {
              Name: '瓦斯卢伊',
              Code: 'VA'
            },
            {
              Name: '锡比乌',
              Code: 'SO'
            },
            {
              Name: '雅西',
              Code: 'IS'
            },
            {
              Name: '亚厉山德里亚',
              Code: 'AD'
            },
            {
              Name: '扎勒乌',
              Code: 'ZA'
            }
          ]
        }
      },
      {
        Name: '马达加斯加',
        Code: 'MDG',
        State: {
          City: [
            {
              Name: '安齐拉纳纳',
              Code: 'AS'
            },
            {
              Name: '菲亚纳兰楚阿',
              Code: 'FN'
            },
            {
              Name: '马哈赞加',
              Code: 'MJ'
            },
            {
              Name: '塔那那利佛',
              Code: 'AN'
            },
            {
              Name: '图阿马西拉',
              Code: 'TM'
            },
            {
              Name: '图利亚拉',
              Code: 'TL'
            }
          ]
        }
      },
      {
        Name: '马尔代夫',
        Code: 'MDV',
        State: {
          City: [
            {
              Name: '阿杜',
              Code: 'ADD'
            },
            {
              Name: '北阿里',
              Code: 'AAD'
            },
            {
              Name: '北蒂拉杜马蒂',
              Code: 'THD'
            },
            {
              Name: '北马洛斯马杜卢',
              Code: 'MAD'
            },
            {
              Name: '北米拉杜马杜卢',
              Code: 'MLD'
            },
            {
              Name: '北尼兰杜',
              Code: 'NAD'
            },
            {
              Name: '北苏瓦迪瓦',
              Code: 'HAD'
            },
            {
              Name: '法迪福卢',
              Code: 'FAA'
            },
            {
              Name: '费利杜',
              Code: 'FEA'
            },
            {
              Name: '福阿穆拉库',
              Code: 'FMU'
            },
            {
              Name: '哈杜马蒂',
              Code: 'HDH'
            },
            {
              Name: '科卢马杜卢',
              Code: 'KLH'
            },
            {
              Name: '马累',
              Code: 'MAL'
            },
            {
              Name: '马累岛',
              Code: 'MAA'
            },
            {
              Name: '穆拉库',
              Code: 'MUA'
            },
            {
              Name: '南阿里',
              Code: 'AAU'
            },
            {
              Name: '南蒂拉杜马蒂',
              Code: 'THU'
            },
            {
              Name: '南马洛斯马杜卢',
              Code: 'MAU'
            },
            {
              Name: '南米拉杜马杜卢',
              Code: 'MLU'
            },
            {
              Name: '南尼兰杜',
              Code: 'NAU'
            },
            {
              Name: '南苏瓦迪瓦',
              Code: 'HAU'
            }
          ]
        }
      },
      {
        Name: '马耳他',
        Code: 'MLT'
      },
      {
        Name: '马拉维',
        Code: 'MWI',
        State: {
          City: [
            {
              Name: '北部区',
              Code: 'N'
            },
            {
              Name: '南部区',
              Code: 'S'
            },
            {
              Name: '中央区',
              Code: 'C'
            }
          ]
        }
      },
      {
        Name: '马来西亚',
        Code: 'MYS',
        State: [
          {
            Name: '槟榔屿',
            Code: 'PH',
            City: [
              {
                Name: '北海',
                Code: 'BWH'
              },
              {
                Name: '槟城',
                Code: 'PEN'
              },
              {
                Name: '大山脚',
                Code: 'BMJ'
              },
              {
                Name: '高渊',
                Code: 'NTE'
              }
            ]
          },
          {
            Name: '玻璃市',
            Code: 'PS',
            City: {
              Name: '加央',
              Code: 'KGR'
            }
          },
          {
            Name: '丁加奴',
            Code: 'TR',
            City: [
              {
                Name: '甘马挽',
                Code: 'KEM'
              },
              {
                Name: '瓜拉丁加奴',
                Code: 'TGG'
              },
              {
                Name: '龙运',
                Code: 'DGN'
              },
              {
                Name: '马江',
                Code: 'MAR'
              },
              {
                Name: '实兆',
                Code: 'SET'
              },
              {
                Name: '乌鲁',
                Code: 'HUL'
              },
              {
                Name: '勿述',
                Code: 'BES'
              }
            ]
          },
          {
            Name: '吉打',
            Code: 'KD',
            City: [
              {
                Name: '巴东得腊',
                Code: 'PGT'
              },
              {
                Name: '笨筒',
                Code: 'PEN'
              },
              {
                Name: '浮罗交怡',
                Code: 'LGK'
              },
              {
                Name: '哥打士打',
                Code: 'KOR'
              },
              {
                Name: '古邦巴素',
                Code: 'KPA'
              },
              {
                Name: '瓜拉姆达',
                Code: 'KMU'
              },
              {
                Name: '华玲',
                Code: 'BLZ'
              },
              {
                Name: '居林',
                Code: 'KLM'
              },
              {
                Name: '万拉峇鲁',
                Code: 'BMA'
              }
            ]
          },
          {
            Name: '吉兰丹',
            Code: 'KN',
            City: [
              {
                Name: '巴西富地',
                Code: 'PPU'
              },
              {
                Name: '巴西马',
                Code: 'PMA'
              },
              {
                Name: '丹那美拉',
                Code: 'TMR'
              },
              {
                Name: '道北',
                Code: 'TUM'
              },
              {
                Name: '登卓',
                Code: 'BAC'
              },
              {
                Name: '哥打巴鲁',
                Code: 'KBR'
              },
              {
                Name: '瓜拉吉赖',
                Code: 'KUG'
              },
              {
                Name: '话望生',
                Code: 'GMU'
              },
              {
                Name: '马樟',
                Code: 'MAC'
              },
              {
                Name: '日里',
                Code: 'JEL'
              }
            ]
          },
          {
            Name: '吉隆坡',
            Code: 'KL',
            City: {
              Name: '吉隆坡',
              Code: 'KUL'
            }
          },
          {
            Name: '马六甲',
            Code: 'ML',
            City: [
              {
                Name: '马六甲市',
                Code: 'MEL'
              },
              {
                Name: '亚罗牙也',
                Code: 'AOG'
              },
              {
                Name: '野新',
                Code: 'JAS'
              }
            ]
          },
          {
            Name: '纳闽',
            Code: 'LB',
            City: [
              {
                Name: '纳闽',
                Code: 'LBU'
              },
              {
                Name: '维多利亚',
                Code: 'VIC'
              }
            ]
          },
          {
            Name: '彭亨',
            Code: 'PG',
            City: [
              {
                Name: '百乐',
                Code: 'BER'
              },
              {
                Name: '北根',
                Code: 'PEK'
              },
              {
                Name: '淡马鲁',
                Code: 'TEM'
              },
              {
                Name: '而连突',
                Code: 'JER'
              },
              {
                Name: '关丹',
                Code: 'KUA'
              },
              {
                Name: '金马仑高原',
                Code: 'CAH'
              },
              {
                Name: '劳勿',
                Code: 'RAU'
              },
              {
                Name: '立卑',
                Code: 'KUL'
              },
              {
                Name: '马兰',
                Code: 'MAR'
              },
              {
                Name: '文冬',
                Code: 'BEN'
              },
              {
                Name: '云冰',
                Code: 'TOM'
              }
            ]
          },
          {
            Name: '霹雳',
            Code: 'PK',
            City: [
              {
                Name: '安顺',
                Code: 'TAS'
              },
              {
                Name: '丹绒马',
                Code: 'TAM'
              },
              {
                Name: '和丰',
                Code: 'SSP'
              },
              {
                Name: '紅土坎',
                Code: 'LUM'
              },
              {
                Name: '华都牙也',
                Code: 'BGA'
              },
              {
                Name: '江沙',
                Code: 'KAR'
              },
              {
                Name: '太平',
                Code: 'TPG'
              },
              {
                Name: '怡保',
                Code: 'IPH'
              }
            ]
          },
          {
            Name: '柔佛',
            Code: 'JH',
            City: [
              {
                Name: '笨珍',
                Code: 'POW'
              },
              {
                Name: '丰盛港',
                Code: 'MEP'
              },
              {
                Name: '哥打丁宜',
                Code: 'KTI'
              },
              {
                Name: '居銮',
                Code: 'KLA'
              },
              {
                Name: '峇株巴辖',
                Code: 'BAT'
              },
              {
                Name: '麻坡',
                Code: 'MUA'
              },
              {
                Name: '昔加末',
                Code: 'SGM'
              },
              {
                Name: '新山',
                Code: 'JHB'
              }
            ]
          },
          {
            Name: '森美兰',
            Code: 'NS',
            City: [
              {
                Name: '波德申',
                Code: 'PDI'
              },
              {
                Name: '淡边',
                Code: 'TAI'
              },
              {
                Name: '芙蓉',
                Code: 'SRB'
              },
              {
                Name: '瓜拉庇劳',
                Code: 'KPI'
              },
              {
                Name: '林茂',
                Code: 'REM'
              },
              {
                Name: '仁保',
                Code: 'JEP'
              },
              {
                Name: '日叻务',
                Code: 'JEL'
              }
            ]
          },
          {
            Name: '沙巴',
            Code: 'SB',
            City: [
              {
                Name: '吧巴',
                Code: 'PAP'
              },
              {
                Name: '保佛',
                Code: 'BEF'
              },
              {
                Name: '比鲁兰',
                Code: 'BEL'
              },
              {
                Name: '必达士',
                Code: 'PIT'
              },
              {
                Name: '兵南邦',
                Code: 'PMP'
              },
              {
                Name: '担布南',
                Code: 'TAB'
              },
              {
                Name: '丹南',
                Code: 'TEN'
              },
              {
                Name: '斗湖',
                Code: 'TAW'
              },
              {
                Name: '斗亚兰',
                Code: 'TUR'
              },
              {
                Name: '哥打基纳巴鲁',
                Code: 'BKI'
              },
              {
                Name: '哥打马鲁都',
                Code: 'KMU'
              },
              {
                Name: '根地咬',
                Code: 'KEG'
              },
              {
                Name: '古达',
                Code: 'KUD'
              },
              {
                Name: '古打毛律',
                Code: 'KBD'
              },
              {
                Name: '古纳',
                Code: 'KUN'
              },
              {
                Name: '瓜拉班尤',
                Code: 'KPU'
              },
              {
                Name: '京那巴登岸',
                Code: 'KBT'
              },
              {
                Name: '兰脑',
                Code: 'RNU'
              },
              {
                Name: '拿笃',
                Code: 'LDU'
              },
              {
                Name: '纳巴湾',
                Code: 'NAB'
              },
              {
                Name: '山打根',
                Code: 'SDK'
              },
              {
                Name: '西比陶',
                Code: 'SPT'
              },
              {
                Name: '仙本那',
                Code: 'SMM'
              }
            ]
          },
          {
            Name: '沙捞越',
            Code: 'SR',
            City: [
              {
                Name: '古晋',
                Code: 'KCH'
              },
              {
                Name: '加帛',
                Code: 'KPI'
              },
              {
                Name: '林梦',
                Code: 'LMN'
              },
              {
                Name: '美里',
                Code: 'MYY'
              },
              {
                Name: '民都鲁',
                Code: 'BTU'
              },
              {
                Name: '木胶',
                Code: 'MKM'
              },
              {
                Name: '木中',
                Code: 'BTG'
              },
              {
                Name: '三马拉汉',
                Code: 'SMH'
              },
              {
                Name: '斯里阿曼',
                Code: 'SAM'
              },
              {
                Name: '泗里街',
                Code: 'SAR'
              },
              {
                Name: '泗务',
                Code: 'SBW'
              }
            ]
          },
          {
            Name: '雪兰莪',
            Code: 'SL',
            City: [
              {
                Name: '八打灵',
                Code: 'PJA'
              },
              {
                Name: '鹅麦',
                Code: 'GOM'
              },
              {
                Name: '瓜拉冷岳',
                Code: 'KLG'
              },
              {
                Name: '瓜拉雪兰莪',
                Code: 'KSL'
              },
              {
                Name: '沙白安南',
                Code: 'SBM'
              },
              {
                Name: '乌鲁冷岳',
                Code: 'HUL'
              },
              {
                Name: '乌鲁雪兰莪',
                Code: 'HUS'
              },
              {
                Name: '雪邦',
                Code: 'SEP'
              }
            ]
          }
        ]
      },
      {
        Name: '马里',
        Code: 'MLI',
        State: {
          City: [
            {
              Name: '巴马科首都区',
              Code: 'CD'
            },
            {
              Name: '基达尔',
              Code: 'KD'
            },
            {
              Name: '加奥',
              Code: 'GA'
            },
            {
              Name: '卡伊',
              Code: 'KY'
            },
            {
              Name: '库利科罗',
              Code: 'KL'
            },
            {
              Name: '莫普提',
              Code: 'MP'
            },
            {
              Name: '塞古',
              Code: 'SG'
            },
            {
              Name: '通布图',
              Code: 'TB'
            },
            {
              Name: '锡卡索',
              Code: 'SK'
            }
          ]
        }
      },
      {
        Name: '马其顿',
        Code: 'MKD'
      },
      {
        Name: '马绍尔群岛',
        Code: 'MHL'
      },
      {
        Name: '马提尼克',
        Code: 'MTQ'
      },
      {
        Name: '马约特岛',
        Code: 'MYT'
      },
      {
        Name: '曼岛',
        Code: 'IMN'
      },
      {
        Name: '毛里求斯',
        Code: 'MUS'
      },
      {
        Name: '毛里塔尼亚',
        Code: 'MRT',
        State: {
          City: [
            {
              Name: '阿德拉尔',
              Code: 'AD'
            },
            {
              Name: '阿萨巴',
              Code: 'AS'
            },
            {
              Name: '卜拉克纳',
              Code: 'BR'
            },
            {
              Name: '东胡德',
              Code: 'HG'
            },
            {
              Name: '戈尔戈勒',
              Code: 'GO'
            },
            {
              Name: '吉迪马卡',
              Code: 'GM'
            },
            {
              Name: '努瓦迪布湾',
              Code: 'DN'
            },
            {
              Name: '努瓦克肖特特区',
              Code: 'NO'
            },
            {
              Name: '塔甘特',
              Code: 'TA'
            },
            {
              Name: '特拉扎',
              Code: 'TR'
            },
            {
              Name: '提里斯-宰穆尔',
              Code: 'TZ'
            },
            {
              Name: '西胡德',
              Code: 'HC'
            },
            {
              Name: '因希里',
              Code: 'IN'
            }
          ]
        }
      },
      {
        Name: '美国',
        Code: 'USA',
        State: [
          {
            Name: '阿肯色',
            Code: 'AR',
            City: [
              {
                Name: '费耶特维尔',
                Code: 'FYV'
              },
              {
                Name: '史密斯堡',
                Code: 'FSM'
              },
              {
                Name: '小石城',
                Code: 'LIT'
              }
            ]
          },
          {
            Name: '阿拉巴马',
            Code: 'AL',
            City: [
              {
                Name: '伯明罕',
                Code: 'BHM'
              },
              {
                Name: '蒙哥马利',
                Code: 'MGM'
              },
              {
                Name: '莫比尔',
                Code: 'MOB'
              }
            ]
          },
          {
            Name: '阿拉斯加',
            Code: 'AK',
            City: [
              {
                Name: '安克雷奇',
                Code: 'ANC'
              },
              {
                Name: '费尔班克斯',
                Code: 'FAI'
              },
              {
                Name: '朱诺',
                Code: 'JNU'
              }
            ]
          },
          {
            Name: '爱达荷',
            Code: 'ID',
            City: [
              {
                Name: '爱达荷福尔斯',
                Code: 'IDA'
              },
              {
                Name: '波卡特洛',
                Code: 'PIH'
              },
              {
                Name: '博伊西',
                Code: 'BOI'
              },
              {
                Name: '布莱克富特',
                Code: 'BLK'
              },
              {
                Name: '科达伦',
                Code: 'COE'
              },
              {
                Name: '刘易斯顿',
                Code: 'LWS'
              },
              {
                Name: '莫斯科',
                Code: 'MJL'
              },
              {
                Name: '墨菲',
                Code: 'ZMU'
              },
              {
                Name: '楠帕',
                Code: 'NPA'
              },
              {
                Name: '岂彻姆',
                Code: 'QKM'
              },
              {
                Name: '森瓦利',
                Code: 'SVY'
              },
              {
                Name: '亚美利加瀑布城',
                Code: 'YAF'
              }
            ]
          },
          {
            Name: '爱荷华',
            Code: 'IA',
            City: [
              {
                Name: '达文波特',
                Code: 'DVN'
              },
              {
                Name: '得梅因',
                Code: 'DSM'
              },
              {
                Name: '锡达拉皮兹',
                Code: 'CID'
              }
            ]
          },
          {
            Name: '北达科他',
            Code: 'ND',
            City: [
              {
                Name: '俾斯麦',
                Code: 'BIS'
              },
              {
                Name: '大福克斯',
                Code: 'GFK'
              },
              {
                Name: '法戈',
                Code: 'FAR'
              },
              {
                Name: '迈诺特',
                Code: 'MOT'
              }
            ]
          },
          {
            Name: '北卡罗来纳',
            Code: 'NC',
            City: [
              {
                Name: '艾许维尔',
                Code: 'AEV'
              },
              {
                Name: '杜罕',
                Code: 'DHH'
              },
              {
                Name: '格林斯伯勒',
                Code: 'GBO'
              },
              {
                Name: '教堂山',
                Code: 'CHE'
              },
              {
                Name: '罗利',
                Code: 'RAG'
              },
              {
                Name: '洛利杜罕都会区',
                Code: 'RDU'
              },
              {
                Name: '夏洛特',
                Code: 'CRQ'
              }
            ]
          },
          {
            Name: '宾夕法尼亚',
            Code: 'PA',
            City: [
              {
                Name: '阿伦敦',
                Code: 'AEW'
              },
              {
                Name: '费城',
                Code: 'PHL'
              },
              {
                Name: '匹兹堡',
                Code: 'PIT'
              }
            ]
          },
          {
            Name: '德克萨斯',
            Code: 'TX',
            City: [
              {
                Name: '埃尔帕索',
                Code: 'ELP'
              },
              {
                Name: '奥斯汀',
                Code: 'AUS'
              },
              {
                Name: '达拉斯',
                Code: 'DAL'
              },
              {
                Name: '哥帕斯基斯蒂',
                Code: 'CRP'
              },
              {
                Name: '交维斯顿',
                Code: 'GLS'
              },
              {
                Name: '拉雷多',
                Code: 'LRD'
              },
              {
                Name: '麦亚伦',
                Code: 'TXC'
              },
              {
                Name: '圣安东尼奥',
                Code: 'SAT'
              },
              {
                Name: '休斯敦',
                Code: 'HOU'
              }
            ]
          },
          {
            Name: '俄亥俄',
            Code: 'OH',
            City: [
              {
                Name: '代顿',
                Code: 'DYT'
              },
              {
                Name: '哥伦布',
                Code: 'CZX'
              },
              {
                Name: '克利夫兰',
                Code: 'CLE'
              },
              {
                Name: '托莱多',
                Code: 'TOL'
              },
              {
                Name: '辛辛那提',
                Code: 'CVG'
              }
            ]
          },
          {
            Name: '俄克拉荷马',
            Code: 'OK',
            City: [
              {
                Name: '俄克拉荷马城',
                Code: 'OKC'
              },
              {
                Name: '诺曼',
                Code: 'OUN'
              },
              {
                Name: '塔尔萨',
                Code: 'TUL'
              }
            ]
          },
          {
            Name: '俄勒冈',
            Code: 'OR',
            City: [
              {
                Name: '本德',
                Code: 'BZO'
              },
              {
                Name: '波特兰',
                Code: 'PDX'
              },
              {
                Name: '达尔斯',
                Code: 'DLS'
              },
              {
                Name: '达拉斯',
                Code: 'DAC'
              },
              {
                Name: '蒂拉穆克',
                Code: 'TLM'
              },
              {
                Name: '格兰茨帕斯',
                Code: 'XFX'
              },
              {
                Name: '胡德里弗',
                Code: 'HDX'
              },
              {
                Name: '火山口湖',
                Code: 'CTR'
              },
              {
                Name: '科瓦利斯',
                Code: 'YCV'
              },
              {
                Name: '库斯贝',
                Code: 'COB'
              },
              {
                Name: '梅德福',
                Code: 'MFR'
              },
              {
                Name: '塞勒姆',
                Code: 'SLE'
              },
              {
                Name: '圣海伦斯',
                Code: 'STH'
              },
              {
                Name: '斯普林菲尔德',
                Code: 'SPY'
              },
              {
                Name: '尤金',
                Code: 'EUG'
              }
            ]
          },
          {
            Name: '佛罗里达',
            Code: 'FL',
            City: [
              {
                Name: '奥兰多',
                Code: 'ORL'
              },
              {
                Name: '基韦斯特',
                Code: 'EYW'
              },
              {
                Name: '杰克逊维尔',
                Code: 'JAX'
              },
              {
                Name: '卡纳维尔角',
                Code: 'CPV'
              },
              {
                Name: '罗德岱堡',
                Code: 'FLL'
              },
              {
                Name: '迈阿密',
                Code: 'MIA'
              },
              {
                Name: '圣彼德斯堡市',
                Code: 'PIE'
              },
              {
                Name: '塔拉哈西',
                Code: 'TLH'
              },
              {
                Name: '坦帕',
                Code: 'TPA'
              }
            ]
          },
          {
            Name: '佛蒙特',
            Code: 'VT',
            City: [
              {
                Name: '伯灵顿',
                Code: 'BTV'
              },
              {
                Name: '拉特兰',
                Code: 'RUT'
              },
              {
                Name: '南伯灵顿',
                Code: 'ZBR'
              }
            ]
          },
          {
            Name: '哥伦比亚特区',
            Code: 'DC',
            City: {
              Name: '华盛顿哥伦比亚特区',
              Code: 'WAS'
            }
          },
          {
            Name: '华盛顿',
            Code: 'WA',
            City: [
              {
                Name: '斯波坎',
                Code: 'GEG'
              },
              {
                Name: '塔科马',
                Code: 'TTW'
              },
              {
                Name: '西雅图',
                Code: 'SEA'
              }
            ]
          },
          {
            Name: '怀俄明',
            Code: 'WY',
            City: [
              {
                Name: '埃文斯顿',
                Code: 'EVD'
              },
              {
                Name: '卡斯珀',
                Code: 'CPR'
              },
              {
                Name: '拉勒米',
                Code: 'LAR'
              },
              {
                Name: '罗克斯普林斯',
                Code: 'RKS'
              },
              {
                Name: '夏延',
                Code: 'CYS'
              },
              {
                Name: '谢里登',
                Code: 'SHR'
              }
            ]
          },
          {
            Name: '加利福尼亚',
            Code: 'CA',
            City: [
              {
                Name: '旧金山',
                Code: 'SFO'
              },
              {
                Name: '洛杉矶',
                Code: 'LAX'
              },
              {
                Name: '圣迭戈',
                Code: 'SAN'
              },
              {
                Name: '圣何塞',
                Code: 'SJC'
              }
            ]
          },
          {
            Name: '堪萨斯',
            Code: 'KS',
            City: [
              {
                Name: '阿比林',
                Code: 'ABZ'
              },
              {
                Name: '奥弗兰公园',
                Code: 'OVL'
              },
              {
                Name: '哈钦森',
                Code: 'HCH'
              },
              {
                Name: '堪萨斯城',
                Code: 'KCK'
              },
              {
                Name: '莱文沃思',
                Code: 'XIA'
              },
              {
                Name: '劳伦斯',
                Code: 'LWC'
              },
              {
                Name: '曼哈顿',
                Code: 'MHK'
              },
              {
                Name: '托皮卡',
                Code: 'TOP'
              },
              {
                Name: '威奇托',
                Code: 'ICT'
              }
            ]
          },
          {
            Name: '康涅狄格',
            Code: 'CT',
            City: [
              {
                Name: '布里奇波特',
                Code: 'BDR'
              },
              {
                Name: '达里恩',
                Code: 'DAQ'
              },
              {
                Name: '格林尼治',
                Code: 'GRH'
              },
              {
                Name: '哈特福德',
                Code: 'HFD'
              },
              {
                Name: '米德尔顿',
                Code: 'XIN'
              },
              {
                Name: '纽黑文',
                Code: 'HVN'
              },
              {
                Name: '韦斯特波特',
                Code: 'WPT'
              },
              {
                Name: '沃特伯里',
                Code: 'WAT'
              },
              {
                Name: '新不列颠',
                Code: 'NWT'
              }
            ]
          },
          {
            Name: '科罗拉多',
            Code: 'CO',
            City: [
              {
                Name: '阿斯彭',
                Code: 'ASE'
              },
              {
                Name: '奥罗拉',
                Code: 'AUX'
              },
              {
                Name: '博尔德',
                Code: 'WBU'
              },
              {
                Name: '大章克申',
                Code: 'GJT'
              },
              {
                Name: '丹佛',
                Code: 'DEN'
              },
              {
                Name: '柯林斯堡',
                Code: 'FNL'
              },
              {
                Name: '科罗拉多斯普林斯',
                Code: 'COS'
              },
              {
                Name: '韦尔',
                Code: 'VAC'
              }
            ]
          },
          {
            Name: '肯塔基',
            Code: 'KY',
            City: [
              {
                Name: '列克星敦',
                Code: 'LEX'
              },
              {
                Name: '路易斯维尔',
                Code: 'LUI'
              },
              {
                Name: '欧文斯伯勒',
                Code: 'OWB'
              }
            ]
          },
          {
            Name: '路易斯安那',
            Code: 'LA',
            City: [
              {
                Name: '巴吞鲁日',
                Code: 'BTR'
              },
              {
                Name: '什里夫波特',
                Code: 'SHV'
              },
              {
                Name: '新奥尔良',
                Code: 'MSY'
              }
            ]
          },
          {
            Name: '罗德岛',
            Code: 'RI',
            City: [
              {
                Name: '波塔基特',
                Code: 'PAW'
              },
              {
                Name: '克兰斯顿',
                Code: 'CQH'
              },
              {
                Name: '纽波特',
                Code: 'NPO'
              },
              {
                Name: '普罗维登斯',
                Code: 'PVD'
              },
              {
                Name: '韦斯特利',
                Code: 'WST'
              },
              {
                Name: '文索基特',
                Code: 'SFN'
              },
              {
                Name: '沃威克',
                Code: 'UZO'
              }
            ]
          },
          {
            Name: '马里兰',
            Code: 'MD',
            City: [
              {
                Name: '巴尔的摩',
                Code: 'BAL'
              },
              {
                Name: '盖瑟斯堡',
                Code: 'GAI'
              },
              {
                Name: '罗克维尔',
                Code: 'RKV'
              }
            ]
          },
          {
            Name: '马萨诸塞',
            Code: 'MA',
            City: [
              {
                Name: '波士顿',
                Code: 'BZD'
              },
              {
                Name: '斯普林菲尔德',
                Code: 'SFY'
              },
              {
                Name: '伍斯特',
                Code: 'ORH'
              }
            ]
          },
          {
            Name: '蒙大拿',
            Code: 'MT',
            City: [
              {
                Name: '比灵斯',
                Code: 'BGS'
              },
              {
                Name: '大瀑布村',
                Code: 'GTF'
              },
              {
                Name: '米苏拉',
                Code: 'MSO'
              }
            ]
          },
          {
            Name: '密苏里',
            Code: 'MO',
            City: [
              {
                Name: '哥伦比亚',
                Code: 'COV'
              },
              {
                Name: '杰佛逊市',
                Code: 'JEF'
              },
              {
                Name: '堪萨斯城',
                Code: 'MKC'
              },
              {
                Name: '圣路易斯',
                Code: 'STL'
              },
              {
                Name: '斯普林菲尔德',
                Code: 'SGF'
              }
            ]
          },
          {
            Name: '密西西比',
            Code: 'MS',
            City: [
              {
                Name: '比洛克西',
                Code: 'BIX'
              },
              {
                Name: '格尔夫波特',
                Code: 'GPT'
              },
              {
                Name: '格林维尔',
                Code: 'GLH'
              },
              {
                Name: '哈蒂斯堡',
                Code: 'HBG'
              },
              {
                Name: '杰克逊',
                Code: 'JAN'
              },
              {
                Name: '默里迪恩',
                Code: 'MEI'
              },
              {
                Name: '维克斯堡',
                Code: 'VKS'
              }
            ]
          },
          {
            Name: '密歇根',
            Code: 'MI',
            City: [
              {
                Name: '安娜堡',
                Code: 'ARB'
              },
              {
                Name: '巴特尔克里克',
                Code: 'BTL'
              },
              {
                Name: '贝城',
                Code: 'BCY'
              },
              {
                Name: '大急流城',
                Code: 'GRR'
              },
              {
                Name: '迪尔伯恩',
                Code: 'DEO'
              },
              {
                Name: '底特律',
                Code: 'DET'
              },
              {
                Name: '弗林特',
                Code: 'FNT'
              },
              {
                Name: '怀恩多特',
                Code: 'WYD'
              },
              {
                Name: '卡拉马袓',
                Code: 'AZO'
              },
              {
                Name: '兰辛',
                Code: 'LAN'
              },
              {
                Name: '马斯基根',
                Code: 'MKG'
              },
              {
                Name: '庞菷亚克',
                Code: 'PTK'
              },
              {
                Name: '萨吉诺',
                Code: 'SGM'
              },
              {
                Name: '苏圣玛丽',
                Code: 'SSM'
              },
              {
                Name: '沃伦',
                Code: 'WAM'
              },
              {
                Name: '休伦港',
                Code: 'PHN'
              }
            ]
          },
          {
            Name: '缅因',
            Code: 'ME',
            City: [
              {
                Name: '班戈',
                Code: 'BNQ'
              },
              {
                Name: '波特兰',
                Code: 'POL'
              },
              {
                Name: '刘易斯顿',
                Code: 'QLW'
              }
            ]
          },
          {
            Name: '明尼苏达',
            Code: 'MN',
            City: [
              {
                Name: '罗切斯特',
                Code: 'RST'
              },
              {
                Name: '明尼阿波利斯',
                Code: 'MES'
              },
              {
                Name: '圣保罗',
                Code: 'STP'
              }
            ]
          },
          {
            Name: '南达科他',
            Code: 'SD',
            City: [
              {
                Name: '阿伯丁',
                Code: 'ABK'
              },
              {
                Name: '拉皮德城',
                Code: 'RAP'
              },
              {
                Name: '苏福尔斯',
                Code: 'FSD'
              }
            ]
          },
          {
            Name: '南卡罗来纳',
            Code: 'SC',
            City: [
              {
                Name: '北查尔斯顿',
                Code: 'NTS'
              },
              {
                Name: '查尔斯顿',
                Code: 'CHS'
              },
              {
                Name: '哥伦比亚',
                Code: 'COV'
              }
            ]
          },
          {
            Name: '内布拉斯加',
            Code: 'NE',
            City: [
              {
                Name: '奥马哈',
                Code: 'OMA'
              },
              {
                Name: '贝尔维尤',
                Code: 'XDE'
              },
              {
                Name: '林肯',
                Code: 'LNK'
              }
            ]
          },
          {
            Name: '内华达',
            Code: 'NV',
            City: [
              {
                Name: '埃尔科',
                Code: 'EKO'
              },
              {
                Name: '北拉斯维加斯',
                Code: 'NVS'
              },
              {
                Name: '弗吉尼亚城',
                Code: 'VGI'
              },
              {
                Name: '亨德森',
                Code: 'HNZ'
              },
              {
                Name: '卡森城',
                Code: 'CSN'
              },
              {
                Name: '拉斯维加斯',
                Code: 'LAS'
              },
              {
                Name: '里诺',
                Code: 'RNO'
              },
              {
                Name: '斯帕克斯',
                Code: 'SPK'
              }
            ]
          },
          {
            Name: '纽约',
            Code: 'NY',
            City: [
              {
                Name: '布法罗',
                Code: 'FFO'
              },
              {
                Name: '罗切斯特',
                Code: 'ROC'
              },
              {
                Name: '纽约市',
                Code: 'QEE'
              }
            ]
          },
          {
            Name: '特拉华',
            Code: 'DE',
            City: [
              {
                Name: '多佛',
                Code: 'DOR'
              },
              {
                Name: '纽瓦克',
                Code: 'NWK'
              },
              {
                Name: '威明顿',
                Code: 'ILG'
              }
            ]
          },
          {
            Name: '田纳西',
            Code: 'TN',
            City: [
              {
                Name: '布利斯托',
                Code: 'BSJ'
              },
              {
                Name: '查塔努加',
                Code: 'CHA'
              },
              {
                Name: '金斯波特',
                Code: 'TRI'
              },
              {
                Name: '孟菲斯',
                Code: 'MEM'
              },
              {
                Name: '纳什维尔',
                Code: 'BNA'
              },
              {
                Name: '诺克斯维尔',
                Code: 'TYS'
              },
              {
                Name: '三城区',
                Code: 'YTC'
              },
              {
                Name: '士麦那',
                Code: 'MQY'
              },
              {
                Name: '斯普林希尔',
                Code: 'RGI'
              },
              {
                Name: '约翰逊城',
                Code: 'JCY'
              }
            ]
          },
          {
            Name: '威斯康星',
            Code: 'WI',
            City: [
              {
                Name: '阿普尓顿',
                Code: 'ATW'
              },
              {
                Name: '奥什科什',
                Code: 'OSH'
              },
              {
                Name: '格林贝',
                Code: 'GBK'
              },
              {
                Name: '基诺沙',
                Code: 'ENW'
              },
              {
                Name: '拉克罗斯',
                Code: 'LSE'
              },
              {
                Name: '拉辛',
                Code: 'RAC'
              },
              {
                Name: '马尼托沃克',
                Code: 'MTW'
              },
              {
                Name: '迈迪逊',
                Code: 'QMD'
              },
              {
                Name: '密尔沃基',
                Code: 'MKE'
              },
              {
                Name: '欧克莱尓',
                Code: 'EAU'
              },
              {
                Name: '沃索',
                Code: 'AUW'
              },
              {
                Name: '希博伊根',
                Code: 'SBM'
              }
            ]
          },
          {
            Name: '维吉尼亚',
            Code: 'VA',
            City: [
              {
                Name: '弗吉尼亚比奇',
                Code: 'VAB'
              },
              {
                Name: '诺福克',
                Code: 'ORF'
              },
              {
                Name: '切萨皮克',
                Code: 'HTW'
              }
            ]
          },
          {
            Name: '西佛吉尼亚',
            Code: 'WV',
            City: [
              {
                Name: '查尔斯顿',
                Code: 'CRW'
              },
              {
                Name: '亨廷顿',
                Code: 'HNU'
              },
              {
                Name: '帕克斯堡',
                Code: 'PKB'
              }
            ]
          },
          {
            Name: '夏威夷',
            Code: 'HI',
            City: [
              {
                Name: '凯卢阿',
                Code: 'KHH'
              },
              {
                Name: '檀香山',
                Code: 'HNL'
              },
              {
                Name: '希洛',
                Code: 'ITO'
              }
            ]
          },
          {
            Name: '新罕布什尔',
            Code: 'NH',
            City: [
              {
                Name: '康科德',
                Code: 'CON'
              },
              {
                Name: '曼彻斯特',
                Code: 'MHT'
              },
              {
                Name: '纳舒厄',
                Code: 'ASH'
              }
            ]
          },
          {
            Name: '新墨西哥',
            Code: 'NM',
            City: [
              {
                Name: '阿尔伯克基',
                Code: 'ABQ'
              },
              {
                Name: '拉斯克鲁塞斯',
                Code: 'LRU'
              },
              {
                Name: '罗斯韦尔',
                Code: 'ROW'
              },
              {
                Name: '圣菲',
                Code: 'SAF'
              }
            ]
          },
          {
            Name: '新泽西',
            Code: 'NJ',
            City: [
              {
                Name: '纽瓦克',
                Code: 'NRK'
              },
              {
                Name: '帕特森',
                Code: 'PAT'
              },
              {
                Name: '泽西城',
                Code: 'JEC'
              }
            ]
          },
          {
            Name: '亚利桑那',
            Code: 'AZ',
            City: [
              {
                Name: '凤凰城',
                Code: 'PHX'
              },
              {
                Name: '格兰代尔',
                Code: 'GDA'
              },
              {
                Name: '梅萨',
                Code: 'MQA'
              },
              {
                Name: '史卡兹代尔',
                Code: 'STZ'
              },
              {
                Name: '坦普',
                Code: 'TPE'
              },
              {
                Name: '图森',
                Code: 'TUC'
              },
              {
                Name: '优玛',
                Code: 'YUM'
              }
            ]
          },
          {
            Name: '伊利诺斯',
            Code: 'IL',
            City: [
              {
                Name: '奥尔顿',
                Code: 'ALN'
              },
              {
                Name: '奥罗拉',
                Code: 'AUZ'
              },
              {
                Name: '布卢明顿',
                Code: 'BLO'
              },
              {
                Name: '丹维尓',
                Code: 'DVI'
              },
              {
                Name: '迪卡尔布',
                Code: 'DEK'
              },
              {
                Name: '迪凯持',
                Code: 'DEC'
              },
              {
                Name: '东圣路易斯',
                Code: 'ESL'
              },
              {
                Name: '厄巴纳-香槟',
                Code: 'CMI'
              },
              {
                Name: '盖尔斯堡',
                Code: 'GSU'
              },
              {
                Name: '卡本代尔',
                Code: 'MDH'
              },
              {
                Name: '罗克艾兰',
                Code: 'RKI'
              },
              {
                Name: '罗克福德',
                Code: 'RFD'
              },
              {
                Name: '诺黙尔',
                Code: 'NOM'
              },
              {
                Name: '皮奥里亚',
                Code: 'PLA'
              },
              {
                Name: '森特勒利亚',
                Code: 'CRA'
              },
              {
                Name: '斯普林菲尔德',
                Code: 'SPI'
              },
              {
                Name: '沃其根',
                Code: 'UGN'
              },
              {
                Name: '芝加哥',
                Code: 'CHI'
              }
            ]
          },
          {
            Name: '印第安那',
            Code: 'IN',
            City: [
              {
                Name: '埃文斯维尔',
                Code: 'EVV'
              },
              {
                Name: '韦恩堡',
                Code: 'FWA'
              },
              {
                Name: '印第安纳波利斯',
                Code: 'IND'
              }
            ]
          },
          {
            Name: '犹他',
            Code: 'UT',
            City: [
              {
                Name: '奥格登',
                Code: 'OGD'
              },
              {
                Name: '雷登',
                Code: 'LTJ'
              },
              {
                Name: '欧仁',
                Code: 'OEU'
              },
              {
                Name: '帕克城',
                Code: 'PAC'
              },
              {
                Name: '普罗沃',
                Code: 'PVU'
              },
              {
                Name: '圣乔治',
                Code: 'SGU'
              },
              {
                Name: '西瓦利城',
                Code: 'WVC'
              },
              {
                Name: '盐湖城',
                Code: 'SLC'
              }
            ]
          },
          {
            Name: '佐治亚',
            Code: 'GA',
            City: [
              {
                Name: '奥古斯塔',
                Code: 'AUT'
              },
              {
                Name: '哥伦布',
                Code: 'CZX'
              },
              {
                Name: '梅肯',
                Code: 'MCN'
              },
              {
                Name: '沙瓦纳',
                Code: 'SAV'
              },
              {
                Name: '亚特兰大',
                Code: 'TAT'
              }
            ]
          }
        ]
      },
      {
        Name: '美属萨摩亚',
        Code: 'ASM',
        State: {
          City: [
            {
              Name: '阿纳',
              Code: 'AAN'
            },
            {
              Name: '阿图阿',
              Code: 'ATU'
            },
            {
              Name: '艾加伊勒泰',
              Code: 'AIT'
            },
            {
              Name: '法塞莱莱阿加',
              Code: 'FAA'
            },
            {
              Name: '加盖福毛加',
              Code: 'GFG'
            },
            {
              Name: '加加埃毛加',
              Code: 'GMG'
            },
            {
              Name: '帕劳利',
              Code: 'PAL'
            },
            {
              Name: '萨图帕伊泰阿',
              Code: 'SAT'
            },
            {
              Name: '萨瓦伊岛',
              Code: 'SAV'
            },
            {
              Name: '图阿马萨加',
              Code: 'TUA'
            },
            {
              Name: '瓦奥福诺蒂',
              Code: 'VAF'
            },
            {
              Name: '韦西加诺',
              Code: 'VAI'
            },
            {
              Name: '乌波卢岛',
              Code: 'UPO'
            }
          ]
        }
      },
      {
        Name: '美属外岛',
        Code: 'UMI'
      },
      {
        Name: '蒙古',
        Code: 'MNG',
        State: {
          City: [
            {
              Name: '巴彦洪格尔',
              Code: '69'
            },
            {
              Name: '巴彦乌勒盖',
              Code: '71'
            },
            {
              Name: '布尔干',
              Code: '67'
            },
            {
              Name: '达尔汗乌勒',
              Code: '37'
            },
            {
              Name: '东方',
              Code: '61'
            },
            {
              Name: '东戈壁',
              Code: '63'
            },
            {
              Name: '鄂尔浑',
              Code: '35'
            },
            {
              Name: '戈壁阿尔泰',
              Code: '65'
            },
            {
              Name: '戈壁苏木贝尔',
              Code: '64'
            },
            {
              Name: '后杭爱',
              Code: '73'
            },
            {
              Name: '科布多',
              Code: '43'
            },
            {
              Name: '肯特',
              Code: '39'
            },
            {
              Name: '库苏古尔',
              Code: '41'
            },
            {
              Name: '南戈壁',
              Code: 'UMN'
            },
            {
              Name: '前杭爱',
              Code: 'UVO'
            },
            {
              Name: '色楞格',
              Code: '49'
            },
            {
              Name: '苏赫巴托尔',
              Code: '51'
            },
            {
              Name: '乌布苏',
              Code: '46'
            },
            {
              Name: '乌兰巴托市',
              Code: '1'
            },
            {
              Name: '扎布汗',
              Code: '57'
            },
            {
              Name: '中戈壁',
              Code: '59'
            },
            {
              Name: '中央',
              Code: '47'
            }
          ]
        }
      },
      {
        Name: '蒙特塞拉特',
        Code: 'MSR'
      },
      {
        Name: '孟加拉',
        Code: 'BGD',
        State: {
          City: [
            {
              Name: '达卡',
              Code: 'DAC'
            },
            {
              Name: '吉大港',
              Code: 'CGP'
            },
            {
              Name: '库尔纳',
              Code: 'KHL'
            }
          ]
        }
      },
      {
        Name: '密克罗尼西亚',
        Code: 'FSM'
      },
      {
        Name: '秘鲁',
        Code: 'PER',
        State: {
          City: [
            {
              Name: '阿雷基帕',
              Code: 'AR'
            },
            {
              Name: '阿普里马克',
              Code: 'AP'
            },
            {
              Name: '阿亚库乔',
              Code: 'AY'
            },
            {
              Name: '安卡什',
              Code: 'AN'
            },
            {
              Name: '胡利亚卡',
              Code: 'JUL'
            },
            {
              Name: '胡宁',
              Code: 'JU'
            },
            {
              Name: '卡哈马卡',
              Code: 'CJ'
            },
            {
              Name: '卡亚俄',
              Code: 'CL'
            },
            {
              Name: '库斯科',
              Code: 'CU'
            },
            {
              Name: '拉利伯塔德',
              Code: 'LD'
            },
            {
              Name: '兰巴耶克',
              Code: 'LY'
            },
            {
              Name: '利马',
              Code: 'LI'
            },
            {
              Name: '洛雷托',
              Code: 'LO'
            },
            {
              Name: '马德雷德迪奥斯',
              Code: 'MD'
            },
            {
              Name: '莫克瓜',
              Code: 'MO'
            },
            {
              Name: '帕斯科',
              Code: 'PA'
            },
            {
              Name: '皮乌拉',
              Code: 'PI'
            },
            {
              Name: '普诺',
              Code: 'PU'
            },
            {
              Name: '钦博特',
              Code: 'CHM'
            },
            {
              Name: '钦查阿尔塔',
              Code: 'CHI'
            },
            {
              Name: '圣马丁',
              Code: 'SM'
            },
            {
              Name: '苏拉纳',
              Code: 'SUL'
            },
            {
              Name: '塔克纳',
              Code: 'TA'
            },
            {
              Name: '通贝斯',
              Code: 'TU'
            },
            {
              Name: '瓦努科',
              Code: 'HO'
            },
            {
              Name: '万卡维利卡',
              Code: 'HV'
            },
            {
              Name: '乌卡亚利',
              Code: 'UC'
            },
            {
              Name: '亚马孙',
              Code: 'AM'
            },
            {
              Name: '伊卡',
              Code: 'IC'
            }
          ]
        }
      },
      {
        Name: '缅甸',
        Code: 'MMR',
        State: {
          City: [
            {
              Name: '勃固省',
              Code: 'BG'
            },
            {
              Name: '掸邦',
              Code: 'SH'
            },
            {
              Name: '德林达依省',
              Code: 'TN'
            },
            {
              Name: '克伦邦',
              Code: 'KN'
            },
            {
              Name: '克钦邦',
              Code: 'KC'
            },
            {
              Name: '克耶邦',
              Code: 'KH'
            },
            {
              Name: '马圭省',
              Code: 'MG'
            },
            {
              Name: '曼德勒省',
              Code: 'MD'
            },
            {
              Name: '孟邦',
              Code: 'MN'
            },
            {
              Name: '钦邦',
              Code: 'CH'
            },
            {
              Name: '若开邦',
              Code: 'RK'
            },
            {
              Name: '实皆省',
              Code: 'SG'
            },
            {
              Name: '仰光省',
              Code: 'YG'
            },
            {
              Name: '伊洛瓦底省',
              Code: 'AY'
            }
          ]
        }
      },
      {
        Name: '摩尔多瓦',
        Code: 'MDA'
      },
      {
        Name: '摩洛哥',
        Code: 'MAR',
        State: {
          City: [
            {
              Name: '丹吉尔',
              Code: 'TGR'
            },
            {
              Name: '得土安',
              Code: 'TET'
            },
            {
              Name: '非斯',
              Code: 'FES'
            },
            {
              Name: '卡萨布兰卡',
              Code: 'CBL'
            },
            {
              Name: '拉巴特',
              Code: 'RSA'
            },
            {
              Name: '马拉喀什',
              Code: 'MRK'
            },
            {
              Name: '梅克内斯',
              Code: 'MKN'
            },
            {
              Name: '乌季达',
              Code: 'OUJ'
            },
            {
              Name: '西撒哈拉',
              Code: 'WSH'
            }
          ]
        }
      },
      {
        Name: '摩纳哥',
        Code: 'MCO'
      },
      {
        Name: '莫桑比克',
        Code: 'MOZ'
      },
      {
        Name: '墨西哥',
        Code: 'MEX',
        State: {
          City: [
            {
              Name: '阿瓜斯卡连斯特',
              Code: 'AGU'
            },
            {
              Name: '阿卡普尔科',
              Code: 'ACA'
            },
            {
              Name: '埃莫西约',
              Code: 'HMO'
            },
            {
              Name: '埃佩切',
              Code: 'CAM'
            },
            {
              Name: '奥夫雷贡城',
              Code: 'OBR'
            },
            {
              Name: '奥里萨巴',
              Code: 'ORI'
            },
            {
              Name: '巴利城',
              Code: 'VHM'
            },
            {
              Name: '巴亚尔塔港',
              Code: 'PVR'
            },
            {
              Name: '比利亚埃尔莫萨',
              Code: 'VSA'
            },
            {
              Name: '波萨里卡',
              Code: 'PRH'
            },
            {
              Name: '蒂华纳',
              Code: 'TIJ'
            },
            {
              Name: '杜兰戈',
              Code: 'DUR'
            },
            {
              Name: '恩塞纳达',
              Code: 'ESE'
            },
            {
              Name: '瓜达拉哈拉',
              Code: 'GDL'
            },
            {
              Name: '瓜纳华托',
              Code: 'GUA'
            },
            {
              Name: '哈拉帕',
              Code: 'JAL'
            },
            {
              Name: '华雷斯',
              Code: 'JUZ'
            },
            {
              Name: '华雷斯港',
              Code: 'BJU'
            },
            {
              Name: '卡门',
              Code: 'CAR'
            },
            {
              Name: '科利马',
              Code: 'COL'
            },
            {
              Name: '克雷塔罗',
              Code: 'QUE'
            },
            {
              Name: '库埃纳瓦卡',
              Code: 'CVC'
            },
            {
              Name: '库利阿坎',
              Code: 'CUL'
            },
            {
              Name: '夸察夸拉克斯',
              Code: 'COA'
            },
            {
              Name: '拉巴斯',
              Code: 'LAP'
            },
            {
              Name: '莱昂',
              Code: 'LEN'
            },
            {
              Name: '雷诺萨',
              Code: 'REX'
            },
            {
              Name: '洛斯莫奇斯',
              Code: 'LMM'
            },
            {
              Name: '马萨特兰',
              Code: 'MZT'
            },
            {
              Name: '马塔莫罗斯',
              Code: 'MAM'
            },
            {
              Name: '梅里达',
              Code: 'MID'
            },
            {
              Name: '蒙克洛瓦',
              Code: 'LOV'
            },
            {
              Name: '蒙特雷',
              Code: 'MTY'
            },
            {
              Name: '莫雷利亚',
              Code: 'MLM'
            },
            {
              Name: '墨西哥城',
              Code: 'MEX'
            },
            {
              Name: '墨西卡利',
              Code: 'MXL'
            },
            {
              Name: '诺加莱斯',
              Code: 'NOG'
            },
            {
              Name: '帕丘卡',
              Code: 'PAC'
            },
            {
              Name: '普埃布拉',
              Code: 'PUE'
            },
            {
              Name: '奇尔潘辛戈',
              Code: 'CHI'
            },
            {
              Name: '奇瓦瓦',
              Code: 'CHH'
            },
            {
              Name: '切图马尔',
              Code: 'CTM'
            },
            {
              Name: '萨尔蒂约',
              Code: 'SLW'
            },
            {
              Name: '萨卡特卡斯',
              Code: 'ZAC'
            },
            {
              Name: '塞拉亚',
              Code: 'CLY'
            },
            {
              Name: '圣路易斯波托亚',
              Code: 'SLP'
            },
            {
              Name: '塔帕丘拉',
              Code: 'TAP'
            },
            {
              Name: '坦皮科',
              Code: 'TAM'
            },
            {
              Name: '特拉斯卡拉',
              Code: 'TLA'
            },
            {
              Name: '特皮克',
              Code: 'TPQ'
            },
            {
              Name: '特瓦坎',
              Code: 'TCN'
            },
            {
              Name: '图斯特拉-古铁雷斯',
              Code: 'TGZ'
            },
            {
              Name: '托雷翁',
              Code: 'TRC'
            },
            {
              Name: '托卢卡',
              Code: 'TLC'
            },
            {
              Name: '瓦哈卡',
              Code: 'OAX'
            },
            {
              Name: '维多利亚城',
              Code: 'VIC'
            },
            {
              Name: '韦拉克鲁斯',
              Code: 'VER'
            },
            {
              Name: '乌鲁阿潘',
              Code: 'UPN'
            },
            {
              Name: '新拉雷多',
              Code: 'NLE'
            },
            {
              Name: '伊拉普阿托',
              Code: 'IRP'
            }
          ]
        }
      },
      {
        Name: '纳米比亚',
        Code: 'NAM',
        State: {
          City: [
            {
              Name: '埃龙戈',
              Code: 'ER'
            },
            {
              Name: '奥汉圭纳',
              Code: 'OW'
            },
            {
              Name: '奥卡万戈',
              Code: 'KV'
            },
            {
              Name: '奥马赫科',
              Code: 'OK'
            },
            {
              Name: '奥姆沙蒂',
              Code: 'OT'
            },
            {
              Name: '奥乔宗蒂约巴',
              Code: 'OJ'
            },
            {
              Name: '奥沙纳',
              Code: 'ON'
            },
            {
              Name: '奥希科托',
              Code: 'OO'
            },
            {
              Name: '哈达普',
              Code: 'HA'
            },
            {
              Name: '霍马斯',
              Code: 'KH'
            },
            {
              Name: '卡拉斯',
              Code: 'KR'
            },
            {
              Name: '卡普里维',
              Code: 'CA'
            },
            {
              Name: '库内内',
              Code: 'KU'
            }
          ]
        }
      },
      {
        Name: '南非',
        Code: 'ZAF',
        State: {
          City: [
            {
              Name: '阿平顿',
              Code: 'UTN'
            },
            {
              Name: '艾利弗山',
              Code: 'MAY'
            },
            {
              Name: '彼德马里茨堡',
              Code: 'PZB'
            },
            {
              Name: '彼德斯堡',
              Code: 'PTG'
            },
            {
              Name: '比勒陀利亚',
              Code: 'PRY'
            },
            {
              Name: '比索',
              Code: 'BIY'
            },
            {
              Name: '布雷达斯多普',
              Code: 'BDD'
            },
            {
              Name: '布隆方丹',
              Code: 'BFN'
            },
            {
              Name: '布隆克斯特斯普利特',
              Code: 'BHT'
            },
            {
              Name: '德阿尔',
              Code: 'DAA'
            },
            {
              Name: '德班',
              Code: 'DUR'
            },
            {
              Name: '邓迪',
              Code: 'DUN'
            },
            {
              Name: '东巴克利',
              Code: 'BAE'
            },
            {
              Name: '东伦敦',
              Code: 'ELS'
            },
            {
              Name: '弗雷堡',
              Code: 'VRU'
            },
            {
              Name: '弗里尼欣',
              Code: 'VGG'
            },
            {
              Name: '格罗布莱斯达尔',
              Code: 'GBD'
            },
            {
              Name: '基雅尼',
              Code: 'GIY'
            },
            {
              Name: '金伯利',
              Code: 'KIM'
            },
            {
              Name: '开普敦',
              Code: 'CPT'
            },
            {
              Name: '克莱克斯多普',
              Code: 'KXE'
            },
            {
              Name: '库鲁曼',
              Code: 'KMH'
            },
            {
              Name: '昆士敦',
              Code: 'UTW'
            },
            {
              Name: '莱迪史密斯',
              Code: 'LAY'
            },
            {
              Name: '兰德方丹',
              Code: 'RFT'
            },
            {
              Name: '理查兹湾',
              Code: 'RCB'
            },
            {
              Name: '利斯滕堡',
              Code: 'RSB'
            },
            {
              Name: '米德尔堡',
              Code: 'MDB'
            },
            {
              Name: '姆库泽',
              Code: 'MZQ'
            },
            {
              Name: '穆里斯堡',
              Code: 'MOO'
            },
            {
              Name: '内尔斯普雷特',
              Code: 'NLP'
            },
            {
              Name: '尼尔斯特隆',
              Code: 'NYL'
            },
            {
              Name: '纽卡斯尔',
              Code: 'NCS'
            },
            {
              Name: '乔治',
              Code: 'GRJ'
            },
            {
              Name: '萨索尔堡',
              Code: 'SAS'
            },
            {
              Name: '瑟孔达',
              Code: 'ZEC'
            },
            {
              Name: '特克索波',
              Code: 'IXO'
            },
            {
              Name: '特隆普斯堡',
              Code: 'TPB'
            },
            {
              Name: '跳羚',
              Code: 'SBU'
            },
            {
              Name: '图拉马哈谢',
              Code: 'TLH'
            },
            {
              Name: '托霍延杜',
              Code: 'THY'
            },
            {
              Name: '韦茨肖克',
              Code: 'WSH'
            },
            {
              Name: '韦尔科姆',
              Code: 'WEL'
            },
            {
              Name: '乌伦迪',
              Code: 'ULD'
            },
            {
              Name: '乌姆塔塔',
              Code: 'UTT'
            },
            {
              Name: '伍斯特',
              Code: 'WOR'
            },
            {
              Name: '西博福特',
              Code: 'BEW'
            },
            {
              Name: '谢普斯通港',
              Code: 'PSS'
            },
            {
              Name: '伊丽莎白港',
              Code: 'PLZ'
            },
            {
              Name: '约翰内斯堡',
              Code: 'JNB'
            }
          ]
        }
      },
      {
        Name: '南极洲',
        Code: 'ATA'
      },
      {
        Name: '南乔治亚和南桑德威奇群岛',
        Code: 'SGS'
      },
      {
        Name: '瑙鲁',
        Code: 'NRU'
      },
      {
        Name: '尼泊尔',
        Code: 'NPL',
        State: {
          City: [
            {
              Name: '巴格马蒂',
              Code: 'BA'
            },
            {
              Name: '道拉吉里',
              Code: 'DH'
            },
            {
              Name: '甘达基',
              Code: 'GA'
            },
            {
              Name: '戈西',
              Code: 'KO'
            },
            {
              Name: '格尔纳利',
              Code: 'KA'
            },
            {
              Name: '贾纳克布尔',
              Code: 'JA'
            },
            {
              Name: '拉布蒂',
              Code: 'RA'
            },
            {
              Name: '蓝毗尼',
              Code: 'LU'
            },
            {
              Name: '马哈卡利',
              Code: 'MA'
            },
            {
              Name: '梅吉',
              Code: 'ME'
            },
            {
              Name: '纳拉亚尼',
              Code: 'NA'
            },
            {
              Name: '佩里',
              Code: 'BH'
            },
            {
              Name: '萨加玛塔',
              Code: 'SA'
            },
            {
              Name: '塞蒂',
              Code: 'SE'
            }
          ]
        }
      },
      {
        Name: '尼加拉瓜',
        Code: 'NIC',
        State: {
          City: [
            {
              Name: '埃斯特利',
              Code: 'ES'
            },
            {
              Name: '北大西洋',
              Code: 'AN'
            },
            {
              Name: '博阿科',
              Code: 'BO'
            },
            {
              Name: '格拉纳达',
              Code: 'GR'
            },
            {
              Name: '卡拉索',
              Code: 'CA'
            },
            {
              Name: '莱昂',
              Code: 'LE'
            },
            {
              Name: '里瓦斯',
              Code: 'RV'
            },
            {
              Name: '马德里斯',
              Code: 'MD'
            },
            {
              Name: '马那瓜',
              Code: 'MN'
            },
            {
              Name: '马萨亚',
              Code: 'MS'
            },
            {
              Name: '马塔加尔帕',
              Code: 'MT'
            },
            {
              Name: '南大西洋',
              Code: 'AS'
            },
            {
              Name: '奇南德加',
              Code: 'CD'
            },
            {
              Name: '琼塔莱斯',
              Code: 'CT'
            },
            {
              Name: '圣胡安河',
              Code: 'SJ'
            },
            {
              Name: '希诺特加',
              Code: 'JI'
            },
            {
              Name: '新塞哥维亚',
              Code: 'NS'
            }
          ]
        }
      },
      {
        Name: '尼日尔',
        Code: 'NER',
        State: {
          City: [
            {
              Name: '阿加德兹',
              Code: 'AJY'
            },
            {
              Name: '迪法',
              Code: 'DIF'
            },
            {
              Name: '蒂拉贝里',
              Code: 'TIL'
            },
            {
              Name: '多索',
              Code: 'DSS'
            },
            {
              Name: '津德尔',
              Code: 'ZND'
            },
            {
              Name: '马拉迪',
              Code: 'MFQ'
            },
            {
              Name: '尼亚美市',
              Code: 'NIM'
            },
            {
              Name: '塔瓦',
              Code: 'THZ'
            }
          ]
        }
      },
      {
        Name: '尼日利亚',
        Code: 'NGA',
        State: {
          City: [
            {
              Name: '阿比亚',
              Code: 'ABV'
            },
            {
              Name: '奥博莫绍',
              Code: 'OGB'
            },
            {
              Name: '卡诺',
              Code: 'KAN'
            },
            {
              Name: '拉各斯',
              Code: 'LOS'
            },
            {
              Name: '伊巴丹',
              Code: 'IBA'
            }
          ]
        }
      },
      {
        Name: '纽埃',
        Code: 'NIU'
      },
      {
        Name: '挪威',
        Code: 'NOR',
        State: {
          City: [
            {
              Name: '阿克什胡斯',
              Code: '2'
            },
            {
              Name: '奥普兰',
              Code: '5'
            },
            {
              Name: '奥斯陆市',
              Code: '3'
            },
            {
              Name: '北特伦德拉格',
              Code: '17'
            },
            {
              Name: '布斯克吕',
              Code: '6'
            },
            {
              Name: '东阿格德尔',
              Code: '9'
            },
            {
              Name: '东福尔',
              Code: '1'
            },
            {
              Name: '芬马克',
              Code: '20'
            },
            {
              Name: '海德马克',
              Code: '4'
            },
            {
              Name: '霍达兰',
              Code: '12'
            },
            {
              Name: '罗加兰',
              Code: '11'
            },
            {
              Name: '默勒－鲁姆斯达尔',
              Code: '15'
            },
            {
              Name: '南特伦德拉格',
              Code: '16'
            },
            {
              Name: '诺尔兰',
              Code: '18'
            },
            {
              Name: '松恩－菲尤拉讷',
              Code: '14'
            },
            {
              Name: '泰勒马克',
              Code: '8'
            },
            {
              Name: '特罗姆斯',
              Code: '19'
            },
            {
              Name: '西阿格德尔',
              Code: '10'
            },
            {
              Name: '西福尔',
              Code: '7'
            }
          ]
        }
      },
      {
        Name: '诺福克',
        Code: 'NFK'
      },
      {
        Name: '帕劳群岛',
        Code: 'PLW'
      },
      {
        Name: '皮特凯恩',
        Code: 'PCN'
      },
      {
        Name: '葡萄牙',
        Code: 'PRT',
        State: {
          City: [
            {
              Name: '滨海阿连特茹',
              Code: 'ALL'
            },
            {
              Name: '滨海皮尼亚尔',
              Code: 'PLT'
            },
            {
              Name: '波尔图',
              Code: 'VDP'
            },
            {
              Name: '杜罗',
              Code: 'MDR'
            },
            {
              Name: '恩特拉杜罗伏日',
              Code: 'EDV'
            },
            {
              Name: '法鲁',
              Code: 'FAO'
            },
            {
              Name: '丰沙尔',
              Code: 'FUN'
            },
            {
              Name: '卡瓦多',
              Code: 'CAV'
            },
            {
              Name: '科瓦贝拉',
              Code: 'CLB'
            },
            {
              Name: '里斯本',
              Code: 'LIS'
            },
            {
              Name: '利巴特茹',
              Code: 'LTE'
            },
            {
              Name: '梅地奥特茹',
              Code: 'MTE'
            },
            {
              Name: '米尼奥-利马',
              Code: 'MLI'
            },
            {
              Name: '内贝拉北',
              Code: 'BIN'
            },
            {
              Name: '内贝拉南',
              Code: 'BIS'
            },
            {
              Name: '内皮尼亚尔北',
              Code: 'PIN'
            },
            {
              Name: '内皮尼亚尔南',
              Code: 'PIS'
            },
            {
              Name: '蓬塔德尔加达',
              Code: 'PDL'
            },
            {
              Name: '塞图巴尔半岛',
              Code: 'PSE'
            },
            {
              Name: '山后',
              Code: 'SES'
            },
            {
              Name: '上阿连特茹',
              Code: 'AAT'
            },
            {
              Name: '上特拉斯山',
              Code: 'ATM'
            },
            {
              Name: '塔梅加',
              Code: 'TAM'
            },
            {
              Name: '万福',
              Code: 'AES'
            },
            {
              Name: '西部',
              Code: 'OES'
            },
            {
              Name: '下阿连特茹',
              Code: 'BAL'
            },
            {
              Name: '下伏日',
              Code: 'BVO'
            },
            {
              Name: '下蒙德古',
              Code: 'BMO'
            },
            {
              Name: '中阿连特茹',
              Code: 'ALC'
            }
          ]
        }
      },
      {
        Name: '乔治亚',
        Code: 'GEO'
      },
      {
        Name: '日本',
        Code: 'JPN',
        State: {
          City: [
            {
              Name: '爱媛',
              Code: '38'
            },
            {
              Name: '爱知',
              Code: '23'
            },
            {
              Name: '北海道',
              Code: '1'
            },
            {
              Name: '兵库',
              Code: '28'
            },
            {
              Name: '冲绳',
              Code: '47'
            },
            {
              Name: '茨城',
              Code: '8'
            },
            {
              Name: '大阪',
              Code: '27'
            },
            {
              Name: '大分',
              Code: '44'
            },
            {
              Name: '岛根',
              Code: '32'
            },
            {
              Name: '徳岛',
              Code: '36'
            },
            {
              Name: '东京',
              Code: '13'
            },
            {
              Name: '福岛',
              Code: '7'
            },
            {
              Name: '福冈',
              Code: '40'
            },
            {
              Name: '福井',
              Code: '18'
            },
            {
              Name: '富山',
              Code: '16'
            },
            {
              Name: '冈山',
              Code: '33'
            },
            {
              Name: '高知',
              Code: '39'
            },
            {
              Name: '宮城',
              Code: '4'
            },
            {
              Name: '宫崎',
              Code: '45'
            },
            {
              Name: '广岛',
              Code: '34'
            },
            {
              Name: '和歌山',
              Code: '30'
            },
            {
              Name: '京都',
              Code: '26'
            },
            {
              Name: '静冈',
              Code: '22'
            },
            {
              Name: '枥木',
              Code: '9'
            },
            {
              Name: '鹿儿岛',
              Code: '46'
            },
            {
              Name: '奈良',
              Code: '29'
            },
            {
              Name: '鸟取',
              Code: '31'
            },
            {
              Name: '岐阜',
              Code: '21'
            },
            {
              Name: '埼玉',
              Code: '11'
            },
            {
              Name: '千叶',
              Code: '12'
            },
            {
              Name: '青森',
              Code: '2'
            },
            {
              Name: '秋田',
              Code: '5'
            },
            {
              Name: '群马',
              Code: '10'
            },
            {
              Name: '三重',
              Code: '24'
            },
            {
              Name: '山口',
              Code: '35'
            },
            {
              Name: '山梨',
              Code: '19'
            },
            {
              Name: '山形',
              Code: '6'
            },
            {
              Name: '神奈川',
              Code: '14'
            },
            {
              Name: '石川',
              Code: '17'
            },
            {
              Name: '香川',
              Code: '37'
            },
            {
              Name: '新潟',
              Code: '15'
            },
            {
              Name: '熊本',
              Code: '43'
            },
            {
              Name: '岩手',
              Code: '3'
            },
            {
              Name: '长崎',
              Code: '42'
            },
            {
              Name: '长野',
              Code: '20'
            },
            {
              Name: '滋贺',
              Code: '25'
            },
            {
              Name: '佐贺',
              Code: '41'
            }
          ]
        }
      },
      {
        Name: '瑞典',
        Code: 'SWE',
        State: {
          City: [
            {
              Name: '北博滕',
              Code: 'BD'
            },
            {
              Name: '布莱金厄',
              Code: 'K'
            },
            {
              Name: '达拉纳',
              Code: 'DLN'
            },
            {
              Name: '东约特兰',
              Code: 'UGL'
            },
            {
              Name: '厄勒布鲁',
              Code: 'T'
            },
            {
              Name: '哥得兰',
              Code: 'I'
            },
            {
              Name: '哈兰',
              Code: 'N'
            },
            {
              Name: '卡尔马',
              Code: 'H'
            },
            {
              Name: '克鲁努贝里',
              Code: 'G'
            },
            {
              Name: '南曼兰',
              Code: 'D'
            },
            {
              Name: '斯德哥尔摩',
              Code: 'AB'
            },
            {
              Name: '斯科耐',
              Code: 'M'
            },
            {
              Name: '韦姆兰',
              Code: 'S'
            },
            {
              Name: '乌普萨拉',
              Code: 'C'
            },
            {
              Name: '西博滕',
              Code: 'AC'
            },
            {
              Name: '西曼兰',
              Code: 'U'
            },
            {
              Name: '西诺尔兰',
              Code: 'Y'
            },
            {
              Name: '西约特兰',
              Code: 'O'
            },
            {
              Name: '延雪平',
              Code: 'F'
            },
            {
              Name: '耶夫勒堡',
              Code: 'X'
            },
            {
              Name: '耶姆特兰',
              Code: 'Z'
            }
          ]
        }
      },
      {
        Name: '瑞士',
        Code: 'CHE',
        State: {
          City: [
            {
              Name: '阿尔高',
              Code: 'AG'
            },
            {
              Name: '巴塞尔城市',
              Code: 'BS'
            },
            {
              Name: '巴塞尔乡村',
              Code: 'BL'
            },
            {
              Name: '伯尔尼',
              Code: 'BE'
            },
            {
              Name: '楚格',
              Code: 'ZG'
            },
            {
              Name: '弗里堡',
              Code: 'FR'
            },
            {
              Name: '格拉鲁斯',
              Code: 'GL'
            },
            {
              Name: '格劳宾登',
              Code: 'GR'
            },
            {
              Name: '卢塞恩',
              Code: 'LU'
            },
            {
              Name: '洛桑',
              Code: 'LA'
            },
            {
              Name: '纳沙泰尔',
              Code: 'NE'
            },
            {
              Name: '内阿彭策尔',
              Code: 'AI'
            },
            {
              Name: '日内瓦',
              Code: 'GE'
            },
            {
              Name: '汝拉',
              Code: 'JU'
            },
            {
              Name: '沙夫豪森',
              Code: 'SH'
            },
            {
              Name: '上瓦尔登',
              Code: 'OW'
            },
            {
              Name: '圣加仑',
              Code: 'SG'
            },
            {
              Name: '施维茨',
              Code: 'SZ'
            },
            {
              Name: '苏黎世',
              Code: 'ZH'
            },
            {
              Name: '索洛图恩',
              Code: 'SO'
            },
            {
              Name: '提契诺',
              Code: 'TI'
            },
            {
              Name: '图尔高',
              Code: 'TG'
            },
            {
              Name: '瓦莱',
              Code: 'VS'
            },
            {
              Name: '外阿彭策尔',
              Code: 'AR'
            },
            {
              Name: '沃',
              Code: 'VD'
            },
            {
              Name: '乌里',
              Code: 'UR'
            },
            {
              Name: '下瓦尔登',
              Code: 'NW'
            }
          ]
        }
      },
      {
        Name: '萨尔瓦多',
        Code: 'SLV',
        State: {
          City: [
            {
              Name: '阿波帕',
              Code: 'APO'
            },
            {
              Name: '阿瓦查潘',
              Code: 'AH'
            },
            {
              Name: '滨海',
              Code: 'LI'
            },
            {
              Name: '查拉特南戈',
              Code: 'CH'
            },
            {
              Name: '德尔加多',
              Code: 'DE'
            },
            {
              Name: '基埃-恩特姆',
              Code: 'KN'
            },
            {
              Name: '卡瓦尼亚斯',
              Code: 'CA'
            },
            {
              Name: '库斯卡特兰',
              Code: 'CU'
            },
            {
              Name: '拉巴斯',
              Code: 'PZ'
            },
            {
              Name: '拉利伯塔德',
              Code: 'LB'
            },
            {
              Name: '拉乌尼翁',
              Code: 'UN'
            },
            {
              Name: '梅基卡诺斯',
              Code: 'MEJ'
            },
            {
              Name: '莫拉桑',
              Code: 'MO'
            },
            {
              Name: '圣安娜',
              Code: 'SA'
            },
            {
              Name: '圣米格尔',
              Code: 'SM'
            },
            {
              Name: '圣萨尔瓦多',
              Code: 'SS'
            },
            {
              Name: '圣维森特',
              Code: 'SV'
            },
            {
              Name: '松索纳特',
              Code: 'SO'
            },
            {
              Name: '索亚潘戈',
              Code: 'SOY'
            },
            {
              Name: '韦莱-恩萨斯',
              Code: 'WN'
            },
            {
              Name: '乌苏卢坦',
              Code: 'US'
            },
            {
              Name: '伊洛潘戈',
              Code: 'IL'
            },
            {
              Name: '中南',
              Code: 'CS'
            }
          ]
        }
      },
      {
        Name: '萨摩亚',
        Code: 'WSM'
      },
      {
        Name: '塞尔维亚,黑山',
        Code: 'SCG',
        State: {
          City: [
            {
              Name: '贝尔格莱德',
              Code: 'BEG'
            },
            {
              Name: '波德戈里察',
              Code: 'POD'
            },
            {
              Name: '克拉古涅瓦茨',
              Code: 'KGV'
            },
            {
              Name: '尼什',
              Code: 'INI'
            },
            {
              Name: '诺维萨德',
              Code: 'NVS'
            },
            {
              Name: '普里什蒂纳',
              Code: 'PRN'
            },
            {
              Name: '苏博蒂察',
              Code: 'SUB'
            },
            {
              Name: '泽蒙',
              Code: 'ZEM'
            }
          ]
        }
      },
      {
        Name: '塞拉利昂',
        Code: 'SLE',
        State: {
          City: [
            {
              Name: '北部',
              Code: 'N'
            },
            {
              Name: '东部',
              Code: 'E'
            },
            {
              Name: '南部',
              Code: 'S'
            },
            {
              Name: '西部区',
              Code: 'W'
            }
          ]
        }
      },
      {
        Name: '塞内加尔',
        Code: 'SEN',
        State: {
          City: [
            {
              Name: '达喀尔',
              Code: 'DA'
            },
            {
              Name: '法蒂克',
              Code: 'FA'
            },
            {
              Name: '济金绍尔',
              Code: 'ZI'
            },
            {
              Name: '捷斯',
              Code: 'TH'
            },
            {
              Name: '久尔贝勒',
              Code: 'DI'
            },
            {
              Name: '考拉克',
              Code: 'KA'
            },
            {
              Name: '科尔达',
              Code: 'KO'
            },
            {
              Name: '卢加',
              Code: 'LO'
            },
            {
              Name: '马塔姆',
              Code: 'MA'
            },
            {
              Name: '圣路易',
              Code: 'SL'
            },
            {
              Name: '坦巴昆达',
              Code: 'TA'
            }
          ]
        }
      },
      {
        Name: '塞浦路斯',
        Code: 'CYP',
        State: {
          City: [
            {
              Name: '法马古斯塔',
              Code: '4'
            },
            {
              Name: '凯里尼亚',
              Code: '6'
            },
            {
              Name: '拉纳卡',
              Code: '3'
            },
            {
              Name: '利马索尔',
              Code: '2'
            },
            {
              Name: '尼科西亚',
              Code: '1'
            },
            {
              Name: '帕福斯',
              Code: '5'
            }
          ]
        }
      },
      {
        Name: '塞舌尔',
        Code: 'SYC'
      },
      {
        Name: '沙特阿拉伯',
        Code: 'SAU',
        State: {
          City: [
            {
              Name: '阿尔阿尔',
              Code: 'ARA'
            },
            {
              Name: '艾卜哈',
              Code: 'AHB'
            },
            {
              Name: '巴哈',
              Code: 'BH'
            },
            {
              Name: '布赖代',
              Code: 'BUR'
            },
            {
              Name: '达曼',
              Code: 'DAM'
            },
            {
              Name: '哈费尔巴廷',
              Code: 'HBT'
            },
            {
              Name: '哈伊勒',
              Code: 'HL'
            },
            {
              Name: '海米斯穆谢特',
              Code: 'KMX'
            },
            {
              Name: '海耶',
              Code: 'AKH'
            },
            {
              Name: '胡富夫',
              Code: 'HFF'
            },
            {
              Name: '吉达',
              Code: 'JED'
            },
            {
              Name: '吉赞',
              Code: 'JZ'
            },
            {
              Name: '利雅得',
              Code: 'RD'
            },
            {
              Name: '麦地那',
              Code: 'MED'
            },
            {
              Name: '麦加',
              Code: 'ML'
            },
            {
              Name: '姆巴拉兹',
              Code: 'MBR'
            },
            {
              Name: '纳季兰',
              Code: 'NR'
            },
            {
              Name: '塞卡卡',
              Code: 'SAK'
            },
            {
              Name: '塔布克',
              Code: 'TB'
            },
            {
              Name: '塔伊夫',
              Code: 'TAR'
            },
            {
              Name: '延布',
              Code: 'YNB'
            },
            {
              Name: '朱拜勒',
              Code: 'JBI'
            }
          ]
        }
      },
      {
        Name: '圣诞岛',
        Code: 'CXR'
      },
      {
        Name: '圣多美和普林西比',
        Code: 'STP'
      },
      {
        Name: '圣赫勒拿',
        Code: 'SHN'
      },
      {
        Name: '圣基茨和尼维斯',
        Code: 'KNA'
      },
      {
        Name: '圣卢西亚',
        Code: 'LCA'
      },
      {
        Name: '圣马力诺',
        Code: 'SMR'
      },
      {
        Name: '圣皮埃尔和米克隆群岛',
        Code: 'SPM'
      },
      {
        Name: '圣文森特和格林纳丁斯',
        Code: 'VCT'
      },
      {
        Name: '斯里兰卡',
        Code: 'LKA',
        State: {
          City: [
            {
              Name: '阿努拉德普勒',
              Code: 'ADP'
            },
            {
              Name: '安帕赖',
              Code: 'AMP'
            },
            {
              Name: '巴杜勒',
              Code: 'BAD'
            },
            {
              Name: '拜蒂克洛',
              Code: 'BTC'
            },
            {
              Name: '波隆纳鲁沃',
              Code: 'POL'
            },
            {
              Name: '汉班托特',
              Code: 'HBA'
            },
            {
              Name: '基里诺奇',
              Code: 'KIL'
            },
            {
              Name: '加勒',
              Code: 'GAL'
            },
            {
              Name: '加姆珀哈',
              Code: 'GAM'
            },
            {
              Name: '贾夫纳',
              Code: 'JAF'
            },
            {
              Name: '卡卢特勒',
              Code: 'KLT'
            },
            {
              Name: '凯格勒',
              Code: 'KEG'
            },
            {
              Name: '康提',
              Code: 'KAN'
            },
            {
              Name: '科伦坡',
              Code: 'CMB'
            },
            {
              Name: '库鲁内格勒',
              Code: 'KUR'
            },
            {
              Name: '拉特纳普勒',
              Code: 'RAT'
            },
            {
              Name: '马纳尔',
              Code: 'MAN'
            },
            {
              Name: '马特莱',
              Code: 'MAT'
            },
            {
              Name: '马特勒',
              Code: 'MAA'
            },
            {
              Name: '莫讷勒格勒',
              Code: 'MON'
            },
            {
              Name: '穆莱蒂武',
              Code: 'MUL'
            },
            {
              Name: '努沃勒埃利耶',
              Code: 'NUE'
            },
            {
              Name: '普塔勒姆',
              Code: 'PUT'
            },
            {
              Name: '亭可马里',
              Code: 'TRR'
            },
            {
              Name: '瓦武尼亚',
              Code: 'VAV'
            }
          ]
        }
      },
      {
        Name: '斯洛伐克',
        Code: 'SVK',
        State: {
          City: [
            {
              Name: '班斯卡-比斯特里察',
              Code: 'BBY'
            },
            {
              Name: '布拉迪斯拉发',
              Code: 'BTS'
            },
            {
              Name: '科希策',
              Code: 'KOR'
            },
            {
              Name: '尼特拉',
              Code: 'NRA'
            },
            {
              Name: '普雷绍夫',
              Code: 'POV'
            },
            {
              Name: '日利纳',
              Code: 'RIL'
            },
            {
              Name: '特尔纳瓦',
              Code: 'TNA'
            },
            {
              Name: '特伦钦',
              Code: 'TRE'
            }
          ]
        }
      },
      {
        Name: '斯洛文尼亚',
        Code: 'SVN',
        State: {
          City: [
            {
              Name: '奥巴尔诺-克拉',
              Code: 'OKR'
            },
            {
              Name: '奥斯雷德涅斯洛文',
              Code: 'OSR'
            },
            {
              Name: '波德拉夫',
              Code: 'POD'
            },
            {
              Name: '波穆尔',
              Code: 'POM'
            },
            {
              Name: '多雷尼',
              Code: 'DLJ'
            },
            {
              Name: '戈雷尼',
              Code: 'GSZ'
            },
            {
              Name: '戈里',
              Code: 'GSK'
            },
            {
              Name: '科洛',
              Code: 'KOR'
            },
            {
              Name: '诺特拉尼',
              Code: 'NKR'
            },
            {
              Name: '萨维尼',
              Code: 'SAV'
            },
            {
              Name: '斯波德涅波萨夫',
              Code: 'SPO'
            },
            {
              Name: '扎萨夫',
              Code: 'ZAS'
            }
          ]
        }
      },
      {
        Name: '斯瓦尔巴和扬马廷',
        Code: 'SJM'
      },
      {
        Name: '斯威士兰',
        Code: 'SWZ'
      },
      {
        Name: '苏丹',
        Code: 'SDN',
        State: {
          City: [
            {
              Name: '北部',
              Code: 'ASH'
            },
            {
              Name: '赤道',
              Code: 'SIS'
            },
            {
              Name: '达尔富尔',
              Code: 'SDA'
            },
            {
              Name: '东部',
              Code: 'SHA'
            },
            {
              Name: '加扎勒河',
              Code: 'SBG'
            },
            {
              Name: '喀土穆',
              Code: 'KRT'
            },
            {
              Name: '科尔多凡',
              Code: 'GKU'
            },
            {
              Name: '上尼罗',
              Code: 'ANB'
            },
            {
              Name: '中部',
              Code: 'WDH'
            }
          ]
        }
      },
      {
        Name: '苏里南',
        Code: 'SUR',
        State: {
          City: [
            {
              Name: '布罗科蓬多',
              Code: 'BR'
            },
            {
              Name: '科罗尼',
              Code: 'CR'
            },
            {
              Name: '科默韦讷',
              Code: 'CM'
            },
            {
              Name: '马罗韦讷',
              Code: 'MA'
            },
            {
              Name: '尼克里',
              Code: 'NI'
            },
            {
              Name: '帕拉',
              Code: 'PA'
            },
            {
              Name: '帕拉马里博',
              Code: 'PM'
            },
            {
              Name: '萨拉马卡',
              Code: 'SA'
            },
            {
              Name: '瓦尼卡',
              Code: 'WA'
            },
            {
              Name: '西帕里韦尼',
              Code: 'SI'
            }
          ]
        }
      },
      {
        Name: '所罗门群岛',
        Code: 'SLB',
        State: {
          City: [
            {
              Name: '瓜达尔卡纳尔',
              Code: 'GC'
            },
            {
              Name: '霍尼亚拉',
              Code: 'HO'
            },
            {
              Name: '拉纳尔和贝罗纳',
              Code: 'RB'
            },
            {
              Name: '马基拉',
              Code: 'MK'
            },
            {
              Name: '马莱塔',
              Code: 'ML'
            },
            {
              Name: '乔伊索',
              Code: 'CH'
            },
            {
              Name: '泰莫图',
              Code: 'TM'
            },
            {
              Name: '西部',
              Code: 'WE'
            },
            {
              Name: '伊萨贝尔',
              Code: 'IS'
            },
            {
              Name: '中部群岛',
              Code: 'CE'
            }
          ]
        }
      },
      {
        Name: '索马里',
        Code: 'SOM'
      },
      {
        Name: '塔吉克斯坦',
        Code: 'TJK',
        State: {
          City: [
            {
              Name: '杜尚别',
              Code: 'DYU'
            },
            {
              Name: '霍罗格',
              Code: 'KHO'
            },
            {
              Name: '卡尼巴达姆',
              Code: 'KAN'
            },
            {
              Name: '科法尔尼洪',
              Code: 'KOF'
            },
            {
              Name: '苦盏',
              Code: 'KHU'
            },
            {
              Name: '库尔干-秋别',
              Code: 'KTJ'
            },
            {
              Name: '库洛布',
              Code: 'KLB'
            },
            {
              Name: '洛贡',
              Code: 'RGU'
            },
            {
              Name: '努雷克',
              Code: 'NUR'
            },
            {
              Name: '彭吉肯特',
              Code: 'PJK'
            },
            {
              Name: '萨班特',
              Code: 'SBA'
            },
            {
              Name: '塔博沙尔',
              Code: 'TBS'
            },
            {
              Name: '图尔孙扎德',
              Code: 'TSZ'
            },
            {
              Name: '乌拉秋别',
              Code: 'UTJ'
            },
            {
              Name: '伊斯法拉',
              Code: 'ISF'
            }
          ]
        }
      },
      {
        Name: '泰国',
        Code: 'THA',
        State: {
          City: [
            {
              Name: '安纳乍能',
              Code: '37'
            },
            {
              Name: '巴蜀',
              Code: '77'
            },
            {
              Name: '巴吞他尼',
              Code: '13'
            },
            {
              Name: '巴真',
              Code: '25'
            },
            {
              Name: '北碧',
              Code: '71'
            },
            {
              Name: '北标',
              Code: '19'
            },
            {
              Name: '北大年',
              Code: '94'
            },
            {
              Name: '北揽',
              Code: '11'
            },
            {
              Name: '北榄坡',
              Code: '60'
            },
            {
              Name: '北柳',
              Code: '24'
            },
            {
              Name: '碧差汶',
              Code: '76'
            },
            {
              Name: '博达伦',
              Code: '93'
            },
            {
              Name: '猜那',
              Code: '18'
            },
            {
              Name: '猜也奔',
              Code: '36'
            },
            {
              Name: '程逸',
              Code: '53'
            },
            {
              Name: '春蓬',
              Code: '86'
            },
            {
              Name: '春武里',
              Code: '20'
            },
            {
              Name: '达',
              Code: '63'
            },
            {
              Name: '达叻',
              Code: '23'
            },
            {
              Name: '大城',
              Code: '14'
            },
            {
              Name: '董里',
              Code: '92'
            },
            {
              Name: '佛丕',
              Code: '78'
            },
            {
              Name: '佛统',
              Code: '73'
            },
            {
              Name: '甘烹碧',
              Code: '62'
            },
            {
              Name: '红统',
              Code: '15'
            },
            {
              Name: '华富里',
              Code: '16'
            },
            {
              Name: '加拉信',
              Code: '46'
            },
            {
              Name: '甲米',
              Code: '81'
            },
            {
              Name: '尖竹汶',
              Code: '22'
            },
            {
              Name: '孔敬',
              Code: '40'
            },
            {
              Name: '拉农',
              Code: '21'
            },
            {
              Name: '廊开',
              Code: '43'
            },
            {
              Name: '廊莫那浦',
              Code: '39'
            },
            {
              Name: '叻丕',
              Code: '70'
            },
            {
              Name: '黎',
              Code: '42'
            },
            {
              Name: '黎逸',
              Code: '45'
            },
            {
              Name: '龙仔厝',
              Code: '74'
            },
            {
              Name: '罗勇',
              Code: '85'
            },
            {
              Name: '洛坤',
              Code: '80'
            },
            {
              Name: '玛哈沙拉堪',
              Code: '44'
            },
            {
              Name: '曼谷',
              Code: '10'
            },
            {
              Name: '莫达汉',
              Code: '49'
            },
            {
              Name: '那空那育',
              Code: '26'
            },
            {
              Name: '那空帕农',
              Code: '48'
            },
            {
              Name: '难',
              Code: '55'
            },
            {
              Name: '南奔',
              Code: '51'
            },
            {
              Name: '暖武里',
              Code: '12'
            },
            {
              Name: '帕',
              Code: '54'
            },
            {
              Name: '帕尧',
              Code: '56'
            },
            {
              Name: '攀牙',
              Code: '82'
            },
            {
              Name: '彭世洛',
              Code: '65'
            },
            {
              Name: '披集',
              Code: '66'
            },
            {
              Name: '普吉',
              Code: '83'
            },
            {
              Name: '清莱',
              Code: '57'
            },
            {
              Name: '清迈',
              Code: '50'
            },
            {
              Name: '色军',
              Code: '47'
            },
            {
              Name: '沙敦',
              Code: '91'
            },
            {
              Name: '沙缴',
              Code: '27'
            },
            {
              Name: '四色菊',
              Code: '33'
            },
            {
              Name: '宋卡',
              Code: '90'
            },
            {
              Name: '素可泰',
              Code: '64'
            },
            {
              Name: '素叻',
              Code: '84'
            },
            {
              Name: '素林',
              Code: '32'
            },
            {
              Name: '素攀武里',
              Code: '72'
            },
            {
              Name: '陶公',
              Code: '96'
            },
            {
              Name: '乌隆',
              Code: '41'
            },
            {
              Name: '乌泰他尼',
              Code: '61'
            },
            {
              Name: '乌汶',
              Code: '34'
            },
            {
              Name: '武里南',
              Code: '31'
            },
            {
              Name: '信武里',
              Code: '17'
            },
            {
              Name: '耶梭通',
              Code: '35'
            },
            {
              Name: '也拉',
              Code: '95'
            },
            {
              Name: '夜丰颂',
              Code: '58'
            },
            {
              Name: '夜功',
              Code: '75'
            }
          ]
        }
      },
      {
        Name: '坦桑尼亚',
        Code: 'TZA',
        State: {
          City: [
            {
              Name: '阿鲁沙',
              Code: 'AR'
            },
            {
              Name: '奔巴北',
              Code: 'PN'
            },
            {
              Name: '奔巴南',
              Code: 'PS'
            },
            {
              Name: '滨海',
              Code: 'PW'
            },
            {
              Name: '达累斯萨拉姆',
              Code: 'DS'
            },
            {
              Name: '多多马',
              Code: 'DO'
            },
            {
              Name: '基戈马',
              Code: 'KI'
            },
            {
              Name: '卡盖拉',
              Code: 'KA'
            },
            {
              Name: '林迪',
              Code: 'LN'
            },
            {
              Name: '鲁夸',
              Code: 'RK'
            },
            {
              Name: '鲁伍马',
              Code: 'RV'
            },
            {
              Name: '马腊',
              Code: 'MR'
            },
            {
              Name: '曼亚拉',
              Code: 'MY'
            },
            {
              Name: '莫洛戈罗',
              Code: 'MO'
            },
            {
              Name: '姆贝亚',
              Code: 'MB'
            },
            {
              Name: '姆特瓦拉',
              Code: 'MT'
            },
            {
              Name: '姆万扎',
              Code: 'MW'
            },
            {
              Name: '乞力马扎罗',
              Code: 'KJ'
            },
            {
              Name: '桑给巴尔',
              Code: 'ZN'
            },
            {
              Name: '桑给巴尔北',
              Code: 'UN'
            },
            {
              Name: '桑给巴尔南',
              Code: 'US'
            },
            {
              Name: '桑给巴尔市和西',
              Code: 'MM'
            },
            {
              Name: '塔波拉',
              Code: 'TB'
            },
            {
              Name: '坦噶',
              Code: 'TN'
            },
            {
              Name: '辛吉达',
              Code: 'SI'
            },
            {
              Name: '欣延加',
              Code: 'SH'
            },
            {
              Name: '伊林加',
              Code: 'IR'
            }
          ]
        }
      },
      {
        Name: '汤加',
        Code: 'TON',
        State: {
          City: [
            {
              Name: '埃瓦',
              Code: 'E'
            },
            {
              Name: '哈派',
              Code: 'H'
            },
            {
              Name: '纽阿斯',
              Code: 'N'
            },
            {
              Name: '汤加塔布',
              Code: 'T'
            },
            {
              Name: '瓦瓦乌',
              Code: 'V'
            }
          ]
        }
      },
      {
        Name: '特克斯和凯克特斯群岛',
        Code: 'TCA'
      },
      {
        Name: '特里斯坦达昆哈',
        Code: 'TAA'
      },
      {
        Name: '特立尼达和多巴哥',
        Code: 'TTO'
      },
      {
        Name: '突尼斯',
        Code: 'TUN',
        State: {
          City: [
            {
              Name: '艾尔亚奈',
              Code: 'AR'
            },
            {
              Name: '巴杰',
              Code: 'BJ'
            },
            {
              Name: '本阿鲁斯',
              Code: 'BA'
            },
            {
              Name: '比塞大',
              Code: 'BI'
            },
            {
              Name: '吉比利',
              Code: 'KB'
            },
            {
              Name: '加贝斯',
              Code: 'GB'
            },
            {
              Name: '加夫萨',
              Code: 'GF'
            },
            {
              Name: '坚杜拜',
              Code: 'JE'
            },
            {
              Name: '卡夫',
              Code: 'LK'
            },
            {
              Name: '卡塞林',
              Code: 'KS'
            },
            {
              Name: '凯鲁万',
              Code: 'KR'
            },
            {
              Name: '马赫迪耶',
              Code: 'MH'
            },
            {
              Name: '马努巴',
              Code: 'MN'
            },
            {
              Name: '梅德宁',
              Code: 'ME'
            },
            {
              Name: '莫纳斯提尔',
              Code: 'MO'
            },
            {
              Name: '纳布勒',
              Code: 'NA'
            },
            {
              Name: '斯法克斯',
              Code: 'SF'
            },
            {
              Name: '苏塞',
              Code: 'SO'
            },
            {
              Name: '泰塔温',
              Code: 'TA'
            },
            {
              Name: '突尼斯',
              Code: 'TU'
            },
            {
              Name: '托泽尔',
              Code: 'TO'
            },
            {
              Name: '西迪布济德',
              Code: 'SD'
            },
            {
              Name: '锡勒亚奈',
              Code: 'SL'
            },
            {
              Name: '宰格万',
              Code: 'ZA'
            }
          ]
        }
      },
      {
        Name: '图瓦卢',
        Code: 'TUV'
      },
      {
        Name: '土耳其',
        Code: 'TUR',
        State: {
          City: [
            {
              Name: '阿达纳',
              Code: 'ADA'
            },
            {
              Name: '阿德亚曼',
              Code: 'ADI'
            },
            {
              Name: '阿尔达罕',
              Code: 'ARD'
            },
            {
              Name: '阿尔特温',
              Code: 'ART'
            },
            {
              Name: '阿菲永',
              Code: 'AFY'
            },
            {
              Name: '阿克萨赖',
              Code: 'AKS'
            },
            {
              Name: '阿勒',
              Code: 'AGR'
            },
            {
              Name: '阿马西亚',
              Code: 'AMA'
            },
            {
              Name: '埃迪尔内',
              Code: 'EDI'
            },
            {
              Name: '埃尔津詹',
              Code: 'EZC'
            },
            {
              Name: '埃尔祖鲁姆',
              Code: 'EZR'
            },
            {
              Name: '埃拉泽',
              Code: 'ELA'
            },
            {
              Name: '埃斯基谢希尔',
              Code: 'ESK'
            },
            {
              Name: '艾登',
              Code: 'AYI'
            },
            {
              Name: '安卡拉',
              Code: 'ANK'
            },
            {
              Name: '安塔利亚',
              Code: 'ANT'
            },
            {
              Name: '奥尔杜',
              Code: 'ORD'
            },
            {
              Name: '巴尔腾',
              Code: 'BAR'
            },
            {
              Name: '巴勒克埃西尔',
              Code: 'BAL'
            },
            {
              Name: '巴特曼',
              Code: 'BAT'
            },
            {
              Name: '巴伊布尔特',
              Code: 'BAY'
            },
            {
              Name: '比莱吉克',
              Code: 'BIL'
            },
            {
              Name: '比特利斯',
              Code: 'BIT'
            },
            {
              Name: '宾格尔',
              Code: 'BIN'
            },
            {
              Name: '博卢',
              Code: 'BOL'
            },
            {
              Name: '布尔杜尔',
              Code: 'BRD'
            },
            {
              Name: '布尔萨',
              Code: 'BRS'
            },
            {
              Name: '昌克勒',
              Code: 'CKR'
            },
            {
              Name: '代尼兹利',
              Code: 'DEN'
            },
            {
              Name: '迪亚巴克尔',
              Code: 'DIY'
            },
            {
              Name: '凡',
              Code: 'VAN'
            },
            {
              Name: '哈卡里',
              Code: 'HKR'
            },
            {
              Name: '哈塔伊',
              Code: 'HTY'
            },
            {
              Name: '基利斯',
              Code: 'KLS'
            },
            {
              Name: '吉雷松',
              Code: 'GIR'
            },
            {
              Name: '加济安泰普',
              Code: 'GAZ'
            },
            {
              Name: '居米什哈内',
              Code: 'GMS'
            },
            {
              Name: '卡尔斯',
              Code: 'KRS'
            },
            {
              Name: '卡赫拉曼马拉什',
              Code: 'KAH'
            },
            {
              Name: '卡拉比克',
              Code: 'KRB'
            },
            {
              Name: '卡拉曼',
              Code: 'KRM'
            },
            {
              Name: '卡斯塔莫努',
              Code: 'KAS'
            },
            {
              Name: '开塞利',
              Code: 'KAY'
            },
            {
              Name: '科贾埃利',
              Code: 'KOC'
            },
            {
              Name: '柯克拉雷利',
              Code: 'KLR'
            },
            {
              Name: '科尼亚',
              Code: 'KON'
            },
            {
              Name: '克尔谢希尔',
              Code: 'KRH'
            },
            {
              Name: '克勒克卡莱',
              Code: 'KRK'
            },
            {
              Name: '拉飞',
              Code: 'URF'
            },
            {
              Name: '里泽',
              Code: 'RIZ'
            },
            {
              Name: '马尔丁',
              Code: 'MAR'
            },
            {
              Name: '马拉蒂亚',
              Code: 'MAL'
            },
            {
              Name: '马尼萨',
              Code: 'MAN'
            },
            {
              Name: '穆拉',
              Code: 'MUG'
            },
            {
              Name: '穆什',
              Code: 'MUS'
            },
            {
              Name: '内夫谢希尔',
              Code: 'NEV'
            },
            {
              Name: '尼代',
              Code: 'NIG'
            },
            {
              Name: '恰纳卡莱',
              Code: 'CKL'
            },
            {
              Name: '乔鲁姆',
              Code: 'COR'
            },
            {
              Name: '屈塔希亚',
              Code: 'KUT'
            },
            {
              Name: '萨卡里亚',
              Code: 'SAK'
            },
            {
              Name: '萨姆松',
              Code: 'SAM'
            },
            {
              Name: '泰基尔达',
              Code: 'TEL'
            },
            {
              Name: '特拉布宗',
              Code: 'TRA'
            },
            {
              Name: '通杰利',
              Code: 'TUN'
            },
            {
              Name: '托卡特',
              Code: 'TOK'
            },
            {
              Name: '乌萨克',
              Code: 'USK'
            },
            {
              Name: '锡尔纳克',
              Code: 'SIR'
            },
            {
              Name: '锡尔特',
              Code: 'SII'
            },
            {
              Name: '锡诺普',
              Code: 'SIN'
            },
            {
              Name: '锡瓦斯',
              Code: 'SIV'
            },
            {
              Name: '伊迪尔',
              Code: 'IGD'
            },
            {
              Name: '伊切尔',
              Code: 'ICE'
            },
            {
              Name: '伊斯帕尔塔',
              Code: 'ISP'
            },
            {
              Name: '伊斯坦布尔',
              Code: 'IST'
            },
            {
              Name: '伊兹密尔',
              Code: 'IZM'
            },
            {
              Name: '约兹加特',
              Code: 'YOZ'
            },
            {
              Name: '宗古尔达克',
              Code: 'ZON'
            }
          ]
        }
      },
      {
        Name: '土库曼斯坦',
        Code: 'TKM',
        State: {
          City: [
            {
              Name: '阿哈尔',
              Code: 'A'
            },
            {
              Name: '阿什哈巴德市',
              Code: 'ASB'
            },
            {
              Name: '巴尔坎',
              Code: 'B'
            },
            {
              Name: '达沙古兹',
              Code: 'D'
            },
            {
              Name: '列巴普',
              Code: 'L'
            },
            {
              Name: '马雷',
              Code: 'M'
            },
            {
              Name: '涅比特达格',
              Code: 'NEB'
            }
          ]
        }
      },
      {
        Name: '托克劳',
        Code: 'TKL'
      },
      {
        Name: '瓦利斯和福图纳',
        Code: 'WLF'
      },
      {
        Name: '瓦努阿图',
        Code: 'VUT',
        State: {
          City: [
            {
              Name: '马朗帕',
              Code: 'MA'
            },
            {
              Name: '彭纳马',
              Code: 'PE'
            },
            {
              Name: '桑马',
              Code: 'SA'
            },
            {
              Name: '塔菲阿',
              Code: 'TA'
            },
            {
              Name: '托尔巴',
              Code: 'TO'
            },
            {
              Name: '谢法',
              Code: 'SH'
            }
          ]
        }
      },
      {
        Name: '危地马拉',
        Code: 'GTM',
        State: {
          City: [
            {
              Name: '埃尔普罗格雷索',
              Code: 'PR'
            },
            {
              Name: '埃斯昆特拉',
              Code: 'ES'
            },
            {
              Name: '哈拉帕',
              Code: 'JA'
            },
            {
              Name: '胡蒂亚帕',
              Code: 'JU'
            },
            {
              Name: '基切',
              Code: 'QC'
            },
            {
              Name: '克萨尔特南戈',
              Code: 'QZ'
            },
            {
              Name: '雷塔卢莱乌',
              Code: 'RE'
            },
            {
              Name: '米克斯科',
              Code: 'MIX'
            },
            {
              Name: '佩滕',
              Code: 'PE'
            },
            {
              Name: '奇基穆拉',
              Code: 'CQ'
            },
            {
              Name: '奇马尔特南戈',
              Code: 'CM'
            },
            {
              Name: '萨卡帕',
              Code: 'ZA'
            },
            {
              Name: '萨卡特佩克斯',
              Code: 'ST'
            },
            {
              Name: '上韦拉帕斯',
              Code: 'AV'
            },
            {
              Name: '圣罗莎',
              Code: 'SR'
            },
            {
              Name: '圣马科斯',
              Code: 'SM'
            },
            {
              Name: '苏奇特佩克斯',
              Code: 'SU'
            },
            {
              Name: '索洛拉',
              Code: 'SO'
            },
            {
              Name: '托托尼卡潘',
              Code: 'TO'
            },
            {
              Name: '危地马拉',
              Code: 'GU'
            },
            {
              Name: '韦韦特南戈',
              Code: 'HU'
            },
            {
              Name: '下韦拉帕斯',
              Code: 'BV'
            },
            {
              Name: '新城',
              Code: 'VIN'
            },
            {
              Name: '伊萨瓦尔',
              Code: 'IZ'
            }
          ]
        }
      },
      {
        Name: '维尔京群岛，美属',
        Code: 'VIR'
      },
      {
        Name: '维尔京群岛，英属',
        Code: 'VGB'
      },
      {
        Name: '委内瑞拉',
        Code: 'VEN',
        State: {
          City: [
            {
              Name: '阿拉瓜',
              Code: 'D'
            },
            {
              Name: '阿马库罗三角洲',
              Code: 'Y'
            },
            {
              Name: '阿普雷',
              Code: 'C'
            },
            {
              Name: '安索阿特吉',
              Code: 'B'
            },
            {
              Name: '巴里纳斯',
              Code: 'E'
            },
            {
              Name: '玻利瓦尔',
              Code: 'F'
            },
            {
              Name: '波图格萨',
              Code: 'P'
            },
            {
              Name: '法尔孔',
              Code: 'I'
            },
            {
              Name: '瓜里科',
              Code: 'J'
            },
            {
              Name: '加拉加斯',
              Code: 'A'
            },
            {
              Name: '卡拉沃沃',
              Code: 'G'
            },
            {
              Name: '科赫德斯',
              Code: 'H'
            },
            {
              Name: '拉腊',
              Code: 'K'
            },
            {
              Name: '联邦属地',
              Code: 'W'
            },
            {
              Name: '梅里达',
              Code: 'L'
            },
            {
              Name: '米兰达',
              Code: 'M'
            },
            {
              Name: '莫纳加斯',
              Code: 'N'
            },
            {
              Name: '苏克雷',
              Code: 'R'
            },
            {
              Name: '苏利亚',
              Code: 'V'
            },
            {
              Name: '塔奇拉',
              Code: 'S'
            },
            {
              Name: '特鲁希略',
              Code: 'T'
            },
            {
              Name: '新埃斯帕塔',
              Code: 'O'
            },
            {
              Name: '亚拉奎',
              Code: 'U'
            },
            {
              Name: '亚马孙',
              Code: 'Z'
            }
          ]
        }
      },
      {
        Name: '文莱',
        Code: 'BRN'
      },
      {
        Name: '乌干达',
        Code: 'UGA',
        State: {
          City: [
            {
              Name: '阿鲁阿',
              Code: 'ARU'
            },
            {
              Name: '阿帕克',
              Code: 'APC'
            },
            {
              Name: '阿朱马尼',
              Code: 'ADJ'
            },
            {
              Name: '本迪布焦',
              Code: 'BUN'
            },
            {
              Name: '布吉里',
              Code: 'BUG'
            },
            {
              Name: '布西亚',
              Code: 'BUS'
            },
            {
              Name: '布谢尼',
              Code: 'BSH'
            },
            {
              Name: '恩通加莫',
              Code: 'NTU'
            },
            {
              Name: '古卢',
              Code: 'GUL'
            },
            {
              Name: '霍伊马',
              Code: 'HOI'
            },
            {
              Name: '基巴莱',
              Code: 'KBA'
            },
            {
              Name: '基博加',
              Code: 'KIB'
            },
            {
              Name: '基恩乔乔',
              Code: 'KYE'
            },
            {
              Name: '基索罗',
              Code: 'KIS'
            },
            {
              Name: '基特古姆',
              Code: 'KIT'
            },
            {
              Name: '金贾',
              Code: 'JIN'
            },
            {
              Name: '卡巴莱',
              Code: 'KBL'
            },
            {
              Name: '卡巴罗莱',
              Code: 'KAR'
            },
            {
              Name: '卡贝拉马伊多',
              Code: 'KAB'
            },
            {
              Name: '卡兰加拉',
              Code: 'KAL'
            },
            {
              Name: '卡姆文盖',
              Code: 'KAM'
            },
            {
              Name: '卡穆利',
              Code: 'KML'
            },
            {
              Name: '卡农古',
              Code: 'KAN'
            },
            {
              Name: '卡普乔鲁瓦',
              Code: 'KPC'
            },
            {
              Name: '卡塞塞',
              Code: 'KAS'
            },
            {
              Name: '卡塔奎',
              Code: 'KTK'
            },
            {
              Name: '卡永加',
              Code: 'KAY'
            },
            {
              Name: '坎帕拉',
              Code: 'KMP'
            },
            {
              Name: '科蒂多',
              Code: 'KOT'
            },
            {
              Name: '库米',
              Code: 'KUM'
            },
            {
              Name: '拉卡伊',
              Code: 'RAK'
            },
            {
              Name: '利拉',
              Code: 'LIR'
            },
            {
              Name: '卢韦罗',
              Code: 'LUW'
            },
            {
              Name: '鲁昆吉里',
              Code: 'RUK'
            },
            {
              Name: '马萨卡',
              Code: 'MAS'
            },
            {
              Name: '马辛迪',
              Code: 'MSN'
            },
            {
              Name: '马尤盖',
              Code: 'MAY'
            },
            {
              Name: '莫罗托',
              Code: 'MRT'
            },
            {
              Name: '莫约',
              Code: 'MOY'
            },
            {
              Name: '姆巴拉拉',
              Code: 'MBR'
            },
            {
              Name: '姆巴莱',
              Code: 'MBA'
            },
            {
              Name: '姆皮吉',
              Code: 'MPI'
            },
            {
              Name: '穆本德',
              Code: 'MUB'
            },
            {
              Name: '穆科诺',
              Code: 'MUK'
            },
            {
              Name: '纳卡皮里皮里特',
              Code: 'NAK'
            },
            {
              Name: '纳卡松戈拉',
              Code: 'NKS'
            },
            {
              Name: '内比',
              Code: 'NEB'
            },
            {
              Name: '帕德尔',
              Code: 'PAD'
            },
            {
              Name: '帕利萨',
              Code: 'PAL'
            },
            {
              Name: '森巴布莱',
              Code: 'SEM'
            },
            {
              Name: '索罗提',
              Code: 'SOR'
            },
            {
              Name: '托罗罗',
              Code: 'TOR'
            },
            {
              Name: '瓦基索',
              Code: 'WAK'
            },
            {
              Name: '锡龙科',
              Code: 'SIR'
            },
            {
              Name: '伊甘加',
              Code: 'IGA'
            },
            {
              Name: '永贝',
              Code: 'YUM'
            }
          ]
        }
      },
      {
        Name: '乌克兰',
        Code: 'UKR',
        State: {
          City: [
            {
              Name: '敖德萨',
              Code: '51'
            },
            {
              Name: '波尔塔瓦',
              Code: '53'
            },
            {
              Name: '第聂伯罗波得罗夫斯克',
              Code: '12'
            },
            {
              Name: '顿涅茨克',
              Code: '14'
            },
            {
              Name: '哈尔科夫',
              Code: '63'
            },
            {
              Name: '赫尔松州',
              Code: '65'
            },
            {
              Name: '赫梅利尼茨基',
              Code: '68'
            },
            {
              Name: '基辅',
              Code: '30'
            },
            {
              Name: '基洛夫格勒',
              Code: '35'
            },
            {
              Name: '捷尔诺波尔',
              Code: '61'
            },
            {
              Name: '克里米亚自治共和国',
              Code: '43'
            },
            {
              Name: '利沃夫',
              Code: '46'
            },
            {
              Name: '卢甘斯克',
              Code: '9'
            },
            {
              Name: '罗夫诺',
              Code: '56'
            },
            {
              Name: '尼古拉耶夫',
              Code: '48'
            },
            {
              Name: '切尔卡瑟',
              Code: '71'
            },
            {
              Name: '切尔尼戈夫',
              Code: '74'
            },
            {
              Name: '切尔诺夫策',
              Code: '77'
            },
            {
              Name: '日托米尔',
              Code: '18'
            },
            {
              Name: '苏梅',
              Code: '59'
            },
            {
              Name: '外喀尔巴阡',
              Code: '21'
            },
            {
              Name: '文尼察',
              Code: '5'
            },
            {
              Name: '沃伦',
              Code: '7'
            },
            {
              Name: '伊万－弗兰科夫州',
              Code: '26'
            },
            {
              Name: '扎波罗热',
              Code: '23'
            }
          ]
        }
      },
      {
        Name: '乌拉圭',
        Code: 'URY',
        State: {
          City: [
            {
              Name: '阿蒂加斯',
              Code: 'AR'
            },
            {
              Name: '杜拉斯诺',
              Code: 'DU'
            },
            {
              Name: '佛罗里达',
              Code: 'FA'
            },
            {
              Name: '弗洛雷斯',
              Code: 'FS'
            },
            {
              Name: '卡内洛内斯',
              Code: 'CA'
            },
            {
              Name: '科洛尼亚',
              Code: 'CO'
            },
            {
              Name: '拉瓦耶哈',
              Code: 'LA'
            },
            {
              Name: '里韦拉',
              Code: 'RV'
            },
            {
              Name: '罗恰',
              Code: 'RO'
            },
            {
              Name: '马尔多纳多',
              Code: 'MA'
            },
            {
              Name: '蒙得维的亚',
              Code: 'MO'
            },
            {
              Name: '内格罗河',
              Code: 'RN'
            },
            {
              Name: '派桑杜',
              Code: 'PA'
            },
            {
              Name: '萨尔托',
              Code: 'SL'
            },
            {
              Name: '塞罗拉尔戈',
              Code: 'CL'
            },
            {
              Name: '三十三人',
              Code: 'TT'
            },
            {
              Name: '圣何塞',
              Code: 'SJ'
            },
            {
              Name: '索里亚诺',
              Code: 'SO'
            },
            {
              Name: '塔夸伦博',
              Code: 'TAW'
            }
          ]
        }
      },
      {
        Name: '乌兹别克斯坦',
        Code: 'UZB',
        State: {
          City: [
            {
              Name: '安集延',
              Code: 'AN'
            },
            {
              Name: '布哈拉',
              Code: 'BU'
            },
            {
              Name: '费尔干纳',
              Code: 'FA'
            },
            {
              Name: '花拉子模',
              Code: 'XO'
            },
            {
              Name: '吉扎克',
              Code: 'JI'
            },
            {
              Name: '卡拉卡尔帕克斯坦共和国',
              Code: 'QR'
            },
            {
              Name: '卡什卡达里亚',
              Code: 'QA'
            },
            {
              Name: '纳曼干',
              Code: 'NG'
            },
            {
              Name: '纳沃伊',
              Code: 'NW'
            },
            {
              Name: '撒马尔罕',
              Code: 'SA'
            },
            {
              Name: '苏尔汉河',
              Code: 'SU'
            },
            {
              Name: '塔什干',
              Code: 'TK'
            },
            {
              Name: '塔什干市',
              Code: 'TO'
            },
            {
              Name: '锡尔河',
              Code: 'SI'
            }
          ]
        }
      },
      {
        Name: '西班牙',
        Code: 'ESP',
        State: {
          City: [
            {
              Name: '阿尔梅里亚',
              Code: 'LEI'
            },
            {
              Name: '阿尔瓦塞特',
              Code: 'ALB'
            },
            {
              Name: '阿拉瓦',
              Code: 'ALA'
            },
            {
              Name: '阿利坎特',
              Code: 'ALC'
            },
            {
              Name: '阿斯图利亚斯',
              Code: 'AST'
            },
            {
              Name: '阿维拉',
              Code: 'AVI'
            },
            {
              Name: '奥伦塞',
              Code: 'ORE'
            },
            {
              Name: '巴达霍斯',
              Code: 'BJZ'
            },
            {
              Name: '巴利阿里',
              Code: 'BLR'
            },
            {
              Name: '巴利亚多利德',
              Code: 'VLL'
            },
            {
              Name: '巴伦西亚',
              Code: 'VLC'
            },
            {
              Name: '巴塞罗那',
              Code: 'BCN'
            },
            {
              Name: '比斯开',
              Code: 'VSE'
            },
            {
              Name: '布尔戈斯',
              Code: 'BUR'
            },
            {
              Name: '格拉纳达',
              Code: 'GRX'
            },
            {
              Name: '瓜达拉哈拉',
              Code: 'GUA'
            },
            {
              Name: '哈恩',
              Code: 'JAE'
            },
            {
              Name: '赫罗纳',
              Code: 'GRO'
            },
            {
              Name: '吉普斯夸',
              Code: 'GUI'
            },
            {
              Name: '加的斯',
              Code: 'CAD'
            },
            {
              Name: '卡塞雷斯',
              Code: 'CCS'
            },
            {
              Name: '卡斯蒂利亚',
              Code: 'CIR'
            },
            {
              Name: '卡斯特利翁',
              Code: 'CAS'
            },
            {
              Name: '科尔多瓦',
              Code: 'ODB'
            },
            {
              Name: '昆卡',
              Code: 'CUE'
            },
            {
              Name: '拉科鲁尼亚',
              Code: 'LCG'
            },
            {
              Name: '拉里奥哈',
              Code: 'ARL'
            },
            {
              Name: '拉斯帕尔马斯',
              Code: 'LPA'
            },
            {
              Name: '莱昂',
              Code: 'LEN'
            },
            {
              Name: '莱里达',
              Code: 'LLE'
            },
            {
              Name: '卢戈',
              Code: 'LGO'
            },
            {
              Name: '马德里',
              Code: 'MAD'
            },
            {
              Name: '马拉加',
              Code: 'AGP'
            },
            {
              Name: '穆尔西亚',
              Code: 'MJV'
            },
            {
              Name: '纳瓦拉',
              Code: 'NVV'
            },
            {
              Name: '帕伦西亚',
              Code: 'PAC'
            },
            {
              Name: '蓬特韦德拉',
              Code: 'PEV'
            },
            {
              Name: '萨拉戈萨',
              Code: 'ZAZ'
            },
            {
              Name: '萨拉曼卡',
              Code: 'SLM'
            },
            {
              Name: '萨莫拉',
              Code: 'ZMR'
            },
            {
              Name: '塞哥维亚',
              Code: 'SEG'
            },
            {
              Name: '塞维利亚',
              Code: 'SVQ'
            },
            {
              Name: '桑坦德',
              Code: 'SDR'
            },
            {
              Name: '圣克鲁斯-德特内里费',
              Code: 'SCT'
            },
            {
              Name: '索里亚',
              Code: 'SOR'
            },
            {
              Name: '塔拉戈纳',
              Code: 'TAR'
            },
            {
              Name: '特鲁埃尔',
              Code: 'TER'
            },
            {
              Name: '托莱多',
              Code: 'TOL'
            },
            {
              Name: '韦尔瓦',
              Code: 'HUV'
            },
            {
              Name: '韦斯卡',
              Code: 'HUC'
            }
          ]
        }
      },
      {
        Name: '希腊',
        Code: 'GRC',
        State: {
          City: [
            {
              Name: '比雷埃夫斯',
              Code: 'PRI'
            },
            {
              Name: '多德卡尼斯',
              Code: 'DO'
            },
            {
              Name: '干尼亚',
              Code: 'CHQ'
            },
            {
              Name: '基克拉迪',
              Code: 'CY'
            },
            {
              Name: '拉西锡',
              Code: 'LST'
            },
            {
              Name: '莱斯博斯',
              Code: 'LES'
            },
            {
              Name: '雷西姆农',
              Code: 'RET'
            },
            {
              Name: '萨摩斯',
              Code: 'SMI'
            },
            {
              Name: '雅典',
              Code: 'ATH'
            },
            {
              Name: '伊拉克里翁',
              Code: 'HER'
            }
          ]
        }
      },
      {
        Name: '新加坡',
        Code: 'SGP'
      },
      {
        Name: '新喀里多尼亚',
        Code: 'NCL'
      },
      {
        Name: '新西兰',
        Code: 'NZL',
        State: {
          City: [
            {
              Name: '奥克兰',
              Code: 'AUK'
            },
            {
              Name: '北岸',
              Code: 'NSH'
            },
            {
              Name: '北帕默斯顿',
              Code: 'PMR'
            },
            {
              Name: '北远',
              Code: 'FNR'
            },
            {
              Name: '布莱尼姆',
              Code: 'BHE'
            },
            {
              Name: '达尼丁',
              Code: 'DUD'
            },
            {
              Name: '格雷茅斯',
              Code: 'GMN'
            },
            {
              Name: '哈密尔顿',
              Code: 'HLZ'
            },
            {
              Name: '黑斯廷斯',
              Code: 'HAS'
            },
            {
              Name: '怀塔科拉',
              Code: 'WAE'
            },
            {
              Name: '吉斯伯恩',
              Code: 'GIS'
            },
            {
              Name: '凯帕拉',
              Code: 'KAI'
            },
            {
              Name: '克赖斯特彻奇',
              Code: 'CHC'
            },
            {
              Name: '里士满',
              Code: 'RMD'
            },
            {
              Name: '马努考',
              Code: 'MNK'
            },
            {
              Name: '纳尔逊',
              Code: 'NSN'
            },
            {
              Name: '内皮尔',
              Code: 'NPE'
            },
            {
              Name: '斯特拉特福德',
              Code: 'STR'
            },
            {
              Name: '陶马鲁努伊',
              Code: 'TAU'
            },
            {
              Name: '瓦卡塔尼',
              Code: 'WHK'
            },
            {
              Name: '旺阿雷',
              Code: 'WRE'
            },
            {
              Name: '旺格努伊',
              Code: 'WAG'
            },
            {
              Name: '新普利茅斯',
              Code: 'NPL'
            },
            {
              Name: '因弗卡吉尔',
              Code: 'IVC'
            }
          ]
        }
      },
      {
        Name: '匈牙利',
        Code: 'HUN',
        State: {
          City: [
            {
              Name: '巴兰尼亚',
              Code: 'BA'
            },
            {
              Name: '巴奇-基什孔',
              Code: 'BK'
            },
            {
              Name: '包尔绍德-奥包乌伊-曾普伦',
              Code: 'BZ'
            },
            {
              Name: '贝凯什',
              Code: 'BE'
            },
            {
              Name: '布达佩斯',
              Code: 'BU'
            },
            {
              Name: '费耶尔',
              Code: 'FE'
            },
            {
              Name: '豪伊杜-比豪尔',
              Code: 'HB'
            },
            {
              Name: '赫维什',
              Code: 'HE'
            },
            {
              Name: '加兹-纳杰孔-索尔诺克',
              Code: 'JN'
            },
            {
              Name: '杰尔-莫松-肖普朗',
              Code: 'GS'
            },
            {
              Name: '科马罗姆',
              Code: 'KE'
            },
            {
              Name: '诺格拉德',
              Code: 'NO'
            },
            {
              Name: '佩斯',
              Code: 'PE'
            },
            {
              Name: '琼格拉德',
              Code: 'CS'
            },
            {
              Name: '绍莫吉',
              Code: 'SO'
            },
            {
              Name: '索博尔奇-索特马尔-贝拉格',
              Code: 'SZ'
            },
            {
              Name: '托尔瑙',
              Code: 'TO'
            },
            {
              Name: '维斯普雷姆',
              Code: 'VE'
            },
            {
              Name: '沃什',
              Code: 'VA'
            },
            {
              Name: '佐洛',
              Code: 'ZA'
            }
          ]
        }
      },
      {
        Name: '叙利亚',
        Code: 'SYR',
        State: {
          City: [
            {
              Name: '阿勒颇',
              Code: 'HL'
            },
            {
              Name: '大马士革',
              Code: 'RD'
            },
            {
              Name: '大马士革市',
              Code: 'DI'
            },
            {
              Name: '代尔祖尔',
              Code: 'DZ'
            },
            {
              Name: '德拉',
              Code: 'DA'
            },
            {
              Name: '哈马',
              Code: 'HM'
            },
            {
              Name: '哈塞克',
              Code: 'HA'
            },
            {
              Name: '霍姆斯',
              Code: 'HI'
            },
            {
              Name: '加布',
              Code: 'GH'
            },
            {
              Name: '卡米什利',
              Code: 'QA'
            },
            {
              Name: '库奈特拉',
              Code: 'QU'
            },
            {
              Name: '拉卡',
              Code: 'RQ'
            },
            {
              Name: '拉塔基亚',
              Code: 'LA'
            },
            {
              Name: '苏韦达',
              Code: 'SU'
            },
            {
              Name: '塔尔图斯',
              Code: 'TA'
            },
            {
              Name: '伊德利卜',
              Code: 'ID'
            }
          ]
        }
      },
      {
        Name: '牙买加',
        Code: 'JAM',
        State: {
          City: [
            {
              Name: '波特兰',
              Code: 'POR'
            },
            {
              Name: '汉诺威',
              Code: 'HAN'
            },
            {
              Name: '金斯敦',
              Code: 'KIN'
            },
            {
              Name: '克拉伦登',
              Code: 'CLA'
            },
            {
              Name: '曼彻斯特',
              Code: 'MAN'
            },
            {
              Name: '圣安德鲁斯',
              Code: 'AND'
            },
            {
              Name: '圣安娜',
              Code: 'ANN'
            },
            {
              Name: '圣凯瑟琳',
              Code: 'CAT'
            },
            {
              Name: '圣玛丽',
              Code: 'MAR'
            },
            {
              Name: '圣托马斯',
              Code: 'THO'
            },
            {
              Name: '圣伊丽莎白',
              Code: 'ELI'
            },
            {
              Name: '圣詹姆斯',
              Code: 'JAM'
            },
            {
              Name: '特里洛尼',
              Code: 'TRL'
            },
            {
              Name: '西摩兰',
              Code: 'WML'
            }
          ]
        }
      },
      {
        Name: '亚美尼亚',
        Code: 'ARM',
        State: {
          City: [
            {
              Name: '阿尔马维尔',
              Code: 'ARM'
            },
            {
              Name: '阿拉加措特恩',
              Code: 'AGT'
            },
            {
              Name: '阿拉拉特',
              Code: 'ARA'
            },
            {
              Name: '埃里温市',
              Code: 'EVN'
            },
            {
              Name: '格加尔库尼克',
              Code: 'GEG'
            },
            {
              Name: '科泰克',
              Code: 'KOT'
            },
            {
              Name: '洛里',
              Code: 'LOR'
            },
            {
              Name: '塔武什',
              Code: 'TAV'
            },
            {
              Name: '瓦约茨·佐尔',
              Code: 'VAY'
            },
            {
              Name: '希拉克',
              Code: 'SHI'
            },
            {
              Name: '休尼克',
              Code: 'SYU'
            }
          ]
        }
      },
      {
        Name: '也门',
        Code: 'YEM',
        State: {
          City: [
            {
              Name: '阿比扬',
              Code: 'AB'
            },
            {
              Name: '阿姆兰',
              Code: 'AM'
            },
            {
              Name: '贝达',
              Code: 'BA'
            },
            {
              Name: '达利',
              Code: 'DA'
            },
            {
              Name: '哈德拉毛',
              Code: 'HD'
            },
            {
              Name: '哈杰',
              Code: 'HJ'
            },
            {
              Name: '荷台达',
              Code: 'HU'
            },
            {
              Name: '焦夫',
              Code: 'JA'
            },
            {
              Name: '拉赫季',
              Code: 'LA'
            },
            {
              Name: '马里卜',
              Code: 'MA'
            },
            {
              Name: '迈赫拉',
              Code: 'MR'
            },
            {
              Name: '迈赫维特',
              Code: 'MW'
            },
            {
              Name: '萨达',
              Code: 'SD'
            },
            {
              Name: '萨那',
              Code: 'SN'
            },
            {
              Name: '赛文',
              Code: 'GXF'
            },
            {
              Name: '舍卜沃',
              Code: 'SH'
            },
            {
              Name: '塔伊兹',
              Code: 'TA'
            },
            {
              Name: '希赫尔',
              Code: 'ASR'
            },
            {
              Name: '亚丁',
              Code: 'AD'
            },
            {
              Name: '伊卜',
              Code: 'IB'
            },
            {
              Name: '扎玛尔',
              Code: 'DH'
            }
          ]
        }
      },
      {
        Name: '伊拉克',
        Code: 'IRQ'
      },
      {
        Name: '伊朗',
        Code: 'IRN'
      },
      {
        Name: '以色列',
        Code: 'ISR',
        State: {
          City: [
            {
              Name: '阿什杜德',
              Code: 'ASH'
            },
            {
              Name: '贝尔谢巴',
              Code: 'BEV'
            },
            {
              Name: '贝特雁',
              Code: 'BAT'
            },
            {
              Name: '海法',
              Code: 'HFA'
            },
            {
              Name: '霍隆',
              Code: 'HOL'
            },
            {
              Name: '内坦亚',
              Code: 'NAT'
            },
            {
              Name: '特拉维夫',
              Code: 'TLV'
            },
            {
              Name: '耶路撒冷',
              Code: 'J'
            }
          ]
        }
      },
      {
        Name: '意大利',
        Code: 'ITA',
        State: {
          City: [
            {
              Name: '阿斯蒂',
              Code: 'AST'
            },
            {
              Name: '阿斯科利皮切诺',
              Code: 'ASP'
            },
            {
              Name: '安科纳',
              Code: 'AOI'
            },
            {
              Name: '奥尔比亚',
              Code: 'OLB'
            },
            {
              Name: '奥里斯塔诺',
              Code: 'QOS'
            },
            {
              Name: '奥斯塔',
              Code: 'AOT'
            },
            {
              Name: '巴勒莫',
              Code: 'PMO'
            },
            {
              Name: '巴里',
              Code: 'BRI'
            },
            {
              Name: '贝加莫',
              Code: 'BGO'
            },
            {
              Name: '贝内文托',
              Code: 'BEN'
            },
            {
              Name: '比萨',
              Code: 'PSA'
            },
            {
              Name: '波代诺内',
              Code: 'PRD'
            },
            {
              Name: '波坦察',
              Code: 'QPO'
            },
            {
              Name: '博洛尼亚',
              Code: 'BLQ'
            },
            {
              Name: '布拉',
              Code: 'BIE'
            },
            {
              Name: '布雷西亚',
              Code: 'BRC'
            },
            {
              Name: '布林迪西',
              Code: 'BDS'
            },
            {
              Name: '的里雅斯特',
              Code: 'TRS'
            },
            {
              Name: '都灵',
              Code: 'TRN'
            },
            {
              Name: '费拉拉',
              Code: 'FRR'
            },
            {
              Name: '佛罗伦萨',
              Code: 'FLR'
            },
            {
              Name: '福贾',
              Code: 'FOG'
            },
            {
              Name: '卡利亚里',
              Code: 'CAG'
            },
            {
              Name: '卡塞塔',
              Code: 'CST'
            },
            {
              Name: '卡塔尼亚',
              Code: 'CTA'
            },
            {
              Name: '卡坦扎罗',
              Code: 'QCZ'
            },
            {
              Name: '坎波巴索',
              Code: 'COB'
            },
            {
              Name: '科摩',
              Code: 'CIY'
            },
            {
              Name: '科森扎',
              Code: 'QCS'
            },
            {
              Name: '克罗托内',
              Code: 'CRV'
            },
            {
              Name: '库内奥',
              Code: 'CUN'
            },
            {
              Name: '拉奎拉',
              Code: 'LAQ'
            },
            {
              Name: '拉斯佩齐亚',
              Code: 'SPE'
            },
            {
              Name: '莱科',
              Code: 'LCO'
            },
            {
              Name: '莱切',
              Code: 'LCC'
            },
            {
              Name: '雷焦艾米利亚',
              Code: 'RNE'
            },
            {
              Name: '雷焦卡拉布里亚',
              Code: 'REG'
            },
            {
              Name: '里窝那',
              Code: 'LIV'
            },
            {
              Name: '罗马',
              Code: 'ROM'
            },
            {
              Name: '马萨',
              Code: 'MCR'
            },
            {
              Name: '马泰拉',
              Code: 'MTR'
            },
            {
              Name: '蒙扎',
              Code: 'MZA'
            },
            {
              Name: '米兰',
              Code: 'MIL'
            },
            {
              Name: '摩德纳',
              Code: 'MOD'
            },
            {
              Name: '墨西拿',
              Code: 'MSN'
            },
            {
              Name: '那不勒斯',
              Code: 'NAP'
            },
            {
              Name: '努奥罗',
              Code: 'QNU'
            },
            {
              Name: '诺瓦拉',
              Code: 'NVR'
            },
            {
              Name: '帕尔马',
              Code: 'PMF'
            },
            {
              Name: '帕维亚',
              Code: 'PAV'
            },
            {
              Name: '佩鲁贾',
              Code: 'PEG'
            },
            {
              Name: '热那亚',
              Code: 'CAX'
            },
            {
              Name: '萨莱诺',
              Code: 'SAL'
            },
            {
              Name: '萨萨里',
              Code: 'QSS'
            },
            {
              Name: '萨沃纳',
              Code: 'SVN'
            },
            {
              Name: '塔兰托',
              Code: 'TAR'
            },
            {
              Name: '特拉帕尼',
              Code: 'TPS'
            },
            {
              Name: '特伦托',
              Code: 'TRT'
            },
            {
              Name: '威尼斯',
              Code: 'VCE'
            },
            {
              Name: '韦尔切利',
              Code: 'VRL'
            },
            {
              Name: '维泰博',
              Code: 'VIT'
            },
            {
              Name: '乌迪内',
              Code: 'UDN'
            },
            {
              Name: '锡拉库扎',
              Code: 'SYR'
            },
            {
              Name: '锡耶纳',
              Code: 'SNA'
            },
            {
              Name: '亚历山德里亚',
              Code: 'ALE'
            },
            {
              Name: '伊塞尔尼亚',
              Code: 'ISE'
            }
          ]
        }
      },
      {
        Name: '印度',
        Code: 'IND',
        State: {
          City: [
            {
              Name: '艾藻尔',
              Code: 'AJL'
            },
            {
              Name: '班加罗尔',
              Code: 'BLR'
            },
            {
              Name: '本地治里',
              Code: 'PNY'
            },
            {
              Name: '博帕尔',
              Code: 'BHO'
            },
            {
              Name: '布巴内斯瓦尔',
              Code: 'BBI'
            },
            {
              Name: '昌迪加尔',
              Code: 'IXC'
            },
            {
              Name: '达曼',
              Code: 'DAM'
            },
            {
              Name: '第乌',
              Code: 'DIU'
            },
            {
              Name: '甘托克',
              Code: 'GTO'
            },
            {
              Name: '哥印拜陀',
              Code: 'CJB'
            },
            {
              Name: '加尔各答',
              Code: 'CCU'
            },
            {
              Name: '加里加尔',
              Code: 'KRK'
            },
            {
              Name: '贾巴尔普尔',
              Code: 'JLR'
            },
            {
              Name: '贾朗达尔',
              Code: 'JUC'
            },
            {
              Name: '焦特布尔',
              Code: 'JDH'
            },
            {
              Name: '金奈',
              Code: 'MAA'
            },
            {
              Name: '卡瓦拉蒂',
              Code: 'KVA'
            },
            {
              Name: '科希马',
              Code: 'KOM'
            },
            {
              Name: '马埃',
              Code: 'MAH'
            },
            {
              Name: '马杜赖',
              Code: 'IXM'
            },
            {
              Name: '森伯尔布尔',
              Code: 'SLR'
            },
            {
              Name: '特里凡得琅',
              Code: 'TRV'
            },
            {
              Name: '乌代布尔',
              Code: 'UDR'
            },
            {
              Name: '西隆',
              Code: 'SHL'
            },
            {
              Name: '锡尔萨瓦',
              Code: 'SIL'
            },
            {
              Name: '新德里',
              Code: 'ICD'
            },
            {
              Name: '亚南',
              Code: 'SRV'
            },
            {
              Name: '因帕尔',
              Code: 'IMF'
            },
            {
              Name: '印多尔',
              Code: 'IDR'
            },
            {
              Name: '斋普尔',
              Code: 'JAI'
            }
          ]
        }
      },
      {
        Name: '印度尼西亚',
        Code: 'IDN',
        State: {
          City: [
            {
              Name: '巴厘',
              Code: 'BA'
            },
            {
              Name: '邦加－勿里洞群岛',
              Code: 'BB'
            },
            {
              Name: '北苏拉威西',
              Code: 'SA'
            },
            {
              Name: '北苏门答腊',
              Code: 'SU'
            },
            {
              Name: '大雅加达首都特区',
              Code: 'KB'
            },
            {
              Name: '东加里曼丹',
              Code: 'KI'
            },
            {
              Name: '东南苏拉威西',
              Code: 'SG'
            },
            {
              Name: '东努沙登加拉',
              Code: 'NT'
            },
            {
              Name: '东爪哇',
              Code: 'JI'
            },
            {
              Name: '廖内',
              Code: 'RI'
            },
            {
              Name: '马鲁古',
              Code: 'MA'
            },
            {
              Name: '明古鲁',
              Code: 'BE'
            },
            {
              Name: '楠榜',
              Code: 'LA'
            },
            {
              Name: '南加里曼丹',
              Code: 'KS'
            },
            {
              Name: '南苏拉威西',
              Code: 'SN'
            },
            {
              Name: '南苏门答腊',
              Code: 'SS'
            },
            {
              Name: '日惹特区',
              Code: 'YO'
            },
            {
              Name: '万丹',
              Code: 'BT'
            },
            {
              Name: '西努沙登加拉',
              Code: 'NB'
            },
            {
              Name: '西苏门答腊',
              Code: 'SR'
            },
            {
              Name: '西爪哇',
              Code: 'JB'
            },
            {
              Name: '雅加达',
              Code: 'JK'
            },
            {
              Name: '亚齐',
              Code: 'AC'
            },
            {
              Name: '伊里安查亚',
              Code: 'IJ'
            },
            {
              Name: '占碑',
              Code: 'JA'
            },
            {
              Name: '中加里曼丹',
              Code: 'KT'
            },
            {
              Name: '中苏拉威西',
              Code: 'ST'
            },
            {
              Name: '中爪哇',
              Code: 'JT'
            }
          ]
        }
      },
      {
        Name: '英国',
        Code: 'GBR',
        State: [
          {
            Name: '北爱尔兰',
            Code: 'NIR',
            City: [
              {
                Name: '贝尔法斯特',
                Code: 'BFS'
              },
              {
                Name: '德里',
                Code: 'DRY'
              },
              {
                Name: '利斯本',
                Code: 'LSB'
              },
              {
                Name: '纽里',
                Code: 'NYM'
              }
            ]
          },
          {
            Name: '苏格兰',
            Code: 'SCT',
            City: [
              {
                Name: '阿伯丁',
                Code: 'ABD'
              },
              {
                Name: '爱丁堡',
                Code: 'EDH'
              },
              {
                Name: '丹迪',
                Code: 'DND'
              },
              {
                Name: '格拉斯哥',
                Code: 'GLG'
              },
              {
                Name: '斯特灵',
                Code: 'STG'
              },
              {
                Name: '因弗内斯',
                Code: 'INV'
              }
            ]
          },
          {
            Name: '威尔士',
            Code: 'WLS',
            City: [
              {
                Name: '班戈',
                Code: 'BAN'
              },
              {
                Name: '卡迪夫',
                Code: 'CDF'
              },
              {
                Name: '纽波特',
                Code: 'NWP'
              },
              {
                Name: '斯旺西',
                Code: 'SWA'
              }
            ]
          },
          {
            Name: '英格兰',
            Code: 'ENG',
            City: [
              {
                Name: '埃克塞特',
                Code: 'EXE'
              },
              {
                Name: '巴斯',
                Code: 'BAS'
              },
              {
                Name: '彼得伯勒',
                Code: 'PTE'
              },
              {
                Name: '伯明翰',
                Code: 'BIR'
              },
              {
                Name: '布拉德福德',
                Code: 'BRD'
              },
              {
                Name: '布莱顿与赫福',
                Code: 'BNH'
              },
              {
                Name: '布里斯托尔',
                Code: 'BST'
              },
              {
                Name: '德比',
                Code: 'DER'
              },
              {
                Name: '德罕',
                Code: 'DUR'
              },
              {
                Name: '格洛斯特',
                Code: 'GLO'
              },
              {
                Name: '赫尔河畔京斯敦',
                Code: 'KUH'
              },
              {
                Name: '赫里福德',
                Code: 'HAF'
              },
              {
                Name: '剑桥',
                Code: 'CAM'
              },
              {
                Name: '卡莱尔',
                Code: 'CAX'
              },
              {
                Name: '坎特伯雷',
                Code: 'CNG'
              },
              {
                Name: '考文垂',
                Code: 'COV'
              },
              {
                Name: '兰开斯特',
                Code: 'LAN'
              },
              {
                Name: '里彭',
                Code: 'RIP'
              },
              {
                Name: '利奇菲尔德',
                Code: 'LHF'
              },
              {
                Name: '利物浦',
                Code: 'LIV'
              },
              {
                Name: '利茲',
                Code: 'LDS'
              },
              {
                Name: '列斯特',
                Code: 'LCE'
              },
              {
                Name: '林肯',
                Code: 'LCN'
              },
              {
                Name: '伦敦',
                Code: 'LND'
              },
              {
                Name: '曼彻斯特',
                Code: 'MAN'
              },
              {
                Name: '南安普敦',
                Code: 'STH'
              },
              {
                Name: '牛津',
                Code: 'OXF'
              },
              {
                Name: '纽卡斯尔',
                Code: 'NCL'
              },
              {
                Name: '诺丁汉',
                Code: 'NGM'
              },
              {
                Name: '诺里奇',
                Code: 'NRW'
              },
              {
                Name: '朴茨茅斯',
                Code: 'POR'
              },
              {
                Name: '普雷斯顿',
                Code: 'PRE'
              },
              {
                Name: '普利茅斯',
                Code: 'PLY'
              },
              {
                Name: '奇切斯特',
                Code: 'CST'
              },
              {
                Name: '切斯特',
                Code: 'CEG'
              },
              {
                Name: '桑德兰',
                Code: 'SUN'
              },
              {
                Name: '圣阿本斯',
                Code: 'TBL'
              },
              {
                Name: '索尔斯堡',
                Code: 'SLS'
              },
              {
                Name: '索福特',
                Code: 'SLF'
              },
              {
                Name: '特鲁罗',
                Code: 'TRU'
              },
              {
                Name: '特伦特河畔斯多克',
                Code: 'SOT'
              },
              {
                Name: '威尔斯',
                Code: 'WLS'
              },
              {
                Name: '韦克菲尔德',
                Code: 'WKF'
              },
              {
                Name: '温彻斯特',
                Code: 'WNE'
              },
              {
                Name: '伍尔弗汉普顿',
                Code: 'WOV'
              },
              {
                Name: '伍斯特',
                Code: 'WOR'
              },
              {
                Name: '谢菲尔德',
                Code: 'SHE'
              },
              {
                Name: '伊利',
                Code: 'ELY'
              },
              {
                Name: '约克',
                Code: 'YOR'
              }
            ]
          }
        ]
      },
      {
        Name: '英属印度洋领地',
        Code: 'IOT'
      },
      {
        Name: '约旦',
        Code: 'JOR',
        State: {
          City: [
            {
              Name: '阿吉隆',
              Code: 'AJ'
            },
            {
              Name: '安曼',
              Code: 'AM'
            },
            {
              Name: '拜勒加',
              Code: 'BA'
            },
            {
              Name: '杰拉什',
              Code: 'JA'
            },
            {
              Name: '卡拉克',
              Code: 'KA'
            },
            {
              Name: '鲁赛法',
              Code: 'RU'
            },
            {
              Name: '马安',
              Code: 'MN'
            },
            {
              Name: '马德巴',
              Code: 'MD'
            },
            {
              Name: '马夫拉克',
              Code: 'MF'
            },
            {
              Name: '塔菲拉',
              Code: 'TA'
            },
            {
              Name: '亚喀巴',
              Code: 'AQ'
            },
            {
              Name: '伊尔比德',
              Code: 'IR'
            },
            {
              Name: '扎尔卡',
              Code: 'ZA'
            }
          ]
        }
      },
      {
        Name: '越南',
        Code: 'VNM',
        State: {
          City: [
            {
              Name: '海防',
              Code: 'HP'
            },
            {
              Name: '河内',
              Code: 'HI'
            },
            {
              Name: '胡志明市',
              Code: 'HC'
            }
          ]
        }
      },
      {
        Name: '赞比亚',
        Code: 'ZMB',
        State: {
          City: [
            {
              Name: '北方',
              Code: 'NO'
            },
            {
              Name: '东方',
              Code: 'EA'
            },
            {
              Name: '卢阿普拉',
              Code: 'LP'
            },
            {
              Name: '卢萨卡',
              Code: 'LK'
            },
            {
              Name: '南方',
              Code: 'SO'
            },
            {
              Name: '铜带',
              Code: 'CB'
            },
            {
              Name: '西北',
              Code: 'NW'
            },
            {
              Name: '西方',
              Code: 'WE'
            },
            {
              Name: '中央',
              Code: 'CE'
            }
          ]
        }
      },
      {
        Name: '泽西岛',
        Code: 'JEY'
      },
      {
        Name: '乍得',
        Code: 'TCD'
      },
      {
        Name: '直布罗陀',
        Code: 'GIB'
      },
      {
        Name: '智利',
        Code: 'CHL',
        State: {
          City: [
            {
              Name: '阿劳卡尼亚大区',
              Code: 'AR'
            },
            {
              Name: '阿塔卡马大区',
              Code: 'AT'
            },
            {
              Name: '安托法加斯塔大区',
              Code: 'AN'
            },
            {
              Name: '比奥比奥大区',
              Code: 'BI'
            },
            {
              Name: '复活节岛',
              Code: 'LI'
            },
            {
              Name: '湖大区',
              Code: 'LL'
            },
            {
              Name: '科金博大区',
              Code: 'CO'
            },
            {
              Name: '马乌莱大区',
              Code: 'ML'
            },
            {
              Name: '麦哲伦-智利南极大区',
              Code: 'MA'
            },
            {
              Name: '圣地亚哥',
              Code: 'RM'
            },
            {
              Name: '塔拉帕卡大区',
              Code: 'TA'
            },
            {
              Name: '瓦尔帕莱索大区',
              Code: 'VS'
            },
            {
              Name: '伊瓦涅斯将军的艾森大区',
              Code: 'AI'
            }
          ]
        }
      },
      {
        Name: '中非共和国',
        Code: 'CAF',
        State: {
          City: [
            {
              Name: '巴明吉-班戈兰',
              Code: 'BB'
            },
            {
              Name: '班吉直辖市',
              Code: 'BGF'
            },
            {
              Name: '宾博',
              Code: 'BI'
            },
            {
              Name: '凯莫',
              Code: 'KG'
            },
            {
              Name: '洛巴伊',
              Code: 'LB'
            },
            {
              Name: '曼贝雷-卡代',
              Code: 'HS'
            },
            {
              Name: '姆博穆',
              Code: 'MB'
            },
            {
              Name: '纳纳-格里比齐',
              Code: 'KB'
            },
            {
              Name: '纳纳-曼贝雷',
              Code: 'NM'
            },
            {
              Name: '桑加-姆巴埃雷',
              Code: 'SE'
            },
            {
              Name: '上科托',
              Code: 'HK'
            },
            {
              Name: '上姆博穆',
              Code: 'HM'
            },
            {
              Name: '瓦卡',
              Code: 'UK'
            },
            {
              Name: '瓦卡加',
              Code: 'VK'
            },
            {
              Name: '瓦姆',
              Code: 'AC'
            },
            {
              Name: '瓦姆-彭代',
              Code: 'OP'
            },
            {
              Name: '翁贝拉-姆波科',
              Code: 'MP'
            },
            {
              Name: '下科托',
              Code: 'BK'
            }
          ]
        }
      }
    ]
  }
}
