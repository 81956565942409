var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelManagement" },
    [
      _c("div", { staticClass: "channelManagement-head" }),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.pageMsg,
                size: "small",
                "label-position": "center",
                "label-width": "60px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入公司名称" },
                            model: {
                              value: _vm.pageMsg.stockName,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "stockName", $$v)
                              },
                              expression: "pageMsg.stockName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "时间:" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.pageMsg.dateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.pageMsg, "dateTime", $$v)
                              },
                              expression: "pageMsg.dateTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.initData },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("el-button", { on: { click: _vm.resetRoadShow } }, [
                        _vm._v("重置"),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.addRoadVisible = true
                            },
                          },
                        },
                        [_vm._v("新建")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "refTable",
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { background: "rgb(250,250,250)" },
                "header-row-class-name": "tableHead",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "stockName",
                  label: "公司名称",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "提交人",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "visitTime",
                  label: "拜访时间",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDate(scope.row.visitTime))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileUrl",
                  label: "附件",
                  "header-align": "center",
                  align: "center",
                  width: "auto",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.redirectToUrl(scope.row.fileUrl)
                              },
                            },
                          },
                          [_vm._v("查看附件")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delRecord(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pb20" }),
          _vm.pageTotal > 0
            ? _c("el-pagination", {
                staticClass: "text-center",
                attrs: {
                  "current-page": _vm.pageMsg.pageNum,
                  "page-sizes": [100, 200, 300, 400],
                  "page-size": _vm.pageMsg.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pageTotal,
                },
                on: {
                  "size-change": _vm.initData,
                  "current-change": _vm.initData,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageNum", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageMsg, "pageSize", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "在线填报",
            visible: _vm.addRoadVisible,
            width: "50%",
            "before-close": _vm.addRoadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addRoadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addRoadRef",
              attrs: {
                model: _vm.addRoadForm,
                rules: _vm.addRoadRules,
                "label-position": _vm.labelPosition,
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称:", prop: "stockName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addRoadForm.stockName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRoadForm, "stockName", $$v)
                      },
                      expression: "addRoadForm.stockName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "拜访人员:", prop: "userName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addRoadForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRoadForm, "userName", $$v)
                      },
                      expression: "addRoadForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "拜访时间:", prop: "visitTime" } },
                [
                  _c("el-date-picker", {
                    attrs: { type: "date", placeholder: "选择日期" },
                    model: {
                      value: _vm.addRoadForm.visitTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRoadForm, "visitTime", $$v)
                      },
                      expression: "addRoadForm.visitTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传附件:", prop: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        action: _vm.uploadUrl,
                        limit: 1,
                        "file-list": _vm.fileList,
                        multiple: "",
                        "on-success": _vm.handleAvatarSuccess,
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("上传文件大小不超过5MB")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addRoadShow } },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.resetAddRoadShow } }, [
                _vm._v("重置"),
              ]),
              _c("el-button", { on: { click: _vm.closeAddRoadShow } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }