var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "water-level-chart" } }, [
    _c(
      "div",
      { staticClass: "chart-container" },
      [_c("dv-charts", { attrs: { option: _vm.option } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }