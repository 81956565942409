
<template>
  <div id="water-level-chart">
    <div class="chart-container">
      <dv-charts :option="option" />
    </div>
  </div>
</template>

<script>

import { queryReleaseRateApi } from 'api/data'

export default {
  name: 'releaseRate',
  props:{
    dateType: {
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      option: {}
    }
  },
  created () {
    this.initData();
  },
  methods:{
    initData(){
      queryReleaseRateApi(this.dateType).then(res => {
        if (res.code ===200){
          this.option = {
            title: {
              text: '发布率',
              style: {
                fill: '#fff'
              }
            },
            series: [
              {
                type: 'gauge',
                data: [ { name: 'itemA', value: res.result } ],
                center: ['50%', '70%'],
                axisLabel: {
                  formatter: '{value}%',
                  style: {
                    fill: '#fff'
                  }
                },
                axisTick: {
                  style: {
                    stroke: '#fff'
                  }
                },
                animationCurve: 'easeInOutBack'
              }
            ]
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#water-level-chart {
  width: 33%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
