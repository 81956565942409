<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">


      <div>
        <el-form
          :model='pageMsg'
          size="small"
          label-position="center"
          label-width="100px">

          <el-row>
            <el-col :span='8'>
              <el-form-item label='类型:' prop="interactType">
                <el-select v-model="pageMsg.interactType" placeholder="请选择类型">
                  <el-option
                    v-for="item in iprTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span='8'>
              <span>
                <el-button type='primary' @click='initData'>查询</el-button>
                <el-button @click='resetCli'>重置</el-button>
              </span>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div>
        <el-table
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          :data="tableData"
          v-loading="tableLoading"
        >
          <el-table-column
            prop="iprType"
            label="IPR类型"
            header-align="center"
            align="center"
          >
          </el-table-column>




        </el-table>


      </div>


    </el-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      iprTypeOptions:[
        {
          label:"IPR100",
          value:"100"
        },
        {
          label:"IPR300",
          value:"300"
        },
        {
          label:"IPR500",
          value:"500"
        },
      ],
      pageMsg:[],
      tableData:[],
      tableLoading:false
    }
  },
  methods:{
    initData(){

    },
    resetCli(){

    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}
</style>
