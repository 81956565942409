var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$route.path == "/rssPushForm"
      ? _c(
          "div",
          { staticClass: "releaseForm" },
          [
            !_vm.isPublish
              ? _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "formData",
                        attrs: {
                          model: _vm.formData,
                          rules: _vm.formRules,
                          size: "small",
                          "label-width": "140px",
                        },
                      },
                      [
                        _c(
                          "section",
                          { staticClass: "self-descriptions" },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "limitinputWidth",
                                        attrs: {
                                          label: "文章标题：",
                                          prop: "articleTitle",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "300px" },
                                          attrs: {
                                            placeholder: "请输入标题名称",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.formData.articleTitle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "articleTitle",
                                                $$v
                                              )
                                            },
                                            expression: "formData.articleTitle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "limitinputWidth",
                                        attrs: {
                                          label: "公司名称:",
                                          prop: "author",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "300px" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "填写为上市公司名称",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.autoCode(
                                                _vm.formData.stockName
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.formData.stockName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "stockName",
                                                $$v
                                              )
                                            },
                                            expression: "formData.stockName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _vm.show
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 9 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "limitinputWidth",
                                            attrs: { label: "所属行业:" },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "300px" },
                                              attrs: {
                                                clearable: "",
                                                disabled: "",
                                                placeholder: "填输入所属行业",
                                              },
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .categoryCodeFirstName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "categoryCodeFirstName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.categoryCodeFirstName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.show1
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "行业类型:" } },
                                          [
                                            _c("el-cascader", {
                                              attrs: { options: _vm.options },
                                              on: { change: _vm.handleChange },
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .industryCategoryArr,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "industryCategoryArr",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.industryCategoryArr",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _vm.show
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 9 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "内容类型:" } },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "300px" },
                                              attrs: {
                                                clearable: "",
                                                disabled: "",
                                                placeholder: "请输入内容类型",
                                              },
                                              model: {
                                                value: _vm.formData.stockType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "stockType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.stockType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.show1
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 9 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "股票类型:" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "auto" },
                                                attrs: {
                                                  placeholder: "请选择股票类型",
                                                },
                                                model: {
                                                  value: _vm.formData.stockType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "stockType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.stockType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.optionContent,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "limitinputWidth",
                                        attrs: {
                                          label: "关联公司：",
                                          prop: "companyId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              filterable: "",
                                              placeholder:
                                                "请填写公司名称或者股票代码",
                                            },
                                            model: {
                                              value: _vm.formData.companyId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "companyId",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.companyId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.companyOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.companyName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 9 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "原文链接:" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请输入原文链接",
                                          },
                                          model: {
                                            value: _vm.formData.contentUrl,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "contentUrl",
                                                $$v
                                              )
                                            },
                                            expression: "formData.contentUrl",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "内容摘要：",
                                          prop: "articleContent",
                                        },
                                      },
                                      [
                                        _c("vue-tinymce", {
                                          attrs: { setting: _vm.setting },
                                          model: {
                                            value: _vm.formData.articleContent,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "articleContent",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.articleContent",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "pt40" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      { on: { click: _vm.goBack } },
                                      [_vm._v("返回 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          loading: _vm.loading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitForm("formData")
                                          },
                                        },
                                      },
                                      [_vm._v("立即发布 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }