var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "staris_finial" }, [
    _vm.publishSuccess.status
      ? _c(
          "div",
          { staticClass: "success" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-result",
                      {
                        attrs: {
                          icon: "success",
                          title: "提交成功",
                          subTitle: "",
                        },
                      },
                      [_c("template", { slot: "extra" })],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("p", { staticClass: "reseturl" }, [
              _vm._v(
                " 提交成功后，5s内自动跳转至分发中心，如需立刻返回，请点击返回列表按钮进行返回 "
              ),
            ]),
            _c("h4", [_vm._v("动态信息")]),
            _c("div", { staticClass: "reason_div" }, [
              _c("p", { staticClass: "reason_title" }, [
                _vm._v(" 任务标题："),
                _c("span", [_vm._v(_vm._s(_vm.publishSuccess.reason))]),
              ]),
              _c("p", [
                _vm._v(" 上传人："),
                _c("span", [_vm._v(_vm._s(_vm.publishSuccess.people))]),
              ]),
              _c("p", [
                _vm._v(" 提交时间："),
                _c("span", [_vm._v(_vm._s(new Date().toLocaleString()))]),
              ]),
            ]),
            _c(
              "el-steps",
              { attrs: { active: 2, "align-center": "" } },
              [
                _c("el-step", {
                  attrs: { title: "创建动态", description: "" },
                }),
                _c("el-step", {
                  attrs: { title: "系统初审", description: "" },
                }),
                _c("el-step", {
                  attrs: { title: "媒介复审", description: "" },
                }),
                _c("el-step", {
                  attrs: { title: "分发成功", description: "" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "success_btns" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "medium" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/release")
                      },
                    },
                  },
                  [_vm._v("返回列表")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "fail" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-result",
                      {
                        attrs: {
                          icon: "error",
                          title: "提交失败",
                          subTitle: "请核对并修改以下信息后，再重新提交。",
                        },
                      },
                      [_c("template", { slot: "extra" })],
                      2
                    ),
                    _c("div", { staticClass: "fail_text" }, [
                      _c("h4", [_vm._v("您提交的内容有如下错误：")]),
                      _c("p", [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.errReason || _vm.publishSuccess.reason) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "success_btns" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "medium" },
                    on: { click: _vm.closePage },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }