<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">
      <el-form
        :model="pageMsg"
        ref="formData"
        size="small"
        label-position="center"
        label-width="60px"
      >

        <el-row>

          <el-col :span='12'>
            <el-form-item label='名称:'>
              <el-input v-model="pageMsg.stockName" style='width: 200px' placeholder="请输入公司名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span='12'>
            <el-form-item label='时间:'>
              <el-date-picker
                v-model="pageMsg.dateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>


        </el-row>

        <el-row>
          <el-col :span='8'>
            <el-button type='primary' @click='initData'>查询</el-button>
            <el-button @click='resetRoadShow'>重置</el-button>
            <el-button type='primary' @click='addRoadVisible=true'>新建</el-button>
          </el-col>
        </el-row>

      </el-form>

      <el-table
        ref="refTable"
        :data="tableData"
        :header-cell-style="{ background: 'rgb(250,250,250)' }"
        header-row-class-name="tableHead"
        v-loading="tableLoading"
      >

        <el-table-column
          prop='stockName'
          label="公司名称"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop='userName'
          label="提交人"
          header-align="center"
          align="center"
          width="auto"
        >
        </el-table-column>

        <el-table-column
          prop='visitTime'
          label="拜访时间"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.visitTime) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop='fileUrl'
          label="附件"
          header-align="center"
          align="center"
          width="auto"
        >
          <template slot-scope="scope">
            <el-button @click="redirectToUrl(scope.row.fileUrl)" type="text">查看附件</el-button>
            <el-button @click="delRecord(scope.row)" type="text">删除</el-button>
          </template>

        </el-table-column>

      </el-table>
      <div class="pb20"></div>
      <el-pagination
        class="text-center"
        v-if="pageTotal > 0"
        @size-change="initData"
        @current-change="initData"
        :current-page.sync="pageMsg.pageNum"
        :page-sizes="[100, 200, 300, 400]"
        :page-size.sync="pageMsg.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </el-card>

      <el-dialog
        title="在线填报"
        :visible.sync="addRoadVisible"
        width="50%"
        :before-close="addRoadClose">
        <el-form
        :model='addRoadForm'
        ref='addRoadRef'
        :rules='addRoadRules'
        :label-position="labelPosition"
        :label-width="formLabelWidth">

          <el-form-item label="公司名称:" prop="stockName">
            <el-input v-model="addRoadForm.stockName"></el-input>
          </el-form-item>

          <el-form-item label="拜访人员:" prop="userName">
            <el-input v-model="addRoadForm.userName"></el-input>
          </el-form-item>

          <el-form-item label="拜访时间:" prop="visitTime">
            <el-date-picker
              v-model="addRoadForm.visitTime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item label='上传附件:' prop="">
            <el-upload
              ref='upload'
              class="upload-demo"
              drag
              :action="uploadUrl"
              :limit="1"
              :file-list="fileList"
              multiple
              :on-success="handleAvatarSuccess">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">上传文件大小不超过5MB</div>
            </el-upload>
          </el-form-item>
          <el-button type='primary' @click="addRoadShow">提交</el-button>
          <el-button @click="resetAddRoadShow">重置</el-button>
          <el-button @click='closeAddRoadShow'>取消</el-button>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>

import { addRoadShowApi,queryRoadShowPageApi,delRecordApi } from 'api/process'
import constant from 'constant'

export default {
  data(){
    return{
      uploadUrl: constant.GLOBAL_CONFIG.UPLOADIMG_Url,
      fileList: [],
      formLabelWidth: '130px',
      labelPosition: 'left',
      addRoadVisible:false,
      tableLoading:false,
      pageMsg: {},
      addRoadForm:{
        stockName:null,
        userName:null,
        visitTime:null,
        fileUrl:null
      },
      tableData:[],
      pageTotal: 0,
      addRoadRules:{

      }

    }
  },
  created() {
    this.initData();
  },
  methods:{
    closeAddRoadShow(){
      this.addRoadClose();
    },
    delRecord(row){
      delRecordApi(row.id).then( res => {
        if (res.code === 200){
          this.$message.success("删除成功");
          this.initData();
        }
      })
    },
    redirectToUrl(url) {
      if (url === null ){
        this.$message.error("暂无附件!")
        return;
      }
      window.open(url, '_blank');
    },
    addRoadShow(){
      addRoadShowApi(this.addRoadForm).then(res => {
        if (res.code === 200){
          this.$message.success("添加成功");
          this.$refs.upload.clearFiles();
          this.addRoadClose();
          this.initData();
        }
      })
    },
    handleAvatarSuccess(res) {
      if (res.code === 200){
        this.addRoadForm.fileUrl = res.result.url;
      }
    },
    resetRoadShow(){
      //查询重置
      this.pageMsg = this.$options.data().pageMsg;
      this.initData();
    },
    resetAddRoadShow(){
      this.addRoadForm = this.$options.data().addRoadForm;
      this.$refs.upload.clearFiles();
    },
    initData(){
      if (this.pageMsg.dateTime!=null&&this.pageMsg.dateTime!=''&&this.pageMsg.dateTime!=undefined){
        const startDate = new Date(this.pageMsg.dateTime[0]);
        const endDate = new Date(this.pageMsg.dateTime[1]);
        this.pageMsg.startTime = this.formatDate(startDate);
        this.pageMsg.endTime = this.formatDate(endDate);
      }
      queryRoadShowPageApi(this.pageMsg).then( res => {
        if (res.code ===200){
          this.tableData = res.result.records;
          this.pageTotal = res.result.total;
        }
      })
    },
    addRoadClose(){
      this.addRoadVisible = false;
      this.addRoadForm = this.$options.data().addRoadForm;
      this.$refs.upload.clearFiles();
    },
    formatDate(dateString) {
      // Ensure dateString is not empty or null
      if (!dateString) {
        return '';
      }

      // Try to create a Date object from the dateString
      const date = new Date(dateString);

      // Check if the date is a valid Date object
      if (isNaN(date.getTime())) {
        // If not a valid date, return an empty string or handle it accordingly
        return 'Invalid Date';
      }

      // Proceed with formatting if the date is valid
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return `${year}-${month}-${day}`;
    }
  }

}

</script>

<style scoped lang='scss'>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
}

</style>