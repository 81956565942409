<template>
  <div>
    <router-view />
    <div class="release" v-if="$route.path == '/rssPush'">
      <el-row class="release-top-new">
        <el-col :span="24" style="padding-bottom: 10px">
          <el-button
            type="primary"
            size="middle"
            @click="$router.push('/rssPushForm')"
          >
            发布内容
          </el-button>
        </el-col>
      </el-row>
      <el-card class="box-card" style='margin-top: 30px'>
        <el-form
          :model="pageMsg"
          ref="formData"
          size="small"
          label-position="left"
          label-width="44px"
        >
          <el-row class="text-left" :gutter="20">
            <el-col :span="6">
              <el-form-item label="公司:" >
                <el-input clearable   @keyup.enter.native="searchData(true)"
                          v-model="pageMsg.stockName" @input="getPinYin3(pageMsg.stockName)"  style="width: 200px;color: #dcdfe6"  placeholder="请输入公司简称"></el-input>
              </el-form-item>

            </el-col>

            <el-col :span="4">

            </el-col>
            <el-col :span="4">
              <el-form-item label="类型:" class="limitinputWidth">
                <el-select v-model="pageMsg.contentTypeId" >
                  <el-option
                    v-for="item in contentTypeList"
                    :key="item.id"
                    :label="item.data"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="4" class="text-right">
              <el-button type="primary" size="small" @click="searchData(true)">
                查询
              </el-button>
              <el-button size="small" @click="searchData(false)">
                重置
              </el-button>
            </el-col>
          </el-row>

        </el-form>
        <div class="select_warn" v-if="selectionArr.length > 0">
          已经选择
          <span>{{ selectionArr.length }}</span>
          项
          <el-button
            type="text"
            @click="normalList(false)"
            v-if="userbtn.indexOf('ARTICLE_SEND_MOREHIDE') >= 0"
          >屏蔽</el-button
          >
          <el-button
            type="text"
            @click="normalList(true)"
            v-if="userbtn.indexOf('ARTICLE_SEND_MORESHOW') >= 0"
          >显示</el-button
          >
        </div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          header-row-class-name="tableHead"
          :header-cell-style="{ background: 'rgb(250,250,250)' }"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="stockName"
            label="企业简称"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click.native.prevent="turnDetail1(row)">
                <p style="display: inline-block;">
                  <span style="color: red;font-size: 30px;">
                {{row.isClient}}
              </span>
                  {{ row.stockName }}
                </p>

              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="articleTitle"
            label="动态"
            header-align="center"
            align="center"
          >

          </el-table-column>
          <el-table-column
            prop="categoryCodeFirstName"
            label="行业类型"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="contentType"
            label="内容类型"
            header-align="center"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="contentUrl"
            label="原文链接"
            header-align="center"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click="aaaa(row)">
                {{ row.contentUrl }}
              </el-button>
            </template>
          </el-table-column>

        </el-table>
        <div class="pb20"></div>
        <el-pagination
          class="text-center"
          v-if="pageTotal > 0"
          @size-change="initData"
          @current-change="initData"
          :current-page.sync="pageMsg.pageNum"
          :page-size.sync="pageMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </el-card>
    </div>
    <vue-html2pdf
      :show-layout="showLayout"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="文章详情"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-orientation="landscape"
      pdf-content-width="1000px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <rssPushDetail :rowID="rowID" @downPdf="downPdf" />
      </section>
    </vue-html2pdf>


    <el-dialog title="查看文章"    :visible.sync="passLoading3"  @close="dialogCreate3" >
      <rssPushDetail :detailID="detailID" @downPdf="downPdf" />
    </el-dialog>
    <el-dialog
      :visible.sync="detailBool"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1200px"
    >
      <rssPushDetail :rowID="rowID1"/>
    </el-dialog>

  </div>
</template>

<script>

import { enableDisableUserApi } from 'api/rightsetting'
import {
  articlePageApi,
  statusStatisticsApi,
  frozenApi,
  normalApi,
  authStateSuccessApi,
  authStatePassApi,
  basicWordSelectApi,
  basicWordSelectApi1,
  authStateFailApi,
  authStateSuccessApiYX,
  articleExportApi,
  contentTypeAllApi,
  articleSetTrueApi,
  articleSetFalseApi,
  getClientApi,
  invalidExportApi,
  articleRssPageApi
} from 'api/article'

import { companyPageApi } from 'api/company'
import VueHtml2pdf from 'vue-html2pdf'
import rssPushDetail from 'views/rssPush/rssPushDetail.vue'
export default {
  name: 'rssPush',
  components: {
    rssPushDetail,
    VueHtml2pdf
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: '文章详情.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'a3',
          orientation: 'portrait'
        }
      }
    }
  },
  data() {
    return {
      exportTime:"", //导出时间区间
      exportIndustry:"", //导出行业
      exportIndustryOptions:[],//导出行业分类
      exportVisible:false, //导出列表分类显示
      clientInfo:[],
      invalidFailReason:'',
      //无效原因
      invalidOptions:[],
      //有效原因
      validOptions: [],
      canSubmit: false,
      countdown: 0,
      detailID: '',
      pageMsg1: {
        pageNum: 1,
        pageSize: 100,
        pass: 1,
        descs: ['created_time'],
        companyId: '',
        authState: '',
        startTime: '',
        startT: null,
        endTime: '',
        endT: null,
        stockName: null,
        contentTypeId: null
      },
      pageMsg2: {
        pageNum: 1,
        pageSize: 100,
        pass: 2,
        descs: ['created_time'],
        companyId: '',
        authState: '',
        startTime: '',
        startT: null,
        endTime: '',
        endT: null,
        stockName: null,
        contentTypeId: null
      },
      pageMsg3: {
        pageNum: 1,
        pageSize: 10,
        pass: 3,
        descs: ['created_time'],
        companyId: '',
        authState: '',
        startTime: '',
        startT: null,
        endTime: '',
        endT: null,
        stockName: null,
        contentTypeId: null
      },
      showLayout: false,
      imgSrc: '',
      rowID: null,
      rowID1: null,
      topListStatus: {},
      timeDefaultShow: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      searchFormData: {
        searchRole: '',
        searchUserName: ''
      },
      publisherDate: [], //时间选择器筛选
      publisherDate1: [], //时间选择器筛选
      publisherDate2: [], //时间选择器筛选
      publisherDate3: [], //时间选择器筛选
      selectionArr: [],
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      pageTotal: 0,
      pageTotal1: 0,
      pageTotal2: 0,
      pageTotal3: 0,
      pageMsg: {
        pageNum: 1,
        pageSize: 10,
        descs: ['created_time'],
        stockName: null,
        contentTypeId: null
      },
      userDetailOperate: '新增用户',
      companyOptions: [],
      authStateArr: [
        {
          label: '审核中',
          value: 10
        },
        {
          label: '成功',
          value: 20
        },
        {
          label: '失败',
          value: 30
        }
      ],
      tableLoading: false,
      tableLoading1: false,
      tableLoading2: false,
      tableLoading3: false,
      passDialog: false,
      passDialog1: false,
      passDialog2: false,
      passDialog3: false,
      passDialog4: false,
      passDialog5: false,
      oneDialog: false,
      oneDialog1: false,
      oneDialog2: false,
      oneDialog3: false,
      oneDialog4: false,
      oneDialog5: false,
      ruleModel: {
        articleId: null,
        failReason: ''
      },
      ruleModel1: {
        articleId: null,
        failReason: ''
      },
      ruleModel3: {
        articleId: null,
        failReason: ''
      },
      ruleModel4: {
        articleId: null,
        failReason: ''
      },
      ruleModel5: {
        articleId: null,
        failReason: ''
      },
      ruleModel2: {
        articleId: null,
        failReason: ''
      },
      /* rules:{
           failReason: [
               { required: true, message: '请选择驳回原因', trigger: 'blur' }
           ]},*/
      basicWordCode: [],
      basicWordCode1: [],
      passLoading: false,
      passLoading1: false,
      passLoading3: false,
      detailBool: false,
      passLoading4: false,
      passLoading5: false,
      rejectLoading: false,
      shRejectLoading: false,
      contentTypeList: [],

    }
  },
  created() {
    this.getClientFun();
    // getClientApi();
    this.timeDefaultShow = new Date()
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
    setInterval(()=>{
      this.initstatus()
    },10000)
    this.toBase64(
      'https://img-blog.csdnimg.cn/img_convert/216a253e50b83b3c33a53a9a95d233b5.png'
    )
  },
  mounted() {
    this.initstatus()
    this.initData()
    this.initCompany()
    this.initContentType()
  },

  watch: {
    $route: {
      handler(to) {
        // 初始化操作
        if (to.path === '/rssPush') {
          // this.pageMsg = this.$options.data().pageMsg
          this.initData()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    //无效列表导出
    invalidExportClick(){
      const data = {
        ...this.pageMsg1
      }
      invalidExportApi(data).then(data => {
        // debugger;
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        // let dateXls =  this.pageMsg.startMonths+'至'+this.pageMsg.endMonths;
        link.download = `无效列表.csv`
        link.href = blobUrl;
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    //导出数据方法
    exportDataClick(){

    },
    //导出数据显示
    exportDataShowClick(){
      this.exportVisible = true;
    },
    getPinYin(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */

      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg3.stockName =name.toUpperCase();

      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg3.stockName)
    },
    getPinYin1(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */

      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg2.stockName =name.toUpperCase();

      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg2.stockName)
    },
    getPinYin2(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */

      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg1.stockName =name.toUpperCase();

      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg1.stockName)
    },
    getPinYin3(name){
      /**
       * 声明变量存储首拼
       */
      var char = ''
      /**
       * 调用设置属性
       */
      /**
       * 跟据传入文字调用方法进行转换
       */
      this.pageMsg.stockName =name.toUpperCase();

      /**
       * 赋值检索码
       */
      console.log('char', this.pageMsg.stockName)
    },
    lookFalse(row) {
      this.$confirm('是否确认不合格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return  articleSetFalseApi(row.id)
      }).then(data => {
        this.$message.success('不合格')
        this.reject()
      })
    },
    turnDetail1(row) {
      // this.$router.push({
      //   path: '/releaseDetail',
      //   query: {
      //     id: row.id
      //   }
      // })
      console.log("主页面:"+row.id)
      this.rowID1 = row.id
      console.log('ddddd',row)
      this.detailBool = true
    },
    turnDetail(row) {
      // this.$router.push({
      //   path: '/releaseDetail',
      //   query: {
      //     id: row.id
      //   }
      // })
      this.rowID1 = row.id
      console.log('ddddd',row)
      this.detailBool = true
    },


    handleClose() {
      this.ruleModel.articleId = null
      this.ruleModel.failReason = ''
      this.basicWordCode = [];
      this.$refs.formName.resetFields()
      this.passDialog = false
      this.oneDialog = false
    },
    handleClose1() {
      this.ruleModel1.articleId = null
      this.ruleModel1.failReason = ''
      this.basicWordCode = [];
      this.$refs.formName.resetFields()
      this.passDialog1 = false
      this.oneDialog1 = false
    },
    handleClose2() {
      this.ruleModel2.articleId = null
      this.ruleModel2.failReason = ''
      this.basicWordCode1 = [];
      this.$refs.formName.resetFields()
      this.passDialog2 = false
      this.oneDialog2 = false
    },

    handleClose3() {
      this.ruleModel3.articleId = null
      this.ruleModel3.failReason = ''
      this.$refs.formName.resetFields()
      this.passDialog3 = false
      this.oneDialog3 = false
    },
    handleClose4() {
      this.ruleModel4.articleId = null
      this.ruleModel4.failReason = ''
      this.$refs.formName.resetFields()
      this.passDialog4 = false
      this.oneDialog4 = false
    },
    handleClose5() {
      this.ruleModel5.articleId = null
      this.ruleModel5.failReason = ''
      this.$refs.formName.resetFields()
      this.passDialog5 = false
      this.oneDialog5 = false
    },
    addAndEditBanner1(formName) {

      this.$refs[formName].validate(valid => {

        if (valid) {
          this.canSubmit = false
          if (this.oneDialog1) {
            authStateFailApi({
              ...this.ruleModel1
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('无效成功')
                this.handleClose1()
                this.shReject()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    addAndEditBanner2(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.canSubmit = false
          if (this.oneDialog2) {
            authStateSuccessApiYX({
              ...this.ruleModel2
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('审核成功')
                this.handleClose2()
                this.shReject()
                this.initstatus()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    //无效 提交按钮点击方法-Dream
    addAndEditBanner(formName) {
      this.$refs[formName].validate(valid => {
        if(this.ruleModel.failReason!=null &&
          this.ruleModel.failReason!=undefined &&
          this.ruleModel.failReason!=''){
          this.ruleModel.failReason = this.ruleModel.failReason.join(',');
        }
        if (valid) {
          this.canSubmit = false
          if (this.oneDialog) {
            authStateFailApi({
              ...this.ruleModel
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('无效成功')
                this.handleClose()
                this.initData()
                this.initstatus()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    addAndEditBanner3(formName) {

      this.$refs[formName].validate(valid => {

        if (valid) {
          this.canSubmit = false
          if (this.oneDialog3) {
            articleSetFalseApi({
              ...this.ruleModel3
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('成功')
                this.handleClose3()
                this.reject()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    addAndEditBanner4(formName) {

      this.$refs[formName].validate(valid => {

        if (valid) {
          this.canSubmit = false
          if (this.oneDialog4) {
            articleSetFalseApi({
              ...this.ruleModel4
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('成功')
                this.handleClose4()
                this.pass()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    //有效按钮提交方法-Dream
    addAndEditBanner5(formName) {
      this.$refs[formName].validate(valid => {
        if(this.ruleModel5.failReason!=null &&
          this.ruleModel5.failReason!=undefined &&
          this.ruleModel5.failReason!=''){
          this.ruleModel5.failReason = this.ruleModel5.failReason.join(',');
        }

        if (valid) {
          this.canSubmit = false
          if (this.oneDialog5) {
            authStateSuccessApiYX({
              ...this.ruleModel5
            }).then(res => {
              if (res.code == 200) {
                this.$message.success('审核成功')

                this.handleClose5()
                this.initData()
                this.initstatus()
              }
            })
          } else {
            this.alldialogTit()
          }
        } else {
          return false
        }
      })
    },
    dialogCreate() {
      this.passLoading = false
      this.pageMsg1 = this.$options.data().pageMsg1
      this.publisherDate1 = [];
    },
    dialogCreate1() {
      this.passLoading1 = false
      this.pageMsg2= this.$options.data().pageMsg2
      this.publisherDate2 = [];
    },
    dialogCreate4() {
      this.passLoading4 = false
      this.pageMsg3= this.$options.data().pageMsg3
      this.publisherDate3 = [];
    },
    dialogCreate3() {
      this.passLoading3 = false
    },
    pass() {
      this.passLoading = true;
      this.tableLoading1 = true
      const data = {
        ...this.pageMsg1
      }
      articlePageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData1 = records

          this.pageTotal1 = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading1 = false
      })
    },
    shReject() {
      this.passLoading4 = true;
      this.tableLoading3 = true;
      const data = {
        ...this.pageMsg3
      }
      articleRssPageApi(data).then(res => {
        console.log('dddd',res)
        if (res.code === 200) {
          let { total, records } = res.result

          this.tableData3 = records

          this.tableData3.forEach(el=> {
            setInterval(() => {
              if (el.countTime > 0) {
                el.countTime--
              }
              else {
                clearInterval(el.countTime)
              }
            }, 1000);
          })
          this.pageTotal3 = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading3 = false
      })
    },
    reject() {
      this.passLoading1 = true;
      this.tableLoading2 = true
      const data = {
        ...this.pageMsg2
      }
      articlePageApi(data).then(res => {
        if (res.code === 200) {
          let { total, records } = res.result
          this.tableData2 = records

          this.pageTotal2 = total
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
        this.tableLoading2 = false
      })
    },
    //无效原因 一级菜单信息
    tree() {
      basicWordSelectApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            console.log("wordName"+item.wordName)
            item.label = item.wordName
            item.value = item.id
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.invalidOptions = result
        // 原代码
        // this.basicWordCode = data.result
      });
    },
//有效原因 一级菜单信息
    tree1() {
      basicWordSelectApi1().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            console.log("wordName"+item.wordName)
            item.label = item.wordName
            item.value = item.id
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.validOptions = result

        //原代码
        // this.basicWordCode1 = data.result
      });
    },
    passItemTrue1(row) {
      this.passDialog2 = true
      this.canSubmit = true
      this.tree1();
      if (row) {
        this.ruleModel2.articleId = row.id
        this.oneDialog2 = true
      }

    },
    passItemTrue4(row) {
      this.passDialog3 = true
      this.canSubmit = true
      if (row) {
        this.ruleModel3.articleId = row.id
        this.oneDialog3 = true
      }

    },
    passItemTrue3(row) {
      this.passDialog4 = true
      this.canSubmit = true
      if (row) {
        this.ruleModel4.articleId = row.id
        this.oneDialog4 = true
      }

    },
    // 有效按钮点击事件Dream定位
    passItemTrue(row) {
      this.canSubmit = true
      this.passDialog5 = true
      this.tree1();
      if (row) {
        this.ruleModel5.articleId = row.id
        this.oneDialog5 = true
      }
    },
    passItemFalse1(row) {
      this.canSubmit = true
      this.passDialog1 = true
      // this.$nextTick(() => {
      //   this.$refs.formName.validate(valid => {
      //     console.log(valid)
      //   })
      // })
      this.tree();
      if (row) {
        this.ruleModel1.articleId = row.id
        this.oneDialog1 = true
      }
      // authStateFailApi({
      //   articleId: row.id
      // }).then(res => {
      //   if (res.code == 200) {
      //     this.$message.success('驳回成功')
      //     this.initData()
      //   }
      // })
    },
    passItemFalse(row) {
      this.canSubmit = true
      this.passDialog = true
      // this.$nextTick(() => {
      //   this.$refs.formName.validate(valid => {
      //     console.log(valid)
      //   })
      // })
      this.tree();
      if (row) {
        this.ruleModel.articleId = row.id
        this.oneDialog = true
      }
      // authStateFailApi({
      //   articleId: row.id
      // }).then(res => {
      //   if (res.code == 200) {
      //     this.$message.success('驳回成功')
      //     this.initData()
      //   }
      // })
    },
    /* bbb(row) {
         this.$confirm('是否确认内容无效', {
             confirmButtonText: '确定',
             cancelButtonText: '取消',
             type: 'warning'
         }).then(function () {
             row.auth = 1;
             return authStatePassApi(row)
         }).then(res => {
             if (res.code == 200) {
                 this.$message.success('已添加至驳回列表')
                 this.initData()
             }
         })
     },*/
    aaaa(row) {
      let routeData = row.contentUrl;
      window.open(routeData);
    },
    toBase64(imgUrl) {

      // 一定要设置为let，不然图片不显示
      const image = new Image()
      // 解决跨域问题
      image.setAttribute('crossOrigin', 'anonymous')
      const imageUrl = imgUrl
      image.src = imageUrl
      // image.onload为异步加载
      image.onload = () => {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var quality = 0.8
        // 这里的dataurl就是base64类型
        // 使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
        const dataurl = canvas.toDataURL('image/jpeg', quality)
        this.imgSrc = dataurl
      }
    },
    normal(row) {
      normalApi({
        list: [row.id]
      }).then(res => {

        if (res.code == 200) {
          this.$message.success('显示成功')
          this.initData()
        }
      })
    },
    /**
     * 导出列表
     */
    downloadPattern() {
      console.log("进入导出列表方法"+this.pageMsg)
      articleExportApi({
        ...this.pageMsg

      }).then(data => {
        let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let blobUrl = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.style.display = 'none'
        let dateXls = new Date().toLocaleDateString()
        link.download = `分发中心列表${dateXls}.xlsx`
        link.href = blobUrl
        link.click()
        link.remove()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(blobUrl)
      })
    },
    resend(row) {

      this.$router.push({
        path: '/releaseForm',
        query: {
          id: row.id
        }
      })
    },

    resend1(row) {

      this.passLoading = false;
      this.$router.push({
        path: '/releaseForm',
        query: {
          id: row.id
        }
      })
    },
    resend2(row) {

      this.passLoading2 = false;
      this.$router.push({
        path: '/releaseForm',
        query: {
          id: row.id
        }
      })
    },
    frozen(row) {
      frozenApi({
        list: [row.id]
      }).then(res => {

        if (res.code == 200) {
          this.$message.success('屏蔽成功')
          this.initData()
        }
      })
    },
    normalList(bool) {
      let arr = []
      this.selectionArr.map(res => {
        arr.push(res.id)
      })
      if (bool) {
        //显示
        normalApi({
          list: arr
        }).then(res => {

          if (res.code == 200) {
            this.$message.success('显示成功')
            this.initData()
          }
        })
      } else {
        frozenApi({
          //屏蔽
          list: arr
        }).then(res => {

          if (res.code == 200) {
            this.$message.success('屏蔽成功')
            this.initData()
          }
        })
      }
    },
    getClientFun(){
      getClientApi().then(res => {
        if(res.code === 200){
          this.clientInfo = res.result;
        }
      })
    },
    initData() {
      this.tableLoading = true
      const data = {
        ...this.pageMsg
      }
      articleRssPageApi(data).then(res => {
            if (res.code === 200) {
              let { total, records } = res.result
              this.tableData = records
              // this.tableData.forEach(el=>{
              //   // setInterval(() => {
              //     if(el.countTime>0){
              //       el.countTime--}
              //     else{
              //       clearInterval(el.countTime)
              //     }
              //   // }, 1000);
              // })
              this.pageTotal = total
            } else {
              this.$message({
                type: 'error',
                message: res.desc
              })
            }
            this.tableLoading = false
          })
    },
    initstatus() {

      statusStatisticsApi().then(res => {
        this.topListStatus = res.result
      })
    },
    initCompany() {
      //初始化公司列表
      companyPageApi({
        pageNum: 1,
        pageSize: 100
      }).then(res => {

        if (res.code === 200) {
          this.companyOptions = res.result.records
        } else {
          this.$message({
            type: 'error',
            message: res.desc
          })
        }
      })
    },
    initContentType() {
      contentTypeAllApi().then(({ code, result }) => {
        if (code == 200) {
          this.contentTypeList = result
        }
      })
    },
    formatData(row, column, cellValue, index) {
      //把传过来的日期进行回炉重造一下，又创建了一个js的 Date对象，进行重新构造，转为String字符串
      //最终返回 s 就可以了
      var s = new Date(cellValue).toLocaleString()
      return s

      // 测试参数含义：不知道参数是什么含义的就打印出来
    },

    // 启用禁用
    enableDisablingRole(data = '') {
      let status = data.status === 1 ? 2 : 1
      // data.status 为1 是启用 去禁用 disable为0
      enableDisableUserApi({ id: data.id, status }).then(res => {
        if (res.code === 200) {
          this.$message({
            message: status === 1 ? '启用成功' : '禁用成功',
            type: 'success'
          })
          this.initData()
        } else {
          this.$message({
            message: res.desc,
            type: 'error'
          })
        }
      })
    },

    lookdetail(data) {
      this.passLoading1 = false
      this.$router.push({
        path: '/rssPushDetail',
        query: {
          id: data.id
        }
      })
      // this.userDetailText = { ...data }
    },
    addAndEditDialog(row) {
      this.rowID = row.id
    },
    downPdf() {
      this.$refs.html2Pdf.generatePdf()
      this.rowID = null
    },
    searchData(bool) {
      if (bool) {
        this.pageMsg.pageNum = 1
        this.pageMsg.pageSize = 10

      } else {
        this.pageMsg = this.$options.data().pageMsg
        this.publisherDate=[]

      }
      this.initData()
    },
    searchData1(bool) {
      if (bool) {
        this.pageMsg1.pageNum = 1
        this.pageMsg1.pageSize = 10
      } else {
        this.pageMsg1 = this.$options.data().pageMsg1
        this.publisherDate1=[]
      }
      this.pass()
    },
    searchData2(bool) {
      if (bool) {
        this.pageMsg2.pageNum = 1
        this.pageMsg2.pageSize = 10
      } else {
        this.publisherDate2=[]
        this.pageMsg2 = this.$options.data().pageMsg2
      }
      this.reject()
    },
    searchData3(bool) {
      if (bool) {
        this.pageMsg3.pageNum = 1
        this.pageMsg3.pageSize = 10
      } else {
        this.publisherDate3=[]
        this.pageMsg3 = this.$options.data().pageMsg3
      }
      this.shReject()
    },


    handleSelectionChange(e) {
      this.selectionArr = e
    },
    dateChange() {
      if (this.publisherDate && this.publisherDate.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg.startTime = this.publisherDate[0]
        this.pageMsg.startT = this.publisherDate[0] + " 00:00:00"
        this.pageMsg.endTime = this.publisherDate[1]
        this.pageMsg.endT = this.publisherDate[1]+ ' 23:59:59'

      }
    },

    dateChange1() {
      if (this.publisherDate1 && this.publisherDate1.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg1.startTime = this.publisherDate1[0]
        this.pageMsg1.startT = this.publisherDate1[0]+ " 00:00:00"
        this.pageMsg1.endTime = this.publisherDate1[1]
        this.pageMsg1.endT = this.publisherDate1[1]+ ' 23:59:59'
      }

    },
    dateChange2() {
      if (this.publisherDate2 && this.publisherDate2.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg2.startTime = this.publisherDate2[0]
        this.pageMsg2.startT = this.publisherDate2[0]+ " 00:00:00"
        this.pageMsg2.endTime = this.publisherDate2[1]
        this.pageMsg2.endT = this.publisherDate2[1]+ ' 23:59:59'
      }

    },
    dateChange3() {
      if (this.publisherDate3 && this.publisherDate3.length == 2) {
        // this.pageMsg.startTime = this.publisherDate[0] + ' 23:59:59'
        // this.pageMsg.endTime = this.publisherDate[1] + ' 23:59:59'
        this.pageMsg3.startTime = this.publisherDate3[0]
        this.pageMsg3.startT = this.publisherDate3[0]+ " 00:00:00"
        this.pageMsg3.endTime = this.publisherDate3[1]
        this.pageMsg3.endT = this.publisherDate3[1]+ ' 23:59:59'
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.release {
  width: 100%;
  .release-top-new {
    height: 83px;
    background: #fff;
    // margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .release_head {
    display: flex;
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
    background: #fff;
    // background: #fff;
    justify-content: space-around;
    margin-bottom: 20px;
    .item {
      width: 30%;
      border-right: 1px solid #8584844a;
      text-align: center;
      &:nth-child(3) {
        border: none;
      }
    }
    h2 {
      font-weight: normal;
    }
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 0px auto;
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
