var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isPublish
    ? _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "channel-add" },
          [
            _c(
              "el-form",
              {
                ref: "addChannel",
                attrs: {
                  rules: _vm.userFormRules,
                  model: _vm.channelForm,
                  "label-position": _vm.labelPosition,
                  "label-width": _vm.formLabelWidth,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "媒介名称", prop: "channelName" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.channelForm.channelName,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channelName", $$v)
                        },
                        expression: "channelForm.channelName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "终端类别", prop: "terminalIdList" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.channelForm.terminalIdList,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "terminalIdList", $$v)
                          },
                          expression: "channelForm.terminalIdList",
                        },
                      },
                      _vm._l(_vm.terminalIdList, function (d) {
                        return _c("el-option", {
                          key: d.id,
                          attrs: { label: d.data, value: d.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "媒介类型", prop: "channelTypeName" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.channelForm.channelTypeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channelTypeName", $$v)
                        },
                        expression: "channelForm.channelTypeName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "媒介描述", prop: "apiDescribe" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", autosize: "" },
                      model: {
                        value: _vm.channelForm.apiDescribe,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "apiDescribe", $$v)
                        },
                        expression: "channelForm.apiDescribe",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "月活跃量（MAU）",
                      prop: "monthlyActivity",
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.channelForm.monthlyActivity,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "monthlyActivity", $$v)
                        },
                        expression: "channelForm.monthlyActivity",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "日活跃量（MAU）", prop: "dayActivity" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.channelForm.dayActivity,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "dayActivity", $$v)
                        },
                        expression: "channelForm.dayActivity",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "下载量（MAU）", prop: "downloadNum" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.channelForm.downloadNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "downloadNum", $$v)
                        },
                        expression: "channelForm.downloadNum",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "接口类别", prop: "type" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { disabled: _vm.isEdit },
                        model: {
                          value: _vm.channelForm.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.channelForm, "type", $$v)
                          },
                          expression: "channelForm.type",
                        },
                      },
                      _vm._l(_vm.apiCategoryList, function (d) {
                        return _c("el-option", {
                          key: d.key,
                          attrs: { label: d.key, value: d.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.channelForm.type == 10
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "接口地址", prop: "apiUrl" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.channelForm.apiUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "apiUrl", $$v)
                              },
                              expression: "channelForm.apiUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益次数", prop: "infiniteFlag" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.channelForm.infiniteFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.channelForm, "infiniteFlag", $$v)
                                },
                                expression: "channelForm.infiniteFlag",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("无限制"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("有限制"),
                              ]),
                            ],
                            1
                          ),
                          _c("br"),
                          _vm.channelForm.infiniteFlag
                            ? _c("el-input-number", {
                                attrs: {
                                  "controls-position": "right",
                                  min: 1,
                                  max: 9999,
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.channelForm.equityNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.channelForm,
                                      "equityNumber",
                                      $$v
                                    )
                                  },
                                  expression: "channelForm.equityNumber",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", autosize: "" },
                            model: {
                              value: _vm.channelForm.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "remarks", $$v)
                              },
                              expression: "channelForm.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.channelForm.type == 20
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限制频次",
                            prop: "withinTimeSecond",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "40%" },
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.channelForm.withinTimeSecond,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.channelForm,
                                      "withinTimeSecond",
                                      $$v
                                    )
                                  },
                                  expression: "channelForm.withinTimeSecond",
                                },
                              }),
                              _vm._v(" 秒请求： "),
                              _c("el-input", {
                                staticStyle: { width: "40%" },
                                attrs: { type: "input" },
                                model: {
                                  value: _vm.channelForm.requestsNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.channelForm,
                                      "requestsNum",
                                      $$v
                                    )
                                  },
                                  expression: "channelForm.requestsNum",
                                },
                              }),
                              _vm._v(" 次 "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "API key", prop: "apiKey" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.isEdit },
                            model: {
                              value: _vm.channelForm.apiKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "apiKey", $$v)
                              },
                              expression: "channelForm.apiKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "API Secret", prop: "apiSecret" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 100px)" },
                                attrs: { disabled: _vm.isEdit },
                                model: {
                                  value: _vm.channelForm.apiSecret,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.channelForm, "apiSecret", $$v)
                                  },
                                  expression: "channelForm.apiSecret",
                                },
                              }),
                              !_vm.isEdit
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.getSecret },
                                    },
                                    [_vm._v("点击获取")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "key和Secret有效期：",
                            prop: "dateValue",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticClass: "change-date-form",
                            staticStyle: { width: "80%" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "~",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                              "default-value": _vm.timeDefaultShow,
                            },
                            on: { change: _vm.dateChange },
                            model: {
                              value: _vm.channelForm.dateValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.channelForm, "dateValue", $$v)
                              },
                              expression: "channelForm.dateValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer text-center",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.channelClose } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.channelAdd } },
                  [_vm._v("提 交")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }