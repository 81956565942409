<template>
  <div class="channelManagement">
    <div class="channelManagement-head">
    </div>
    <el-card class="box-card">

      <el-form
        :model='pageMsg'
        size="small"
        label-position="center"
        label-width="100px">

        <el-row>


          <el-col :span='14'>
            <el-form-item label='日期:'>
              <el-radio-group v-model="pageMsg.dateType" @input='dateTypeInput'>
                <el-radio :label="0">当日</el-radio>
                <el-radio :label="1">本周</el-radio>
                <el-radio :label="2">本月</el-radio>
                <el-radio :label="3">本年</el-radio>
                <el-radio :label="4">区间</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span='6'>
            <el-form-item label="辖区:">
              <el-select v-model="pageMsg.secBureau" placeholder="请选择">
                <el-option
                  v-for="item in secBureauOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span='4'>
            <el-button type='primary'@click='initData'>查询</el-button>
            <el-button @click='resetCli'>重置</el-button>
          </el-col>
        </el-row>

        <el-row>

          <el-col :span="14" v-show='dateTimeShow'>
            <el-form-item label="时间:">
              <el-date-picker
                v-model="pageMsg.timeDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
            <el-form-item label="行业:">
              <el-select v-model="pageMsg.industryCode" placeholder="请选择行业">
                <el-option
                  v-for="item in industryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span='8'>
            <el-form-item label='类型:' prop="interactType">
              <el-select v-model="pageMsg.interactType" placeholder="请选择类型">
                <el-option
                  v-for="item in interactOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>



      </el-form>

      <el-row>
        <el-col :span='24'>
          <div id="barChart" style="height: 600px;"></div>
        </el-col>

      </el-row>

      <el-row>
        <el-col :span='24'>
          <div id="lineChart" style="height: 600px;"></div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span='24'>
          <div id="qualityChart" style="height: 600px;"></div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { getInteractByQualityRankingApi, getInteractByTimelyRankingApi, getInteractRankingApi } from 'api/stock'
import { getSecBureauAllApi } from 'api/article'
import { industryCategoryTreeApi } from 'api/common'
export default {
  data() {
    return {
      dateTimeShow:false,
      pageMsg:{
        dateType:2,
        startTime:'',
        endTime:''
      },
      secBureauOptions:[],
      industryOptions:[],
      interactOptions:[
        {
          label:"互动易",
          value:"深交所"
        },
        {
          label:"E互动",
          value:"上证"
        },
      ],
    }
  },
  created() {

  },
  mounted() {
    // this.getInteractRanking();
    // this.getInteractByTimelyRanking();
    this.secBureauData();
    // this.initCategoryCode()
    this.getIndustry();

    this.initData();

  },
  methods:{
    dateTypeInput(){
      if (this.pageMsg.dateType===4){
        this.dateTimeShow = true;
      }else {
        this.dateTimeShow = false;
      }
    },
    resetCli(){

    },
    getIndustry(){
      industryCategoryTreeApi().then(({ result }) => {
        const data = result;
        const options = [];
        data.forEach(item => {
          const val = {
            label : item.name,
            value : item.code
          }
          options.push(val);
        })

        this.industryOptions = options;
      })
    },
    initData(){
      if (this.pageMsg.timeDate && this.pageMsg.timeDate.length === 2) {
        const startDate = this.pageMsg.timeDate[0];
        const endDate = this.pageMsg.timeDate[1];
        this.pageMsg.startTime = this.formatDate(startDate, true);
        this.pageMsg.endTime = this.formatDate(endDate, false)
      }

      console.log("开始时间:"+this.pageMsg.startTime)
      console.log("结束时间:"+this.pageMsg.endTime)

      this.getInteractByTimelyRanking();
      this.getInteractRanking();
      this.getInteractByQualityRanking()
    },
    handleChange(val) {
      this.pageMsg.industryCode = val[val.length - 1]
    },
    initCategoryCode() {
      industryCategoryTreeApi().then(({ result }) => {
        const classification = result
        function classifi(arr) {
          arr.forEach(item => {
            item.label = item.name
            item.value = item.code
            if (item.children && item.children.length > 0) {
              classifi(item.children)
            } else {
              delete item['children']
            }
          })
        }
        classifi(classification)
        this.options = result
      })
    },
    secBureauData(){
      getSecBureauAllApi().then(res=>{
        this.secBureauOptions = res.result;
      })
    },
    getInteractByTimelyRanking(){
      getInteractByTimelyRankingApi(this.pageMsg).then(res => {
          if (res.code === 200){
            var chartData = res.result.map(item => ({ name: item.stockName, value: item.timely }));
            var lineChart = echarts.init(document.getElementById('lineChart'));

            const chartDataArr = chartData.map(item => item.value)

            // 配置项
            var option = {
              title: {
                text: '及时回复消息数量'
              },
              xAxis: {
                type: 'category',
                data: chartData.map(item => item.name), // X 轴数据
                axisLabel: {
                  rotate: 45, // 旋转 X 轴标签，避免重叠
                  interval: 0 // 强制显示所有标签
                }
              },
              yAxis: {
                type: 'value',
              },
              series: [{
                type: 'bar',
                // type: 'line',
                // smooth: true, // 平滑曲线
                data:[
                  {
                    value: chartDataArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: chartDataArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: chartDataArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: chartDataArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: chartDataArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: chartDataArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: chartDataArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: chartDataArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: chartDataArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: chartDataArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  },
                ],
                // data: chartData.map(item => item.value), // Y 轴数据
                // data: chartData.map(item => (item.value * 100).toFixed(2) + '%'),
                label: {
                  show: true,
                  position: 'top' // 在折线上显示具体数值
                }
              }]
            };
            // 使用刚指定的配置项和数据显示图表
            lineChart.setOption(option);
          }
      })
    },
    getInteractByQualityRanking(){
      getInteractByQualityRankingApi(this.pageMsg).then(res => {
          if (res.code === 200){
            var chartData = res.result.map(item => ({ name: item.stockName, value: item.quality }));
            var lineChart = echarts.init(document.getElementById('qualityChart'));
            const chartDataArr = chartData.map(item => item.value)

            // 配置项
            var option = {
              title: {
                text: '高质量数量'
              },
              xAxis: {
                type: 'category',
                data: chartData.map(item => item.name), // X 轴数据
                axisLabel: {
                  rotate: 45, // 旋转 X 轴标签，避免重叠
                  interval: 0 // 强制显示所有标签
                }
              },
              yAxis: {
                type: 'value',
              },
              series: [{
                type: 'bar',
                data:[
                  {
                    value: chartDataArr[0],
                    itemStyle: {
                      color: 'rgba(65,105,225)'
                    }
                  },
                  {
                    value: chartDataArr[1],
                    itemStyle: {
                      color: 'rgba(50,205,50)'
                    }
                  },
                  {
                    value: chartDataArr[2],
                    itemStyle: {
                      color: 'rgba(255,215,0)'
                    }
                  },
                  {
                    value: chartDataArr[3],
                    itemStyle: {
                      color: 'rgba(0,128,0)'
                    }
                  },
                  {
                    value: chartDataArr[4],
                    itemStyle: {
                      color: 'rgba(70,130,180)'
                    }
                  },
                  {
                    value: chartDataArr[5],
                    itemStyle: {
                      color: 'rgba(0,255,255)'
                    }
                  },
                  {
                    value: chartDataArr[6],
                    itemStyle: {
                      color: 'rgba(255,140,0)'
                    }
                  },
                  {
                    value: chartDataArr[7],
                    itemStyle: {
                      color: 'rgba(255,0,0)'
                    }
                  },
                  {
                    value: chartDataArr[8],
                    itemStyle: {
                      color: 'rgba(147,112,219)'
                    }
                  },
                  {
                    value: chartDataArr[9],
                    itemStyle: {
                      color: 'rgba(0,255,0)'
                    }
                  },
                ],
                // type: 'line',
                // smooth: true, // 平滑曲线
                // data: chartData.map(item => item.value), // Y 轴数据
                // data: chartData.map(item => (item.value * 100).toFixed(2) + '%'),
                label: {
                  show: true,
                  position: 'top' // 在折线上显示具体数值
                }
              }]
            };
            // 使用刚指定的配置项和数据显示图表
            lineChart.setOption(option);
          }
      })
    },
    getInteractRanking(){
      getInteractRankingApi(this.pageMsg).then(res => {
        if (res.code === 200){
          // 将数据转换成 ECharts 所需的格式
          var chartData = res.result.map(item => ({ name: item.stockName, value: item.putSum }));
          // 初始化柱状图实例
          var barChart = echarts.init(document.getElementById('barChart'));
          // 配置项
          const chartDataArr = chartData.map(item => item.value)

          var option = {
            title: {
              text: '公司回复消息数量'
            },
            xAxis: {
              type: 'category',
              data: chartData.map(item => item.name), // X 轴数据
              axisLabel: {
                rotate: 45, // 旋转 X 轴标签，避免重叠
                interval: 0 // 强制显示所有标签
              }
            },
            yAxis: {
              type: 'value',
            },
            series: [{
              type: 'bar',
              data:[
                {
                  value: chartDataArr[0],
                  itemStyle: {
                    color: 'rgba(65,105,225)'
                  }
                },
                {
                  value: chartDataArr[1],
                  itemStyle: {
                    color: 'rgba(50,205,50)'
                  }
                },
                {
                  value: chartDataArr[2],
                  itemStyle: {
                    color: 'rgba(255,215,0)'
                  }
                },
                {
                  value: chartDataArr[3],
                  itemStyle: {
                    color: 'rgba(0,128,0)'
                  }
                },
                {
                  value: chartDataArr[4],
                  itemStyle: {
                    color: 'rgba(70,130,180)'
                  }
                },
                {
                  value: chartDataArr[5],
                  itemStyle: {
                    color: 'rgba(0,255,255)'
                  }
                },
                {
                  value: chartDataArr[6],
                  itemStyle: {
                    color: 'rgba(255,140,0)'
                  }
                },
                {
                  value: chartDataArr[7],
                  itemStyle: {
                    color: 'rgba(255,0,0)'
                  }
                },
                {
                  value: chartDataArr[8],
                  itemStyle: {
                    color: 'rgba(147,112,219)'
                  }
                },
                {
                  value: chartDataArr[9],
                  itemStyle: {
                    color: 'rgba(0,255,0)'
                  }
                },
              ],
              // data: chartData.map(item => item.value), // Y 轴数据
              label: {
                show: true,
                position: 'top' // 在柱状图上显示具体数值
              }
            }]
          };
          // 使用刚指定的配置项和数据显示图表
          barChart.setOption(option);

        }
      })
    },
    formatDate(date, isStart) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const time = isStart ? "00:00:00" : "23:59:59";
      return `${year}-${month}-${day} ${time}`;
    }
  }
}

</script>

<style lang="scss" scoped>
.channelManagement {
  width: 100%;
  .channelManagement-head {
    padding: 10px 20px;
    background: #fff;
  }
  .box-card {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 20px auto;
  }
  /deep/.state-table {
    i {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .select_warn {
    background: rgb(230, 247, 255);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
}
</style>
