var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "channelManagement" },
      [
        _c("div", { staticClass: "channelManagement-head" }),
        _c("el-card", { staticClass: "box-card" }, [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.pageMsg,
                    size: "small",
                    "label-position": "center",
                    "label-width": "60px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "日期:" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { input: _vm.dateTypeInput },
                                  model: {
                                    value: _vm.pageMsg.dateType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pageMsg, "dateType", $$v)
                                    },
                                    expression: "pageMsg.dateType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("近7天"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("近30天"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("区间"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.dateTimeShow,
                              expression: "dateTimeShow",
                            },
                          ],
                          attrs: { span: 10 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "时间:" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.pageMsg.dateTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pageMsg, "dateTime", $$v)
                                  },
                                  expression: "pageMsg.dateTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 4, align: "right" } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.search()
                                  },
                                },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.resetClick()
                                  },
                                },
                              },
                              [_vm._v(" 重置 ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px" } },
            [
              _c("h2", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, align: "center" } },
                        [
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("有效率"),
                              ]),
                            ],
                            1
                          ),
                          _c("el-progress", {
                            attrs: {
                              type: "circle",
                              percentage: _vm.releaseData.validRate,
                              color: "green",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, align: "center" } },
                        [
                          _c(
                            "p",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("无效率"),
                              ]),
                            ],
                            1
                          ),
                          _c("el-progress", {
                            attrs: {
                              type: "circle",
                              percentage: _vm.releaseData.inValidRate,
                              color: "red",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "20px",
                    "margin-top": "50px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12, align: "center" } }, [
                        _vm._v(" 文章标题: "),
                      ]),
                      _c("el-col", { attrs: { span: 12, align: "center" } }, [
                        _vm._v(" 发布时间: "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.releaseArticleList, function (item) {
                return _c(
                  "div",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, align: "center" } },
                          [
                            item.isValid
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(_vm._s(item.articleTitle)),
                                ])
                              : _vm._e(),
                            !item.isValid
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(_vm._s(item.articleTitle)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, align: "center" } },
                          [
                            _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v(_vm._s(item.createdTime)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12, align: "center" } }, [
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(" 有效数: "),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.readLoading,
                                expression: "readLoading",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.releaseData.validSum))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12, align: "center" } }, [
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(" 无效数: "),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.readLoading,
                                expression: "readLoading",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.releaseData.inValidSum))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12, align: "center" } }, [
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(" 分发终端数总和: "),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.pushApiLoading,
                                expression: "pushApiLoading",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.channelData.pushApiSum))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12, align: "center" } }, [
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(" 阅读量总和: "),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.readLoading,
                                expression: "readLoading",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.channelData.readSum))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12, align: "center" } }, [
                    _c("h3", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(" 同业分发排名: "),
                    ]),
                    _c("div", {
                      staticStyle: { width: "500px", height: "450px" },
                      attrs: { id: "industry" },
                    }),
                    _c("p", { staticStyle: { "text-align": "center" } }, [
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v(" 您公司当前在同行业中发布数量排名为第 "),
                      ]),
                      _c(
                        "span",
                        { staticStyle: { color: "red", "font-size": "18px" } },
                        [_vm._v(_vm._s(_vm.rankingNumber))]
                      ),
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v(" 名! "),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12, align: "center" } }, [
                    _c("h3", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(" 同辖区发排名: "),
                    ]),
                    _c("div", {
                      staticStyle: { width: "500px", height: "450px" },
                      attrs: { id: "bureau" },
                    }),
                    _c("p", { staticStyle: { "text-align": "center" } }, [
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v(" 您公司当前在同辖区中发布数量排名为第 "),
                      ]),
                      _c(
                        "span",
                        { staticStyle: { color: "red", "font-size": "18px" } },
                        [_vm._v(_vm._s(_vm.bureauRankingNumber))]
                      ),
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v(" 名! "),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }