<template>
  <div class="release_detail">
    <h2>{{ detail.articleTitle+ "     " }}
<sub style="font-size: 10px">
    来源：
    {{
     detail.stockName

    }}
</sub>
    </h2>
    <h4 class="time" >

      时间：
      {{
        new Date(detail.createdTime).toLocaleString()
      }}



    </h4>
    <div class="content">
      <div v-html="detail.articleContent"></div>
    </div>
    <h4 ></h4>
    <div @click="aaaa" v-if="showFalse" style="color: blue" align="center">原文点击跳转</div>
  </div>
</template>
<script>
import { articleRssContentApi } from 'api/article'
import { urlToBase64Api } from 'api/common'
export default {
  data() {
    return {
      detail: {},
      id: null,
        showFalse: false,
      styleNewContent: '',
        acccc: undefined
    }
  },
  props: {
    rowID: {
      type: Number,
      default: null
    }
  },
  created() {
    // setTimeout(function() {
      this.initData(true)
    // }, 1000);
  },
  watch: {
    rowID(id) {
      if (id) {
        this.initData(true)
      }
    }
  },
  methods: {
    test1(){
      console.log("测试按钮"+this.id)
    },
      aaaa() {
          if (this.showFalse === true) {
              let routeData = this.acccc;
              window.open(routeData);
          }
      },
    initData(bool) {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
      } else if (this.rowID) {
        this.id = this.rowID
      }
      if (this.id) {
        articleRssContentApi(this.id).then(res => {
          if (bool) {
            this.das(res.result.articleContent)
            // this.setImageSrc(this.styleNewContent).then(newContent => {
            this.setImageSrc(res.result.articleContent).then(newContent => {
              this.detail = res.result
              this.acccc =  res.result.contentUrl
                if (this.acccc) {
                    this.showFalse = true;
                }
              this.detail.articleContent = newContent
              this.$emit('downPdf')
             })
          } else {
            this.detail = res.result
              this.acccc = res.result.contentUrl
              if (this.acccc) {
                  this.showFalse = true;
              }
            this.das(res.result.articleContent)
            this.detail.articleContent = this.styleNewContent
          }
        })
      }
    },
    setImageSrc(content) {
      //重组IMG 图片转换为base64
      return new Promise((resolve, reject) => {
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        let _this = this
        let srcArr = content.split(/src="/g) //截取全部图片为数组
        let isEnd = 1 //初始化是否转换完毕
        function creatBase64(srcArr) {
          //循环img数组 转换为图片
          for (let i = 1; i < srcArr.length; i++) {
            let lastIndex = srcArr[i].indexOf('"') //找到第一个 "双引号
            let src = srcArr[i].slice(0, lastIndex) //用下标获取每个图片的src
            urlToBase64Api({
              url: src
            }).then(res => {
              if (res.code == 200) {
                let base64 = 'data:image/png;base64,' + res.result
                let newSrc = srcArr[i].slice(lastIndex) //获取 第一个双引号 之后的字符再把 生成的 base64格式的图片的字符拼接起来
                srcArr[i] = base64 + newSrc
                isEnd++
                if (isEnd == srcArr.length) {
                  //用全局变量 判断是否创建完所有6base4图片，返回数组
                  let result = srcArr.join('src="') //拿到 创建2完的base64图片数组，拼接为一个字符串，抛出去
                  loading.close()
                  resolve(result)
                }
              }
            })
          }
        }
        if (srcArr.length > 1) {
          creatBase64(srcArr)
        } else {
          loading.close()
          resolve(content)
        }
      })
    },
    das(StylearticleContent) {
      // 去除富文本标签样式
      const removeHtmlStyle = str => {
        // str = str.replace(/<\/?[^>]*>/g, '') //匹配所有html标签

        // str = str.replace(/<p[^>]*>/g, '<p class="tag-class">') //去除标签内所有属性，并设置自定义类名

        str = str.replace(/(\n)/g, '') //去除换行
        str = str.replace(/\s+style="[^"]*"/g, '') //去除style样式
        str = str.replace(/\s+width="[^"]*"/g, 'width="640px"') //去除style样式
        str = str.replace(/\s+height="[^"]*"/g, '') //去除style样式
        str = str.replace(/&nbsp;/gi, '') //去除空格
        str = str.replace(' ', '')
        str = str.replace(
          /<img/g,
          '<img style="width:640px;vertical-align: top;margin:0px auto;"'
        )
        // str = str.replace(/<p/g, '<p style="font-size:12px;"')
        // str = str.replace(/\<img/gi, '<img class="img_class"') //添加自定义样式

        return str
      }

      // function reMove(content) {
      //   return content.replace(/<\/?[^>]*>|(\n|\t|\r)|(\s)/g, '')
      // }
      this.styleNewContent = removeHtmlStyle(StylearticleContent)

}
  }
}
</script>
<style lang="scss" scoped>
.release_detail {
  // width: 85%;
  width: 900px;
  margin: 30px auto;
  padding: 40px 100px;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  min-height: calc(100vh - 250px);
  h4 {
    padding-bottom: 30px;
    border-bottom: 1px dotted #666;
  }
  .time {
    color: #666;
  }
  .content {
    max-width: 1280px;
    overflow: auto;
  }
}
</style>
